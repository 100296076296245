import React, { useEffect, useState } from 'react';
import ModalNoticeLineLogin from 'components/ModalNoticeLineLogin';

export const FuneralRegistrationThanks = () => {
  const [showDialog, setShowDialog] = useState(false);
  
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.history.pushState(null, null, window.location.href);
    setShowDialog(true);
  }

  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  return (
    <div id="auth-main">
      <div id="auth-common">
        <img className="logo" src="/images/itowa-logo-square.jpg" alt="logo"></img>
        <div className="title">受付完了</div>
        <p>itowa公式LINEのトークをご確認ください。</p>
      </div>
      {showDialog && <ModalNoticeLineLogin showDialog={showDialog} setShowDialog={setShowDialog}/>}
    </div>
  )
}

export default FuneralRegistrationThanks;
