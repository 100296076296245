export const validateEmail = (email) => {
  const regex = /\S+@\S+\.\S+/;
  return regex.test(email);
};

const checkCharacterErrorMessage = {
  'U001': 'Contains characters that cannot be used by Humony system',
  'P091': 'The number of characters in "character string" exceeds the limit',
  'S002': 'Humony system is under maintenance',
  'R001': 'Sent using a request method other than POST',
};

export const checkCharacterError = (response) => {
  const errors = [];
  Object.keys(checkCharacterErrorMessage)?.forEach((item) => {
    if (response.includes(item)) {
      errors.push(checkCharacterErrorMessage[item]);
    }
  });
  return errors;
}

export const handleSplitData = (data) => {
  const dataSplit = data.replace("true", "").trim().split(',');
  return dataSplit.map(item => ({ value: item }));
}

export const requestFormData = (type, prefecture, city, town) => {
  const formData = new FormData();
  formData.append("type", type);
  formData.append("pref", prefecture);
  switch (type) {
    case "tyou":
      formData.append("siku", city);
      break;
    case "zipcode":
      formData.append("siku", city);
      formData.append("tyou", town);
      break;
    default:
      break;
  }
  return formData;
}

export function validateHalfwidthNumber(input) {
  if(input){
    const regex = /^[0-9]+$/;
    return regex.test(input);
  }
  return true;
}
