import React, { useRef, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';

import { AuthContext } from '../components/Contexts';
import AttendeeLayout from "../components/AttendeeLayout"
import { paymentIntent } from "../utils/aws/payment";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const amount = useRef(0);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const pi = await paymentIntent("sougisya1.bloom-post.test", amount.current.value); // TODO
    console.log(pi.paymentIntent);
    const payload = await stripe.confirmCardPayment(pi.paymentIntent.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: "user name",
        },
      }
    },{
      stripeAccount: pi.accountId
    });
    console.log(payload);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input ref={amount}></input>
      <CardElement options={{ hidePostalCode: true }} />
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
}

export const PaymentTest = () => {
  const { userInfo } = useContext(AuthContext);
  const stripePromise = loadStripe(userInfo.key, {
    stripeAccount: 'acct_1JpjCSDIq1Qicu96'
  }); // TODO

  return (
    <AttendeeLayout>
      <Elements stripe={stripePromise}>
        <CheckoutForm userInfo={userInfo}/>
      </Elements>
    </AttendeeLayout>
  )
}

export default PaymentTest;