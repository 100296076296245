import React, { useState, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';

import { AuthContext, AttendanceContext } from 'components/Contexts';
import AttendeeLayout from 'components/AttendeeLayout';
import { removeCartItem, getCartContents, setCartItemLabel, checkLimit } from '../../components/Cart';
import CartItem from 'components/CustomForms/CartItem';
import CartTotal from 'components/CartTotal';
import { Img } from 'components/Img';
import { shuffle } from 'utils/shuffle';
import * as Constants from '../../constants';

import ModalPolicySagawa from '../../components/ModalPolicySagawa';
import ModalConfirmFuneralMoney from 'components/ModalConfirmFuneralMoney';

export const AttendanceContentConfirm = (props) => {
  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;
  const history = useHistory();

  const { userInfo } = useContext(AuthContext);
  const { funeralInfo, showConfirmFuneralMoney, setShowConfirmFuneralMoney } = useContext(AttendanceContext);

  const [selectedItem, setSelectedItem] = useState([]);
  const [kumotsuList, setKumotsuList] = useState([]);
  const [kyoukaList, setKyoukaList] = useState([]);
  const [koudenList, setKoudenList] = useState([]);

  const [showDialog, setShowDialog] = useState(false);
  const [koudenLimit, setKoudenLimit] = useState("");
  const [vigilLimit, setVigilLimit] = useState("");
  const [funeralLimit, setFuneralLimit] = useState("");
  const [fortyNinthLimit, setFortyNinthLimit] = useState("");
  const [showDialogPolicy, setShowDialogPolicy] = useState(false);
  const [isConfirmPolicy, setIsConfirmPolicy] = useState(false);

  const { location } = props;
  const queryString = new URLSearchParams(location.search);
  const isOrderOnly = queryString.get("isOrderOnly") === "true";

  React.useEffect(() => {
    const fetch = async () => {
      const result = await getDisplayItem(funeralId);
      await checkLimit(funeralId, result.itemLimit, result.koudenLimit);
      const cart = await getCartContents(funeralId);

      setKumotsuList(result.kumotsuList);
      setKyoukaList(result.kyoukaList);
      setKoudenList(result.koudenList);
      setSelectedItem(cart);
      setKoudenLimit(result.displayLimits.koudenLimit);
      setVigilLimit(result.displayLimits.vigil);
      setFuneralLimit(result.displayLimits.funeral);
      setFortyNinthLimit(result.displayLimits.fortyNinth);
    }
    fetch();
    // eslint-disable-next-line
  }, []);

  const setLabel = (index, label, msg) => {
    let itemList = selectedItem;
    itemList[index].label = label || "";
    itemList[index].message = msg || "";

    setCartItemLabel(funeralId, index, label || "", msg || "");
    setSelectedItem(itemList);
  }

  const deleteItem = async (index) => {
    await removeCartItem(funeralId, selectedItem, index);
    const cart = await getCartContents(funeralId);
    setSelectedItem(cart);
  }

  const handleConfirmPopUpPolicy = () => {
    setShowDialogPolicy(false)
    setIsConfirmPolicy(true);
  }

  const handlePurchase = async () => {
    //Handle logic show popup
    const emptyLabel = selectedItem.filter(item => !item.label || item.label.length === 0).length;
    const emptyMessage = selectedItem.filter(item => (item.itemType === "money" || item.itemType === "telegram" || item.itemType === "sagawa_telegram") && (!item.message || item.message.length === 0)).length;
    if(emptyLabel !== 0 || emptyMessage !== 0) {
      setShowDialog(true);
    } else {
      if(selectedItem?.some(el => el.itemType === "sagawa_telegram") && !isConfirmPolicy){
        setShowDialogPolicy(true)
      } else {
        const cart = await getCartContents(funeralId);
        const haveItemMoney = cart?.find((item) => item?.itemType === 'money');
        if (!userInfo?.isChiefMourner && !userInfo?.attendees && koudenList?.length > 0 && haveItemMoney && funeralInfo?.enableMoneyFlow) {
          setShowConfirmFuneralMoney(true);
        } else {
          handleCommit();
        }
      }
    }
  }

  const handleCommit = async () => {
    history.push("/" + funeralId + "/attendance/content/input?isOrderOnly=" + isOrderOnly);
  }

  return (
    <AttendeeLayout>
      <Helmet>
        <title>籠の中 | itowa</title>
      </Helmet>
      <form>
        <h2 className="item-title">籠の中</h2>
        <div className="item-limit">
          <h5>香典</h5>
          {
            koudenLimit ?
            <p>{moment(koudenLimit).format("YYYY年M月D日 HH:mm")}まで受付可能です</p>
            :
            <p>香典は受け付けておりません</p>
          }
          <h5>弔文、お供物、お供花</h5>
          <div className="indent-1">
            時間毎の注文期限は以下の通りです
            <table className="table table-borderless table-sm indent-1">
              <tbody>
              <tr>
                <td>お通夜へのお届け</td>
                {vigilLimit === "" ?
                    <td>執り行っておりません</td>
                    :
                    <td>{moment(vigilLimit).format("YYYY年M月D日 HH:mm")}</td>
                }
              </tr>
              <tr>
                <td>葬儀告別式へのお届け</td>
                <td>{moment(funeralLimit).format("YYYY年M月D日 HH:mm")}</td>
              </tr>
              <tr>
                <td>葬儀後ご自宅等へのお届け</td>
                <td>{moment(fortyNinthLimit).add(1, "days").format("YYYY年M月D日 00:00")}</td>
              </tr>
              </tbody>
            </table>
            (葬儀後は葬家希望の日時にお届けとなります)
          </div>
        </div>
        <div className="description-link">
          <div>名札の書き方については<a href={Constants.EXAMPLE_NAME_URL} target="_blank" rel="noreferrer">こちら</a></div>
          <div>弔文の文章例を参考にされたい方は<a href={Constants.EXAMPLE_CHODEN_URL} target="_blank" rel="noreferrer">こちら</a></div>
        </div>
        <div id="cart">
          <div className="cart-panel">
            <div className="cart-item-panel">
              {
                selectedItem.map((item, index) =>
                  <CartItem key={index} item={item} onChange={(label, msg) => setLabel(index, label, msg)} onDelete={() => deleteItem(index)} />
                )
              }
            </div>
            <div className="cart-total-panel">
              <CartTotal selectedItem={selectedItem}>
                <div className="button-to-payment-wrapper">
                  <button type="button" className="btn btn-primary button-to-payment" disabled={!selectedItem.length} onClick={() => handlePurchase()}>ご購入手続きへ</button>
                  <span className="chevron-right"></span>
                </div>
              </CartTotal>
            </div>
          </div>
          <h2 className="item-title-horizontal">お供物とお供花</h2>
          <div className="item-sample">
            <div className="item-side-scroll">
              {
                shuffle(kumotsuList.concat(kyoukaList)).map((item, index) =>
                  <div key={index} className="item-photos">
                    <Img src={item.imagePath} />
                  </div>
                )
              }
            </div>
          </div>
          <Button className="btn-to-flower-and-things" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/select/kumotsu?isOrderOnly=" + isOrderOnly)}>お供物とお供花をもっと見る</Button>
        </div>
      </form>
      <Modal id="attendee-modal" show={showDialog} onHide={() => setShowDialog(false)} centered>
        <Modal.Body className="mincho">
          <div style={{margin:"2rem auto"}}>
            お名前もしくはメッセージの入力が完了していません。<br/>
            お名前入力ボタンを押して入力してください。
          </div>
          <button type="button" className="btn btn-promary black-button" onClick={() => setShowDialog(false)}>閉じる</button>
        </Modal.Body>
      </Modal>
      {showConfirmFuneralMoney && <ModalConfirmFuneralMoney onSubmit={() => handleCommit()}  />}
      {showDialogPolicy && <ModalPolicySagawa showDialogPolicy={showDialogPolicy} setShowDialogPolicy={setShowDialogPolicy} handelConfirm={handleConfirmPopUpPolicy}/>}
    </AttendeeLayout>
  );
};

const getDisplayItem = async (funeralId) => {
  const options = {
    queryStringParameters: { funeralId: funeralId }
  }

  return await API.get('guest', '/funerals/item/template', options);
}

export default AttendanceContentConfirm;