import moment from 'moment-timezone';
import uuid from 'uuid';

export const setCartItem = async (funeralId, item, quantity) => {
  let cart = JSON.parse(localStorage.getItem(funeralId));

  switch(item.itemType) {
    case "money":
      cart.items = cart.items.filter((row) => row.itemType !== "money");
      cart.items.push(item);
      break;
    case "telegram":
      cart.items.push(item);
      break;
    case "sagawa_telegram":
      cart.items.push(item);
      break;
    case "things":
      let kumotsuItem = cart.items.filter((row) => row.itemId === item.itemId);
      if(kumotsuItem.length > 0) {
        cart.items = cart.items.filter((row) => row.itemId !== item.itemId);
        if(kumotsuItem.length > quantity) {
          for(let i=0; i<quantity; i++) {
            cart.items.push(kumotsuItem[i]);
          }
        } else {
          kumotsuItem.map((row) => {return cart.items.push(row)});
          for(let i=0; i<quantity-kumotsuItem.length; i++) {
            cart.items.push(item);
          }
        }
      } else {
        for(let i=0; i<quantity; i++) {
          cart.items.push(item);
        }
      }
      break;
    case "flower":
      let kyoukaItem = cart.items.filter((row) => row.itemId === item.itemId);
      if(kyoukaItem.length > 0) {
        cart.items = cart.items.filter((row) => row.itemId !== item.itemId);
        if(kyoukaItem.length > quantity) {
          for(let i=0; i<quantity; i++) {
            cart.items.push(kyoukaItem[i]);
          }
        } else {
          kyoukaItem.map((row) => {return cart.items.push(row)});
          for(let i=0; i<quantity-kyoukaItem.length; i++) {
            cart.items.push(item);
          }
        }
      } else {
        for(let i=0; i<quantity; i++) {
          cart.items.push(item);
        }
      }
      break;
    default:
  }
  cart.paymentIntent = "";

  localStorage.setItem(
    funeralId,
    JSON.stringify(cart)
  );
}

export const removeCartItem = async (funeralId, item, index) => {
  let cart = JSON.parse(localStorage.getItem(funeralId));

  if(index !== undefined) {
    cart.items.splice(index, 1);
  } else {
    cart.items = cart.items.filter((row) => row.itemId !== item.itemId);
  }
  cart.paymentIntent = "";

  localStorage.setItem(
    funeralId,
    JSON.stringify(cart)
  );
}

export const getCartContents = async (funeralId) => {
  let cart = JSON.parse(localStorage.getItem(funeralId));

  return cart?.items || [];
}

export const setCartItemLabel = async (funeralId, index, label, msg) => {
  let cart = JSON.parse(localStorage.getItem(funeralId));

  cart.items[index].label = label;
  cart.items[index].message = msg;

  localStorage.setItem(
    funeralId,
    JSON.stringify(cart)
  );
}

export const checkLimit = async (funeralId, itemLimit, koudenLimit) => {
  let cart = JSON.parse(localStorage.getItem(funeralId));
  let flag = true;
  const now = moment().tz('Asia/Tokyo').format("YYYY-MM-DD HH:mm");

  if (!cart || !cart.items) {
    cart = {
      itemLimit: itemLimit,
      koudenLimit: koudenLimit,
      paymentIntent: "",
      items: []
    }

    flag = false;
  }

  if(cart.itemLimit !== itemLimit) {
    cart = {
      itemLimit: itemLimit,
      koudenLimit: cart.koudenLimit,
      paymentIntent: "",
      items: cart.items.filter((row) => row.itemType === "money")
    }
    
    flag = false;
  }

  if(cart.koudenLimit !== koudenLimit || koudenLimit < now) {
    cart = {
      itemLimit: cart.itemLimit,
      koudenLimit: koudenLimit,
      paymentIntent: "",
      items: cart.items.filter((row) => row.itemType !== "money")
    }

    flag = false;
  }

  localStorage.setItem(
    funeralId,
    JSON.stringify(cart)
  );

  return flag;
}

export const setCartGiftItem = async (funeralId, gift, jpy, order = null) => {
  const { GiftName, Price, ImagePath, GiftId, CompanyId } = gift;
  const { UserName, OrderId, AttendeeId } = order;
  const giftItem = {
    'itemName': GiftName,
    'price': Price,
    'imagePath': ImagePath,
    'giftId': GiftId,
    'companyId': CompanyId,
    'itemType': 'gift',
    'priceCategory': jpy,
    'userName': UserName ?? null,
    'orderId': OrderId ?? null,
    'targetAttendeeId': AttendeeId ?? null,
    'uuid': uuid.v4(),
    'taxRateTenPer': gift.TaxRateTenPer,
  };

  let cart = JSON.parse(localStorage.getItem(funeralId + 'funeralGift'));
  if (cart) {
    if (OrderId) {
      const duplicationIndividualIndex = cart.items.findIndex(item => item.priceCategory === jpy && item.orderId === OrderId);

      if (duplicationIndividualIndex >= 0) {
        //　個別重複上書きスコープ
        cart.items[duplicationIndividualIndex] = giftItem;
      } else {
        // 個別追加スコープ
        cart.items.push(giftItem);
      }
    } else {
      const duplicationIndex = cart.items.findIndex(item => item.priceCategory === jpy && item.orderId === null);

      if (duplicationIndex >= 0) {
        // 重複上書きスコープ
        cart.items[duplicationIndex] = giftItem;
      } else {
        //　追加スコープ
        cart.items.push(giftItem);
      }
    }
  } else {
    // 新規作成スコープ
    cart =
      {
        'items': [
          giftItem
        ]
      };
  }

  cart.paymentIntent = "";

  localStorage.setItem(
    funeralId + 'funeralGift',
    JSON.stringify(cart)
  );
}

export const getOrderGiftReturnSelected = async (funeralId) => {
  let orderSelected = JSON.parse(localStorage.getItem(funeralId + '_orderGiftReturnSelected'));
  return orderSelected;
}

export const setOrderGiftReturnSelected = async (funeralId, orderIds) => {
  localStorage.setItem(
    funeralId + '_orderGiftReturnSelected',
    JSON.stringify(orderIds)
  );
}

export const getCartGiftContents = async (funeralId) => {
  let cart = JSON.parse(localStorage.getItem(funeralId + 'funeralGift'));
  const defaultGift = [{
    giftName: undefined,
    price: -1,
    imagePath: undefined,
    giftId: undefined,
    itemName: undefined,
    itemType: 'gift',
    priceCategory: undefined,
    userName: undefined,
    orderId: undefined,
    targetCount: undefined,
    totalPrice: undefined
  }];
  return cart ? cart.items : defaultGift;
}

export const removeCartGiftItem = async (funeralId, item, index) => {
  const cart = await getCartGiftContents(funeralId);
  const targetGift = item[index];
  const targetIndex = cart.findIndex(row =>
    row.uuid === targetGift.uuid
  );
  let updateCart = {};
  updateCart.items = cart;

  updateCart.items.splice(targetIndex, 1);
  updateCart.paymentIntent = "";

  localStorage.setItem(
    funeralId + 'funeralGift',
    JSON.stringify(updateCart)
  );
}