import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalNoticeLineLogin = ({ showDialog, setShowDialog }) => {
    
  return (
    <Modal id="attendee-modal" show={showDialog} onHide={() => setShowDialog(false)} centered>
    <Modal.Body id="item-detail-modal" >
      <div className="title-custom">ブラウザを閉じてitowa公式LINEの内容を<br/>ご確認ください。</div>
      <button type="button" className="btn btn-promary black-button" onClick={() => setShowDialog(false)}>閉じる</button>
    </Modal.Body>
  </Modal>
  );
}

export default ModalNoticeLineLogin;
