import { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MessageContext } from '../Contexts';

export const ModalConfirmDelete = () => {
  const { modal, setModal } = useContext(MessageContext);

  const handleClose = () => {
    setModal({ show: false });
  }

  return (
    <Modal show={modal.show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{modal.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modal.message}</Modal.Body>
      <Modal.Footer>
        <Button variant={modal.variant} onClick={() => { handleClose(); modal.onSubmit() }}>
          OK
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalConfirmDelete;