import React, { useRef } from 'react';
import { API } from 'aws-amplify'
import { useHistory, Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Pagination from 'components/Pagination';

export const FuneralHistoryAttendeeList = (props) => {
  const PER_PAGE = 10;

  const [attendeeUserList, setAttendeeUserList] = React.useState([]);
  const [pagenatedList, setPagenatedList] = React.useState([]);
  const history = useHistory();
  const searchKey = useRef("");
  const funeralId = props.match.params.funeralId;

  const search = async () => {
    const result = await getAttendeeUserList(funeralId, searchKey.current.value);
    const resultList = result.attendeeUserList.sort((a, b) => a.Id < b.Id ? -1 : 1);
    setAttendeeUserList(result.attendeeUserList);
    setPagenatedList(resultList.slice(0, PER_PAGE));
  }

  React.useEffect(() => {
    const fetch = async () => {
      const result = await getAttendeeUserList(funeralId);
      const resultList = result.attendeeUserList.sort((a, b) => a.Id < b.Id ? -1 : 1);
      setAttendeeUserList(result.attendeeUserList);
      setPagenatedList(resultList.slice(0, PER_PAGE));
    }
    fetch()
    // eslint-disable-next-line
  }, []);

  return (
    <CommonLayout>
      <ContentHeader>
        参列者情報
      </ContentHeader>

      <div className="form-group">
        <Row>
          <Col md="6">
            <div className="card">
              <div className="card-body">
                <label className="control-label">検索条件</label>
                <input type="text" className="form-control" ref={searchKey} placeholder="名前" />
              </div>
              <div className="card-footer">
                <Button className="float-right" onClick={() => search()}>検索</Button>
                {' '}
                <Button variant="success" className="float-left" onClick={() => history.goBack()}>戻る</Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Row>
        <Col md="9">
          <div className="card">
            <div className="card-body p-0">
              <table className="table">
                <thead>
                  <tr>
                    <th>参列者名</th>
                    <th>参列日</th>
                    <th className="text-right">香典金額</th>
                    <th className="text-right">商品注文数</th>
                    <th>注文リスト</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    pagenatedList.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td>{row.UserName}</td>
                          <td>{moment(row.AttendeeDateTime).format("YYYY/MM/DD")}</td>
                          <td className="text-right">{parseInt(row.MoneyTotal,10).toLocaleString()}</td>
                          <td className="text-right">{row.orderList.length}</td>
                          <td><Link to={funeralId + "/" + row.Email}>注文リスト</Link></td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <Pagination per={PER_PAGE} list={attendeeUserList} onChange={(list) => setPagenatedList(list)} />
            </div>
          </div>
        </Col>
      </Row>
    </CommonLayout>
  );
};

const getAttendeeUserList = async (funeralId, keyword) => {
  const options = {
    queryStringParameters: {
      funeralId: funeralId,
      keyword: keyword
    }
  };

  return await API.get('product', '/users/attendee', options);
}

export default FuneralHistoryAttendeeList;