import React from 'react';
import { Row, Col } from 'react-bootstrap';

import ContentHeader from 'components/ContentHeader';

export const TableAttendeeSummary = ({ funeral }) => {
  return (
    <>
      <ContentHeader>
        会場参列者データ集計
      </ContentHeader>
      <Row>
        <Col>
          <div className="card">
            <div className="card-body">
              <table className="table table-bordered border-primary border-analytics">
                <tbody>
                  <tr>
                    <td className="table-secondary">　　</td>
                    <td className="table-secondary fs-5">参列予定数<br />（意思表示ボタン）</td>
                    <td className="table-secondary fs-5">参列実数<br />（受付数）</td>
                  </tr>
                  <tr>
                    <td className="fs-5">通夜</td>
                    <td className="fs-5">{funeral?.intendVigil}</td>
                    <td className="fs-5">{funeral?.attendVigil}</td>
                  </tr>
                  <tr>
                    <td className="fs-5">葬儀</td>
                    <td className="fs-5">{funeral?.intendFuneral}</td>
                    <td className="fs-5">{funeral?.attendFuneral}</td>
                  </tr>
                  <tr>
                    <td className="table-danger fs-5">両方</td>
                    <td className="table-danger fs-5">{funeral?.intendBoth}</td>
                    <td className="table-danger fs-5">{funeral?.attendBoth}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default TableAttendeeSummary;