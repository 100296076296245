import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { API } from 'aws-amplify';
import moment from 'moment';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import { useLoading } from 'components/Loading';

const Today = styled.div`
  font-size: 20px;
  font-weight: bold;
`

const Halls = styled.div`
  margin-bottom: 2rem;
`

export const Main = (props) => {
  const [today, setToday] = useState(null);
  const [halls, setHalls] = useState([]);
  const loading = useLoading();

  useEffect(() => {
    const fetch = async () => {
      if(!today) {
        loading(true);
        const todayFunerals = await getTodayFunerals(props.match.params.companyId);
        setToday(todayFunerals.today);
        setHalls(todayFunerals.halls);
        loading(false);
      }
    }
    fetch();
    // eslint-disable-next-line
  }, [])

  return (
    <CommonLayout>
      <ContentHeader>
        ダッシュボード
      </ContentHeader>
      <Row>
        <Col>
          <div className="callout callout-info">
            <Today>
              <i className="far fa-calendar"></i>　{ today }
            </Today>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                本日の葬儀
              </div>
              <div className="card-tools">
              </div>
            </div>
            <div className="card-body">
              {
                halls.length > 0 ?
                halls.map((h, j) => {
                  return (
                    <Halls key={j}>
                      <h4>{h.HallName}</h4>
                      { h.funerals.length > 0 ?
                        <table className="table table-sm">
                          <thead>
                            <tr>
                              <th className="fs-6">葬儀日時</th>
                              <th className="fs-6">部屋名</th>
                              <th className="fs-6">葬家名</th>
                              <th className="fs-6">担当者名</th>
                            </tr>
                          </thead>
                          <tbody>
                          { h.funerals.map((f, i) => {
                            return (
                              <tr key={i}>
                                <td className="fs-6">{moment(f.FuneralDateTime).format("HH時mm分")}</td>
                                <td className="fs-6">{f.Room}</td>
                                <td className="fs-6">{f.FamilyName}家</td>
                                <td className="fs-6">{f.UndertakerName}</td>
                              </tr>
                            )
                          }) }
                          </tbody>
                        </table>
                        : "今日は葬儀はありません"
                      }
                    </Halls>
                  )
                })
                :
                <p>葬儀会場が登録されていません</p>
              }
            </div>
          </div>
        </Col>
      </Row>
    </CommonLayout>
  );
};

const getTodayFunerals = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }
  return await API.get('product', '/funerals/today', options);
}



export default Main;