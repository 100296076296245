import React from 'react'
import '../css/Common.scss';
import AttendanceTermsContents from 'components/AttendanceTermsContents';

const AttendanceTerms = (props) => {
    
  return (
    <div>
      <AttendanceTermsContents />
    </div>
  )
}

export default AttendanceTerms  