import React, {useState, useEffect, useContext} from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import AttendeeLayout from 'components/AttendeeLayout';
import {AttendanceContext} from "../../components/Contexts";
import { useLoading } from 'components/Loading';
import {API} from "aws-amplify";

export const FuneralGiftPaymentSelect = (props) => {
    const { funeralInfo } = useContext(AttendanceContext);
    const [selectCard, setSelectCard] = useState(false);
    const [selectInvoice, setSelectInvoice] = useState(false);
    const [receiptNameAddress, setReceiptNameAddress] = useState('');
    const [invoiceNameAddress, setInvoiceNameAddress] = useState('');
    const [invoicePhoneNo, setInvoicePhoneNo] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory();
    const loading = useLoading();
    const funeralId = props.match.params.funeralId;

    useEffect(() => {
        // funeralInfoが存在しなければ取得できるまで待機する
        if (!funeralInfo) return;

        // 請求書払いが有効じゃなければそのままクレジットカード決済画面へ遷移する
        if (!funeralInfo.invoicePaymentCheck) {
            history.push("/" + funeralId + "/funeral/gift/payment")
        }

        localStorage.setItem("receiptName", localStorage.getItem("attendeeName"));
        setReceiptNameAddress(localStorage.getItem("attendeeName"));
        setInvoiceNameAddress(localStorage.getItem("attendeeName"));
        setInvoicePhoneNo(localStorage.getItem("attendeePhoneNo"));

        // eslint-disable-next-line
    }, [funeralInfo, funeralId]);

    const changeInvoice = () => {
        setSelectCard(false);
        setSelectInvoice(true);
    }

    const changeCard = () => {
        setSelectCard(true);
        setSelectInvoice(false);
    }

    const handlePayment = async () => {
        // カード払いの場合はそのまま遷移する
        if (selectCard && !selectInvoice) {
            history.push("/" + funeralId + "/funeral/gift/payment")
            return;
        } else if (!selectCard && !selectInvoice) {
            alert("支払い方法を選択してください。");
            return;
        }

        // SMS認証を送信する
        loading(true);
        const getSessionResult = await getSessionId();
        const sessionId = getSessionResult.data;
        const result = await sendVerifyCode(sessionId, invoicePhoneNo);
        if (result.error) {
            setErrorMessage(result.error.data.message);
            loading(false);
            return;
        }

        // 請求書払い用の名前と電話番号、SMS認証のセッションIDをlocalStorageへ保存する
        const localData = {
            invoiceNameAddress: invoiceNameAddress,
            invoicePhoneNo: invoicePhoneNo,
            sessionId: sessionId,
            authId: result.data
        }
        const localStorageKey = funeralId + 'sms';
        localStorage.setItem(localStorageKey, JSON.stringify(localData));

        // 請求書払いの認証コード入力画面へ遷移する
        history.push("/" + funeralId + "/funeral/gift/payment/invoice");
        loading(false);
    }

    const updateReceiptNameAddress = (value) => {
        localStorage.setItem("receiptName", value);
        setReceiptNameAddress(value);
    }

    return (
        <AttendeeLayout>
            <Helmet>
                <title>支払い方法の選択 | itowa</title>
            </Helmet>
            <h2 className="title-horizontal" style={{ width: "14rem" }}>支払い方法</h2>
            {
                errorMessage &&
                <div className="fs-3 error-message">
                    {errorMessage}
                </div>
            }
            <div className="paymentSelect">
                <div class="form-check">
                    <label className="fs-2"><input className="koudenPriceSelectButton" type="radio" name="paymentSelectButton" value="Unselected" onClick={() => changeCard()}/>　クレジットカード</label>
                </div>
                <br/>
                <div class="form-check">
                    <label className="fs-2"><input className="koudenPriceSelectButton" type="radio" name="paymentSelectButton" value="Unselected" onClick={() => changeInvoice()}/>　請求書払い</label>
                </div>
                {/* 請求書払い選択時のみ請求書の入力欄表示 */}
                { selectInvoice ?
                    <div className="invoiceInputField">
                        <label class="form-label fs-2 mt-4">請求書宛名</label>
                        <input type="email" className="form-control" placeholder="宛名入力欄" value={invoiceNameAddress} onChange={event => setInvoiceNameAddress(event.target.value)}></input>
                        <p className="fs-4">※発行後の宛名の修正は不可</p>
                        <br/>
                        <label class="form-label fs-2 mt-4">認証用電話番号</label>
                        <input type="email" className="form-control" placeholder="電話番号入力欄" value={invoicePhoneNo} onChange={event => setInvoicePhoneNo(event.target.value)}></input>
                        <p className="fs-4">※SMSが受信できる携帯番号のみ対応</p>
                    </div>
                    :
                    <div className="invoiceInputField">
                        <label class="form-label fs-2 mt-4">領収書宛名</label>
                        <input type="email" className="form-control" placeholder="宛名入力欄" value={receiptNameAddress} onChange={event => updateReceiptNameAddress(event.target.value)}></input>
                        <p className="fs-4">※発行後の宛名の修正は不可</p>
                    </div>
                }
                <div className="button-to-payment-wrapper">
                    <button type="button" className="btn-to-confirm mt-5" onClick={ handlePayment }>進む</button>
                    <span className="chevron-right"></span>
                </div>
            </div>
        </AttendeeLayout>
    )
}

const getSessionId = async () => {
    return await API.get('guest', '/sms/session', {});
}

const sendVerifyCode = async (sessionId, phoneNo) => {
    const options = {
        body: {
            session_id: sessionId,
            phone_number: phoneNo
        }
    }
    return await API.post('guest', '/sms/send-verify-code', options);
}

export default FuneralGiftPaymentSelect;
