import { useState, useEffect, useContext } from "react";
import AttendeeLayout from "components/AttendeeLayout";
import moment from "moment";
import { API } from "aws-amplify";
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from "../../components/Contexts";

export const Receipt = (props) => {
  const funeralId = props.match.params.funeralId;
  const { userInfo } = useContext(AuthContext);
  const [receiptList, setReceiptList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const fetch = async () => {
      const response = await getReceiptList(userInfo.email, funeralId);
      setReceiptList(response);
    };
    fetch();
    // eslint-disable-next-line
  },[]);
   
  const handleDownload = async (orderId) => {
    history.push(`/${funeralId}/attendance/receipt?orderId=${orderId}&openExternalBrowser=1`)
  }

  return (
    <AttendeeLayout>
      <Helmet>
        <title>領収書 | itowa</title>
      </Helmet>
      <div id="receipt">
        <h2 className="item-title">領収書</h2>
        <div className="receipt-list">
          {
            receiptList.map((receipt, index) => 
              <div key={index} className="receipt-item">
                <div>ご利用日：{moment(receipt.date).format("YYYY年M月D日")}</div>
                <div><button type="button" className="btn btn-primary black-button" onClick={() => handleDownload(receipt.orderId) }>領収書ダウンロード</button></div>
              </div>
            )
          }
        </div>
      </div>
    </AttendeeLayout>
  )
}

const getReceiptList = async (email, funeralId) => {
  const options = {
    queryStringParameters: {
      email: email
    }
  }
  return API.get('product', `/receipt/${funeralId}/list`, options);
}

export default Receipt; 