import { useState, useContext } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import '../css/Common.scss';
import { Auth, API } from 'aws-amplify';
import { AuthContext } from 'components/Contexts';
import { Modal } from 'react-bootstrap';

// TODO: Googleログインに関わるパッケージ
// import base64url from 'base64url';
// import sha256 from 'js-sha256';
// import { FUNERALS_WEB_URL, COGNITO_DOMAIN } from '../constants';
// import { awsConfig } from 'aws-config';

const getOptions = (funeralId) => {
  return {
    queryStringParameters: {
      funeralId: funeralId
    }
  };
}

export const LogIn = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [code, setCode] = useState("");
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [tooglePasswordType, setTooglePasswordType] = useState(true);
  const [loading, setLoading] = useState(false)
  const history = useHistory();
  const match = useRouteMatch("/:funeralId");
  const { setUserInfo } = useContext(AuthContext);

  const handleResendVerificationEmail = async () => {
    try {
      setLoading(true)
      await Auth.resendSignUp(email)
      setLoading(false)
    } catch (e) {
      setMessage('メールの送信に失敗しました。')
      setLoading(false)
    }
  }

  const handleConfirm = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      await Auth.confirmSignUp(email, code);
      setLoading(false)
      setShowVerificationModal(false)
      setMessage('')
    } catch (e) {
      setMessage("コードが正しくありません");
      setLoading(false)
    }
  }

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
      const user = await Auth.signIn(email, password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(user, password, user.userAttributes);
      }
      const userInfo = await API.get("product", "/auth", getOptions(match.params.funeralId));
      if (!userInfo.error) {
        setUserInfo(userInfo);
      }
      setLoading(false)

      // 利用規約のチェックが未完了の場合は利用規約のページへ飛ばす
      if (!userInfo.attendeeTermsConsents || userInfo.attendeeTermsConsents.TermsChecked !== true) {
        history.push(`/${match.params.funeralId}/attendanceterms`);
        return;
      }

      const url = sessionStorage.getItem('returnUrl');
      sessionStorage.removeItem('returnUrl');
      if (url) {
        history.push(url);
      } else {
        history.push(`/${match.params.funeralId}`);
      }
    } catch (e) {
      setLoading(false)
      if (e.name === "UserNotConfirmedException") {
        setMessage('')
        setShowVerificationModal(true)
      } else {
        setMessage('認証できませんでした');
      }
    }
    return false;
  }

  // TODO: Googleログインは認証フロー改善するまで一旦なし
  // const handleGoogleLogin = async () => {
  //   sessionStorage.setItem('funeralId', match.params.funeralId);
  //
  //   const get_code_challenge = (str) => {
  //     const hash = sha256.arrayBuffer(str);
  //     return base64url(hash);
  //   }
  //   const get_code_verifier = () => {
  //     let buf = Buffer.alloc(128);
  //     for (let i = 0; i < buf.length; i++) {
  //       const random_num = Math.floor(Math.random() * 256);
  //       buf.writeUInt8(random_num, i);
  //     }
  //     return base64url(buf);
  //   }
  //
  //   const codeVerifier = get_code_verifier();
  //   sessionStorage.setItem('code_verifier', codeVerifier);
  //   const codeChallenge = get_code_challenge(codeVerifier);
  //   window.location.href = `https://${COGNITO_DOMAIN}.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&response_type=code&client_id=${awsConfig.UserPoolClientId}&scope=openid%20email%profile&redirect_uri=${FUNERALS_WEB_URL}/login/redirect&code_challenge=${codeChallenge}&code_challenge_method=S256`;
  // }

  return (
    <div id="auth-main">
      <div id="auth-common">
        <img className="logo" src="/images/itowa-logo-square.jpg" alt="logo"></img>
        <div className="title">ログイン</div>
        <form onSubmit={handleLogin}>
          <div id="identity">
            <div className="form-group">
              <label className="control-label">Eメール</label>
              <div className="input-group">
                <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}></input>

              </div>
            </div>
            <div className="form-group">
              <label className="control-label">パスワード</label>
              <div className="input-wrapper">
                <input type={tooglePasswordType ? 'password' : 'text'} className="form-control" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                <button type="button" onClick={() => setTooglePasswordType((prevent) => !prevent )}>
                    {tooglePasswordType ? (
                      <img className="logo" src="/images/hide.png" alt="password" />
                    ) : (
                      <img className="logo" src="/images/view.png" alt="password" />
                    )}
                </button>
              </div>
            </div>
          </div>
          <div className="alert">
            {!showVerificationModal && <div className="msg">{message}</div>}
          </div>
          <div className="forget">
            <Link to="password-reset">パスワードを忘れた方</Link>
          </div>
          <div className="buttons">
            <button type="submit" className="btn btn-primary login-button" disabled={loading}>ログイン</button>
            {/* Googleアカウントでログインは一旦非表示　*/}
            {/*<button type="button" className="btn btn-primary google-button" onClick={handleGoogleLogin}>Googleアカウントでログイン</button>*/}
          </div>
        </form>
      </div>
      <Modal show={showVerificationModal} centered={true}>
        <Modal.Body style={{ textAlign: "center" }}>
          <h2 style={{ fontSize: "18px", textAlign: "center", fontWeight: "bold", margin: "20px" }} className="gothic">認証コードを入力してください。</h2>
          <label className="control-label gothic">メールに記載された6桁のコードを入力してください。</label>
          <input type="text" className="form-control" value={code} onChange={(e) => setCode(e.target.value)} maxLength={6} />
          {message && <div className="msg">{message}</div>}
          <div className="buttons">
            <button type="button" className="btn btn-primary black-button" onClick={handleConfirm} disabled={loading}>確認</button>
            <button type="button" className="btn btn-primary white-button" onClick={handleResendVerificationEmail} disabled={loading}>メールの再送</button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="regist">
        <span>新規登録がまだの方</span>
        <Link to="regist">新規登録</Link>
      </div>
    </div>
  )
}

export default LogIn;
