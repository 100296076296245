import { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { CSVLink } from 'react-csv'

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';

import { useLoading } from 'components/Loading';
import { useMessage } from 'components/Message';
import { isMobile } from 'utils/device';

import * as Constants from "../../constants";

export const SalesScore = () => {
  const loading = useLoading();
  const message = useMessage();

  const [label, setLabel] = useState([]);
  const [data, setData] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [csvData, setCsvData] = useState(null)

  const csvDownloaderRef = useRef(null)

  useEffect(() => {
    const fetch = async () => {
      try {
        loading(true);
        const response = await getAdminSalesScore();

        setLabel(response.label);
        setData(response.data);
        setCompanyList(response.company);

      } catch (e) {
        console.log(e);
      }
      loading(false)
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleDownload = async (monthString) => {
    if (csvDownloaderRef.current) {
      try {
        const res = await getOrdersListByMonth(monthString)
        setCsvData({
          filename: `${monthString}.csv`,
          data: res.orderDetails,
        })
      }
      catch (e) {
        // Show banner
        message.alert.danger('データ取得に失敗しました')
      }
    }
  }

  useEffect(() => {
    if (csvData) {
      csvDownloaderRef.current.link.click()
    }
  }, [csvData]);

  return (
    <CommonLayout>
      <ContentHeader>
        月別累計売上高
      </ContentHeader>

      <div className="card">
        <div className="card-body">
          <table className="table table-bordered table-sm">
            <thead>
              <th style={{ width: "20%" }} colSpan={2}></th>
              {
                label.map((l, i) => <th key={i} className="text-right">
                  { // 値が一つもなければダウンロードボタンを表示しない : 必要であれば修正
                    !isMobile && companyList?.length > 0 ? (
                      <Button className="btn btn-outline-info btn-sm btn-download"
                        onClick={() => handleDownload(l)}>ダウンロード</Button>
                    ) : null}
                  <div>{l}</div>
                </th>)
              }
            </thead>
            <tbody>
              {data !== {} &&
                companyList.map((company, j) => {
                  return (
                    <>
                      <tr key={"row1-" + j}>
                        <td rowSpan={10}>{company.CompanyName}</td>
                        <td>お供物売上</td>
                        {
                          data[company.CompanyId].map((d, i) => <td key={i} className="text-right">{parseInt(d.Kumotsu, 10).toLocaleString()}</td>)
                        }
                      </tr>
                      <tr key={"row2-" + j}>
                        <td>お供物振込金額</td>
                        {
                          data[company.CompanyId].map((d, i) => <td key={i} className="text-right">{parseInt(d.Kumotsu*(1-Constants.COMMISSION_RATE/100), 10).toLocaleString()}</td>)
                        }
                      </tr>
                      <tr key={"row3-" + j}>
                        <td>弔文売上</td>
                        {
                          data[company.CompanyId].map((d, i) => <td key={i} className="text-right">{parseInt(d.Telegram, 10).toLocaleString()}</td>)
                        }
                      </tr>
                      <tr key={"row4-" + j}>
                        <td>弔文振込金額</td>
                        {
                          data[company.CompanyId].map((d, i) => <td key={i} className="text-right">{parseInt(d.Telegram*(1-Constants.COMMISSION_RATE/100), 10).toLocaleString()}</td>)
                        }
                      </tr>
                      <tr key={"row5-" + j}>
                        <td>返礼品売り上げ</td>
                        {
                          data[company.CompanyId].map((d, i) => <td key={i} className="text-right">{parseInt(d.ReturnGift, 10).toLocaleString()}</td>)
                        }
                      </tr>
                      <tr key={"row6-" + j}>
                        <td>返礼品振込金額</td>
                        {
                          data[company.CompanyId].map((d, i) => <td key={i} className="text-right">{parseInt(d.ReturnGift * (1 - Constants.COMMISSION_RATE / 100), 10).toLocaleString()}</td>)
                        }
                      </tr>
                      <tr key={"row7-" + j}>
                        <td>香典発生金額</td>
                        {
                          data[company.CompanyId].map((d, i) => <td key={i} className="text-right">{parseInt(d.Kouden, 10).toLocaleString()}</td>)
                        }
                      </tr>
                      <tr key={"row8-" + j}>
                        <td>弔電報酬金額</td>
                        {
                          data[company.CompanyId].map((d, i) => <td key={i} className="text-right">{parseInt((d?.SagawaTelegram || 0) * 0.05, 10).toLocaleString()}</td>)
                        }
                      </tr>
                      <tr key={"row9-" + j}>
                        <td>売上</td>
                        {
                          data[company.CompanyId].map((d, i) => <td key={i} className="text-right">{parseInt(d?.SagawaTelegram || 0, 10).toLocaleString()}</td>)
                        }
                      </tr>
                      <tr key={"row10-" + j}>
                        <td>参列人数</td>
                        {
                          data[company.CompanyId].map((d, i) => <td key={i} className="text-right">{parseInt(d.Attendees, 10).toLocaleString()}</td>)
                        }
                      </tr>
                    </>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      <CSVLink
        ref={csvDownloaderRef}
        filename={csvData?.filename}
        data={csvData?.data || []}
        style={{ display: 'none' }}
      />
    </CommonLayout>
  )
}

// Expecting month to be 'yyyy-MM' format
const getOrdersListByMonth = async (month) => {
  const options = {
    queryStringParameters: {
      month: month
    }
  }
  return await API.get('product', '/orders/month', options);
}

const getAdminSalesScore = async () => {
  return await API.get('product', '/admin/statistics/score');
}

export default SalesScore;