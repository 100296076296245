import React, { useEffect } from 'react';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import { Row, Col, Button } from 'react-bootstrap';
import { useLoading } from 'components/Loading';
import Pagination from 'components/Pagination';
import useMessage from 'components/Message';

export const GiftManagement = (props) => {
  const PER_PAGE = 10;

  const [itemList, setItemList] = React.useState([]);
  const [paginatedList, setPaginatedList] = React.useState([]);
  const history = useHistory();
  const loading = useLoading();
  const message = useMessage();

  const createGift = () => {
    history.push("../gifts/new");
  }

  const editGift = (giftId) => {
    history.push("../gifts/" + giftId);
  }

  const handleDelete = (giftId) => {
    message.confirm.danger({
      title: "返礼品を削除",
      message: "削除してもよろしいですか？",
      onSubmit: async () => {
        await deleteGift("Swell", giftId);
        message.alert.success("返礼品を削除しました");
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    const search = async () => {
      loading(true);
      const result = await getGiftList();
      const resultList = result.itemList.sort((a, b) => a.Id < b.Id ? -1 : 1);
      setItemList(result.itemList);
      setPaginatedList(resultList.slice(0, PER_PAGE));
      loading(false);
    }

    search(props.match.params.itemId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CommonLayout>
      <ContentHeader>
        返礼品管理
      </ContentHeader>

      <div className="form-group">
        <Row>
          <Col>
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">登録返礼品一覧</h3>
                <div className="text-right">
                  <Button onClick={() => createGift()}>返礼品新規登録</Button>
                </div>
              </div>
              <div className="card-body">
                <Row>
                  <Col>
                    <div className="card">
                      <div className="card-body p-0">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>登録商品名</th>
                              <th>商品コード</th>
                              <th>価格</th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              paginatedList.map((row, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{row.GiftName}</td>
                                    <td>{row.GiftId}</td>
                                    <td>{parseInt(row.Price, 10).toLocaleString()}</td>
                                    <td className="text-right"><Button variant="light"  size="sm" onClick={() => editGift(row.GiftId)}><i className="fas fa-pencil"></i>編集</Button></td>
                                    <td className="text-right"><Button variant="danger" size="sm" onClick={() => handleDelete(row.GiftId)}><i className="fas fa-trash"></i></Button></td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                      <div className="card-footer clearfix">
                        <Pagination per={PER_PAGE} list={itemList} onChange={(list) => setPaginatedList(list)} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </CommonLayout>

  )
}

const getGiftList = async () => {
  return await API.get('product', '/gifts/swell');
}

const deleteGift = async (companyId, giftId) => {
  const options = {
    body: {
      companyId: companyId,
      giftId: giftId,
    }
  }
  return await API.del('product', '/gifts', options);
}

export default GiftManagement;
