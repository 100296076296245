export const Select = ({ className, name, formik, children, ...props }) => {
  const hasError = formik && formik.errors[name];

  return (
    <>
      <select name={name} className={className + (hasError ? " is-invalid" : "")} {...props} {...formik.getFieldProps(name)}>
        {children}
      </select>
      <div className="invalid-feedback">{formik.errors[name]}</div>
    </>
  )
}

export default Select;