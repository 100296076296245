import React, { useRef } from 'react';
import { API } from 'aws-amplify'
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Pagination from 'components/Pagination';

export const HistoryList = (props) => {
  const PER_PAGE = 10;

  const [userList, setUserList] = React.useState([]);
  const [pagenatedList, setPagenatedList] = React.useState([]);
  const searchKey = useRef("");

  const search = async () => {
    const result = await getUserList(props.match.params.companyId, searchKey.current.value);
    const resultList = result.userList.sort((a, b) => a.Id < b.Id ? -1 : 1);
    setUserList(result.userList);
    setPagenatedList(resultList.slice(0, PER_PAGE));
  }

  return (
    <CommonLayout>
      <ContentHeader>
        担当者別葬儀履歴
      </ContentHeader>

      <div className="form-group">
        <Row>
          <Col>
            <div className="card">
              <div className="card-body">
                <label className="control-label">検索条件</label>
                <input type="text" className="form-control" ref={searchKey} placeholder="名前" />
              </div>
              <div className="card-footer">
                <Button className="float-right" onClick={() => search()}>検索</Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Row>
        <Col>
          <div className="card">
            <div className="card-body p-0">
              <table className="table">
                <thead>
                  <tr>
                    <th>メールアドレス</th>
                    <th>名前</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    pagenatedList.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td><Link to={"histories/" + row.UserId}>{row.UserId}</Link></td>
                          <td>{row.UserName}</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <Pagination per={PER_PAGE} list={userList} onChange={(list) => setPagenatedList(list)} />
            </div>
          </div>
        </Col>
      </Row>
    </CommonLayout>
  );
};

const getUserList = async (companyId, keyword) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
      keyword: keyword,
      staff: true
    }
  };

  return await API.get('product', '/users/undertaker', options);
}

export default HistoryList;