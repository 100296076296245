import React, { useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { API } from 'aws-amplify';
import { useFormik } from 'formik';
import { ZipCode } from 'components/CustomForms/ZipCode';
import { Prefecture } from 'components/CustomForms/Prefecture';
import {AttendanceContext, AuthContext} from 'components/Contexts';

import Input from 'components/Input';
import AttendeeLayout from 'components/AttendeeLayout';
import { getCartGiftContents, removeCartGiftItem, getOrderGiftReturnSelected } from 'components/Cart';
import { CATEGORY_GIFT_LIST } from '../../constants';
import CartItem from 'components/CustomForms/CartItem';
import CartTotal from 'components/CartTotal';
import * as Yup from "yup";

export const FuneralGiftConfirm = (props) => {
  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);
  const { funeralInfo } = useContext(AttendanceContext);

  const [giftItemList, setGiftItemList] = useState([]);

  const formik = useFormik({
    initialValues: {
      familyAddressName: "",
      familyPhoneNo: "",
      familyZipCode: "",
      familyPrefecture: "",
      familyAddress1: "",
      familyAddress2: "",
      email: "",
      receiptName: "",
    },
    validationSchema: Yup.object({
      familyAddressName: Yup.string().required("氏名は必須です"),
      familyPhoneNo: Yup.string().required("電話番号は必須です"),
      familyZipCode: Yup.string().required("郵便番号は必須です"),
      familyPrefecture: Yup.string().required("都道府県は必須です"),
      familyAddress1: Yup.string().required("住所１は必須です"),
      email: Yup.string().required("メールアドレスは必須です"),
      receiptName: Yup.string().required("領収書宛名は必須です"),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        localStorage.setItem("ordererInfo", JSON.stringify(values));
        history.push("/" + funeralId + "/funeral/gift/payment")
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  const setFieldValue = formik.setFieldValue;

  React.useEffect(() => {
    if (!funeralInfo) return;
    setFieldValue("familyAddressName", funeralInfo.address.Name);
    setFieldValue("familyPhoneNo", funeralInfo.phoneNo);
    setFieldValue("familyZipCode", funeralInfo.address.ZipCode);
    setFieldValue("familyPrefecture", funeralInfo.address.Prefecture);
    setFieldValue("familyAddress1", funeralInfo.address.Address1);
    setFieldValue("familyAddress2", funeralInfo.address.Address2);
    setFieldValue("receiptName", funeralInfo.address.Name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funeralInfo]);

  React.useEffect(() => {
    if (!userInfo) return;
    setFieldValue("email", userInfo.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);


  React.useEffect(() => {
    const fetch = async () => {
      const cart = await cartInitialization(funeralId);
      setGiftItemList(cart[0]?.giftId !== undefined ? cart : []);
    }
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleChangeFamilyZipCode = (result) => {
    formik.setFieldValue("familyPrefecture", result.prefCode);
    formik.setFieldValue("familyAddress1", result.city.kanji + result.town.kanji);
  }

  const editItem = (item) => {
    if (item.orderId) {
      history.push(`../gift/select/${item.orderId}/?jpy=${item.priceCategory}`);
    } else {
      history.push(`../gift/select?jpy=${item.priceCategory}`);
    }
  }

  const deleteItem = async (index) => {
    await removeCartGiftItem(funeralId, giftItemList, index);
    const cart = await cartInitialization(funeralId);
    setGiftItemList(cart[0].giftId !== undefined ? cart : []);
  }

  return (
    <AttendeeLayout>
      <Helmet>
        <title>籠の中 | itowa</title>
      </Helmet>
      <form onSubmit={formik.handleSubmit}>
        <h2 className="item-title">籠の中</h2>
        <div id="cart">
          <div className="cart-panel">
            <div className="cart-item-panel">
              {
                giftItemList && giftItemList.map((item, index) =>
                  <CartItem key={index} item={item} onEdit={ () => editItem(item) } onDelete={() => deleteItem(index)}/>
                )
              }
            </div>

            <div className="cart-item-panel">
              <div className="card giftAddress">
                <div className="card-body">
                  <div className="form-group">
                    <span className="fs-4">注文者情報</span>
                    <br/>
                    <Row>
                      <Col>
                        <label className="control-label">氏名</label>
                        <Input type="text" name="familyAddressName" className="form-control" formik={formik} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="control-label">電話番号</label>
                        <Input type="text" name="familyPhoneNo" className="form-control" formik={formik} />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <Row>
                      <Col md="6">
                        <label className="control-label">郵便番号</label>
                        <ZipCode name="familyZipCode" className="form-control" formik={formik} onChange={handleChangeFamilyZipCode} />
                      </Col>
                      <Col md="6">
                        <label className="control-label">都道府県</label>
                        <Prefecture type="text" name="familyPrefecture" className="custom-select" formik={formik} />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <Row>
                      <Col md="12">
                        <label className="control-label">住所１</label>
                        <Input type="text" name="familyAddress1" className="form-control" formik={formik} />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <label className="control-label">住所２（建物名）</label>
                        <Input type="text" name="familyAddress2" className="form-control" formik={formik} />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <Row>
                      <Col md="12">
                        <label className="control-label">メールアドレス</label>
                        <p className='text-danger'>※領収書を送付するアドレスのため入力にご注意ください。</p>
                        <Input type="text" name="email" className="form-control" formik={formik} />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <Row>
                      <Col md="12">
                        <label className="control-label">領収書宛名</label>
                        <p className='text-danger'>※発行後の宛名の修正は不可</p>
                        <Input type="text" name="receiptName" className="form-control" formik={formik} />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className="cart-total-panel">
              <div className="group">
                <CartTotal giftItemList={giftItemList} type={'gift'}>
                  <button type="submit" className="btn btn-primary button-to-payment" disabled={!giftItemList.length}>ご購入手続きへ</button>
                </CartTotal>
              </div>
            </div>
          </div>
        </div>
      </form>
    </AttendeeLayout>
  );
};

const getOrderAttendeeList = async (funeralId, sortOrder = null) => {
  const options = {
    queryStringParameters: {
      funeralId: funeralId,
      sortOrder: sortOrder,
    }
  };
  return  await API.get('product', '/orders/attendee', options);
}

const cartInitialization = async (funeralId) => {
  try {
  const cart = await getCartGiftContents(funeralId);
  const result = await getOrderAttendeeList(funeralId);
  const orderSelected = await getOrderGiftReturnSelected(funeralId);
  CATEGORY_GIFT_LIST.forEach(jpy => {
    const targetIndex = cart.findIndex(item => item.priceCategory === jpy && item.orderId === null);

    if (targetIndex !== -1) {
      let targetCount = 0;
      
      if (result.groupedOrders && result.groupedOrders[jpy]) {
        const groupedOrders = result.groupedOrders[jpy]?.filter((order) => orderSelected?.includes(`${order?.OrderId}__${order?.ItemId}`));
        targetCount = groupedOrders.length;
        console.log(targetCount);
      }

      const individualCount = cart.filter(item =>
        item.priceCategory === jpy && item.orderId !== null
      ).length;

      const totalCount = targetCount - individualCount;
      cart[targetIndex].targetCount = totalCount;
      cart[targetIndex].totalPrice = cart[targetIndex].price * totalCount;
    }
  });
  return cart.filter(item => (item.targetCount !== 0 && item.orderId === null) || (item.targetCount === undefined && item.orderId !== null));
  } catch (e) {
    console.log(e);
    return []; // Return an empty array in case of error
  }
}

export default FuneralGiftConfirm;