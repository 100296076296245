import { useState, useRef, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal, ProgressBar } from 'react-bootstrap';
import { API } from 'aws-amplify';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import AttendeeLayout from 'components/AttendeeLayout';
import { Input } from 'components/Input';
import { useHistory } from 'react-router-dom';
import * as Constants from '../../constants';
import { AuthContext } from "../../components/Contexts";

const Empty = styled.div`
  background-color: #DDDDDD;
  display: flex;
  justify-content: center;
  align-items: center;
`

const EmptyText = styled.div`
  font-size: 16px;
`

const FILE_SIZE_LIMIT = 5000000;

const ErrorText = styled.div`
  color: #FF0000;
`

const PostItem = (props) => {
  const { name, index, formik } = props;
  const inputRef = useRef(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [shared, setShared] = useState(true);
  const [preview, setPreview] = useState(false);
  const previewPath = formik.values[name][index].image;
  const [error, setError] = useState(false);

  useEffect(() => {
    if (previewPath && previewPath.slice(0, 10) !== "data:image") {
      setFileUrl(Constants.IMAGE_BUCKET_URL + previewPath);
    }
    setShared(formik.values[name][index].shared);
    setFileName(formik.values[name][index].filename);
    setError(false);
    // eslint-disable-next-line
  }, [formik.values]);

  const fileUpload = () => {
    inputRef.current.click()
  }

  const handleOnChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size > FILE_SIZE_LIMIT) {
        setFileUrl(null);
        setFileName(null);
        setError(true)
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        formik.setFieldValue(`${name}.${index}.image`, reader.result);
        formik.setFieldValue(`${name}.${index}.filename`, e.target.files[0].name);
        setFileUrl(URL.createObjectURL(e.target.files[0]));
        setFileName(e.target.files[0].name);
        setError(false);
      }
    }
  }

  const handleShared = () => {
    formik.setFieldValue(`${name}.${index}.shared`, !shared);
    setShared(!shared);
  }

  return (
    <div className="photo-message-panel">
      <div className="photo-panel">
        <div className="photo">
          {fileUrl ?
            <img src={fileUrl} alt="" onClick={() => setPreview(true)} />
            :
            <Empty style={{ width: "100%", height: "100%", aspectRatio: "1/1" }}><EmptyText>Empty</EmptyText></Empty>
          }
        </div>
        <div className="control">
          {
            error ?
              <ErrorText>5MBを超えるファイルは<br/>アップロードできません</ErrorText>
            :
              <span>{fileName ? fileName : "ファイル未選択"}</span>
          }
          <Button variant="secondary" className="btn btn-sm upload-btn" onClick={fileUpload}>{fileUrl ? "ファイル変更" : "ファイル追加"}</Button>
          <input type="file" ref={inputRef} accept="image/*" hidden onChange={handleOnChange} />
          <div className="shared">
            <div className="check" onClick={handleShared}>
              <div className="circle"></div>
              <div className={"point" + (shared ? "" : " active")}></div>
            </div>
            <div onClick={handleShared}>公開しない</div>
          </div>
        </div>
      </div>
      <div className="message-panel">
        <label>一言メッセージ</label>
        <Input type="text" className="form-control" name={`${name}.${index}.message`} formik={formik} placeholder="20文字まで入力できます" maxLength={20}/>
      </div>
      <Modal id="attendee-modal" show={preview} size="xl" onHide={() => setPreview(false)} centered>
        <img className="photo-preview" src={fileUrl} alt="" />
      </Modal>
    </div>
  )
}

export const PhotoPost = (props) => {
  const { userInfo } = useContext(AuthContext);
  const [posted, setPosted] = useState(false);
  const [progress, setProgress] = useState(null);

  // [0, 1, ..., 9]の配列を作る
  const photoIndex = [...Array(10)].map((_, i) => i);

  const funeralId = props.match.params.funeralId;
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      photos: photoIndex.map(i => {
        return {
          image: "",
          filename: "",
          shared: true,
          message: ""
        }
      })
    },
    validationSchema: Yup.object({
      photos: Yup.array().of(
        Yup.object({
          message: Yup.string().max(20)
        })
      )
    }),
    onSubmit: async (values) => {
      setProgress(0);
      for(let i = 0 ; i < values.photos.length ; i++) {
        setProgress((i + 1) * 10);
        await postData(userInfo.email, funeralId, values.photos[i], i);
      }
      setProgress(null);
      setPosted(true);
    },
  })

  useEffect(() => {
    const fetch = async () => {
      const response = await getData(funeralId);
      if (response && response.length > 0) {
        response.map((photo, i) => {
          formik.setFieldValue(`photos.${i}.image`, photo.image);
          formik.setFieldValue(`photos.${i}.message`, photo.message);
          formik.setFieldValue(`photos.${i}.shared`, photo.shared);
          formik.setFieldValue(`photos.${i}.filename`, photo.filename);
          return photo;
        });
      }
    };
    fetch();
    // eslint-disable-next-line
  }, []);
  
  return (
    <AttendeeLayout>
      <div id="photo-post">
        <Helmet>
          <title>写真投稿 | itowa</title>
        </Helmet>
        {!posted ? 
        <div className="post-panel">
          <h3 className="title">写真を選択する</h3>
          <p className="p-message">
            故人との思い出を共有し、最後にメッセージを<br />
            お送りすることができます。
          </p>
          <p className="p-notice">
            ※メッセージとお写真はご遺族に閲覧いただきます。
          </p>
          <div className="upload-photos">
            <form onSubmit={formik.handleSubmit}>
              <div style={{ marginTop: "10rem" }}></div>
              <Row>
                {
                  photoIndex.map(i => 
                    <Col key={i} xl={6} lg={12} md={12} sm={12} xs={12} className="photo-block">
                      <PostItem name="photos" index={i} formik={formik} />
                    </Col>
                  )
                }
              </Row>
              <div className="buttons">
                <Button type="button" className="back-button" onClick={() => history.goBack()}>戻る</Button>
                <Button type="submit" className="post-button">送信する</Button>
              </div>
            </form>
          </div>
        </div>
        :
        <div className="post-panel">
          <h3 className="title">送信完了</h3>
          <p className="p-message">
              思い出の写真が葬家様に公開されました。<br/>
              葬家様が公開選択された写真が<br/>
              贈り物アルバムへ公開されます。
          </p>
          <div className="album-picture">
            <img className="album-image" src="/images/album.jpg" alt="" />
          </div>
          <div className="text-center">
            <Button type="submit" className="sent-button" onClick={() => history.push(`/${funeralId}/album`)}>アルバムを見る</Button>
          </div>
        </div>
        }
      </div>
      <Modal show={progress != null}>
        <Modal.Body>
          <div className="text-center" style={{fontSize:"1.2rem", marginBottom:"1rem"}}>送信しています</div>
          <ProgressBar now={progress * 10} animated/>
        </Modal.Body>
      </Modal>
    </AttendeeLayout>
  )
}

const getData = async (funeralId) => {
  return await API.get('product', `/photos/${funeralId}/shared`);
}

const postData = async (email, funeralId, photo, index) => {
  const options = {
    body: {
      email: email,
      index: index,
      photo: photo,
    }
  };
  await API.post('product', `/photos/${funeralId}/shared`, options);
}