export const MENU = (companyId) => {
  const prefix = "/" + companyId;
  
  const menu = [
    {
      icon: 'fas fa-tachometer-alt',
      name: 'ダッシュボード',
      path: '/main',
      staff: true
    },
    {
      icon: 'fas fa-users',
      name: '葬家管理',
      path: '/funerals',
      staff: true
    },
    {
      icon: 'fas fa-store-alt',
      name: '業者管理',
      path: '/suppliers'
    },
    {
      icon: 'fas fa-gifts',
      name: '商品管理',
      path: '/items',
    },
    {
      icon:'fas fa-gift',
      name:'返礼品管理',
      children: [
        {
          name:'返礼品一覧',
          path:'/gifts/list',
        },
        {
          name:'返礼品新規登録',
          path:'/gifts/new',
        },
      ]
    },
    {
      icon:'fas fa-calendar-plus',
      name:'スケジュール管理',
      staff: true,
      children: [
        {
          icon: 'fas fa-bars',
          name: '葬儀項目設定',
          path: '/time-schedule-items'
        },
        {
          icon: 'fas fa-bars',
          name: '葬儀テンプレート設定',
          path: '/time-schedule-template-list'
        },
      ]
    },
    {
      icon: 'far fa-clock',
      name: '操作ログ',
      path: '/accesslogs'
    },
    {
      icon: 'fas fa-chart-bar',
      name: 'データ集計',
      path: '/statistics',
      staff: true
    },
    {
      icon: 'far fa-list-alt',
      name: '担当者別葬儀履歴',
      path: '/histories',
      staff: true
    },
    {
      icon: 'far fa-list-alt',
      name: '月別累計売上高',
      path: '/salesscore',
      staff: true
    },
    {
      icon: 'fas fa-cogs',
      name: '設定',
      path: '',
      children: [
        {
          icon: 'fas fa-users',
          name: 'ユーザー管理',
          path: '/users'
        },
        {
          icon: 'fas fa-building',
          name: '葬儀社設定',
          path: '/companies'
        },
        {
          icon: 'fas fa-landmark',
          name: '葬儀会場設定',
          path: '/halls'
        },
      ]
    }
  ];

  return menu.filter(m => {
    if(m.children) {
      return m.children.filter(m => {
        m.path = prefix + m.path;
        return m;
      });
    } else {
      m.path = prefix + m.path
      return m;
    }
  });
}

export default MENU;