import React, { useEffect,useState } from 'react';
import AttendeeLayout from 'components/AttendeeLayout'
import { Button, Card, CardImg, Modal } from 'react-bootstrap';
import CardHeader from 'react-bootstrap/CardHeader';
import { useLoading } from 'components/Loading';
import { API } from 'aws-amplify';
import { GenderTypeToName } from 'components/GenderType';
import * as format from 'utils/format';
import { Img } from 'components/Img';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { setOrderGiftReturnSelected, getCartGiftContents } from "components/Cart";
import { Form } from 'react-bootstrap';

const GiftReturnPanel = ({orderList, giftList, sortAsc, sortDesc, listOrdersSelected, setListOrdersSelected}) =>{
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [selectedGiftValues, setSelectedGiftValues] = useState({});
  const [sortModalShow, setSortModalShow] = useState(false);
  const [isCondolenceGiftPriceSort, setIsCondolenceGiftPriceSort] = useState(true);

  const handleClickGiftEdit = (row, giftName, imagePath) =>{
    row.giftName = giftName;
    row.imagePath = imagePath;
    setSelectedGiftValues(row);
    setModalShow(true);
  }

  const CondolenceGiftPriceAsc　= () => {
    setSortModalShow(false)
    setIsCondolenceGiftPriceSort(true)
    sortAsc();
  }

  const CondolenceGiftPriceDesc = () => {
    setSortModalShow(false)
    setIsCondolenceGiftPriceSort(false)
    sortDesc();
  }

  const individualGiftSelect = (orderId, jpy) => {
    history.push(`../gift/select/${orderId}/?jpy=${jpy}`);
  }

  useEffect(() => {
    setListOrdersSelected(orderList?.map(item => `${item?.OrderId}__${item?.ItemId}`));

    // eslint-disable-next-line
  }, [orderList]);  

  const handleCheckItem = (e, orderId) => {
    if (e?.target?.checked) {
      setListOrdersSelected((prev) => {
        return [
          ...prev,
          orderId,
        ]
      });
    } else {
      setListOrdersSelected((prev) => {
        return prev?.filter(item => item !== orderId);
      });
    }
  }

  const renderOrderType = (ItemType) => {
    switch (ItemType) {
      case 'flower':
        return '（供花）';
      case 'things':
        return '（供物）';
      case 'money':
        return '（香典）';

      default:
        return '';
    }
  }

  return(
    <div className="gift-return-wrapper">
      <Card>
        <CardHeader>
          <div className="container">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="text-left">
                <div className="fs-4">香典を頂いたリスト一覧</div>
                <div className="fs-6">返礼品を送らない方はチェックを外して下さい。</div>
              </div>
              <div>
                <Button className="btn-dark" onClick={() => setSortModalShow(true)}>並び替え</Button>
                {' '}
                {/* TODO: 仕様不明、確定後実装 */}
                <Button className="btn-dark">検索</Button>
              </div>
            </div>
          </div>
        </CardHeader>
        <div className="row row-cols-1 row-cols-md-2 g-4">
        {
          orderList.map((row, index) => {
            const individualGift = giftList.find((item) => item.orderId === row.OrderId && item?.priceCategory.toString() === row?.PriceCategory.toString());
            const amountCategoryGift = giftList.find(item => item?.priceCategory?.toString() === row?.PriceCategory?.toString());
            /** @type {Gift} */
            const gift = individualGift ?　individualGift　: amountCategoryGift;
            return (
              <div className="col" key={index}>
                <Card className={`${listOrdersSelected?.includes(`${row?.OrderId}__${row?.ItemId}`) ? 'active': ''} card w-auto p-3 rear`} style={{ borderColor: "#bbb", padding: "0", marginTop: "1rem" }}>
                  <Img imgClassName={'photo-gift-circle img-fluid'} src={ gift ? gift.imagePath : '' }/>
                  <div className="text-left CondolenceGiftName"><p>{ gift ? gift.giftName : '' }</p></div>
                  <Card.Body className="text-left card-margin">
                    <div>
                      <p>名前： { row?.OrderLabel || row.UserName || '' }</p>
                      <p>年齢： { row.Age }歳</p>
                      <p>性別： { GenderTypeToName(row.Gender) }</p>
                      <p>住所： { row?.FromReception ? format.addressReception(row.Address) : format.address(row.Address) }</p>
                      <p>金額： { row.Price }円 {renderOrderType(row?.ItemType)}</p>
                    </div>
                  </Card.Body>
                  {/* 透過させたカードを重ねる */}
                  <Card className="frontCard w-100 card p-3 opacity-50" style={{ borderColor: "#bbb", padding: "0", marginTop: "1rem"}}>
                    <CardImg></CardImg>
                    <Card.Body></Card.Body>
                  </Card>
                    <div className="front d-grid col-4">
                      <Button type="button" className="btn btn-secondary" onClick={() =>
                        handleClickGiftEdit(
                          row,
                          gift　? gift.giftName : '',
                          gift ? gift.imagePath : ''
                        )
                      }>編集</Button>
                    </div>
                    <div className="checkbox-front">
                      <Form.Check
                        name="attendee"
                        type='checkbox'
                        checked={listOrdersSelected?.includes(`${row?.OrderId}__${row?.ItemId}`)}
                        id={`${row?.OrderId}__${row?.ItemId}`}
                        onChange={(e) => handleCheckItem(e, `${row?.OrderId}__${row?.ItemId}`)}
                      />
                    </div>
                </Card>
              </div>
            )
          })
        }
        </div>

        <Modal show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header closeButton style={{ borderBottomWidth: "0px", backgroundColor: "#FBFBFB" }}>
            <p>返礼品編集</p>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#FBFBFB" }}>
            <div className="text-center">
              <Img imgClassName={'photo-gift-circle img-fluid'} src={selectedGiftValues.imagePath}/>
            </div>
            <div className="text-center"><p>{ selectedGiftValues.GiftName }</p></div>
            <p>名前： { selectedGiftValues?.OrderLabel || selectedGiftValues.UserName || '' }</p>
            <p>年齢： { selectedGiftValues.Age }歳</p>
            <p>性別： { GenderTypeToName(selectedGiftValues.Gender)}</p>
            <p>住所： { selectedGiftValues?.FromReception ? format.addressReception(selectedGiftValues.Address) : format.address(selectedGiftValues.Address)}</p>
            <p>金額： { selectedGiftValues.Price }円 {renderOrderType(selectedGiftValues?.ItemType)}</p>
            <div className="text-center">
              <Button type="button" className="btn btn-secondary"　onClick={() => individualGiftSelect(selectedGiftValues.OrderId, selectedGiftValues?.PriceCategory)}>返礼品を変更する</Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal size="xl" show={sortModalShow} onHide={() => setSortModalShow(false)} centered={false}>
          <Modal.Header closeButton style={{borderBottomWidth: "0px", backgroundColor: "#FBFBFB"}}/>
          <p className='col-12 modal-title text-center'>並び替え</p>
          <Modal.Body style={{ backgroundColor: "#FBFBFB" }}>
            <ul className="list-group">
              <li className={`list-group-item ${isCondolenceGiftPriceSort ? "active" : ""}`} onClick={CondolenceGiftPriceAsc}>香典金額が低い順</li>
              <li className={`list-group-item ${isCondolenceGiftPriceSort ? "" : "active"}`} onClick={CondolenceGiftPriceDesc}>香典金額が高い順</li>
            </ul>
          </Modal.Body>
        </Modal>
      </Card>
    </div>
  );
}

export const CondolenceGiftReturnList = (props) => {
  const [orderList, setOrderList] = useState([]);
  const [giftList, setGiftList] = useState([{}]);
  const [sortOrder, setSortOrder] = useState('ASC');
  const [listOrdersSelected, setListOrdersSelected] = useState([]);

  const loading = useLoading();
  const { funeralId } = props.match.params;
  const history = useHistory();

  useEffect(() => {
    loading(true);

    const fetch = async () => {
      const cart = await getCartGiftContents(funeralId);
      setGiftList(cart);

      const result = await getOrderAttendeeList(funeralId, sortOrder);
      setOrderList(result.orderList);
      loading(false);
    }
    fetch();
  },[sortOrder, loading, funeralId]);

  const sortAsc = () => {
    setSortOrder('ASC');
  }

  const sortDesc = () => {
    setSortOrder('DESC');
  }

  const giftSettlement = () => {
    setOrderGiftReturnSelected(funeralId, listOrdersSelected);
    history.push("/" + funeralId + "/funeral/gift/confirm")
  }

  return (
    <AttendeeLayout adminPage={true}>
      <Helmet>
        <title>香典返しリスト管理 | itowa</title>
      </Helmet>
      <div className="content-header" style={{
        backgroundColor: "white",
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: "2rem",
        maxWidth: "77.5%",
        borderRadius: "1rem",
        marginBottom: "3rem",
        marginTop: "2rem",
      }}>
        香典返しリスト管理
      </div>
      <div className="d-grid gap-2 col-6 mx-auto">
        <button type="button" className="btn btn-lg btn-dark" onClick={() => giftSettlement()}>選択した返礼品の決済に進む</button>
        <p>ここでは決済は行われません</p>
      </div>
      <GiftReturnPanel
        orderList={orderList}
        giftList={giftList}
        listOrdersSelected={listOrdersSelected}
        setListOrdersSelected={setListOrdersSelected}
        sortAsc={sortAsc}
        sortDesc={sortDesc}
      />
    </AttendeeLayout>
  );
}

const getOrderAttendeeList = async (funeralId, sortOrder = null) => {
  const options = {
    queryStringParameters: {
      funeralId: funeralId,
      sortOrder: sortOrder,
    }
  };
  return  await API.get('product', '/orders/attendee', options);
}

export default CondolenceGiftReturnList;

/** @typedef {string} S3ImagePath */
/** @typedef {
 *      {
 *          itemName: string | undefined,
 *          price: number,
 *          imagePath?: S3ImagePath,
 *          giftId: string,
 *          itemType: string,
 *          priceCategory: string,
 *          userName: string | undefined,
 *          orderId: string | undefined,
 *          targetAttendeeId: string | targetAttendeeId,
 *          uuid: string
 *      }
 * } Gift */