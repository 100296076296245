import styled from 'styled-components';

const SpanLabel = styled.span`
  display: flex;
  align-items: center;
`

const TimelineLabel = (props) => {
  return (
    <div className="time-label">
      <span className="bg-gray">
        <SpanLabel>{props.children}</SpanLabel>
      </span>
    </div>
  )
}

export default TimelineLabel;