import { useEffect } from 'react';
import { API } from 'aws-amplify'
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Auth } from "aws-amplify";

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Input from 'components/Input';
import useMessage from 'components/Message';

export const UserEdit = (props) => {
  const companyId = props.match.params.companyId;
  const newEntry = props.method === "new";

  const history = useHistory();
  const message = useMessage();

  const formik = useFormik({
    initialValues: {
      userId: "",
      userName: "",
      phoneNo: "",
      employeeId: "",
      manager: "",
      staff: "",
      subscribeToEmails: ""
    },
    validationSchema: Yup.object({
      userId: Yup.string().required("メールアドレスは必須です").email("メールアドレスの書式ではありません"),
      userName: Yup.string().max(20, "20文字以下で入力してください").required("ユーザ名は必須です"),
      phoneNo: Yup.string().required("電話番号は必須です")
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        if (newEntry) {
          const result = await createUser(companyId, values.userId, values.userName, values.phoneNo, values.employeeId, values.manager, values.staff, values.subscribeToEmails);
          if (result.error) {
            if (result.error === "User exists") {
              message.alert.danger("葬儀社ユーザがすでに存在しています");
            } else {
              message.alert.danger("システムエラーが発生しました");
            }
          } else {
            message.alert.success("葬儀社ユーザを作成しました");
            history.goBack();
          }
        } else {
          await updateUser(companyId, values.userId, values.userName, values.phoneNo, values.employeeId, values.manager, values.staff, values.subscribeToEmails);
          message.alert.success("葬儀社ユーザを更新しました");
          history.goBack();
        }
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });
  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    const fetch = async (userId) => {
      const user = await getUser(props.match.params.companyId, userId);
      setFieldValue("userId", user.UserId);
      setFieldValue("userName", user.UserName);
      setFieldValue("phoneNo", user.PhoneNo);
      setFieldValue("employeeId", user.EmployeeId);
      setFieldValue("manager", user.Manager);
      setFieldValue("staff", user.Staff);
      setFieldValue("subscribeToEmails", user.SubscribeToEmails);
    }
    if (!newEntry) {
      fetch(props.match.params.userId);
    }
  }, [newEntry, props, setFieldValue]);

  const handleDelete = async () => {
    const userInfo = await Auth.currentAuthenticatedUser();
    if (userInfo.username === formik.values.userId) {
      message.alert.danger("自分自身を削除することはできません");
    } else {
      message.confirm.danger({
        title: "葬儀社ユーザを削除",
        message: "削除してもよろしいですか？",
        onSubmit: () => {
          deleteUser(companyId, formik.values.userId);
          message.alert.success("葬儀社ユーザを削除しました");
          history.goBack();
        }
      })
    }
  }

  const handleSendEmail = async () => {
    const { error } = await sendEmailUser(props.match.params.companyId, formik.values.userId);
    if (error) {
      message.alert.danger("メールを送信できませんでした。");
      return
    }
    message.alert.success("メールを送信しました。");
  }

  return (
    <CommonLayout>
      <ContentHeader>
        {newEntry ? "葬儀社ユーザ新規作成" : "葬儀社ユーザ編集"}
      </ContentHeader>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md="6">
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <label className="control-label">メールアドレス</label>
                  <Input type="text" name="userId" className="form-control" disabled={!newEntry} formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">ユーザ名</label>
                  <Input type="text" name="userName" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">電話番号</label>
                  <Input type="text" name="phoneNo" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">社員ID</label>
                  <Input type="text" name="employeeId" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <Input type="checkbox" name="manager" className="form-check-input" formik={formik} />
                    <label className="control-label">管理者</label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <Input type="checkbox" name="staff" className="form-check-input" formik={formik} />
                    <label className="control-label">担当者</label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <Input type="checkbox" name="subscribeToEmails" className="form-check-input" formik={formik} />
                    <label className="control-label">受注メールを受け取る</label>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div>
                  <Button variant="secondary" className="float-right" onClick={() => history.goBack()}>戻る</Button>
                  {
                    newEntry ?
                      <Button type="submit" variant="success">作成</Button>
                      :
                      <>
                        <Button type="submit" variant="success">更新</Button>
                        {' '}
                        <Button variant="danger" onClick={handleDelete}>削除</Button>
                        {' '}
                        <Button variant="info" onClick={handleSendEmail}>メールの再送</Button>
                      </>
                  }
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </form>

    </CommonLayout>
  );
};

const getUser = async (companyId, userId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }
  return await API.get('product', '/users/undertaker/' + userId, options);
}

const createUser = async (companyId, userId, userName, phoneNo, employeeId, manager, staff, subscribeToEmails) => {
  const options = {
    body: {
      companyId: companyId,
      email: userId,
      userName: userName,
      phoneNo: phoneNo,
      employeeId: employeeId,
      manager: (manager) ? true : false,
      staff: (staff) ? true : false,
      subscribeToEmails: (subscribeToEmails) ? true : false
    }
  }
  return await API.post('product', '/users/undertaker', options);
}

const updateUser = async (companyId, userId, userName, phoneNo, employeeId, manager, staff, subscribeToEmails) => {
  const options = {
    body: {
      companyId: companyId,
      email: userId,
      userName: userName,
      phoneNo: phoneNo,
      employeeId: employeeId,
      manager: (manager) ? true : false,
      staff: (staff) ? true : false,
      subscribeToEmails: (subscribeToEmails) ? true : false
    }
  }
  return await API.put('product', '/users/undertaker', options);
}

const deleteUser = async (companyId, userId) => {
  const options = {
    body: {
      companyId: companyId,
      email: userId,
    }
  }
  return await API.del('product', '/users/undertaker', options);
}

const sendEmailUser = async (companyId, userId) => {
  const options = {
    body: {
      email: userId,
      companyId: companyId
    }
  }
  return await API.post('product', '/mail/users/undertaker', options);
}

export default UserEdit;
