import React, { useState, useEffect, useContext } from 'react';
import { API } from 'aws-amplify';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useLoading } from 'components/Loading';
import { Helmet } from 'react-helmet-async';

import AttendeeLayout from 'components/AttendeeLayout';
import { PhotoGallery } from 'components/PhotoGallery';
import ConfirmReOrder from 'components/ConfirmReOrder';
import {AttendanceContext, AuthContext} from 'components/Contexts';
import {getBlob} from "../../utils/request";
import * as Constants from "../../constants";
import { saveAs } from 'file-saver';
import { isIOS } from 'utils/device';

export const AttendanceInvoiceComplete = (props) => {
  const [photoList, setPhotoList] = useState([]);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const { location } = props;
  const queryString = new URLSearchParams(location.search);

  const { userInfo } = useContext(AuthContext);
  const { funeralInfo } = useContext(AttendanceContext);

  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;
  const email = queryString.get("email");
  const orderId = queryString.get("orderId");
  const isOrderOnly = queryString.get("isOrderOnly") === "true";
  const prefix = "/" + match.params.funeralId;
  const history = useHistory();
  const loading = useLoading();

  useEffect(() => {
    const fetch = async () => {
      const response = await getTopPagePhoto(funeralId);
      setPhotoList(response.attendees);
    }
    if (userInfo) {
      fetch();
    }
    // eslint-disable-next-line
  }, [props, funeralId]);

  // 請求書のダウンロード
  const handleDownload = async (orderId) => {
    loading(true);
    const filename = `${funeralInfo.companyId}/invoice/${orderId}.pdf`;
    if (isIOS()) {
      window.location.href = Constants.IMAGE_BUCKET_URL + filename;
    } else {
      const pdfFile = await getBlob(Constants.IMAGE_BUCKET_URL + filename, { method: 'GET' });
      saveAs(pdfFile, '請求書.pdf');
    }

    loading(false)
  }

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.history.pushState(null, null, document.URL);
    setShowPopupConfirm(true);
  }

  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, []);

  useEffect(() => {
    if (confirmed) {
      history.push(`/${funeralId}/?re-order=1`);
    }

    // eslint-disable-next-line
  }, [confirmed]);

  return (
    <AttendeeLayout>
      <Helmet>
        <title>ご注文完了 | itowa</title>
      </Helmet>
      <div id="complete">
        <h2 className="item-title-horizontal">ご注文完了</h2>
        { userInfo
          ? <>
            <p className="thanks-message">
              ご注文いただきありがとうございます。 <br/>
              故人を大切にされてきた方々とアルバムを<br/>
              共有・閲覧することができます。
            </p>

            <div className="button-reorder-wrapper">
              <h5>再度注文される方はこちら</h5>
              {
                isOrderOnly
                  ? <button className="btn btn-primary" onClick={() => history.push("/" + funeralId + "/attendance/content/select/kumotsu?isOrderOnly=" + isOrderOnly)}>商品ページに戻る</button>
                  : <button className="btn btn-primary" onClick={() => history.push(`/${funeralId}/order`)}>トップページに戻る</button>
              }
            </div>

            <button className="btn btn-primary set-password-button" onClick={() => handleDownload(orderId)}>請求書ダウンロード</button>
            <PhotoGallery photos={photoList}/>
            <button className="btn btn-primary black-button show-album-button" onClick={() => history.push(`/${funeralId}/album`)}>もっと見る</button>
          </>
          : <>
            <p className="thanks-message">
              ご注文いただきありがとうございます。
            </p>
            <button className="btn btn-primary set-password-button" onClick={() => handleDownload(orderId)}>請求書ダウンロード</button>
            <div className="button-reorder-wrapper">
              <h5>再度注文される方はこちら</h5>
              {
                isOrderOnly
                  ? <button className="btn btn-light btn-go-to-home" onClick={() => history.push("/" + funeralId + "/attendance/content/select/kumotsu?isOrderOnly=" + isOrderOnly)}>商品ページに戻る</button>
                  : <button className="btn btn-light btn-go-to-home" onClick={() => history.push(`/${funeralId}/?re-order=1`)}>トップページに戻る</button>
              }
            </div>
            {
              !isOrderOnly &&
              <>
                <div className="complete-message-panel">
                  <div className="group">
                    <p className="complete-message-title">ログイン設定</p>
                    <p>パスワードを設定いただきますと、 下記の機能が使用可能となります。</p>
                    <ul className="account-feature-list">
                      <li>領収書のダウンロード</li>
                      <li>故人のアルバム共有・閲覧</li>
                      <li>再購入</li>
                    </ul>
                  </div>
                </div>
                <button className="btn btn-primary set-password-button" onClick={() => history.push(prefix + "/regist?email=" + email)}>ログインパスワード設定</button>
              </>
            }
          </>
        }
      </div>
      {showPopupConfirm && <ConfirmReOrder show={showPopupConfirm} setShow={setShowPopupConfirm} onConfirm={setConfirmed} />}
    </AttendeeLayout>
  );
};

const getTopPagePhoto = async (funeralId) => {
  return await API.get('product', `/photos/${funeralId}/top`);
}

export default AttendanceInvoiceComplete;