import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { FUNERALS_WEB_URL } from '../constants';

import { copyToClipboardQRCode } from "utils/copyToClipboard";

const FuneralQRCode = ({ showQr, setShowQr, funeralItem }) => {
  const handleCopyClick = async () => {
    await copyToClipboardQRCode(`${FUNERALS_WEB_URL}/${funeralItem?.FuneralId}/registration`);
  }

  return (
    <React.Fragment>
      <Modal show={showQr} centered={true} className="funeral-qr-code">
        <Modal.Header closeButton={true} onHide={() => setShowQr(false)}>
          <div style={{marginLeft: "auto", marginRight: "auto", paddingLeft: "calc(1rem + 32px)"}}>
            <Modal.Title>家族受付QR</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{marginLeft: "5%", marginRight: "5%"}}>
            <div style={{background: 'white', padding: '16px', textAlign: "center"}}>
              <QRCode
                size={256}
                style={{height: "auto", maxWidth: "75%", width: "75%"}}
                value={`${FUNERALS_WEB_URL}/${funeralItem?.FuneralId}/registration`}
                viewBox={`0 0 256 256`}
              />
            </div>
            <div style={{width: "100%", textAlign: "center"}}>
              <Button onClick={() => handleCopyClick()}
                      style={{
                        width: "120px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        border: "0.1rem solid rgba(170, 170, 170, 0.8)"
                      }}
                      variant={"secondary"}>
                URLコピー
              </Button>
            </div>
            <ul>
              <li>
                <img src="/images/icon-1.png" alt="" />
                <span>最初に読み込みをされた方が<br />【代表者様】となります</span>
              </li>
              <li>
                <img src="/images/icon-2.png" alt="" />
                <span>2番目以降の読み込みは<br />【ご家族様】となります</span>
              </li>
              <li>
                <img src="/images/icon-3.png" alt="" />
                <span>itowa公式LINEより<br />【葬儀情報】が通知されます</span>
              </li>
              <li>
                <img src="/images/icon-line.png" alt="" />
                <span className='primary'>必ずLINEのQRコードリーダーで<br />読み込みお願いします</span>
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default FuneralQRCode;
