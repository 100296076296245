import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import LPLayout from 'components/LPLayout';
import { Img } from 'components/Img';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import * as Constants from '../../constants';
import { Helmet } from 'react-helmet-async';
import CommonFooter from 'components/CommonFooter';
import * as format from 'utils/format';

export const HallInfo = (props) => {
  const [today, setToday] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [topImage, setTopImage] = useState("");
  const [bottomImage, setBottomImage] = useState("");
  const [hall, setHall] = useState(null);
  const [funeralList, setFuneralList] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const [searchName, setSearchName] = useState("");
  const history = useHistory();
  const companyId = props.match.params.companyId;
  const hallId = props.match.params.hallId;

  const [todayList, setTodayList] = useState([]);
  const [pastList, setPastList] = useState(null);
  const [homePageLink, setHomePageLink] = useState("");
  const [personalInfoLink, setPersonalInfoLink] = useState("");

  useEffect(() => {

    const fetch = async () => {
      const result = await getHallInfo(companyId, hallId);

      setCompanyName(result.company.CompanyName);
      setHall({
        hallId: hallId,
        hallName: result.hall.HallName,
        hallAddress: format.customAddress1(result?.hall?.Address) + result?.hall?.Address?.Address2 || ""
      });

      if(result.hall.ImagePath1) {
        setTopImage(result.hall.ImagePath1);
      }
      if (result.company.ImagePath2) {
        setBottomImage(result.company.ImagePath2);
      }
      setHomePageLink(result.company.HomePageLink);
      setPersonalInfoLink(result.company.PersonalInfoLink);

      let funeralList = result.funeralList;

      // 本日〜の葬式をまとめる
      let todayList = [];
      let filteredTodayList = funeralList.filter((row) => moment(row.FuneralDateTime).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD"));
      let groupedTodayList = groupBy(filteredTodayList, row => moment(row.FuneralDateTime).format("YYYY-MM-DD"));
      Object.keys(groupedTodayList).forEach(date => {
        todayList.push({
          date: moment(date).format("YYYY-MM-DD"),
          list: groupedTodayList[date],
        })
      });

      // 本日より前の葬式をまとめる
      let pastList = [];
      let filteredPastList = funeralList.filter((row) => moment(row.FuneralDateTime).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD"));
      let groupedPastList = groupBy(filteredPastList, row => moment(row.FuneralDateTime).format("YYYY-MM-DD"));
      Object.keys(groupedPastList).forEach(date => {
        pastList.push({
          date: moment(date).format("YYYY-MM-DD"),
          list: groupedPastList[date],
        })
      });

      setTodayList(todayList);
      setPastList(pastList);
      setFuneralList(todayList);
      setDisplayList(todayList);
    }
    fetch();
    // eslint-disable-next-line
  }, []);

  const switchRange = async (todayFlag) => {
    if (todayFlag === true) {
      setFuneralList(todayList);
      setDisplayList(todayList);
      setToday(true);
    } else {
      setFuneralList(pastList);
      setDisplayList(pastList);
      setToday(false);
    }
    setSearchName("")
  }

  const handleChangeName = async (e) => {
    setSearchName(e.target.value);
    let fl = [];
    funeralList.forEach((row) => {
      let list = [];
      list = row.list.filter((funeral) => funeral.DecreasedName.indexOf(e.target.value) > -1)
      if(list.length > 0) {
        fl.push({
          date: row.date,
          list: list
        });
      }
    });
    setDisplayList(fl);
  }

  return (
    <>
      {
        (companyName && hall) &&
        <LPLayout name={companyName}>
          <Helmet>
            <title>{hall.hallName} | {companyName} | itowa</title>
          </Helmet>
          <div id="hall-info">
            <div className="top-banner">
              {
                topImage && <Img src={topImage} />
              }
              <div className="top-banner-text">
                <div className="hall-name">
                  {hall.hallName}
                </div>
                <div className="hall-address">
                  ({hall.hallAddress})
                </div>
              </div>
            </div>
            <div className="range-select" onClick={() => switchRange(!today)}>
              <div>
                <div className="select-btn gothic">
                  <div className="text">{today ? "本日より前のお葬式はこちら" : "本日～のお葬式はこちら"}</div>
                  <div className="arrow">
                    <i className={"fas fa-chevron-right"}></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="title">お悔やみ情報</div>
            <p>
              現在公開中のお悔やみ情報です。<br/>
              公開期限内であればいつでも弔意を届ける事が可能です。
            </p>
            <div className="funerals">
              <Row>
                <Col xl={5} lg={0} md={0} sm={0} xs={0}></Col>
                <Col xl={7} lg={12} md={12} sm={12} xs={12} className="search">
                  <label className="control-label">名前検索：</label>
                  <input type="text" className="form-control" value={searchName} onChange={handleChangeName}></input>
                </Col>
              </Row>
              {
                displayList.map((date, di) =>
                  <div key={di} className="funeral-date-panel">
                    <div className="funeral-date">
                      {moment(date.date).format("YYYY.MM.DD")}
                    </div>
                    {
                      date.list.map((funeral, i) =>
                        <div className="funeral-panel" key={i} onClick={() => window.open(`${Constants.FUNERALS_WEB_URL}/${funeral.FuneralId}`)}>
                          <div className="funeral-info">
                            <div className="family">{funeral.FamilyName} 家</div>
                            <div className="name">{funeral.DecreasedName} 様</div>
                            <div className="address">{funeral.Address.Address1}</div>
                          </div>
                          <div className="arrow">
                            <i className={"fas fa-chevron-right"}></i>
                          </div>
                        </div>
                      )
                    }
                  </div>
                )
              }
            </div>
            <div>
              <button type="button" className="btn btn-primary go-back-button" onClick={() => history.goBack()}>トップページに戻る</button>
            </div>
            <div className="bottom-banner">
              <div className="homepage" onClick={() => window.open(homePageLink, 'homepage')}>{companyName}ホームページ</div>
              {
                bottomImage && <Img src={bottomImage} />
              }
            </div>
          </div>
          <CommonFooter privacyPolicyUrl={personalInfoLink} companyId={companyId} />
        </LPLayout>
      }
    </>
  );
};

const getHallInfo = async (companyId, hallId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }
  return await API.get('guest', '/halls/info/' + hallId, options);
}

// グルーピング用スクリプト
const groupBy = (array, getKey) => {
  return   array.reduce((obj, cur, idx, src) => {
    const key = getKey(cur, idx, src);
    (obj[key] || (obj[key] = [])).push(cur);
    return obj;
  }, {});
}

export default HallInfo;