import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify'
import { Row, Col, Button } from 'react-bootstrap';
import { useLoading } from 'components/Loading';
import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Pagination from 'components/Pagination';
import useMessage from 'components/Message';
import ModalFuneralTimeInput from 'components/FuneralItem/ModalFuneralTimeInput';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

export const FuneralTimeScheduleItem = (props) => {
  const PER_PAGE = 10;
  const companyId = props.match.params.companyId;
  const loading = useLoading();
  const [editedItem, setEditedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const message = useMessage();
  const [itemList, setItemList] = useState([]);
  const [pagenatedList, setPagenatedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const paginatedList = (resultList) => {
    // If the current page exceeds the valid range, navigate to the last page
    if (currentPage >= Math.ceil(resultList.length / PER_PAGE)) {
      const page = (Math.max(0, Math.ceil(resultList.length / PER_PAGE) - 1));
      setPagenatedList(resultList?.slice(page * PER_PAGE, (page + 1) * PER_PAGE));
    } else {
      setPagenatedList(resultList?.slice(currentPage * PER_PAGE, (currentPage + 1) * PER_PAGE));
    }
  };

  const fetch = async () => {
    loading(true);
    try {
      const result = await getScheduleItemList(companyId);
      setItemList(result?.scheduleItemList);
      paginatedList(result?.scheduleItemList);
    } catch (e) {
      if (e?.response?.status === 400) {
        message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
      } else {
        message.alert.danger("システムエラーが発生しました");
      }
    } finally {
      loading(false);
    }
  }

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openEditPopup = (item) => {
    setEditedItem(item);
    setShowModal(true);
  };

  const openAddPopup = () => {
    setShowModal(true);
    setEditedItem(null);
  };

  const closeEditPopup = () => {
    setEditedItem(null);
    setShowModal(false);
  };

  const saveEditedItem = async (newItemName) => {
    if (editedItem) {
      try {
        await updateScheduleItem(companyId, newItemName, editedItem?.Id);
        message.alert.success("商品を更新しました");
        fetch();
      } catch (e) {
        if (e?.response?.status === 400) {
          message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
        } else {
          message.alert.danger("システムエラーが発生しました");
        }
      }
    } else {
      try {
        await createScheduleItem(companyId, newItemName);
        message.alert.success("商品を作成しました");
        fetch();
      } catch (e) {
        if (e?.response?.status === 400) {
          message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
        } else {
          message.alert.danger("システムエラーが発生しました");
        }
      }
    }

    closeEditPopup();
  };


  const handleDelte = (id) => {
    message.confirm.danger({
      title: "葬家を削除",
      message: "削除してもよろしいですか？",
      onSubmit: async () => {
        try {
          await deleteScheduleItem(companyId, id);
          message.alert.success("葬家を削除しました");
          fetch();
        } catch (e) {
          if (e?.response?.status === 400) {
            message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
          } else {
            message.alert.danger("システムエラーが発生しました");
          }
        }
      }
    });
  }

  return (
    <CommonLayout>
      <ContentHeader>
        タイムスケジュール項目管理
      </ContentHeader>
      <Row>
        <Col>
          <div className="card funeral-time-item">
            <div className="card-body p-0">
              <table className="table">
                <thead>
                  <tr>
                    <th>項目名</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    pagenatedList.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td>{row.Name}</td>
                          <td className="space-btn">
                            <div className='actions'>
                              <EditOutlined onClick={() => openEditPopup(row)} />
                              <DeleteOutlined onClick={() => { handleDelte(row.Id) }} />
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <Button variant="success" className="float-left" onClick={openAddPopup}>新規作成</Button>
              <Pagination per={PER_PAGE} list={itemList} onChange={(list) => setPagenatedList(list)} setCurrentPage={setCurrentPage} />
            </div>
          </div>
        </Col>
      </Row>
      {showModal &&
        <ModalFuneralTimeInput
          editedItem={editedItem}
          onSave={saveEditedItem}
          onCancel={closeEditPopup}
          show={showModal}
        />}
    </CommonLayout>
  );
};

const getScheduleItemList = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
    }
  };

  return await API.get('product', '/schedule-item', options);
}

const createScheduleItem = async (companyId, name) => {
  const options = {
    body: {
      companyId: companyId,
      name: name
    }
  }
  return await API.post('product', '/schedule-item', options);
}

const updateScheduleItem = async (companyId, name, scheduleItemId) => {
  const options = {
    body: {
      companyId: companyId,
      name: name
    }
  }
  return await API.put('product', '/schedule-item/' + scheduleItemId, options);
}

const deleteScheduleItem = async (companyId, scheduleItemId) => {
  const options = {
    body: {
      companyId: companyId,
      scheduleItemId: scheduleItemId
    }
  }
  return await API.del('product', '/schedule-item', options);
}

export default FuneralTimeScheduleItem;