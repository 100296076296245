import React, { useContext, useEffect, useState, useRef } from 'react'
import { API } from 'aws-amplify';
import { AuthContext } from './Contexts';
import useLoading from "./Loading";

const TermsContents = () => {
  const [checked, setChecked] = useState(false);
  const { userInfo } = useContext(AuthContext);
  const loading = useLoading();
  const [checkDisabled, setCheckDisabled] = useState(true);
  const termsOfUseRef = useRef(null);

  const handleChanged = (event) => {
    setChecked(event.target.checked);
  }

  const handleContinue = async () => {
    loading(true);
    const companyId = userInfo.undertaker.CompanyId;
    await updateUndertakerTermsCheck(companyId, userInfo.email, checked);
    // TODO: history.pushだと何故か遷移しない (別ページからリダイレクトされてここに来た場合のみ)
    window.location.href = `/${companyId}/main`;
    loading(false);
  }

  useEffect(() => {
    const termsOfUse = termsOfUseRef.current;
    const handleScroll = () => {
      if (Math.abs(termsOfUse.scrollHeight - termsOfUse.clientHeight - termsOfUse.scrollTop) < 1) {
        setCheckDisabled(false);
      }
    };
    termsOfUse.addEventListener('scroll', handleScroll);
    return () => {
      termsOfUse.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="termsScroll" id="termsScroll">
      <br />
      <h4 className="text-center">本サービスを利用するには以下の規則に同意する必要があります。</h4>
      <h4 className="text-center text-danger">利用規約を最後までスクロールすると、同意ボタンにチェックできます。</h4>
      <div className="terms-text fs-5  terms-text--short" ref={termsOfUseRef}>
        <br />
        <p className="text-center fs-2">利用規約（葬儀会社及び供物等販売会社用）</p><br />
        <br />
        <p className="indent-1">　この利用規約（以下、「本規約」といいます。）は、株式会社itowa（以下、「当社」といいます。）が提供する「itowa」（以下、「本サービス」といいます。）の利用に関する条件を定めるものであり、本サービスを利用するすべての葬儀会社及び供物等販売会社（以下に定義する。）に適用されます。本サービスを利用する前に、本規約をよくお読みください。</p><br />
        <br />
        第１条（本規約への同意）<br />
        <br />
        <p className="indent-3">１　本規約は、会員と当社との間の本サービス利用に関わる一切の関係に適用されます。</p>
        <p className="indent-3">２　当社が、当社ウェブサイト又は本サービス上に本サービスに関して個別規定や追加規定を掲載する場合、それらは本規約の一部を構成するものとし、個別規定又は追加規定が本規約と抵触する場合には、当該個別規定又は追加規定が優先されるものとします。</p>
        <p className="indent-3">３　会員は、本規約に従って本サービスを利用するものとし、本規約に同意しない限り本サービスを利用できません。</p>
        <p className="indent-3">４　会員が本規約に同意した上で会員登録を完了した時点で、当該会員と当社との間で、本規約の諸規定に従った本サービスの利用に関するサービス利用契約（以下、「本利用契約」といいます。）が成立します。</p>

        <br />
        第２条（定義）<br />
        <br />
        <p className="indent-3">本規約において、次の各号に掲げる用語の意味は、当該各号に定めるとおりとします。</p>
        <p className="indent-3">(1)　「葬儀会社」　本規約に同意の上、本利用契約を締結した法人、団体、組合又は個人であって、葬家と葬儀を行う契約を締結し、その葬儀のために本サービスを利用する者</p>
        <p className="indent-3">(2)　「供物等販売会社」　本規約に同意の上、本利用契約を締結した法人、団体、組合又は個人であって、葬儀会社以外で第５条第１項に定義する供物等の受注を受ける者をいう。</p>
        <p className="indent-3">(3)　「葬家」　当社が別途定める利用規約（葬家・参列者・参列候補者用）に同意の上、葬儀会社と葬儀を行う契約を締結し、その葬儀のために当社が提供するサービスを利用する者</p>
        <p className="indent-3">(4)　「参列」　当社が参列者に対して提供するサービスを利用して、供物・供花・弔電の提供、香典の支払い等を行うこと</p>
        <p className="indent-3">(5)　「参列者」　葬家が行う葬儀に当社が提供するサービスを利用して参列した個人、法人その他の団体であって、葬家及びその親族以外の者　</p>
        <p className="indent-3">(6)　「会員」　本サービスを利用する葬儀会社又は供物等販売会社</p>
        <p className="indent-3">(7)　「アカウント」　会員が本サービスを利用するための権利又は当該権利を行使するための口座</p>
        <p className="indent-3">(8)　「参列候補者」　葬家が行う葬儀及び当社が提供するサービスを利用した参列に関する情報提供を受けた個人、法人その他の団体、若しくは、かかる個人、法人その他の団体からさらに当該情報の提供を受けた個人、法人その他の団体、又は、葬儀会社のウェブサイト等から葬家が行う葬儀のウェブページに直接アクセスして当該情報を得た個人、法人その他の団体</p>
        <p className="indent-3">(9)　「会員情報」　会員の住所、電話番号、代表者の氏名、メールアドレス等のプロフィールに関する情報</p>
        <p className="indent-3">(10)　「本サービス活用データ」　会員が本サービスを利用して送信又は保存するすべての電子的なデータ及び情報</p>
        <p className="indent-3">(11)　「統計情報」　会員情報又は本サービス活用データ（いずれも個人情報、仮名加工情報又は匿名加工情報に該当するものは除く）の属性集計・分析を行い、個人が識別・特定できないようにした統計的な情報</p>
        <p className="indent-3">(12)　「外部サービス」　当社が運営主体ではなく、外部の個人、法人その他の団体が運営主体であるサービス</p>

        <br />
        第３条（本規約の変更）<br />
        <br />
        <p className="indent-3">１　当社は、民法第５４８条の４に従い、以下のいずれかに該当する場合に、法令上認められる範囲内で本規約を変更できるものとします。</p>
        <p className="indent-4">　(1)　本規約の変更が、会員の一般の利益に適合するとき</p>
        <p className="indent-4">　(2)　本規約の変更が、本規約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。</p>
        <p className="indent-3">２　当社は、本規約を変更する場合、その効力発生時期を定めたうえで、本規約を変更する旨及び変更後の本規約の内容並び効力発生時期を当社が運営するウェブサイトその他相当の方法で公表又は会員に対して通知するものとし、当該変更はその効力発生日に効力を生じるものとします。</p>

        <br />
        第４条（通知）<br />
        <br />
        <p className="indent-3">１　当社は、本サービスに関連して会員に通知をする場合には、本サービス上の会員専用ページ又は会員専用アプリで通知する方法又は登録情報として登録された電子メールアドレス・住所に宛てて電子メール・文書を送信・送付する方法など、当社が適当と判断する方法で実施します。</p>
        <p className="indent-3">２　前項に定める方法により行われた通知は、前者の場合には通知内容が当社ウェブサイト若しくは本サービス上に掲示された時点で、後者の場合は当社が電子メール・文書が通常到達すべきであった時点で、それぞれその効力を生じるものとします。</p>
        <p className="indent-3">３　本サービスに関する問い合わせその他会員から当社に対する連絡又は通知は、当社の定める方法で行うものとします。</p>

        <br />
        第５条（本サービスについて）<br />
        <br />
        <p className="indent-3">１　会員は、本サービスにおいて、以下のサービスを利用することができます。詳細は個別の条項又は個別の契約において定めるものとします。</p>
        <p className="indent-4">　・供物、供花、弔電及び返礼品（以下、総称して「供物等」といいます。）の受注</p>
        <p className="indent-4">　・葬儀に関する情報の登録・管理（ただし、供物等販売会社の場合を除く。）</p>
        <p className="indent-4">　・葬儀会場における参列者の電子受付及び当該電子受付に必要な機器の貸与（ただし、当社の定める個別契約を締結した会員に限ります。）</p>
        <p className="indent-3">２　本サービスは、前項のサービスに係る業務・行為の効率化を支援するためのツールであり、会員の業務・行為の正確性、適法性、完全性を保証するものではありません。</p>
        <p className="indent-3">３　会員の業務・行為に関する最終的な判断は会員の判断によりますので、会員の業務・行為によって生じた不利益及び損害については会員自身が責任を負うものとし、当社は、一切の責任を負いません。ただし、当社に故意又は重過失がある場合はこの限りではありません。</p>

        <br />
        第６条（会員登録）<br />
        <br />
        <p className="indent-3">１　本サービスの利用を希望する者（以下、「申請者」といいます。）は、本規約を遵守することに同意し、当社の定める情報を当社の定める方法により、当社に提供することによって、会員登録をするものとします。</p>
        <p className="indent-3">２　申請者が以下の各号のいずれかに該当する場合には、当社は、会員登録を拒否し、又は当該申請者による利用を制限することがあります。</p>
        <p className="indent-3">　(1) 会員登録にあたって当社に提供された情報の全部又は一部につき、虚偽、誤り又は記載漏れがあった場合</p>
        <p className="indent-3">　(2) 申請者が、本サービスにつき利用停止措置を受けたことがあり、又は現在受けている場合</p>
        <p className="indent-3">　(3) 過去に本利用契約その他当社との間で締結した契約に関し、当該契約に定められた義務の履行を怠ったことがある場合、その他本利用契約上の義務の履行を怠るおそれがあると当社が判断した場合</p>
        <p className="indent-3">　(4) 第２４条第１項第１号に定義する反社会的勢力であるか、又は資金提供その他を通じて反社会的勢力の維持、運営若しくは経営に協力若しくは関与する等、反社会的勢力との何らかの交流若しくは関与を行っていると当社が判断した場合</p>
        <p className="indent-3">　(5) 申請者が非居住者である場合</p>
        <p className="indent-3">　(6) その他、当社が会員登録を適当でないと判断した場合</p>

        <br />
        第７条（ID等の管理）<br />
        <br />
        <p className="indent-3">１　会員は、自己の責任において本サービスのユーザＩＤ及びパスワード（以下、「ID等」といいます。）を管理・保管するものとし、これを会員以外の第三者に利用させ、又は貸与、譲渡、名義変更、売買等をしてはならないものとします。当社は、ID等の一致を確認した場合、当該アカウントを保有するものとして登録された会員が本サービスを利用したものとみなします。</p>
        <p className="indent-3">２　ID等の管理不十分又は第三者の使用等による損害の責任は、会員自身が負うものとし、当社は、当社に故意又は重過失がない限り、一切の責任を負いません。</p>
        <p className="indent-3">３　会員は、ID等が盗用され又は第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。</p>

        <br />
        第８条（供物等）<br />
        <br />
        <p className="indent-3">１　葬儀会社及び供物等販売会社は、本規約に同意することにより、供物等の注文を行った葬家、参列者又は参列候補者（以下、総称して「注文者」といいます。）が会員に対して支払う供物等の代金の代理受領権を、当社に対して授けるものとします。</p>
        <p className="indent-3">２　会員は、当社から当社の指定する決済代行業者（以下、「決済代行業者」といいます。）を通じて、前項の供物等の代金から決済代行業者の定める決済手数料及び当社の定める手数料を控除した残額を受け取るものとし、支払いに関する条件等は、決済代行業者と会員との間の契約及び当社の定める内容（別紙１）に従うものとします。</p>
        <p className="indent-3">３　当社が、決済代行業者を通じて注文者からの支払いを受けた時点で、注文者の会員に対する供物等の代金支払債務は履行されたものとして消滅し、会員は注文者に別途供物等の代金を請求することはできないものとします。</p>
        <p className="indent-3">４　第１項から第３項までは、会員が、当社所定の手続に従い、銀行口座への振込み等、クレジットカード以外での支払方法を指定した場合には、当該支払いに関しては適用しないものとします。この場合、振込手数料その他の支払いに係る費用は注文者の負担とし、会員が当社に対して負担する手数料は、当社の定める内容（別紙２）に従うものとします。</p>
        <p className="indent-3">５　供物等の代金の支払いに関し、注文者又はその他の第三者と会員との間で紛争が発生した場合は、当該当事者間において解決するものとします。当社が、会員のために、供物等の代金の請求書発行に関する業務を代行した場合も、同様とします。</p>
        <p className="indent-3">６　本サービス上で注文完了画面が表示された時点において、注文者と葬儀会社との間で、当該供物等の売買契約・弔電の送付契約（会員が弔電を印刷して葬家に交付します。）が成立したものとします。葬儀会社は、当該供物等の売買契約・弔電の送付契約の履行に関し、第三者に業務を委託することができるものとします。</p>
        <p className="indent-3">７　葬儀会社は、前項の契約成立後、供物等を当該契約において定める時期及び方法により注文者に引き渡すものとします。供物等の引き渡しに関して葬儀会社と葬家との間で紛争が発生した場合は、当該当事者双方で解決するものとし、当社は責任を負わないものとします。本利用契約（終了の理由は問いません。）が終了した場合においても、葬儀会社は、成立した前項の契約を、責任をもって履行するものとします。</p>

        <br />
        第９条 （税金関係）<br />
        <br />
        <p className="indent-1">本サービスの利用に関し会員に発生する課税及び会員における本サービスに関連する税務上の取扱い(会員と葬家、参列者又は参列候補者との間の取引等に関連する取扱いを含みます。)について当社は一切関知しないものとし、会員が自らの責任において対応し、処理するものとします。</p>

        <br />
        第１０条（写真）<br />
        <br />
        <p className="indent-1">当社が提供するサービスを通じて葬家又は参列者がアップロードした写真は、会員である供物等販売会社はこれを閲覧できないものとし、葬家の承諾がない限り、会員である葬儀会社もこれを閲覧することができないものとします。</p>

        <br />
        第１１条（権利の帰属・利用）<br />
        <br />
        <p className="indent-3">１　本サービスにおいて、当社から提供される情報等に関する⼀切の知的財産権は当社又は当社にライセンスを許諾している者に帰属し、本規約によって会員が当社又は当社にライセンスを許諾している者の知的財産権を取得し、又は使用許諾されることはありません。</p>
        <p className="indent-3">２　会員は、当社の許諾を得ずに、当社が提供する情報等の翻訳、編集及び改変等を行い、又は第三者に使用し若しくは公開することはできず、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）をしてはなりません。</p>
        <p className="indent-3">３　本サービス上には商標、ロゴ及びサービスマーク等（以下、総称して「商標等」といいます。）が表示される場合がありますが、当社は、会員その他の第三者に対し何ら当該商標を譲渡し、又は使用を許諾するものではありません。</p>
        <p className="indent-3">４　会員は、会員情報を含む本サービス活用データについて、自らが送信又は保存等することについての適法な権利を有していること、及び送信等データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。</p>
        <p className="indent-3">５　会員は、本サービス活用データ(統計情報又は匿名加工情報に加工されたものに限ります。)について、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。</p>
        <p className="indent-3">６　会員は、当社及び当社から権利を承継し又は許諾された者に対して著作者人格権を行使しないことに同意するものとします。</p>

        <br />
        第１２条（外部サービス）<br />
        <br />
        <p className="indent-3">１　当社は、当社が指定する外部サービスと連携して本サービスを提供することができるものとします。</p>
        <p className="indent-3">２　外部サービスについては、当該サービスを提供する事業者が責任を負うものとし、当社は、外部サービスにつき、会員の特定の目的への適合性、商品的価値、正確性、有用性、完全性、適法性、会員に適用される団体の内部規則等への適合性、及びセキュリティ上の欠陥、エラー、バグ又は不具合が存しないこと、並びに第三者の権利を侵害しないことについて、いかなる保証も行うものではありません。</p>

        <br />
        第１３条（競合事業者における使用）<br />
        <br />
        <p className="indent-1">会員は、当社と競合する事業者その他本サービスと競合する事業を運営する可能性のある第三者にアカウントやパスワードを伝えて本サービスを使用させてはならないものとします。</p>

        <br />
        第１４条 （禁止行為）<br />
        <br />
        <p className="indent-1">会員は、本サービスの利用にあたり、自ら又は第三者をして、以下の各号のいずれかに該当する行為をしてはならず、また、以下の各号の行為を直接又は間接に惹起し、又は容易にしてはなりません。</p>
        <p className="indent-4">(1) 法令又は公序良俗に違反する行為、又はそのおそれのある行為</p>
        <p className="indent-4">(2) 本サービスの誤作動を誘引する行為、又はそのおそれのある行為</p>
        <p className="indent-4">(3) 法令、裁判所の判決、決定若しくは命令、又は法令上拘束力のある行政措置に違反する行為及びこれらを助長する行為又はそのおそれのある行為</p>
        <p className="indent-4">(4) 本サービスを逆アセンブル、逆コンパイル、リバースエンジニアリング、その他本サービスのソースコードを解析する行為</p>
        <p className="indent-4">(5) スクレイピング、クローリング（クローラ、ロボット又はスパイダー等のプログラム）及びその他の類似の手段によって本サービスにアクセスし、又は本サービスに関する情報を取得する行為</p>
        <p className="indent-4">(6) 本サービスに接続されたシステムに権限なく不正にアクセスし又は当社設備に蓄積された情報を不正に書き換え若しくは消去する行為</p>
        <p className="indent-4">(7) 本サービスを複製、譲渡、貸与又は改変する行為</p>
        <p className="indent-4">(8) 当社、他の会員その他の第三者に成りすます行為</p>
        <p className="indent-4">(9) 同一のアカウントを複数の葬儀会社又は供物等販売会社で利用する行為</p>
        <p className="indent-4">(10) 同一の会員が複数のアカウントを利用する行為</p>
        <p className="indent-4">(11) 容易に推測されるパスワードの使用、パスワードの使い回し</p>
        <p className="indent-4">(12) 本規約及び本サービスの趣旨・目的に反する行為</p>
        <p className="indent-4">(13) 第２４条第１項第１号に定義する反社会的勢力への利益供与行為</p>
        <p className="indent-4">(14) 当社に虚偽の情報（故人及び葬儀に関する情報を含むが、これに限られない。）を提供する行為</p>
        <p className="indent-4">(15) 第５条第１項に掲げるサービスを利用する目的以外の目的で本サービスを利用</p>
        <p className="indent-4">(16) その他、前各号に準じる場合であって、当該行為の態様及び頻度等を考慮して、本サービスの利用に著しく支障があるものであると当社が合理的に判断する行為     </p>

        <br />
        第１５条（葬家・参列者・参列候補者の個人情報の利用の制限）<br />
        <br />
        <p className="indent-1">会員は、当社から提供する葬家、参列者及び参列候補者の個人情報（住所、氏名、電話番号、メールアドレス等を含みます。）について、第５条第１項に掲げるサービスを利用する目的（葬家、参列者及び参列候補者に対する供物等の発送、領収書・請求書発行等に関する業務及び問い合わせ対応を含み、会員自身の事業のための新規会員獲得や宣伝広告等は含まれないものとします。）以外の目的のために利用することはできません。</p>

        <br />
        第１６条 （規約違反の場合の措置等）<br />
        <br />
        <p className="indent-3">１　当社は、会員が次の各号の一に該当し又は該当するおそれがあると当社が合理的に判断した場合には、相当期間を定めて催告し、会員が当該期間内に是正しない場合には（ただし、第４号から第１６号に該当する場合については、何らの催告なく）、当該会員に対し、本サービスの利用の一時停止若しくは制限、アカウントの削除又は本利用契約の解除等の措置（以下、「利用停止等」といいます。）を講じることができるものとします。</p>
        <p className="indent-4">(1) 本規約のいずれかの条項に違反した場合</p>
        <p className="indent-4">(2) 会員が本規約に基づいて負担する債務の履行を遅滞し又は怠った場合</p>
        <p className="indent-4">(3) 供物等の提供を遅滞し又は怠った場合</p>
        <p className="indent-4">(4) 当社に提供された情報（故人及び葬儀に関する情報を含むが、これに限られない。）の全部又は一部につき虚偽の事実があることが判明した場合</p>
        <p className="indent-4">(5) 重要な資産につき差押え、仮差押え又は競売の申立てがあったとき</p>
        <p className="indent-4">(6) 租税滞納処分を受けたとき</p>
        <p className="indent-4">(7) 支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始若しくはこれらに類する手続の開始の申立てがあった場合</p>
        <p className="indent-4">(8) 清算に入ったとき</p>
        <p className="indent-4">(9) 事業を停止又は中止したとき</p>
        <p className="indent-4">(10) 解散を決議し、又は解散したとき</p>
        <p className="indent-4">(11) 当社からの問い合わせその他の回答を求める連絡に対して３０日又は別途当社が指定した期間を超えて応答がない場合</p>
        <p className="indent-4">(12) 本サービスの利用に際して、過去に利用停止等の措置を受けたことがあり又は現在受けている場合</p>
        <p className="indent-4">(13) 第２５条第２項各号に定める場合</p>
        <p className="indent-4">(14) 第５条第１項に掲げるサービスを利用する目的以外の目的で本サービスを利用した場合</p>
        <p className="indent-4">(15) 本サービスの運営、保守管理上必要であると当社が合理的に判断した場合</p>
        <p className="indent-4">(16) その他前各号に類する事由であって、当該事由の態様及び頻度等を考慮して、本サービスの利用に著しく支障があるものと当社が合理的に判断した場合</p>
        <p className="indent-4">２　会員が前項各号のいずれかに該当し、前項に定める相当期間内に是正しない場合（ただし、第４号から第１６号に定める場合については、当該各号に該当した場合）、当該会員は、期限の利益を失い、当社及び第三者に対して本規約に基づいて負担する一切の義務及び債務（損害賠償債務を含みますが、これに限られません。）を直ちに履行しなければなりません。</p>
        <p className="indent-4">３　当社は、本条に基づき当社が行った行為により会員に生じた損害について責任を負わず、利用停止等後も、当該会員に関し当社が取得した情報を保有・利用することができるものとします。</p>

        <br />
        第１７条（本サービスの利用終了）<br />
        <br />
        <p className="indent-1">会員は、アカウントの削除その他当社の定める方法により、本サービスの利用を終了することができます。本サービスの利用を終了した会員は、利用終了の時点から本サービスを利用することができなくなります。</p>

        <br />
        第１８条（本サービスの変更・中断・終了等）<br />
        <br />
        <p className="indent-3">１　当社は、会員に事前に通知することなく、本サービスの内容の全部又は一部を変更又は追加することができるものとします。</p>
        <p className="indent-3">２　当社は、事前に、本サービス上への掲示その他当社が適当と判断する方法で会員に通知することにより、当社の裁量で、本サービスを終了することができるものとします。ただし、緊急の場合は会員への通知を行わずに、当社の裁量で本サービスを終了することができます。</p>
        <p className="indent-3">３　当社は、以下の各号に掲げる事由の一が生じた場合には、会員に事前に通知することなく、本サービスの一部又は全部を一時的に中断することができるものとします。</p>
        <p className="indent-4">(1) 本サービス用の通信機器設備等に関わるメンテナンスや修理を定期的又は緊急に行う場合</p>
        <p className="indent-4">(2) アクセス過多、その他予期せぬ要因でシステムに負荷が集中した場合</p>
        <p className="indent-4">(3) 会員のセキュリティを確保する必要が生じた場合</p>
        <p className="indent-4">(4) 本サービスの提供に必要な電気通信事業者の役務が提供されない場合</p>
        <p className="indent-4">(5) 天災等の不可抗力により本サービスの提供が困難な場合</p>
        <p className="indent-4">(6) 火災、停電、その他の不慮の事故又は戦争、紛争、動乱、暴動、感染症（未知のものを含みます。）、労働争議等により本サービスの提供が困難な場合</p>
        <p className="indent-4">(7) 法令又はこれらに基づく措置により本サービスの運営が不能となった場合</p>
        <p className="indent-4">(8) その他前各号に準じる事由が生じ、当社が合理的に必要と判断した場合</p>
        <p className="indent-3">４　当社は、本条に基づき当社が行った措置により会員に生じた損害について責任を負いません。</p>

        <br />
        第１９条（損害賠償）<br />
        <br />
        <p className="indent-3">１　会員による本規約違反行為その他本サービスの利用に起因して、当社に直接又は間接の損害が生じた場合（当該行為が原因で、当社が第三者から損害賠償請求その他の請求を受けた場合を含みます。）、会員は、当社に対し、その全ての損害（弁護士等専門家費用及び当社において対応に要した人件費相当額を含みます。）を賠償しなければなりません。</p>
        <p className="indent-3">２　当社は、本サービスの利用に関連して会員が被った損害について、責任を負いません。ただし、当社に故意又は重過失がある場合はこの限りではありません。     </p>

        <br />
        第２０条 （保証の否認及び免責）<br />
        <br />
        <p className="indent-3">１　当社は、商品、本サービス、その他本サービス上に表示された一切の情報につき、会員の特定の目的への適合性、商品的価値、正確性、有用性、完全性、適法性、会員に適用のある団体の内部規則等への適合性を有すること、及びセキュリティ上の欠陥、エラー、バグ又は不具合が存しないこと、並びに第三者の権利を侵害しないことについて、本規約で定めるほかは如何なる保証も行うものではありません。</p>
        <p className="indent-3">２　当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、会員はあらかじめ承諾するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。</p>
        <p className="indent-3">３　当社は、原則として会員間の通信や活動に関与しません。万一会員間又は会員と葬家、参列者又は参列候補者との間で紛争や問題が生じた場合には、当該会員間又は会員と葬家、参列者又は参列候補者の責任と費用でこれを解決するものとし、当社はこれに一切関与しません。</p>
        <p className="indent-3">４　本サービスの利用に関連して会員と第三者との間で紛争が生じた場合には、会員は自己の責任と費用でこれを解決するものとし、当社はこれに一切関与しません。</p>
        <p className="indent-3">５　本サービスは、第５条第１項に掲げる業務等の効率化の支援を目的とするものであって、業務等の正確性、適法性、完全性を保証するものではありません。</p>

        <br />
        第２１条 （権利義務の譲渡禁止）<br />
        <br />
        <p className="indent-3">１　会員は、当社の書面による事前の承諾がある場合を除き、本利用契約に基づく会員の権利若しくは義務、又は本利用契約上の地位について、第三者への譲渡、承継、担保設定、その他一切の処分をすることはできません。</p>
        <p className="indent-3">２　当社が、本サービスにかかる事業の譲渡又は当社が消滅会社若しくは分割会社となる合併若しくは会社分割等（以下、「事業譲渡等」といいます。）により本サービスに係る事業を個別承継又は包括承継させたときは、当社は、当該事業譲渡等に伴い、本サービスに関する本利用契約上の地位、権利及び義務並びに会員情報その他の会員に関する情報を当該事業譲渡等の譲受人又は承継人に譲渡することができるものとし、会員は、予めこれに同意するものとします。</p>

        <br />
        第２２条 （委託）<br />
        <br />
        <p className="indent-1">当社は、本サービスに関する業務の一部又は全部を第三者に委託することができるものとします。</p>

        <br />
        第２３条（秘密保持）<br />
        <br />
        <p className="indent-3">１　会員は、本サービスに関して知り得た当社の情報（以下、「本情報」といいます。）について、秘密として扱うものとし、善良な管理者の注意をもって     適切な方法で管理し、本利用契約の目的以外に使用せず、当社の事前の書面による同意を得ない限り、第三者に開示又は遺漏してはならないものとします。</p>
        <p className="indent-3">２　前項の規定にかかわらず、本情報には、次の各号に該当する情報は含まれないものとします。</p>
        <p className="indent-4"> (1) 会員が本サービスに関して本情報を知り得た時点で既に公知となっていた情報</p>
        <p className="indent-4"> (2) 会員が本サービスに関して本情報を知り得た後に受領者の責めに帰すべき事由によらずに公知となった情報</p>
        <p className="indent-4"> (3) 会員が本サービスに関して本情報を知り得た時点で受領者が既に保有していた情報</p>
        <p className="indent-4"> (4) 会員が本サービスに関して本情報を知り得た後に受領者が正当な権限を有する第三者からの秘密保持義務を自ら負うことなく開示された情報</p>
        <p className="indent-3">３　本情報が不要となったときは、会員は遅滞なくこれらを当社に返還又は当社の指示に従った処置を行うものとする。</p>
        <p className="indent-3">４　会員が第１項に違反した場合には、当社は、直ちに本利用契約を解除することができるものとし、当該会員は、当社に対し、当該違反に起因して直接又は間接に当社に生じた損害を賠償するものとする。     </p>

        <br />
        第２４条（情報管理）<br />
        <br />
        <p className="indent-3">１　本サービスの利用に関連して会員から送信等される会員に関する情報及び本サービス活用データの取扱いについては、別途、当社の定める当社プライバシーポリシー、個人情報保護法及びその他関連する法令等を遵守し、情報の適切な取扱い及び保護に努めます。</p>
        <p className="indent-3">２　当社は、会員情報について、会員の事前の同意を得ずに第三者に開示しません。ただし、次の各号に掲げる場合はこの限りではありません。会員は、本規約に同意することにより、次の各号に掲げる場合に当社が会員情報を当該各号に掲げる第三者に提供することに同意するものとします。</p>
        <p className="indent-4">(1) 法令に基づき又は公的機関からの要請を受け、開示に応じる必要を認めた場合</p>
        <p className="indent-4">(2) 人の生命、身体又は財産の保護のために必要があり、かつ会員の同意を得ることが困難である場合</p>
        <p className="indent-4">(3) 会員に利用料金を請求する目的のために必要な範囲で、決済システム会社、クレジット会社、銀行等に本サービスを利用している会員の会員情報を提供する場合</p>
        <p className="indent-4">(4) 本サービスの提供のために必要な範囲で、葬家に開示する場合</p>
        <p className="indent-3">３　前項にかかわらず、当社は、統計情報を作成し、本サービス及び当社のその他のサービスのために利用することがあります。また、統計情報を第三者に開示することがあります。</p>
        <p className="indent-3">４　当社は、会員情報の紛失、破壊、改鼠、漏洩等の危険に対して、合理的な最大限の安全対策を講じます。</p>
        <p className="indent-3">５　当社は、電話応対品質向上等のため、会員との間の電話応対を録音し、録音内容を業務において使用することができるものとします。</p>

        <br />
        第２５条（反社会的勢力との取引排除）<br />
        <br />
        <p className="indent-3">１　会員は、当社に対し、次の各号の事項を確約するものとします。</p>
        <p className="indent-4">⑴　自らが、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋、社会的運動等標榜ゴロ、特殊知能暴力集団その他これらに準ずる者（総称して「反社会的勢力」といいます。）でないこと</p>
        <p className="indent-4">⑵　反社会的勢力と次の関係を有していないこと</p>
        <p className="indent-5">①　自ら若しくは第三者の不正の利益を図る目的、又は第三者に損害を与える目的をもって反社会的勢力を利用していると認められる関係</p>
        <p className="indent-5">②　反社会的勢力に対して資金等を提供し、又は便宜を供与するなど反社会的勢力の維持、運営に協力し、又は関与している関係</p>
        <p className="indent-4">⑶　自らの役員（取締役、執行役、執行役員、監査役、相談役、会長その他、名称の如何を問わず、経営に実質的に関与している者をいいます。）が反社会的勢力ではないこと、及び反社会的勢力と社会的に非難されるべき関係を有していないこと</p>
        <p className="indent-4">⑷　反社会的勢力に自己の名義を使用させ、本利用契約を締結するものでないこと</p>
        <p className="indent-4">⑸　自ら又は第三者を利用して本利用契約に関して次の行為をしないこと</p>
        <p className="indent-5">①　暴力的な要求行為</p>
        <p className="indent-5">②　法的な責任を超えた不当な要求行為</p>
        <p className="indent-5">③　取引に関して、脅迫的な言動をし、又は暴力を用いる行為</p>
        <p className="indent-5">④　風説を流布し、偽計又は威力を用いて相手方の業務を妨害し、又は信用を毀損する行為</p>
        <p className="indent-5">⑤　その他前各号に準ずる行為</p>
        <p className="indent-3">２　会員について、次のいずれかに該当した場合には、当社は、何らの催告を要せずして、本利用契約を解除することができます。</p>
        <p className="indent-4">⑴　前項⑴ないし⑶の確約に反する表明をしたことが判明した場合</p>
        <p className="indent-4">⑵　前項⑷の確約に反し契約を締結したことが判明した場合</p>
        <p className="indent-4">⑶　前項⑸の確約に反した行為をした場合</p>
        <p className="indent-3">３　前項の規定により本利用契約が解除された場合には、解除された者は、当社に対し、当社の被った損害を賠償するものとします。</p>
        <p className="indent-3">４　第２項の規定により本利用契約が解除された場合には、解除された者は、解除により生じる損害について、当社及び他の会員に対し一切の請求を行わないものとします。</p>

        <br />
        第２６条（存続条項）<br />
        <br />
        <p className="indent-1">第９条（税金関係）、第１１条（権利の帰属・利用）、第１２条（外部サービス）第２項、第１３条（競合禁止等）、第１５条（葬家・参列者・参列候補者の個人情報の利用の制限）、第１６条 （規約違反の場合の措置等）第２項及び第３項、第１８条（本サービスの変更・中断・終了等）第４項、第１９条（損害賠償）、第２０条（保証の否認及び免責）、第２３条（秘密保持）、第２４条（情報管理）、第２５条（反社会的勢力との取引排除）第３項及び第４項、第２６条（存続条項）、第２８条（本規約の有効性及び個別性）、第２９条（準拠法・合意管轄）、並びに第３０条（協議）は、本利用契約終了後も効力を有するものとします。</p>

        <br />
        第２７条（完全合意）<br />
        <br />
        <p className="indent-1">本規約は、締結日現在における当社と会員との合意を規定したものであり、本利用契約締結以前に両当事者間でなされた協議内容、合意事項又は一方当事者から相手方に提供された資料、申入れその他の通信と本利用契約の内容とが相違する場合は、本利用契約が優先するものとします。</p>

        <br />
        第２８条（本規約の有効性及び個別性）<br />
        <br />
        <p className="indent-1">本規約のいずれかの規定が無効又は違法であっても、本規約のほかの規定はそれに何ら影響を受けることなく有効とします。</p>

        <br />
        第２９条（準拠法・合意管轄）<br />
        <br />
        <p className="indent-1">本利用契約は、準拠法を日本法とし、本利用契約に関して訴訟の必要が生じた場合には、名古屋地方裁判所を第一審の専属的合意管轄裁判所とします。</p>

        <br />
        第３０条（協議）<br />
        <br />
        <p className="indent-1">本規約に定めのない事項又は疑義が生じた事項については、信義誠実の原則に従い当事者間で協議し、円満に解決を図るものとします。</p>

        <br />
        2023年3月17日制定<br />
        2023年10月24日改訂<br />
        2024年5月1日改訂<br />
        <br />

        <br />
        別紙１<br />
        <br />

        <p className="indent-3">第８条第２項の「当社の定める内容」は以下のとおりとする。</p>
        <p className="indent-3">供物等の代金について、会員である葬儀会社の作成する葬儀イベントごとの区別はしない（ただし、当社から会員に対して明細書を発行する）。</p>
        <p className="indent-3">当社が会員に支払うべき金額から、本サービスの使用許諾規約にて定めるファックス送信サービスの利用料を控除して支払うことがあり、この場合には当社から会員に事前に通知をする。</p>
        <p className="indent-3">当社は、会員に対して、その指定する口座に、当社が別途定める時点に、振り込む方法で支払う。振込手数料は、当社の負担とする。</p>

        <br />
        別紙２<br />
        <br />

        <p className="indent-3">第８条第４項の「当社の定める内容」は以下のとおりとする。</p>
        <p className="indent-3">会員が当社に対して負担する手数料額は、当社が別途定める。</p>
        <p className="indent-3">会員が当社に対して負担する手数料は、別紙１(3)の銀行口座に振り込まれる金額から控除する。当該金額が当該手数料を控除するに足りない場合、当該手数料は次回の銀行口座への振込みの際に控除する。</p>

        <br />
        以上<br />
        <br />
      </div>

      <br/>
        <p className="text-center text-danger fs-3">2024年5月1日改訂</p>
        <br/>
        <div className="text-center consent-button">
          <label><input className="check" id="check" type="checkbox" disabled={ checkDisabled } onChange={ handleChanged }/>　利用規約に同意する</label>
        </div>
        <br/>
        <div>
          <br/>
          <h4 className="text-center text-danger">利用規約を最後までスクロールすると、同意ボタンにチェックできます。</h4>
        </div>
        <div style={{ marginTop: '40px' }} className="d-flex justify-content-center">
          <button type="submit" className='terms-white-button mb-5' disabled={ !checked } onClick={ handleContinue }>続ける</button>
        </div>
    </div>
  )
}

const updateUndertakerTermsCheck = (companyId, email, termsChecked) => {
  const options = {
    body: {
      companyId: companyId,
      email: email,
      termsChecked: termsChecked
    }
  }
  return API.put('product', '/users/undertaker/terms', options);
}

export default TermsContents