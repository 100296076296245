import {useContext, useState} from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import '../css/Common.scss';
import {API, Auth} from 'aws-amplify';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import {AuthContext} from "../components/Contexts";

const BlackButton = styled.button`
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  border: 0px;
  font-size: 11px;
  padding: 5px 50px;
  margin: 20px;
`

const Error = styled.div`
  color: #FF0000;
`
const getOptions = (funeralId) => {
  return {
    queryStringParameters: {
      funeralId: funeralId
    }
  };
}

export const Registration = (props) => {
  const { location } = props;
  const queryString = new URLSearchParams(location.search);

  const history = useHistory();
  const [isFixedEmail] = useState(queryString.get("email") !== null);
  const [email, setEmail] = useState(queryString.get("email") ?? "");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [code, setCode] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const match = useRouteMatch("/:funeralId");
  const { setUserInfo } = useContext(AuthContext);

  const handleRegist = async () => {
    try {
      await Auth.signUp(email, password, email);
      setShow(true);
    } catch (err) {
      if (err.code === 'UsernameExistsException') {
        setMessage("そのメールアドレスは登録済みです");
      } else {
        setMessage("エラーが発生しました");
        setIsError(true);
      }
    }
  }

  const handleConfirm = async () => {
    try {
      await Auth.confirmSignUp(email, code);

      // サインアップ直後はログイン状態になっていないので、ログインする
      await Auth.signIn(email, password);
      const userInfo = await API.get("product", "/auth", getOptions(match.params.funeralId));
      if (!userInfo.error) {
        setUserInfo(userInfo);
      }

      // サインアップ後は利用規約ページへ飛ばす
      history.push(`/${match.params.funeralId}/attendanceterms`);
    } catch (e) {
      debugger
      setError("コードが正しくありません");
    }
  }

  return (
    <div id="auth-main">
      <div id="auth-common">
        <img className="logo" src="/images/itowa-logo-square.jpg" alt="logo"></img>
        <div className="regist-title">新規登録</div>
        {
          isError &&
          <div className="error-msg mb-4">
            新規登録用のアドレスと<br/>
            任意のパスワードを入力し、<br/>
            新規登録ボタンを押してください。
          </div>
        }
        <form>
          <div id="identity">
            <div className="form-group">
              <label className="control-label">登録したいEメール</label>
              <div className="input-group">
                <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} disabled={isFixedEmail}></input>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label">新規に設定するパスワード</label>
              <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)}></input>
            </div>
          </div>
          <div className="alert">
            <div className="msg">{message}</div>
          </div>

          <div className="buttons">
            <button type="button" className="btn btn-primary login-button" onClick={handleRegist}>新規登録</button>
          </div>
        </form>
        <Modal show={show} centered={true}>
          <Modal.Body style={{textAlign:"center"}}>
            <h2 style={{fontSize:"18px",textAlign:"center",fontWeight:"bold",margin:"20px"}} className="gothic">確認メールを送信しました</h2>
            <label className="control-label gothic">メールに記載された6桁のコードを入力してください。</label>
            <input type="text" className="form-control" value={code} onChange={(e)=> setCode(e.target.value)} maxLength={6}/>
            {error && <Error>{error}</Error>}
            <div className="buttons">
              <BlackButton type="button" className="btn btn-primary black-button" onClick={handleConfirm}>確認</BlackButton>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className="regist">
        <span>アカウントはお持ちですか？</span>
        <Link to="login">ログイン</Link>
      </div>
    </div>
  )
}

export default Registration;