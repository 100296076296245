import * as Constant from '../constants';
import styled from 'styled-components';

const Empty = styled.div`
  background-color: #DDDDDD;
  display: flex;
  justify-content: center;
  align-items: center;
`

const EmptyText = styled.div`
  font-size: 16px;
`

export const Img = ({src, width, height, imgClassName, ...props}) => {
  return (
    <>
      {
        src !== undefined ?
          <img src={Constant.IMAGE_BUCKET_URL + src} className={imgClassName} alt="" {...props} />
        :
          <Empty className={imgClassName} style={{ width: width + "px", height: height + "px" }}><EmptyText>Empty</EmptyText></Empty>
      }
    </>
  )
}

export default Img;