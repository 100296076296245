import { useEffect } from 'react';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Auth } from "aws-amplify";

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Input from 'components/Input';
import useMessage from 'components/Message';

export const UserEdit = (props) => {
  const newEntry = props.method === "new";

  const history = useHistory();
  const message = useMessage();

  const formik = useFormik({
    initialValues: {
      userId: "",
      userName: "",
      phoneNo: "",
      role: "manager",
    },
    validationSchema: Yup.object({
      userId: Yup.string()
        .required("メールアドレスは必須です")
        .email("メールアドレスの書式ではありません"),
      userName: Yup.string()
        .required("ユーザ名は必須です"),
      phoneNo: Yup.string()
        .required("電話番号は必須です")
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        if (newEntry) {
          const result = await createUser(values);
          if (result.error) {
            if (result.error === "User exists") {
              message.alert.danger("ユーザがすでに存在しています");
            } else {
              message.alert.danger("システムエラーが発生しました");
            }
          } else {
            message.alert.success("ユーザを作成しました");
            history.goBack();
          }
        } else {
          updateUser(values);
          message.alert.success("ユーザを更新しました");
          history.goBack();
        }
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });
  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    const fetch = async (userId) => {
      const user = await getUser(userId);
      setFieldValue("userId", user.UserId);
      setFieldValue("userName", user.UserName);
      setFieldValue("phoneNo", user.PhoneNo);
      setFieldValue("role", user.Role || "manager")
    }
    if (!newEntry) {
      fetch(props.match.params.userId);
    }
  }, [newEntry, props, setFieldValue]);

  const handleDelete = async () => {
    const userInfo = await Auth.currentAuthenticatedUser();
    if (userInfo.username === formik.values.userId) {
      message.alert.danger("自分自身を削除することはできません");
    } else {
      message.confirm.danger({
        title: "ユーザを削除",
        message: "削除してもよろしいですか？",
        onSubmit: () => {
          deleteUser(formik.values.userId);
          message.alert.success("ユーザを削除しました");
          history.goBack();
        }
      });
    }
  }

  const handleSendEmail = async () => {
    const { error } = await sendEmailUser(formik.values.userId);
    if (error) {
      message.alert.danger("メールを送信できませんでした。");
      return
    }
    message.alert.success("メールを送信しました。");
  }

  return (
    <CommonLayout>
      <ContentHeader>
        {newEntry ? "管理者ユーザ新規作成" : "管理者ユーザ編集"}
      </ContentHeader>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md="6">
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <label className="control-label">メールアドレス</label>
                  <Input type="text" name="userId" className="form-control" disabled={!newEntry} formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">ユーザ名</label>
                  <Input type="text" name="userName" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">電話番号</label>
                  <Input type="text" name="phoneNo" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">権限</label>
                  <RoleType name="role" formik={formik} />
                </div>
              </div>
              <div className="card-footer">
                <div>
                  <Button variant="secondary" className="float-right" onClick={() => history.goBack()}>戻る</Button>
                  {
                    newEntry ?
                      <Button type="submit" variant="success">作成</Button>
                      :
                      <>
                        <Button type="submit" variant="success">更新</Button>
                        {' '}
                        <Button variant="danger" onClick={handleDelete}>削除</Button>
                        {' '}
                        <Button variant="info" onClick={handleSendEmail}>メールの再送</Button>
                      </>
                  }
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </CommonLayout>
  );
};

const getUser = async (userId) => {
  return await API.get('product', '/users/admin/' + userId);
}

const createUser = async (values) => {
  const options = {
    body: {
      email: values.userId,
      userName: values.userName,
      phoneNo: values.phoneNo,
      role: values.role
    }
  }
  return await API.post('product', '/users/admin', options);
}

const updateUser = async (values) => {
  const options = {
    body: {
      email: values.userId,
      userName: values.userName,
      phoneNo: values.phoneNo,
      role: values.role
    }
  }
  return await API.put('product', '/users/admin', options);
}

const deleteUser = async (userId) => {
  const options = {
    body: {
      email: userId,
    }
  }
  return await API.del('product', '/users/admin', options);
}

const sendEmailUser = async (userId) => {
  const options = {
    body: {
      email: userId,
    }
  }
  return await API.post('product', '/mail/users/admin', options);
}

const RoleType = (props) => {
  const { name, formik } = props;
  const hasError = formik && formik.errors[name];

  return (
    <>
      <div className={"form-check" + (hasError ? " is-invalid" : "")} style={{ display: "flex", justifyContent: "flex-start", marginLeft: "1rem" }}>
        <label style={{ width: "8rem" }}>
          <Input type="radio" name={name} value="manager" className={"form-check-input" + (hasError ? " is-invalid" : "")} formik={formik} />管理者
        </label>
        <label style={{ width: "12rem" }}>
          <Input type="radio" name={name} value="call" className={"form-check-input" + (hasError ? " is-invalid" : "")} formik={formik} />コールセンター
        </label>
        <label style={{ width: "8rem" }}>
          <Input type="radio" name={name} value="sagawa" className={"form-check-input" + (hasError ? " is-invalid" : "")} formik={formik} />佐川管理者
        </label>
      </div>
      <div className="invalid-feedback">{formik.errors[name]}</div>
    </>
  )
}

export default UserEdit;
