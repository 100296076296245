import React, {useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { FUNERALS_WEB_URL } from '../constants';
import { useRouteMatch } from 'react-router-dom';

export const ContentHeader = ({ children, showQr, sideElement }) => {
  const [show, setShow] = useState(false);
  const match = useRouteMatch("/:companyId");

  return (
    <React.Fragment>
      <Helmet>
        <title>{children} | itowa</title>
      </Helmet>
      <Row>
        <Col>
          <div className="content-header d-flex align-items-center">
            <h1 className="mr-auto">{children}</h1>
            {showQr && <Button onClick={() => setShow(true)}>申請用QR</Button>}
            {sideElement && sideElement}
          </div>
        </Col>
      </Row>
      <Modal show={show} centered={true}>
        <Modal.Header closeButton={true} onHide={() => setShow(false)}>
          <div style={{marginLeft: "auto", marginRight: "auto", paddingLeft: "calc(1rem + 32px)"}}>
            <Modal.Title>＜LINE申請用QRコード＞</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{marginLeft: "5%", marginRight: "5%"}}>
            <div style={{background: 'white', padding: '16px', textAlign: "center"}}>
              <QRCode
                size={256}
                style={{height: "auto", maxWidth: "75%", width: "75%"}}
                value={`${FUNERALS_WEB_URL}/${match.params.companyId}/pre-registration`}
                viewBox={`0 0 256 256`}
              />
            </div>
            <p>上記のQRコードを葬家の代表者の方に読み込むようにお伝えてください。QRコードを読み込むと申請画面が開きます。</p>
            <div style={{width: "100%", textAlign: "center"}}>
              <Button onClick={() => setShow(false)}
                      style={{
                        width: "60%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        border: "0.1rem solid rgba(170, 170, 170, 0.8)"
                      }}
                      variant={"secondary"}>
                読み取り完了
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default ContentHeader;