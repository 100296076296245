import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import AttendeeLayout from 'components/AttendeeLayout';
import { Helmet } from 'react-helmet-async';
import * as Constants from '../../constants';
import { useLoading } from 'components/Loading';

export const MournerMessage = (props) => {
  const [mournerMessage, setMournerMessage] = useState("");
  const funeralId = props.match.params.funeralId;
  const loading = useLoading();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const get = async () => {
      const result = await getMournerMessage(funeralId);
      if(result.message) {
        setMournerMessage(result.message);
      } else {
        setMournerMessage(`ここに生前のご厚誼を感謝し\n謹んでご通知申し上げます\nなお通夜葬儀式は下記のとおり相営みます`)
      }
    }

    get();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    loading(true);
    const result = await updateMournerMessage(funeralId, mournerMessage);
    if(result.error) {
      setMessage("システムエラーが発生しました");
    } else {
      setMessage("メッセージを更新しました");
      setTimeout(() => setMessage(null), 2000);
    }
    loading(false);
  }

  return (
    <AttendeeLayout adminPage={true}>
      <div id="mourner-message">
        <Helmet>
          <title>挨拶文章登録 | itowa</title>
        </Helmet>
        <div className="frame">
          <div className="title mincho">ご挨拶</div>
          <div className="message-label">
            <label className="control-label mincho">メッセージ</label>
            <div className="description mincho">
              ご挨拶の文章例を参考にされたい方は<a href={Constants.EXAMPLE_MESSAGE_URL} target="blank" rel="noreferrer">こちら</a>
            </div>
          </div>
          <textarea className="form-control mincho" rows={12} value={mournerMessage} onChange={(e) => setMournerMessage(e.target.value)}></textarea>
          <button type="button" className="btn btn-primary mincho" onClick={handleSubmit}>確定する</button>
        </div>
      </div>
      {
        message &&
        <div className="msg" style={{color:"#FFFFFF",backgroundColor:"#4B4B4B"}}>
          {message}
        </div>
      }
    </AttendeeLayout>
  )
}

const getMournerMessage = async (funeralId) => {
  return await API.get('product', '/funerals/message/'+funeralId);
}

const updateMournerMessage = async (funeralId, message) => {
  const options = {
    body: {
      funeralId: funeralId,
      message: message
    }
  }
  return await API.put('product', '/funerals/message', options);
}

export default MournerMessage;