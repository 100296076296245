/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, {useEffect, useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom';

const MenuItem = ({menuItem}) => {
  const [isMenuExtended, setIsMenuExtended] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const [isMainActive, setIsMainActive] = useState(false);
  const [isOneOfChildrenActive, setIsOneOfChildrenActive] = useState(false);
  const history = useHistory();
  const [dataWidget, setDataWidget] = useState("");
  const widthBorder = 768
  const [width, setWidth] = useState(window.innerWidth)
  const updateWidth = (event) => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener(`resize`, updateWidth, {
      capture: false,
      passive: true,
    })
    return () => window.removeEventListener(`resize`, updateWidth)
  })

  useEffect(() => {
    if (width < widthBorder){
      return setDataWidget("pushmenu");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const toggleMenu = () => {
    setIsMenuExtended(!isMenuExtended);
  };

  const handleMainMenuAction = () => {
    if (isExpandable) {
      toggleMenu();
      return;
    }
    history.push(menuItem.path);
  };

  useEffect(() => {
    if (!isMainActive && !isOneOfChildrenActive) {
      setIsMenuExtended(false);
    }
  }, [isMainActive, isOneOfChildrenActive]);

  useEffect(() => {
    setIsExpandable(
      menuItem && menuItem.children && menuItem.children.length > 0
    );
  }, [menuItem]);

  useEffect(() => {
    const url = history.location.pathname;
    setIsMainActive(false);
    setIsOneOfChildrenActive(false);
    if (isExpandable) {
      menuItem.children.forEach((item) => {
        if (item.path === url) {
          setIsOneOfChildrenActive(true);
          setIsMenuExtended(true);
        }
      });
    } else if (url.indexOf(menuItem.path) === 0) {
      setIsMainActive(true);
    }
  }, [history, isExpandable, menuItem, history.location.pathname]);

  return (
    <li data-widget={`${menuItem.children ? '' : dataWidget}`} className={`nav-item${isMenuExtended ? ' menu-open' : ''}`}>
      <a
        className={`nav-link${
            isMainActive || isOneOfChildrenActive ? ' active' : ''
        }`}
        role="link"
        onClick={handleMainMenuAction}
        style={{cursor: 'pointer'}}
      >
        <i className={`nav-icon ${menuItem.icon}`} />
        <p>{menuItem.name}</p>
        {isExpandable ? (
          <i className="right fas fa-angle-left" />
        ) : null}
      </a>

      {isExpandable &&
        menuItem.children.map((item) => (
          <ul data-widget={dataWidget} key={item.name} className="nav nav-treeview">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                exact
                to={`${item.path}`}
              >
                <i className={`nav-icon ${item.icon}`} />
                <p>{item.name}</p>
              </NavLink>
            </li>
          </ul>
        ))}
    </li>
  );
};

export default MenuItem;
