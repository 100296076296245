import { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MessageContext } from '../Contexts';

export const ModalConfirm = () => {
  const { modalConfirm, setModalConfirm } = useContext(MessageContext);

  const handleClose = () => {
    setModalConfirm({ show: false });
  }

  return (
    <Modal show={modalConfirm.show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{modalConfirm.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalConfirm.message}</Modal.Body>
      <Modal.Footer>
        <Button variant={modalConfirm.variant} onClick={() => { handleClose(); modalConfirm.onSubmit(true) }}>
          はい
        </Button>
        <Button variant="secondary" onClick={() => { handleClose(); modalConfirm.onSubmit(false) }}>
          いいえ
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalConfirm;