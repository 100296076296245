import { Helmet } from 'react-helmet-async';

import AttendeeLayout from 'components/AttendeeLayout';

export const FuneralGiftComplete = () => {

  return (
    <AttendeeLayout>
      <Helmet>
        <title>お支払い完了 | itowa</title>
      </Helmet>
      <div id="complete">
        <h2 className="item-title-horizontal">お支払い完了</h2>
        <p className="thanks-message">
          お支払いいただきありがとうございます。 <br/>
          返礼品を選択・購入できました。<br/>
          葬儀社にご連絡が伝わって降りますので<br/>
          返礼品の郵送は葬儀社にお任せください。
        </p>
      </div>
    </AttendeeLayout>
  );
};

export default FuneralGiftComplete;