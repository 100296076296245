import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Modal } from 'react-bootstrap';
import * as Constants from '../constants';
import { Img } from 'components/Img';

const Photo = (props) => {
  const photo = props.src;
  const [modalShow, setModalShow] = useState(false);
  const [published, setPublished] = useState(photo ? photo.published : false);
  const shared = photo ? photo.shared : true;
  const selectMode = props.selectMode;

  const previewOrSelect = () => {
    if(selectMode) {
      // 選択モードの時は、publishedをon/offする
      photo.published = !published;
      photo.updated = true;
      setPublished(!published);
      props.onSelect(photo);
    } else {
      // 選択モードでないときは、プレビュー表示
      setModalShow(true);
    }
  }

  if(photo) {
    return (
      <>
        {(selectMode && shared) &&
        <>
          <div className="circle"></div>
          <div className={"point" + (published === true ? " active" : "")}></div>
        </>
        }
        <Img className={selectMode && shared === true && published === true ? "mask" : ""} src={photo.path} alt="" onClick={previewOrSelect} />
        <Modal id="attendee-modal" show={modalShow} onHide={() => setModalShow(false)} centered={true}>
          {
            photo.userName &&
            <Modal.Header>{photo.userName}様</Modal.Header>
          }
          <Img className="photo-preview" src={photo.path} onClick={() => setModalShow(false)} />
          <Modal.Body>{photo.message}</Modal.Body>
        </Modal>
      </>
    )
  } else {
    return <></>
  }
}

const noop = () => {
  return null;
}

export const PhotoGallery = (props) => {
  const [width, setWidth] = useState(window.innerWidth); 
  const photos = props.photos || [];
  const append = props.append || false;
  const history = useHistory();
  const match = useRouteMatch("/:funeralId");
  const selectMode = props.selectMode || false;
  const onSelect = props.onSelect || noop;

  const updateWidth = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', updateWidth, {
      capture: false,
      passive: true
    });

    return () => window.removeEventListener('resize', updateWidth);
  },[]);

  const toPostPage = () => {
    history.push("/" + match.params.funeralId + "/album/post");
  }

  if (width < Constants.PC_WINDOW_WIDTH) {
    // SP用
    const skip = append ? 1 : 0;

    return (
      <div className="photo-gallery">
        {
          append &&
          <div className="block sp-block0 photo-append" onClick={toPostPage}>
            <div id="append-text">
              <div>
                思い出を追加する
              </div>
            </div>
            <img src="/images/blank.png" alt="" />
          </div>
        }
        {
          photos.map((photo, index) =>
            <div key={index} className={"block sp-block" + (index + skip) % 10}>
              <Photo src={photo} selectMode={selectMode} onSelect={onSelect}/>
            </div>
          )
        }
      </div>
    )
  } else {
    // PC用

    // 配列を15個単位に区切る
    const newPhotos = [];
    let offset = append ? -4 : 0;
    while(true) {
      // append = trueのとき[0..10][11..25][26..40] 最初の１つだけ4つ分少なくする（追加ボタン分のスペース）
      const sublist = photos.slice(Math.max(offset,0), offset + 18);
      if(sublist.length === 0) {
        break;
      }
      if(offset === -4) { // append=trueかつ第1週目
        for(let i = 0 ; i < 4 ; i++) {
          sublist.unshift(null);
        }
      }
      newPhotos.push(sublist);
      offset+=18;
    }

    if(append && newPhotos.length === 0) {
      // 1件もない場合は下記のループに入らないので、追加ボタンもでてこない。なので１件ダミーを追加する
      newPhotos.push([[{}]]);
    }

    return (
      <div className="photo-gallery">
        {
          newPhotos.map((photos, j) =>
            <div className="photo-group" key={j}>
              {
                <div className="h-flex">
                  <div className="v-flex divide0">
                    <div className="h-flex divide0-0">
                      <div className="v-flex divide0-0-0">
                        {
                          append && j === 0 ?
                            <div className="block photo-append" onClick={toPostPage}>
                              <div id="append-text">
                                <div>
                                  思い出を追加する
                                </div>
                              </div>
                              <img src="/images/blank.png" alt="" />
                            </div>
                          :
                          <>
                            <div className="h-flex divide0-0-0-0">
                              <div className="block divide0-0-0-0-0">
                                <Photo src={photos[0]} selectMode={selectMode} onSelect={onSelect} />
                              </div>
                              <div className="block divide0-0-0-0-1">
                                <Photo src={photos[1]} selectMode={selectMode} onSelect={onSelect} />
                              </div>
                            </div>
                            <div className="h-flex divide0-0-0-1">
                              <div className="block divide0-0-0-1-0">
                                <Photo src={photos[2]} selectMode={selectMode} onSelect={onSelect} />
                              </div>
                              <div className="block divide0-0-0-1-1">
                                <Photo src={photos[3]} selectMode={selectMode} onSelect={onSelect} />
                              </div>
                            </div>
                          </>
                        }
                      </div>
                      <div className="block divide0-0-1">
                        <Photo src={photos[4]} selectMode={selectMode} onSelect={onSelect} />
                      </div>
                      <div className="block divide0-0-2">
                        <Photo src={photos[5]} selectMode={selectMode} onSelect={onSelect} />
                      </div>
                      <div className="v-flex divide0-0-3">
                        <div className="block pc-block divide0-0-3-0">
                          <Photo src={photos[6]} selectMode={selectMode} onSelect={onSelect} />
                        </div>
                        <div className="block pc-block divide0-0-3-1">
                          <Photo src={photos[7]} selectMode={selectMode} onSelect={onSelect} />
                        </div>
                      </div>
                    </div>
                    <div className="h-flex divide0-1">
                      <div className="v-flex divide0-1-0">
                        <div className="h-flex divide0-1-0-0">
                          <div className="block divide0-1-0-0-0">
                            <Photo src={photos[10]} selectMode={selectMode} onSelect={onSelect} />
                          </div>
                          <div className="block divide0-1-0-0-1">
                            <Photo src={photos[11]} selectMode={selectMode} onSelect={onSelect} />
                          </div>
                        </div>
                        <div className="block divide0-1-0-1">
                          <Photo src={photos[12]} selectMode={selectMode} onSelect={onSelect} />
                        </div>
                      </div>
                      <div className="block pc-block divide0-1-1">
                        <Photo src={photos[13]} selectMode={selectMode} onSelect={onSelect} />
                      </div>
                      <div className="block pc-block divide0-1-2">
                        <Photo src={photos[14]} selectMode={selectMode} onSelect={onSelect} />
                      </div>
                      <div className="v-flex divide0-1-3">
                        <div className="block divide0-1-3-0">
                          <Photo src={photos[15]} selectMode={selectMode} onSelect={onSelect} />
                        </div>
                        <div className="block divide0-1-3-1">
                          <Photo src={photos[16]} selectMode={selectMode} onSelect={onSelect} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="v-flex divide1">
                    <div className="block divide1-0">
                      <Photo src={photos[8]} selectMode={selectMode} onSelect={onSelect} />
                    </div>
                    <div className="block divide1-1">
                      <Photo src={photos[9]} selectMode={selectMode} onSelect={onSelect} />
                    </div>
                    <div className="block divide1-2">
                      <Photo src={photos[17]} />
                    </div>
                  </div>
                </div>
              }
            </div>
          )
        }

      </div>
    )
  }
}