import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import {useContext, useRef, useState} from 'react';
import moment from 'moment-timezone';
import AttendeeLayout from 'components/AttendeeLayout';
import {API} from "aws-amplify";
import {checkLimit, getCartContents} from "../../components/Cart";
import {AuthContext} from "../../components/Contexts";
import { useLoading } from 'components/Loading';
import uuid from 'uuid';

import * as Constants from '../../constants';

export const AttendanceContentInvoiceAuthCode = (props) => {
  const [limitDateTime, setLimitDateTime] = useState("");
  const [authCode1, setAuthCode1] = useState("");
  const [authCode2, setAuthCode2] = useState("");
  const [authCode3, setAuthCode3] = useState("");
  const [authCode4, setAuthCode4] = useState("");
  const [authCode5, setAuthCode5] = useState("");
  const [authCode6, setAuthCode6] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();
  const loading = useLoading();
  const { userInfo, setUserInfo } = useContext(AuthContext);

  const num1 = useRef(null);
  const num2 = useRef(null);
  const num3 = useRef(null);
  const num4 = useRef(null);
  const num5 = useRef(null);
  const num6 = useRef(null);

  const funeralId = props.match.params.funeralId;
  const orderId = uuid.v4();

  const { location } = props;
  const queryString = new URLSearchParams(location.search);
  const isOrderOnly = queryString.get("isOrderOnly") === "true";

  const refCallback = (el) => {
    num1.current = el;
    num1.current?.addEventListener("paste", function(event){
      setTimeout(function(e){
        const nums = [num1, num2, num3, num4, num5, num6];
        e.target.value.split('').forEach((value, index) => {
          if (index > 5) return;
          nums[index].current.value = value;
          numChange(value, index);
          num6.current.focus();
        });
      }, 10, event);
    });
  }

  const numChange = (value, currentCode) => {
    // 認証コードを記録する
    const authCodes = [setAuthCode1, setAuthCode2, setAuthCode3, setAuthCode4, setAuthCode5, setAuthCode6];
    authCodes[currentCode](value);

    // 次のフォームに入力を切り替える (未入力 or 最後の入力ならスキップ)
    if (currentCode === 5) return;
    const nums = [num1, num2, num3, num4, num5, num6];
    nums[currentCode + 1].current.focus();
  }

  const resendAuthCode = async () => {
    // 認証コードを再度送信する
    loading(true);
    const localData = JSON.parse(localStorage.getItem(funeralId));
    if (localData.invoicePhoneNo !== Constants.PHONE_SKIP_VERIFY || Constants.APP_STAGE === 'prd') {
      const result = await sendVerifyCode(localData.sessionId, localData.invoicePhoneNo);
      if (result.error) {
        setErrorMessage(result.error.data.message);
        loading(false);
        return;
      }

      // 認証IDを再度記録する
      localData.authId = result.data;
      localStorage.setItem(funeralId, JSON.stringify(localData));
    } else {
      localData.authId = localData.invoicePhoneNo;
      localStorage.setItem(funeralId, JSON.stringify(localData));
    }

    loading(false);
  }

  const onKeyDown = (event, currentCode) => {
    const nums = [num1, num2, num3, num4, num5, num6];
    const authCodes = [setAuthCode1, setAuthCode2, setAuthCode3, setAuthCode4, setAuthCode5, setAuthCode6];
    if (currentCode !== 0 && event.keyCode === 8) {
      event.preventDefault();
      authCodes[currentCode]('');
      authCodes[currentCode - 1]('');
      nums[currentCode - 1].current.focus();
    }
  }

  const handleOrder = async () => {
    loading(true);

    // SMS認証を行う
    const localData = JSON.parse(localStorage.getItem(funeralId));
    if (localData.invoicePhoneNo !== Constants.PHONE_SKIP_VERIFY || Constants.APP_STAGE === 'prd') {
      const pin = authCode1 + authCode2 + authCode3 + authCode4 + authCode5 + authCode6;
      const status = await verificationComplete(localData.sessionId, localData.authId, pin);

      // エラーコード603はすでに認証済みなのでエラー扱いにしない
      if (status.error && status.error.data.error !== '603') {
        setErrorMessage(status.error.data.message);
        loading(false);
        return;
      }
    }

    // 商品がタイムアウトになっていないか確認する
    const result = await getDisplayItem(funeralId);
    const checkResult = await checkLimit(funeralId, result.itemLimit, result.koudenLimit);
    const cart = await getCartContents(funeralId);
    if (!checkResult) {
      history.push("/" + funeralId + "/attendance/content/select/kouden?isOrderOnly=" + isOrderOnly);
    }
    setLimitDateTime({itemLimit: result.itemLimit, koudenLimit: result.koudenLimit});
    const now = moment().tz('Asia/Tokyo').format("YYYY-MM-DD HH:mm");
    if (limitDateTime.itemLimit < now || limitDateTime.koudenLimit < now) {
      localStorage.removeItem(funeralId);
      history.push("/" + funeralId + "/attendance/content/select/kouden?isOrderOnly=" + isOrderOnly);
    }

    // 注文を送信する
    const email = userInfo ? userInfo?.orderEmail || userInfo?.email : localData.email;
    const type = userInfo ? 'product' : 'guest';
    const orderResult = await createOrder(orderId, funeralId, email, cart, isOrderOnly, type);

    if(orderResult && orderResult.result) {
      localStorage.removeItem(funeralId);

      if (userInfo) {
        // 最新の認証情報を取り直す
        const options = {
          queryStringParameters: {
            funeralId: funeralId,
            companyId: ""
          }
        };
        const userInfo = await API.get("product", "/auth", options);
        setUserInfo(userInfo);
        history.push(`/${funeralId}/attendance/invoice/complete?orderId=${orderId}&email=${email}&isOrderOnly=${isOrderOnly}`);
      } else {
        // TODO: 未ログインの場合は、emailを引き継いで完了画面にするべきかも? (AttendanceContentPayment.jsを参照)
        history.push(`/${funeralId}/attendance/invoice/complete?orderId=${orderId}&email=${email}&isOrderOnly=${isOrderOnly}`);
      }
    } else {
      if(orderResult) {
        setErrorMessage(orderResult.message);
      } else {
        setErrorMessage("システムエラーが発生しました。")
      }
    }

    loading(false);
  }

  return (
    <AttendeeLayout>
      <Helmet>
        <title>認証コード入力 | itowa</title>
      </Helmet>
      <h2 className="title-horizontal" style={{ width: "14rem" }}>認証コード入力</h2>
      <div className="authCode">
        <div className="authCodeInfo">
          <p className="fs-2">SMSに届いた6桁の認証コードを<br className="br-sp"/>入力してください</p>
          {
              errorMessage &&
              <div className="fs-3 error-message">
                {errorMessage}
              </div>
          }
        </div>
        <br/>

        {/* SMS認証コード入力箇所 */}
        <div className="authCodeArea flex items-center justify-around">
          <input className="authCodeInput" value={authCode1} ref={el => refCallback(el)} onChange={e => numChange(e.target.value, 0)} onKeyDown={e => onKeyDown(e, 0)} autoFocus={true} type="number" pattern="\d*" autocomplete="one-time-code"></input>
          <input className="authCodeInput" maxLength={1} value={authCode2} ref={num2} onChange={e => numChange(e.target.value, 1)} onKeyDown={e => onKeyDown(e, 1)} type="number" pattern="\d*"></input>
          <input className="authCodeInput" maxLength={1} value={authCode3} ref={num3} onChange={e => numChange(e.target.value, 2)} onKeyDown={e => onKeyDown(e, 2)} type="number" pattern="\d*"></input>
          <input className="authCodeInput" maxLength={1} value={authCode4} ref={num4} onChange={e => numChange(e.target.value, 3)} onKeyDown={e => onKeyDown(e, 3)} type="number" pattern="\d*"></input>
          <input className="authCodeInput" maxLength={1} value={authCode5} ref={num5} onChange={e => numChange(e.target.value, 4)} onKeyDown={e => onKeyDown(e, 4)} type="number" pattern="\d*"></input>
          <input className="authCodeInput" maxLength={1} value={authCode6} ref={num6} onChange={e => numChange(e.target.value, 5)} onKeyDown={e => onKeyDown(e, 5)} type="number" pattern="\d*"></input>
        </div>
        <div className="authCodeSendButton">
          <button type="button" className="btn-to-confirm" onClick={handleOrder}>注文完了</button>
        </div>
        {/* コードの再送信 */}
        <div className="resendCode">
            <a href={() => false} onClick={() => resendAuthCode()} className="fs-3">認証コードを再送信する</a>
        </div>
      </div>

    </AttendeeLayout>
  )
}

const createOrder = async (orderId, funeralId, email, selectedItem, isOrderOnly, type) => {
  const cart = JSON.parse(localStorage.getItem(funeralId));
  const options = {
    body: {
      paymentIntentId: orderId,
      funeralId: funeralId,
      email: email,
      selectedItem: selectedItem,
      isFromOrderForm: isOrderOnly,
      isInvoice: true,
      invoiceNameAddress: cart.invoiceNameAddress,
      invoicePhoneNo: cart.invoicePhoneNo,
      invoiceType: cart.invoiceType,
    }
  }

  try {
    await API.post(type, '/orders', options);
    return {
      result: true
    };
  } catch (e) {
    if(e === "Error: Network Error") {
      return {
        result: false,
        message: "問題が発生しました。インターネット接続をご確認の上、もう一度お試しください。"
      }
    }
  }
}

const verificationComplete = async (sessionId, authId, pin) => {
  const options = {
    body: {
      session_id: sessionId,
      auth_id: authId,
      pin: pin,
    }
  }
  return await API.post('guest', '/sms/verified', options);
}

const getDisplayItem = async (funeralId) => {
  const options = {
    queryStringParameters: { funeralId: funeralId }
  }

  return await API.get('guest', '/funerals/item/template', options);
}

const sendVerifyCode = async (sessionId, phoneNo) => {
  const options = {
    body: {
      session_id: sessionId,
      phone_number: phoneNo
    }
  }
  return await API.post('guest', '/sms/send-verify-code', options);
}

export default AttendanceContentInvoiceAuthCode;
