import { useState, useEffect } from 'react';
import { Img } from 'components/Img';
import { Modal } from 'react-bootstrap';

export const ItemCell = (props) => {
  const { item, onChange, flag, value } = props;
  const countable = props.countable || false;

  const [selected, setSelected] = useState(value || false);
  const [quantity, setQuantity] = useState(1);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    setSelected(flag);
    if (countable) setQuantity(value)
  }, [value, setSelected, countable, flag])

  const handleSelectedChange = (flag) => {
    setSelected(flag);
    if (onChange) {
      onChange(flag, quantity);
    }
  }

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
    if (onChange) {
      onChange(selected, e.target.value);
    }
  }

  return (
    <div className="item-cell">
      <div className="frame" onClick={() => setModalShow(true)}>
        <Img className="photo" src={item.imagePath}></Img>
        <div className="circle" />
        <div className={"point " + (selected ? "point-active" : "point-inactive")} />
      </div>
      <div className="name">{item.itemName}</div>
      <hr className="line" />
      <div className="price">
        <span className="price-value">{parseInt(item.displayPrice || item.price, 10).toLocaleString()}</span>
        <span className="yen">円</span>
        <span className="yen"> {item.itemType === "money" ? '' : "（税込）"}</span>
      </div>
      <div>
        {
          countable &&
          <>
            <span className="quantity-label">数量</span>
            <select className="quantity" value={quantity} onChange={handleQuantityChange}>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
            </select>
          </>
        }
        {
          selected ?
            <button type="button" className={"status status-active" + (countable === false ? " long-style" : "")} onClick={() => handleSelectedChange(false)}>選択済み</button>
            :
            <button type="button" className={"status status-inactive" + (countable === false ? " long-style" : "")} onClick={() => handleSelectedChange(true)}>選択する</button>
        }
      </div>
      <Modal id="attendee-modal" size="xl" show={modalShow} onHide={() => setModalShow(false)} centered={false}>
        <Modal.Header closeButton style={{ borderBottomWidth: "0px", backgroundColor: "#FBFBFB" }}>
        </Modal.Header>
        <Modal.Body id="item-detail-modal" className="item-cell" style={{ backgroundColor: "#FBFBFB" }}>
          <div className="title">{item.itemName}</div>
          <div className="panel">
            <div className="frame">
              <Img className="photo" src={item.imagePath}></Img>
            </div>
            <div className="detail">
              <div className="price">
                <span className="price-label">{item.itemType === "money" ? "香典料" : "価格"}</span>
                <span className="price-value">
                  {parseInt(item.displayPrice || item.price, 10).toLocaleString()}
                  <span className="yen"> {item.itemType === "money" ? "円" : "円（税込）"}</span>
                </span>
              </div>
              <div className="description">
                {
                  item.itemType === "money" ? "香典料を葬家様へお渡しします" : item.description
                }
              </div>
              <div className="buttons">
                <button type="button" className="status close-btn" onClick={() => setModalShow(false)}>閉じる</button>
                {
                  selected ?
                    <button type="button" className="status status-active" onClick={() => handleSelectedChange(false)}>選択されました</button>
                    :
                    <button type="button" className="btn btn-light status status-inactive" onClick={() => handleSelectedChange(true)}>選択する</button>
                }
              </div>
            </div>
          </div>
          {
            item.youtubeTag &&
            <div id="embeded" dangerouslySetInnerHTML={{ __html: item.youtubeTag }} />
          }
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ItemCell;