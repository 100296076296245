import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const ModalPolicySagawa = ({ showDialogPolicy, setShowDialogPolicy, handelConfirm }) => {
  const [checkPolicy, setCheckPolicy] = useState(false);

  const handleChanged = () => {
    setCheckPolicy((prev) => !prev);
  }

  return (
    <Modal size="xl" className="item-center-dl policy-container-dl" show={showDialogPolicy} onHide={() => setShowDialogPolicy(false)} centered>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className="policy-detail-modal">
        <p className="text-policy-center"><strong><span>利用規約</span></strong></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>お申込の前に必ず、下記利用規約に同意の上、お申込願います。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>本規約は、株式会社ｉｔｏｗａが提供するデジタルプラットフォーム上で弔電・香典・お供物・供花の手配ができる「itowa」（以下「itowa」といいます）の会員様に、佐川ヒューモニー株式会社（以下「弊社」といいます）が提供する『電報サービスVERY CARD』（以下「本サービス」といいます）をご利用頂くための規約です。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第1条（目的）</span></p>
        <p className="text-policy"><span>弊社は、第3条に定める本サービスの利用に関する規約（以下「本規約」といいます）をここに定めます。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第2条（顧客）</span></p>
        <p className="text-policy"><span>本サービスの利用者を顧客と言い、顧客は、本規約同意のうえ、弊社所定の方法により本サービスを利用するものとします。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第3条（サービス）</span></p>
        <p className="text-policy"><span>本サービスは、以下の全部または一部を提供します。</span></p>
        <p className="text-policy"><span>（１）　VERYCARD</span></p>
        <p className="text-policy"><span>弊社は、顧客から台紙および文書の製作、ならびにその成果物を指定先に送達する作業を一貫して受託し、成果物を指定先に送達します。当該成果物をVERYCARDと称します。</span></p>
        <p className="text-policy"><span>VERYCARDには信書便物であることを示す表示、および弊社の名称を表示し、平成16年1月28日付け特定信書便の事業許可（総特第10号）に基づき、</span><a href="https://www.verycard.net/license/article.php" target="_blank" rel="noreferrer">信書便約款</a><span>に従い特定信書便事業として提供します。前述に係わらず、VERYCARDの一部については、日本郵便株式会社へ差し出しを代行します。</span></p>
        <p className="text-policy"><span>（２）　商品</span></p>
        <p className="text-policy"><span>弊社は、以下の商品（以下「商品」といいます）を提供します。</span></p>
        <p className="text-policy"><span>　 ① ギフト</span></p>
        <p className="text-policy"><span>　 弊社は、顧客からの発注を受け、商品を販売・配達します。なお、一部商品については、弊社の提携店から販売委託を受け販売・配達します。</span></p>
        <p className="text-policy"><span>　 ② ギフト電報</span></p>
        <p className="text-policy"><span>　 弊社は、顧客からの発注を受け、ギフトを除く商品に添え状（顧客が指定する弊社所定の台紙）を付して、成果物を指定先に配達します。</span></p>
        <p className="text-policy"><span>以下、(1)～(2)を総称して商品等といいます。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第4条（契約の成立）</span></p>
        <p className="text-policy"><span>１　本サービスの利用に関する個別の契約は、顧客がitowaのサイト上でお申し込みに必要なデータを入力し、弊社がデータを受領し、WEBサイト上にお申し込みが完了した旨の表示がなされた時点で、お申し込み完了とし個別の契約が成立します。但し、次の場合には、本サービスのお申し込みを受け付けないことがあります。</span></p>
        <p className="text-policy"><span>（１）お申し込み時の申告事項に、虚偽の記載、誤記、または記載漏れがあったとき</span></p>
        <p className="text-policy"><span>（２）顧客が本サービスの利用料金の支払いが滞った場合、または支払いが遅延するおそれがあるとき</span></p>
        <p className="text-policy"><span>（３）第三者に損害を生じさせるおそれのある目的、または方法で本サービスを利用するとき</span></p>
        <p className="text-policy"><span>（４）本規約または信書便約款の定めに違反すると弊社が判断したとき</span></p>
        <p className="text-policy"><span>（５）弊社の責めに帰すべき事由によらず、指定する日時に商品等のお届けができないとき</span></p>
        <p className="text-policy"><span>（６）その他、弊社が適当でないと判断したとき</span></p>
        <p className="text-policy"><span>２　弊社は、お申し込み完了し個別の契約が成立した後であっても、前項各号のいずれかに該当することが判明した場合には、個別の契約を解除することがあります。この場合、弊社はあらかじめそのことを顧客に通知します。但し、緊急、やむを得ない場合は、この限りではありません。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第5条（配達）</span></p>
        <p className="text-policy"><span>１　商品等の配達は、itowaのサイト上で定められたお届け希望日時までに行います。式典終了や住所不備等で配達ができない場合は、弊社は、お申込者に処分の指図を求めます。</span></p>
        <p className="text-policy"><span>２　お届け希望日までに、配達ができない場合、再送・転送等は行いません。その場合であっても、顧客は、当該利用料金を支払うものとします。但し、弊社の責めに帰すべき事由による場合は、この限りではありません。</span></p>
        <p className="text-policy"><span>３　式典終了や住所不備等により商品の配達ができない場合であって、弊社の責めに帰すべき事由によらず配達ができないときは、弊社の判断により当該品を処分できるものとします。但し、その場合であっても、顧客は当該利用料金を支払うものとします。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第6条（変更・キャンセル等）</span></p>
        <p className="text-policy"><span>１　商品等のお申し込み完了後の、申込内容の変更は、原則、弊社が配達作業に入る前に限り対応します。但し、商品等の種類変更はできません。</span></p>
        <p className="text-policy"><span>２　配達作業開始後に配達の停止を希望する場合は、可能な場合に限り配達停止をします。なお、この場合であっても、顧客は当該利用料金を支払うものとします。</span></p>
        <p className="text-policy"><span>３　弊社は、配達作業開始後であっても、第4条1項の規定により、本サービスを提供しないことができるものとします。</span></p>
        <p className="text-policy"><span>４　商品等のお申し込み完了後の、申込キャンセルについては、原則、お受け付けできません。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第7条（配達の完了）</span></p>
        <p className="text-policy"><span>商品等は、受取人本人、受取人のポスト、メール室等への配達をもって配達完了とします。また、配達先が住宅の場合は、その配達先の同居者またはこれに準ずる者、配達先が住居以外の場合は、その管理者またはこれに準ずる者への配達をもって、配達完了とみなします。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第8条（所有権の移転）</span></p>
        <p className="text-policy"><span>商品等の所有権は、配達完了と同時に顧客に移転するものとします。但し、商品等の配達完了時に、当該利用料金のクレッジトカード会社等の決済会社による口座等引落しが未了の場合、商品等の所有権は、顧客が当該料金を完済まで、クレジットカード会社等の決済会社、または弊社に留保され、当該料金の完済後に、受取人に移転するものとします。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第9条（料金）</span></p>
        <p className="text-policy"><span>顧客は、本サービスの利用料金について、itowaのサイト上に掲載された料金であることを了承した上で、本サービスを利用するものとします。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第10条（支払い）</span></p>
        <p className="text-policy"><span>顧客は、本サービスの利用料金は、本サービスのお申し込み時にitowaのサイト上で決済するものとします。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第11条（債権譲渡）</span></p>
        <p className="text-policy"><span>弊社は、顧客における本サービスの利用料金に関する債権を、弊社が指定する決済会社に譲渡することが出来るものとします。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第12条（禁止事項）</span></p>
        <p className="text-policy"><span>顧客は、本サービスの利用にあたって、以下の行為をしてはならないものとします。</span></p>
        <p className="text-policy"><span>（１）　第三者または弊社の著作権、財産権、プライバシーもしくはその他の権利を侵害する行為、および侵害するおそれのある行為</span></p>
        <p className="text-policy"><span>（２）　第三者または弊社に不利益もしくは損害を与える行為、および与えるおそれのある行為</span></p>
        <p className="text-policy"><span>（３）　公序良俗に反する行為、もしくはそのおそれのある行為、または公序良俗に反する情報を第三者に提供する行為</span></p>
        <p className="text-policy"><span>（４）　犯罪的行為、もしくは犯罪的行為に結び付く行為、またはそのおそれのある行為</span></p>
        <p className="text-policy"><span>（５）　弊社の承諾なく、本サービスを通じて、もしくは本サービスに関連して、営利を目的とする行為、またはその準備を目的とした行為</span></p>
        <p className="text-policy"><span>（６）　コンピュータウィルス等の有害なプログラムを、本サービスを通じてまたは本サービスに関連して使用し、もしくは提供する行為</span></p>
        <p className="text-policy"><span>（７）　法令に違反する、または違反するおそれのある行為</span></p>
        <p className="text-policy"><span>（８）　その他、弊社が不適切と判断する行為</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第13条（権利の帰属等）</span></p>
        <p className="text-policy"><span>１　本サービスを通じて弊社により提供される情報、および画像等（以下、総称して「情報等」といいます）に関する著作権、およびその他の一切の知的財産権等（以下、総称して「知的財産権等」といいます）は、弊社または弊社にライセンス許諾をしている者に帰属します。</span></p>
        <p className="text-policy"><span>２　顧客は、方法または形態の如何を問わず、著作権法に定める私的使用の範囲を超えて、複製、転載、公衆送信、配布、改変、その他の利用をすることはできません。</span></p>
        <p className="text-policy"><span>３　前2項の規定に係わらず、知的財産権等の権利者と顧客間で問題が発生した場合、顧客は、自己の費用と責任においてかかる問題を解決するとともに、弊社に何等の迷惑または損害を与えないものとします。但し、弊社が本サービスを通じて提供する情報等について権利者との間に問題が生じた場合は、弊社が自己の費用と責任においてかかる問題を解決するとともに、顧客に何等の迷惑または損害を与えないものとします。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第14条（改廃）</span></p>
        <p className="text-policy"><span>弊社は、本サービスの内容を予告なく改廃できるものとします。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第15条（サービスの運営、中断または中止）</span></p>
        <p className="text-policy"><span>１　弊社は、次の何れかに該当する場合、自らの判断により本サービスの運営の全部、または一部を中断または中止することができるものとします。</span></p>
        <p className="text-policy"><span>（１）　天災、悪天候、交通事情、事変、その他の非常事態が発生し、または発生するおそれがある場合</span></p>
        <p className="text-policy"><span>（２）　本サービスに関連して、弊社が設置または管理する設備の保守を定期的に、または緊急に行う場合</span></p>
        <p className="text-policy"><span>（３）　弊社が設置または管理する設備の異状、故障、障害、その他本サービスを顧客に提供できない事由が生じた場合</span></p>
        <p className="text-policy"><span>（４）　itowaのサービスの一部または全部が中断されたとき</span></p>
        <p className="text-policy"><span>（５）　itowaのサービスが終了したとき</span></p>
        <p className="text-policy"><span>（６）　弊社が委託する物流業者の業務が停止した場合</span></p>
        <p className="text-policy"><span>（７）　その他、弊社がやむをえないと判断した場合</span></p>
        <p className="text-policy"><span>２　弊社は理由の如何を問わず、本サービスの提供の中断もしくは中止または前項の措置によって生じた顧客の損害につき、一切責任を負わないものとします。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第16条（顧客の損害賠償責任）</span></p>
        <p className="text-policy"><span>顧客は、本規約の違反又は本サービスの利用に関連して弊社に損害を与えた場合、弊社に発生した損害（特別損害、逸失利益及び弁護士費用を含みます。）を賠償します。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第17条（免責・非保証）</span></p>
        <p className="text-policy"><span>１　弊社は、VERY CARDの滅失、き損または遅延による損害について、信書便約款第32条各号の何れかに該当する場合、損害賠償の責任を負わないものとします。また、商品の滅失、き損または遅延による損害について、信書便約款第32条第三号から第八号の何れかに該当する場合、弊社の責めに帰すべき事由によらない場合、またはその他、弊社がやむを得ないと判断した場合、一切責任を負わないものとします。</span></p>
        <p className="text-policy"><span>２　本サービスを通じて弊社より提供される情報等を利用する事により被った被害について、弊社は一切の責任を負いません。</span></p>
        <p className="text-policy"><span>３　弊社は、顧客に対して、商品等、および本サービスの内容について、その完全性、正確性および有効性等について、一切の保証をしません。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第18条（返品等）</span></p>
        <p className="text-policy"><span>１　弊社は、配達した商品等に品物間違い、または破損・汚損・不良等の契約の不適合があった場合、配達完了後7日以内にご連絡をいただいた場合に限り、無料で代替品と交換、不足数量の納品、または利用料金を返金します。直ちに発見することができない契約の不適合があった場合でも、配達完了後7日以上を経過したとき、または使用後等の返品、不足数量の納品、返金、交換、損害賠償等には応じかねます。</span></p>
        <p className="text-policy"><span>２　前項に係わらず、弊社の責めに帰すべき事由によらない場合、または配達先の都合により、商品等の配達が遅延または不能となった場合は、商品等の破損・汚損・不良品等の契約の不適合（直ちに発見することができない契約の不適合を含む）について弊社は一切の責任を負いません。</span></p>
        <p className="text-policy"><span>３　本条１項に従い返金を行う場合は、原則、弊社は顧客の指定する銀行口座へ振り込みを行います。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第19条（損害賠償責任）</span></p>
        <p className="text-policy"><span>１　弊社は、本サービスの提供にあたり、商品等の滅失、き損、または遅延が発生した場合で、第17条の免責の対象とならない損害に限り、顧客の指示により次の各号に該当する方法で損害賠償の責任を負うものとします。</span></p>
        <p className="text-policy"><span>（１）　当該商品等の代替品、不足分の無償配達</span></p>
        <p className="text-policy"><span>（２）　当該商品等の利用料金の返金</span></p>
        <p className="text-policy"><span>２　前項の定めを除き、本サービスの利用に起因したその他のいかなる損害についても弊社は一切の責任を負わないものとします。但し、弊社が特定信書便事業として送達を行うVERYCARDについては、弊社の故意または重大な過失によって、滅失、き損、または遅延が生じた場合は、それにより生じた一切の損害を賠償します。</span></p>
        <p className="text-policy"><span>第20条（個人情報）</span></p>
        <p className="text-policy"><span>弊社は、個人情報の取り扱いについて、別途定める「個人情報のお取扱いについて」を遵守します。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第21条（反社会的勢力の排除）</span></p>
        <p className="text-policy"><span>１　顧客および弊社は、反社会的勢力を排除するため相手方に対し、次の各号について表明し、かつ将来にわたっても該当しないことを保証します。</span></p>
        <p className="text-policy"><span>（１）　暴力団、暴力団員、暴力団員でなくなったときから５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者（以下「暴力団員等」という）</span></p>
        <p className="text-policy"><span>（２）　暴力団員等が経営を支配していると認められる関係を有すること</span></p>
        <p className="text-policy"><span>（３）　暴力団員等が経営に実質的に関与していると認められる関係を有すること</span></p>
        <p className="text-policy"><span>（４）　自己若しくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること</span></p>
        <p className="text-policy"><span>（５）　暴力団員等に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること</span></p>
        <p className="text-policy"><span>（６）　役員または経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること</span></p>
        <p className="text-policy"><span>２　顧客および弊社は、自らまたは第三者を利用して次の各号に該当する行為を行わないことを確約します。</span></p>
        <p className="text-policy"><span>（１）　暴力的な要求行為</span></p>
        <p className="text-policy"><span>（２）　法的な責任を超えた不当な要求行為</span></p>
        <p className="text-policy"><span>（３）　取引に関して、脅迫的な言動をし、または暴力を用いる行為</span></p>
        <p className="text-policy"><span>（４）　風説を流布し、偽計を用いまたは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為</span></p>
        <p className="text-policy"><span>（５）　その他前各号に準ずる行為</span></p>
        <p className="text-policy"><span>３　弊社は、顧客が本条に違反する事実を知った場合、直ちに顧客の登録を削除し、顧客による本サービスの利用を終了することができるものとします。弊社は、これにより顧客に発生した損害について一切の責を負わないものとし、顧客は、弊社が被った一切の損害（間接損害・特別損害を含む）について、賠償する責任を負うものとします。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第2２条（業務提携）</span></p>
        <p className="text-policy"><span>１　弊社は、本サービスの発展の為に企業、組織または団体（以下「提携先」といいます）と業務提携の契約をする場合があります。</span></p>
        <p className="text-policy"><span>２　顧客は、前項業務提携および提携先について異議なく承認するものとします。</span></p>
        <p className="text-policy"><span>３　弊社には提携先を公表する義務はないものとします。</span></p>
        <p className="text-policy"><span>４　弊社は、提携先が本規約の関連条項を遵守することを保証します。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第23条（本規約の適用範囲および変更）</span></p>
        <p className="text-policy"><span>１　本規約は、本サービスの利用に関し、顧客・弊社間における契約の内容の詳細を定めるものであり、個別の条項について双方において合意されたものとみなします。</span></p>
        <p className="text-policy"><span>２　今後提供する新サービス毎に規定する個別規定は、本規約の一部を構成します。なお、本規約とその他の規約等の内容が異なる場合は、本規約が優先して適用されます。</span></p>
        <p className="text-policy"><span>３　弊社は、合理的な範囲・方法により、顧客の格別の承諾を得ることなく本規約を適宜変更でき、顧客はあらかじめこれを承諾します。その場合には、本サービス提供条件は、変更後の規定によります。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第24条（準拠法）</span></p>
        <p className="text-policy"><span>本規約の成立、効力、履行および解釈に関しては、日本法が適用されるものとします。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>第25条（管轄裁判所）</span></p>
        <p className="text-policy"><span>１　本サービスに関連して、顧客と弊社との間で紛争が生じた場合には、当事者間において誠意をもって解決するものとします。</span></p>
        <p className="text-policy"><span>２　協議をしても解決しない場合、東京地方裁判所または東京簡易裁判所を専属的合意裁判所とすることに合意します。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>佐川ヒューモニー株式会社</span></p>
        <p className="text-policy"><span>TEL06-7739-4411&nbsp;</span></p>
        <p className="text-policy"><span>MAIL　info@verycard.net</span></p>
        <p className="text-policy"><span><a href="http://www.verycard.net/" target="_blank" rel="noreferrer">http://www.verycard.net/</a></span></p>
        <p className="text-policy"><span>制定日　2024年2月19日</span></p>
        <p className="text-policy"><br /></p>
        <div className="break-line-policy"/>
        <p><br/></p>
        <p className="text-policy-center"><strong><span>個人情報取得同意</span></strong></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>佐川ヒューモニー株式会社（以下、「当社」）は、 お客様の個人情報について適切に保護することが個人情報取扱事業者としての重要な責務であると認識しております。 当社は、個人情報保護法に基づき、お客様の個人情報を適正に取扱い、以下に記載する内容を遵守し、個人情報保護に努めてまいります。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>●個人情報の取扱いについて</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; 当社は、個人情報の取扱いにおいて、法令及び国が定める指針その他の規範および当社の内部規定に則り適切な安全管理対策を実施し、個人情報を保護致します。当社の個人情報に関する責任者は個人情報保護管理者とし、経営企画部門取締役がこの任にあたります。個人情報保護管理者の連絡先は、お客様相談室と致します。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>●個人情報の利用目的について</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; 当社は、電報サービス等の提供・販売等に際して個人情報を取扱うに当たり、次のとおり利用目的を定めます。</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; &nbsp; &nbsp; (1)電報サービス等の提供・販売のため</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; &nbsp; &nbsp; (2)電報サービス等の申し込み、相談の受付、これらに関するご連絡のため</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; &nbsp; &nbsp; (3)電報サービス等に関する各種紹介、ご提案のため</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; &nbsp; &nbsp; (4)本人確認法に基づくご本人様の確認等や電報サービス等をご利用いただく資格等の確認のため</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; &nbsp; &nbsp; (5)与信管理、電報サービス等のご利用料金計算及び請求・回収・決済、これらに関わるお客様へのご連絡のため</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; &nbsp; &nbsp; (6)当社のお客様相談室その他の相談窓口へのご相談・ご要望等(個人情報の開示等のお申し出等を含みます。)に対応をするため</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; &nbsp; &nbsp; (7)電報サービス及びその他の事業に附随するサービスに関するご案内、これらに関連するキャンペーン等のご案内、情報提供、及びアンケート調査等のご協力依頼のため</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; &nbsp; &nbsp; (8)ご意見、ご相談等の内容、及びアンケート調査結果に基づく、電報サービス及びその他の事業に附随するサービスの品質改善・顧客満足度向上、及び新たな商品・サービス等の企画・開発のため</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; &nbsp; &nbsp; (9)懸賞の抽選、当選商品送付、アンケート調査等に対する謝礼の送付、及びこれらのご連絡のため</span></p>
        <p className="text-policy"><span>●共同利用に関する事項</span></p>
        <p className="text-policy"><span>当社は、上記に定める利用目的において、当社サービスが連携する株式会社itowaの下記記載情報を共同利用いたします。</span></p>
        <p className="text-policy"><span>（１）</span><span>&nbsp; &nbsp;&nbsp;</span><span>共同利用する項目</span></p>
        <p className="text-policy"><span>お申込者の氏名・性別・住所・電話番号・メールアドレス、お届け先の氏名・電話番号・住所・FAX番号</span></p>
        <p className="text-policy"><span>（２）</span><span>&nbsp; &nbsp;&nbsp;</span><span>共同して利用する者の範囲</span></p>
        <p className="text-policy"><span>株式会社itowa及び佐川ヒューモニー株式会社</span></p>
        <p className="text-policy"><span>（３）共同して利用する個人データの管理について責任を有する者</span></p>
        <p className="text-policy"><span>　　　 株式会社itowa　代表取締役　坂元　充</span></p>
        <p className="text-policy"><span>　　　 愛知県名古屋市中村区平池町4-60-12</span></p>
        <p className="text-policy"><span>　　　 グローバルゲート 11F</span></p>
        <p className="text-policy"><span>（４）共同利用する個人情報の取得方法　</span></p>
        <p className="text-policy"><span>電子データ</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>●個人情報の第三者提供について</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; 当社は、法令に定める場合を除き、お客様の個人情報をあらかじめ本人の同意を得ることなく、第三者に対し開示、提供することは致しません。&nbsp;</span></p>
        <p className="text-policy"><span>　　また、当社は、当社のサイトにおいて、広告の効果測定のため、第三者の運営するツールから当社のサイトに訪れる前にクリックされている広告の情報（クリック日や広告掲載サイトなど）を取得し、ご注文の情報と照合する場合がございます。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>●個人情報の委託について</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; 当社は、利用目的の達成に必要な範囲内において、個人情報の取扱を業務委託先に委託する場合があります。委託に際しては、個人情報保護に関する契約を締結するなど必要かつ適切な措置を講じます。&nbsp;</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>●個人情報の開示等(開示・訂正等)の手続きについて</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; 当社は、ご本人またはその代理人からのご依頼により、利用目的の通知又は個人情報の開示、もしくは個人情報の訂正、追加、削除又は利用・第三者提供の停止のお申出について、以下「個人情報に関するお客様相談室」にて対応いたします。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>&nbsp; &nbsp; 【個人情報についてのお問い合わせ窓口】</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; &nbsp; &nbsp; 〒136-0075　東京都江東区新砂2丁目２番８号</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; &nbsp; &nbsp; 個人情報に関するお客様相談室</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; &nbsp; &nbsp; Mail：info@keicho.net</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>●個人情報ご提供頂く任意性及び留意点について</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; 当社が取得する個人情報は、すべてお客様のご意思によってご提供頂きます。Webに記載されている個人情報の入力によって得られるサービスの内容をご確認の上、ご入力下さい。 必要な個人情報をご提供頂けない場合、当社のサービス等がご利用できない場合がございます。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>●改訂について</span></p>
        <p className="text-policy"><span>&nbsp; &nbsp; 当社では、利用目的の変更、また関連法令および規範の改訂等に応じて本内容を改訂する場合がございます。お客様におかれましては、当社Webサイト等にて定期的にご確認ください。</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>佐川ヒューモニー株式会社</span></p>
        <p className="text-policy"><span>代表取締役　青木 淳一</span></p>
        <p className="text-policy"><br /></p>
        <p className="text-policy"><span>制定日　2024年２月19日</span></p>
      </Modal.Body>
      <div className="btn-close-policy">
        <div className="checkbox-container">
          <label><input className="check checkbox-policy" id="check" type="checkbox" onChange={handleChanged} />　利用規約と個人情報のお取り扱いについて同意する</label>
        </div>
        <button type="button" className="btn btn-promary black-button btn-confirm-sagawa" disabled={!checkPolicy} onClick={() => handelConfirm()}>続ける</button>
      </div>
    </Modal>
  );
}

export default ModalPolicySagawa;
