export const RelationSelect = (props) => {
  const { name, label, formik } = props; 
  const hasError = formik && formik.errors[name];

  return (
    <>
      <label className="control-label">{label}</label>
      <select className={"custom-select" + (hasError ? " is-invalid" : "")} name={name} {...formik.getFieldProps(name)}>
        <option value=""></option>
        <option value="A">親族</option>
        <option value="B">一般</option>
        <option value="C">仕事関係</option>
        <option value="Z">その他</option>
      </select>
      <div className="invalid-feedback">{formik.errors[name]}</div>
    </>
  )
}

export default RelationSelect;