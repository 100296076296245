import React from "react";
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify'

import useLoading from 'components/Loading';
import useMessage from 'components/Message';

const ReceiveMoneySetting = ({ showPopup, setShowPopup, listOrdersSelected, funeralId, funeralInfo, setFuneralInfo, setShowButton, getListAttendee }) => {
  const loading = useLoading();
  const message = useMessage();
  const history = useHistory();

  const handleCancel = () => {
    setShowPopup(false);
  };

  const handleAccept = async () => {
    loading(true);
    setShowPopup(false);
    try {
      const response = await updateFuneralByChiefMourner(funeralId, listOrdersSelected);
      if(!response.error) {
        setFuneralInfo({
         ...funeralInfo,
         orderIds: listOrdersSelected,
        });
        getListAttendee();

        if (listOrdersSelected?.length > 0) {
          if (!response?.hasBankAccount) {
            history.push("/" + funeralId + "/funeral/connect");
          } else {
            history.push("/" + funeralId + "/funeral/gift");
          }
        }
        setShowButton(false);
      }
    } catch (e) {
      if (e?.response?.status === 400) {
        message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
      } else {
        message.alert.danger("システムエラーが発生しました");
      }
    } finally {
      loading(false);
    }
  };

  return (
    <Modal show={showPopup} centered={true} className='receivedDeniedWrapper' onHide={() => setShowPopup(false)}>
      <Modal.Header closeButton={true}></Modal.Header>
      <Modal.Body>
        <div className="boxHeader">
          <img className="giftLogo" src="/images/icon-funeral-gift.png" alt="" />
          <h4 style={{textAlign: "center"}}>オンライン香典</h4>
        </div>
        <ul>
        <li>
            <img src="/images/icon-funeral-phone.png" alt="" />
            <span>① お受け取りには必ず口座登録が必要です</span>
          </li>
          <li>
            <img src="/images/icon-funeral-company.png" alt="" />
            <span>② 振込時決済手数料3.85% 差引かれます</span>
          </li>
          <li>
            <img src="/images/icon-funeral-money.png" alt="" />
            <span>③ 返礼品のご注文が簡単に行えます</span>
          </li>
        </ul>
        <p>※受取チェックが外れている方のオンライン香典はキャンセルとなります。 確定を押すと変更できません、ご注意ください。</p>
        <div className='button-wrapper'>
          <button className='cancel-button' onClick={handleCancel}>やり直す</button>
          <button className='accept-button' onClick={handleAccept}>確定</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const updateFuneralByChiefMourner = async (funeralId, orderIds) => {
  const options = {
    body: { orderIds }
  };
  return await API.put('product', `/funerals/${funeralId}/chief-mourner/confirm-gift-money`, options);
};

export default ReceiveMoneySetting;