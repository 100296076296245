import React, { useRef } from 'react';
import { API } from 'aws-amplify'
import { useHistory, Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Pagination from 'components/Pagination';

export const UserList = (props) => {
  const PER_PAGE = 10;

  const [userList, setUserList] = React.useState([]);
  const [pagenatedList, setPagenatedList] = React.useState([]);
  const history = useHistory();
  const searchKey = useRef("");
  const selectKey = useRef("");

  const search = async () => {
    const result = await getUserList(searchKey.current.value, selectKey.current.value);
    const resultList = result.userList.sort((a, b) => a.UserId < b.UserId ? -1 : 1);
    setUserList(resultList);
    setPagenatedList(resultList.slice(0, PER_PAGE));
  }

  const create = () => {
    history.push("/users/new");
  }

  const renderRole = (role) => {
    switch(role) {
      case 'manager':
        return '管理者';
      case 'sagawa':
        return '佐川管理者';
      case 'call':
        return 'コールセンター';
      default:
        return '';
    }
  }

  return (
    <CommonLayout>
      <ContentHeader>
        管理者ユーザ一覧
      </ContentHeader>

      <div className="form-group">
        <Row>
          <Col md="6">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">検索条件</h3>
              </div>

              <div className="card-body"><Row>
                <Col md="6">
                  <label className="control-label">ユーザ名</label>
                  <input type="text" className="form-control" ref={searchKey} placeholder="ユーザ名（部分一致）" />
                </Col>
                <Col md="6">
                  <label className="control-label">権限</label>
                  <select className="custom-select" ref={selectKey}>
                    <option value=""></option>
                    <option value="manager">管理者</option>
                    <option value="call">コールセンター</option>
                    <option value="sagawa">佐川管理者</option>
                  </select>
                </Col>
              </Row>
              </div>
              <div className="card-footer">
                <Button className="float-right" onClick={() => search()}>検索</Button>
                {' '}
                <Button variant="success" className="float-left" onClick={() => create()}>新規作成</Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Row>
        <Col md="6">
          <div className="card">
            <div className="card-body p-0">
              <table className="table">
                <thead>
                  <tr>
                    <th>メールアドレス</th>
                    <th>ユーザ名</th>
                    <th>電話番号</th>
                    <th>権限</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    pagenatedList.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td><Link to={"/users/" + row.UserId}>{row.UserId}</Link></td>
                          <td>{row.UserName}</td>
                          <td>{row.PhoneNo}</td>
                          <td>{renderRole(row.Role)}</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <Pagination per={PER_PAGE} list={userList} onChange={(list) => setPagenatedList(list)} />
            </div>
          </div>
        </Col>
      </Row>
    </CommonLayout>
  );
};

const getUserList = async (keyword, selectRole) => {
  const options = {
    queryStringParameters: {
      keyword: keyword,
      selectRole: selectRole
    }
  };

  return await API.get('product', '/users/admin', options);
}

export default UserList;