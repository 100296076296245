import React, { useState, useEffect, useCallback } from 'react';
import { API } from 'aws-amplify';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { Form, Radio, Popconfirm } from 'antd';

import { DeleteOutlined, VerticalAlignTopOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import { useLoading } from 'components/Loading';
import useMessage from 'components/Message';

import * as Constants from '../../constants';

export const FuneralTimeScheduleTemplates = (props) => {
  const match = useRouteMatch();
  const companyId = match?.params?.companyId || null;
  const method = props?.method || 'update';
  const duplicateId = props.location?.state?.id ? props.location?.state?.id : '';
  const templateId = match?.params?.templateId ? match?.params?.templateId : duplicateId ? duplicateId : '';

  const hours = [...Array(24)].map((_, i) => ('0' + i).slice(-2));
  const minutes = [...Array(12)].map((_, i) => ('0' + (i * 5)).slice(-2));

  const negativeHours = [...Array(13)].map((_, i) => (`${(i < 3 ? '-' : '-0') + Math.abs(i - 12)}`).slice(-3));
  const positiveHours = [...Array(13)].map((_, i) => (`${'0' + i}`).slice(-2));
  const funeralHours = [...negativeHours, ...positiveHours];


  const history = useHistory();
  const message = useMessage();
  const loading = useLoading();

  const [optionsItem, setOptionsItem] = useState([]);
  const [rerenderHourOptions, setRerenderHourOptions] = useState(false);

  const [form] = Form.useForm();

  const fetchData = async () => {
    loading(true);
    try {
      const [scheduleTemplates, scheduleItems] = await Promise.all([
        getTimeScheduleTemplates(companyId),
        getScheduleItemList(companyId),
      ]);
      setOptionsItem(scheduleItems?.scheduleItemList || []);
      if (method === 'update' || duplicateId) {
        const currentTemplate = scheduleTemplates?.scheduleTemplateList?.find(item => item?.Id === templateId);
        if (currentTemplate?.Id) {
          let scheduleSets = [
            {
              items: [{}],
              timeSettingType : 'time-settings',
              title: '',
            }
          ];
          if (currentTemplate?.ScheduleSets?.length > 0) {
            scheduleSets = currentTemplate?.ScheduleSets?.map((values) => ({
              timeSettingType : values?.TimeSettingType  || 'time-settings',
              title: values?.Title || '',
              items: values?.Items?.map((item) => ({
                itemName: item?.ItemName || '',
                hour: item?.Hour || '',
                minute: item?.Minute || '',
                comment: item?.Comment || '',
              })) || [],
            }));
          }

          const formValues = {
            scheduleSets: scheduleSets,
            scheduleTemplateName: currentTemplate?.ScheduleTemplateName || '',
          };
          form.setFieldsValue(formValues);
        }
      } else {
        // clear data when navigate from edit page to creat page
        form.resetFields();
      }
    } catch (e) {
      if (e?.response?.status === 400) {
        message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
      } else {
        message.alert.danger("システムエラーが発生しました");
      }
    } finally {
      loading(false);
    }
  };

  useEffect(() => {
    fetchData();
    
    // eslint-disable-next-line
  }, [templateId, duplicateId]);

  const handleSave = async (values) => {
    loading(true);
    try {
      if (templateId && method === 'update') {
        const data = {
          ...values,
          companyId: companyId,
        }
        await updateTimeScheduleTemplates(templateId, data);
        message.alert.success("スケジュールテンプレートを編集しました。");
        history.push(`/${companyId}/time-schedule-template-list`)
      } else {
        const data = {
          ...values,
          companyId: companyId,
        }
        await createTimeScheduleTemplates(data);
        message.alert.success("スケジュールテンプレートを作成しました。");
        history.push(`/${companyId}/time-schedule-template-list`)
      }
    } catch (e) {
      if (e?.response?.status === 400) {
        message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
      } else {
        message.alert.danger("システムエラーが発生しました");
      }
    } finally {
      loading(false);
    }
  }

  const handleDelete = () => {
    message.confirm.danger({
      title: "葬家を削除",
      message: "削除してもよろしいですか？",
      onSubmit: async () => {
        try {
          await deleteScheduleTemplate(companyId, templateId);
          message.alert.success("葬家を削除しました");
          history.push(`/${companyId}/time-schedule-template-list`)
        } catch (e) {
          if (e?.response?.status === 400) {
            message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
          } else {
            message.alert.danger("システムエラーが発生しました");
          }
        }
      }
    });
  }

  const renderHourOptions = useCallback((timeSettingType ) => {
    if (timeSettingType  === 'time-settings') {
      return (
        hours.map((h, i) => <option key={i} value={h}>{h}</option>)
      );
    } else {
      return (
        funeralHours.map((h, i) => <option key={i} value={h}>{h}</option>)
      );
    }

    // eslint-disable-next-line
  }, [rerenderHourOptions]);

  return (
    <CommonLayout>
      <ContentHeader>葬儀タイムスジュールテンプレート管理</ContentHeader>
      <Form
        layout="vertical"
        form={form}
        name="time-schedule"
        autoComplete="off"
        onFinish={handleSave}
        initialValues={{
          scheduleSets: [{
            items: [{}],
            timeSettingType : 'time-settings',
            title: '',
          }],
          scheduleTemplateName: ''
        }}
      >
        <Row>
          <Col lg="12">
            <div className="card funeralTimeSchedule">
              {method === 'update' && !duplicateId && (
                <div className="card-header">
                  <Button variant="light" onClick={() => history.push(`/${companyId}/time-schedule-templates/new`)}>+テンプレートを追加</Button>
                  <Button variant="light" onClick={() => history.push(`/${companyId}/time-schedule-templates/new`, { id: templateId })}>複製</Button>
                  <Button variant="dark" onClick={handleDelete}>テンプレートを削除</Button>
                </div>
              )}
              <div className="card-body">
                <Row>
                  <Col sm="12" md="5" lg="3">
                    <Form.Item label="テンプレート名" name="scheduleTemplateName">
                      <input className="form-control" placeholder="例:葬儀のみ(最大10文字)" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.List name="scheduleSets">
                  {(fields, { add, remove }) => (
                    <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                      {fields.map((field) => (
                        <div key={field?.key}>
                          <Row className="titleWrapper">
                            <Col sm="12" md="3">
                              <Form.Item label="タイトル" name={[field.name, 'title']}>
                                <input className="form-control" placeholder="例:葬儀(最大23文字)" />
                              </Form.Item>
                            </Col>
                            <Col sm="12" lg="3" xl="2">
                              <Button variant="light" onClick={() => add({
                                items: [{}],
                                timeSettingType: 'time-settings'
                              })}>+タイトルを追加</Button>
                            </Col>
                            <Col sm="12" lg="3" xl="2">
                              <Popconfirm
                                description="このタイトルを削除しますか?"
                                onConfirm={() => {
                                  remove(field.name);
                                }}
                                okText="削除"
                                cancelText="キャンセル"
                              >
                                <Button variant="dark">タイトルを削除</Button>
                              </Popconfirm>
                            </Col>
                            <Col sm="12" lg="12" xl="5">
                              <Form.Item name={[field.name, 'timeSettingType']}>
                                <Radio.Group onChange={() => setRerenderHourOptions((prev) => !prev)}>
                                  {Constants?.SETTING_DATETIME?.map((dt) => (
                                    <Radio value={dt?.value}>{dt?.label}</Radio>
                                  ))}
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>

                          <Form.Item>
                            <Form.List name={[field.name, 'items']}>
                              {(subFields, subOpt) => (
                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                  <div className="listItem listLabel">
                                    <Row>
                                      <Col sm="12" md="3">
                                        <label>項目</label>
                                      </Col>
                                      <Col sm="12" md="2">
                                        <label>時間</label>
                                      </Col>
                                      <Col sm="12" md="2">
                                        <label>分</label>
                                      </Col>
                                      <Col sm="12" md="3">
                                        <label>コメント</label>
                                      </Col>
                                    </Row>
                                  </div>
                                  {subFields.map((subField, index) => (
                                    <div className="listItem" key={subField.key}>
                                      <Row>
                                        <Col xs="6" md="3">
                                          <Form.Item label="項目" name={[subField.name, 'itemName']}>
                                            <select className="custom-select">
                                              <option value=""></option>
                                              {optionsItem?.map((item, index) => <option key={item?.Id || index} value={item?.Id}>{item?.Name || ''}</option>)}
                                            </select>
                                          </Form.Item>
                                        </Col>
                                        <Col xs="3" md="2">
                                          <Form.Item label="時間" name={[subField.name, 'hour']}>
                                            <select className="custom-select">
                                              <option value=""></option>
                                              {renderHourOptions(form.getFieldValue('scheduleSets')?.[field?.name]?.timeSettingType)}
                                            </select>
                                          </Form.Item>
                                        </Col>
                                        <Col xs="3" md="2">
                                          <Form.Item label="分" name={[subField.name, 'minute']}>
                                            <select className="custom-select">
                                              <option value=""></option>
                                              {minutes.map((m, i) => <option key={i} value={m}>{m}</option>)}
                                            </select>
                                          </Form.Item>
                                        </Col>
                                        <Col sm="12" md="3">
                                          <Form.Item label="コメント" name={[subField.name, 'comment']}>
                                            <input className="form-control" maxLength={30} />
                                          </Form.Item>
                                        </Col>
                                        <Col sm="12" md="2">
                                          <div className='actions'>
                                            <VerticalAlignTopOutlined
                                              onClick={() => {
                                                subOpt.move(index, index - 1);
                                              }}
                                            />
                                            <VerticalAlignBottomOutlined
                                              onClick={() => {
                                                subOpt.move(index, index + 1);
                                              }}
                                            />
                                            <Popconfirm
                                              description="この項目を削除しますか？"
                                              onConfirm={() => {
                                                subOpt.remove(subField.name);
                                              }}
                                              okText="削除"
                                              cancelText="キャンセル"
                                            >
                                              <DeleteOutlined />
                                            </Popconfirm>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                  <Row>
                                    <Col sm="12" md="10">
                                      <Button className="button-add-item" variant="light" onClick={() => subOpt.add()} disabled={subFields?.length >= 18}>+項目を追加</Button>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </Form.List>
                          </Form.Item>
                        </div>
                      ))}
                    </div>
                  )}
                </Form.List>
              </div>

              <div className="card-footer">
                <div>
                  <Button type="submit" variant="success">保存</Button>
                  <Button variant="secondary" onClick={() => history.goBack()}>戻る</Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </CommonLayout>
  );
};

const getTimeScheduleTemplates = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }

  return await API.get('product', '/schedule-template', options);
}

const getScheduleItemList = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
    }
  };

  return await API.get('product', '/schedule-item', options);
}

const createTimeScheduleTemplates = async (values) => {
  const options = {
    body: values
  }
  return await API.post('product', '/schedule-template/', options);
}

const updateTimeScheduleTemplates = async (templateId, values) => {
  const options = {
    body: values
  }
  return await API.put('product', '/schedule-template/' + templateId, options);
}

const deleteScheduleTemplate = async (companyId, scheduleTemplateId) => {
  const options = {
    body: {
      companyId: companyId,
      scheduleTemplateId: scheduleTemplateId
    }
  }
  return await API.del('product', '/schedule-template', options);
}

export default FuneralTimeScheduleTemplates;
