
export const Input = ({type, name, className, value, formik, ...props}) => {
  switch(type) {
    case "checkbox":
      return (
        <input type="checkbox" className={className} {...props} onChange={(e) => formik.setFieldValue(name, e.target.checked)} checked={formik.values[name]}/>
      )
    case "radio":
      return (
        <input type="radio" name={name} className={className} {...props} value={value} onChange={(e) => formik.setFieldValue(name, e.target.value)} onBlur={(e) => formik.setFieldValue(name, e.target.value)} checked={formik.values[name] === value} />
      )
    case "file":
      return (
        <>
          <input type="file" className={className} {...props} onChange={(e) => {
            const reader = new FileReader();
            if(e.target.files && e.target.files[0]) {
              reader.readAsDataURL(e.target.files[0]);
              reader.onload = (ev) => {
                formik.setFieldValue(name, reader.result);
              }
            }
          }}/>
        </>
      )
    case "textarea":
      return (
        <>
          <textarea className={className} {...props} {...formik.getFieldProps(name)}/>
          <div className="invalid-feedback">{formik.errors[name]}</div>
        </>
      )
    default:
      const hasError = formik && formik.errors[name];
      return (
        <>
          <input type={type} className={className + (hasError ? " is-invalid" : "")}  {...props} {...formik.getFieldProps(name)} />
          <div className="invalid-feedback">{formik.errors[name]}</div>
        </>
      );
  }
}

export default Input;