import { useState } from 'react';
import { API } from 'aws-amplify'
import { Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import * as Constants from '../constants';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Pagination from 'components/Pagination';
import DateTime from 'components/CustomForms/DateTime';

export const AccessLogList = (props) => {
  const PER_PAGE = 10;

  const [accessLogList, setAccessLog] = useState([]);
  const [pagenatedList, setPagenatedList] = useState([]);

  const companyId = window.location.hostname === Constants.ADMIN_DOMAIN ? "admin" : props.match.params.companyId;

  const formik = useFormik({
    initialValues: {
      searchFrom: moment().add(-6, "days").format("YYYY-MM-DD 00:00"),
      searchTo: moment().add(1, "days").format("YYYY-MM-DD 00:00"),
      searchUserId: ""
    },
    validationSchema: Yup.object({
      searchFrom: Yup.string().required("Fromを入力してください"),
      searchTo: Yup.string().required("Toを入力してください")
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        const result = await getAccessLog(companyId, values);
        const resultList = result.accessLogList.sort((a, b) => a.AccessDateTime < b.AccessDateTime ? 1 : -1);
        setAccessLog(result.accessLogList);
        setPagenatedList(resultList.slice(0, PER_PAGE));
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  return (
    <CommonLayout>
      <ContentHeader>
        操作ログ
      </ContentHeader>

      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <Row>
            <Col>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">検索条件</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <Row>
                      <Col md="5">
                        <label>日付(From)</label>
                        <DateTime name="searchFrom" formik={formik} />
                      </Col>
                      <Col md="5">
                        <label>日付(To)</label>
                        <DateTime name="searchTo" formik={formik} />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    
                    <label>メールアドレス</label>
                    <input type="text" className="form-control" placeholder="メールアドレス" />
                  </div>
                </div>
                <div className="card-footer">
                  <Button type="submit" className="float-right">検索</Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </form>

      <Row>
        <Col>
          <div className="card">
            <div className="card-body p-0">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: "12rem" }}>アクセス日時</th>
                    <th style={{ width: "10rem" }}>IPアドレス</th>
                    <th>ユーザ名(メールアドレス)</th>
                    <th>操作内容</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    pagenatedList.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td>{row.AccessDateTime}</td>
                          <td>{row.SourceIp}</td>
                          <td>{row.UserName}({row.UserId})</td>
                          <td>{row.LogInfo}</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <Pagination per={PER_PAGE} list={accessLogList} onChange={(list) => setPagenatedList(list)} />
            </div>
          </div>
        </Col>
      </Row>
    </CommonLayout>
  );
};

const getAccessLog = async (companyId, values) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
      searchFrom: values.searchFrom,
      searchTo: values.searchTo,
      searchUserId: values.searchUserId
    }
  };

  return await API.get('product', '/accesslogs', options);
}

export default AccessLogList;