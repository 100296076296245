import React, { useState, useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Button, Modal, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import uuid from 'uuid';

import { AttendanceContext, AuthContext } from 'components/Contexts';
import useMessage from 'components/Message';

const ModalRegistrationAttendance = ({ showAttendance, onClose }) => {
  const match = useRouteMatch("/:funeralId");
  const message = useMessage();
  const { funeralInfo } = useContext(AttendanceContext);
  const { userInfo } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const funeralId = match.params.funeralId;

  const getAttendanceLocal = () => {
    try {
      return JSON.parse(localStorage.getItem('intendJoins'));
    } catch (_) {
      return {};
    }
  };

  const attendanceLocal = getAttendanceLocal();
  
  const formik = useFormik({
    initialValues: {
      attendance: "",
    },
    validationSchema: Yup.object({
      attendance: Yup.string().required("確認する前にオプションを選択してください"),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        setLoading(true);
          try {
            const attendanceData = {
              intendJoins: {
                userId: attendanceLocal?.userId || userInfo?.email || uuid.v4(),
                selectedValue: values?.attendance || "funeral",
              }
            }
            
            const response = await registrationAttendance(funeralId, attendanceData);

            if (!response.error) {
              message.alert.success("ギフト受け取り設定が正常に更新されました");
              localStorage.setItem('intendJoins', JSON.stringify(attendanceData?.intendJoins));
              onClose();
            } else {
              message.alert.danger("システムエラーが発生しました");
            }
          } catch (e) {
            if (e?.response?.status === 400) {
              message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
            } else {
              message.alert.danger("システムエラーが発生しました");
            }
          } finally {
            setLoading(false);
          }
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const setFieldValue = formik.setFieldValue;
  const errors = formik.errors;

  useEffect(() => {
    if (attendanceLocal?.selectedValue) {
      formik.setFieldValue('attendance', attendanceLocal?.selectedValue);
    }

    // eslint-disable-next-line
  }, [attendanceLocal?.selectedValue]);

  return (
    <React.Fragment>
      <Modal show={showAttendance} onHide={onClose} centered={true} className='registrationAttendanceWrapper'>
        <Modal.Header closeButton={true} />
        <Modal.Body>
          <div className="boxHeader">
            <img className="giftLogo" src="/images/icon-funeral-gift.png" alt="" />
            <h4 style={{textAlign: "center"}}>参列意志表示の<br />お願い</h4>
            <p>葬家側に参列人数を<br />お知らせるため参列<br />意志を教えてください</p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="settingGiftGroup">
              <Form.Group controlId="attendance">
                {funeralInfo?.vigilDateTime ? (
                  <>
                    <Form.Check checked={formik?.values?.attendance === 'vigil'} name="attendance" label="通夜のみ参列" type="radio" onChange={() => setFieldValue('attendance', 'vigil')} />
                    <Form.Check checked={formik?.values?.attendance === 'funeral'} name="attendance" label="葬儀のみ参列" type="radio" onChange={() => setFieldValue('attendance', 'funeral')} />
                    <Form.Check checked={formik?.values?.attendance === 'both'} name="attendance" label="両方参列" type="radio" onChange={() => setFieldValue('attendance', 'both')} />
                  </>
                ) : (
                  <Form.Check checked={formik?.values?.attendance === 'funeral'} name="attendance" label="参列する" type="radio" onChange={() => setFieldValue('attendance', 'funeral')} />
                )}
                {errors?.attendance !== '' && <div className="invalid-feedback show">{errors?.attendance}</div>}
              </Form.Group>
            </div>
            <Button type="submit" variant="warning" className='btn-confirm' disabled={loading}>確定</Button>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

const registrationAttendance = async (funeralId, forms) => {
  const options = {
    body: forms
  }
  return await API.post('guest', `/funerals/${funeralId}/optional/public`, options);
}

export default ModalRegistrationAttendance;
