import { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import { API } from 'aws-amplify';
import { useLoading } from 'components/Loading';
import { useMessage } from 'components/Message';
import { CSVLink } from 'react-csv'
import { isMobile } from 'utils/device'
import * as Constants from '../../constants';

export const SalesScore = (props) => {
  const loading = useLoading();
  const message = useMessage();
  const [label, setLabel] = useState([]);
  const [data, setData] = useState({});
  const [transferData, setTransferData] = useState({});
  const [staffList, setStaffList] = useState([]);

  const [csvData, setCsvData] = useState(null)
  const csvDownloaderRef = useRef(null)

  const handleDownload = async (monthString) => {
    if (csvDownloaderRef.current) {
      try {
        const res = await getOrdersListByMonth(props.match.params.companyId, monthString)
        setCsvData({
          filename: `${monthString}.csv`,
          data: res.orderDetails,
        })
      }
      catch (e) {
        // Show banner
        message.alert.danger('データ取得に失敗しました')
      }
    }
  }

  useEffect(() => {
    if (csvData) {
      csvDownloaderRef.current.link.click()
    }
  }, [csvData])

  useEffect(() => {
    const fetch = async () => {
      loading(true);

      const scoreResponse = await getSalesScore(props.match.params.companyId);
      setLabel(scoreResponse.label);
      setData(scoreResponse.data);
      setStaffList(scoreResponse.staff);

      const transferResponse = await getOrdersTransferPriceByMonth(props.match.params.companyId, scoreResponse.label)
      setTransferData(transferResponse.data)

      loading(false);
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  return (
    <CommonLayout>
      <ContentHeader>
        月別累計売上高
      </ContentHeader>

      <div className="card">
        <div className="card-body table-wrap">
          <table border="1" className="table table-bordered">
            <thead>
              <td className="fixed01 bg-white" style={{ width: "20%" }} colSpan={2}></td>
              {label.map((l, i) => (
                <th key={i} className="text-right">
                  { // 値が一つもなければダウンロードボタンを表示しない : 必要であれば修正
                    !isMobile && data.all && Object.values(data.all[i]).some(value => value) ? (
                      <Button className="btn btn-outline-info btn-sm btn-salesscore"
                        onClick={() => handleDownload(l)}>ダウンロード</Button>
                    ) : null}
                  <div>{l}</div>
                </th>
              ))}
            </thead>
            <tbody>
              {data.all &&
                <>
                  <tr>
                    <td className="fixed01 bg-white" rowSpan={8}>全体</td>
                    <td className="fixed02 bg-white">お供物売上</td>
                    {
                      data.all.map((d, i) => <td key={i} className="bordered text-right">{parseInt(d.Kumotsu, 10).toLocaleString()}</td>)
                    }
                  </tr>
                  <tr>
                    <td className="fixed02 bg-white">お供物振込金額</td>
                    {
                      label.map((l, i) => <td key={i} className="bordered text-right">{parseInt(transferData[l]?.kumotsu, 10).toLocaleString()}</td>)
                    }
                  </tr>
                  <tr>
                    <td className="fixed02 bg-white">弔文売上</td>
                    {
                      data.all.map((d, i) => <td key={i} className="bordered text-right">{parseInt(d.Telegram, 10).toLocaleString()}</td>)
                    }
                  </tr>
                  <tr>
                    <td className="fixed02 bg-white">弔文振込金額</td>
                    {
                      label.map((l, i) => <td key={i} className="bordered text-right">{parseInt(transferData[l]?.telegram, 10).toLocaleString()}</td>)
                    }
                  </tr>
                  <tr>
                    <td className="fixed02 bg-white">返礼品売上</td>
                    {
                      data.all.map((d, i) => <td key={i} className="bordered text-right">{parseInt(d.ReturnGift, 10).toLocaleString()}</td>)
                    }
                  </tr>
                  <tr>
                    <td className="fixed02 bg-white">返礼品振込金額</td>
                    {
                      label.map((l, i) => <td key={i} className="bordered text-right">{parseInt(transferData[l]?.returnGift, 10).toLocaleString()}</td>)
                    }
                  </tr>
                  <tr>
                    <td className="fixed02 bg-white">香典発生金額</td>
                    {
                      data.all.map((d, i) => <td key={i} className="bordered text-right">{parseInt(d.Kouden, 10).toLocaleString()}</td>)
                    }
                  </tr>
                  <tr>
                    <td className="fixed02 bg-white">弔電報酬金額</td>
                    {
                      data.all.map((d, i) => <td key={i} className="bordered text-right">{parseInt(d.SagawaTelegram * Constants.SAGAWA_FEE, 10).toLocaleString()}</td>)
                    }
                  </tr>
                  <tr>
                    <td className="fixed01 bg-white">参列人数</td>
                    <td className="fixed02 bg-white"></td>
                    {
                      data.all.map((d, i) => <td key={i} className="bordered text-right">{parseInt(d.Attendees, 10).toLocaleString()}</td>)
                    }
                  </tr>
                  {
                    staffList.map((staff, j) => {
                      return (
                        <>
                          <tr key={"row1-" + j}>
                            <td className="fixed01 bg-white" rowSpan={5}>{staff.name}</td>
                            <td className="fixed02 bg-white">お供物売上</td>
                            {
                              data[staff.id].map((d, i) => <td key={i} className="bordered text-right">{parseInt(d.Kumotsu, 10).toLocaleString()}</td>)
                            }
                          </tr>
                          <tr key={"row2-" + j}>
                            <td className="fixed02 bg-white">弔文売上</td>
                            {
                              data[staff.id].map((d, i) => <td key={i} className="bordered text-right">{parseInt(d.Telegram, 10).toLocaleString()}</td>)
                            }
                          </tr>
                          <tr key={"row3-" + j}>
                            <td className="fixed02 bg-white">香典発生金額</td>
                            {
                              data[staff.id].map((d, i) => <td key={i} className="bordered text-right">{parseInt(d.Kouden, 10).toLocaleString()}</td>)
                            }
                          </tr>
                          <tr key={"row4-" + j}>
                            <td className="fixed02 bg-white">弔電報酬金額</td>
                            {
                              data[staff.id].map((d, i) => <td key={i} className="bordered text-right">{parseInt(d.SagawaTelegram  * Constants.SAGAWA_FEE, 10).toLocaleString()}</td>)
                            }
                          </tr>
                          <tr key={"row5-" + j}>
                            <td className="fixed02 bg-white">参列人数</td>
                            {
                              data[staff.id].map((d, i) => <td key={i} className="bordered text-right">{parseInt(d.Attendees, 10).toLocaleString()}</td>)
                            }
                          </tr>
                        </>
                      )
                    })
                  }
                </>
              }
            </tbody>
          </table>
        </div>
      </div>
      <CSVLink
        ref={csvDownloaderRef}
        filename={csvData?.filename}
        data={csvData?.data || []}
        style={{ display: 'none' }}
      />
    </CommonLayout >
  )
}

const getSalesScore = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }
  return await API.get('product', '/statistics/score', options);
}

// Expecting month to be 'yyyy-MM' format
const getOrdersListByMonth = async (companyId, month) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
      month: month
    }
  }
  return await API.get('product', '/orders/month', options);
}

// Expecting month to be 'yyyy-MM' format
const getOrdersTransferPriceByMonth = async (companyId, months) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
      months: months
    }
  }
  return await API.get('product', '/orders/month/transfer', options);
}

export default SalesScore;
