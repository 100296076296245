import { useState, useContext, useEffect } from 'react';
import {useHistory, useRouteMatch, NavLink, Link, useLocation} from 'react-router-dom';
import { Modal, Navbar, Nav, NavDropdown, Row, Col, Button } from 'react-bootstrap';
import { AuthContext, AttendanceContext } from '../Contexts';
import { copyToClipboard } from 'utils/copyToClipboard';
import { Auth } from 'aws-amplify';
import { API } from 'aws-amplify';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';
import * as Constants from "../../constants";
import {getBlob} from "../../utils/request";
import { isIOS } from 'utils/device';

export const Header = (props) => {
  const history = useHistory();
  const match = useRouteMatch("/:funeralId");
  const prefix = "/" + match.params.funeralId;
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const { setAttendance, funeralInfo, setShowPortalFuneralSetting } = useContext(AttendanceContext);
  const [showMenu, setShowMenu] = useState(false);
  const isAdminPage = props.adminPage || false;
  const [copied, setCopied] = useState(false);
  const [showFuho, setShowFuho] = useState(false);
  const funeralId = match.params.funeralId;
  const [isPublicPhotoFuneral, setIsPublicPhotoFuneral] = useState(false);

  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const isOrderOnly = queryString.get("isOrderOnly") === "true";

  const [isAttendanceKoudenDisplay, setIsAttendanceKoudenDisplay] = useState(false);
  const [isAttendanceKumotsuDisplay, setIsAttendanceKumotsuDisplay] = useState(false);

  useEffect(() => {
    const fetchDisplayItem = async () => {
      const result = await getDisplayItem(funeralId);

      if(result.koudenList.length > 0) {
        setIsAttendanceKoudenDisplay(true);
      }
      if (result.kumotsuList.length > 0) {
        setIsAttendanceKumotsuDisplay(true);
      }
    }
    fetchDisplayItem();

    // 特定葬家のみ認証無しで写真を表示する
    setIsPublicPhotoFuneral(funeralId === '36b85ef8-deda-4c84-ae19-5b1ad7b64241' || funeralId === '2d5b0d55-b010-4606-8759-f937f536907f')
    // eslint-disable-next-line
  }, []);

  const handleLogOut = async () => {
    sessionStorage.setItem('funeralId', match.params.funeralId);
    await Auth.signOut();
    setUserInfo(undefined);
    sessionStorage.removeItem('funeralId');
    history.push(prefix);
  }

  const toAlbum = async () => {
    if ((userInfo && userInfo.attendees !== null && userInfo.attendees.FuneralId === match.params.funeralId) || isPublicPhotoFuneral) {
      // 参列済み：アルバムへ
      history.push(prefix + "/album");
    } else {
      // 未参列：参列を勧めるダイアログを表示
      setAttendance(true);
    }
  }

  const handleFuhoClick = () => {
    copyToClipboard(match.params.funeralId, funeralInfo, userInfo);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }

  const handleDownloadPDF = async () => {
    const res = await downloadTimeSchedulePdf(userInfo.attendees.FuneralId)
    if (res.filename) {
      if (isIOS()) {
        window.location.href = Constants.IMAGE_BUCKET_URL + res.filename;
      } else {
        const pdfFile = await getBlob(Constants.IMAGE_BUCKET_URL + res.filename, { method: 'GET' });
        saveAs(pdfFile, 'タイムスケジュール' + moment(new Date()).format("YYYY-MM-DD") + ".pdf");
      }
    }
  }

  return (
    <>
      <header id="header" className="main-header">
        <div className="container-fluid">
          <Navbar bg="light" expand="xl">
            <Navbar.Brand id="header-logo" onClick={() => !isOrderOnly && history.push(prefix + "/")}>
              <img src="/images/logo.png" alt="" />
            </Navbar.Brand>
            {
              isAdminPage &&
              <Navbar.Brand id="header-admin-title" className="gothic">
                {funeralInfo.familyName}家　管理画面
              </Navbar.Brand>
            }
            {
              !isOrderOnly &&
               <>
                 <span className="navbar-toggler-icon" onClick={() => setShowMenu(true)}></span>
                 <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                   <Nav className="">
                     <Nav.Link as={Link} to={prefix}>葬儀情報</Nav.Link>
                     <div>
                       { (isAttendanceKoudenDisplay || isAttendanceKumotsuDisplay) &&
                         <NavDropdown title="参列選択">
                          { 
                            isAttendanceKoudenDisplay && 
                            funeralInfo?.enableMoneyFlow && 
                            !userInfo?.isChiefMourner && 
                            !userInfo?.attendees?.Relatives &&
                             <NavDropdown.Item as={NavLink} to={prefix + "/attendance/content/select/kouden"}>香典</NavDropdown.Item>
                           }
                           { isAttendanceKumotsuDisplay &&
                             <NavDropdown.Item as={NavLink} to={prefix + "/attendance/content/select/kumotsu"}>お供物</NavDropdown.Item>
                           }
                         </NavDropdown>
                       }
                     </div>
                     <Nav.Link onClick={toAlbum}>写真を追加する</Nav.Link>
                     <Nav.Link onClick={() => setShowFuho(true)}>訃報を知らせる</Nav.Link>
                     {
                       (userInfo) ?
                         <NavDropdown title={userInfo.attendees !== null ? userInfo.attendees.UserName : "未参列"}>
                           {
                             userInfo.attendees !== null && userInfo.attendees.Relatives === true &&
                             <>
                               <NavDropdown.Item as={NavLink} to={prefix + "/album/select"}>家族アルバムを見る</NavDropdown.Item>
                             </>
                           }
                           <NavDropdown.Item onClick={toAlbum}>写真を追加する</NavDropdown.Item>
                           {
                             userInfo.attendees !== null && userInfo.attendees.Relatives === true &&
                             <>
                               <NavDropdown.Item as={NavLink} to={prefix + "/users/funeral"}>家族を招待する</NavDropdown.Item>
                               <NavDropdown.Item as={NavLink} to={prefix + "/users/attendee"}>参列者情報を見る</NavDropdown.Item>
                               {
                                 userInfo.attendees.RelativesRepresentative &&
                                 <>
                                   <NavDropdown.Item as={NavLink} to={prefix + "/funeral/gift"}>返礼品を注文する</NavDropdown.Item>
                                 </>
                               }
                               <NavDropdown.Item as={NavLink} to={prefix + "/funeral/message"}>挨拶文章を登録する</NavDropdown.Item>
                               {
                                 userInfo.attendees.RelativesRepresentative &&
                                 <>
                                   <NavDropdown.Item as={NavLink} to={prefix + "/funeral/connect/detail"}>香典受取口座を登録する</NavDropdown.Item>
                                 </>
                               }
                               {
                                 userInfo?.isChiefMourner &&  funeralInfo?.enablePortalForFuneral &&
                                 <>
                                   <NavDropdown.Item onClick={() => setShowPortalFuneralSetting(true)}>情報サイト掲載設定</NavDropdown.Item>
                                 </>
                               }
                               {props.isShowMenuSchedulePDF && <NavDropdown.Item onClick={() => handleDownloadPDF()}>タイムスケジュールを確認</NavDropdown.Item>}
                             </>
                           }{
                           userInfo.attendees !== null && userInfo.attendees.AttendDateTime &&
                           <NavDropdown.Item as={NavLink} to={prefix + "/receipt"}>領収書ダウンロード</NavDropdown.Item>
                         }
                           <NavDropdown.Divider />
                           <NavDropdown.Item onClick={handleLogOut}>ログアウト</NavDropdown.Item>
                         </NavDropdown>
                         :
                         <Nav.Link as={NavLink} to={prefix + "/login"}>ログイン</Nav.Link>
                     }
                   </Nav>
                 </Navbar.Collapse>
               </>
            }
         </Navbar>
        </div>
      </header>
      <div id="header-ellipse"></div>
      <div id="header-dummy"></div>
      <Modal id="attendee-menu" show={showMenu} onHide={() => setShowMenu(false)} centered={true}>
        <Modal.Header closeButton={true}>
          <img className="logo" src="/images/logo.png" alt=""></img>
        </Modal.Header>
        <Modal.Body>
          <div className="account mincho">
            {
              (userInfo) ?
                (userInfo.attendees !== null) ? userInfo.attendees.UserName : "未参列"
                : "ログインしていません"
            }
          </div>
          <ul className="menu-list gothic">
            {
                userInfo && userInfo.attendees !== null && userInfo.attendees.Relatives === true &&
                <>
                  <li onClick={() => history.push(prefix + "/album/select")}>家族アルバムを見る</li>
                </>
            }
            <li onClick={toAlbum}>写真を追加する</li>
            {
              userInfo && userInfo.attendees !== null && userInfo.attendees.Relatives === true &&
              <>
                <li onClick={() => history.push(prefix + "/users/funeral")}>家族を招待する</li>
                <li onClick={() => history.push(prefix + "/users/attendee")}>参列者情報を見る</li>
                {
                  userInfo.attendees.RelativesRepresentative &&
                    <>
                      <li onClick={() => history.push(prefix + "/funeral/gift")}>返礼品を注文する</li>
                    </>
                }
                <li onClick={() => history.push(prefix + "/funeral/message")}>挨拶文章を登録する</li>
                {
                  userInfo.attendees.RelativesRepresentative &&
                    <>
                      <li onClick={() => history.push(prefix + "/funeral/connect/detail")}>香典受取口座を登録する</li>
                    </>
                }
                {
                  userInfo?.isChiefMourner &&  funeralInfo?.enablePortalForFuneral &&
                    <>
                      <li onClick={() => setShowPortalFuneralSetting(true)}>情報サイト掲載設定</li>
                    </>
                }
                {props.isShowMenuSchedulePDF && <li onClick={() => handleDownloadPDF()}>タイムスケジュールを確認</li>}
              </>
            }
            {
              userInfo && userInfo.attendees !== null && userInfo.attendees.AttendDateTime &&
              <>
                <li onClick={() => history.push(prefix + "/receipt")}>領収書ダウンロード</li>
              </>
            }
            {
              (userInfo) ?
                <li onClick={handleLogOut}>ログアウト</li>
                :
                <li onClick={() => history.push(prefix + "/login")}>ログイン</li>
            }
          </ul>
        </Modal.Body>
      </Modal>
      <Modal show={showFuho} onHide={() => setShowFuho(false)} centered={true} dialogClassName="fuho-modal-size" size="lg">
        <Modal.Header closeButton={true}><Modal.Title className="ms-auto">＜訃報を知り合いに伝える＞</Modal.Title></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6} sm={5} xs={12}>
              <div className="fuho-banner pt-1 pb-1 rounded-pill">STEP1</div>
              <br />
              <div className="ml-2">「訃報情報をコピーする」ボタンを押す</div>
              <br />
              <div className="fuho-banner pt-1 pb-1 rounded-pill">STEP2</div>
              <br />
              <div className="ml-2">メールやLINE等で貼り付け(ペースト)して送信する</div>
              <br />
            </Col>
            <Col md={6} sm={7} xs={12}><img src="/images/fuhou.jpg" alt="" width={300} /></Col>
          </Row>
          <div className="text-center mt-5"><Button variant="secondary" onClick={handleFuhoClick}>{copied ? <span className="copied">訃報をクリップボードにコピーしました</span> : "訃報情報をコピーする"}</Button></div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const getDisplayItem = async (funeralId) => {
  const options = {
    queryStringParameters: { funeralId: funeralId }
  }

  return await API.get('guest', '/funerals/item/template', options);
}

const downloadTimeSchedulePdf = async (funeralId) => {
  return await API.get('product', '/funerals-schedule/' + funeralId + '/download');
}

export default Header;