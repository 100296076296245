import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as Constants from './../../constants';
import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Input from 'components/Input';
import useMessage from 'components/Message';
import UploadImage from 'components/UploadImage';
import { ZipCodeHall } from 'components/CustomForms/ZipCodeHall';
import { Prefecture } from 'components/CustomForms/Prefecture';
import { RoomList } from 'components/CustomForms/RoomList';
import { useLoading } from 'components/Loading';
import { City } from 'components/CustomForms/City';
import { Town } from 'components/CustomForms/Town';
import { handleSplitData, requestFormData, validateHalfwidthNumber } from 'utils/helper';

export const HallEdit = (props) => {
  const companyId = props.match.params.companyId;

  const newEntry = props.method === "new";
  const [listCity, setListCity] = useState([]);
  const [listTown, setListTown] = useState([]);

  const history = useHistory();
  const message = useMessage();
  const loading = useLoading();

  const numberPattern = (number) => `^[0-9０-９]{${number}}$`
  const phonePattern = '^0(?!.*--)(?!.*-$)([0-9]{9,10}|[0-9]+(-[0-9]+)+)$'

  const formik = useFormik({
    initialValues: {
      hallId: "",
      hallName: "",
      phoneNo: "",
      zipCode: "",
      prefecture: "",
      address1: "",
      address2: "",
      faxNo: "",
      imagePath1: "",
      description: "",
      rooms: [],
      googleMapAppUrl: "",
      googleMapUrl: "",
      city: "",
      town: "",
      prefectureName: ""

    },
    validationSchema: Yup.object({
      hallName: Yup.string().trim().required("会場名は必須です"),
      phoneNo: Yup.string().trim().required("電話番号は必須です").matches(new RegExp(phonePattern , 'u'),'10～11桁の半角数字でご入力ください'),
      zipCode: Yup.string().required("郵便番号は必須です").matches(new RegExp(numberPattern(7), 'u'), '郵便番号はハイフンなしの7桁で入力してください').test("halfwidth", "ハイフンなしで半角数字でご入力ください。", validateHalfwidthNumber),
      prefecture: Yup.string().trim().required("都道府県は必須です"),
      city: Yup.string().trim().required("市区郡は必須です"),
      town: Yup.string().trim().required("町村は必須です"),
      address1: Yup.string().trim().required("住所1は必須です"),
      address2: Yup.string().trim().required("住所（番地・建物名）は必須です"),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        if (newEntry) {
          loading(true);
          try {
            const result = await createHall(companyId, values);
            if (!result.error) {
              message.alert.success("会場を作成しました");
              history.goBack();
            } else {
              if (result.error === "HallId exists") {
                formik.setFieldError("hallId", "会場IDがすでに存在しています");
              } else {
                message.alert.danger("システムエラーが発生しました");
              }
            }
          } catch (e) {
            if (e.response.status === 400) {
              message.alert.danger(e.response.data.error);
            } else {
              message.alert.danger("システムエラーが発生しました");
            }
          } finally {
            loading(false);
          }
        } else {
          loading(true);
          try {
            const result = await updateHall(companyId, values);
            if (!result.error) {
              message.alert.success("会場を更新しました");
              history.goBack();
            } else {
              message.alert.danger("システムエラーが発生しました");
            }
          } catch (e) {
            if (e.response.status === 400) {
              message.alert.danger(e.response.data.error);
            } else {
              message.alert.danger("システムエラーが発生しました");
            }
          } finally {
            loading(false);
          }
        }
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  const setFieldValue = formik.setFieldValue;

  const handleCallApiGetCityAndTown = async (hall) => {
    const prefecture = Constants.PREFECTURES.find((item) => item.key === hall.Address?.Prefecture)
    setListCity(await requestGetListData("siku", prefecture?.value || '') || []);
    setListTown(await requestGetListData("tyou", prefecture?.value || '', hall.Address?.City || '') || []);
  }

  useEffect(() => {
    const fetch = async (hallId) => {
      loading(true)
      try {
        const hall = await getHall(props.match.params.companyId, hallId);
        if (!hall.error) {
          setFieldValue("hallId", hall.HallId);
          setFieldValue("hallName", hall.HallName);
          setFieldValue("phoneNo", hall.PhoneNo);
          setFieldValue("zipCode", hall.Address?.ZipCode);
          setFieldValue("prefecture", hall.Address?.Prefecture);
          setFieldValue("address1", hall.Address?.Address1);
          setFieldValue("address2", hall.Address?.Address2);
          setFieldValue("faxNo", hall.FaxNo ?? "");
          setFieldValue("imagePath1", hall.ImagePath1 ?? "");
          setFieldValue("description", hall.Description ?? "");
          setFieldValue("rooms", hall.Rooms ?? []);
          setFieldValue("googleMapAppUrl", hall.GoogleMapAppUrl ?? "");
          setFieldValue("googleMapUrl", hall.GoogleMapUrl ?? "");
          const prefectureName = Constants.PREFECTURES.find((e) => e.key ===  hall.Address?.Prefecture);
          setFieldValue("prefectureName", prefectureName?.value ?? "");
          setFieldValue("city", hall.Address?.City);
          setFieldValue("town", hall.Address?.Town);
          handleCallApiGetCityAndTown(hall);
        } else {
          message.alert.danger("システムエラーが発生しました");
        }
      } catch (e) {
        if (e.response.status === 400) {
          message.alert.danger(e.response.data.error);
        } else {
          message.alert.danger("システムエラーが発生しました");
        }
      } finally {
        loading(false)
      }
    }
    if (!newEntry) {
      fetch(props.match.params.hallId);
    }
    return () => {
      formik.setErrors({});
    };
    // eslint-disable-next-line
  }, []);

  const handleChangeZipCode = async (result) => {
    formik.setFieldError('zipCode', null)
    if (!result) {
      // show error
      if(!validateHalfwidthNumber(formik?.values?.zipCode)){
        formik.setFieldError('zipCode', "ハイフンなしで半角数字でご入力ください。");
      } else {
        formik.setFieldError('zipCode', "郵便番号が正しくありません。")
      }
      formik.setFieldValue("prefecture", "")
      formik.setFieldValue("city", "")
      formik.setFieldValue("town", "")
      formik.setFieldValue("address1", "")
      setListCity([]);
      setListTown([]);
      return
    }
    formik.setFieldValue("prefecture", result.prefCode)
    formik.setFieldValue("prefectureName", result.prefecture)
    // auto field prefecture, city and town 
    formik.setFieldValue("city", result.city)
    formik.setFieldValue("town", result.town)
    formik.setFieldValue("address1", result.city + result.town)
    // reset error messsage if the data filled
    const fieldsToClear = ['prefecture', 'city', 'town'];
    fieldsToClear.forEach(field => {
      if (formik.errors?.[field]) {
        formik.setFieldError(field, null);
      }
    });
    // call api get list city and town
    setListCity(await requestGetListData("siku", result.prefecture || '') || []);
    setListTown(await requestGetListData("tyou", result.prefecture || '', result.city || '') ||[]);
  }

  const handleChangePrefecture = async (result) => {
    formik.setFieldValue("prefecture", result.prefecture.key)
    formik.setFieldValue("prefectureName", result.prefecture.value)
    formik.setFieldValue("city", "")
    formik.setFieldValue("town", "")
    formik.setFieldValue("zipCode", "")
    formik.setFieldError('zipCode', null);
    // call api get list city of prefecture
    setListCity(await requestGetListData("siku", result.prefecture.value || '') || []);
    setListTown([])
  }

  const handleChangeCity = async (result) => {
    formik.setFieldValue("city", result.city)
    formik.setFieldValue("town", "")
    formik.setFieldValue("zipCode", "")
    formik.setFieldError('zipCode', null);
    // call api get list town of city
    setListTown(await requestGetListData("tyou", formik.values.prefectureName || '', result.city || '') || []);
  }

  const handleChangeTown = async (result) => {
    formik.setFieldValue("town", result.town)
    formik.setFieldValue("address1", formik.values.city + result.town)
    const responseZipCode = await requestGetListData("zipcode", formik.values.prefectureName || '', formik.values.city || '', result.town || '');
    formik.setFieldValue("zipCode", responseZipCode ? responseZipCode[0]?.value : "")
  }

  const handleDelete = () => {
    message.confirm.danger({
      title: "会場を削除",
      message: "削除してもよろしいですか？",
      onSubmit: () => {
        deleteHall(companyId, formik.values.hallId);
        message.alert.success("会場を削除しました");
        history.goBack();
      }
    });
  }

  const requestGetListData = async (type, prefecture = null, city = null, town = null) => {
    loading(true);
    const formData = requestFormData(type, prefecture, city, town)
    const response = await getAddress(formData);
    let results = [];
    if (response && response.includes('true')) {
      results = handleSplitData(response)
    }
    loading(false);
    return results
  }

  return (
    <CommonLayout>
      <ContentHeader>
        {newEntry ? "会場新規作成" : "会場編集"}
      </ContentHeader>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md="6">
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <label className="control-label">会場名 <span className="text-danger fs-6">*</span></label>
                  <Input type="text" maxLength="50" name="hallName" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="6">
                      <label className="control-label">問い合わせ電話番号 <span className="text-danger fs-6">*</span></label>
                      <Input type="text" name="phoneNo" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="4">
                      <label className="control-label">郵便番号 <span className="text-danger fs-6">*</span></label>
                      <ZipCodeHall name="zipCode" className="form-control" formik={formik} onChange={handleChangeZipCode} maxLength={7}/>
                    </Col>
                    <Col md="4">
                      <label className="control-label">都道府県 <span className="text-danger fs-6">*</span></label>
                      <Prefecture type="text" name="prefecture" className="form-control" formik={formik} onChange={handleChangePrefecture} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="4">
                      <label className="control-label">市区郡 <span className="text-danger fs-6">*</span></label>
                      <City type="text" name="city" className="form-control" formik={formik} listCity={listCity} onChange={handleChangeCity} />
                    </Col>
                    <Col md="4">
                      <label className="control-label">町村 <span className="text-danger fs-6">*</span></label>
                      <Town type="text" name="town" className="form-control" formik={formik} listTown={listTown} onChange={handleChangeTown} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <label className="control-label">住所（番地・建物名）<span className="text-danger fs-6">*</span></label>
                  <Input type="text" maxLength="50" name="address2" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">受注FAX番号（会場用）</label>
                  <Input type="text" name="faxNo" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">画像パス1</label>
                  <UploadImage name="imagePath1" className="form-control" formik={formik} width={400} height={400} />
                </div>
                <div className="form-group">
                  <label className="control-label">Googleマップ埋め込みHTML</label>
                  <Input type="textarea" name="googleMapUrl" className="form-control" formik={formik} placeholder='<iframe src="https://www.google.com/maps/embed?....'/>
                </div>
                <div className="form-group">
                  <label className="control-label">Googleマップ共有URL</label>
                  <Input type="textarea" name="googleMapAppUrl" className="form-control" formik={formik} placeholder='https://maps.app.goo.gl/....'/>
                </div>
                <div className="form-group">
                  <label className="control-label">部屋リスト</label>
                  <RoomList name="rooms" formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">説明</label>
                  <Input type="textarea" name="description" className="form-control" formik={formik} />
                </div>
              </div>
              <div className="card-footer">
                <div>
                  <Button variant="secondary" className="float-right" onClick={() => history.goBack()}>戻る</Button>
                  {
                    newEntry ?
                      <Button type="submit" variant="success">作成</Button>
                      :
                      <>
                        <Button type="submit" variant="success">更新</Button>
                        {' '}
                        <Button variant="danger" onClick={handleDelete}>削除</Button>
                      </>
                  }
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </form>

    </CommonLayout>
  );
};

const getHall = async (companyId, hallId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }
  return await API.get('product', '/halls/' + hallId, options);
}

const createHall = async (companyId, values) => {
  values.companyId = companyId;
  const options = {
    body: values
  }
  return await API.post('product', '/halls', options);
}

const updateHall = async (companyId, values) => {
  values.companyId = companyId;
  const options = {
    body: values
  }
  return await API.put('product', '/halls', options);
}

const deleteHall = async (companyId, hallId) => {
  const options = {
    body: {
      companyId: companyId,
      hallId: hallId,
    }
  }
  return await API.del('product', '/halls', options);
}

export const getAddress = async (formData) => {
  formData.append("targetUrl", "https://www.keicho.net/api/AddressInformation/v1/addressInformation.php");
  const options = {
    body: formData
  }
  return await API.post('guest', '/sagawa-api-proxy', options);
}

export default HallEdit;
