import React from 'react';
import { Modal, Table } from 'react-bootstrap';

const ModalTermSagawa = ({ showDialog, setShowTermDialog }) => {

  return (
    <Modal size="xl" className="item-center-dl" show={showDialog} onHide={() => setShowTermDialog(false)} centered>
      <Modal.Body className="table-sagawa-dl">
      <div className="title-table-sagawa">特定商取引法表記</div>
        <Table bordered hover className='table-sagawa'>
          <tbody>
            <tr>
              <td>会社名</td>
              <td>佐川ヒューモニー株式会社</td>
            </tr>
            <tr>
              <td>所在地</td>
              <td>〒136-0075　東京都江東区新砂２丁目２番８号</td>
            </tr>
            <tr>
              <td>代表者</td>
              <td>青木 淳一</td>
            </tr>
            <tr>
              <td>連絡先</td>
              <td>0570-056-115</td>
            </tr>
            <tr>
              <td>メールアドレス</td>
              <td>info@verycard.net</td>
            </tr>
            <tr>
              <td rowSpan={5}>許認可等</td>
            </tr>
            <tr>
              <td>第一種貨物利用運送事業登録（近運自貨第516号）</td>
            </tr>
            <tr>
              <td>特定信書便事業許可（総特第10号）</td>
            </tr>
            <tr>
              <td>プライバシーマーク（第20000538（09）号）</td>
            </tr>
            <tr>
              <td>通信販売酒類小売業免許(京法3707)</td>
            </tr>
            <tr>
              <td>商品の価格</td>
              <td>サイト上で表示された商品代金（表示価格）による販売</td>
            </tr>
            <tr>
              <td className="highlight-text">商品の引き渡し等</td>
              <td>ご利用規約に従い、itowaのサイト上で定められているお届け希望日時までに配達いたします。<br />
                但し、何らかの理由でご指定日時に遅れる場合はメール等によりご連絡いたします。<br />
                申込者の責に帰す事由、配達先の都合による、再送・転送は、ご利用規約に従い、原則有償にて対応いたします。
              </td>
            </tr>
            <tr>
              <td>返品、キャンセル、不良品等</td>
              <td>申込内容の変更は、配達作業に入る前に限り、ご利用規約に従い対応いたします。<br />
                申込のキャンセルについては、原則、お受け付けできません。<br />
                配達した商品が、商品間違い、不良品の場合のみ、信書便約款に従い、無料で代替品と交換いたします。
              </td>
            </tr>
            <tr>
              <td>代金支払い方法</td>
              <td>itowaが定める支払い方法</td>
            </tr>
          </tbody>
        </Table>
        <button type="button" className="btn btn-promary black-button" onClick={() => setShowTermDialog(false)}>閉じる</button>
      </Modal.Body>
    </Modal>
  );
}

export default ModalTermSagawa;
