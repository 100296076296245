import { appConfig } from './app-config';
import { awsConfig } from 'aws-config';

export const APP_DOMAIN = appConfig.domain;
/** @deprecated It is meant for a single purpose: redirecting to new domain. */
export const LEGACY_APP_DOMAIN = appConfig.legacyDomain;
export const APP_STAGE = appConfig.stage;
export const ADMIN_ID = "admin";

export const ADMIN_DOMAIN = "admin." + APP_DOMAIN;
export const UNDERTAKER_DOMAIN = "biz." + APP_DOMAIN;
export const FUNERALS_DOMAIN = APP_STAGE === "local" ? "localhost" : "app." + APP_DOMAIN;
/** @deprecated It is meant for a single purpose: redirecting to new domain. */
export const LEGACY_ADMIN_DOMAIN = "admin." + LEGACY_APP_DOMAIN;
/** @deprecated It is meant for a single purpose: redirecting to new domain. */
export const LEGACY_UNDERTAKER_DOMAIN = "album." + LEGACY_APP_DOMAIN;
/** @deprecated It is meant for a single purpose: redirecting to new domain. */
export const LEGACY_FUNERALS_DOMAIN = "miokuri." + LEGACY_APP_DOMAIN;
export const OFFICIAL_DOMAIN = process.env.OFFICIAL_DOMAIN;

export const UNDERTAKER_WEB_URL = APP_STAGE === "local" ? `http://${UNDERTAKER_DOMAIN}:3000` : `https://${UNDERTAKER_DOMAIN}`;
export const FUNERALS_WEB_URL = APP_STAGE === "local" ? `http://${FUNERALS_DOMAIN}:3000` : `https://${FUNERALS_DOMAIN}`;

export const IMAGE_BUCKET_URL = `https://img.${APP_DOMAIN}/`;

export const COGNITO_DOMAIN = APP_STAGE === "prd" ? "bloom-post" : (APP_STAGE === 'local' ? 'bloom-post-dev' : "bloom-post-" + APP_STAGE);

export const COGNITO_LS_KEY = `CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}`;

export const PREFECTURES = [
  { key: "1", value: "北海道", kana: "ﾎｯｶｲﾄﾞｳ" },
  { key: "2", value: "青森県", kana: "ｱｵﾓﾘｹﾝ" },
  { key: "3", value: "岩手県", kana: "ｲﾜﾃｹﾝ" },
  { key: "4", value: "宮城県", kana: "ﾐﾔｷﾞｹﾝ" },
  { key: "5", value: "秋田県", kana: "ｱｷﾀｹﾝ" },
  { key: "6", value: "山形県", kana: "ﾔﾏｶﾞﾀｹﾝ" },
  { key: "7", value: "福島県", kana: "ﾌｸｼﾏｹﾝ" },
  { key: "8", value: "茨城県", kana: "ｲﾊﾞﾗｷｹﾝ" },
  { key: "9", value: "栃木県", kana: "ﾄﾁｷﾞｹﾝ" },
  { key: "10", value: "群馬県", kana: "ｸﾞﾝﾏｹﾝ" },
  { key: "11", value: "埼玉県", kana: "ｻｲﾀﾏｹﾝ" },
  { key: "12", value: "千葉県", kana: "ﾁﾊﾞｹﾝ" },
  { key: "13", value: "東京都", kana: "ﾄｳｷｮｳﾄ" },
  { key: "14", value: "神奈川県", kana: "ｶﾅｶﾞﾜｹﾝ" },
  { key: "15", value: "新潟県", kana: "ﾆｲｶﾞﾀｹﾝ" },
  { key: "16", value: "富山県", kana: "ﾄﾔﾏｹﾝ" },
  { key: "17", value: "石川県", kana: "ｲｼｶﾜｹﾝ" },
  { key: "18", value: "福井県", kana: "ﾌｸｲｹﾝ" },
  { key: "19", value: "山梨県", kana: "ﾔﾏﾅｼｹﾝ" },
  { key: "20", value: "長野県", kana: "ﾅｶﾞﾉｹﾝ" },
  { key: "21", value: "岐阜県", kana: "ｷﾞﾌｹﾝ" },
  { key: "22", value: "静岡県", kana: "ｼｽﾞｵｶｹﾝ" },
  { key: "23", value: "愛知県", kana: "ｱｲﾁｹﾝ" },
  { key: "24", value: "三重県", kana: "ﾐｴｹﾝ" },
  { key: "25", value: "滋賀県", kana: "ｼｶﾞｹﾝ" },
  { key: "26", value: "京都府", kana: "ｷｮｳﾄﾌ" },
  { key: "27", value: "大阪府", kana: "ｵｵｻｶﾌ" },
  { key: "28", value: "兵庫県", kana: "ﾋｮｳｺﾞｹﾝ" },
  { key: "29", value: "奈良県", kana: "ﾅﾗｹﾝ" },
  { key: "30", value: "和歌山県", kana: "ﾜｶﾔﾏｹﾝ" },
  { key: "31", value: "鳥取県", kana: "ﾄｯﾄﾘｹﾝ" },
  { key: "32", value: "島根県", kana: "ｼﾏﾈｹﾝ" },
  { key: "33", value: "岡山県", kana: "ｵｶﾔﾏｹﾝ" },
  { key: "34", value: "広島県", kana: "ﾋﾛｼﾏｹﾝ" },
  { key: "35", value: "山口県", kana: "ﾔﾏｸﾞﾁｹﾝ" },
  { key: "36", value: "徳島県", kana: "ﾄｸｼﾏｹﾝ" },
  { key: "37", value: "香川県", kana: "ｶｶﾞﾜｹﾝ" },
  { key: "38", value: "愛媛県", kana: "ｴﾋﾒｹﾝ" },
  { key: "39", value: "高知県", kana: "ｺｳﾁｹﾝ" },
  { key: "40", value: "福岡県", kana: "ﾌｸｵｶｹﾝ" },
  { key: "41", value: "佐賀県", kana: "ｻｶﾞｹﾝ" },
  { key: "42", value: "長崎県", kana: "ﾅｶﾞｻｷｹﾝ" },
  { key: "43", value: "熊本県", kana: "ｸﾏﾓﾄｹﾝ" },
  { key: "44", value: "大分県", kana: "ｵｵｲﾀｹﾝ" },
  { key: "45", value: "宮崎県", kana: "ﾐﾔｻﾞｷｹﾝ" },
  { key: "46", value: "鹿児島県", kana: "ｶｺﾞｼﾏｹﾝ" },
  { key: "47", value: "沖縄県", kana: "ｵｷﾅﾜｹﾝ" }
]

export const GENDER = {
  "M": "男性",
  "F": "女性",
  "W": "女性",
  "O": "その他"
}

export const RELATIONS = {
  "A": "親族",
  "B": "一般",
  "C": "会社関係",
  "O": "その他"
}

export const SETTING_DATETIME = [
  { value: "wake-time", label: "通夜時間" },
  { value: "funeral-time", label: "葬儀時間" },
  { value: "time-settings", label: "時間設定" }
];

export const PC_WINDOW_WIDTH = 1200;

export const COMMISSION_RATE = 9.85;

export const SAGAWA_FEE = 0.05;

export const MESSAGE_FEE = 110;

export const OTHER_ITEMS = "otherItems";
export const OTHER_ITEM_TYPE = "OtherItemType";
export const CATEGORY_GIFT_LIST = [3000, 5000, 10000, 30000, 50000, 100000, OTHER_ITEMS];

export const BLOG_TOP_URL = "https://itowa71.com/";
export const KIYAKU_URL = "https://itowa71.com/terms_of_service";
export const SERVICE_URL = "https://itowa71.com/service";
export const PRIVACY_POLICY_URL = "https://itowa71.com/privacy_policy"

export const EXAMPLE_NAME_URL = "https://news.itowa71.com/?p=412";
export const EXAMPLE_MESSAGE_URL = "https://news.itowa71.com/?p=632";
export const EXAMPLE_CHODEN_URL = "https://news.itowa71.com/?p=483";
export const EXAMPLE_KOUDEN_URL = "https://news.itowa71.com/?p=454";

export const KEICHO_ENPOINT_URL = "https://www.keicho.net/api";

export const EMPTY_EMAIL = 'empty@example.org';

export const PHONE_SKIP_VERIFY = '1111111111';

export const PORTAL_STATUS = {
  NOT_SET: "NOT_SET",
  ALLOWED: "ALLOWED",
  DENIED: "DENIED",
}

export const CHIEF_MOUENER_MONEY_STATUS  = {
  NOT_SET: "NOT_SET",
  ALLOWED: "ALLOWED",
  DENIED: "DENIED"
}

export const ORDER_STATUS = {
  "waiting_confirm": "未確認",
  "confirmed": "受取",
  "denied": "辞退",
}

export const ORDER_BIZ_STATUS = {
  "waiting_confirm": "未回答",
  "confirmed": "受取済み",
  "denied": "キャンセル済み",
}
