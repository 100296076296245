import React, { useState } from 'react';
import { API } from 'aws-amplify'
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Pagination from 'components/Pagination';
import { prefCodeToName } from 'components/CustomForms/Prefecture';
import * as format from 'utils/format';
import moment from 'moment';
import ExcelJS from 'exceljs';
import encoding from 'encoding-japanese';

import { ORDER_BIZ_STATUS } from '../../constants';

export const FuneralKoudenList = (props) => {
  const PER_PAGE = 10;
  const history = useHistory();

  const [koudenList, setKoudenList] = React.useState([]);
  const [giftList, setGiftList] = React.useState([]);
  const [, setIsPrepareGiftList] = React.useState(false);
  const [pagenatedList, setPagenatedList] = React.useState([]);
  const [familyName, setFamilyName] = React.useState("");
  const funeralId = props.match.params.funeralId;
  const companyId = props.match.params.companyId;
  const [modal, setModal] = useState(false);
  const [order, setOrder] = useState({});
  const [moneyTotal, setMoneyTotal] = useState(0);

  React.useEffect(() => {
    const fetchKoudenList = async () => {
      const result = await getFuneralKoudenList(funeralId, companyId);

      let list = [];
      result.koudenList.forEach((row) => {
        list.push({
          itemName: row.itemName,
          label: row.label,
          status: row.status,
          price: row.price.toLocaleString(),
          purchaseDate: row.purchaseDate,
          address: prefCodeToName(row.userInfo?.Address?.Prefecture),
          phoneNo: row.userInfo.PhoneNo,
          userName: row.userInfo.UserName,
          furigana: row.userInfo.Furigana,
          companyName: row.userInfo.CompanyName,
          gender: format.gender(row.userInfo?.Statistics?.Gender),
          age: row.userInfo.Statistics.Age + "代",
          relation: format.relations(row.userInfo?.Statistics?.Relation),
          attendDateTime: row.userInfo.AttendDateTime
        })
      });

      const resultList = list.sort((a, b) => a.Id < b.Id ? -1 : 1);

      setKoudenList(list);
      setPagenatedList(resultList.slice(0, PER_PAGE));
      setFamilyName(result.familyName);
      setMoneyTotal(result.moneyTotal);
    }
    fetchKoudenList()

    const fetchGiftList = async () => {
      let list = []
      const result = await getFuneralOrderList(funeralId, companyId);
      result.orderList.forEach((order) => {
        order.Item.forEach((item) => {
          if (item.ItemType === 'gift') {
            list.push({
              itemId: item.ItemId,
              itemName: item.ItemName,
              priceCategory: item.priceCategory,
              price: item.Price,
              userName: item.UserName,
              phoneNo: item.PhoneNo,
              zipCode: item?.Address?.ZipCode,
              address: prefCodeToName(item?.Address?.Prefecture) + item?.Address?.Address1 + item?.Address?.Address2,
            })
          }
        })
      });
      setGiftList(list);
      setIsPrepareGiftList(true)
    }
    fetchGiftList()
    // eslint-disable-next-line
  }, []);

  const showDetail = (row) => {
    setOrder(row);
    setModal(true);
  }

  const download = async () => {
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet("sheet1");
    const worksheet = workbook.getWorksheet("sheet1");

    worksheet.columns = [
      { header: "商品名", key: "itemName" },
      { header: "商品ID", key: "itemId" },
      { header: "香典金額", key: "priceCategory" },
      { header: "ご注文税込価格", key: "price" },
      { header: "お名前", key: "userName" },
      { header: "電話番号", key: "phoneNo" },
      { header: "郵便番号", key: "zipCode" },
      { header: "お届け先住所", key: "address" },
    ];
    worksheet.addRows(giftList);

    const uint8Array = new Uint8Array(
      encoding.convert(await workbook.csv.writeBuffer(), {
        from: "UTF8",
        to: "SJIS"
      })
    );
    const blob = new Blob([uint8Array], { type: "application/octet-binary" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `返礼品リスト-${familyName}家-${moment().format("YYYY_M_D")}.csv`;
    a.click();
    a.remove();
  }

  const contentHeaderSideElement = (
    <Button variant="info" onClick={() => download()}>
      返礼品CSVダウンロード
    </Button>
  )

  return (
    <CommonLayout>
      <ContentHeader sideElement={contentHeaderSideElement}>
        {`${familyName}家 香典リスト`}
      </ContentHeader>
      <Row>
        <Col md="9">
          <div className="card">
            <div className="card-body p-0">
              <div className="text-start ms-4 mt-3" style={{ fontSize: "24px"}}>
                香典合計:{"¥" + moneyTotal.toLocaleString() + "-"}
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th>注文日</th>
                    <th>お名前(名札)</th>
                    <th>価格</th>
                    <th>ステータス</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    pagenatedList.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td>{moment(row.purchaseDate).format("YYYY/M/D")}</td>
                          <td>
                            {
                              row.label ? 
                                row.label.split('\n').map((label, i) => { return (<div key={i}>{label}</div>) })
                                :
                                ""
                            }
                          </td>
                          <td className='text-end'>{row.price}</td>
                          <td>{row?.status && ORDER_BIZ_STATUS?.[row.status]}</td>
                          <td><button className="btn btn-warning" onClick={() => showDetail(row)}>詳細</button></td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <Button variant="secondary" onClick={() => history.goBack()}>戻る</Button>
              <Pagination per={PER_PAGE} list={koudenList} onChange={(list) => setPagenatedList(list)} />
            </div>
            <Modal show={modal} onHide={() => setModal(false)} backdrop="static" centered={true} size="lg">
              <Modal.Header className="header" closeButton >
              </Modal.Header>
              <Modal.Body>
                <table className="table table-borderless text-left">
                  <tbody>
                    <tr>
                      <th>参列日時</th>
                      <td>{moment(order.attendDateTime).format("YYYY/M/D H:mm")}</td>
                    </tr>
                    <tr>
                      <td>{order.address}</td>
                    </tr>
                    <tr>
                      <th>商品名</th>
                      <td>{order.itemName}</td>
                    </tr>
                    <tr>
                      <th>年代</th>
                      <td>{order.age}</td>
                    </tr>
                    <tr>
                      <th>性別</th>
                      <td>{order.gender}</td>
                    </tr>
                  </tbody>
                </table>

                <div style={{ textAlign: "center" }}>
                  <button type="button" className="black-button btn btn-primary" onClick={() => setModal(false)}>閉じる</button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </Col>
      </Row>
    </CommonLayout >
  );
};

const getFuneralKoudenList = async (funeralId, companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }

  return await API.get('product', `/funerals/${funeralId}/kouden`, options);
}

const getFuneralOrderList = async (funeralId, companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }

  return await API.get('product', `/funerals/${funeralId}/order`, options);
}

export default FuneralKoudenList;