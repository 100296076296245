import { useState, useEffect } from 'react';
import { API } from 'aws-amplify'
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import useMessage from 'components/Message';
import Input from 'components/Input';
import UploadImage from 'components/UploadImage';
import { ZipCode } from 'components/CustomForms/ZipCode';
import { Prefecture, prefCodeToName, prefCodeToKana, prefNameToCode } from 'components/CustomForms/Prefecture';
import { useLoading } from 'components/Loading';

export const CompanyStripeConnectEdit = (props) => {
  const newEntry = props.method === "new";
  const [accountRegisted, setAccountRegisted] = useState(false);
  const [directorRegisted, setDirectorRegisted] = useState(false);

  const history = useHistory();
  const message = useMessage();
  const loading = useLoading();

  const formik = useFormik({
    initialValues: {
      companyName: "",
      companyNameKana: "",
      companyNameEnglish: "",
      phoneNo: "",
      zipCode: "",
      prefecture: "",
      city: "",
      cityKana: "",
      town: "",
      townKana: "",
      address1: "",
      address1Kana: "",
      address2: "",
      address2Kana: "",
      directorFirstName: "",
      directorLastName: "",
      directorFirstNameKana: "",
      directorLastNameKana: "",
      directorPhoneNo: "",
      directorTitle: "",
      directorEmail: "",
      directorZipCode: "",
      directorPrefecture: "",
      directorCity: "",
      directorCityKana: "",
      directorTown: "",
      directorTownKana: "",
      directorAddress1: "",
      directorAddress1Kana: "",
      directorAddress2: "",
      directorAddress2Kana: "",
      dob: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
        try {
          loading(true);
          if (newEntry) {
            const result = await createCompanyStripeConnect(props.match.params.companyId, values);
            if (result.error) {
              message.alert.danger("システムエラーが発生しました");
            } else {
              message.alert.success("葬儀社のStripeConnectアカウントを作成しました");
              history.goBack();
            }
          } else {
            const result = await createCompanyStripeConnect(props.match.params.companyId, values);
            if (result.error) {
              message.alert.danger("システムエラーが発生しました");
            } else {
              message.alert.success("葬儀社のStripeConnectアカウントを更新しました");
              history.goBack();
            }
          }
        } catch (e) {
          message.alert.danger("システムエラーが発生しました");
        } finally {
          loading(false);
        }

    },
    validateOnChange: false,
    validateOnBlur: false
  });

  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    const fetch = async (companyId) => {
      loading(true);
      const { hasAccountId, hasDirectorId, ...company } = await getCompany(companyId);

      setFieldValue("companyName", company.CompanyName);

      if (hasAccountId) {
        setAccountRegisted(true);
      }
      if (hasDirectorId) {
        setDirectorRegisted(true)
      }

      Object.keys(company).map((key) => {
        if (key === "directorPrefecture") {
          return setFieldValue(key, prefNameToCode(company[key]));
        } else {
          return setFieldValue(key, company[key]);
        }
      });
      loading(false);
    }
    fetch(props.match.params.companyId);
    // eslint-disable-next-line
  }, []);

  const handleChangeCompanyZipCode = (result) => {
    formik.setFieldValue("prefecture", result.prefCode)
    formik.setFieldValue("city", result.city.kanji)
    formik.setFieldValue("cityKana", result.city.kana)
    formik.setFieldValue("town", result.town.kanji)
    formik.setFieldValue("townKana", result.town.kana)
  }

  const handleChangeDirectorZipCode = (result) => {
    formik.setFieldValue("directorPrefecture", result.prefCode)
    formik.setFieldValue("directorCity", result.city.kanji)
    formik.setFieldValue("directorCityKana", result.city.kana)
    formik.setFieldValue("directorTown", result.town.kanji)
    formik.setFieldValue("directorTownKana", result.town.kana)
  }

  return (
    <CommonLayout>
      <ContentHeader>
        {newEntry ? "葬儀社StripeConnectアカウント新規作成" : "葬儀社StripeConnectアカウント編集"}
      </ContentHeader>

      <Row>
        <Col md="8">
          <form onSubmit={formik.handleSubmit}>
            <div className="card">
              <div className="card-body">
                <h5>会社情報 {accountRegisted ? <span className="badge badge-success">Stripeと同期しています</span> : null}</h5>
                <div className="form-group">
                  <label className="control-label">会社名（漢字）</label>
                  <Input type="text" name="companyName" className="form-control" formik={formik} disabled={true} />
                </div>
                <div className="form-group">
                  <label className="control-label">会社名（カナ）</label>
                  <Input type="text" name="companyNameKana" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">会社名（英語）</label>
                  <Input type="text" name="companyNameEnglish" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="6">
                      <label className="control-label">法人番号</label>
                      <Input type="text" name="taxId" className="form-control" formik={formik} />
                    </Col>
                    <Col md="6">
                      <label className="control-label">本社住所・電話番号</label>
                      <Input type="text" name="phoneNo" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="3">
                      <label className="control-label">本社住所・郵便番号</label>
                      <ZipCode name="zipCode" className="form-control" formik={formik} onChange={handleChangeCompanyZipCode} />
                    </Col>
                    <Col md="3">
                      <label className="control-label">本社住所・都道府県</label>
                      <Prefecture name="prefecture" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="6">
                      <label className="control-label">本社住所・市区町村（漢字）</label>
                      <Input type="text" name="city" className="form-control" formik={formik} />
                    </Col>
                    <Col md="6">
                      <label className="control-label">本社住所・市区町村（カナ）</label>
                      <Input type="text" name="cityKana" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="6">
                      <label className="control-label">本社住所・町域名（漢字）</label>
                      <Input type="text" name="town" className="form-control" formik={formik} />
                    </Col>
                    <Col md="6">
                      <label className="control-label">本社住所・町域名（カナ）</label>
                      <Input type="text" name="townKana" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="6">
                      <label className="control-label">本社住所・番地等（漢字）</label>
                      <Input type="text" name="address1" className="form-control" formik={formik} />
                    </Col>
                    <Col md="6">
                      <label className="control-label">本社住所・番地等（カナ）</label>
                      <Input type="text" name="address1Kana" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="6">
                      <label className="control-label">本社住所・建物名・部屋番号（漢字）</label>
                      <Input type="text" name="address2" className="form-control" formik={formik} />
                    </Col>
                    <Col md="6">
                      <label className="control-label">本社住所・建物名・部屋番号（カナ）</label>
                      <Input type="text" name="address2Kana" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <h5>会社代表者情報 {directorRegisted ? <span className="badge badge-success">Stripeと同期しています</span> : null}</h5>
                <div className="form-group">
                  <Row>
                    <Col md="6">
                      <label className="control-label">会社代表者名（漢字）</label>
                      <Row>
                        <Col md="6">
                          <Input type="text" name="directorLastName" className="form-control" formik={formik} placeholder="氏" />
                        </Col>
                        <Col md="6">
                          <Input type="text" name="directorFirstName" className="form-control" formik={formik} placeholder="名" />
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6">
                      <label className="control-label">会社代表者名（カナ）</label>
                      <Row>
                        <Col md="6">
                          <Input type="text" name="directorLastNameKana" className="form-control" formik={formik} placeholder="氏" />
                        </Col>
                        <Col md="6">
                          <Input type="text" name="directorFirstNameKana" className="form-control" formik={formik} placeholder="名" />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="6">
                      <label className="control-label">会社代表者・役職名</label>
                      <Input type="text" name="directorTitle" className="form-control" formik={formik} />
                    </Col>
                    <Col md="6">
                      <label className="control-label">会社代表者・電話番号</label>
                      <Input type="text" name="directorPhoneNo" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="3">
                      <label className="control-label">会社代表者住所・郵便番号</label>
                      <ZipCode name="directorZipCode" className="form-control" formik={formik} onChange={handleChangeDirectorZipCode} />
                    </Col>
                    <Col md="3">
                      <label className="control-label">会社代表者住所・都道府県</label>
                      <Prefecture name="directorPrefecture" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="6">
                      <label className="control-label">会社代表者住所・市区町村（漢字）</label>
                      <Input type="text" name="directorCity" className="form-control" formik={formik} />
                    </Col>
                    <Col md="6">
                      <label className="control-label">会社代表者住所・市区町村（カナ）</label>
                      <Input type="text" name="directorCityKana" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="6">
                      <label className="control-label">会社代表者住所・町域名（漢字）</label>
                      <Input type="text" name="directorTown" className="form-control" formik={formik} />
                    </Col>
                    <Col md="6">
                      <label className="control-label">会社代表者住所・町域名（カナ）</label>
                      <Input type="text" name="directorTownKana" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="6">
                      <label className="control-label">会社代表者住所・番地等（漢字）</label>
                      <Input type="text" name="directorAddress1" className="form-control" formik={formik} />
                    </Col>
                    <Col md="6">
                      <label className="control-label">会社代表者住所・番地等（カナ）</label>
                      <Input type="text" name="directorAddress1Kana" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="6">
                      <label className="control-label">会社代表者住所・建物名・部屋番号（漢字）</label>
                      <Input type="text" name="directorAddress2" className="form-control" formik={formik} />
                    </Col>
                    <Col md="6">
                      <label className="control-label">会社代表者住所・建物名・部屋番号（カナ）</label>
                      <Input type="text" name="directorAddress2Kana" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <label className="control-label">会社代表者・メールアドレス</label>
                  <Input type="text" name="directorEmail" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="3">
                      <label className="control-label">会社代表者・生年月日</label>
                      <Input type="date" name="dob" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <h5>銀行口座情報</h5>
                <div className="form-group">
                  <label className="control-label">会社代表者・身分証明書のコピー</label>
                  <Row>
                    <Col md="6">
                      <label className="control-label">表面</label>
                      <UploadImage type="file" name="idFileFront" className="form-control" formik={formik} width={360} />
                    </Col>
                    <Col md="6">
                      <label className="control-label">裏面</label>
                      <UploadImage type="file" name="idFileBack" className="form-control" formik={formik} width={360} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="3">
                      <label className="control-label">銀行コード</label>
                      <Input type="text" name="bankCode1" className="form-control" formik={formik} placeholder="0000" />
                    </Col>
                    <Col md="3">
                      <label className="control-label">支店コード</label>
                      <Input type="text" name="bankCode2" className="form-control" formik={formik} placeholder="000" />
                    </Col>
                    <Col md="4">
                      <label className="control-label">口座番号</label>
                      <Input type="text" name="accountNo" className="form-control" formik={formik} placeholder="0000000" />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <label className="control-label">口座名義</label>
                  <Input type="text" name="accountName" className="form-control" formik={formik} placeholder="カ）〇〇〇〇〇" />
                </div>
                <div className="form-group" style={{ paddingLeft: "1.25rem" }}>
                  <label className="form-check-label">
                    <Input type="checkbox" name="accepted" className="form-check-input" formik={formik} />
                    Stripeの利用規約に同意します
                  </label>
                </div>
              </div>

              <div className="card-footer">
                <div>
                  <Button variant="secondary" className="float-right" onClick={() => history.goBack()}>戻る</Button>
                  {
                    newEntry ?
                      <Button type="submit" variant="success">作成</Button>
                      :
                      <>
                        <Button type="submit" variant="warning">更新</Button>
                      </>
                  }
                </div>
              </div>
            </div>
          </form>
        </Col>
      </Row>

    </CommonLayout>
  );
};

const getCompany = async (companyId) => {
  return await API.get('product', '/companies/' + companyId);
}

const createCompanyStripeConnect = async (companyId, form) => {
  form.prefectureKanji = prefCodeToName(form.prefecture);
  form.prefectureKana = prefCodeToKana(form.prefecture);
  form.directorPrefectureKanji = prefCodeToName(form.directorPrefecture);
  form.directorPrefectureKana = prefCodeToKana(form.directorPrefecture);
  const options = {
    body: form
  }
  return await API.post('product', '/companies/' + companyId + '/connect', options);
}

export default CompanyStripeConnectEdit;
