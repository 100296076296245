import { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 5px;
`

const Select = styled.select`
  width: 100%;
  margin: 0 0.5rem;
  min-width: 4rem;
`

export const DateTime = ({name, formik, onChange}) => {
  const hours = [...Array(24)].map((_, i) => ('0' + i).slice(-2));
  const minutes = [...Array(12)].map((_, i) => ('0' + (i * 5)).slice(-2));

  const [date, setDate] = useState("");
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");

  const hasError = formik && formik.errors[name];

  useEffect(() => {
    if (formik.values[name]) {
      const current = moment(formik.values[name]);
      setDate(current.format("YYYY-MM-DD"));
      setHour(current.format("HH"));
      setMinute(current.format("mm"));
    }
  }, [formik, name])

  const handleDateChange = (e) => {
    setDate(e.target.value);
    if(e.target.value && hour && minute) {
      formik.setFieldValue(name, e.target.value + " " + hour + ":" + minute);
      onChange && onChange(e.target.value + " " + hour + ":" + minute);
    } else {
      formik.setFieldValue(name, "");
      onChange && onChange("");
    }
  }

  const handleHourChange = (e) => {
    setHour(e.target.value);
    if(date && minute && e.target.value) {
      formik.setFieldValue(name, date + " " + e.target.value + ":" + minute);
      onChange && onChange(date + " " + e.target.value + ":" + minute);
    } else {
      formik.setFieldValue(name, "");
      onChange && onChange("");
    }
  }

  const handleMinuteChange = (e) => {
    setMinute(e.target.value);
    if(date && hour && e.target.value) {
      formik.setFieldValue(name, date + " " + hour + ":" + e.target.value);
      onChange && onChange(date + " " + hour + ":" + e.target.value);
    } else {
      formik.setFieldValue(name, "");
      onChange && onChange("");
    }
  }

  return (
    <>
      <Flex className={formik.errors[name] ? "is-invalid" : ""}>
        <input type="date" className={"form-control" + (hasError ? " is-invalid" : "")} value={date} onChange={handleDateChange} onBlur={handleDateChange}/>
        <Select className={"custom-select" + (hasError ? " is-invalid" : "")} value={hour} onChange={handleHourChange} onBlur={handleHourChange}>
          <option value=""></option>
          { hours.map((h, i) => <option key={i} value={h}>{h}</option>) }
        </Select>
        <div>時</div>
        <Select className={"custom-select" + (hasError ? " is-invalid" : "")} value={minute} onChange={handleMinuteChange} onBlur={handleMinuteChange}>
          <option value=""></option>
          {minutes.map((m, i) => <option key={i} value={m}>{m}</option>)}
        </Select>
        <div>分</div>
      </Flex>
      <div className="invalid-feedback">{formik.errors[name]}</div>
    </>
  )
}

export default DateTime;