import { Img } from 'components/Img';
import { MESSAGE_FEE } from '../constants';

export const CartTotal = (props) => {
  const {selectedItem, type, giftItemList} = props;

  switch (type) {
    case 'gift':
      return (
        <div id="cart-total">
          <TotalOrderQuantity CartItemList={ giftItemList && giftItemList }/>
          <hr />
          <div className="price-label">商品内訳</div>
          {
            giftItemList &&
            giftItemList.map(
              (item, index) => <ItemBreakdown key={index} item={item}/>
            )
          }
          <hr />
          <TotalPrice CartItemList={ giftItemList && giftItemList }/>
          {props.children}
        </div>
      )
    default :
      return (
        <div id="cart-total">
          <div className="image-grid">
            {
              selectedItem.map((item, index) =>
                <div key={index} className="item-image">
                  <Img src={item.imagePath}/>
                  <span className="cart-item-quantity mincho">{item.targetCount !== undefined ? item.targetCount : 1}</span>
                </div>
              )
            }
          </div>
          <TotalOrderQuantity CartItemList={selectedItem}/>
          <hr/>
          <TotalPrice CartItemList={selectedItem}/>
          <hr />
          {props.children}
        </div>
      )
  }
}

const ItemBreakdown = (props) => {
  const { item } = props
  return (
    <div className={'d-flex justify-content-between'}>
      <div>
        { item.targetCount !== undefined ? item.itemName + " × " + item.targetCount : item.itemName + " × 1"}
      </div>
      <div>
        {
          parseInt(item.totalPrice !== undefined ? item.totalPrice : item.price, 10).toLocaleString()
        }円
      </div>
    </div>
  )
}

const TotalOrderQuantity = (props) => {
  const { CartItemList } = props;
  return (
    <div className="total-order-quantity">
      <div className="quantity-label">注文内容</div>
      <div className="vertical-line">|</div>
      <div className="quantity-value">{
        CartItemList.filter(item => {
          return (item.targetCount !== 0 && item.orderId === null) || (item.targetCount === undefined && item.orderId !== null)
        }).length
      }件</div>
    </div>
  );
}

const TotalPrice = (props) => {
  const { CartItemList } = props;
  return (
    <div className="total-price">
      <div className="price-label">商品合計</div>
      <div className="price-value">
        {
          CartItemList.reduce((previous, current) => {
            let amount = parseInt(current.totalPrice ? current.totalPrice : current.price, 10);
            // 香典の場合は香典システム手数料を加算
            if (current.itemType === "money") amount = amount + KoudenCommission(current.price) + MESSAGE_FEE;
            return previous + amount;
          }, 0).toLocaleString()
        }
        <span className="yen">円</span>
      </div>
    </div>
  );
}

// 香典手数料を取得する
const KoudenCommission = (price) => {
  switch (price) {
    case 3000:
      return 330;
    case 5000:
      return 495;
    case 10000:
      return 891;
    case 20000:
      return 1650;
    case 30000:
      return 2200;
    case 50000:
      return 3300;
    case 70000:
      return 4400;
    case 100000:
      return 5500;
    default:
      return 0;
  }
}

export default CartTotal;