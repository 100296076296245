import AttendeeLayout from 'components/AttendeeLayout'
import { useHistory } from 'react-router-dom';
import {Button, Card, Col, Modal, Row} from 'react-bootstrap';
import CardHeader from 'react-bootstrap/CardHeader';
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Img } from 'components/Img';
import { useLoading } from 'components/Loading';
import { setCartGiftItem, getCartGiftContents } from 'components/Cart';

import { OTHER_ITEMS, OTHER_ITEM_TYPE } from '../../constants';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FuneralGiftSelectPanelEntry = (props) => {
  const { gift, jpy, order, funeralId } = props;
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const onClickShowConfirmModalButton = () => {
    setShowModal(true);
  };

  const lessLargerFontStyle = {
    fontSize: "1.25rem",
  };
  const largeFontStyle = {
    fontSize: "1.5rem",
  };
  const largerFontStyle = {
    fontSize: "1.75rem",
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  const onSelectModalButtonClick = () => {
    onModalClose();
    setCartGiftItem(funeralId, gift, jpy, order);
    history.goBack();
  };

  return (
    <Col xs>
      <Card style={{ borderColor: "#bbb", padding: "0", marginTop: "1rem" }}>
        <Img imgClassName={'photo-gift-square mx-sm-2 d-block'} src={gift.ImagePath}/>
        <CardHeader style={{
          borderBottom: "0.2rem solid rgba(0,0,0,0.125)",
          margin: ".75rem 1.25rem",
        }}>
        <span>
          <span style={{ fontSize: "1.75rem" }}>{gift.GiftName}</span>
        </span>
        </CardHeader>
        <Card.Body style={{ paddingLeft: "0", paddingRight: "0" }}>
          <div>
            <span style={{ fontSize: "1.5rem" }}>{gift.Price.toLocaleString()}</span>
            <span style={{ verticalAlign: "text-bottom" }}> 円 (税込)</span>
          </div>
          <Button className={'mold-gift-select-btn'} onClick={onClickShowConfirmModalButton}>
            <span className={'mx-sm-4'}>返礼品として選ぶ</span>
          </Button>
        </Card.Body>
      </Card>
      <Modal
          show={showModal}
          centered={true}
          className={"workaround-modal-funeral-gift-select"}
          onHide={() => onModalClose()}>
        <Modal.Header closeButton={true} style={{ borderBottomStyle: "unset", paddingTop: "1rem" }}>
          <div style={{ width: "100%", }}>
            <div style={{ marginTop: "5rem" }}>
              <Modal.Title style={{
                fontSize: "2.25rem",
                textAlign: "center",
              }}>{gift.GiftName}</Modal.Title>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{ paddingLeft: "1rem", paddingRight: "1rem"}}>
            <div className={'d-flex justify-content-center'}>
              <Img imgClassName={'photo-gift-square-modal img-fluid'} src={gift.ImagePath} alt={"返礼品の画像"}/>
            </div>
            <div style={{ marginLeft: "15%", marginRight: "15%", marginTop: "4rem" }}>
              <div className={"funeral-gift-select-price-detail-row"}>
                <span style={{ ...largeFontStyle, marginRight: "21%", }}>価格</span>
                <span style={{ textAlign: "right" }}>
                  <span style={{ fontSize: "3.5rem", marginRight: "7%" }}>{gift.Price.toLocaleString()}</span>
                  <span style={{ ...largerFontStyle }}>円</span>
                </span>
              </div>
              <div className={"funeral-gift-select-price-detail-row"} style={{ textAlign: "right", height: "3.5rem" }}>
                <span style={{ ...lessLargerFontStyle, top: "25%", position: "relative" }}>税込</span>
              </div>
              <div>
                <div style={{ ...largeFontStyle, marginBottom: "3rem" }}>商品内容</div>
                <div style={{ ...largeFontStyle, marginBottom: "3rem", overflow: "auto" }}>
                  {gift.Description}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Button style={{
          ...largerFontStyle,
          marginLeft: "30%",
          marginRight: "30%",
          marginBottom: "2rem",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          borderColor: "#555",
          backgroundColor: "#444",
          borderRadius: "1.5rem",
        }} onClick={() => onSelectModalButtonClick()}>選択する</Button>
      </Modal>
    </Col>
  )
}

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FuneralGiftSelectPanel = (props) => {
  const { giftList, jpy, individualEntry, order, funeralId } = props;

  if (!jpy) {
    return (
        <p>金額が存在しません</p>
    )
  }

  if (giftList.length === 0) {
    return (
        <p>選択できる商品が存在しません</p>
    )
  }

  return (
      <Row style={{
        backgroundColor: "white",
        paddingLeft: "5rem",
        paddingRight: "5rem",
        paddingTop: "2.5rem",
        paddingBottom: "2.5rem",
        borderRadius: "4rem",
      }} xs={1} sm={1} md={2}>
        {
          giftList.map((gift, index) => {
            return <FuneralGiftSelectPanelEntry key={index} gift={gift} jpy={jpy} individualEntry={individualEntry} order={order} funeralId={funeralId} />
          })
        }
      </Row>
  );
}

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const FuneralGiftSelect = (props) => {
  const individualEntry = props.method === "individual";
  const { orderId } = props.match.params;
  const [order, setOrder] = useState({});
  const [userName, setUserName] = useState('');

  const [giftList, setGiftList] = useState([]);
  const { funeralId } = props.match.params;
  const loading = useLoading();

  const query = new URLSearchParams(props.location.search);
  const rawJPY = query.get("jpy");
  const jpy = parseInt(rawJPY, 10);

  useEffect( ()  => {
    loading(true);
    const fetch = async () => {
      try {
      const gifts = await getGiftList(funeralId);
      const swellGifts = await getSwellGiftList();
      const typeMoney = (jpy || rawJPY) === OTHER_ITEMS ? OTHER_ITEM_TYPE : "Money" + jpy;
      let giftList = [];

      if (individualEntry) {
        const order = await getOrder(funeralId, orderId);
        setOrder(order)
        setUserName(order.UserName);

        // 金額毎に選択されてる返礼品は、個別設定の際に表示されない様にする（既に選択されているものを個別に設定できる必要はない為）
        const cart = await getCartGiftContents(funeralId);
        const categoryGift = cart.find(item => item.priceCategory === jpy && item.orderId === null);
        giftList = gifts.itemList.filter(gift => gift[typeMoney] && gift?.GiftId !== categoryGift?.giftId)
        setGiftList(giftList);
      } else {
        giftList = gifts.itemList.filter(gift => gift[typeMoney]);
        setGiftList(giftList);
      }

      if(giftList.length === 0) {
        const swellGiftList = swellGifts.itemList.filter(gift => gift[typeMoney]);
        setGiftList(swellGiftList);
      }
    } catch (e) {
      console.log(e);
    } finally {
      loading(false);
    }
    }

    fetch();
  }, [loading, jpy, rawJPY, funeralId, individualEntry, orderId]);

  const selectHeader = (
      <div style={{
        backgroundColor: "white",
        width: "90%",
        borderRadius: "0.5rem",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "4rem",
        marginTop: "96px",
        paddingBottom: "1.25rem",
        paddingTop: "1.25rem",
      }}>
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          {rawJPY === OTHER_ITEMS ? <div className="gift-category">供花供物・その他香典</div> :
            <div className="gift-category">{individualEntry ? userName + ' 様' : `香典${jpy.toLocaleString()}円分`}</div>
          }
          <div style={{ fontSize: "2.25rem", fontWeight: "bold" }}>返礼品一覧</div>
        </div>
      </div>
  );

  return (
      <AttendeeLayout adminPage={true}>
        {selectHeader}
        <FuneralGiftSelectPanel jpy={jpy || rawJPY}  giftList={giftList} individualEntry={individualEntry} order={order} funeralId={funeralId}/>
      </AttendeeLayout>
  );
};

/**
 * @param {string} funeralId
 * @returns PartialGiftResponse[]
 */
const getGiftList = async (funeralId) => {
  const options = {
    queryStringParameters: {
      funeralId: funeralId,
    }
  };
  return await API.get('product', '/gifts', options);
}

const getSwellGiftList = async () => {
  return await API.get('product', '/gifts/swell');
}

const getOrder = async (funeralId, orderId) => {
  const options = {
    queryStringParameters: {
      funeralId: funeralId,
      orderId: orderId
    }
  };
  return await API.get('product', '/order', options);
}

/**
 * @typedef {
 *     {
 *         GiftName: string,
 *         Description: string,
 *         Money3000: boolean,
 *         Money5000: boolean,
 *         Money10000: boolean,
 *         Money30000: boolean,
 *         Money50000: boolean,
 *         Money100000: boolean,
 *         Price: number,
 *         ImagePath: string.
 *         GiftId: string
 *     }
 * } PartialGiftResponse
 */

/**
 * @typedef {
 *     {
 *          itemList: PartialGiftResponse[]
 *     }
 * } gifts
 */

export default FuneralGiftSelect;
