export const PreRegistrationThanks = () => {
  return (
    <div id="auth-main">
      <div id="auth-common">
        <img className="logo" src="/images/itowa-logo-square.jpg" alt="logo"></img>
        <div className="title">申請完了</div>
        <p>itowa公式LINEより後ほどご連絡させていただきます。</p>
      </div>
    </div>
  )
}

export default PreRegistrationThanks;
