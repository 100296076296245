import { useState, useEffect, useContext } from 'react';
import { API } from 'aws-amplify'
import { useHistory, useRouteMatch } from 'react-router-dom';

import AttendeeLayout from 'components/AttendeeLayout';
import AttendeeDownloadCSV from 'components/AttendeeDownloadCSV';
import { prefCodeToName } from 'components/CustomForms/Prefecture';
import AttendeeOrderList from 'components/AttendeeOrderList';
import AttendeeReceptionList from 'components/AttendeeReceptionList';
import { AttendanceContext, AuthContext } from 'components/Contexts';

import useMessage from 'components/Message';
import useLoading from 'components/Loading';
import * as format from 'utils/format';
import ReceiveMoneySetting from 'components/ReceiveMoneySetting';

export const AttendeeUserList = (props) => {

  const match = useRouteMatch("/:funeralId");
  const history = useHistory();
  const loading = useLoading();
  const message = useMessage();
  const funeralId = match.params.funeralId;
  const returnGiftURL = `/${funeralId}/funeral/gift`;

  const [attendeeUserList, setAttendeeUserList] = useState([]);

  const [currentTab, setCurrentTab] = useState("order"); 
  const { funeralInfo, setFuneralInfo } = useContext(AttendanceContext);
  const { userInfo } = useContext(AuthContext);
  const [showButton, setShowButton] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [listOrdersSelected, setListOrdersSelected] = useState([]);

  const initialState = props?.location?.state === 'money' ? 'money' : 'all';
  const [currentFilter, setCurrentFilter] = useState(initialState); 

  useEffect(() => {
    if(funeralInfo?.showNotifyConfirmMoney && currentFilter === 'money' && userInfo?.isChiefMourner) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
    
    // eslint-disable-next-line
  }, [funeralInfo?.showNotifyConfirmMoney, currentFilter]); 

  const fetchAttendee = async () => {
    loading(true);
    try {
      const result = await getAttendeeUserList(funeralId, currentTab, currentFilter);
      if(result.data) {
        result.data.forEach((user) => {
          user.ZipCode = user?.Address?.ZipCode;
          if(user?.IsOrderedReturnGift === false) {
            if(result?.attendeeType === 'order') {
              user.FormatAddress = prefCodeToName(user?.Address?.Prefecture) + user?.Address?.Address1 + user?.Address?.Address2; 
            } else {
              user.FormatAddress = user?.Address?.Prefecture + user?.Address?.City + format.townCustom(user?.Address?.Town) + user?.Address?.Address1;
            }
          } else {
            if(result?.attendeeType === 'order') {
              user.FormatAddress = prefCodeToName(user?.Address?.Prefecture) + user?.Address?.Address1 + user?.Address?.Address2; 
            } else {
              user.FormatAddress = user?.Address?.Prefecture + user?.Address?.City + format.townCustom(user?.Address?.Town) + user?.Address?.Address1;
            }
          }
          user.Age = user?.Statistics && user?.Statistics?.Age + "代";
          user.Gender = user?.Statistics && format.gender(user?.Statistics?.Gender);
          user.Relation = user?.Statistics && format.relations(user?.Statistics?.Relation);
          user.OrderReturnGiftDate = user?.OrderReturnGiftDate || '未';
          user.OrderUserName = user?.OrderLabel || user?.UserName || '';
        });
        setAttendeeUserList(result.data);
      } else {
        setAttendeeUserList([]);
      } 
      setFuneralInfo((prev) => ({
        ...prev,
        showNotifyConfirmMoney: result?.showNotifyConfirmMoney,
      }));
    } catch (e) {
      if (e?.response?.status === 400) {
        message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
      } else {
        message.alert.danger("システムエラーが発生しました");
      }
    } finally {
      loading(false);
    }
  }

  useEffect(() => {
      fetchAttendee();
    
    // eslint-disable-next-line
  }, [currentTab, currentFilter]);
  
  const handleTabChange = (tab) => {
    setCurrentTab(tab);
    setCurrentFilter('all');
  }
    
  const handleSelectFilter = (filter) => {
    setCurrentFilter(filter);
  }

  const handleReceivedMoney = () => {
    setShowPopup(true);
  }

  return (
    <AttendeeLayout adminPage={true}>
      <ReceiveMoneySetting showPopup={showPopup} setShowPopup={setShowPopup} listOrdersSelected={listOrdersSelected} funeralId={funeralId} funeralInfo={funeralInfo} setFuneralInfo={setFuneralInfo} setShowButton={setShowButton} getListAttendee={fetchAttendee} />

      <div id="attendee-users">
        <div className="title-search">
          <div className="title">
            <div className='title-page'>
              <p>
                参列者・注文者リスト
              </p>
            </div>
            {userInfo?.isChiefMourner && (
              <div>
                <button className="btn btn-primary select-gift-button" onClick={() => {history.push(returnGiftURL)}}>
                  返礼品を選ぶ <p className='fas fa-chevron-right'></p>
                </button>
              </div>
            )}
            {
              showButton && (
                <div className="received-denied-buttons">
                  <button className="btn btn-primary received-button" onClick={() => handleReceivedMoney()}>
                    受取可否を確定する 
                  </button>
                </div>
              )
            }
            { attendeeUserList.length > 0 && (
              <AttendeeDownloadCSV currentTab={currentTab} attendeeUserList={attendeeUserList} />
              )
            }
          </div>
        </div>
        <div className="card gothic">
          <div className='tab-filter'>
            <button className={currentTab === 'order' ? 'active' : ''} onClick={() => handleTabChange('order')}>
              注文者リスト
            </button>
            <button className={currentTab === 'reception' ? 'active' : ''} onClick={() => handleTabChange('reception')}>
              参列者リスト
            </button>
          </div>
          {
            currentTab === 'order' && (
              <AttendeeOrderList attendeeUserList={attendeeUserList} handleSelectFilter={handleSelectFilter} currentFilter={currentFilter} listOrdersSelected={listOrdersSelected} setListOrdersSelected={setListOrdersSelected} />
            )
          }

          {
            currentTab === 'reception' && (
              <AttendeeReceptionList attendeeUserList={attendeeUserList} handleSelectFilter={handleSelectFilter} currentFilter={currentFilter} />
            )
          }
        </div>
      </div>
    </AttendeeLayout>
  );
};

const getAttendeeUserList = async (funeralId, tab, filter) => {
  const options = {
    queryStringParameters: {
      funeralId: funeralId,
      attendeeType: tab,
      filterBy: filter,
    }
  };

  return await API.get('product', '/users/attendee', options);
}

export default AttendeeUserList;