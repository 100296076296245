import * as Constants from '../constants';

export const CommonFooter = (props) => {
  return (
    <>
      <div className="footer-logo-wrapper">
        <img className="footer-logo" src="/images/itowa_blacklogomain.png" alt="" />
        <div className="footer-dummy footer-surface"></div>
        <div className="footer-ellipse"></div>
        <div className="footer-dummy footer-base"></div>
      </div>

      <div className="copyright">
        <div className="cp-links">
          <div className="law"><a href={`${Constants.UNDERTAKER_WEB_URL}/${props.companyId}/law/info`} target="_blank" rel="noreferrer"><span>特商法</span><span>（香典・お供え）</span></a></div>
          <div className="privacy-policy-undertaker"><a href={props.privacyPolicyUrl} target="_blank" rel="noreferrer"><span>プライバシーポリシー</span><span>（葬儀社）</span></a></div>
          <div className="privacy-policy-swell"><a href={Constants.PRIVACY_POLICY_URL} target="_blank" rel="noreferrer"><span>プライバシーポリシー</span><span>（株式会社itowa）</span></a></div>
          <div className="kiyaku"><a href={Constants.KIYAKU_URL} target="_blank" rel="noreferrer">利用規約</a></div>
          <div className="swell-info"><a href={Constants.BLOG_TOP_URL} target="_blank" rel="noreferrer">サービス提供会社情報</a></div>
        </div>
        <div className="cp-text">
          2022 (c) itowa-inc. All Rights Reserved.
        </div>
      </div>
    </>
  )
}

export default CommonFooter;