import { API } from 'aws-amplify'
import * as Constants from "../constants";

export const getUserInfo = async (uuid) => {
  try {
    const url = window.location
    let funeralId;
    let companyId;
    switch (url.hostname) {
      case Constants.FUNERALS_DOMAIN:
        funeralId = uuid;
        companyId = "";
        break;
      case Constants.UNDERTAKER_DOMAIN:
        funeralId = "";
        companyId = uuid;
        break;
      default:
        funeralId = "";
        companyId = "";
    }

    const options = {
      queryStringParameters: {
        funeralId: funeralId,
        companyId: companyId
      }
    };

    const userInfo = await API.get("product", "/auth", options);
    if (!userInfo.error) {
      return userInfo;
    }
  } catch (err) {
    if (err.message === "Cannot retrieve a new session. Please authenticate." || err.message === "Refresh Token has expired") {
      const userName = localStorage.getItem(`${Constants.COGNITO_LS_KEY}.LastAuthUser`);
      if (userName) {
        localStorage.removeItem(`${Constants.COGNITO_LS_KEY}.LastAuthUser`);
        localStorage.removeItem(`${Constants.COGNITO_LS_KEY}.${userName}.accessToken`);
        localStorage.removeItem(`${Constants.COGNITO_LS_KEY}.${userName}.refreshToken`);
        localStorage.removeItem(`${Constants.COGNITO_LS_KEY}.${userName}.idToken`);
        localStorage.removeItem(`${Constants.COGNITO_LS_KEY}.${userName}.clockDrift`);
        localStorage.removeItem(`${Constants.COGNITO_LS_KEY}.${userName}.userData`);
      }
      // No current userというエラーは無視する
    } else if (err !== "No current user") {
      throw err
    }
  }
}