import AttendeeLayout from 'components/AttendeeLayout';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { API } from 'aws-amplify'
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import React from 'react';
import { Img } from 'components/Img';
import { getCartGiftContents } from 'components/Cart';
import { useLoading } from 'components/Loading';
import { useEffect, useState } from 'react';

import { OTHER_ITEMS, CATEGORY_GIFT_LIST } from '../../constants';

const GiftChoiceEntry = (props) => {
  const { jpy, cart } = props;
  const history = useHistory();

  /** @type {Gift} */
  const gift = cart.find(item => item.priceCategory === jpy　&& item.orderId === null);

  const onClickSelectButton = () => {
    history.push("./gift/select?jpy=" + jpy)
  };

  return (
      <Card style={{ marginTop: "5%", marginBottom: "5%", paddingLeft: "0", paddingRight: "0", marginLeft: "auto", marginRight: "auto", borderStyle: "none" }}>
        <Img imgClassName={'photo-gift-circle img-fluid'} src={ gift ? gift.imagePath : ''}/>
        <Card.Body>
          <div style={{ textAlign: "center", height: "1.1rem", fontSize: "1.1rem", marginBottom: "1.5rem" }}>
            { gift ? gift.giftName : "" }
          </div>
          <div style={{ textAlign: "center", fontSize: "1.5rem" }}>{jpy === OTHER_ITEMS ? "供花供物・その他香典" : `香典${jpy.toLocaleString()}円分`}</div>
          <Button className={'mold-gift-select-btn'} onClick={onClickSelectButton}>
            <span className={'mx-sm-4'}>返礼品を選ぶ</span>
          </Button>
        </Card.Body>
      </Card>
  );
}

const GiftChoiceEntries = (props) => {
  const { funeralId } = props;
  const loading = useLoading();
  const [cart, setCart] = useState([{}]);
  const [attendeeMoney, setAttendeeMoney] = useState([]);

  useEffect( ()  => {
    loading(true);
    const fetch = async () => {
      try {
      const cart = await getCartGiftContents(funeralId);
      const result = await getOrderAttendeeList(funeralId);
      const resultArray = [];
      CATEGORY_GIFT_LIST.forEach((key) => {
        if (result?.groupedOrders[key]?.length > 0) {
          resultArray.push(key);
        }
      });
      setAttendeeMoney(resultArray);
      setCart(cart);
    } catch (e) {
      console.log(e);
    } finally {
      loading(false);
    }
    }
    fetch();
  }, [loading, funeralId]);
  // Containerはコンテナ要素の幅を100%として均等に要素を配置しようとするが、100vwに対して均等に配置されると
  // 水平方向に対するカード同士の間隔が広すぎて不都合なのでコンテナ要素の親で画面幅に対する制限を設定する
  return (
      <div style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "85%" }}>
        <Container fluid={true}>
          <Row xs={2} md={3}>
              {attendeeMoney && attendeeMoney.map((e, idx) => {
                return (
                    <Col key={ idx }>
                      <GiftChoiceEntry jpy={ e } cart={cart}/>
                    </Col>
                );
              })}
          </Row>
        </Container>
      </div>
  );
}

export const FuneralGiftList = (props) => {
  const history = useHistory();
  const { funeralId } = props.match.params;

  const funeralGiftList = () => {
    history.push("../funeral/gift/return");
  }

  return (
      <AttendeeLayout adminPage={true}>
        <React.Fragment>
          <Helmet>
            <title>香典返し用の返礼品を選ぼう | itowa</title>
          </Helmet>
        </React.Fragment>
        <div className="content-header title-gift">
          返礼品を選択してください
        </div>
        <GiftChoiceEntries funeralId={funeralId}/>
      <div className="d-grid gap-2 col-6 mx-auto">
        <button type="button" className="btn btn-lg btn-dark" onClick={() => funeralGiftList()}>香典リストを確認する</button>
      </div>
      </AttendeeLayout>
  );
};

const getOrderAttendeeList = async (funeralId) => {
  const options = {
    queryStringParameters: {
      funeralId: funeralId,
      sortOrder: 'ASC',
    }
  };
  return  await API.get('product', '/orders/attendee', options);
}


/** @typedef {string} S3ImagePath */
/** @typedef {
 *      {
 *          itemName: string | undefined,
 *          price: number,
 *          imagePath?: S3ImagePath,
 *          giftId: string,
 *          itemType: string,
 *          priceCategory: string,
 *          userName: string | undefined,
 *          orderId: string | undefined,
 *          targetAttendeeId: string | targetAttendeeId,
 *          uuid: string
 *      }
 * } Gift */

export default FuneralGiftList;
