import { useState, useEffect, useContext, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Modal } from 'react-bootstrap';
import { API } from 'aws-amplify';
import 'moment/locale/ja';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';

import { CHIEF_MOUENER_MONEY_STATUS } from '../../constants';

import { getCartContents } from 'components/Cart';

import { AuthContext, AttendanceContext } from 'components/Contexts';
import AttendeeLayout from 'components/AttendeeLayout';
import { PhotoGallery } from 'components/PhotoGallery';
import * as format from 'utils/format';
import Footer from '../../components/AttendeeLayout/Footer';

import ModalRecieveMoney from 'components/ModalRecieveMoney';
import ModalFuneralMoney from 'components/ModalFuneralMoney';

const checkLogin = (userInfo, funeralId) => {
  if (userInfo && userInfo.attendees !== null && userInfo.attendees.FuneralId === funeralId) {
    return true;
  }
  return false;
}

export const Top = (props) => {
  const [attendanceMethods, setAttendanceMethods] = useState([]);

  const match = useRouteMatch("/:funeralId");
  const history = useHistory();
  const funeralId = match.params.funeralId;
  const pathPrefix = "/" + funeralId;

  const { userInfo } = useContext(AuthContext);
  const { setAttendance, funeralInfo, setShowFuneralMoney, showFuneralMoney } = useContext(AttendanceContext);

  const giftBoxRef = useRef();

  const [photoList, setPhotoList] = useState([]);
  const [showFuho, setShowFuho] = useState(false);
  const [firstModal, setFirstModal] = useState(false);
  const [isHaveKoudenList, setIsHaveKoudenList] = useState(false);

  const isLogin = checkLogin(userInfo, funeralId)

  const [isEndedFuneral, setIsEndedFuneral] = useState(false);

  // 請求書払いがONの時、香典以外の商品のボタンに"請求書払い可"を表示
  const [isInvoiceDisplay, setIsInvoiceDisplay] = useState(false);

  // 会場住所GoogleMapのiframe.src
  const [iframeSrc, setIframeSrc] = useState("");

  useEffect(() => {
    let haveKoudenList = false;

    const fetchDisplayItem = async () => {
      const result = await getDisplayItem(funeralId);
      const attendanceMethodList = [];

      // 香典払いがONだった場合、香典、長文ボタンの文字の位置を修正する

      if (result?.koudenList?.length > 0) {
        haveKoudenList = true;
        setIsHaveKoudenList(true)
      }

      if (result.choudenList.length > 0 || result.sagawaList.length > 0) {
        // TODO: 以下の葬家のみ弔文・弔電を贈れないようにするための一時的なコード (高宮家)
        if (funeralId !== "c2c83828-46db-4b03-88f9-932d98a22084") {
          attendanceMethodList.push({label: "弔文・弔電を贈る", class: "chouden", pict: "icon-chouden.png", isInvoiceOff: true, handle: toChoudenPage, isLabelCenter: true});
        }
      }

      if (result.kyoukaList.length > 0) {
        attendanceMethodList.push({label: "お供花を贈る", class: "kyouka", pict: "icon-kyouka.png", Invoice: "請求書払い可", handle: toKumotsuPage});
      }

      if (result.kumotsuList.length > 0) {
        attendanceMethodList.push({label: "お供物を贈る", class: "kumotsu", pict: "icon-kumotsu.png", Invoice: "請求書払い可", handle: toKumotsuPage});
      }

      setIsInvoiceDisplay(funeralInfo.invoicePaymentCheck);
      setAttendanceMethods(attendanceMethodList);
    }
    fetchDisplayItem();
    if (isLogin || isPublicPhotoFuneral(funeralId)) {
      const fetch = async () => {
        const response = await getTopPagePhoto(funeralId);
        setPhotoList(response.attendees);
      }
      fetch();
    }

    const loadIsFirst = () => {
      const isFirst = localStorage.getItem('isFirst');
      if (isFirst || userInfo) {
        setFirstModal(JSON.parse(isFirst));
      } else {
        // ログインしていないかキーが存在しない場合は初めての訪問者ということになる
        setFirstModal(true);
      }
    }
    loadIsFirst();

    // 本日の日付が葬儀日より後なら葬儀済みとする (日付が切り替わった瞬間に変わるようになっている)
    moment.locale('ja');
    setIsEndedFuneral(moment() > moment(funeralInfo.funeralDateTime).endOf('day'));

    // Handle logic show popup funeral money
    let haveItemMoney;
    const fetch = async () => {
      const cart = await getCartContents(funeralId);
      haveItemMoney = cart?.find((item) => item?.itemType === 'money');
    }
    fetch();

    // 会場住所のGoogleMapのiframe.srcを設定
    const srcMatch = funeralInfo.googleMapUrl?.match(/src="([^"]*)"/);
    if (srcMatch) {
      setIframeSrc(srcMatch[1]);
    }  else {
      setIframeSrc(`https://maps.google.co.jp/maps?output=embed&q=${format.address(funeralInfo.hallAddress)}&z=16`);
    }

    const timoutShowPopup =  setTimeout(() => {
      const showPopupMoney = localStorage.getItem(`${funeralId}_showPopupMoney`);
      if (
        !userInfo?.isChiefMourner && 
        !userInfo?.attendees?.Relatives && 
        haveKoudenList && 
        !haveItemMoney && 
        funeralInfo?.chiefMournerMoneyStatus !== CHIEF_MOUENER_MONEY_STATUS.DENIED &&
        funeralInfo?.enableMoneyFlow &&
        showPopupMoney !== '1'
      ) {
        setShowFuneralMoney(true);
      }
    }, 15000);

    return () => {
      clearTimeout(timoutShowPopup);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funeralInfo, userInfo]);

  const toChoudenPage = () => {
    history.push(pathPrefix + "/attendance/content/select/chouden");
  }

  const toKumotsuPage = () => {
    history.push(pathPrefix + "/attendance/content/select/kumotsu");
  }

  const toAlbum = () => {
    if (isLogin || isPublicPhotoFuneral(funeralId)) {
      history.push(pathPrefix + "/album");
    } else {
      setAttendance(true);
    }
  }

  const toGoogleMap = () => {
    var url = 'https://www.google.com/maps/search/?api=1&query=' + format.address(funeralInfo.hallAddress);
    if (funeralInfo.googleMapAppUrl) {
     url = funeralInfo.googleMapAppUrl; 
    }

    window.open(url, '_blank');
  }

  const toCall = () => {
    window.open(`tel:${funeralInfo.hallPhoneNo}`, '_self');
  }

  const firstModalClose = () => {
    localStorage.setItem("isFirst", false.toString());
    setFirstModal(false);
  }

  useEffect(() => {
    return () => {
      setShowFuneralMoney(false);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <AttendeeLayout giftBoxRef={giftBoxRef}>
      {funeralInfo ?
        <div id="top">
          <Helmet>
            <title>{`${funeralInfo.familyName}家様トップページ | itowa`}</title>
          </Helmet>
          <div id="top-banner">
            <div id="banner-bg">
              <img src="/images/cloud-sky.png" alt=""></img>
            </div>
            <div className="v-text">
              <div>人 の 心 の</div>
              <div>繋 が り が</div>
              <div>限 り な く</div>
              <div>続 い て い く</div>
            </div>
          </div>
          <div>
            {isEndedFuneral ?
            <div>
              <h2 className="item-title">追悼</h2>
              <p className="p-eimin">
                葬儀式は{format.mourningEnd(funeralInfo.funeralDateTime)}に
              </p>
              <p className="p-eimin">
                相済ませました
              </p>
              <br/>

              {/* TODO: 特定の葬家のみ挨拶 */}
              {funeralId === "36b85ef8-deda-4c84-ae19-5b1ad7b64241" || funeralId === "2d5b0d55-b010-4606-8759-f937f536907f" ?
                  <div>
                    <p className="p-eimin">喪主 {funeralInfo?.chiefMournerName}{funeralInfo?.relationship && `（${funeralInfo?.relationship}）`}</p>
                    <p className="p-eimin">
                      {funeralInfo.mournerMessage ?
                          <div className="gothic">
                            {funeralInfo.mournerMessage.split('\n').map((message, i) => <div
                                className="mournerMessage"
                                key={i}>{message}</div>)}
                          </div>
                          :
                          <>
                            ここに生前のご厚誼を感謝し<br/>謹んでご通知申し上げます<br/>
                            なお通夜葬儀式は下記のとおり相営みます
                          </>
                      }
                    </p>

                    <br/>
                  </div>
                  : <></>
              }

              <div className="bg-white m-4 p-5 funeral-info">
                <div className="row">
                  <div className="col-3 pl-0 pr-0">
                    <button type="button" className=" btn btn-secondary rounded-pill btn-sm fs-5 decreasedNameButton" disabled>故人</button>
                  </div>
                  <div className="col-9">
                    <div className="row pb-0 mb-0 decreasedNameRubyBottomLine">
                      <p className="col-12 mb-1 mt-1 d-flex align-items-center text-left"><div className="fs-6 text-funeral-info">{funeralInfo.decreasedNameRuby}</div></p>
                    </div>
                    <div className="row">
                      <p className="col-12 mb-1 mt-1 d-flex align-items-center text-left text-funeral-info">{funeralInfo.decreasedName?.replace(' ', '　')}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 pl-0 pr-0">
                    <button type="button" className=" btn btn-secondary rounded-pill btn-sm fs-5 decreasedNameButton" disabled>喪主</button>
                  </div>
                  <div className="col-9">
                    <div className="row pb-0 mb-0 decreasedNameRubyBottomLine">
                      <p className="col-12 mb-1 mt-1 d-flex align-items-center text-left"><div className="fs-6 text-funeral-info">{funeralInfo.chiefMournerNameRuby}</div></p>
                    </div>
                    <div className="row">
                      <p className="col-12 mb-1 mt-1 d-flex align-items-center text-left text-funeral-info">{funeralInfo.chiefMournerName}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <button type="button" className="btn btn-secondary rounded-pill btn-sm fs-5 col-3" disabled>没日</button>
                  <p className="col-9 mb-1 mt-1 d-flex align-items-center text-left text-funeral-info">{format.mourningEnd(funeralInfo.deathDay)}</p>
                </div>
              </div>
              <br/>
              <p className="p-eimin-action-description">以下から追悼のお気持ちを お伝えすることができます</p>
            </div>
            :
            <div>
              <p className="p-eimin">
                故 {funeralInfo?.decreasedName}{funeralInfo?.age &&`（${funeralInfo?.age}）`}は
              </p>
              <p className="p-eimin">
                {format.mourningEnd(funeralInfo.deathDay)}に永眠いたしました
              </p>
              <p className="p-eimin">
                ・・・・
              </p>
              <p className="p-eimin">喪主 {funeralInfo?.chiefMournerName}{funeralInfo?.relationship && `（${funeralInfo?.relationship}）`}</p>
              <p className="p-eimin">
              {funeralInfo.mournerMessage ?
                      <div className="gothic">
                        {funeralInfo.mournerMessage.split('\n').map((message, i) => <div className="mournerMessage" key={i}>{message}</div>)}
                      </div>
                      :
                      <>
                        ここに生前のご厚誼を感謝し<br/>謹んでご通知申し上げます<br />
                        なお通夜葬儀式は下記のとおり相営みます
                      </>
                    }
              </p>
              <br/>
              <div className="bg-white m-4 p-5 funeral-info">
                <div className="row">
                  <div className="col-3 pl-0 pr-0">
                    <button type="button" className=" btn btn-secondary rounded-pill btn-sm fs-5 decreasedNameButton" disabled>故人</button>
                  </div>
                    <div className="col-9">
                      <div className="row pb-0 mb-0 decreasedNameRubyBottomLine">
                        <p className="col-12 mb-1 mt-1 d-flex align-items-center text-left"><div className="fs-6 text-funeral-info">{funeralInfo.decreasedNameRuby}</div></p>
                      </div>
                      <div className="row">
                        <p className="col-12 mb-1 mt-1 d-flex align-items-center text-left text-funeral-info">{funeralInfo.decreasedName?.replace(' ', '　')} {funeralInfo?.age && `（${funeralInfo?.age}）`}</p>
                      </div>
                    </div>
                </div>
                <div className="row">
                  <div className="col-3 pl-0 pr-0">
                    <button type="button" className=" btn btn-secondary rounded-pill btn-sm fs-5 decreasedNameButton" disabled>喪主</button>
                  </div>
                    <div className="col-9">
                      <div className="row pb-0 mb-0 decreasedNameRubyBottomLine">
                        <p className="col-12 mb-1 mt-1 d-flex align-items-center text-left"><div className="fs-6 text-funeral-info">{funeralInfo.chiefMournerNameRuby}</div></p>
                      </div>
                      <div className="row">
                        <p className="col-12 mb-1 mt-1 d-flex align-items-center text-left text-funeral-info">{funeralInfo.chiefMournerName}{funeralInfo.relationship && `（${funeralInfo.relationship}）`}</p>
                      </div>
                    </div>
                </div>
                <div className="row">
                  <button type="button" className="btn btn-secondary rounded-pill btn-sm fs-5 col-3" disabled>通夜式</button>
                    {funeralInfo.vigilDateTime === "Invalid date" || funeralInfo.vigilDateTime === "" ?
                        <p className="col-9 mb-1 mt-1 d-flex align-items-center text-left text-funeral-info">執り行っておりません<br/>{funeralInfo.VigilMessage}</p>
                      :
                        <p className="col-9 mb-1 mt-1 d-flex align-items-center text-left text-funeral-info">{format.dateTimeFuneral(funeralInfo?.vigilDateTime)}<br/>{funeralInfo.VigilMessage}</p>
                    }
                </div>
                <div className="row">
                  <button type="button" className="btn btn-secondary rounded-pill btn-sm fs-5 col-3" disabled>葬儀式</button>
                  <p className="col-9 mb-1 mt-1 d-flex align-items-center text-left text-funeral-info">{format.dateTimeFuneral(funeralInfo?.funeralDateTime)}<br/>{funeralInfo.funeralMessage}</p>
                </div>
                <div className="row">
                  <button type="button" className="btn btn-secondary rounded-pill btn-sm fs-5 col-3" disabled>葬儀社名</button>
                  <p className="col-9 mb-1 mt-1 d-flex align-items-center text-left text-funeral-info">{funeralInfo.brandName}</p>
                </div>
                <div className="row">
                  <button type="button" className="btn btn-secondary rounded-pill btn-sm fs-5 col-3" disabled>会場名</button>
                  <p className="col-9 mb-1 mt-1 d-flex align-items-center text-left text-funeral-info">{funeralInfo.hallName}</p>
                </div>
                <div className="row">
                  <button type="button" className="btn btn-secondary rounded-pill btn-sm fs-5 col-3" disabled>会場住所</button>
                  <p className="col-9 mb-1 mt-1 d-flex align-items-center text-left text-funeral-info">{format.addressCustom(funeralInfo.hallAddress)}</p>
                </div>
                {!funeralInfo.funeralFormat || funeralInfo.funeralFormat === "" ?
                  <></>
                  :
                  <div className="row">
                    <button type="button" className="btn btn-secondary rounded-pill btn-sm fs-5 col-3" disabled>形式</button>
                    <p className="col-9 mb-1 mt-1 d-flex align-items-center text-left text-funeral-info">{funeralInfo.funeralFormat}</p>
                  </div>
                }
              </div>

              <br/>
              <p className="p-eimin-action-description">葬儀社からの案内<br/>会場参列できない方は 以下からオンラインで弔意を お伝えすることができます</p>
            </div>
            }
          </div>

          <div className="attendance-select" ref={giftBoxRef}>
            { attendanceMethods.length === 0 &&
                <div className="setNoItem bg-white">
                  <p className="setNoItemText fs-4 align-middle text-nowrap">選択肢がありません</p>
                </div>
            }
            { isInvoiceDisplay ?
              attendanceMethods.map((item, index) =>
                <div key={index} className="attendance-method" onClick={item.handle}>
                  <div className={"align-items-center pict py-2 " + item.class}>
                    <div>
                      <img src={"/images/" + item.pict} alt="" />
                    </div>
                    <div>
                      { item.isLabelCenter ?
                        <label className="invoiceLabelCenter">{item.label}</label>
                        :
                        <label>{item.label}</label>
                      }
                      {item?.labelButton && (
                        <div className="invoiceOnDisplay">
                          <button type="button" className="btn btn-secondary rounded-pill fs-6" disabled>{item.labelButton}</button>
                        </div>
                      )}
                      { item.isInvoiceOff ?
                          <></>
                        :
                        <div className="invoiceOnDisplay">
                          <button type="button" className="btn btn-secondary rounded-pill fs-6" disabled>{item.Invoice}</button>
                        </div>
                      }
                    </div>
                    <span className="chevron-right-thin"></span>
                  </div>
                </div>
              )
            :
            attendanceMethods.map((item, index) =>
                <div key={index} className="attendance-method" onClick={item.handle}>
                  <div className={"align-items-center pict py-2 " + item.class}>
                    <div>
                      <img src={"/images/" + item.pict} alt="" />
                    </div>
                    <div>
                      <label>{item.label}</label>
                    </div>
                    <span className="chevron-right-thin"></span>
                  </div>
                </div>
            )
            }
          </div>
          <br/>

          <div>
            {isEndedFuneral ?
            <></>
            :
            <div>
              <iframe src={iframeSrc} width="95%" height="400" frameBorder="0" scrolling="no" title="Google Map"/>
              <div className="hall-map-actions">
              <div className="hall-map-button" onClick={toGoogleMap}>
                <img src="/images/map-icon.png" alt=""/>
                <span>
                  地図アプリで開く<br />
                  Google Map
                </span>
              </div>

              <div className="hall-map-button" onClick={toCall}>
                <img src="/images/phone-icon.png" alt=""/>
                <span>
                  電話で問い合わせ<br />
                  {funeralInfo.hallPhoneNo}
                </span>
              </div>
            </div>
            <br/>

            <div className="mx-3 mb-5">
              <div className="bg-white position-relative funeral-announce-btn" onClick={() => setShowFuho(true)}>
              <span className="text-center fs-3 font-weight-bold">
                葬儀会社からのご案内はこちら
              </span>
                <span className="chevron-right-thin"></span>
              </div>
            </div>
            </div>
            }
          </div>

          {userInfo?.isChiefMourner &&  funeralInfo?.enableMoneyFlow && <ModalRecieveMoney/>}

          <Modal show={showFuho} onHide={() => setShowFuho(false)} centered={true} dialogClassName="fuho-modal-size" size="lg">
            <Modal.Header closeButton={true}><Modal.Title className="ms-auto">{funeralInfo.companyName} よりご案内</Modal.Title></Modal.Header>
            <Modal.Body className="fs-2">
              {funeralInfo.companyMessage}
            </Modal.Body>
          </Modal>
          <br/>

          <br/>
          {photoList.length > 0 ?
            <>
              <PhotoGallery photos={photoList} />
              <button className="btn btn-primary black-button show-album-button" onClick={toAlbum}>もっと見る</button>
            </>
            :
            <div className="group album-show-group">
              <div id="album-section">
                <img className="album-image" src="/images/album.jpg" alt="" />
                <div className="btn btn-primary black-button share-album-button position-relative" onClick={toAlbum}>
                  <span>思い出を共有する</span>
                  <img className="memory-share-icon" src="/images/memory-share-icon.png" alt=""/>
                </div>

              </div>
            </div>
          }
          <div id="hall-info">
            <div className="obituary-code">訃報No.{funeralInfo.funeralCode === "undefined" ? "" : funeralInfo.funeralCode}</div>
          </div>
   
          <Modal
            id="attendee-first-modal"
            show={firstModal}
            onHide={firstModalClose}
            centered={true}
            size="lg"
          >
            <Modal.Header
                closeButton={true}
                style={{ borderBottomWidth: "0", backgroundColor: "#FBFBFB" }}>
            </Modal.Header>
            <Modal.Body>
              <div className="first-modal-header">
                <div className="mincho first-modal-subtitle">弔意をカタチにできる場所</div>
                <div className="mincho first-modal-title">itowaで、できること</div>
              </div>
              <div className="first-modal-section">
                <h2 className="font-weight-bold">①訃報を伝達する</h2>
                <p>故人のお知り合いの方にLINEなどで訃報をすぐにお伝えすることができます。</p>
              </div>
              <div className="first-modal-section">
                <h2 className="font-weight-bold">②お気持ちを届ける</h2>
                <p>参列が難しい方は、訃報案内ページから香典や御供物などを選択肢、お気持ちを届けることができます。</p>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        : ""
      }

      {showFuneralMoney && <ModalFuneralMoney  />}
      <Footer haveKoudenList={isHaveKoudenList}/>
    </AttendeeLayout>
  );
};

const getTopPagePhoto = async (funeralId) => {
  const apiName = isPublicPhotoFuneral(funeralId) ? 'guest' : 'product';
  return await API.get(apiName, `/photos/${funeralId}/top`);
}

// 特定葬家のみ認証無しで写真を表示する
const isPublicPhotoFuneral = (funeralId) => {
  return funeralId === '36b85ef8-deda-4c84-ae19-5b1ad7b64241' || funeralId === '2d5b0d55-b010-4606-8759-f937f536907f';
}

const getDisplayItem = async (funeralId) => {
  const options = {
    queryStringParameters: { funeralId: funeralId }
  }

  return await API.get('guest', '/funerals/item/template', options);
}

export default Top;
