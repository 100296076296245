import { useState, useEffect } from 'react';
import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import { Row, Col, Button } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { useLoading } from 'components/Loading';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DataTable = ({labels, datasets}) => {
  return (
    <table className="table table-sm">
      <thead>
        <tr>
          <th>対象月日</th>
          {
            datasets.map((d, i) => 
              <th key={i} className="text-right">{d.label}</th>
            )
          }
        </tr>
      </thead>
      <tbody>
        {
          labels.map((label, i) => {
            return (
              <tr key={i}>
                <td>{label}</td>
                {
                  datasets.map((d, j) => <td key={i + ":" + j} className="text-right">{(parseInt(d.data[i], 10) || 0).toLocaleString()}</td>)
                }
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}

export const Statistics = (props) => {
  const loading = useLoading();
  const [salesMode, setSalesMode] = useState(0);
  const [attendeesMode, setAttendeesMode] = useState(0);
  const [salesDataSet, setSalesDataSet] = useState({
    gender: {
      labels: ['　', '　', '　', '　', '　', '　', '　', '　', '　', '　', '　', '　'],
      datasets: []
    },
    age: {
      labels: [],
      datasets: []
    },
    area: {
      labels: [],
      datasets: []
    },
    relation: {
      labels: [],
      datasets: []
    }
  });
  const [attendeesDataSet, setAttendeesDataSet] = useState({
    gender: {
      labels: ['　', '　', '　', '　', '　', '　', '　', '　', '　', '　', '　', '　'],
      datasets: []
    },
    age: {
      labels: [],
      datasets: []
    },
    area: {
      labels: [],
      datasets: []
    },
    relation: {
      labels: [],
      datasets: []
    }
  });

  const stackOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  // const handleReload = () => {
  //   window.location.reload();
  // }

  useEffect(() => {
    const colors = ['rgba(53, 162, 235, 0.5)', 'rgba(255, 99, 132, 0.5)', 'rgba(53, 162, 135, 0.5)'];
    const ageColors = [
      'rgba(255, 202, 191, 1)',
      'rgba(255, 255, 128, 1)',
      'rgba(191, 228, 255, 1)',
      'rgba(255, 202, 128, 1)',
      'rgba(119, 217, 168, 1)',
      'rgba(201, 172, 230, 1)',
    ];
    const fetch = async () => {
      loading(true);
      const response = await getStatistics(props.match.params.companyId);
      setSalesDataSet({
        gender: {
          labels: response.labels,
          datasets: response.dataset.sales.gender.map((e, i) => {
            e.backgroundColor = colors[i];
            return e;
          })
        },
        age: {
          labels: response.labels,
          datasets: response.dataset.sales.age.map((e, i) => {
            e.backgroundColor = ageColors[i];
            return e;
          })
        },
        area: {
          labels: response.labels,
          datasets: response.dataset.sales.area.map((e, i) => {
            e.backgroundColor = colors[i];
            return e;
          })
        },
        relation: {
          labels: response.labels,
          datasets: response.dataset.sales.relation.map((e, i) => {
            e.backgroundColor = colors[i];
            return e;
          })
        }
      });
      setAttendeesDataSet({
        gender: {
          labels: response.labels,
          datasets: response.dataset.attendees.gender.map((e, i) => {
            e.backgroundColor = colors[i];
            return e;
          })
        },
        age: {
          labels: response.labels,
          datasets: response.dataset.attendees.age.map((e, i) => {
            e.backgroundColor = ageColors[i];
            return e;
          })
        },
        area: {
          labels: response.labels,
          datasets: response.dataset.attendees.area.map((e, i) => {
            e.backgroundColor = colors[i];
            return e;
          })
        },
        relation: {
          labels: response.labels,
          datasets: response.dataset.attendees.relation.map((e, i) => {
            e.backgroundColor = colors[i];
            return e;
          })
        }
      });
      loading(false);
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  const salesTitle = ['売上（男女別）', '売上（年代別）', '売上（関係別）','売上（エリア別）']
  const attendeesTitle = ['参列者数（男女別）', '参列者数（年代別）', '参列者数（関係別）', '参列者数（エリア別）']

  return (
    <CommonLayout>
      <ContentHeader>
        データ集計
      </ContentHeader>

      <Row>
        <Col md="6">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{salesTitle[salesMode]}</h3>
              <div className="card-tools">
                <Button className="btn btn-sm" variant={salesMode === 0 ? "primary" : "secondary"} onClick={() => setSalesMode(0)}>男女別</Button>&nbsp;
                <Button className="btn btn-sm" variant={salesMode === 1 ? "primary" : "secondary"} onClick={() => setSalesMode(1)}>年代別</Button>&nbsp;
                <Button className="btn btn-sm" variant={salesMode === 2 ? "primary" : "secondary"} onClick={() => setSalesMode(2)}>関係別</Button>&nbsp;
                <Button className="btn btn-sm" variant={salesMode === 3 ? "primary" : "secondary"} onClick={() => setSalesMode(3)}>エリア別</Button>&nbsp;
              </div>
            </div>
            <div className="card-body">
              {salesMode === 0 &&
                <>
                <Bar options={stackOptions} data={salesDataSet.gender} />
                <DataTable labels={salesDataSet.gender.labels} datasets={salesDataSet.gender.datasets} />
                </>
              }
              {salesMode === 1 &&
                <>
                <Bar options={stackOptions} data={salesDataSet.age} />
                <DataTable labels={salesDataSet.age.labels} datasets={salesDataSet.age.datasets} />
                </>
              }
              {salesMode === 2 &&
                <>
                <Bar options={stackOptions} data={salesDataSet.relation} />
                <DataTable labels={salesDataSet.relation.labels} datasets={salesDataSet.relation.datasets} />
                </>
              }
              {salesMode === 3 &&
                <>
                <Bar options={stackOptions} data={salesDataSet.area} />
                <DataTable labels={salesDataSet.area.labels} datasets={salesDataSet.area.datasets} />
                </>
              }
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{attendeesTitle[attendeesMode]}</h3>
              <div className="card-tools">
                <Button className="btn btn-sm" variant={attendeesMode === 0 ? "primary" : "secondary"} onClick={() => setAttendeesMode(0)}>男女別</Button>&nbsp;
                <Button className="btn btn-sm" variant={attendeesMode === 1 ? "primary" : "secondary"} onClick={() => setAttendeesMode(1)}>年代別</Button>&nbsp;
                <Button className="btn btn-sm" variant={attendeesMode === 2 ? "primary" : "secondary"} onClick={() => setAttendeesMode(2)}>関係別</Button>&nbsp;
                <Button className="btn btn-sm" variant={attendeesMode === 3 ? "primary" : "secondary"} onClick={() => setAttendeesMode(3)}>エリア別</Button>&nbsp;
              </div>
            </div>
            <div className="card-body">
              {attendeesMode === 0 &&
                <>
                  <Bar options={stackOptions} data={attendeesDataSet.gender} />
                <DataTable labels={attendeesDataSet.gender.labels} datasets={attendeesDataSet.gender.datasets} />
                </>
              }
              {attendeesMode === 1 &&
                <>
                <Bar options={stackOptions} data={attendeesDataSet.age} />
                <DataTable labels={attendeesDataSet.age.labels} datasets={attendeesDataSet.age.datasets} />
                </>
              }
              {attendeesMode === 2 &&
                <>
                <Bar options={stackOptions} data={attendeesDataSet.relation} />
                <DataTable labels={attendeesDataSet.relation.labels} datasets={attendeesDataSet.relation.datasets} />
                </>
              }
              {attendeesMode === 3 &&
                <>
                <Bar options={stackOptions} data={attendeesDataSet.area} />
                <DataTable labels={attendeesDataSet.area.labels} datasets={attendeesDataSet.area.datasets} />
                </>
              }
            </div>
          </div>
        </Col>
      </Row>

      {/*TODO: 仕様が未確定なので一旦コメントアウトしておく*/}
      {/*<ContentHeader>*/}
      {/*  アクセスデータ集計*/}
      {/*</ContentHeader>*/}

      {/*<Row>*/}
      {/*  <Col>*/}
      {/*    <div className="card">*/}
      {/*      <div className="card-body">*/}
      {/*      <Button className="float-right mr-1 mb-2" onClick={handleReload}>更新</Button>*/}
      {/*        <table class="table table-bordered border-primary border-analytics">*/}
      {/*          <tbody>*/}
      {/*            <tr>*/}
      {/*              <td className="table-secondary">　　</td>*/}
      {/*              <td className="table-secondary fs-4">アクセス数<br/>（PV数）</td>*/}
      {/*              <td className="table-secondary fs-4">ユーザー数<br/>（UU数）</td>*/}
      {/*              <td className="table-secondary fs-4">決済数<br/>（CV数）</td>*/}
      {/*            </tr>*/}
      {/*            <tr>*/}
      {/*              <td className="fs-4">通夜まで</td>*/}
      {/*              <td className="fs-4">100</td>*/}
      {/*              <td className="fs-4">100</td>*/}
      {/*              <td className="fs-4">100</td>*/}
      {/*            </tr>*/}
      {/*            <tr>*/}
      {/*              <td className="fs-4">葬儀まで</td>*/}
      {/*              <td className="fs-4">100</td>*/}
      {/*              <td className="fs-4">100</td>*/}
      {/*              <td className="fs-4">100</td>*/}
      {/*            </tr>*/}
      {/*            <tr>*/}
      {/*              <td class="fs-4">葬儀後</td>*/}
      {/*              <td class="fs-4">100</td>*/}
      {/*              <td class="fs-4">0</td>*/}
      {/*              <td class="fs-4">100</td>*/}
      {/*            </tr>*/}
      {/*            <tr>*/}
      {/*              <td class="table-danger fs-4">合計</td>*/}
      {/*              <td class="table-danger fs-4">100</td>*/}
      {/*              <td class="table-danger fs-4">0</td>*/}
      {/*              <td class="table-danger fs-4">100</td>*/}
      {/*            </tr>*/}
      {/*          </tbody>*/}
      {/*        </table>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </CommonLayout>
  )
}

const getStatistics = async (companyId) => {
  const options = {
    queryStringParameters : {
      companyId: companyId
    }
  }
  return await API.get('product', '/statistics', options);
}

export default Statistics;
