import { useRouteMatch } from 'react-router-dom';
import { FUNERALS_WEB_URL, COGNITO_DOMAIN } from '../constants';
import { awsConfig } from 'aws-config';
import LinePreRegisterButton from 'components/LinePreRegisterButton';


export const PreRegistration = (props) => {
  const match = useRouteMatch("/:companyId");

  const handleLineLogin = () => {
    localStorage.setItem('companyId', match.params.companyId);
    window.location.href = `https://${COGNITO_DOMAIN}.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?identity_provider=LINE&response_type=code&client_id=${awsConfig.UserPoolClientId}&scope=openid%20email%profile&redirect_uri=${FUNERALS_WEB_URL}/pre-registration/submit`;
  }

  return (
    <div id="auth-main">
      <div id="auth-common">
        <img className="logo" src="/images/itowa-logo-square.jpg" alt="logo"></img>
        <h1 className="">申請画面</h1>
        <div>
          <div className="buttons">
            <LinePreRegisterButton handleLineLogin={handleLineLogin}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreRegistration;
