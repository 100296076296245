import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

function ModalFuneralTimeInput({ editedItem, onSave, onCancel, show }) {
  const [itemName, setItemName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setItemName(editedItem?.Name || '')
  }, [editedItem])

  const handleSave = () => {
    if (!itemName.trim()) {
      setError('項目名は必須です');
    } else {
      onSave(itemName)
      setItemName('')
    }
  }

  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>項目名</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          maxLength={10}
          className="form-control"
          type="text"
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
        />
        {error && <div className="modal-error-message">{error}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>閉じる</Button>
        <Button variant="success" onClick={handleSave}>保存</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalFuneralTimeInput;