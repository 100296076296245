import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { API } from 'aws-amplify';

import { Img } from 'components/Img';
import * as Constants from '../../constants';

import { checkCharacterError } from 'utils/helper';

import styled from 'styled-components';

const Wrapper = styled.div`
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  // font-family: source-han-serif-japanese, serif;
  font-weight: 500;
  padding: 1rem 2rem;
`
const Title = styled.div`
  writing-mode: vertical-rl;
  margin: 0 auto 3rem auto;
  font-size: 19px;
  font-weight: 500;
`

const Label = styled.div`
  font-size: 12px;
`

const InputButton = styled.button`
  background: #5D5D5D 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  opacity: 1;
  border: 0px;
  width: 100px;
  height: 22px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

const Description = styled.div`
  font-size: 10px;
  transform: scale(0.7);
  text-align: right;
  width: 50%;
  white-space: nowrap;
`
const ExternalLink = styled.a`
  font-size: 10px;
  transform: scale(0.7);
`

export const CartItem = (props) => {
  const { item, onChange, onDelete, onEdit } = props;

  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState([]);
  const [messageError, setMessageError] = useState([]);

  useEffect(() => {
    setName(item.label);
    if(item.message) {
      setMessage(item.message);
    } else if(item.itemType === "money"){
      setMessage("謹んでお悔やみ申し上げます。");
    }
  }, [item, modalShow])

  const handleChange = async () => {
    setLoading(true);
    try {
      if (item.itemType === "sagawa_telegram") {
        let names = [];
        if (name?.length > 30) {
          for (let i = 0; i < name.length; i += 30) {
              names.push(name.substring(i, i + 30));
          }
        } else {
          names.push(name);
        }

        let messages = [];
        if (message?.length > 30) {
          for (let i = 0; i < message.length; i += 30) {
            messages.push(message.substring(i, i + 30));
          }
        } else {
          messages.push(message);
        }

        const formDataName = new FormData();
        if (names?.length > 0) {
          names?.forEach((value, index) => {
            formDataName.append(`text${index + 1}`, value);
            formDataName.append(`category${index + 1}`, `name${index + 1}`);
          });
        }

        const formDataMessage = new FormData();
        if (messages?.length > 0) {
          messages?.forEach((value, index) => {
            formDataMessage.append(`text${index + 1}`, value);
            formDataMessage.append(`category${index + 1}`, `message${index + 1}`);
          });
        }

        const [checkName, checkMessage] = await Promise.all([
          checkCharacter(formDataName),
          checkCharacter(formDataMessage)
        ]);

        if (checkName.toLocaleString() !== 'true') {
          setNameError(checkCharacterError(checkName.toLocaleString()));
        }

        if (checkMessage.toLocaleString() !== 'true') {
          setMessageError(checkCharacterError(checkMessage.toLocaleString()));
        }

        if(checkName.toLocaleString() === 'true'  && checkMessage.toLocaleString() === 'true') {
          if (onChange) {
            onChange(name, message);
          }
          setModalShow(false);
        }
      } else {
        if(onChange) {
          onChange(name, message);
        }
        setModalShow(false);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  const handleDelete = () => {
    if(onDelete) {
      onDelete();
    }
  }

  const handleEdit = () => {
    if(onEdit) {
      onEdit();
    }
  }

  const handleCloseBtn = () => {
    if(nameError || messageError) {
      setNameError(null);
      setMessageError(null);
      setName(null);
      setMessage(null);
    }
    setModalShow(false)
  }

  const preventEnterKey = (e) => {
    if(item.itemType === "sagawa_telegram") {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    }
  };

  let title = "";
  if (item.itemType === "money") title = "香典申込";
  if (item.itemType === "telegram") title = "弔文";
  if (item.itemType === "flower") title = "お供花";
  if (item.itemType === "things") title = "お供物";
  if (item.itemType === "sagawa_telegram") title = "弔電";

  const price = parseInt(item.price, 10).toLocaleString();

  switch (item.itemType) {
    case 'gift':
      return (
        <div className="cart-item">
          <div className="item-body">
            <div className="frame">
              <Img className="photo" src={ item.imagePath }></Img>
            </div>
            <div className="item-info">
              <div className="item-name-code">
                <div>商品名</div><div className="item-name">{ item.itemName }</div>
              </div>
              <br/>
              <div className="price cart-price">{item.userName && <>{item.userName} 様<br/></>}{item.priceCategory === Constants.OTHER_ITEMS 
                ? "供花供物・その他香典" : parseInt(item.priceCategory, 10).toLocaleString() + '円分の返礼品'}
            </div>
              <div className="buttons">
                <button type="button" className="btn btn-primary edit-button" onClick={ handleEdit }>編集</button>
                {
                  item.userName && <button type="button" className="btn btn-primary delete-button" onClick={ handleDelete }>削除</button>
                }
              </div>
            </div>
          </div>
          <hr/>
          <div className="total">
            <div className="total-label">小計</div>
            <div>
              { item.totalPrice ? parseInt(item.totalPrice, 10).toLocaleString() : price }
              <span className="yen">円</span>
            </div>
          </div>
        </div>
      )
    default :
      return (
        <div className="cart-item">
          <div className="item-body">
            <div className="frame">
              <Img className="photo" src={item.imagePath}></Img>
            </div>
            <div className="item-info">
              <div className="item-name-code">
                <div className="item-name">{item.itemType === "money" ? "香典申込" : item.itemName}</div>
                { item.itemType === "money" ? "" :
                  <div className="item-code">商品コード:{item.itemId}</div>
                }
              </div>
              <div className="item-label-message">
                <div className="item-label">お名前：{item.label || ""}</div>
                <div className="item-message">
                  {item.itemType === "money" || item.itemType === "telegram" || item.itemType === "sagawa_telegram" ?
                    <>
                      メッセージ：{item.message || ""}
                    </>
                    : "　"
                  }
                </div>
              </div>
              <br/>
              <div className="price">{price}<span className="yen">円</span></div>
              <div className="buttons">
                <button type="button" className="btn btn-primary edit-button" onClick={() => setModalShow(true)}>お名前入力</button>
                <button type="button" className="btn btn-primary delete-button" onClick={handleDelete}>削除</button>
              </div>
            </div>
          </div>
          <hr/>
          <div className="total">
            {item.itemType === "money" ? (
              <div className="text-right">香典申込金額 {price}<span className="yen">円</span></div>
            ) : <div className="text-right">小計 {price}<span className="yen">円</span></div>}
          </div>
          {
            item.itemType === "money" &&
            // 香典金額がカンマ付きの文字列で渡されるため、カンマを削除して数値に変換している
            <>
            <div className="text-right">システム利用料 {KoudenCommission(parseInt(price.replace(/,/g, "")))}円</div>
            <div className="text-right">メッセージ添付料 {Constants.MESSAGE_FEE}円</div>
            </>
          }
          <Modal show={modalShow} onHide={handleCloseBtn} backdrop="static" centered={true}>
            <Modal.Header closeButton style={{borderBottomWidth: "0px", backgroundColor: "#FBFBFB"}}>
            </Modal.Header>
            <Modal.Body style={{backgroundColor: "#FBFBFB"}}>
              <Wrapper>
                <Title>{title}</Title>
                <div className="form-group">
                  <div style={{display:"flex", justifyContent:"space-between"}}>
                    <Label className="control-label">お名前</Label>
                    <Description>
                      名札の書き方については<ExternalLink href={Constants.EXAMPLE_NAME_URL} target="blank">こちら</ExternalLink>
                    </Description>
                  </div>
                  <textarea placeholder={item.itemType === "telegram"  || item.itemType === "sagawa_telegram"? "90文字以内で入力してください。": ""} maxlength={item.itemType === "telegram"  || item.itemType === "sagawa_telegram"? "90":"140"} className={`form-control ${nameError?.length > 0 ? 'is-invalid' : ''}`} value={name} onChange={(e) => {
                    setNameError([]);
                    setName(e.target.value);
                  }}
                  onKeyDown={preventEnterKey}
               ></textarea>
                  {nameError?.map((err) => (
                    <div class="invalid-feedback">{err}</div>
                  ))}
                </div>
                {
                  (item.itemType === "money" || item.itemType === "telegram"  || item.itemType === "sagawa_telegram") &&
                  <div className="form-group">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      {item.itemType === "money" ? (
                        <Label className="control-label">メッセージを100文字以内で入力してください </Label>
                      ) : (
                      <>
                      <Label className="control-label">メッセージ</Label>
                      <Description>
                        弔文の文章例を参考にされたい方は<ExternalLink href={Constants.EXAMPLE_CHODEN_URL} target="blank">こちら</ExternalLink>
                      </Description></>
                      )}
                    </div>
                    <textarea placeholder={item.itemType === "money" ? "100文字以内で入力してください。" : "300文字以内で入力してください。"} maxlength={item.itemType === "money" ? "100" :"300"} className={`form-control ${messageError?.length > 0 ? 'is-invalid' : ''}`} rows={12} value={message} onChange={(e) => {
                      setMessageError([]);
                      setMessage(e.target.value);
                    }}
                    onKeyDown={preventEnterKey}></textarea>
                    {messageError?.map((err) => (
                      <div class="invalid-feedback">{err}</div>
                    ))}
                  </div>
                }
                <div className="form-group">
                  <InputButton type="button" className="btn btn-primary" onClick={handleChange} disabled={loading}>確定する</InputButton>
                </div>
              </Wrapper>
            </Modal.Body>
          </Modal>
        </div>
      )
  }
}

export const checkCharacter = async (formData) => {
  formData.append("targetUrl", `${Constants.KEICHO_ENPOINT_URL}/CharacterCheck/v1/characterCheck.php`);
  const options = {
    body: formData
  }
  return await API.post('guest', '/sagawa-api-proxy', options);
}

// 香典手数料を取得する
const KoudenCommission = (price) => {
  switch (price) {
    case 3000:
      return 330;
    case 5000:
      return 495;
    case 10000:
      return 891;
    case 20000:
      return 1650;
    case 30000:
      return 2200;
    case 50000:
      return 3300;
    case 70000:
      return 4400;
    case 100000:
      return 5500;
    default:
      return 0;
  }
}

export default CartItem;