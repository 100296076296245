import { useState, useContext, useEffect } from 'react';
import EventListener from 'react-event-listener';
import { Alert } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay-ts';

import Header from './Header';
import Footer from './Footer';
import { AuthContext, MessageContext, LoadingContext } from '../Contexts';
import * as Constants from '../../constants';
import SideMenuBar from './SideMenuBar';
import AdminMenu from 'pages/admin/Menu';
import UndertakerMenu from 'pages/undertaker/Menu';

import '../../css/Undertaker.scss';
import ModalConfirm from './ModalConfirm';
import ModalConfirmDelete from './ModalConfirmDelete';

export const CommonLayout = (props) => {
  const headerHeight = 57;
  const footerHeight = 57;
  const { appendClassName } = props;
  const [height, setHeight] = useState(window.innerHeight - headerHeight - footerHeight);
  const [menuHeight, setMenuHeight] = useState(window.innerHeight);
  const { userInfo } = useContext(AuthContext);
  const { alert, setAlert } = useContext(MessageContext);
  const { loading } = useContext(LoadingContext);

  const handleResize = (e) => {
    setHeight(window.innerHeight - headerHeight - footerHeight);
    setMenuHeight(document.getElementById('content-main').offsetHeight + headerHeight + footerHeight);
  }

  useEffect(() => {
    setMenuHeight(document.getElementById('content-main').offsetHeight + headerHeight + footerHeight);
  }, [])

  useEffect(() => {
    if (alert.message && alert.variant !== "danger") {
      setTimeout(() => setAlert({}), 3000);
    }
  }, [alert, setAlert]);

  const isAdmin = document.domain === Constants.ADMIN_DOMAIN;

  const user = isAdmin ? userInfo.admin : userInfo.undertaker;
  const menu = isAdmin
    ? (userInfo.admin.Role === "manager" ? AdminMenu : (userInfo.admin.Role === "sagawa" ? AdminMenu.filter((m) => m.sagawa === true) : AdminMenu.filter((m) => m.call === true)))
    : (userInfo.undertaker.Manager ? UndertakerMenu(userInfo.undertaker.CompanyId) : UndertakerMenu(userInfo.undertaker.CompanyId).filter((m) => m.staff === true));

  return (
    <div id="common-root">
      <EventListener target="window" onResize={(e) => handleResize(e)} />
      <SideMenuBar menu={menu} height={menuHeight} />
      <LoadingOverlay active={loading} spinner text='Loading...'>
        <Header user={user} email={userInfo.email} />
        <div id="content-main" className={"content-wrapper content " + appendClassName} style={{ minHeight: height + 'px' }}>
          <div className="pt-3" />
          <section className="content">
            {alert.message ? <Alert variant={alert.variant} onClose={() => setAlert({})} dismissible>{alert.message}</Alert> : ""}
            {props.children}
          </section>
        </div>
        <Footer />
        <div id="sidebar-overlay" data-widget="pushmenu"></div>
      </LoadingOverlay>
      <ModalConfirm />
      <ModalConfirmDelete />
    </div>
  );
}

export default CommonLayout;