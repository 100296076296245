export const MENU = [
  {
    icon: 'fas fa-tachometer-alt',
    name: 'ダッシュボード',
    path: '/main'
  },
  {
    icon: 'fas fa-users',
    name: 'ユーザー管理',
    path: '/users'
  },
  {
    icon: 'fas fa-building',
    name: '葬儀社管理',
    path: '/companies'
  },
  {
    icon: 'fas fa-building',
    name: '商品管理',
    path: '/items',
    sagawa: true
  },
  {
    icon:'fas fa-gift',
    name:'返礼品管理',
    children: [
      {
        name:'返礼品一覧',
        path:'/gifts/list',
      },
      {
        name:'返礼品新規登録',
        path:'/gifts/new',
      },
    ]
  },
  {
    icon: 'far fa-clock',
    name: '操作ログ',
    path: '/accesslogs'
  },
  {
    icon: 'fas fa-chart-bar',
    name: 'データ集計',
    path: '/statistics'
  },
  {
    icon: 'fas fa-chart-bar',
    name: '月別累計売上高',
    path: '/salesscore'
  },
  {
    icon: 'fas fa-scroll',
    name: '訃報検索',
    path: '/obituary/search',
    call: true
  },
  {
    icon: 'fas fa-file-csv',
    name: 'CSVダウンロード',
    path: '/payment/csv/download',
    call: true
  }
];

export default MENU;