import 'moment/locale/ja';
import moment from 'moment';
import * as Constants from '../constants';
import { prefCodeToName } from 'components/CustomForms/Prefecture';

export const dateTime = (datetime) => {
  const dt = moment(datetime);
  if (dt.format("m") === "0") {
    return dt.format("YYYY年M月D日 H時");
  } else {
    return dt.format("YYYY年M月D日 H時m分");
  }
}

export const dateTimeFuneral = (datetime) => {
  if(!datetime){
    return;
  }
  const dt = moment(datetime);
  return dt.format("M月D日（ddd）HH:mm～");
}

export const today = (datetime) => {
  const dt = moment(datetime);
  return dt.format("YYYY年M月D日 H時mm分ss秒");
}

export const mourningEnd = (datetime) => {
  const dt = moment(datetime);
  if (dt.format("m") === "0") {
    return dt.format("YYYY年M月D日");
  } else {
    return dt.format("YYYY年M月D日");
  }
}

export const date = (date) => {
  return moment(date).format("YYYY年M月D日");
}

export const deathDateFormat = (date) => {
  return moment(date).format("YYYY/M/D");
}

export const address = (address) => {
  return address ? prefCodeToName(address.Prefecture) + address.Address1 + address.Address2 : "";
}

export const address1 = (address) => {
  return address ? prefCodeToName(address.Prefecture) + address.Address1 : "";
}

export const addressReception = (address) => {
  return address ? address?.Prefecture + address?.City + address?.Town + address?.Address1 : "";
}

export const gender = (symbol) => {
  const genderName = Constants.GENDER[symbol];
  return genderName || "その他"
}

export const relations = (symbol) => {
  const relationName = Constants.RELATIONS[symbol];
  return relationName || "その他"
}

export const customAddress1 = (address) => {
  if (!address) return "";
  const address1 = address?.Address1?.replace(/（([^）]+)）/g, '');
  return address1;
}

export const townCustom = (address) => {
  if (!address) return "";
  const addressCustom = address.replace(/（([^）]+)）/g, '');
  return addressCustom;
}

export const addressCustom = (address) => {
  if (!address) return "";
  const address1 = address?.Address1?.replace(/（([^）]+)）/g, '');
  const prefecture = prefCodeToName(address.Prefecture);
  return prefecture + address1 + address.Address2 || "";
}