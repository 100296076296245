import { useState, useEffect, useContext } from "react";
import AttendeeLayout from 'components/AttendeeLayout';
import { PhotoGallery } from "components/PhotoGallery";
import { API } from "aws-amplify";
import * as Constants from '../../constants';
import { saveAs } from 'file-saver';
import ContentHeader from "components/ContentHeader";
import { getBlob } from 'utils/request';
import { AttendanceContext } from "components/Contexts";
import { useLoading } from 'components/Loading';

export const PhotoSelect = (props) => {
  const funeralId = props.match.params.funeralId;
  const { funeralInfo } = useContext(AttendanceContext);

  const [photoList, setPhotoList] = useState([]);
  const [selectMode, setSelectMode] = useState(false);

  const loading = useLoading();

  useEffect(() => {
    const fetch = async () => {
      const response = await getSelectPhotos(funeralId);
      setPhotoList(response);
    };
    fetch();
    // eslint-disable-next-line
  },[]);

  const handleSelect = (selected) => {
    const newPhotoList = photoList.map((photo, i) => {
      if(photo.email === selected.email && photo.index === selected.index) {
        return selected;
      } else {
        return photo;
      }
    });

    setPhotoList(newPhotoList);
  }

  const handleUpdate = async () => {
    const updateParams = photoList.filter(photo => photo.updated === true)
                                  .map(photo => {
                                    return {
                                      email: photo.email,
                                      index: photo.index,
                                      published: photo.published
                                    }
                                  });
    try {
      loading(true);
      await updateSelectPhotos(funeralId, updateParams);
    } finally {
      loading(false);
      setSelectMode(false);
    }
  }

  const handleDownload = async () => {
    if(photoList.length > 0) {
      const response = await downloadPhotos(funeralId);
      if(response.filename) {
        const blob = await getBlob(Constants.IMAGE_BUCKET_URL + response.filename, { method: 'GET' });
        saveAs(blob, `${funeralInfo.familyName}家投稿写真.zip`);
      }
    }
  }

  return (
    <AttendeeLayout adminPage={true}>
      <div id="photo-select">
        <div className="info">
          <ContentHeader>
            アルバム管理
          </ContentHeader>
          {
            selectMode ?
              "公開する写真をタップしてください。黒丸がついた写真を公開します。"
            :
              "写真をタップするとメッセージを閲覧できます。"
          }
        </div>
        {
          selectMode ?
          <div className="buttons">
            <button type="button" className="btn btn-primary publish-button mincho" onClick={() => handleUpdate()}>選択した写真を公開</button>
            &nbsp;
            <button type="button" className="btn btn-primary white-button mincho" onClick={() => setSelectMode(false)}>キャンセル</button>
          </div>
          :
          <div className="buttons">
            <button type="button" className="btn btn-primary white-button mincho" onClick={() => setSelectMode(true)}>写真を選択する</button>
            &nbsp;
            <button type="button" className="btn btn-primary white-button mincho" onClick={() => handleDownload()}>ダウンロード</button>
          </div>
        }
        <PhotoGallery photos={photoList} append={false} selectMode={selectMode} showName={true} onSelect={handleSelect}/>
      </div>
    </AttendeeLayout>

  )
}

const getSelectPhotos = async (funeralId) => {
  return API.get('product', `/photos/${funeralId}/uploaded`)
}

const updateSelectPhotos = async (funeralId, updateList) => {
  const options = {
    body : updateList
  }
  return API.put('product', `/photos/${funeralId}/uploaded`, options);
}

const downloadPhotos = async (funeralId) => {
  return API.get('product', `/photos/${funeralId}/download`)
}

export default PhotoSelect;