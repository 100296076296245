import React from 'react';
import { API } from 'aws-amplify'
import { useHistory, Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Pagination from 'components/Pagination';

export const FuneralHistoryList = (props) => {
  const PER_PAGE = 10;
  const history = useHistory();

  const [funeralList, setFuneralList] = React.useState([]);
  const [pagenatedList, setPagenatedList] = React.useState([]);
  const [userName, setUserName] = React.useState("");
  const userId = props.match.params.userId;

  React.useEffect(() => {
    const fetch = async () => {
      const result = await getFuneralList(props.match.params.companyId, userId);
      const resultList = result.funeralList.sort((a, b) => a.Id < b.Id ? -1 : 1);
      setFuneralList(result.funeralList);
      setPagenatedList(resultList.slice(0, PER_PAGE));
      const user = await getUndertakerName(props.match.params.companyId, userId);
      setUserName(user.UserName);
    }
    fetch()
    // eslint-disable-next-line
  }, []);

  return (
    <CommonLayout>
      <ContentHeader>
        {`${userName} 葬儀一覧`}
      </ContentHeader>
      <Row>
        <Col md="9">
          <div className="card">
            <div className="card-body p-0">
              <table className="table">
                <thead>
                  <tr>
                    <th>葬儀日時</th>
                    <th>会場名</th>
                    <th>葬家名</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    pagenatedList.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td>{row.FuneralDateTime}</td>
                          <td>{row.HallName}</td>
                          <td>{row.FamilyName} 家</td>
                          <td><Link to={userId + "/" + row.FuneralId + "/order"}>発注リスト</Link></td>
                          <td><Link to={userId + "/" + row.FuneralId}>参列者情報</Link></td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <Button variant="success" className="float-left" onClick={() => history.goBack()}>戻る</Button>
              <Pagination per={PER_PAGE} list={funeralList} onChange={(list) => setPagenatedList(list)} />
            </div>
          </div>
        </Col>
      </Row>
    </CommonLayout>
  );
};

const getFuneralList = async (companyId, userId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
      undertakerId: userId
    }
  };

  return await API.get('product', '/funerals', options);
}

const getUndertakerName = async (companyId, userId) => {
  const options = {
    queryStringParameters : {
      companyId: companyId
    }
  }
  return await API.get('product', '/users/undertaker/' + userId, options);
}

export default FuneralHistoryList;