import React, { useRef } from 'react';
import { API } from 'aws-amplify'
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Pagination from 'components/Pagination';
import { prefCodeToName } from 'components/CustomForms/Prefecture';
import * as format from 'utils/format';

export const HallList = (props) => {
  const PER_PAGE = 10;
  const match = useRouteMatch("/:companyId");
  const companyId = match.params.companyId;

  const [hallList, setHallList] = React.useState([]);
  const [pagenatedList, setPagenatedList] = React.useState([]);
  const history = useHistory();
  const searchKey = useRef("");
  
  const search = async () => {
    const result = await getHallList(companyId, searchKey.current.value);
    const resultList = result.hallList.sort((a, b) => a.Id < b.Id ? -1 : 1);
    setHallList(result.hallList);
    setPagenatedList(resultList.slice(0, PER_PAGE));
  }
  
  const create = () => {
    history.push("halls/new");
  }
  
  return (
    <CommonLayout>
      <ContentHeader>
        葬儀会場一覧
      </ContentHeader>
      
      <div className="form-group">
        <Row>
          <Col>
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">検索条件</h3>
              </div>
              <div className="card-body">
                <label className="control-label">会場名</label>
                <input type="text" className="form-control" ref={searchKey} placeholder="会場名"/>
              </div>
              <div className="card-footer">
                <Button className="float-right" onClick={() => search()}>検索</Button>
                {' '}
                <Button variant="success" className="float-left" onClick={() => create()}>新規作成</Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
  
      <Row>
        <Col>
          <div className="card">
            <div className="card-body p-0">
              <table className="table">
                <thead>
                  <tr>
                    <th>会場名</th>
                    <th>住所</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    pagenatedList.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td><Link to={"halls/" + row.HallId}>{row.HallName}</Link></td>
                          <td>{prefCodeToName(row.Address?.Prefecture) + format.customAddress1(row.Address)}</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <Pagination per={PER_PAGE} list={hallList} onChange={(list) => setPagenatedList(list)} />
            </div>
          </div>
        </Col>
      </Row>
    </CommonLayout>
  );
};
  
const getHallList = async (companyId, keyword) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
      keyword: keyword
    }
  };

  return await API.get('product', '/halls', options);
}
  
export default HallList;