import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import '../css/Common.scss';
import { Auth } from 'aws-amplify';

export const PasswordReset = (props) => {
  const history = useHistory();
  const [confirmed, setConfirmed] = useState(false); 
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");

  const handleSend = async (e) => {
    try {
      e.preventDefault();
      await Auth.forgotPassword(email);
      setConfirmed(true);
    } catch (err) {
      
    }
  }

  const handleUpdate = async (e) => {
    try {
      e.preventDefault();
      await Auth.forgotPasswordSubmit(email, code, password);
      alert("パスワードを更新しました");
      history.push("login");
    } catch (err) {
      
    }
  }

  return (
    <div id="auth-main">
      <div id="auth-common">
        <img className="logo" src="/images/itowa-logo-square.jpg" alt="logo"></img>
        <div className="title">パスワードの再設定</div>
        {
          confirmed === false ?
          <form onSubmit={handleSend}>
            <div id="identity">
              <div className="form-group">
                <label className="control-label">Eメール</label>
                <div className="input-group">
                  <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}></input>
                </div>
              </div>
            </div>

            <div className="buttons">
              <button type="submit" className="btn btn-primary login-button" onClick={handleSend}>送る</button>
            </div>
          </form>
          :
          <form>
            <div id="identity">
              <div className="form-group">
                <label className="control-label">認証コード</label>
                <div className="input-group">
                  <input type="text" className="form-control" value={code} onChange={(e) => setCode(e.target.value)}></input>
                </div>
              </div>
              <div className="form-group">
                <label className="control-label">新しいパスワード</label>
                <div className="input-group">
                  <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                </div>
              </div>
            </div>

            <div className="buttons">
              <button type="button" className="btn btn-primary login-button" onClick={handleUpdate}>更新</button>
            </div>
          </form>
        }
      </div>
      <div className="regist">
        <Link to="login">ログイン</Link>
      </div>
    </div>
  )
}

export default PasswordReset;