import LPLayout from 'components/LPLayout';

export const Top = () => {
  return (
    <LPLayout>
      Bloom ランディングページ
    </LPLayout>
  );
};

export default Top;