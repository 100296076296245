import React, { useState, useEffect, useContext } from 'react';
import { API } from 'aws-amplify';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import AttendeeLayout from 'components/AttendeeLayout';
import { PhotoGallery } from 'components/PhotoGallery';
import { AuthContext } from 'components/Contexts';
import ConfirmReOrder from 'components/ConfirmReOrder';

export const AttendanceComplete = (props) => {
  const [photoList, setPhotoList] = useState([]);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [showBtnDownloadSagawa, setShowBtnDownloadSagawa] = useState(false);
  const [showBtnDownloadItowa, setShowBtnDownloadItowa] = useState(false);

  const { location } = props;
  const queryString = new URLSearchParams(location.search);

  const { userInfo } = useContext(AuthContext);
  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;
  const email = queryString.get("email");
  const orderId = queryString.get("orderId");
  const isOrderOnly = queryString.get("isOrderOnly") === "true";
  const prefix = "/" + match.params.funeralId;
  const history = useHistory();

  useEffect(() => {
    const fetch = async () => {
      const response = await getTopPagePhoto(funeralId);
      setPhotoList(response.attendees);
    }
    if (userInfo) {
      fetch();
    }
    // eslint-disable-next-line
  }, [props, funeralId]);

  const handleDownload = async (funeralId, orderId, type) => {
    history.push(`/${funeralId}/attendance/receipt?orderId=${orderId}&type=${type}&openExternalBrowser=1&isOrderOnly=${isOrderOnly}`)
  }

  useEffect(() => {
    getOrder(funeralId, orderId).then((res) => {
      if (res?.Item?.some((item) => item?.ItemType === 'sagawa_telegram')) {
        setShowBtnDownloadSagawa(true);
      }
      if (res?.Item?.some((item) => item?.ItemType !== 'sagawa_telegram')) {
        setShowBtnDownloadItowa(true);
      }
    });

    // eslint-disable-next-line
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.history.pushState(null, null, document.URL);
    setShowPopupConfirm(true);
  }

  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, []);

  useEffect(() => {
    if (confirmed) {
      history.push(`/${funeralId}/?re-order=1`);
    }

    // eslint-disable-next-line
  }, [confirmed]);

  return (
    <AttendeeLayout>
      <Helmet>
        <title>お支払い完了 | itowa</title>
      </Helmet>
      <div id="complete">
        <h2 className="item-title-horizontal">お支払い完了</h2>
        { userInfo
          ? <>
            <p className="thanks-message">
              お支払いいただきありがとうございます。 <br/>
              故人を大切にされてきた方々とアルバムを<br/>
              共有・閲覧することができます。
            </p>
            {showBtnDownloadItowa && <button className="btn btn-primary set-password-button" onClick={() => handleDownload(funeralId, orderId, 'itowa')}>領収書ダウンロード</button>}
            {showBtnDownloadSagawa && <button className="btn btn-primary set-password-button" onClick={() => handleDownload(funeralId, orderId, 'sagawa')}>領収書（弔電）ダウンロード</button>}
            <div className="button-reorder-wrapper">
              <h5>再度注文される方はこちら</h5>
              {
                isOrderOnly
                  ? <button className="btn btn-light btn-go-to-home" onClick={() => history.push("/" + funeralId + "/attendance/content/select/kumotsu?isOrderOnly=" + isOrderOnly)}>商品ページに戻る</button>
                  : <button className="btn btn-light btn-go-to-home" onClick={() => history.push(`/${funeralId}/?re-order=1`)}>トップページに戻る</button>
              }
            </div>
            <PhotoGallery photos={photoList}/>
            <button className="btn btn-primary black-button show-album-button" onClick={() => history.push(`/${funeralId}/album`)}>もっと見る</button>
          </>
          : <>
            <p className="thanks-message">
              お支払いいただきありがとうございます。
            </p>
            {showBtnDownloadItowa && <button className="btn btn-primary set-password-button" onClick={() => handleDownload(funeralId, orderId, 'itowa')}>領収書ダウンロード</button>}
            {showBtnDownloadSagawa && <button className="btn btn-primary set-password-button" onClick={() => handleDownload(funeralId, orderId, 'sagawa')}>領収書（弔電）ダウンロード</button>}
            <div className="button-reorder-wrapper">
              <h5>再度注文される方はこちら</h5>
              {
                isOrderOnly
                  ? <button className="btn btn-light btn-go-to-home" onClick={() => history.push("/" + funeralId + "/attendance/content/select/kumotsu?isOrderOnly=" + isOrderOnly)}>商品ページに戻る</button>
                  : <button className="btn btn-light btn-go-to-home" onClick={() => history.push(`/${funeralId}/?re-order=1`)}>トップページに戻る</button>
              }
            </div>
            {
              !isOrderOnly &&
                <>
                  <div className="complete-message-panel">
                    <div className="group">
                      <p className="complete-message-title">ログイン設定</p>
                      <p>パスワードを設定いただきますと、 下記の機能が使用可能となります。</p>
                      <ul className="account-feature-list">
                        <li>領収書のダウンロード</li>
                        <li>故人のアルバム共有・閲覧</li>
                        <li>再購入</li>
                      </ul>
                    </div>
                  </div>
                  <button className="btn btn-primary set-password-button" onClick={() => history.push(prefix + "/regist?email=" + email)}>ログインパスワード設定</button>
                </>
            }
          </>
        }
      </div>
      {showPopupConfirm && <ConfirmReOrder show={showPopupConfirm} setShow={setShowPopupConfirm} onConfirm={setConfirmed} />}
    </AttendeeLayout>
  );
};

const getOrder = async (funeralId, orderId) => {
  const options = {
    queryStringParameters: {
      funeralId: funeralId,
      orderId: orderId
    }
  };
  return await API.get('guest', '/order', options);
}

const getTopPagePhoto = async (funeralId) => {
  return await API.get('product', `/photos/${funeralId}/top`);
}

export default AttendanceComplete;