import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { AttendanceContext } from 'components/Contexts';

const ModalConfirmFuneralMoney = ({ onSubmit }) => {
  const { showConfirmFuneralMoney, setShowConfirmFuneralMoney } = useContext(AttendanceContext);

  const onClose = () => {
    setShowConfirmFuneralMoney(false);
  };

  const handleSubmit = () => {
    onSubmit();
    setShowConfirmFuneralMoney(false);
  };

  return (
    <React.Fragment>
      <Modal show={showConfirmFuneralMoney} onHide={onClose} centered={true} className='funeralMoneyWrapper'>
        <Modal.Header closeButton={true} />
        <Modal.Body>
          <div className="box-header">
            <img className="giftLogo" src="/images/icon-funeral-gift.png" alt="" />
            <h4>オンライン香典</h4>
            <div className='popupContent'>
              <p>
                ご遺族へ香典申込を一括通知します。 万が一ご遺族の意向や都合により受取されなかった場合は 「香典申込金額」 と 「システム利用料」 はキャンセル返金されます。
              </p>
              <p>その場合でもお名前とメッセージはお届けさせて頂いております為、 「メッセージ添付料¥110」 のみ発生致します。</p>
            </div>
          </div>
          <div className="box-footer">
              <Button variant="warning" className='btn-confirm' onClick={handleSubmit}>注文手続き</Button>

              <Button variant="secondary" className='btn-cancel' onClick={onClose}>やり直す</Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default ModalConfirmFuneralMoney;
