import { useContext } from 'react';
import { MessageContext } from 'components/Contexts';

export const useMessage = () => {
  const { setAlert, setModal, setModalConfirm } = useContext(MessageContext);
 
  return {
    alert: {
      success: (msgText) => {
        setAlert({variant: "success", message: msgText})
      },
      warning: (msgText) => {
        setAlert({ variant: "warning", message: msgText })
      },
      danger: (msgText) => {
        setAlert({ variant: "danger", message: msgText })
      },
      info: (msgText) => {
        setAlert({ variant: "info", message: msgText })
      },
    },
    confirm: {
      info: (params) => {
        setModalConfirm({
          show: true,
          title: params.title || "確認",
          message: params.message,
          variant: "success",
          onSubmit: params.onSubmit
        })
      },
      warning: (params) => {
        setModal({
          show: true,
          title: params.title || "確認",
          message: params.message,
          variant: "warning",
          onSubmit: params.onSubmit
        })
      },
      danger: (params) => {
        setModal({
          show: true,
          title: params.title || "警告",
          message: params.message,
          variant: "danger",
          onSubmit: params.onSubmit
        })
      },
    }
  }
}

export default useMessage;