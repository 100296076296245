import { useState, useRef } from 'react';
import { API } from 'aws-amplify'
import { Row, Col, Button } from 'react-bootstrap';
import { saveAs } from 'file-saver';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import * as Constants from '../../constants';
import { getBlob } from 'utils/request';

export const ObituarySearch = () => {

  const [funeral, setFuneral] = useState({});
  const searchKey = useRef("");

  const search = async () => {
    try {
      if (searchKey.current.value && searchKey.current.value !== "undefined") {
        const result = await getFuneral(searchKey.current.value);
        if (result.error) {
          console.log(result.error);
          setFuneral({});
        } else {
          setFuneral(result);
        }
      } else {
        setFuneral({});
      }
    } catch (e) {
      console.log(e);
      setFuneral({});
    }
  }

  const handleDownloadObituary = async (funeralId) => {
    const response = await downloadObituary(funeralId);
    if (response.filename) {
      const pdfData = await getBlob(Constants.IMAGE_BUCKET_URL + response.filename, { method: 'GET' });
      saveAs(pdfData, '訃報案内.pdf');
    }
  }

  return (
    <CommonLayout>
      <ContentHeader>
        訃報検索
      </ContentHeader>

      <div className="form-group">
        <Row>
          <Col md="6">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">検索条件</h3>
              </div>
              <div className="card-body">
                <label className="control-label">訃報NO</label>
                <input type="text" className="form-control" ref={searchKey} />
              </div>
              <div className="card-footer">
                <Button className="float-right" onClick={() => search()}>検索</Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {
        Object.keys(funeral).length ?
          <Row>
            <Col md="9">
              <div className="card">
                <div className="card-body">
                  <Row>
                    <Col md="2">
                      <div className="h4 text-center">{funeral.familyName + " 家"}</div>
                    </Col>
                    <Col md="6">
                    <div className="h4 text-center">{funeral.decreasedName + " 様"}</div>
                    </Col>
                    <Col md="4">
                    <div className="h4 text-center">{funeral.address1}</div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer">
                  <Button variant="dark" className="float-left" onClick={() => handleDownloadObituary(funeral.funeralId)}>訃報案内</Button>
                  <Button variant="success" className="float-right" onClick={() => window.open(`${Constants.FUNERALS_WEB_URL}/${funeral.funeralId}`)}>葬家ページ <i className="fas fa-external-link-alt" /></Button>
                </div>
              </div>
            </Col>
          </Row>
          :
          <></>
      }
    </CommonLayout>
  );
};

const getFuneral = async (funeralCode) => {
  return await API.get('product', '/funerals/obituary/' + funeralCode);
}

const downloadObituary = async (funeralId) => {
  return await API.get('product', '/funerals/' + funeralId + '/download/obituary');
}

export default ObituarySearch;