import React, {useRef, useState} from 'react';
import { useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { API } from 'aws-amplify';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import {CSVLink} from "react-csv";

export const PaymentCsvDownload = () => {
  const [dateArray, setDateArray] = useState([]);
  const [targetDate, setTargetDate] = useState("");
  const [isFirstHalf, setIsFirstHalf] = useState(true);

  const [csvData, setCsvData] = useState(null)
  const csvDownloaderRef = useRef(null)

  const handleCsvDownload = async (index) => {
    if (!csvDownloaderRef.current) return;
    try {
      let res = null;
      switch (index) {
        case 0:
          res = await getTransferSummaryCsv(`${targetDate}/01`, isFirstHalf);
          break;
        case 1:
          res = await getTransferCsvForCompany(`${targetDate}/01`, isFirstHalf);
          break;
        case 2:
          res = await getTransferCsvForFuneral(`${targetDate}/01`, isFirstHalf);
          break;
        case 3:
          res = await getInvoiceCsv(`${targetDate}/01`);
          break;
        case 4:
          res = await getTransferCsvForSagawa(`${targetDate}/01`, isFirstHalf);
          break;
        default:
          break;
      }
      setCsvData({
        filename: `transfer_summary.csv`,
        data: res?.csvData,
      });
    } catch (e) {
      alert('データ取得に失敗しました');
    }
  }

  useEffect(() => {
    if (csvData) {
      csvDownloaderRef.current.link.click()
    }
  }, [csvData])

  useEffect(() => {
    const processDate = new Date();
    processDate.setMonth(processDate.getMonth() + 1);
    const dateArray = [];

    // 処理日+1ヶ月から13ヶ月前までの月を生成
    for (let i = 0; i < 13; i++) {
        const currentMonth = processDate.getMonth();
        const formattedDate = `${processDate.getFullYear()}/${(currentMonth + 1).toString().padStart(2, '0')}`;
        dateArray.push(formattedDate);
        processDate.setMonth(currentMonth - 1);
    }
    setTargetDate(dateArray[0]);
    setDateArray(dateArray);
  }, []);

  return (
    <CommonLayout>
      <ContentHeader>
        決済情報CSVダウンロード
      </ContentHeader>
      <label>対象年月</label>
      <Row>
        <Col md="12" lg="2">
          <select className="custom-select" value={targetDate} onChange={(e) => setTargetDate(e.target.value)}>
            {
              dateArray.map((c, i) => <option key={i} value={c}>{c}</option>)
            }
          </select>
        </Col>
        <Col md="12" lg="2">
          <div class="form-check">
            <input className="form-check-input" type="radio" name="period" id="first_half" onClick={() => setIsFirstHalf(true)} checked={isFirstHalf}/><label for="first_half">前半</label>
          </div>
          <div class="form-check">
            <input className="form-check-input" type="radio" name="period" id="latter_half" onClick={() => setIsFirstHalf(false)} checked={!isFirstHalf}/><label for="latter_half">後半</label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="info" onClick={() => handleCsvDownload(0)}>送金：サマリーのCSV</Button>
        </Col>
        <Col>
          <Button variant="info" onClick={() => handleCsvDownload(1)}>葬儀社送金一覧CSV</Button>
        </Col>
        <Col>
          <Button variant="info" onClick={() => handleCsvDownload(2)}>葬家送金一覧のCSV</Button>
        </Col>
        <Col>
          <Button variant="info" onClick={() => handleCsvDownload(3)}>請求書払手数料CSV</Button>
        </Col>
        <Col>
          <Button variant="info" onClick={() => handleCsvDownload(4)}>佐川送金一覧のCSV</Button>
        </Col>
      </Row>
      <CSVLink
        ref={csvDownloaderRef}
        filename={csvData?.filename}
        data={csvData?.data || []}
        style={{ display: 'none' }}
      />
    </CommonLayout>
  );
};

const getTransferSummaryCsv = async (date, isFirstHalf) => {
  const options = {
    queryStringParameters: {date, isFirstHalf}
  }
  return await API.get('product', '/admin/transfers/summary/csv', options);
}


const getTransferCsvForCompany = async (date, isFirstHalf) => {
  const options = {
    queryStringParameters: {date, isFirstHalf}
  }
  return await API.get('product', '/admin/transfers/company/csv', options);
}


const getTransferCsvForFuneral = async (date, isFirstHalf) => {
  const options = {
    queryStringParameters: {date, isFirstHalf}
  }
  return await API.get('product', '/admin/transfers/funeral/csv', options);
}

const getInvoiceCsv = async (date) => {
  const options = {
    queryStringParameters: {date}
  }
  return await API.get('product', '/admin/transfers/invoice/csv', options);
}

const getTransferCsvForSagawa = async (date, isFirstHalf) => {
  const options = {
    queryStringParameters: {date, isFirstHalf}
  }
  return await API.get('product', '/admin/transfers/sagawa/csv', options);
}

export default PaymentCsvDownload;
