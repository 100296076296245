import React, { useEffect } from 'react';
import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useLoading } from 'components/Loading';
import { Row, Col, Button } from 'react-bootstrap';
import UploadImage from 'components/UploadImage';
import useMessage from 'components/Message';
import Input from 'components/Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const GiftEdit = (props) => {
  const companyId = "Swell";
  const newEntry = props.method === "new";
  const message = useMessage();
  const history = useHistory();
  const loading = useLoading();
  const formik = useFormik({
    initialValues: {
      giftId: "",
      giftName: "",
      giftPrice: "",
      taxRate: false,
      giftDetail: "",
      youtubeTag: "",
      imagePath: "",
      funeralGiftPrice3k: false,
      funeralGiftPrice5k: false,
      funeralGiftPrice10k: false,
      funeralGiftPrice30k: false,
      funeralGiftPrice50k: false,
      funeralGiftPrice100k: false,
      funeralGiftOther: false,
    },
    validationSchema: Yup.object({
      giftId: Yup.string().required("商品コードは必須です"),
      giftName: Yup.string().required("商品名は必須です"),
      giftPrice: Yup.string().required("価格は必須です").matches(/^[0-9]+$/, { message: '価格が有効ではありません' })
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        if (values.giftId.slice(0, 1) !== "_") {
          if (newEntry) {
            const result = await createGift(companyId, values);
            if (result.error) {
              if (result.error === "giftId exists") {
                formik.setFieldError("giftId", "同一コードがすでに存在しています");
              } else {
                message.alert.danger("システムエラーが発生しました");
              }
            } else {
              message.alert.success("返礼品を作成しました");
              history.goBack();
            }
          } else {
            await updateGift(companyId, values);
            message.alert.success("返礼品を更新しました");
            history.goBack();
          }
        } else {
          formik.setFieldError("giftId", "頭文字を_にすることはできません");
        }
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    const fetch = async (giftId) => {
      // loading(true);
      if (!newEntry) {
        const gifts = await getGift(companyId, giftId);
        setFieldValue("giftId", gifts.GiftId);
        setFieldValue("giftName", gifts.GiftName);
        setFieldValue("giftPrice", gifts.Price);
        setFieldValue("taxRate", !gifts.TaxRateTenPer);
        setFieldValue("giftDetail", gifts.Description);
        setFieldValue("youtubeTag", gifts.YoutubeTag);
        setFieldValue("imagePath", gifts.ImagePath);
        setFieldValue("funeralGiftPrice3k", gifts.Money3000);
        setFieldValue("funeralGiftPrice5k", gifts.Money5000);
        setFieldValue("funeralGiftPrice10k", gifts.Money10000);
        setFieldValue("funeralGiftPrice30k", gifts.Money30000);
        setFieldValue("funeralGiftPrice50k", gifts.Money50000);
        setFieldValue("funeralGiftPrice100k", gifts.Money100000);
        setFieldValue("funeralGiftOther", gifts.OtherItemType);
      }
      loading(false);
    }

    fetch(props.match.params.giftId);
  }, [newEntry, props, setFieldValue, loading]);


  return (
    <CommonLayout>
      <ContentHeader>
        返礼品管理
      </ContentHeader>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <Row>
            <Col>
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">{newEntry ? "返礼品新規作成" : "返礼品編集"}</h3>
                  </div>
                <div className="card-body">
                  <Row>
                    <Col>
                      <Row>
                        <Col md="5">
                          <label className="control-label">商品コード</label>
                            <Input type="text" name="giftId" className="form-control"  placeholder="001" formik={formik}/>
                        </Col>
                        <Col md="1">
                        </Col>
                        <Col md="5">
                          <label className="control-label">価格（税込み）</label>
                            <Input type="text" name="giftPrice" className="form-control"  placeholder="1000" formik={formik}/>
                            <Input type="checkbox" name="taxRate" formik={formik}/>
                              <label>消費税率8%</label>
                        </Col>
                        <Col md="1">
                        </Col>
                      </Row><br/>

                      <Row>
                        <Col md="5">
                          <label className="control-label">商品名（30文字以内）</label>
                            <Input type="text" name="giftName" className="form-control" maxlength="30" placeholder="商品名" formik={formik}/>
                        </Col>
                        <Col md="1">
                        </Col>
                        <Col md="6">
                          <label className="control-label">香典金額</label>
                          <br/>
                          <div className="priceCheckBox">
                            <Input type="checkbox" name="funeralGiftPrice3k" formik={formik}/>
                                <label className="label-money-small">3,000円</label>
                            </div>
                            <div className="priceCheckBox">
                              <Input type="checkbox" name="funeralGiftPrice5k" formik={formik}/>
                                  <label className="label-money-small">5,000円</label>
                            </div>
                            <div className="priceCheckBox">
                              <Input type="checkbox" name="funeralGiftPrice10k" formik={formik}/>
                                <label className="label-money-small">10,000円</label>
                            </div>
                          <br/>

                            <div className="priceCheckBox">
                              <Input type="checkbox" name="funeralGiftPrice30k" formik={formik}/>
                                <label className="label-money-small">30,000円</label>
                            </div>
                            <div className="priceCheckBox">
                              <Input type="checkbox" name="funeralGiftPrice50k" formik={formik}/>
                                <label className="label-money-small">50,000円</label>
                            </div>
                            <div className="priceCheckBox">
                              <Input type="checkbox" name="funeralGiftPrice100k" formik={formik}/>
                                <label className="label-money-large">100,000円</label>
                            </div>
                            <div className="priceCheckBox">
                              <Input type="checkbox" name="funeralGiftOther" formik={formik}/>
                                <label className="label-money">供花供物／その他香典</label>
                            </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="5">
                          <label className="control-label">商品説明</label>
                            <Input type="textarea" name="giftDetail" className="form-control" rows="10" placeholder="詳細" formik={formik}/>
                        </Col>
                        <Col md="1">
                        </Col>
                        <Col md="5">
                          <label className="control-label">YouTubeタグ</label>
                            <Input type="text" name="youtubeTag" className="form-control"  placeholder="url" formik={formik}/>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="5">
                      <Row>
                        <Col md="2">
                        </Col>
                        <Col md="10">
                          <label className="control-label">画像選択</label>
                            <UploadImage name="imagePath" className="form-control" width={400} height={400} formik={formik}/>
                        </Col>
                        <Col md="9">
                        </Col>
                        <Col>
                          {
                            newEntry ?
                              <Button type="submit" variant="success">作成</Button>
                              :
                              <Button type="submit" variant="warning">更新</Button>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </form>
    </CommonLayout>
  )
}

const getGift = async (companyId, giftId) => {
  const options = {
    queryStringParameters:{
      companyId: companyId
    }
  }
  return await API.get('product', '/gifts/' + giftId, options);
}

const createGift = async (companyId, values) => {
  const options = {
    body: {
      companyId: companyId,
      giftId: values.giftId,
      giftName: values.giftName,
      description: values.giftDetail,
      price: values.giftPrice,
      taxRate: !values.taxRate,
      money3000: values.funeralGiftPrice3k,
      money5000: values.funeralGiftPrice5k,
      money10000: values.funeralGiftPrice10k,
      money30000: values.funeralGiftPrice30k,
      money50000: values.funeralGiftPrice50k,
      money100000: values.funeralGiftPrice100k,
      otherItemType: values.funeralGiftOther,
      youtubeTag: values.youtubeTag,
      imagePath: values.imagePath,
    }
  }
  return await API.post('product', '/gifts', options);
}

const updateGift = async (companyId, values) => {
  const options = {
    body: {
      companyId: companyId,
      giftId: values.giftId,
      giftName: values.giftName,
      description: values.giftDetail,
      price: values.giftPrice,
      taxRate: !values.taxRate,
      money3000: values.funeralGiftPrice3k,
      money5000: values.funeralGiftPrice5k,
      money10000: values.funeralGiftPrice10k,
      money30000: values.funeralGiftPrice30k,
      money50000: values.funeralGiftPrice50k,
      money100000: values.funeralGiftPrice100k,
      otherItemType: values.funeralGiftOther,
      youtubeTag: values.youtubeTag,
      imagePath: values.imagePath,
    }
  }
  return await API.put('product', '/gifts', options);
}

export default GiftEdit;
