import React, { useEffect } from 'react';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Input from 'components/Input';
import Select from 'components/Select';
import useMessage from 'components/Message';
import UploadImage from 'components/UploadImage';
import { useLoading } from 'components/Loading';

const ItemType = (props) => {
  const { name, formik } = props;
  const hasError = formik && formik.errors[name];

  return (
    <>
      <div className={"form-check" + (hasError ? " is-invalid" : "")} style={{ display: "flex", justifyContent: "flex-start", marginLeft: "1rem" }}>
        <label style={{ width: "8rem" }}>
          <Input type="radio" name={name} value="flower" className={"form-check-input" + (hasError ? " is-invalid" : "")} formik={formik} />供花
        </label>
        <label style={{ width: "8rem" }}>
          <Input type="radio" name={name} value="things" className={"form-check-input" + (hasError ? " is-invalid" : "")} formik={formik} />供物
        </label>
        <label style={{ width: "8rem" }}>
          <Input type="radio" name={name} value="telegram" className={"form-check-input" + (hasError ? " is-invalid" : "")} formik={formik} />弔文
        </label>
      </div>
      <div className="invalid-feedback">{formik.errors[name]}</div>
    </>
  )
}

export const ItemEdit = (props) => {
  const newEntry = props.method === "new";

  const history = useHistory();
  const message = useMessage();
  const [supplierList, setSupplierList] = React.useState([]);
  const loading = useLoading();

  const formik = useFormik({
    initialValues: {
      itemType: "flower",
      itemId: "",
      itemName: "",
      supplierId: "",
      price: "",
      taxRate: false,
      description: "",
      youtubeTag: "",
      imagePath: "",
      exampleLink: ""
    },
    validationSchema: Yup.object({
      itemId: Yup.string().required("商品コードは必須です").matches(/^[a-zA-Z0-9]*$/,'6文字以内で半角英数字で入力してください。'),
      itemName: Yup.string().required("商品名は必須です"),
      supplierId: Yup.string().required("業者は必須です"),
      price: Yup.string().required("価格は必須です").matches(/^[0-9]+$/, { message: '価格が有効ではありません' })
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        if (values.itemId.slice(0, 1) !== "_") {
          if (newEntry) {
            const result = await createItem(props.match.params.companyId, values);
            if (result.error) {
              if (result.error === "ItemId exists") {
                formik.setFieldError("itemId", "同一コードがすでに存在しています");
              } else {
                message.alert.danger("システムエラーが発生しました");
              }
            } else {
              message.alert.success("商品を作成しました");
              history.goBack();
            }
          } else {
            await updateItem(props.match.params.companyId, values);
            message.alert.success("商品を更新しました");
            history.goBack();
          }
        } else {
          formik.setFieldError("itemId", "頭文字を_にすることはできません");
        }
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    const fetch = async (itemId) => {
      // loading(true);
      if (!newEntry) {
        const item = await getItem(props.match.params.companyId, itemId);
        setFieldValue("itemId", item.ItemId);
        setFieldValue("itemType", item.ItemType);
        setFieldValue("itemName", item.ItemName);
        setFieldValue("supplierId", item.SupplierId);
        setFieldValue("price", item.Price);
        setFieldValue("taxRate", !item.TaxRateTenPer);
        setFieldValue("description", item.Description);
        setFieldValue("youtubeTag", item.YoutubeTag);
        setFieldValue("imagePath", item.ImagePath);
        setFieldValue("exampleLink", item.ExampleLink);
      }
      const result = await getSupplierList(props.match.params.companyId);
      setSupplierList(result.supplierList);

      loading(false);
    }

    fetch(props.match.params.itemId);
  }, [newEntry, props, setFieldValue, loading]);

  const handleDelete = async () => {
    message.confirm.danger({
      title: "商品を削除",
      message: "削除してもよろしいですか？",
      onSubmit: async () => {
        await deleteItem(props.match.params.companyId, formik.values.itemId);
        message.alert.success("商品を削除しました");
        history.goBack();
      }
    });
  }

  return (
    <CommonLayout>
      <ContentHeader>
        {newEntry ? "商品新規作成" : "商品編集"}
      </ContentHeader>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md="8">
            <div className="card">
              <div className="card-body">
                <Row>
                  <Col md="7">
                    <div className="form-group">
                      <Row>
                        <Col md="5">
                          <label className="control-label">商品コード</label>
                          <Input type="text" maxLength="6" name="itemId" className="form-control" disabled={!newEntry} formik={formik} />
                        </Col>
                        <Col md="7">
                          <label className="control-label">商品種別</label>
                          <ItemType name="itemType" formik={formik} />
                        </Col>
                      </Row>
                    </div>
                    <div className="form-group">
                      <Row>
                        <Col md="6">
                          <label className="control-label">商品名</label>
                          <Input type="text" name="itemName" className="form-control" formik={formik} />
                        </Col>
                        <Col md="6">
                          <label className="control-label">業者</label>
                          <Select name="supplierId" className="custom-select" formik={formik}>
                            <option></option>
                            {
                              supplierList.map((s, i) => {
                                return (
                                  <option key={i} value={s.Id}>{s.CompanyName}</option>
                                );
                              })
                            }
                          </Select>
                        </Col>
                      </Row>
                    </div>
                    <div className="form-group">
                      <label className="control-label">価格</label>
                      <Input type="text" name="price" className="form-control text-right" formik={formik} />
                      <div className="form-check">
                        <Input type="checkbox" name="taxRate" className="form-check-input" formik={formik} />
                        <label className="form-check-label">消費税率8%</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">商品説明</label>
                      <Input type="textarea" name="description" className="form-control" formik={formik} style={{ height: "100px" }} />
                    </div>
                    <div className="form-group">
                      {formik.values.itemType !== "telegram" ?
                        <>
                          < label className="control-label">Youtubeタグ</label>
                          <Input type="text" name="youtubeTag" className="form-control" formik={formik} />
                        </>
                        :
                        <>
                          < label className="control-label">弔文例文集リンク</label>
                          <Input type="text" name="exampleLink" className="form-control" formik={formik} />
                        </>
                      }
                    </div>
                  </Col>
                  <Col md="5">
                    <label className="control-label">商品画像</label>
                    <UploadImage name="imagePath" className="form-control" formik={formik} width={400} height={400} />
                  </Col>
                </Row>
              </div>
              <div className="card-footer">
                <div>
                  <Button variant="secondary" className="float-right" onClick={() => history.goBack()}>戻る</Button>
                  {
                    newEntry ?
                      <Button type="submit" variant="success">作成</Button>
                      :
                      <>
                        <Button type="submit" variant="success">更新</Button>
                        {' '}
                        <Button variant="danger" onClick={handleDelete}>削除</Button>
                      </>
                  }
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </form>

    </CommonLayout>
  );
};

const getItem = async (companyId, itemId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }
  return await API.get('product', '/items/' + itemId, options);
}

const createItem = async (companyId, values) => {
  const options = {
    body: {
      companyId: companyId,
      itemId: values.itemId,
      itemType: values.itemType,
      itemName: values.itemName,
      supplierId: values.supplierId,
      price: values.price,
      taxRate: !values.taxRate,
      description: values.description,
      youtubeTag: values.youtubeTag,
      imagePath: values.imagePath,
      exampleLink: values.exampleLink
    }
  }
  return await API.post('product', '/items', options);
}

const updateItem = async (companyId, values) => {
  const options = {
    body: {
      companyId: companyId,
      itemId: values.itemId,
      itemType: values.itemType,
      itemName: values.itemName,
      supplierId: values.supplierId,
      price: values.price,
      taxRate: !values.taxRate,
      description: values.description,
      youtubeTag: values.youtubeTag,
      imagePath: values.imagePath,
      exampleLink: values.exampleLink
    }
  }
  return await API.put('product', '/items', options);
}

const deleteItem = async (companyId, itemId) => {
  const options = {
    body: {
      companyId: companyId,
      itemId: itemId,
    }
  }
  return await API.del('product', '/items', options);
}

const getSupplierList = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
      keyword: null
    }
  };

  return await API.get('product', '/suppliers', options);
}

export default ItemEdit;