import { useContext, useEffect } from 'react';
import { API } from 'aws-amplify'
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import AttendeeLayout from 'components/AttendeeLayout';
import { prefCodeToName, prefNameToCode } from 'components/CustomForms/Prefecture';
import { useLoading } from 'components/Loading';
import { ContentHeader } from 'components/ContentHeader';
import { AuthContext } from '../../components/Contexts';

export const FuneralStripeConnectDetail = (props) => {
  const { userInfo } = useContext(AuthContext);
  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;
  const prefix = "/" + funeralId;

  const history = useHistory();
  const loading = useLoading();

  const formik = useFormik({
    initialValues: {
      FirstName: "",
      LastName: "",
      FirstNameKana: "",
      LastNameKana: "",
      phoneNo: "",
      zipCode: "",
      prefecture: "",
      city: "",
      cityKana: "",
      town: "",
      townKana: "",
      address1: "",
      address1Kana: "",
      address2: "",
      address2Kana: "",
      dob: "",
      email: "",
      bankCode1: "",
      bankCode2: "",
      accountNo: "",
      accountName: "",
      verified: []
    },
    validationSchema: Yup.object({}),
    validateOnChange: false,
    validateOnBlur: false
  });

  const setFieldValue = formik.setFieldValue;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    loading(true);

    // 親族代表アカウントじゃなければリダイレクトする
    if (!userInfo.attendees.RelativesRepresentative) {
      loading(false);
      history.push(prefix + "/");
      return;
    }

    let funeral = null;
    try {
      funeral = await getFuneralStripeConnect(funeralId);
      if(funeral === 'error') {
        toConnectEdit();
        return;
      }
      const { isAlreadyIdentityVerified, identityErrors } = await getVerifiedFuneralStripeConnect(funeralId)
      if(isAlreadyIdentityVerified) {
        funeral.verified = ['確認済み'];
      } else {
        funeral.verified = identityStatus(identityErrors);
      }
    } catch (e) {
      if (e.response.status !== 404) alert("システムエラーが発生しました");
      loading(false);
      return;
    }

    Object.keys(funeral).map((key) => {
      if (key === "prefecture") {
        return setFieldValue(key, prefNameToCode(funeral[key]));
      } else {
        return setFieldValue(key, funeral[key]);
      }
    });
    loading(false);

    // eslint-disable-next-line
  }, []);

  const toConnectEdit = async () => {
    loading(false);
    history.push(prefix + "/funeral/connect");
  }

  const toPayout = () => {
    loading(false);
    history.push(prefix + "/funeral/connect/payout");
  }

  return (
    <AttendeeLayout adminPage={true}>
      <div id="attendee-users">
        <div className="title-search">
          <div className="title">
            <ContentHeader>
              出金情報詳細
            </ContentHeader>
            <div>
              <button type="button" className="black-button btn mb-2" onClick={toPayout}>振込申請</button>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  <th>項目</th>
                  <th>登録内容</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>代表者名（漢字）</td>
                  <td>{ formik.values.LastName }　{ formik.values.FirstName }</td>
                </tr>
                <tr>
                  <td>代表者名（カナ）</td>
                  <td>{ formik.values.LastNameKana }　{ formik.values.FirstNameKana }</td>
                </tr>
                <tr>
                  <td>郵便番号</td>
                  <td>{ formik.values.zipCode }</td>
                </tr>
                <tr>
                  <td>都道府県</td>
                  <td>{ prefCodeToName(formik.values.prefecture) }</td>
                </tr>
                <tr>
                  <td>電話番号</td>
                  <td>{ formik.values.phoneNo }</td>
                </tr>
                <tr>
                  <td>住所・市区町村（漢字）</td>
                  <td>{ formik.values.city }</td>
                </tr>
                <tr>
                  <td>住所・市区町村（カナ）</td>
                  <td>{ formik.values.cityKana }</td>
                </tr>
                <tr>
                  <td>住所・町域名（漢字）</td>
                  <td>{ formik.values.town }</td>
                </tr>
                <tr>
                  <td>住所・町域名（カナ）</td>
                  <td>{ formik.values.townKana }</td>
                </tr>
                <tr>
                  <td>住所・番地等（漢字）</td>
                  <td>{ formik.values.address1 }</td>
                </tr>
                <tr>
                  <td>住所・番地等（カナ）</td>
                  <td>{ formik.values.address1Kana }</td>
                </tr>
                <tr>
                  <td>住所・建物名・部屋番号（漢字）</td>
                  <td>{ formik.values.address2 }</td>
                </tr>
                <tr>
                  <td>住所・建物名・部屋番号（カナ）</td>
                  <td>{ formik.values.address2Kana }</td>
                </tr>
                <tr>
                  <td>代表者・メールアドレス</td>
                  <td>{ formik.values.email }</td>
                </tr>
                <tr>
                  <td>代表者・生年月日</td>
                  <td>{ formik.values.dob }</td>
                </tr>
                <tr>
                  <td>銀行コード</td>
                  <td>{ formik.values.bankCode1 }</td>
                </tr>
                <tr>
                  <td>支店コード</td>
                  <td>{ formik.values.bankCode2 }</td>
                </tr>
                <tr>
                  <td>口座番号</td>
                  <td>{ formik.values.accountNo }</td>
                </tr>
                <tr>
                  <td>口座名義</td>
                  <td>{ formik.values.accountName }</td>
                </tr>
                <tr>
                  <td>本人確認ステータス</td>
                  <td>{ formik.values.verified.map(x => <span>{x}<br/></span>)}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="card-footer">
            <div>
              <Button variant="secondary" className="float-right" onClick={() => history.goBack()}>戻る</Button>
              <Button className="float-left" variant="success" onClick={toConnectEdit}>更新はこちら</Button>
            </div>
          </div>
        </div>
      </div>
    </AttendeeLayout>
  );
};

const getFuneralStripeConnect = async (funeralId) => {
  try {
    return await API.get('product', `/funerals/${funeralId}/connect`);
  }catch (e) {
    return 'error'
  }
}

const getVerifiedFuneralStripeConnect = async (funeralId) => {
  try {
    return await API.get('product', `/funerals/${funeralId}/connect/verified`);
  }catch (e) {
    return false
  }
}

const identityStatus = (identityErrors) => {
  return identityErrors.map(identityErrorToString);
}

const identityErrorToString = (identityError) => {
  switch(identityError) {
    // 入力内容に空白があった場合の処理
    // 名前に空欄がある場合
    case "individual.first_name_kana":
      return "名前（カナ）が未入力です。";
    case "individual.first_name_kanji":
      return "名前（漢字）が未入力です。";
    case "individual.last_name_kana":
      return "名字（カナ）が未入力です。";
    case "individual.last_name_kanji":
      return "名字（漢字）が未入力です。";

    // 生年月日に空欄がある場合
    case "individual.dob.day":
    case "individual.dob.month":
    case "individual.dob.year":
      return "生年月日に空欄があります。";

    // 住所(カナに空欄がある場合
    case "individual.address_kana.line1":
    case "individual.address_kana.postal_code":
    case "individual.address_kana.city":
    case "individual.address_kana.state":
    case "individual.address_kana.town":
      return "住所情報の（カナ）に未入力の項目があります。";

    // 住所(漢字に空欄がある場合
    case "individual.address_kanji.line1":
    case "individual.address_kanji.postal_code":
    case "individual.address_kanji.city":
    case "individual.address_kanji.state":
    case "individual.address_kanji.town":
      return "住所情報の（漢字）に未入力の項目があります。";

    // 本人確認書類を提出してない場合
    case "individual.verification.document":
      return "本人確認書類が提出されていません。";

    // 電話番号が空欄だった場合
    case "individual.phone":
      return "電話番号が未入力です。";

    // 銀行口座情報が未入力の場合
    case "external_account":
      return "銀行口座情報が未入力です";

    // 本人確認書類の判定
    case "verification_document_corrupt":
      return "本人確認書類のデータが破損しています。";
    case "verification_document_failed_copy":
      return "本人確認書類が不正です。";
    case "verification_document_failed_greyscale":
      return "本人確認書類はフルカラーのものをアップロードして下さい。";
    case "verification_document_incomplete":
      return "本人確認書類に不備があります。";
    case "verification_document_not_readable":
      return "本人確認書類が読み取れません。";
    case "verification_document_not_uploaded":
      return "本人確認書類がアップロードされていません。";
    case "verification_document_not_signed":
      return "本人確認書類のサインが確認できません。";
    case "verification_document_missing_back":
      return "本人確認書類の裏面に不備があります。";
    case "verification_document_missing_front":
      return "本人確認書類の表面に不備があります。";
    case "verification_document_too_large":
      return "本人確認書類の容量が10MBを超えています。";

    // 本人確認書類がサポート対象かどうか
    case "verification_document_country_not_supported":
    case "verification_document_invalid":
    case "verification_document_type_not_supported":
      return "アップロードされた本人確認書類はサポート対象ではありません。";

    // 問い合わせが必要なエラー
    case "verification_failed_other":
    case "verification_document_failed_other":
      return "本人確認に失敗しました。詳細についてはお問い合わせ下さい。";

    // 本人確認書類の期限切れ、期限が確認できない場合
    case "verification_document_expired":
      return "本人確認書類の有効期限が切れています。";
    case "verification_document_issue_or_expiry_date_missing":
      return "本人確認書類の有効期限が確認できませんでした。";

    // 本人確認に関するエラー
    case "verification_failed_keyed_identity":
      return "本人確認書類と入力内容が照合できませんでした。";
    case "verification_document_name_mismatch":
      return "入力内容と本人確認書類の氏名が一致しません。";
    case "verification_document_dob_mismatch":
      return "入力内容と本人確認書類の生年月日が一致しません。";
    case "verification_document_address_mismatch":
      return "入力内容と本人確認書類の住所が一致しません。";
    case "verification_document_id_number_mismatch":
      return "会社法人等番号が不正です。";
    case "verification_document_photo_mismatch":
      return "入力内容と本人確認書類の顔写真が一致しません。";
    case "verification_document_fraudulent":
    case "verification_document_manipulated":
      return "本人確認書類が改変されている可能性があるため確認できません。";

    // ビジネス確認に関するエラー
    case "verification_failed_keyed_match":
    case "verification_failed_document_match":
      return "アカウント情報が確認できませんでした。アカウント情報を更新して下さい。";
    case "verification_failed_id_number_match":
    case "verification_failed_name_match":
    case "verification_failed_address_match":
      return "書類上の情報がアカウントの情報と一致しません。";
    case "verification_document_address_missing":
    case "verification_document_id_number_missing":
    case "verification_document_name_missing":
      return "アップロードされた書類に、必須情報が空欄になっている箇所があります。";

    default:
      return "確認中";
  }
}

export default FuneralStripeConnectDetail;
