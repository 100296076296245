import { API } from 'aws-amplify'

export const paymentIntent = async (companyId, amount) => {
  const options = {
    body: {
      companyId: companyId,
      amount: amount
    }
  };

  return await API.post('product', '/charge', options);
}