import React, {useState, useEffect, useContext} from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import AttendeeLayout from 'components/AttendeeLayout';
import {AttendanceContext} from "../../components/Contexts";
import {getCartContents} from "../../components/Cart";
import { useLoading } from 'components/Loading';
import {API} from "aws-amplify";

import * as Constants from '../../constants';

export const AttendanceContentPaymentSelect = (props) => {
  const { funeralInfo } = useContext(AttendanceContext);
  const [selectCard, setSelectCard] = useState(false);
  const [selectInvoice, setSelectInvoice] = useState(false);
  const [cartInKouden, setCartInKouden] = useState(false);
  const [receiptNameAddress, setReceiptNameAddress] = useState('');
  const [invoiceNameAddress, setInvoiceNameAddress] = useState('');
  const [invoicePhoneNo, setInvoicePhoneNo] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const loading = useLoading();
  const funeralId = props.match.params.funeralId;

  const { location } = props;
  const queryString = new URLSearchParams(location.search);
  const isOrderOnly = queryString.get("isOrderOnly") === "true";

  useEffect(() => {
    // funeralInfoが存在しなければ取得できるまで待機する
    if (!funeralInfo) return;

    // 請求書払いが有効じゃなければそのままクレジットカード決済画面へ遷移する
    if (!funeralInfo.invoicePaymentCheck) {
      history.push("/" + funeralId + "/attendance/content/payment?isOrderOnly=" + isOrderOnly);
    }

    // カートに香典が含まれてるかどうか
    async function fetchCartContents() {
      const cart = await getCartContents(funeralId);
      const isIncludeKouden = cart.some((item) => item.itemType === 'money' || item.itemType === 'sagawa_telegram' );
      setCartInKouden(isIncludeKouden);
    }
    fetchCartContents();

    localStorage.setItem("receiptName", localStorage.getItem("attendeeName"));
    setReceiptNameAddress(localStorage.getItem("attendeeName"));
    setInvoiceNameAddress(localStorage.getItem("attendeeName"));
    setInvoicePhoneNo(localStorage.getItem("attendeePhoneNo"));

    // eslint-disable-next-line
  }, [funeralInfo, funeralId]);

  const changeInvoice = (invoiceType) => {
    const cart = JSON.parse(localStorage.getItem(funeralId));
    cart.invoiceType = invoiceType;
    localStorage.setItem(funeralId, JSON.stringify(cart));
    setSelectCard(false);
    setSelectInvoice(true);
  }

  const changeCard = () => {
    setSelectCard(true);
    setSelectInvoice(false);
  }

  const handlePayment = async () => {
    // カード払いの場合はそのまま遷移する
    if (selectCard && !selectInvoice) {
      history.push("/" + funeralId + "/attendance/content/payment?isOrderOnly=" + isOrderOnly);
      return;
    } else if (!selectCard && !selectInvoice) {
      alert("支払い方法を選択してください。");
      return;
    }

    // SMS認証を送信する
    loading(true);
    const getSessionResult = await getSessionId();
    const sessionId = getSessionResult.data;
    if (invoicePhoneNo !== Constants.PHONE_SKIP_VERIFY || Constants.APP_STAGE === 'prd') {
      const result = await sendVerifyCode(sessionId, invoicePhoneNo);
      if (result.error) {
        setErrorMessage(result.error.data.message);
        loading(false);
        return;
      }

      // 請求書払い用の名前と電話番号、SMS認証のセッションIDをlocalStorageへ保存する
      const cart = JSON.parse(localStorage.getItem(funeralId))
      cart.invoiceNameAddress = invoiceNameAddress;
      cart.invoicePhoneNo = invoicePhoneNo;
      cart.sessionId = sessionId;
      cart.authId = result.data;
      localStorage.setItem(funeralId, JSON.stringify(cart));
    } else {
      const cart = JSON.parse(localStorage.getItem(funeralId))
      cart.invoiceNameAddress = invoiceNameAddress;
      cart.invoicePhoneNo = invoicePhoneNo;
      cart.sessionId = sessionId;
      cart.authId = invoicePhoneNo;
      localStorage.setItem(funeralId, JSON.stringify(cart));
    }

    // 請求書払いの認証コード入力画面へ遷移する
    history.push("/" + funeralId + "/attendance/content/payment/invoice?isOrderOnly=" + isOrderOnly);
    loading(false);
  }

  const updateReceiptNameAddress = (value) => {
    localStorage.setItem("receiptName", value);
    setReceiptNameAddress(value);
  }

  return (
    <AttendeeLayout>
      <Helmet>
        <title>支払い方法の選択 | itowa</title>
      </Helmet>
      <h2 className="title-horizontal" style={{ width: "14rem" }}>支払い方法</h2>
      {
          errorMessage &&
          <div className="fs-3 error-message">
            {errorMessage}
          </div>
      }
      { cartInKouden ?
        //香典が含まれていた場合
        <div className="paymentSelect">
          <div class="form-check">
            <label className="fs-2"><input className="paymentSelectButton" type="radio" name="kouden" value="Unselected" onClick={() => changeCard()}/>　クレジットカード</label>
          </div>
          <br/>
          <div class="form-check">
            <label className="fs-2"><input className="paymentSelectButton" type="radio" name="kouden" value="Unselected" disabled/>　請求書払い</label>
          </div>
          <div className="invoiceInputField">
            <label class="form-label fs-2 mt-4">領収書宛名</label>
            <input type="email" className="form-control" placeholder="宛名入力欄" value={receiptNameAddress} onChange={event => updateReceiptNameAddress(event.target.value)}></input>
            <p className="fs-4">※発行後の宛名の修正は不可</p>
          </div>
          <div className="selectAlert mt-4">
            <p className="fs-2">香典は請求書払いが<br className="br-sp"/>ご利用いただけません。<br/>請求書以外の支払い方法で<br className="br-sp"/>ご注文お願いします。</p>
          </div>
          <div className="button-to-payment-wrapper">
            <button type="button" className="btn-to-confirm mt-5" onClick={ handlePayment }>進む</button>
            <span className="chevron-right"></span>
          </div>
        </div>
        :
        //香典がない場合
        <div className="paymentSelect">
          <div class="form-check">
            <label className="fs-2"><input className="koudenPriceSelectButton" type="radio" name="paymentSelectButton" value="Unselected" onClick={() => changeCard()}/>　クレジットカード</label>
          </div>
          <br/>
          <div class="form-check">
            <label className="fs-2"><input className="koudenPriceSelectButton" type="radio" name="paymentSelectButton" value="Unselected" onClick={() => changeInvoice("BANK_TRANSFER")}/>　銀行振込（請求書払い）</label>
          </div>
          {/* 請求書払い選択時のみ請求書の入力欄表示 */}
          { selectInvoice ?
            <div className="invoiceInputField">
              <label class="form-label fs-2 mt-4">請求書宛名</label>
              <input type="email" className="form-control" placeholder="宛名入力欄" value={invoiceNameAddress} onChange={event => setInvoiceNameAddress(event.target.value)}></input>
              <p className="fs-4">※発行後の宛名の修正は不可</p>
              <br/>
              <label class="form-label fs-2 mt-4">認証用電話番号</label>
              <input type="email" className="form-control" placeholder="電話番号入力欄" value={invoicePhoneNo} onChange={event => setInvoicePhoneNo(event.target.value)}></input>
              <p className="fs-4">※SMSが受信できる携帯番号のみ対応</p>
            </div>
            :
            <div className="invoiceInputField">
                <label class="form-label fs-2 mt-4">領収書宛名</label>
                <input type="email" className="form-control" placeholder="宛名入力欄" value={receiptNameAddress} onChange={event => updateReceiptNameAddress(event.target.value)}></input>
                <p className="fs-4">※発行後の宛名の修正は不可</p>
            </div>
          }
          <div className="message-warning fs-3">香典または弔電は請求書払いがご利用いただけません。請求書以外の支払い方法でご注文お願いします。</div>
          <div className="button-to-payment-wrapper">
            <button type="button" className="btn-to-confirm mt-5" onClick={ handlePayment }>進む</button>
            <span className="chevron-right"></span>
          </div>
        </div>
      }
    </AttendeeLayout>
  )
}

const getSessionId = async () => {
  return await API.get('guest', '/sms/session', {});
}

const sendVerifyCode = async (sessionId, phoneNo) => {
  const options = {
    body: {
      session_id: sessionId,
      phone_number: phoneNo
    }
  }
  return await API.post('guest', '/sms/send-verify-code', options);
}

export default AttendanceContentPaymentSelect;
