import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';

import AttendeeLayout from 'components/AttendeeLayout';
import * as Constants from "../../constants";
import {getBlob} from "../../utils/request";
import { isIOS } from 'utils/device';

export const ReceiptDownload = (props) => {
  const { location } = props;
  const queryString = new URLSearchParams(location.search);

  const [showBtnDownloadSagawa, setShowBtnDownloadSagawa] = useState(false);
  const [showBtnDownloadItowa, setShowBtnDownloadItowa] = useState(false);

  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;
  const orderId = queryString.get("orderId");
  const type = queryString.get("type");

  useEffect(() => {
    if (type === 'itowa' || type === 'sagawa') {
      handleDownload(funeralId, orderId, type);
    }
  }, [funeralId, orderId, type]);

  useEffect(() => {
    getOrder(funeralId, orderId).then((res) => {
      if (res?.Item?.some((item) => item?.ItemType === 'sagawa_telegram')) {
        setShowBtnDownloadSagawa(true);
      }
      if (res?.Item?.some((item) => item?.ItemType !== 'sagawa_telegram')) {
        setShowBtnDownloadItowa(true);
      }
    });

    // eslint-disable-next-line
  }, []);


  const handleDownload = (funeralId, orderId, type) => {
    download(funeralId, orderId, type);
  }

  return (
    <AttendeeLayout>
      <Helmet>
        <title>領収書ダウンロード | itowa</title>
      </Helmet>
      <div id="complete">
        <h2 className="item-title-horizontal">領収書ダウンロード</h2>
        <p className="mx-4 fs-4">
          領収書をダウンロードしています... <br/>
          ダウンロードが始まらない場合は以下のボタンを押してください。
        </p>
        {showBtnDownloadItowa && <button className="btn btn-primary set-password-button" onClick={() => handleDownload(funeralId, orderId, 'itowa')}>領収書ダウンロード</button>}
        {showBtnDownloadSagawa && <button className="btn btn-primary set-password-button" onClick={() => handleDownload(funeralId, orderId, 'sagawa')}>領収書（弔電）ダウンロード</button>}
      </div>
    </AttendeeLayout>
  );
};

const getOrder = async (funeralId, orderId) => {
  const options = {
    queryStringParameters: {
      funeralId: funeralId,
      orderId: orderId
    }
  };
  return await API.get('guest', '/order', options);
}

const download = (funeralId, orderId, type) => {
  const options = {
    queryStringParameters: {
      orderId: orderId,
      downloadFile: type,
    }
  }
  API.get(
      'guest',
      `/receipt/${funeralId}/create`, options
  ).then(async res => {
    if(Array.isArray(res)) {
      res?.forEach(async (file) => {
        if (file?.filename) {
          if (isIOS()) {
            window.location.href = Constants.IMAGE_BUCKET_URL + file.filename;
          } else {
            const pdfFile = await getBlob(Constants.IMAGE_BUCKET_URL + file.filename, { method: 'GET' });
            saveAs(pdfFile, '領収書' + (type === 'sagawa' ? '_弔電' : '') + moment(res.orderDate).format("YYYY-MM-DD") + ".pdf");
          }
        }
      });
    } else {
      if (res.filename) {
        if (isIOS()) {
          window.location.href = Constants.IMAGE_BUCKET_URL + res.filename;
        } else {
          const pdfFile = await getBlob(Constants.IMAGE_BUCKET_URL + res.filename, { method: 'GET' });
          saveAs(pdfFile, '領収書' + (type === 'sagawa' ? '_弔電' : '') + moment(res.orderDate).format("YYYY-MM-DD") + ".pdf");
        }
      }
    }
  });
}

export default ReceiptDownload;