import { useState, useEffect, useContext } from 'react';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet-async';
import { appConfig } from 'app-config';

import AttendeeLayout from 'components/AttendeeLayout';
import CartTotal from 'components/CartTotal';
import { getCartContents, checkLimit } from '../../components/Cart';

import {
  Elements,
  useStripe,
  useElements,
  PaymentElement
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { AuthContext } from '../../components/Contexts';
import { useLoading } from 'components/Loading';
import { Button, Modal } from 'react-bootstrap';
import { MESSAGE_FEE } from '../../constants';

export const AttendanceContentPayment = (props) => {
  const funeralId = props.match.params.funeralId;
  const history = useHistory();

  const { userInfo } = useContext(AuthContext);
  const stripePromise = loadStripe(appConfig.stripePublicKey);

  const [elementOptions, setElementOptions] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [selectedItem, setSelectedItem] = useState([]);
  const [limitDateTime, setLimitDateTime] = useState("");
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(true);

  useEffect(() => {
    const appearance = {
      theme: 'stripe',
      rules: {
        ".Label": {
          fontFamily: '"游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif',
          fontWeight: 500
        },
        ".Input": {
          border: "1px solid #42464C",
          borderRadius: "5px"
        }
      }
    };
    const get = async () => {
      const result = await getDisplayItem(funeralId);
      const checkResult = await checkLimit(funeralId, result.itemLimit, result.koudenLimit);
      const cart = await getCartContents(funeralId);
      if (!checkResult) {
        history.push("/" + funeralId + "/attendance/content/select/kouden");
      }
      setSelectedItem(cart);
      setLimitDateTime({itemLimit: result.itemLimit, koudenLimit: result.koudenLimit});

      let ls = JSON.parse(localStorage.getItem(funeralId));
      if (ls.paymentIntent && ls.paymentIntent !== "") {
        setPaymentIntent(ls.paymentIntent);
      } else {
        const total = cart.reduce((previous, current) => {
          let amount = parseInt(current.price, 10);
          // 香典の場合は香典システム手数料を加算
          if (current.itemType === "money") amount = amount + KoudenCommission(current.price) + MESSAGE_FEE;
          return previous + amount;
        }, 0);
        const feeTotal = Math.ceil(cart.reduce((previous, current) => previous + current.fee, 0));
        const pi = await getPaymentIntent(funeralId, total, feeTotal);
        setPaymentIntent(pi.paymentIntent);
        ls.paymentIntent = pi.paymentIntent;
        localStorage.setItem(
          funeralId,
          JSON.stringify(ls)
        );
      }

      setElementOptions({
        clientSecret: ls.paymentIntent.client_secret,
        appearance: appearance
      })
    };
    get();
  }, [funeralId, history]);



  return (
    <AttendeeLayout>
      <Helmet>
        <title>お支払い | itowa</title>
      </Helmet>
      <h2 className="title-horizontal" style={{ width: "14rem" }}>お支払い</h2>
      <div style={{ width: "90%", maxWidth: "1200px", margin: "0 auto" }}>
        {
          elementOptions &&
          <Elements stripe={stripePromise} options={elementOptions}>
            <CheckoutForm
              userInfo={userInfo}
              funeralId={funeralId}
              paymentIntent={paymentIntent}
              selectedItem={selectedItem}
              limitDateTime={limitDateTime}
              {...props} />
          </Elements>
        }
      </div>
      <Modal show={showDisclaimerModal} centered={true}>
        <Modal.Header closeButton={true} onHide={() => setShowDisclaimerModal(false)}>
          <div style={{marginLeft: "auto", marginRight: "auto", paddingLeft: "calc(1rem + 32px)"}}>
            <Modal.Title>＜注文キャンセルについて＞</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{marginLeft: "5%", marginRight: "5%"}}>
            <ul>
              <li>「弔文・供物」は葬儀の特性上、注文完了されると葬儀社が直ちに手配を行います。</li>
              <li>香典はリアルタイムで葬家様へ反映されます。</li>
            </ul>
            <p>以上のことからご注文後のキャンセルは一切応じておりません。ご注文内容についてご確認の上、決済手続きをお願い申し上げます。</p>
            <div style={{width: "100%", textAlign: "center"}}>
              <Button onClick={() => setShowDisclaimerModal(false)}
                      style={{width: "60%", marginLeft: "auto", marginRight: "auto", border: "0.1rem solid rgba(170, 170, 170, 0.8)"}}
                      variant={"secondary"}>
                確認
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </AttendeeLayout>
  );
};


const CheckoutForm = (props) => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const { funeralId, selectedItem, userInfo, paymentIntent, limitDateTime } = props;
  const loading = useLoading();
  const [errorMessage, setErrorMessage] = useState("");
  const { setUserInfo } = useContext(AuthContext);

  const { location } = props;
  const queryString = new URLSearchParams(location.search);
  const isOrderOnly = queryString.get("isOrderOnly") === "true";

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const now = moment().tz('Asia/Tokyo').format("YYYY-MM-DD HH:mm");
    if (limitDateTime.itemLimit < now || limitDateTime.koudenLimit < now) {
      localStorage.removeItem(funeralId);
      history.push("/" + funeralId + "/attendance/content/select/kouden?isOrderOnly=" + isOrderOnly);
    }
    try {
      loading(true);
      // Ensure elements.submit() is called first
      const submitResult = await elements.submit();
      if (submitResult?.error) {
        setErrorMessage(submitResult?.error?.message);
        return;
      }
      const result = await stripe.createConfirmationToken({elements})
      if (result?.error) {
        setErrorMessage(result?.error?.message);
        return;
      }
      const cart = JSON.parse(localStorage.getItem(funeralId));
      const confirmationToken = result?.confirmationToken?.id;
      const orderResult = userInfo
        ? await createOrder(
          paymentIntent.id,
          funeralId,
          userInfo?.orderEmail || userInfo?.email,
          selectedItem,
          confirmationToken,
          isOrderOnly,
          'product'
        )
        : await createOrder(
          paymentIntent.id,
          funeralId,
          cart.email,
          selectedItem,
          confirmationToken,
          isOrderOnly,
          'guest'
        );

      if(orderResult && orderResult.result) {
        localStorage.removeItem(funeralId);

        if (userInfo) {
          // 最新の認証情報を取り直す
          const options = {
            queryStringParameters: {
              funeralId: funeralId,
              companyId: ""
            }
          };
          const userInfo = await API.get("product", "/auth", options);
          setUserInfo(userInfo);
          history.push("/" + funeralId + `/attendance/complete?orderId=${paymentIntent.id}&isOrderOnly=${isOrderOnly}`);
        } else {
          history.push(`/${funeralId}/attendance/complete?email=${cart.email}&orderId=${paymentIntent.id}&isOrderOnly=${isOrderOnly}`);
        }
      } else {
        if(orderResult) {
          setErrorMessage(orderResult.message);
        } else {
          setErrorMessage("システムエラーが発生しました。")
        }
      }
    } finally {
      loading(false);
    }

  };

  return (
    <form id="payment">
      <div className="payment-panel">
        <div className="group card-info">
          <p className="p-crypted">
            全ての取引は暗号化されています
          </p>
          <PaymentElement />
          {
            errorMessage &&
            <div className="error-message">
              {errorMessage}
            </div>
          }
        </div>
      </div>
      <div className="cart-total-panel">
        <div className="group">
          <CartTotal selectedItem={selectedItem}>
            <button type="button" className="btn btn-primary button-to-payment" onClick={handleSubmit}>今すぐお支払い</button>
          </CartTotal>
        </div>
        <button type="button" className="btn btn-primary button-to-return" onClick={() => history.goBack()}>参列者様情報の入力に戻る</button>
      </div>
    </form>
  );
}

const createOrder = async (paymentIntentId, funeralId, email, selectedItem, confirmationToken, isOrderOnly, type) => {
  const options = {
    body: {
      paymentIntentId: paymentIntentId,
      funeralId: funeralId,
      email: email,
      selectedItem: selectedItem,
      confirmationToken: confirmationToken,
      receiptName: localStorage.getItem("receiptName"),
      isFromOrderForm: isOrderOnly
    }
  }
  
  try {
    await API.post(type, '/orders', options);
    return {
      result: true
    };
  } catch (e) {
    const itemSagawa = selectedItem.filter((item) => item.itemType === "sagawa_telegram")
    // Hanlde show error message for sagawa product
    if (itemSagawa?.length > 0 && e?.response?.status === 400) {
      return {
        result: false,
        message: e?.response?.data?.error || "システムエラーが発生しました"
      }
    }
    if(e === "Error: Network Error") {
      return {
        result: false,
        message: "問題が発生しました。インターネット接続をご確認の上、もう一度お試しください。"
      }
    }
  }
}

const getPaymentIntent = async (funeralId, amount, fee) => {
  const options = {
    body: {
      funeralId: funeralId,
      amount: amount,
      fee: fee
    }
  };

  return await API.post('guest', '/charge', options);
}

const getDisplayItem = async (funeralId) => {
  const options = {
    queryStringParameters: { funeralId: funeralId }
  }

  return await API.get('guest', '/funerals/item/template', options);
}

// 香典手数料を取得する
const KoudenCommission = (price) => {
  switch (price) {
    case 3000:
      return 330;
    case 5000:
      return 495;
    case 10000:
      return 891;
    case 20000:
      return 1650;
    case 30000:
      return 2200;
    case 50000:
      return 3300;
    case 70000:
      return 4400;
    case 100000:
      return 5500;
    default:
      return 0;
  }
}

export default AttendanceContentPayment;