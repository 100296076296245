import React, { useState, useEffect, useContext } from 'react';
import { API } from 'aws-amplify';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

import { AttendanceContext, AuthContext } from 'components/Contexts';

import AttendeeLayout from 'components/AttendeeLayout';
import { setCartItem, removeCartItem, getCartContents, checkLimit } from '../../components/Cart';
import { Img } from 'components/Img';
import { shuffle } from 'utils/shuffle';

export const AttendanceContentSelectKouden = (props) => {
  const [koudenList, setKoudenList] = useState([]);
  const [choudenList, setChoudenList] = useState([]);
  const [kumotsuList, setKumotsuList] = useState([]);
  const [kyoukaList, setKyoukaList] = useState([]);
  const [, setKoudenId] = useState("");
  const [selectKouden, setSelectKouden] = useState(false);

  const { funeralInfo } = useContext(AttendanceContext);
  const { userInfo } = useContext(AuthContext);

  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;
  const history = useHistory();

  const { location } = props;
  const queryString = new URLSearchParams(location.search);
  const isOrderOnly = queryString.get("isOrderOnly") === "true";

  useEffect (() => {
    if(!funeralInfo?.enableMoneyFlow || userInfo?.isChiefMourner) {
      history.push(`/${funeralId}/`);
    }

    // eslint-disable-next-line
  }, [userInfo, funeralInfo])

  useEffect(() => {
    const fetch = async () => {
      const result = await getDisplayItem(funeralId);
      if (!result.koudenList.length) history.push(`/${funeralId}/`)
      await checkLimit(funeralId, result.itemLimit, result.koudenLimit);
      let cart = await getCartContents(funeralId);
      cart.kouden = cart.filter((row) => row.itemType === "money");
      cart.chouden = cart.filter((row) => row.itemType === "telegram");

      let choudenList = result.choudenList;
      for (let i = 0; i < choudenList.length; i++) {
        if (cart.chouden.length > 0) {
          for (let j = 0; j < cart.chouden.length; j++) {
            if (choudenList[i].itemId === cart.chouden[j].itemId) {
              choudenList[i].flag = true;
              break;
            }
            choudenList[i].flag = false
          }
        } else {
          choudenList[i].flag = false
        }
      }

      if (cart.kouden.length > 0) setKoudenId(cart.kouden[0].itemId);
      setKoudenList(result.koudenList.map((k, i) => {
        return {
          itemId: k.ItemId,
          itemName: k.ItemName,
          itemType: "money",
          price: k.Price,
          displayPrice: k.DisplayPrice,
          fee: k.Fee,
          imagePath: k.ImagePath
        }
      }));
      setChoudenList(choudenList);
      setKumotsuList(result.kumotsuList);
      setKyoukaList(result.kyoukaList);
    }
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, funeralId]);

  const onKoudenSelect = (koudenType) => {
    const money003000 = koudenList.filter(kouden => kouden.itemId === "money003000")[0];
    const money005000 = koudenList.filter(kouden => kouden.itemId === "money005000")[0];
    const money010000 = koudenList.filter(kouden => kouden.itemId === "money010000")[0];
    const money030000 = koudenList.filter(kouden => kouden.itemId === "money030000")[0];
    const money050000 = koudenList.filter(kouden => kouden.itemId === "money050000")[0];
    const money100000 = koudenList.filter(kouden => kouden.itemId === "money100000")[0];

    if (money003000) removeCartItem(funeralId, money003000);
    if (money005000) removeCartItem(funeralId, money005000);
    if (money010000) removeCartItem(funeralId, money010000);
    if (money030000) removeCartItem(funeralId, money030000);
    if (money050000) removeCartItem(funeralId, money050000);
    if (money100000) removeCartItem(funeralId, money100000);

    if (koudenType === "") {
      setSelectKouden(false);
      return;
    }

    setSelectKouden(true);
    const row = koudenList.filter(kouden => kouden.itemId === koudenType)[0];
    if (row) {
      setCartItem(funeralId, row);
    }
  }

  return (
    <AttendeeLayout>
      <Helmet>
        <title>参列選択（香典・弔文） | itowa</title>
      </Helmet>
      <form>
        <h2 className="item-title">香典</h2>
        {
          koudenList.length > 0 ?
            <>
              <div className="koudenPriceSelect">
                <div>
                  <div className="item-photos koudenPhoto">
                    <Img className="koudenImg" src={koudenList[0].imagePath}></Img>
                  </div>
                  <p className="koudenPriceSelectTitle fs-3">香典</p>
                </div>
                <div className="koudenHr">
                  <hr/>
                </div>

                <div className="koudenPriceSelectAnnounce">
                  <u>下記で香典を贈る金額を選択してください</u>
                </div>
                <br/>

                <div>
                  <label className="fs-3 koudenPriceSelectLabel"><input className="koudenPriceSelectButton" type="radio" name="kouden" value="Unselected" onClick={() => onKoudenSelect("")}/>　未選択</label>
                </div>
                <div>
                  <label className="fs-3 koudenPriceSelectLabel"><input className="koudenPriceSelectButton" type="radio" name="kouden" value="3,000" onClick={() => onKoudenSelect("money003000")}/>　3,000円</label>
                </div>
                <div>
                  <label className="fs-3 koudenPriceSelectLabel"><input className="koudenPriceSelectButton" type="radio" name="kouden" value="5,000" onClick={() => onKoudenSelect("money005000")}/>　5,000円</label>
                </div>
                <div>
                  <label className="fs-3 koudenPriceSelectLabel"><input className="koudenPriceSelectButton" type="radio" name="kouden" value="10,000" onClick={() => onKoudenSelect("money010000")}/>　10,000円</label>
                </div>
                <div>
                  <label className="fs-3 koudenPriceSelectLabel"><input className="koudenPriceSelectButton" type="radio" name="kouden" value="30,000" onClick={() => onKoudenSelect("money030000")}/>　30,000円</label>
                </div>
                <div>
                  <label className="fs-3 koudenPriceSelectLabel"><input className="koudenPriceSelectButton" type="radio" name="kouden" value="50,000" onClick={() => onKoudenSelect("money050000")}/>　50,000円</label>
                </div>
                <div>
                  <label className="fs-3 koudenPriceSelectLabel"><input className="koudenPriceSelectButton" type="radio" name="kouden" value="100,000" onClick={() => onKoudenSelect("money100000")}/>　100,000円</label>
                </div>
                <br/>
                <div className="selectKoudenCheck">
                  { selectKouden === false ?
                    <div>
                      <div className="selectKoudenUncheckButton fs-3">選択する</div>
                    </div>
                    :
                    <div>
                      <div className="selectKoudenCheckedButton fs-3">選択されました</div>
                    </div>
                  }
                  <br/>
                </div>

              </div>
              <br/>

              <div>
                <p className="koudenPriceSelectBottomInfo">香典は直接、葬家へ送金されます</p>
              </div>

              <Button className="btn-to-confirm" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/confirm?isOrderOnly=" + isOrderOnly)}>お支払いに進む</Button>
            </>
            :
            <>ご選択項目は現在注文可能な商品がありません</>
        }
        {
          (choudenList.length > 0 || kumotsuList.length > 0 || kyoukaList.length > 0) &&
          <>
            <div className="item-sample">
            <div className="item-side-scroll">
                {
                  shuffle(choudenList).map((item, index) =>
                    <div key={index} className="item-photos">
                      <Img src={item.imagePath} />
                    </div>
                  )
                }
              </div>
              <Button className="btn-to-flower-and-things" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/select/chouden?isOrderOnly=" + isOrderOnly)}>弔文を贈る</Button>
              <div className="item-side-scroll">
                {
                  shuffle(kumotsuList).map((item, index) =>
                    <div key={index} className="item-photos">
                      <Img src={item.imagePath} />
                    </div>
                  )
                }
              </div>
              <Button className="btn-to-flower-and-things" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/select/kumotsu?isOrderOnly=" + isOrderOnly)}>お供花を贈る</Button>
              <div className="item-side-scroll">
                {
                  shuffle(kyoukaList).map((item, index) =>
                    <div key={index} className="item-photos">
                      <Img src={item.imagePath} />
                    </div>
                  )
                }
              </div>
            </div>
            <Button className="btn-to-flower-and-things" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/select/kumotsu?isOrderOnly=" + isOrderOnly)}>お供物を贈る</Button>
          </>
        }
      </form>
    </AttendeeLayout>
  );
};

const getDisplayItem = async (funeralId) => {
  const options = {
    queryStringParameters: { funeralId: funeralId }
  }

  return await API.get('guest', '/funerals/item/template', options);
}

export default AttendanceContentSelectKouden;