export const FuneralRegistrationError = () => {
  return (
    <div id="auth-main">
      <div id="auth-common">
        <img className="logo" src="/images/itowa-logo-square.jpg" alt="logo"></img>
        <div className="title">キャンセル完了</div>
        <p>葬儀日程の受取をキャンセルしました。再度行う場合は、QRコードの読込をお願いします。</p>
      </div>
    </div>
  )
}

export default FuneralRegistrationError;
