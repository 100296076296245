import React from 'react'
import '../css/Common.scss';
import TermsDocument from 'components/TermsDocument';

function TermsDocumentPage() {
  return (
    <div>
      <TermsDocument />
    </div>
  )
}

export default TermsDocumentPage