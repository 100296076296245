import { useState, useEffect, useContext } from 'react';
import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import { Row, Col } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useLoading } from 'components/Loading';
import { AuthContext } from '../../components/Contexts';

export const Main = () => {
  const [sales, setSales] = useState(0);
  const [attendees, setAttendees] = useState(0);
  const { userInfo } = useContext(AuthContext);
  const loading = useLoading();
  const history = useHistory();

  useEffect(() => {
    if(userInfo.admin.Role !== "manager") {
      history.push("/obituary/search");
    }
    const fetch = async () => {
      loading(true);
      const result = await getSalesInfo();

      setSales(parseInt(result.sales, 10).toLocaleString());
      setAttendees(parseInt(result.attendees, 10).toLocaleString());
      loading(false);
    }
    fetch();
    // eslint-disable-next-line
  }, []);

  return (
    <CommonLayout>
      <ContentHeader>
        ダッシュボード
      </ContentHeader>
      <Row>
        <Col md="3">
          <div className="small-box bg-info">
            <div className="inner">
              <h3>{sales}円</h3>

              <p>今月の総売上</p>
            </div>
            <div className="icon">
              <i className="fas fa-shopping-bag"></i>
            </div>
            <Link className="small-box-footer" to="/statistics">More info <i className="fas fa-arrow-circle-right"></i></Link>
          </div>
        </Col>
        <Col md="3">
          <div className="small-box bg-warning">
            <div className="inner">
              <h3>{attendees}人</h3>

              <p>今月の参列者数</p>
            </div>
            <div className="icon">
              <i className="fas fa-user-tie"></i>
            </div>
            <Link className="small-box-footer" to="/statistics">More info <i className="fas fa-arrow-circle-right"></i></Link>
          </div>
        </Col>
      </Row>
    </CommonLayout>
  );
};

const getSalesInfo = async () => {
  return await API.get('product', '/statistics/dashboard');
}

export default Main;