import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { AttendanceContext } from './Contexts';

const ModalRecieveMoney = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const { funeralInfo } = useContext(AttendanceContext);
  const match = useRouteMatch("/:funeralId");
  const prefix = "/" + match.params.funeralId;
  const currentFilter = "money";

  useEffect(() => {
    if(funeralInfo?.showNotifyConfirmMoney) {
      setShow(true)
    }
    // eslint-disable-next-line
  }, [funeralInfo]);

  const goToAttendeeScreen = () => {
    setShow(false)
    history.push(prefix + "/users/attendee", currentFilter)
  }

  return (
    <React.Fragment>
      <Modal show={show} centered={true} className='funeralRecieveMoney' onHide={() => setShow(false)}>
      <Modal.Header closeButton={true}></Modal.Header>
        <Modal.Body>
          <div className="boxHeader">
            <img className="giftLogo" src="/images/icon-funeral-gift.png" alt="" />
            <p className="dl-title">オンライン香典</p>
          </div>
          <p className="dl-content-money">香典申込を頂いております。<br/>受け取りの意思表示をお願いいたします。<br/>
          香典申込者のリストとメッセージを以下からご確認ください。</p>
          <button type="button" className="btn-navigate" onClick={() => goToAttendeeScreen()}>香典申込リストを確認する</button>
          <div className="btn-link">
            <a href="https://itowa71.com/itowakouden" target="_blank" rel="noreferrer">サービスを詳しく理解する</a>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default ModalRecieveMoney;
