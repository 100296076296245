import * as Constants from './../../constants';

export const Prefecture = ({name, className, formik, onChange, ...props }) => {

  const handleChange = (e) => {
    if(onChange){
      const prefecture = Constants.PREFECTURES.find((item) => item.key === e?.target?.value)
      onChange({
      prefecture: prefecture
      })
    } else {
      formik.setFieldValue(name, e?.target?.value);
    }
  }

  return (
    <>
      <select name={name} className={"custom-select" + (formik.errors[name] ? " is-invalid" : "")} onChange={handleChange} value={formik.values[name]} {...props}>
        <option value=""></option>
        {
          Constants.PREFECTURES.map((p, i) => {
            return (
              <option key={i} value={p.key}>{p.value}</option>
            );
          })
        }
      </select>
      <div className="invalid-feedback">{formik.errors[name]}</div>
    </>
  )
}

export const prefCodeToName = (code) => {
  if(code) {
    const results = Constants.PREFECTURES.filter((e) => e.key === code);
    if (results.length > 0) {
      return results[0].value;
    } else {
      return "";
    }
  }
}

export const prefCodeToKana = (code) => {
  if(code) {
    const results = Constants.PREFECTURES.filter((e) => e.key === code);
    if (results.length > 0) {
      return results[0].kana;
    } else {
      return "";
    }
  }
}

export const prefNameToCode = (name) => {
  if(name) {
    const results = Constants.PREFECTURES.filter((e) => e.value === name);
    if(results.length > 0) {
      return results[0].key;
    } else {
      return "";
    }
  }
}

export default Prefecture;