import ExcelJS from 'exceljs';
import encoding from 'encoding-japanese';

export const AttendeeDownloadCSV = ({ currentTab, attendeeUserList }) => {
  const generateWorkbook = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet("sheet1");
    return workbook.getWorksheet("sheet1");
  };

  const createCSV = async (worksheet, columns, dataList, filename) => {
    worksheet.columns = columns;
    dataList.forEach((data) => {
      if (data.PhoneNo) {
        data.PhoneNo = `\t${data.PhoneNo}`;
      }
      worksheet.addRow(data);
    });

    const uint8Array = new Uint8Array(
      encoding.convert(await worksheet.workbook.csv.writeBuffer(), {
        from: "UTF8",
        to: "SJIS"
      })
    );
    const blob = new Blob([uint8Array], { type: "application/octet-binary" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    a.remove();
  };

  const csvAttendee = async () => {
    const worksheet = generateWorkbook();
    const columns = [
      { header: "返礼品注文日", key: "OrderReturnGiftDate" },
      { header: "法人名", key: "CompanyName" },
      { header: "氏名", key: "OrderUserName" },
      { header: "郵便番号", key: "ZipCode" },
      { header: "住所", key: "FormatAddress" },
      { header: "関係性", key: "Relation" },
      { header: "年代", key: "Age" },
      { header: "性別", key: "Gender" },
      { header: "参列日時", key: "AttendDateTime" },
      { header: "香典金額", key: "GiftMoney" },
      { header: "電話番号", key: "PhoneNo" },
    ];

  const maxAccompanyCount = attendeeUserList?.reduce((max, attendee) => 
    Math.max(max, attendee.Accompanies?.length || 0), 0);

  for (let i = 0; i < maxAccompanyCount; i++) {
    columns.push({ header: `連名${i + 1}`, key: `AccompanyName${i + 1}` });
  }
    const mappedAttendeeList = attendeeUserList?.map(attendee => {
      const newAttendee = { ...attendee };
      attendee.Accompanies?.forEach((accompany, index) => {
        newAttendee[`AccompanyName${index + 1}`] = accompany.name;
      });
      return newAttendee;
    });
  await createCSV(worksheet, columns, mappedAttendeeList, "参列者リスト.csv");
  };

  const csvOrderGift = async () => {
    const worksheet = generateWorkbook();
    const columns = [
      { header: "返礼品注文日", key: "OrderReturnGiftDate" },
      { header: "法人名", key: "CompanyName" },
      { header: "注文者名", key: "UserName" },
      { header: "氏名", key: "OrderUserName" },
      { header: "郵便番号", key: "ZipCode" },
      { header: "住所", key: "FormatAddress" },
      { header: "関係性", key: "Relation" },
      { header: "年代", key: "Age" },
      { header: "性別", key: "Gender" },
      { header: "注文日時", key: "OrderDate" },
      { header: "注文内容", key: "OrderItemType" },
      { header: "金額", key: "OrderMoney" },
      { header: "アドレス", key: "Email" },
      { header: "電話番号", key: "PhoneNo" },
    ];
    await createCSV(worksheet, columns, attendeeUserList, "注文者リスト.csv");
  };

  const download = () => {
    if (currentTab === "reception") {
      csvAttendee();
    } else {
      csvOrderGift();
    }
  };

  return (
    <div className="button-download-csv-wrapper">
      <button type="button" className="btn btn-primary white-button download-csv-button" onClick={() => download()}>CSVダウンロード</button>
      <p className="sub-title-page">LINEブラウザ内ではダウンロードが出来ません。</p>
    </div>
  )
}

export default AttendeeDownloadCSV;