import React, { useState, useEffect, useContext } from 'react';
import { API } from 'aws-amplify';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

import AttendeeLayout from 'components/AttendeeLayout';
import { setCartItem, removeCartItem, getCartContents, checkLimit } from '../../components/Cart';
import ItemCell from 'components/CustomForms/ItemCell';
import { Img } from 'components/Img';
import { shuffle } from 'utils/shuffle';
import ModalTermSagawa from 'components/ModalTermSagawa';

import {AttendanceContext, AuthContext} from "../../components/Contexts";

export const AttendanceContentSelectChouden = (props) => {
  const [koudenList, setKoudenList] = useState([]);
  const [choudenList, setChoudenList] = useState([]);
  const [sagawaList, setSagawaList] = useState([]);
  const [kumotsuList, setKumotsuList] = useState([]);
  const [kyoukaList, setKyoukaList] = useState([]);
  const [, setKoudenId] = useState("");
  const [isShowSagawaItem, setShowSagawaItem] = useState(false);
  const [showTermDialog, setShowTermDialog] = useState(false);

  const { funeralInfo } = useContext(AttendanceContext);
  const { userInfo } = useContext(AuthContext);

  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;
  const history = useHistory();

  const { location } = props;
  const queryString = new URLSearchParams(location.search);
  const isOrderOnly = queryString.get("isOrderOnly") === "true";

  useEffect(() => {
    const fetch = async () => {
      const result = await getDisplayItem(funeralId);
      await checkLimit(funeralId, result.itemLimit, result.koudenLimit);
      let cart = await getCartContents(funeralId);
      cart.kouden = cart.filter((row) => row.itemType === "money");
      cart.chouden = cart.filter((row) => row.itemType === "telegram");
      cart.sagawa_telegram = cart.filter((row) => row.itemType === "sagawa_telegram");

      let choudenList = result.choudenList;
      for (let i = 0; i < choudenList.length; i++) {
        if (cart.chouden.length > 0) {
          for (let j = 0; j < cart.chouden.length; j++) {
            if (choudenList[i].itemId === cart.chouden[j].itemId) {
              choudenList[i].flag = true;
              break;
            }
            choudenList[i].flag = false
          }
        } else {
          choudenList[i].flag = false
        }
      }

      let sagawaList = result.sagawaList;
      for (let i = 0; i < sagawaList.length; i++) {
        if (cart.sagawa_telegram.length > 0) {
          for (let j = 0; j < cart.sagawa_telegram.length; j++) {
            if (sagawaList[i].itemId === cart.sagawa_telegram[j].itemId) {
              sagawaList[i].flag = true;
              break;
            }
            sagawaList[i].flag = false
          }
        } else {
          sagawaList[i].flag = false
        }
      }

      if (cart.kouden.length > 0) setKoudenId(cart.kouden[0].itemId);
      setKoudenList(result.koudenList.map((k, i) => {
        return {
          itemId: k.ItemId,
          itemName: k.ItemName,
          itemType: "money",
          price: k.Price,
          displayPrice: k.DisplayPrice,
          fee: k.Fee,
          imagePath: k.ImagePath
        }
      }));
      setChoudenList(choudenList);
      setSagawaList(sagawaList);
      setKumotsuList(result.kumotsuList);
      setKyoukaList(result.kyoukaList);
      setShowSagawaItem(result?.enableSagawaItem || false);
    }
    fetch();
  }, [props, funeralId]);

  const ButtonPayment = () => {
    if(sagawaList.length > 0 || choudenList.length > 0) {
      return  (
        <div className="btn-to-confirm-wrapper-chouden">
          <Button className="btn-to-confirm" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/confirm?isOrderOnly=" + isOrderOnly)}>お支払いに進む</Button>
        </div>
      )
    }
    return null
  }
  return (
    <AttendeeLayout>
      <Helmet>
        <title>参列選択（弔文） | itowa</title>
      </Helmet>
      <form>
        <div>
          <h2 className="item-title font-weight-bold item-chouden">弔　弔<br />文　電</h2>
          <ButtonPayment/>
          {
            choudenList.length > 0 ?
              <>
                <Row className="item-grids">
                  {
                    choudenList.map((row, index) => {
                      return (
                        <Col key={index} xl={3} lg={6} md={6} sm={6} xs={6}>
                          <ItemCell key={index} item={row} countable={false} flag={row.flag} onChange={(flag) => {
                            if (flag) {
                              setCartItem(funeralId, row);
                            } else {
                              removeCartItem(funeralId, row);
                            }
                          }} />
                        </Col>
                      )
                    })
                  }
                </Row>
              </>
              :
              <p>ご選択項目は現在注文可能な商品がありません</p>
          }
          <div style={{ marginBottom: "5rem" }}>&nbsp;</div>
        </div>
        <div>
          {isShowSagawaItem && (
            <>
            <div className="btn-to-confirm-wrapper">
              <Button className="btn-to-confirm" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/confirm?isOrderOnly=" + isOrderOnly)}>お支払いに進む</Button>
            </div>
            <div className="item-title item-chouden-img">
              <img src={"/images/verrycard.svg"} alt="" />
            </div>
            <div className="link-sagawa-container">
              <p><a className="link-sagawa-popup" href={() => false} onClick={() => setShowTermDialog(true)}>特定商取引法に基づく表記</a></p>
              <p className="text-sagawa">下記の弔電に関するお問い合わせ </p>
              <a className="link-sagawa-popup text-sagawa link-sagawa-phone" href="tel:0570056115">0570-056-115</a>
            </div>
            {showTermDialog && <ModalTermSagawa showDialog={showTermDialog} setShowTermDialog={setShowTermDialog}/>}
              {
                sagawaList.length > 0 ?
                  <>
                    <Row className="item-grids">
                      {
                        sagawaList.map((row, index) => {
                          return (
                            <Col key={index} xl={3} lg={6} md={6} sm={6} xs={6}>
                              <ItemCell key={index} item={row} countable={false} flag={row.flag} onChange={(flag) => {
                                if (flag) {
                                  setCartItem(funeralId, row);
                                } else {
                                  removeCartItem(funeralId, row);
                                }
                              }} />
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </>
                  :
                  <p>ご選択項目は現在注文可能な商品がありません</p>
              }
              <div style={{ marginBottom: "5rem" }}>&nbsp;</div>
            </>
          )}
          <ButtonPayment/>

        </div>
        {
          (koudenList.length > 0 || choudenList.length > 0) &&
          <>
            <div className="item-sample">
              {
                koudenList.length > 0 &&
                funeralInfo?.enableMoneyFlow && 
                !userInfo?.isChiefMourner && 
                !userInfo?.attendees?.Relatives &&
                (
                  <>
                    <div className="item-side-scroll">
                      {
                        shuffle(koudenList).map((item, index) =>
                          <div key={index} className="item-photos">
                            <Img src={item.imagePath} />
                          </div>
                        )
                      }
                    </div>
                    <div className="btn-to-flower-and-things-wrapper">
                      <Button className="btn-to-flower-and-things" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/select/kouden?isOrderOnly=" + isOrderOnly)}>香典を送る</Button>
                      <span className="chevron-right-thin"></span>
                    </div>
                  </>
                )
              }
              <div className="item-side-scroll">
                {
                  shuffle(kumotsuList).map((item, index) =>
                    <div key={index} className="item-photos">
                      <Img src={item.imagePath} />
                    </div>
                  )
                }
              </div>
              <div className="btn-to-flower-and-things-wrapper">
                <Button className="btn-to-flower-and-things" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/select/kumotsu?isOrderOnly=" + isOrderOnly)}>お供花を贈る</Button>
                <span className="chevron-right-thin"></span>
              </div>
              <div className="item-side-scroll">
                {
                  shuffle(kyoukaList).map((item, index) =>
                    <div key={index} className="item-photos">
                      <Img src={item.imagePath} />
                    </div>
                  )
                }
              </div>
            </div>
            <div className="btn-to-flower-and-things-wrapper">
              <Button className="btn-to-flower-and-things" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/select/kumotsu?isOrderOnly=" + isOrderOnly)}>お供物を贈る</Button>
              <span className="chevron-right-thin"></span>
            </div>
          </>
        }
      </form>
    </AttendeeLayout>
  );
};

const getDisplayItem = async (funeralId) => {
  const options = {
    queryStringParameters: { funeralId: funeralId }
  }

  return await API.get('guest', '/funerals/item/template', options);
}

export default AttendanceContentSelectChouden;