import React, { useContext } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

import { AttendanceContext } from 'components/Contexts';

const ModalFuneralMoney = ({ onClosePopup = () => {} }) => {
  const history = useHistory();
  const match = useRouteMatch("/:funeralId");
  const { showFuneralMoney, setShowFuneralMoney } = useContext(AttendanceContext);

  const funeralId = match.params.funeralId;

  const onClose = () => {
    localStorage.setItem(`${funeralId}_showPopupMoney`, '1');
    onClosePopup();
    setShowFuneralMoney(false);
    
  };

  const handleSubmit = () => {
    onClose();
    history.push("/" + funeralId + "/attendance/content/select/kouden")
  };

  return (
    <React.Fragment>
      <Modal show={showFuneralMoney} centered={true} className='funeralMoneyWrapper'>
        <Modal.Header/>
        <Modal.Body>
          <div className="box-header box-apply">
            <h4>オンライン香典</h4>
            <p className="dl-text-apply">
              訃報サービス「itowa」からのご提案<br />オンライン香典は、香典現金書留の代わりに、キャッシュレスでの香典を申込むことができます。
            </p>
            <img className="giftLogo logo-apply" src="/images/icon-funeral-gift.png" alt="" />
            <p className='warning dl-text-apply'>
              ご遺族側のご都合や環境により受取を辞退される場合、キャンセル処理となります。
            </p>
          </div>
          <div className="box-footer">
            <Button variant="warning" className='btn-confirm-apply' onClick={handleSubmit}>オンライン香典を申込む</Button>
            <Button variant="warning" className='btn-confirm-apply btn-cancel-apply' onClick={onClose}>あとで確認する</Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default ModalFuneralMoney;
