import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

import AttendeeLayout from 'components/AttendeeLayout';
import ItemCell from 'components/CustomForms/ItemCell';
import { setCartItem, removeCartItem, getCartContents, checkLimit } from 'components/Cart';

export const AttendanceContentSelectSagawa = (props) => {
  const [sagawaList, setSagawaList] = useState([]);

  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;
  const history = useHistory();

  useEffect(() => {
    const fetch = async () => {
      const result = await getDisplayItem(funeralId);
      await checkLimit(funeralId, result.itemLimit, result.koudenLimit);
      let cart = await getCartContents(funeralId);
      cart.sagawa_telegram = cart.filter((row) => row.itemType === "sagawa_telegram");

      let sagawaList = result.sagawaList;
      for (let i = 0; i < sagawaList.length; i++) {
        if (cart.sagawa_telegram.length > 0) {
          for (let j = 0; j < cart.sagawa_telegram.length; j++) {
            if (sagawaList[i].itemId === cart.sagawa_telegram[j].itemId) {
              sagawaList[i].flag = true;
              break;
            }
            sagawaList[i].flag = false
          }
        } else {
          sagawaList[i].flag = false
        }
      }

      setSagawaList(sagawaList);
    }
    
    fetch();
  }, [props, funeralId]);

  return (
    <AttendeeLayout>
      <Helmet>
        <title>参列選択（弔電） | itowa</title>
      </Helmet>
      <form className="sagawa-form">
      <h2 className="item-title font-weight-bold">弔電</h2>
        {
          sagawaList.length > 0 ?
            <>
              <Row className="item-grids">
                {
                  sagawaList.map((row, index) => {
                    return (
                      <Col key={index} xl={3} lg={6} md={6} sm={6} xs={6}>
                        <ItemCell key={index} item={row} countable={false} flag={row.flag} onChange={(flag) => {
                          if (flag) {
                            setCartItem(funeralId, row);
                          } else {
                            removeCartItem(funeralId, row);
                          }
                        }} />
                      </Col>
                    )
                  })
                }
              </Row>
              <Button className="btn-to-confirm" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/confirm")}>お支払いに進む</Button>
              <div style={{ marginBottom: "5rem" }}>&nbsp;</div>
            </>
            :
            <>ご選択項目は現在注文可能な商品がありません</>
        }
      </form>
    </AttendeeLayout>
  );
};

const getDisplayItem = async (funeralId) => {
  const options = {
    queryStringParameters: { funeralId: funeralId }
  }

  return await API.get('guest', '/funerals/item/template', options);
}

export default AttendanceContentSelectSagawa;