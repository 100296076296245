import { useRef } from 'react';

export const LinePreRegisterButton = ({handleLineLogin}) => {
  const lineBtnRef = useRef();

  const onHover = () => {
    lineBtnRef.current.src = '/images/RegisterBtnHover.png';
  }

  const unHover = () => {
    lineBtnRef.current.src = '/images/RegisterBtnDefault.png';
  }

  const downClick = () => {
    lineBtnRef.current.src = '/images/RegisterBtnPress.png';
  }

  const upClick = () => {
    lineBtnRef.current.src = '/images/RegisterBtnDefault.png';
  }
  return (
    <img
      className={'pointer'}
      ref={lineBtnRef}
      src="/images/RegisterBtnDefault.png"
      alt={'LINEアカウントで申請'}
      onMouseEnter={onHover}
      onMouseLeave={unHover}
      onMouseUp={upClick}
      onMouseDown={downClick}
      onClick={handleLineLogin}
    />
  )
}

export default LinePreRegisterButton;