import {useState, useEffect, useContext} from 'react';
import AttendeeLayout from "components/AttendeeLayout";
import { PhotoGallery } from 'components/PhotoGallery';
import { API } from 'aws-amplify';
import { useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from "../../components/Contexts";

export const Album = (props) => {
  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;
  const [photoList, setPhotoList] = useState([]);
  const { userInfo } = useContext(AuthContext);
  const isLogin = checkLogin(userInfo, funeralId)

  useEffect(() => {
    const fetch = async () => {
      const response = await getPhotos(funeralId);
      setPhotoList(response);
    }
    fetch();
  },[funeralId, setPhotoList]);
  
  return (
    <AttendeeLayout>
      <Helmet>
        <title>アルバム | itowa</title>
      </Helmet>
      <div id="album">
        <div id="banner-bg"></div>
        <div id="banner">
          <div>人 の 心 の</div>
          <div>繋 が り が</div>
          <div>限 り な く</div>
          <div>続 い て い く</div>
        </div>

        <PhotoGallery photos={photoList} append={isLogin}/>
      </div>
    </AttendeeLayout>
  )
}

const getPhotos = async (funeralId) => {
  const apiName = isPublicPhotoFuneral(funeralId) ? 'guest' : 'product';
  return await API.get(apiName, "/photos/" + funeralId);
}

const checkLogin = (userInfo, funeralId) => {
  return userInfo && userInfo.attendees !== null && userInfo.attendees.FuneralId === funeralId;
}

// 特定葬家のみ認証無しで写真を表示する
const isPublicPhotoFuneral = (funeralId) => {
  return funeralId === '36b85ef8-deda-4c84-ae19-5b1ad7b64241' || funeralId === '2d5b0d55-b010-4606-8759-f937f536907f';
}