import { useEffect, useContext } from 'react';
import { AuthContext } from 'components/Contexts';
import { useHistory } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import { awsConfig } from '../aws-config';
import { FUNERALS_WEB_URL, COGNITO_DOMAIN } from '../constants';

export const LoginRedirect = () => {
  const { setUserInfo } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    const getToken = async () => {
      const codeVerifier = sessionStorage.getItem('code_verifier');
      sessionStorage.removeItem('code_verifier');

      const url = new URL(window.location.href);
      const code = url.searchParams.get('code');

      const params = new URLSearchParams();
      params.append('client_id', awsConfig.UserPoolClientId);
      params.append('grant_type', 'authorization_code');
      params.append('code', code);
      params.append('code_verifier', codeVerifier);
      params.append('redirect_uri', `${FUNERALS_WEB_URL}/login/redirect`);

      const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

      try {
        fetch(`https://${COGNITO_DOMAIN}.auth.ap-northeast-1.amazoncognito.com/oauth2/token`, {
          body: params,
          method: 'POST',
          headers: headers
        })
          .then(response => response.json())
          .then(data => {
            let idToken = data.id_token.split('.');
            idToken = JSON.parse(atob(idToken[1]));
            const userName = idToken["cognito:username"];

            localStorage.setItem(`CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}.${userName}.idToken`, data.id_token);
            localStorage.setItem(`CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}.${userName}.accessToken`, data.access_token);
            localStorage.setItem(`CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}.${userName}.refreshToken`, data.refresh_token);
            localStorage.setItem(`CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}.LastAuthUser`, userName);
            Auth.currentSession().then(res => {
              localStorage.setItem(`CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}.${userName}.clockDrift`, res.clockDrift);
            });
          }).then(async () => {
          const funeralId = sessionStorage.getItem('funeralId');
          sessionStorage.removeItem('funeralId');
          let options = {
            queryStringParameters: {
              funeralId: funeralId
            }
          };
          const userInfo = await API.get("product", "/auth", options);
          if (!userInfo.error) {
            setUserInfo(userInfo);
          }

            // 利用規約のチェックが未完了の場合は利用規約のページへ飛ばす
            if (userInfo?.attendeeTermsConsents?.TermsChecked !== true) {
              history.push(`/${funeralId}/attendanceterms`);
              return;
            }

            const url = sessionStorage.getItem('returnUrl');
            sessionStorage.removeItem('returnUrl');
            if (url) {
              history.push(`${url}`);
            } else {
              history.push(`/${funeralId}`);
            }
          });
      } catch (e) {
        console.log(e)
      }
    }

    getToken();
    // eslint-disable-next-line
  }, []);

  return (
    <div></div>
  )
}

export default LoginRedirect;