import React from 'react';
import moment from 'moment';

export const AttendeeReceptionList = ({ attendeeUserList, handleSelectFilter, currentFilter }) => {

  return (
    <>
      <div className='filter-group'>
        <div className="button-filter">
          <button className={currentFilter === 'all' ? 'active' : ''} onClick={() => handleSelectFilter('all')}>全体</button>
          <button className={currentFilter === 'vigil' ? 'active' : ''} onClick={() => handleSelectFilter('vigil')}>通夜</button>
          <button className={currentFilter === 'funeral' ? 'active' : ''} onClick={() => handleSelectFilter('funeral')}>葬儀</button>
        </div>
      </div>
      <div className="card-body p-0" style={{ overflow: "scroll", height: "50vh" }}>
        <table className="table table-width">
          <thead>
            <tr>
              <th className="th-12 freeze-column">氏名</th>
              <th className="th-16">返礼品注文日</th>
              <th className="th-12">法人名</th>
              <th className="th-16">住所</th>
              <th className="th-10">参列日時</th>
              <th className="th-10">香典金額</th>
              <th className="th-10">電話番号</th>
            </tr>
          </thead>
          <tbody>
            {
              attendeeUserList.map((row, index) => {
                return (
                  <tr key={index}>
                    <td className="freeze-column">{row?.UserName || ""}</td>
                    <td>{row?.OrderReturnGiftDate}</td>
                    <td>{row?.CompanyName || ""}</td>
                    <td>{row?.FormatAddress || ""}</td>
                    <td>{
                      row?.AttendDateTime ? moment(row?.AttendDateTime).format("YYYY/MM/DD HH:mm:ss") : ""  
                    }</td>
                    <td>{row?.GiftMoney || ""}</td>
                    <td>{row?.PhoneNo || ""}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default AttendeeReceptionList;