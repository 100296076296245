import React from 'react';

/**
 * 銀行コードを入力するとき、利用者に外部サイトによる検索を促すコンポーネントです。
 */
export class SearchBankCode extends React.Component {
    render() {
        // @media で解像度別の絶対サイズ指定が入っているため、それを明示的にオーバーライドする
        const workaround = {
            "font-size": "1em"
        };

        const outerStyle = {
            "font-size": "0.8em",
            "margin-left": "0.75em",
        };

        return (
            <span style={outerStyle}>
                検索は<a href="https://www.zenginkyo.or.jp/shop/" target="_blank" rel="noreferrer" style={workaround}>こちら</a>
            </span>
        );

    }
}
