import request from 'utils/request';
import { validateHalfwidthNumber } from 'utils/helper';

export const ZipCode = (props) => {
  const { name, className, onChange, formik, ...params } = props;

  const handleBlur = (e) => {
    formik.handleBlur(e);
    const fetch = async (zipCode) => {
      const response = await getAddress(zipCode);
      if (response && response.status === 200 && response.results) {
        const address = {
          prefCode: response.results[0].prefcode,
          city: {
            kanji: response.results[0].address2,
            kana: response.results[0].kana2
          },
          town: {
            kanji: response.results[0].address3,
            kana: response.results[0].kana3
          }
        }
        if(response.results.length > 1) {
          address.city.kanji = "(複数該当します)";
          address.city.kana = "";
          address.town.kanji = "";
          address.town.kana = "";
        }

        if(onChange) {
          onChange(address)
        }
      } else {
        formik.setFieldError('zipCode', "郵便番号が正しくありません。");
        onChange({
          prefCode: "",
          city: {
            kanji: "",
            kana: ""
          },
          town: {
            kanji: "",
            kana: ""
          }
        })
      }
    }
    if(e.target.value && e.target.value.length >= 7) {
      if(name === "zipCode"){
        if(!validateHalfwidthNumber(Number(e.target.value))){
          formik.setFieldError('zipCode', "ハイフンなしで半角数字でご入力ください。");
          onChange({
            prefCode: "",
            city: {
              kanji: "",
              kana: ""
            },
            town: {
              kanji: "",
              kana: ""
            }
          })
          return;
        } else {
          // reset error messsage if the data filled
          const fieldsToClear = ['prefecture', 'zipCode', 'address1'];
          fieldsToClear.forEach(field => {
            if (formik.errors?.[field]) {
              formik.setFieldError(field, null);
            }
          });
        }
      }
      fetch(e.target.value);
    }
  }

  return (
    <>
      <input type="text" name={name} className={className + (formik.errors[name] ? " is-invalid" : "")} value={formik.values[name]} onChange={formik.handleChange} onBlur={handleBlur} {...params}/>
      <div className="invalid-feedback">{formik.errors[name]}</div>
    </>
  )
}

const getAddress = async (zipCode) => {
  const options = {
    method: 'GET'
  };

  return request("https://zipcloud.ibsnet.co.jp/api/search?zipcode=" + zipCode, options);
}