import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import { awsConfig } from '../aws-config';
import { FUNERALS_WEB_URL, COGNITO_DOMAIN } from '../constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLoading } from 'components/Loading';
import Input from 'components/Input';
import { Button } from 'react-bootstrap';

export const LineFamilySignInSubmit = () => {
  const history = useHistory();
  const loading = useLoading();

  const formik = useFormik({
    initialValues: {
      email: "",
      userName: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("メールアドレスは必須です").email("メールアドレスの書式ではありません"),
      userName: Yup.string().required("ユーザ名は必須です"),
    }),
    onSubmit: async (values) => {
      if (!formik.isValid) return;

      const funeralId = localStorage.getItem("funeralId");
      loading(true);
      await createLineFuneralUser(funeralId, values);
      loading(false);

      history.push('/signin/line/thanks');
    },
    validateOnChange: false,
    validateOnBlur: false
  });
  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    const getToken = async () => {
      const url = new URL(window.location.href);
      const code = url.searchParams.get('code');

      const params = new URLSearchParams();
      params.append('client_id', awsConfig.UserPoolClientId);
      params.append('grant_type', 'authorization_code');
      params.append('code', code);
      params.append('redirect_uri', `${FUNERALS_WEB_URL}/signin/line/submit`);

      const headers = new Headers({'Content-Type': 'application/x-www-form-urlencoded'});

      try {
        fetch(`https://${COGNITO_DOMAIN}.auth.ap-northeast-1.amazoncognito.com/oauth2/token`, {
          body: params,
          method: 'POST',
          headers: headers
        })
          .then(response => response.json())
          .then(data => {
            let idToken = data.id_token.split('.');
            idToken = JSON.parse(atob(idToken[1]));

            // LINEユーザIDとメールアドレスを取得して設定
            setFieldValue("lineUserId", idToken["identities"][0]["userId"]);
            if (typeof idToken["email"] != 'undefined') {
              setFieldValue("email", idToken["email"]);
            }

            // Amplify関連の設定
            const userName = idToken["cognito:username"];
            localStorage.setItem(`CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}.${userName}.idToken`, data.id_token);
            localStorage.setItem(`CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}.${userName}.accessToken`, data.access_token);
            localStorage.setItem(`CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}.${userName}.refreshToken`, data.refresh_token);
            localStorage.setItem(`CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}.LastAuthUser`, userName);
            Auth.currentSession().then(res => {
              localStorage.setItem(`CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}.${userName}.clockDrift`, res.clockDrift);
            });
          });
      } catch (e) {
        console.log(e)
      }
    }

    getToken();
    // eslint-disable-next-line
  }, []);

  return (
    <div id="auth-main">
      <div id="auth-common">
        <img className="logo" src="/images/itowa-logo-square.jpg" alt="logo"></img>
        <div className="title mb-3">ログイン</div>
        <form onSubmit={formik.handleSubmit}>
          <div id="identity">
            <div className="form-group">
              <label className="control-label">氏名</label>
              <div className="input-group">
                <Input type="text" name="userName" className="form-control" formik={formik} />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label">メールアドレス</label>
              <div className="input-group">
                <Input type="email" name="email" className="form-control" formik={formik} />
              </div>
            </div>
          </div>
          <div className="buttons">
            <Button type="submit" variant="success">登録</Button>
          </div>
        </form>
      </div>
    </div>
  )
}

const createLineFuneralUser = async (funeralId, forms) => {
  forms.funeralId = funeralId;
  const options = {
    body: forms
  }
  return await API.post('product', '/users/funeral/line', options);
}

export default LineFamilySignInSubmit;
