import React, { useContext, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay-ts';

import { LoadingContext, MessageContext } from './Contexts';

import '../css/Attendee.scss';

export const MounerLayout = (props) => {
  const { loading } = useContext(LoadingContext);
  const { alert, setAlert } = useContext(MessageContext);

  useEffect(() => {
    if (alert.message && alert.variant !== "danger") {
      setTimeout(() => setAlert({}), 3000);
    }
  }, [alert, setAlert]);

  return (
    <div id="registration-wrapper">
      <LoadingOverlay active={loading} spinner text='Loading...'>
        <div id="auth-main">
          {props.children}
          {alert.message ? <Alert className="attendee-alert" variant={alert.variant} onClose={() => setAlert({})} dismissible>{alert.message}</Alert> : ""}
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default MounerLayout;