import { useState, useEffect, useContext } from 'react';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import AttendeeLayout from 'components/AttendeeLayout';
import CartTotal from 'components/CartTotal';
import { getCartGiftContents, getOrderGiftReturnSelected } from 'components/Cart';
import { CATEGORY_GIFT_LIST } from '../../constants';

import {
  Elements,
  useStripe,
  useElements,
  PaymentElement
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { AuthContext } from 'components/Contexts';
import { useLoading } from 'components/Loading';
import { Button, Modal } from 'react-bootstrap';

export const FuneralGiftPayment = (props) => {
  const funeralId = props.match.params.funeralId;

  const { userInfo } = useContext(AuthContext);
  const stripePromise = loadStripe(userInfo.key);

  const [elementOptions, setElementOptions] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [giftItemList, setGiftItemList] = useState([]);
  const [postGiftItemList, setPostGiftItemList] = useState([]);
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(true);

  useEffect(() => {
    const appearance = {
      theme: 'stripe',
      rules: {
        ".Label": {
          fontFamily: '"游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif',
          fontWeight: 500
        },
        ".Input": {
          border: "1px solid #42464C",
          borderRadius: "5px"
        }
      }
    };
    const get = async () => {
      try {
      const cart = await getCartGiftContents(funeralId);
      const result = await getOrderAttendeeList(funeralId);
      const orderSelected = await getOrderGiftReturnSelected(funeralId);
      
      const moldGiftItemList = [];
      // eslint-disable-next-line
      Object.keys(result?.groupedOrders).forEach((orderKey) => {
        const giftItem = cart.find((gift) => gift?.priceCategory.toString() === orderKey);
        const groupedOrders = result.groupedOrders[orderKey]?.filter((order) => orderSelected?.includes(`${order?.OrderId}__${order?.ItemId}`));
        groupedOrders.forEach((orderItem) => {
          if (giftItem) {
            const { giftId, companyId, itemType, itemName, price, imagePath, priceCategory, taxRateTenPer } = giftItem;
            moldGiftItemList.push({
              giftId,
              companyId,
              itemType,
              itemName,
              price,
              imagePath,
              targetAttendeeId: orderItem?.AttendeeId,
              priceCategory,
              taxRateTenPer,
              targetOrderId: orderItem?.OrderId,
              targetItemId: orderItem?.ItemId,
            });
          }
        });
      });

      setPostGiftItemList(moldGiftItemList)

      CATEGORY_GIFT_LIST.forEach(jpy => {
        const targetIndex = cart.findIndex(item => item.priceCategory === jpy && item.orderId === null);
        if (targetIndex !== -1) {
          let targetCount = 0;
          if (result.groupedOrders && result.groupedOrders[jpy]) {
            const groupedOrders = result.groupedOrders[jpy]?.filter((order) => orderSelected?.includes(`${order?.OrderId}__${order?.ItemId}`));
            targetCount = groupedOrders.length;
          }

          const individualCount = cart.filter(item =>
            item.priceCategory === jpy && item.orderId !== null
          ).length;
          const totalCount = targetCount - individualCount;
          cart[targetIndex].targetCount = totalCount;
          cart[targetIndex].totalPrice = cart[targetIndex].price * totalCount;
        }
      });

      // 決済対象のfilterを行う
      const filteredCart = cart.filter(item => {
        return (item.targetCount !== 0 && item.orderId === null) || (item.targetCount === undefined && item.orderId !== null)
      })
      setGiftItemList(filteredCart);

      let ls = filteredCart;
      if (ls.paymentIntent && ls.paymentIntent !== "") {
        setPaymentIntent(ls.paymentIntent);
      } else {
        const total = moldGiftItemList.reduce((previous, current) => previous + parseInt(current.price, 10), 0);
        // const feeTotal = Math.ceil(cart.reduce((previous, current) => previous + current.fee, 0)); TODO: Giftsテーブルにfeeカラムが今後追加される？
        const feeTotal = Math.ceil(
          moldGiftItemList.reduce((previous, current) =>
            // items.jsのcreateItem()を参考に算出
            previous + Math.round(parseInt(current.price, 10) * 0.096 * 1000)/1000, 0
          )
        );
        const pi = await getPaymentIntent(funeralId, total, feeTotal);
        setPaymentIntent(pi.paymentIntent);
        ls.paymentIntent = pi.paymentIntent;
        localStorage.setItem(
          funeralId,
          JSON.stringify(ls)
        );
      }

      setElementOptions({
        clientSecret: ls.paymentIntent.client_secret,
        appearance: appearance
      })
    } catch (e) {
      console.error(e);
    }
    };
    get();
  }, [funeralId]);

  return (
    <AttendeeLayout>
      <Helmet>
        <title>お支払い | itowa</title>
      </Helmet>
      <h2 className="title-horizontal" style={{ width: "14rem" }}>お支払い</h2>
      <div style={{ width: "90%", maxWidth: "1200px", margin: "0 auto" }}>
        {
          elementOptions &&
          <Elements stripe={stripePromise} options={elementOptions}>
            <CheckoutForm
              userInfo={userInfo}
              funeralId={funeralId}
              paymentIntent={paymentIntent}
              giftItemList={giftItemList}
              postGiftItemList={postGiftItemList}
              {...props} />
          </Elements>
        }
      </div>
      {/* TODO: 内容が決定次第中身差し替え */}
      <Modal show={showDisclaimerModal} centered={true}>
        <Modal.Header closeButton={true} onHide={() => setShowDisclaimerModal(false)}>
          <div style={{marginLeft: "auto", marginRight: "auto", paddingLeft: "calc(1rem + 32px)"}}>
            <Modal.Title>＜注文キャンセルについて＞</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{marginLeft: "5%", marginRight: "5%"}}>
            <ul>
              <li>「返礼品」は葬儀の特性上、注文完了されると葬儀社が直ちに手配を行います。</li>
            </ul>
            <p>以上のことからご注文後のキャンセルは一切応じておりません。ご注文内容についてご確認の上、決済手続きをお願い申し上げます。</p>
            <div style={{width: "100%", textAlign: "center"}}>
              <Button onClick={() => setShowDisclaimerModal(false)}
                style={{width: "60%", marginLeft: "auto", marginRight: "auto", border: "0.1rem solid rgba(170, 170, 170, 0.8)"}}
                variant={"secondary"}>
                確認
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </AttendeeLayout>
  );
};


const CheckoutForm = (props) => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const { funeralId, giftItemList, userInfo, paymentIntent, postGiftItemList } = props;
  const loading = useLoading();
  const [errorMessage, setErrorMessage] = useState("");
  const { setUserInfo } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    try {
      loading(true);
      // Ensure elements.submit() is called first
      const submitResult = await elements.submit();
      if (submitResult?.error) {
        setErrorMessage(submitResult?.error?.message);
        return;
      }
      const result = await stripe.createConfirmationToken({elements})
      if (result?.error) {
        setErrorMessage(result?.error?.message);
        return;
      }
      const confirmationToken = result?.confirmationToken?.id;
      const orderResult = await createOrder(
        paymentIntent.id,
        funeralId,
        userInfo?.orderEmail || userInfo?.email,
        postGiftItemList,
        confirmationToken,
      );

      if(orderResult && orderResult.result) {
        localStorage.removeItem(funeralId + 'funeralGift');

        // 最新の認証情報を取り直す
        const options = {
          queryStringParameters: {
            funeralId: funeralId,
            companyId: ""
          }
        };
        const userInfo = await API.get("product", "/auth", options);
        setUserInfo(userInfo);
        history.push("/" + funeralId + "/funeral/gift/complete");
      } else {
        if(orderResult) {
          setErrorMessage(orderResult.message);
        } else {
          setErrorMessage("システムエラーが発生しました。")
        }
      }
    } finally {
      loading(false);
    }

  };

  return (
    <form id="payment">
      <div className="payment-panel">
        <div className="group card-info">
          <p className="p-crypted">
            全ての取引は暗号化されています
          </p>
          <PaymentElement />
          {
            errorMessage &&
            <div className="error-message">
              {errorMessage}
            </div>
          }
        </div>
      </div>
      <div className="cart-total-panel">
        <div className="group">
          <CartTotal giftItemList={giftItemList} type={'gift'}>
            <button type="button" className="btn btn-primary button-to-payment" onClick={handleSubmit}>お支払いする</button>
          </CartTotal>
        </div>
        <button type="button" className="btn btn-primary button-to-return" onClick={() => history.goBack()}>籠の中に戻る</button>
      </div>
    </form>
  );
}

const createOrder = async (paymentIntentId, funeralId, email, selectedItem, confirmationToken) => {
  const ordererInfo = JSON.parse(localStorage.getItem('ordererInfo'));
  const options = {
    body: {
      paymentIntentId: paymentIntentId,
      funeralId: funeralId,
      email: email,
      selectedItem: selectedItem,
      ordererInfo: ordererInfo,
      confirmationToken: confirmationToken
    }
  }

  try {
    await API.post('product', '/orders', options);
    return {
      result: true
    };
  } catch (e) {
    if(e === "Error: Network Error") {
      return {
        result: false,
        message: "問題が発生しました。インターネット接続をご確認の上、もう一度お試しください。"
      }
    }
  }
}

const getPaymentIntent = async (funeralId, amount, fee) => {
  const options = {
    body: {
      funeralId: funeralId,
      amount: amount,
      fee: fee
    }
  };

  return await API.post('product', '/charge', options);
}

const getOrderAttendeeList = async (funeralId, sortOrder = null) => {
  const options = {
    queryStringParameters: {
      funeralId: funeralId,
      sortOrder: sortOrder,
    }
  };
  return  await API.get('product', '/orders/attendee', options);
}

export default FuneralGiftPayment;