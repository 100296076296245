import React, { useState, useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Button, Modal, Form } from 'react-bootstrap';
import { useFormik } from 'formik';

import useMessage from 'components/Message';
import { AttendanceContext } from './Contexts';
import * as Constants from '../constants';

const PortalFuneralSettings = () => {
  const match = useRouteMatch("/:funeralId");
  const message = useMessage();

  const { funeralInfo, setFuneralInfo, showPortalFuneralSetting, setShowPortalFuneralSetting} = useContext(AttendanceContext);
  const [loading, setLoading] = useState(false);

  const funeralId = match.params.funeralId;

  const handleCheckboxChange = (name) => {
    if (name === 'notAllow') {
      setFieldValue('portalInfoOptions.address', false);
      setFieldValue('portalInfoOptions.chiefMournerName', false);
      setFieldValue('portalInfoOptions.decreasedName', false);
      setFieldValue('portalStatus', Constants?.PORTAL_STATUS?.DENIED)
    } else if (name === 'allowAddress'){
      setFieldValue('portalInfoOptions.address', !formik?.values?.portalInfoOptions?.address);
      setFieldValue('portalStatus', Constants?.PORTAL_STATUS?.ALLOWED);
      setFieldValue('portalInfoOptions.decreasedName', true);
    } else if (name === 'allowChiefMourner') {
      setFieldValue('portalInfoOptions.chiefMournerName', !formik?.values?.portalInfoOptions?.chiefMournerName);
      setFieldValue('portalStatus', Constants?.PORTAL_STATUS?.ALLOWED);
      setFieldValue('portalInfoOptions.decreasedName', true);
    } else if (name === 'allowDecreasedName') {
      setFieldValue('portalInfoOptions.decreasedName', true);
      setFieldValue('portalStatus', Constants?.PORTAL_STATUS?.ALLOWED);
    }
  };

  const formik = useFormik({
    initialValues: {
      portalInfoOptions: {
        Address: false,
        DecreasedName: true,
        ChiefMournerName: false,
      },
      portalStatus: Constants?.PORTAL_STATUS?.NOT_SET
    },
    onSubmit: async (values) => {
      if(formik.isValid) {
        setLoading(true);
        try {
          const response = await updateFuneralByChiefMourner(funeralId, values);
          if (!response.error) {
            setFuneralInfo({
              ...funeralInfo,
              portalInfoOptions: {
                Address: values?.portalInfoOptions?.address,
                DecreasedName: values?.portalInfoOptions?.decreasedName,
                ChiefMournerName: values?.portalInfoOptions?.chiefMournerName,
              },
              portalStatus: values?.portalStatus || Constants?.PORTAL_STATUS?.NOT_SET,
            });
            message.alert.success("掲載設定が完了しました。");
            setShowPortalFuneralSetting(false);
          } else {
            message.alert.danger("システムエラーが発生しました");
          }
        } catch (e) {
          if (e?.response?.status === 400) {
            message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
          } else {
            message.alert.danger("システムエラーが発生しました");
          }
        } finally {
          setLoading(false);
        }
      }
    }
  })

  useEffect(() => {
    if (funeralInfo?.portalInfoOptions && funeralInfo?.portalStatus) {
      setFieldValue('portalInfoOptions.decreasedName', funeralInfo.portalInfoOptions.DecreasedName);
      setFieldValue('portalInfoOptions.address', funeralInfo.portalInfoOptions.Address);
      setFieldValue('portalInfoOptions.chiefMournerName', funeralInfo.portalInfoOptions.ChiefMournerName);
      if (funeralInfo.portalStatus === Constants?.PORTAL_STATUS?.NOT_SET) {
        setFieldValue('portalStatus', Constants?.PORTAL_STATUS?.ALLOWED);
      } else {
        setFieldValue('portalStatus', funeralInfo.portalStatus);
      }
    }
    // eslint-disable-next-line
  }, [funeralInfo?.portalInfoOptions, funeralInfo?.portalStatus]);

  const setFieldValue = formik.setFieldValue;

  return (
    <React.Fragment>
      <Modal show={showPortalFuneralSetting} centered={true} className='portalPermSettingWrapper'>
        <Modal.Body>
          <div className='boxHeader'>
            <img className="giftLogo" src="/images/icon-funeral-gift.png" alt="" />
            <h2 style={{textAlign: 'center'}}>
                  itowaお悔やみ情報サイト（沖縄ナビ）への掲載同意
            </h2>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-2 setting-portal-group">
              <Form.Group controlId="setting-portal-form">
                <Form.Check label={ <p> 故人名の掲載に <br/> 同意する </p> } type="checkbox" checked={formik?.values?.portalInfoOptions?.decreasedName} onChange={() => handleCheckboxChange('allowDecreasedName')}/>
                <Form.Check label={ <p> 住所（町名まで）の掲載に <br/> 同意する </p> } type="checkbox" checked={formik?.values?.portalInfoOptions?.address} onChange={() => handleCheckboxChange('allowAddress')} />
                <Form.Check label={ <p> 喪主名の掲載に <br/> 同意する </p> } type="checkbox" checked={formik?.values?.portalInfoOptions?.chiefMournerName} onChange={() => handleCheckboxChange('allowChiefMourner')} />
                <Form.Check label={ <p> 掲載しない </p> } type="checkbox" checked={formik?.values?.portalStatus === Constants?.PORTAL_STATUS?.DENIED} onChange={() => handleCheckboxChange('notAllow')} />
              </Form.Group>
            </div>
            <div className="button-container">
              <Button
                type="submit"
                variant="warning"
                disabled={loading}
              >
                確定
              </Button>
            </div>
            <p className='textSmall'>確定後でもメニューより変更可能です</p>
            <p className='textNote'>※itowaのシステム構成上、喪主名は必ず <br/> 当ページには掲載されます</p>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const updateFuneralByChiefMourner = async (funeralId, portalForm) => {
  const options = {
    body: { ...portalForm }
  };
  return await API.put('product', `/funerals/${funeralId}/optional/chief-mourner`, options);
};

export default PortalFuneralSettings;
