import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Auth } from "aws-amplify";

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Input from 'components/Input';
import useMessage from 'components/Message';

export const Password = (props) => {
  const message = useMessage();

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("現在のパスワードは必須です"),
      newPassword: Yup.string().required("新パスワードは必須です"),
      confirmPassword: Yup.string().required("新パスワードの確認は必須です"),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        if (values.newPassword !== values.confirmPassword) {
          message.alert.danger("新パスワードと確認用のバスワードが異なります")
        } else {
          Auth.currentAuthenticatedUser()
            .then(user => {
              return Auth.changePassword(user, values.oldPassword, values.newPassword);
            })
            .then(data => message.alert.success("パスワードを変更しました"))
            .catch(err => message.alert.danger("現在のパスワードが異なります"));
        }
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  return (
    <CommonLayout>
      <ContentHeader>
        パスワードの再設定
      </ContentHeader>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md="6">
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <label className="control-label">現在のパスワード</label>
                  <Input type="text" name="oldPassword" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">新パスワード</label>
                  <Input type="text" name="newPassword" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">新パスワードの確認</label>
                  <Input type="text" name="confirmPassword" className="form-control" formik={formik} />
                </div>
              </div>
              <div className="card-footer">
                <div>
                  <Button type="submit" className="float-right" variant="success">パスワードを変更する</Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </form>

    </CommonLayout>
  );
};

export default Password;