import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom';
import { API } from 'aws-amplify';

import { FUNERALS_WEB_URL, COGNITO_DOMAIN } from '../../constants';
import { awsConfig } from '../../aws-config';

import MounerLayout from 'components/MounerLayout';
import LinePreRegisterButton from 'components/LinePreRegisterButton';
import { useLoading } from 'components/Loading';
import useMessage from 'components/Message';

export const FuneralRegistration = () => {
  const match = useRouteMatch("/:funeralId");
  const loading = useLoading();
  const message = useMessage();

  const [funeralInfo, setFuneralInfo] = useState();

  const handleLineLogin = () => {
    localStorage.setItem('funeralId', match.params.funeralId);
    const funeralIdState = `${match.params.funeralId}---${new Date().getTime()}`;
    window.location.href = `https://${COGNITO_DOMAIN}.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?identity_provider=LINE&response_type=code&client_id=${awsConfig.UserPoolClientId}&scope=openid%profile&redirect_uri=${FUNERALS_WEB_URL}/registration/submit&state=${funeralIdState}`;
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await getFuneralInfo(match?.params?.funeralId || '');
        if (!response.error) {
          setFuneralInfo(response);
          loading(false);
        } else {
          message.alert.danger("システムエラーが発生しました");
        }
      } catch (e) {
        if (e.response.status === 400) {
          message.alert.danger(e.response.data.error);
        } else {
          message.alert.danger("システムエラーが発生しました");
        }
      } finally {
        loading(false);
      }
    }
    loading(true);
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, match?.params?.funeralId]);

  return (
    <MounerLayout>
      {funeralInfo && (
        <div id="auth-common">
          <img className="logo" src="/images/itowa-logo-square.jpg" alt="logo"></img>
          <p class="h2">{funeralInfo?.familyName || ''}家</p>
          <p class="h4">(故 {funeralInfo?.decreasedName || ''})</p>
          <div>
            <div className="buttons">
              <LinePreRegisterButton handleLineLogin={handleLineLogin}/>
            </div>
          </div>
        </div>
      )}
    </MounerLayout>
  )
}

const getFuneralInfo = async (funeralId) => {
  return await API.get('guest', '/funerals/' + funeralId + '/info-registration');
}

export default FuneralRegistration;
