import { useState, useEffect } from 'react';
import '../css/Common.scss';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Redirect } from 'react-router-dom';

export const SignIn = (props) => {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
    });
  }, []);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  
  const formFields = [
    {
      type: "email",
      label: "Username *",
      placeholder: "Enter your username",
      required: true,
    },
    {
      type: "password",
      label: "Password *",
      placeholder: "Enter your password",
      required: true,
      inputProps: {
        type: showPassword ? 'text' : 'password',
      },
    },
  ];

  let header = "ログイン画面";
  let slot = "sign-in";
  if (props.type === "admin") {
    header += " (管理者用)"
  } else if (props.type === "undertaker") {
    header += " (葬儀社用)"
  }

  return authState === AuthState.SignedIn && user ? (
    <Redirect to="main" />
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn
        slot={slot}
        headerText={header}
        hideSignUp={true}
        usenameAlias="email"
        formFields={formFields}>
        <div id="toggle-button" slot="federated-buttons">
          <button type="button" onClick={handleTogglePassword}>
            {showPassword ? (
              <img className="logo" src="/images/hide.png" alt="password" />
            ) : (
              <img className="logo" src="/images/view.png" alt="password" />
            )}
          </button>
        </div>
      </AmplifySignIn>
    </AmplifyAuthenticator>
  );
};

export default SignIn;