import { useState, useEffect, useContext } from 'react';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';

import { AuthContext } from 'components/Contexts';
import AttendeeLayout from 'components/AttendeeLayout';
import Input from 'components/Input';
import { ZipCodeHall } from 'components/CustomForms/ZipCodeHall';
import { Prefecture } from 'components/CustomForms/Prefecture';
import AgeSelect from 'components/CustomForms/AgeSelect';
import Gender from 'components/CustomForms/Gender';
import RelationSelect from 'components/CustomForms/RelationSelect';
import AccountConfirm from 'components/CustomForms/AccountConfirm';
import { getCartContents, checkLimit } from 'components/Cart';
import CartTotal from 'components/CartTotal';
import { getUserInfo } from 'utils/userInfo';
import { validateEmail, validateHalfwidthNumber } from 'utils/helper';
import { useLoading } from 'components/Loading';
import { EMPTY_EMAIL } from '../../constants';
import * as format from 'utils/format';

import styled from 'styled-components';

const Label = styled.label`
  margin-left: 3rem;
  width: 5rem;
`;

export const AttendanceContentInput = (props) => {
  const [selectedItem, setSelectedItem] = useState([]);
  const history = useHistory();
  const loading = useLoading();
  const { userInfo, setUserInfo } = useContext(AuthContext);

  const [isFixedEmail, setIsFixedEmail] = useState(false);
  const [belongs, setBelongs] = useState();
  const [isJapan, setIsJapan] = useState(true);
  const funeralId = props.match.params.funeralId;

  const { location } = props;
  const queryString = new URLSearchParams(location.search);
  const isOrderOnly = queryString.get("isOrderOnly") === "true";

  const numberPattern = (number) => `^[0-9０-９]{${number}}$`
  const phonePattern = '^0(?!.*--)(?!.*-$)([0-9]{9,10}|[0-9]+(-[0-9]+)+)$'

  const formik = useFormik({
    initialValues: {
      companyName: "",
      userName: "",
      phoneNo: "",
      email: "",
      zipCode: "",
      prefecture: "",
      address1: "",
      address2: "",
      address3: "",
      age: "",
      gender: "",
      relation: ""
    },
    validationSchema: Yup.object({
      userName: Yup.string().max(20, "20文字以下で入力してください").required("氏名は必須です"),
      phoneNo: Yup.string().required("電話番号は必須です").matches(new RegExp(phonePattern , 'u'),'10～11桁の半角数字でご入力ください'),
      zipCode: Yup.string().required("郵便番号は必須です").matches(new RegExp(numberPattern(7), 'u'), '郵便番号はハイフンなしの7桁で入力してください').test("halfwidth", "ハイフンなしで半角数字でご入力ください。", validateHalfwidthNumber),
      prefecture: Yup.string().required("都道府県は必須です"),
      address1: Yup.string().required("住所1は必須です"),
      email: Yup.string().required("メールアドレスは必須です").email("メールアドレスの書式ではありません").matches(/^[\w.+-]+@([\w-]+\.)+[\w-]{2,4}$/, '記号以外を入力してください。'),
      age: Yup.string().required("年代は必須です"),
      gender: Yup.string().required("性別は必須です"),
      relation: Yup.string().required("故人との関係は必須です"),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        localStorage.setItem("attendeeName", belongs === "corporation" ? values.companyName : values.userName);
        localStorage.setItem("attendeePhoneNo", values.phoneNo);
        values.address2 += values.address3;
        const user = ({
          companyName: values.companyName || "",
          companyFurigana: values.companyFurigana || "",
          userName: values.userName,
          furigana: values.furigana || "",
          email: values.email,
          phoneNo: values.phoneNo,
          zipCode: values.zipCode,
          prefecture: values.prefecture,
          address1: values.address1,
          address2: values.address2 || "",
          age: values.age,
          gender: values.gender,
          relation: values.relation,
          isJapan: isJapan,
          belongs: belongs
        });

        await createAttendeeUser(funeralId, user);

        const userinfo = await getUserInfo(funeralId);
        if (userinfo) {
          setUserInfo({
            ...userInfo,
            orderEmail: values.email,
          });
        } else {
          const cart = JSON.parse(localStorage.getItem(funeralId));
          cart.email = user.email;
          localStorage.setItem(
              funeralId,
              JSON.stringify(cart)
          );
        }

        history.push("/" + funeralId + "/attendance/content/payment/select?isOrderOnly=" + isOrderOnly);
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });
  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    const fetch = async () => {
      loading(true);
      const [result, cart] = await Promise.all([
        getDisplayItem(funeralId),
        getCartContents(funeralId)
      ]);
      await checkLimit(funeralId, result.itemLimit, result.koudenLimit);
      setBelongs('private');
      setIsJapan(true);
      setSelectedItem(cart);
      if (userInfo) {
        const defaultEmail = validateEmail(userInfo.email) && userInfo.email !== EMPTY_EMAIL ? userInfo.email : "";
        setFieldValue("email", defaultEmail);
        setIsFixedEmail(userInfo.email !== "" && userInfo.email !== EMPTY_EMAIL && validateEmail(userInfo.email));

        const result = await getAttendeeUser(funeralId, userInfo.email);
        if (!result.error) {
          setFieldValue("companyName", result.CompanyName);
          setFieldValue("companyFurigana", result.CompanyFurigana);
          setFieldValue("userName", result.UserName);
          setFieldValue("furigana", result.Furigana);
          setFieldValue("phoneNo", result.PhoneNo);
          setFieldValue("email", userInfo.email)
          if (result.Address) {
            setFieldValue("zipCode", result.Address.ZipCode);
            setFieldValue("prefecture", result.Address.Prefecture);
            setFieldValue("address1", result.Address.Address1);
            setFieldValue("address2", result.Address.Address2);
          }
          if (result.Statistics) {
            setFieldValue("age", result.Statistics.Age);
            setFieldValue("gender", result.Statistics.Gender);
            setFieldValue("relation", result.Statistics.Relation);
          }
          if (result.Belongs !== undefined) {
            setBelongs(result.Belongs);
          }
          if (result.IsJapan !== undefined) {
            setIsJapan(result.IsJapan);
          }
        }
      }
      loading(false);
    }

    fetch();
  }, [props, funeralId, setFieldValue, userInfo, loading]);

  const handleChangeZipCode = (result) => {
    formik.setFieldError('zipCode', null)
    if (!result) {
      if(!validateHalfwidthNumber(formik?.values?.zipCode)){
        formik.setFieldError('zipCode', "ハイフンなしで半角数字でご入力ください。");
      } else {
        formik.setFieldError('zipCode', "郵便番号が正しくありません。")
      }
      formik.setFieldValue("prefecture", "")
      formik.setFieldValue("address1", "")
      return
    }
      formik.setFieldValue("prefecture", result?.prefCode);
      formik.setFieldValue("address1", result?.city + format.townCustom(result?.town));
  }

  const handleChangeForm = async (e) => {
    if (e.target.value === "private") {
      setBelongs('private')
    } else if (e.target.value === "corporation") {
      setBelongs('corporation')
    } else if (e.target.value === "isJapan") {
      setIsJapan(!isJapan);
    }
  }

  return (
    <AttendeeLayout>
      <Helmet>
        <title>参列者様情報の入力 | itowa</title>
      </Helmet>
      <AccountConfirm />

      <form onSubmit={formik.handleSubmit} >
        <div id="account-input">
          <div className="account-input-panel">
            <div className="group ">
              <h2 className="text-center font-weight-bold mb-3">ゲスト購入</h2>
              <h3 className="text-center mb-5">(ログインせず購入可能です)</h3>
              <div className="form-group">
                <RelationSelect label="故人との関係" name="relation" formik={formik} />
              </div>
              <div className="form-group" id="zipCode">
                <Row>
                  <Col xs="6">
                    <label className="control-label">郵便番号</label>
                    <ZipCodeHall type="text"  name="zipCode" className="form-control" onChange={handleChangeZipCode} formik={formik} disabled={!isJapan} maxLength={7} />
                    <button type="button" className="btn btn-secondary rounded-pill btn-sm fs-6 mt-2" disabled="">郵便番号から自動入力</button>
                  </Col>
                  <Col xs="6">
                    <label className="control-label">都道府県</label>
                    <Prefecture type="text" name="prefecture" className="form-control" formik={formik} disabled={!isJapan} />
                  </Col>
                </Row>
              </div>
              <div className="form-group" id="address1">
                <label className="control-label">住所１</label>
                <Input type="text" maxLength={selectedItem?.some(el => el.itemType === "sagawa_telegram") ? 50 : ""} name="address1" className="form-control" formik={formik} disabled={!isJapan} />
              </div>
              <div className="form-group" id="address2">
                <label className="control-label">住所２</label>
                <Input type="text" maxLength={selectedItem?.some(el => el.itemType === "sagawa_telegram") ? 50 : ""} name="address2" className="form-control" formik={formik} disabled={!isJapan} />
              </div>
              <div className="form-group" id="address3">
                <label className="control-label">建物名</label>
                <Input type="text" name="address3" className="form-control" formik={formik} disabled={!isJapan} />
              </div>
              <hr />
              <div className="form-group">
                <div className="form-inline">
                  <label className="control-label" style={{ marginRight: "3rem" }}>区分</label>
                  <div className="form-check">
                    <Label className="control-label">
                      <input type="radio" value="private" className="form-check-input" onChange={handleChangeForm} checked={belongs === 'private'} />個人
                    </Label>
                    <Label className="control-label">
                      <input type="radio" value="corporation" className="form-check-input" onChange={handleChangeForm} checked={belongs === 'corporation'} />法人
                    </Label>
                  </div>
                </div>
              </div>
              {
                  belongs === "corporation" &&
                  <>
                    <div className="form-group" id="companyName">
                      <label className="control-label">法人名</label>
                      <Input type="text" name="companyName" className="form-control" formik={formik} />
                    </div>
                  </>
              }
              <div className="form-group">
                <label className="control-label">氏名</label>
                <Input type="text" name="userName" className="form-control" formik={formik} />
              </div>
              <div className="form-group" id="age">
                <div className="form-inline">
                  <AgeSelect label="年代" name="age" formik={formik} />
                </div>
              </div>
              <div className="form-group" id="gender">
                <div className="form-inline">
                  <Gender label="性別" name="gender" formik={formik} />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label">電話番号</label>
                <Input type="text" name="phoneNo" className="form-control" formik={formik}/>
              </div>
              <div className="form-group">
                <label className="control-label">メールアドレス</label>
                <Input type="text" maxLength="50" name="email" className="form-control" formik={formik} disabled={isFixedEmail} />
                <p className="fs-4 text-danger">※請求書または領収書を送付するアドレスのため入力にご注意ください。</p>
              </div>
            </div>
          </div>
        </div>
        <div className="cart-total-panel">
          <CartTotal selectedItem={selectedItem}>
            <div className="button-to-payment-wrapper">
              <button type="submit" className="btn btn-primary button-to-payment" disabled={formik.isSubmitting}>お支払い方法へ</button>
              <span className="chevron-right"></span>
            </div>
          </CartTotal>

          <button type="button" className="btn btn-primary button-to-return" onClick={() => history.push("/" + funeralId + "/attendance/content/confirm?isOrderOnly=" + isOrderOnly)}>籠の中に戻る</button>
        </div>
      </form>
    </AttendeeLayout>
  );
};

const createAttendeeUser = async (funeralId, user) => {
  const options = {
    body: {
      funeralId: funeralId,
      user: user
    }
  };
  return await API.post('guest', '/users/attendee/', options);
}

const getAttendeeUser = async (funeralId, email) => {
  const options = {
    queryStringParameters: {
      funeralId: funeralId
    }
  };
  return await API.get('product', '/users/attendee/' + email, options);
}

const getDisplayItem = async (funeralId) => {
  const options = {
    queryStringParameters: { funeralId: funeralId }
  }

  return await API.get('guest', '/funerals/item/template', options);
}

export default AttendanceContentInput;