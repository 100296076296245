import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useRouteMatch } from "react-router-dom";
import { useLoading } from 'components/Loading';
import { API } from "aws-amplify";
import { Button } from "react-bootstrap";
import moment from 'moment';
import * as Constants from "../../constants";

export const OrderForm = (props) => {
  const [searchWord, setSearchWord] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [selectedDeceased, setSelectedDeceased] = useState(null);
  const [isSearched, setIsSearched] = useState(false);
  const [funerals, setFunerals] = useState([]);
  const [halls, setHalls] = useState([]);
  const loading = useLoading();

  const match = useRouteMatch("/:companyId");
  const companyId = match.params.companyId;

  useEffect(() => {
    const fetch = async () => {
      const getFuneralResponse = await getFuneralList(companyId);
      setFunerals(getFuneralResponse.funeralList || []);

      const getHallResponse = await getHallList(companyId);
      setHalls(getHallResponse.hallList || []);
      loading(false)
    }
    loading(true)
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, companyId]);

  const searchDeceased = () => {
    setIsSearched(true);
    setSelectedDeceased(null);
    setSearchResult(funerals.filter((funeral) => funeral.DecreasedName.includes(searchWord)));
  }

  return (
    <div className="order-form">
      <Helmet>
        <title>参列選択（香典・弔文） | itowa</title>
      </Helmet>

      <h3 className="text-center font-weight-bold mb-3">供花・供物をご注文の方へ</h3>
      <h3 className="text-center font-weight-bold text-danger mb-3">
        故人名で検索することができます。
      </h3>
      <form>
        <div className="order-search-panel">
          <div className="form-group">
            <label className="order-search-label">故人名から検索</label>
            <div className="d-flex search-input">
              <input
                type='text'
                className="form-control"
                placeholder="故人名を入力してください"
                value={searchWord}
                onChange={(e) => setSearchWord(e.target.value)}
              ></input>
              <button
                type="button"
                className="btn btn-primary btn-sm fs-6"
                onClick={searchDeceased}
              >
                検索
              </button>
            </div>
            <div className="search-input-hint">※ 部分一致（姓のみで可）</div>
          </div>
        </div>
      </form>

      {searchResult && searchResult.length > 0 && !selectedDeceased &&
        <div className="order-search-result-panel">
          <div className="result-title font-weight-bold">＜検索結果＞</div>
          <div
            className="result-subtitle text-center text-danger font-weight-bold">下記よりお名前を選択してください
          </div>
          <div className="result-content">
            <ul className="deceased-list">
              {searchResult.map((deceased) =>
                <li onClick={() => setSelectedDeceased(deceased)}>
                  故　{deceased.DecreasedName}　様
                </li>
              )}
            </ul>
          </div>
        </div>
      }

      {searchResult && searchResult.length === 0 && !selectedDeceased && isSearched &&
        <div className="order-search-result-panel">
          <div className="result-title font-weight-bold">＜検索結果＞</div>
          <div
            className="result-subtitle text-center text-danger font-weight-bold">該当する情報は<br />見つかりませんでした
          </div>
        </div>
      }

      {selectedDeceased &&
        <div>
          <div className="order-search-result-panel">
            <div className="result-content">
              <div className="content-title font-weight-bold">
                ● 故人名
              </div>
              <div className="content-body">
                故　{selectedDeceased.DecreasedName}　様
              </div>

              <div className="content-title font-weight-bold">
                ● 通夜日時
              </div>
              <div className="content-body">
                {
                  selectedDeceased.VigilDateTime === "Invalid date" || selectedDeceased.VigilDateTime === ""
                    ? "執り行っておりません"
                    : new Date(selectedDeceased.VigilDateTime).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' }).slice( 0, -3) + "〜"
                }
              </div>

              <div className="content-title font-weight-bold">
                ● 葬儀日時
              </div>
              <div className="content-body">
                {new Date(selectedDeceased.FuneralDateTime).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' }).slice( 0, -3)}〜
              </div>

              <div className="content-title font-weight-bold">
                ● 会場情報
              </div>
              <div className="content-body">
                {halls.find((hall) => hall.HallId === selectedDeceased.HallId)?.HallName || "会場情報がありません"}
              </div>
            </div>
          </div>

          <p className="text-center font-weight-bold text-danger fs-4 mb-3">
            上記の情報でお間違いないかご確認ください
          </p>

          <Button className="btn next-button" variant="success" onClick={() => window.open(`${Constants.FUNERALS_WEB_URL}/${selectedDeceased.FuneralId}/attendance/content/select/kumotsu?isOrderOnly=true`)}>商品選択へ進む</Button>
        </div>
      }
    </div>
  );
};

const getFuneralList = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
      dateFrom: moment().format("YYYY-MM-DD"),
      dateTo: moment().add(14, 'days').format("YYYY-MM-DD"),
      hallId: "",
      sortBy: 'FuneralDateTime',
      sortDir: 'ASC',
    }
  };

  return await API.get('guest', '/funerals', options);
}

const getHallList = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
      keyword: "" // すべて取得
    }
  };

  return await API.get('guest', '/halls', options);
}

export default OrderForm;
