import styled from 'styled-components';
import Input from 'components/Input';

const Label = styled.label`
  margin-left: 1rem;
  width: 7rem;
`

export const Gender = (props) => {
  const { name, label, formik } = props;
  const hasError = formik && formik.errors[name];

  return (
    <>
      <label className="control-label">{label}</label>
      <div className={"form-check" + (hasError ? " is-invalid" : "")} style={{display: "flex", justifyContent: "flex-start"}}>
        <Label>
          <Input type="radio" name={name} value="M" className={"form-check-input" + (hasError ? " is-invalid" : "")} formik={formik} />男性
        </Label>
        <Label>
          <Input type="radio" name={name} value="W" className={"form-check-input" + (hasError ? " is-invalid" : "")} formik={formik} />女性
        </Label>
        <Label>
          <Input type="radio" name={name} value="O" className={"form-check-input" + (hasError ? " is-invalid" : "")} formik={formik} />その他
        </Label>
      </div>
      <div className="invalid-feedback">{formik.errors[name]}</div>
    </>
  )
}

export default Gender;