import React, { useRef } from 'react';
import { API } from 'aws-amplify';
import { useHistory, Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Pagination from 'components/Pagination';
import { useLoading } from 'components/Loading';

const ADMIN_COMPANY_ID = '0';

export const ItemList = (props) => {
  const PER_PAGE = 10;

  const [itemList, setItemList] = React.useState([]);
  const [paginatedList, setPaginatedList] = React.useState([]);
  const history = useHistory();
  const searchKey = useRef("");
  const loading = useLoading();
  
  const search = async () => {
    loading(true);
    const result = await getAdminItemList(ADMIN_COMPANY_ID, searchKey.current.value);
    const resultList = result.itemList.sort((a, b) => a.Id < b.Id ? -1 : 1);
    setItemList(result.itemList);
    setPaginatedList(resultList.slice(0, PER_PAGE));
    loading(false);
  }
  
  const create = () => {
    history.push("items/new");
  }
  
  return (
    <CommonLayout>
      <ContentHeader>
        商品一覧
      </ContentHeader>
      
      <div className="form-group">
        <Row>
          <Col md="6">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">検索条件</h3>
              </div>
              <div className="card-body">
                <label className="control-label">商品名</label>
                <input type="text" className="form-control" ref={searchKey} placeholder="商品名"/>
              </div>
              <div className="card-footer">
                <Button className="float-right" onClick={() => search()}>検索</Button>
                {' '}
                <Button variant="success" className="float-left" onClick={() => create()}>新規作成</Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
  
      <Row>
        <Col md="6">
          <div className="card">
            <div className="card-body p-0">
              <table className="table">
                <thead>
                  <tr>
                    <th>商品コード</th>
                    <th>商品名</th>
                    <th className="text-right">価格</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    paginatedList.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td><Link to={"items/" + row.ItemId}>{row.ItemId}</Link></td>
                          <td>{row.ItemName}</td>
                          <td className="text-right">{parseInt(row.Price, 10).toLocaleString()}</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <Pagination per={PER_PAGE} list={itemList} onChange={(list) => setPaginatedList(list)} />
            </div>
          </div>
        </Col>
      </Row>
    </CommonLayout>
  );
};

const getAdminItemList = async (companyId, keyword) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
      keyword: keyword
    }
  };

  return await API.get('product', '/admin/items', options);
}
  
export default ItemList;