import React, {useState, useEffect, useContext} from 'react';
import { API } from 'aws-amplify';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Row, Col, Button, Accordion } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import uuid from 'uuid';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import { useLoading } from 'components/Loading';
import Input from 'components/Input';
import Select from 'components/Select';
import useMessage from 'components/Message';
import { ZipCode } from 'components/CustomForms/ZipCode';
import { Prefecture } from 'components/CustomForms/Prefecture';
import { DateTime } from 'components/CustomForms/DateTime';
import { getBlob } from 'utils/request';
import * as Constants from '../../constants';

import TimelineLabel from 'components/CustomForms/TimelineLabel';
import TimelineItem from 'components/CustomForms/TimelineItem';
import TableAttendeeSummary from 'components/TableAttendeeSummary';
import {AuthContext} from "../../components/Contexts";
import { ZipCodeHall } from 'components/CustomForms/ZipCodeHall';
import { City } from 'components/CustomForms/City';
import { Town } from 'components/CustomForms/Town';
import { handleSplitData, requestFormData, validateHalfwidthNumber } from 'utils/helper';
import { calculateDate, calculateTime } from 'utils/funeral';

export const FuneralEdit = (props) => {
  const match = useRouteMatch("/:companyId");
  const companyId = match.params.companyId;
  const funeralId = props.match.params.funeralId;

  const newEntry = props.method === "new";

  const history = useHistory();
  const message = useMessage();

  const [halls, setHalls] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [allRooms, setAllRooms] = useState({});
  const [staffList, setStaffList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [funeralCode, setFuneralCode] = useState("");
  const [currentFuneral, setCurrentFuneral] = useState({});
  const [currentCompany, setCurrentCompany] = useState({});
  const loading = useLoading();
  const [vigilToFuneral, setVigilToFuneral] = useState(true);
  const [userLogsSummary, setUserLogsSummary] = useState({});
  const [lineUserId, setLineUserId] = useState('');
  const [activeKey, setActiveKey] = useState('');
  const [activeKeyAddress, setActiveKeyAddress] = useState('');
  const [listCity, setListCity] = useState([]);
  const [listTown, setListTown] = useState([]);
  const [itemDateTime, setItemDateTime] = useState(null);
  const [listScheduleTemplates, setListScheduleTemplates] = useState([]);
  const [isHall, setIsHall] = useState(true);

  const { userInfo } = useContext(AuthContext);

  const phonePattern = '^0(?!.*--)(?!.*-$)([0-9]{9,10}|[0-9]+(-[0-9]+)+)$'
  const numberPattern = (number) => `^[0-9０-９]{${number}}$`
  const validationSchema = Yup.object().shape({
    familyPhoneNo: Yup.string().trim().matches(new RegExp(phonePattern , 'u'),'10～11桁の半角数字でご入力ください'),
    familyZipCode: Yup.string().trim().matches(new RegExp(numberPattern(7), 'u'), '郵便番号はハイフンなしの7桁で入力してください').test("halfwidth", "ハイフンなしで半角数字でご入力ください。", validateHalfwidthNumber),
    decreasedFirstName: Yup.string().trim().required("故人名は必須です"),
    decreasedLastName: Yup.string().trim().required("故人名は必須です"),
    chiefMournerName: Yup.string().trim().required("喪主名は必須です"),
    deathDay: Yup.string().trim().required("没日は必須です"),
    funeralDateTime: Yup.string().trim().required("葬儀日時は必須です"),
    hall: Yup.string().trim().required("葬儀会場は必須です"),
    staff: Yup.string().trim().required("担当者は必須です"),
    hallZipCode: Yup.string().trim().matches(new RegExp(numberPattern(7), 'u'), '郵便番号はハイフンなしの7桁で入力してください').test("halfwidth", "ハイフンなしで半角数字でご入力ください。", validateHalfwidthNumber),
    brandName: Yup.string().trim().required("葬儀社名は必須です"),
});

const validationOtherHall = Yup.object().shape({
  familyPhoneNo: Yup.string().trim().matches(new RegExp(phonePattern , 'u'),'10～11桁の半角数字でご入力ください'),
  familyZipCode: Yup.string().trim().matches(new RegExp(numberPattern(7), 'u'), '郵便番号はハイフンなしの7桁で入力してください').test("halfwidth", "ハイフンなしで半角数字でご入力ください。", validateHalfwidthNumber),
  decreasedFirstName: Yup.string().trim().required("故人名は必須です"),
  decreasedLastName: Yup.string().trim().required("故人名は必須です"),
  chiefMournerName: Yup.string().trim().required("喪主名は必須です"),
  deathDay: Yup.string().trim().required("没日は必須です"),
  funeralDateTime: Yup.string().trim().required("葬儀日時は必須です"),
  hall: Yup.string().trim().required("葬儀会場は必須です"),
  staff: Yup.string().trim().required("担当者は必須です"),
  hallZipCode: Yup.string().trim().required("郵便番号は必須です").matches(new RegExp(numberPattern(7), 'u'), '郵便番号はハイフンなしの7桁で入力してください').test("halfwidth", "ハイフンなしで半角数字でご入力ください。", validateHalfwidthNumber),
  hallName: Yup.string().trim().required("会場名は必須です"),
  hallPrefecture: Yup.string().trim().required("都道府県は必須です"),
  hallCity: Yup.string().trim().required("市区郡は必須です"),
  hallTown: Yup.string().trim().required("町村は必須です"),
  hallAddress2: Yup.string().trim().required("住所（番地・建物名）は必須です"),
});

  const formik = useFormik({
    initialValues: {
      decreasedFirstName: "",
      decreasedLastName: "",
      decreasedNameRuby: "",
      chiefMournerName: "",
      chiefMournerNameRuby: "",
      relationship: "",
      familyAddressName: "",
      familyPhoneNo: "",
      familyZipCode: "",
      familyPrefecture: "",
      familyAddress1: "",
      familyAddress2: "",
      email: "",
      userName: "",
      furigana: "",
      phoneNo: "",
      zipCode: "",
      prefecture: "",
      address1: "",
      address2: "",
      age: "",
      gender: "",
      hall: "",
      room: "",
      hallName: "",
      hallZipCode: "",
      hallPrefecture: "",
      hallAddress1: "",
      hallAddress2: "",
      staff: "",
      deathDay: moment().format("YYYY-MM-DD"),
      vigilDateTime: moment().format("YYYY-MM-DD HH:00"),
      funeralDateTime: moment().format("YYYY-MM-DD HH:00"),
      fortyNinthDate: moment().add(48, 'days').format("YYYY-MM-DD"),
      vigilItems: [],
      vigilToFuneral: moment().format("YYYY-MM-DD HH:00"),
      funeralItems: [],
      funeralToFortyNinth: moment().format("YYYY-MM-DD HH:00"),
      fortyNinthItems: [],
      useVigilMoney: true,
      useFuneralMoney: true,
      useFortyNinthMoney: true,
      companyMessage: "",
      topPublic: false,
      vigilMessage: "",
      funeralMessage: "",
      funeralFormat: "",
      enableGiftMoney: false,
      enableMoneyFlow: true,
      sendReturnGiftDate: "",
      hallPrefectureName: "",
      hallCity: "",
      hallTown: "",
      enablePortalForFuneral: true,
      brandName: "",
    },
    validationSchema: isHall ? validationSchema : validationOtherHall,
    onSubmit: async (values) => {
      const convertValues = {};
      // eslint-disable-next-line
      Object.keys(values)?.map((key) => {
        Object.assign(convertValues, {
          [key]: typeof values?.[key] === 'string' ? values?.[key]?.trim() : values?.[key],
        });
      });

      if (formik.isValid) {
        const formValues = {
          ...convertValues,
          isDraft: false,
          fortyNinthDate: convertValues?.fortyNinthDate !== '' ? convertValues?.fortyNinthDate : moment(convertValues?.deathDay).add(48, 'days').format("YYYY-MM-DD")
        };
        if (newEntry) {
          loading(true);
          try {
            const response = await createFuneral(companyId, formValues, vigilToFuneral);
            if (!response.error) {
              message.alert.success("葬家を登録しました");
              history.goBack();
            } else {
              message.alert.danger("システムエラーが発生しました");
            }
          } catch (e) {
            if (e.response.status === 400) {
              message.alert.danger(e.response.data.error);
            } else {
              message.alert.danger("システムエラーが発生しました");
            }
          } finally {
            loading(false);
          }
        } else {
          loading(true);
          try {
            if (currentFuneral?.funeralTimeSchdule && Object.keys(currentFuneral?.funeralTimeSchdule)?.length > 0 && (currentFuneral?.vigilDateTime !== formValues?.vigilDateTime || currentFuneral?.funeralDateTime !== formValues?.funeralDateTime)) {
              await saveTimeSchedule(formValues);
            }
            const response = await updateFuneral(companyId, funeralId, formValues, funeralCode, vigilToFuneral, formik);
            if (!response.error) {
              message.alert.success("葬家の情報を更新しました");
              // history.goBack();
            } else {
              message.alert.danger("システムエラーが発生しました");
            }
          } catch (e) {
            if (e?.response?.status === 400) {
              message.alert.danger(e?.response?.data?.error);
            } else {
              message.alert.danger("システムエラーが発生しました");
            }
          } finally {
            loading(false);
          }
        }
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });
  const setFieldValue = formik.setFieldValue;

  const saveTimeSchedule = async (formValues) => {
    try {
      setTimeout(async () => {
        const currentTemplate = currentFuneral?.funeralTimeSchdule?.ScheduleTemplateId && listScheduleTemplates?.find((item)=> item?.Id === currentFuneral?.funeralTimeSchdule?.ScheduleTemplateId);
        if (currentFuneral?.funeralTimeSchdule?.ScheduleSets?.length > 0) {
          if (currentTemplate?.Id) {
            if (currentTemplate?.ScheduleSets?.length > 0) {
              const scheduleSets = [];
              let index = 0;
              currentTemplate?.ScheduleSets?.forEach((values) => {
                if (values?.TimeSettingType !== 'wake-time' || formValues?.vigilDateTime !== '') {
                    let dateItem = calculateDate(values?.TimeSettingType  || 'time-settings', formValues, 'funeral', currentFuneral?.funeralTimeSchdule?.ScheduleSets?.[index]?.Date);
                    dateItem = dateItem ? dayjs(dateItem).format('YYYY-MM-DD') : null;
                    
                    scheduleSets.push({
                    timeSettingType : values?.TimeSettingType  || 'time-settings',
                    title: values?.Title || '',
                    date: dateItem,
                    items: values?.Items?.map((item, itemIndex) => ({
                      itemName: item?.ItemName || '',
                      hour: calculateTime(values?.TimeSettingType  || 'time-settings', item, formValues, 'funeral', currentFuneral?.funeralTimeSchdule?.ScheduleSets?.[index]?.Items?.[itemIndex])?.hour || '',
                      minute: calculateTime(values?.TimeSettingType  || 'time-settings', item, formValues, 'funeral', currentFuneral?.funeralTimeSchdule?.ScheduleSets?.[index]?.Items?.[itemIndex])?.minute || '',
                      comment: item?.Comment || '',
                    })) || [],
                  });
                  index++;
                }
              });

              const scheduleValues = {
                scheduleSets: scheduleSets,
                companyId: companyId,
                funeralId: funeralId,
                scheduleTemplateId: currentFuneral?.funeralTimeSchdule?.ScheduleTemplateId,
                isDraft: currentFuneral?.funeralTimeSchdule?.IsDraft,
                sendLine: false,
              };

              const responseSchedule = await setFuneralTimeSchedule(scheduleValues);

              if (responseSchedule?.error) {
                message.alert.danger("タイムスケジュールを保存できませんでした。もう一度お試しください。");
              }
            }
          } else {
            message.alert.danger("タイムスケジュールを保存できませんでした。もう一度お試しください。");
          }
        }
      }, 1000);
    } catch (e) {
      if (e?.response?.status === 400) {
        message.alert.danger(e?.response?.data?.error || "タイムスケジュールを保存できませんでした。もう一度お試しください。");
      } else {
        message.alert.danger("タイムスケジュールを保存できませんでした。もう一度お試しください。");
      }
    }
  }

  useEffect(() => {
    if (formik?.errors?.userName || formik?.errors?.email) {
      setActiveKey('0');
    }

    if( formik?.errors?.familyZipCode|| formik?.errors?.familyPhoneNo){
      setActiveKeyAddress('0');
    }

    // eslint-disable-next-line
  }, [formik?.errors]);

  const handleCallApiGetCityAndTown = async (funeral) => {
    const prefecture = Constants.PREFECTURES.find((item) => item.key === funeral?.hallPrefecture)
    setListCity(await requestGetListData("siku", prefecture?.value || '') || []);
    setListTown(await requestGetListData("tyou", prefecture?.value || '', funeral?.hallCity || '') || []);
  }

  useEffect(() => {
    if (newEntry) {
      const fetch = async () => {
        loading(true);
        try {
          const template = await getFuneralTemplate(companyId);
          if (!template.error) {
            setItemDateTime(template.itemDateTime);
            setHalls(template.halls);
            setRooms([]);
            setAllRooms(template.rooms);
            setStaffList(template.staffList);
            setItemList(template.itemList);
            setFieldValue("funeralId", uuid.v4());
            setFieldValue("vigilItems", template.vigilItems);
            setFieldValue("funeralItems", template.funeralItems);
            setFieldValue("fortyNinthItems", template.fortyNinthItems);
            setFieldValue("useVigilMoney", template.useVigilMoney);
            setFieldValue("useFuneralMoney", template.useFuneralMoney);
            setFieldValue("useFortyNinthMoney", template.useFortyNinthMoney);
            setFieldValue("staff", template.staffList.find(staff => staff.UserId === userInfo?.email)?.UserId ?? "");
            setDefaultCompanyMessage();
          } else {
            message.alert.danger("システムエラーが発生しました");
          }
          const company = await getCompany(companyId);
          if(!company.error) {
            setCurrentCompany(company);
            setFieldValue("brandName", company.brandName && company.brandName !== "" ? company.brandName : company.companyName);
          }
        } catch (e) {
          if (e.response.status === 400) {
            message.alert.danger(e.response.data.error);
          } else {
            message.alert.danger("システムエラーが発生しました");
          }
        } finally {
          loading(false);
        }
      }
      fetch();
    } else {
      const fetch = async (funeralId) => {
        loading(true);
        try {
          const [scheduleTemplates, funeral, company] = await Promise.all([
            getTimeScheduleTemplates(companyId),
            getFuneral(companyId, funeralId),
            getCompany(companyId),
          ]);

          if(!company.error) {
            setCurrentCompany(company);
          }

          const brandName = getBrandName(funeral, company);
          
          setListScheduleTemplates(scheduleTemplates?.scheduleTemplateList || []);
          if (!funeral.error) {
            setCurrentFuneral(funeral);
            setVigilToFuneral(funeral.vigilDateTime !== "")
            // 事前申請の場合と通常の編集で初期化処理が変わる
            const template = await getFuneralTemplate(companyId);
            setItemDateTime(template.itemDateTime);
            if (funeral.preRegistration) {
              setHalls(template.halls);
              setRooms([]);
              setAllRooms(template.rooms);
              setStaffList(template.staffList);
              setItemList(template.itemList);
              setFuneralCode(funeral.funeralCode);

              Object.keys(funeral).map((key) => {
                return setFieldValue(key, funeral[key]);
              });
              setFieldValue("vigilItems", template.vigilItems);
              setFieldValue("funeralItems", template.funeralItems);
              setFieldValue("fortyNinthItems", template.fortyNinthItems);
              setFieldValue("staff", template.staffList.find(staff => staff.UserId === userInfo?.email)?.UserId ?? "");
              setFieldValue("brandName", brandName);
              setDefaultCompanyMessage();
            } else {
              funeral.decreasedLastName = funeral?.familyName || "";
              funeral.decreasedFirstName = funeral?.decreasedName?.replace(`${funeral?.familyName} `, '') || "";

              setHalls(funeral.halls);
              if(funeral.hall === -1){
                setIsHall(false);
                const prefectureName = Constants.PREFECTURES.find((e) => e.key ===  funeral?.hallPrefecture);
                formik.setValues({
                  ...formik.values,
                  "hallPrefectureName": prefectureName?.value ?? "",
                  "hallCity": funeral?.hallCity ?? "",
                  "hallTown": funeral?.hallTown ?? ""
                });
                handleCallApiGetCityAndTown(funeral);
              }
              setRooms(isNaN(funeral.hall) || funeral.hall === -1 ? [] : funeral.rooms[funeral.hall]);
              setAllRooms(funeral.rooms);
              setStaffList(funeral.staffList);
              setItemList(funeral.itemList);
              setFuneralCode(funeral.funeralCode);
              setLineUserId(funeral.lineUserId || "");
              Object.keys(funeral).map((key) => {
                return setFieldValue(key, funeral[key]);
              });
              setFieldValue("brandName", brandName);
              if (!funeral.companyMessage) setDefaultCompanyMessage();
              // ユーザーログを取得
              const result = await getUserLogsSummary(funeralId);
              setUserLogsSummary(result);
            }
          } else {
            message.alert.danger("システムエラーが発生しました");
          }
        } catch (e) {
          if (e.response.status === 400) {
            message.alert.danger(e.response.data.error);
          } else {
            message.alert.danger("システムエラーが発生しました");
          }
        } finally {
          loading(false);
        }
      }
      fetch(funeralId);
    }
    // eslint-disable-next-line
  }, []);

  // Handle delete funeral
  const handleDelete = async () => {
    message.confirm.danger({
      title: "葬家を削除",
      message: "削除してもよろしいですか？",
      onSubmit: async () => {
        await deleteFuneral(companyId, funeralId);
        message.alert.success("葬家を削除しました");
        history.goBack();
      }
    });
  }

  // Handle save funeral as draft
  const handleSaveDraft = async () => {
    const convertValues = {};
    // eslint-disable-next-line
    Object.keys(formik.values)?.map((key) => {
      Object.assign(convertValues, {
        [key]: typeof formik.values?.[key] === 'string' ? formik.values?.[key]?.trim() : formik.values?.[key],
      });
    });
    const formValues = {
      ...convertValues,
      isDraft: true,
      fortyNinthDate: convertValues?.fortyNinthDate !== '' ? convertValues?.fortyNinthDate : moment(convertValues?.deathDay || new Date()).add(48, 'days').format("YYYY-MM-DD")
    };

    const errors = {};
  
    if (formValues?.decreasedFirstName.trim() === '') {
      Object.assign(errors, {decreasedFirstName: '故人名は必須です'});
    }

    if (formValues?.decreasedLastName.trim() === '') {
      Object.assign(errors, {decreasedLastName: '故人名は必須です'});
    }

    if (Object.keys(errors)?.length > 0) {
      formik.setErrors(errors);
    } else {
      if (newEntry) {
        loading(true);
        await createFuneral(companyId, formValues, vigilToFuneral);
        message.alert.success("葬家を登録しました");
        loading(false);
        history.goBack();
      } else {
        loading(true);
        if (currentFuneral?.funeralTimeSchdule && Object.keys(currentFuneral?.funeralTimeSchdule)?.length > 0 && (currentFuneral?.vigilDateTime !== formValues?.vigilDateTime || currentFuneral?.funeralDateTime !== formValues?.funeralDateTime)) {
          await saveTimeSchedule(formValues);
        }
        await updateFuneral(companyId, funeralId, formValues, funeralCode, vigilToFuneral, formik);
        message.alert.success("葬家の情報を更新しました");
        loading(false);
        history.goBack();
      }
    }
  }

  const handleSendEmail = async () => {
    const { error } = await sendEmailUser(formik.values.email, funeralId);
    if (error) {
      message.alert.danger("メールを送信できませんでした。");
      return
    }
    message.alert.success("メールを送信しました。");
  }

  const handleResendLineMessage = async () => {
    const { error } = await resendLineMessage(lineUserId, funeralId, formik.values.userName);
    if (error) {
      message.alert.danger("メッセージを送信できませんでした。");
      return
    }
    message.alert.success("メッセージを送信しました。");
  }

  const handleDownloadObituary = async () => {
    try {
      loading(true);
      const response = await downloadObituary(funeralId);
      if (response.filename) {
        const pdfData = await getBlob(Constants.IMAGE_BUCKET_URL + response.filename, { method: 'GET' });
        saveAs(pdfData, '訃報紙.pdf');
      }
    } catch (e) {
      if (e?.response?.status === 400) {
        message.alert.danger(e?.response?.data?.error);
      } else {
        message.alert.danger("システムエラーが発生しました");
      }
    } finally {
      loading(false);
    }
  }

  const handleChangeFamilyZipCode = (result) => {
    formik.setFieldValue("familyPrefecture", result.prefCode);
    formik.setFieldValue("familyAddress1", result.city.kanji + result.town.kanji);
  }

  const handleChangeHall = (e) => {
    if (e.target.value !== '-1') {
      setIsHall(true);
      setRooms(allRooms[e.target.value] || []);
    } else {
      setIsHall(false);
      setRooms([]);
    }
    formik.setFieldValue("hall", e.target.value);
    formik.setFieldValue("room", "");
  }

  const handleChangeZipCode = async (result) => {
    formik.setFieldError('hallZipCode', null)
    if (!result) {
      // show error
      if(!validateHalfwidthNumber(formik?.values?.hallZipCode)){
        formik.setFieldError('hallZipCode', "ハイフンなしで半角数字でご入力ください。");
      } else {
        formik.setFieldError('hallZipCode', "郵便番号が正しくありません。")
      }
      formik.setValues({
        ...formik.values,
        "hallPrefecture": "",
        "hallCity": "",
        "hallTown": "",
        "hallAddress1": ""
      });
      setListCity([]);
      setListTown([]);
      return
    }
    formik.setValues({
      ...formik.values,
      "hallPrefecture": result.prefCode,
      "hallPrefectureName": result.prefecture,
      "hallCity": result.city,
      "hallTown": result.town,
      "hallAddress1": result.city + result.town
    });
    // call api get list city and town
    setListCity(await requestGetListData("siku", result.prefecture || '') || []);
    setListTown(await requestGetListData("tyou", result.prefecture || '', result.city || '') ||[]);
  }

  const handleChangePrefecture = async (result) => {
    formik.setValues({
      ...formik.values,
      "hallPrefecture": result.prefecture.key,
      "hallPrefectureName": result.prefecture.value,
      "hallCity": "",
      "hallTown": "",
      "hallZipCode": ""
    });
    formik.setFieldError('hallZipCode', null);
    // call api get list city of prefecture
    setListCity(await requestGetListData("siku", result.prefecture.value || '') || []);
    setListTown([])
  }

  const handleChangeCity = async (result) => {
    formik.setValues({
      ...formik.values,
      "hallCity": result.city,
      "hallTown": "",
      "hallZipCode": ""
    });
    formik.setFieldError('hallZipCode', null);
    // call api get list town of city
    setListTown(await requestGetListData("tyou", formik.values.hallPrefectureName || '', result.city || '') || []);
  }

  const handleChangeTown = async (result) => {
    formik.setFieldValue("hallTown", result.town)
    formik.setFieldValue("hallAddress1", formik.values.hallCity + result.town)
    const responseZipCode = await requestGetListData("zipcode", formik.values.hallPrefectureName || '', formik.values.hallCity || '', result.town || '');
    formik.setFieldValue("hallZipCode", responseZipCode ? responseZipCode[0]?.value : "")
  }

  const handleChangeVigilDateTime = (e) => {
    if (!itemDateTime || Object.keys(itemDateTime.vigilToFuneral).length !== 0) return;
    setFieldValue("vigilToFuneral", formik.values.vigilDateTime);
  }

  const copyChiefMournerInfo = () => {
    setFieldValue("userName", formik.values.chiefMournerName);
  }

  const setDefaultCompanyMessage = () => {
    setFieldValue("companyMessage", "会場へのご参列が難しい方は当ページより、贈り物が可能となっております。\n"
      + "当社が真心を込めてお届けさせて頂きます。\n"
      + "オンラインでの参列選択をされますと、故人様や思い出のお写真の閲覧が可能になります。\n"
      + "また皆様が、故人様との思い出のお写真をお持ちでしたら投稿頂く事も可能となり、ご許可いただけるお写真については贈り物アルバムへ公開する事も可能です。"
    )
  }

  const handleChangeVigilToFuneral = () => {
    setVigilToFuneral((vigilToFuneral) => !vigilToFuneral);
  };

  const handleChangeVigilMessage = () => {
    setFieldValue("vigilMessage", formik.values.vigilMessage);
  }

  const handleChangeFuneralMessage = () => {
    setFieldValue("funeralMessage", formik.values.funeralMessage);
  }

  const handleChangeFuneralFormat = () => {
    setFieldValue("funeralFormat", formik.values.funeralFormat);
  }

  const onChangeGiftMoney = (e) => {
    formik.setFieldValue("enableGiftMoney", e.target.value === "true");
    if(e.target.value === "true"){
      formik.setFieldValue("sendReturnGiftDate", "today");
    } else {
      formik.setFieldValue("sendReturnGiftDate", "");
    }
  }

  // 没日が変更されたら切り替わるようにした
  useEffect(() => {
    if (formik.values.deathDay) {
      setFieldValue("deathDate", moment(formik.values.deathDay).format("YYYY-MM-DD"));
      setFieldValue("vigilDateTime", moment(formik.values.deathDay).add(1, 'days').hour(18).format("YYYY-MM-DD HH:mm"));
      setFieldValue("funeralDateTime", moment(formik.values.deathDay).add(2, 'days').hour(12).format("YYYY-MM-DD HH:mm"));
      setFieldValue("fortyNinthDate", moment(formik.values.deathDay).add(48, 'days').format("YYYY-MM-DD"));

      setFieldValue("vigilToFuneral", moment(formik.values.deathDay).add(1, 'days').hour(18).format("YYYY-MM-DD HH:mm"));
      setFieldValue("funeralToFortyNinth", moment(formik.values.deathDay).add(2, 'days').hour(12).format("YYYY-MM-DD HH:mm"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.deathDay]);

  // 通夜日時が変更されたら商品切り替えもテンプレートに沿って変更するようにした
  useEffect(() => {
    if (!itemDateTime || formik.values.vigilDateTime === '' || !itemDateTime.vigilToFuneral || Object.keys(itemDateTime.vigilToFuneral).length === 0) return;
    const vigilToFuneralItemDateTime = itemDateTime.vigilToFuneral;

    // 時間をパースする
    const dateTime = vigilToFuneralItemDateTime.dateTime.split(':');
    const hour = dateTime[0];
    const minute = dateTime[1];

    // 数時間前パターン
    if (vigilToFuneralItemDateTime.type === 'FEW_HOURS_AGO') {
      const vigilToFuneral = moment(formik.values.vigilDateTime).subtract(hour, 'hours').subtract(minute, 'minutes').format("YYYY-MM-DD HH:mm");
      setFieldValue("vigilToFuneral", vigilToFuneral);
    }

    // 日時固定パターン
    if (vigilToFuneralItemDateTime.type === 'FIXED_DATE_TIME') {
      const subtractDay = vigilToFuneralItemDateTime.fixDateTimeDay === "TODAY" ? 0 : 1;
      const vigilToFuneral = moment(formik.values.vigilDateTime).subtract(subtractDay, 'days').hour(hour).minute(minute).format("YYYY-MM-DD HH:mm");
      setFieldValue("vigilToFuneral", vigilToFuneral);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.vigilDateTime, itemDateTime]);

  // 葬儀日時が変更されたら商品切り替えもテンプレートに沿って変更するようにした
  useEffect(() => {
    if (!itemDateTime || formik.values.funeralDateTime === '' || !itemDateTime.funeralToFortyNinth || Object.keys(itemDateTime.funeralToFortyNinth).length === 0) return;
    const funeralToFortyNinthItemDateTime = itemDateTime.funeralToFortyNinth;

    // 時間をパースする
    const dateTime = funeralToFortyNinthItemDateTime.dateTime.split(':');
    const hour = dateTime[0];
    const minute = dateTime[1];

    // 数時間前パターン
    if (funeralToFortyNinthItemDateTime.type === 'FEW_HOURS_AGO') {
      const funeralToFortyNinth = moment(formik.values.funeralDateTime).subtract(hour, 'hours').subtract(minute, 'minutes').format("YYYY-MM-DD HH:mm");
      setFieldValue("funeralToFortyNinth", funeralToFortyNinth);
    }

    // 日時固定パターン
    if (funeralToFortyNinthItemDateTime.type === 'FIXED_DATE_TIME') {
      const subtractDay = funeralToFortyNinthItemDateTime.fixDateTimeDay === "TODAY" ? 0 : 1;
      const funeralToFortyNinth = moment(formik.values.funeralDateTime).subtract(subtractDay, 'days').hour(hour).minute(minute).format("YYYY-MM-DD HH:mm");
      setFieldValue("funeralToFortyNinth", funeralToFortyNinth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.funeralDateTime, itemDateTime]);

  const requestGetListData = async (type, prefecture = null, city = null, town = null) => {
    loading(true);
    const formData = requestFormData(type, prefecture, city, town)
    const response = await getAddress(formData);
    let results = [];
    if (response && response.includes('true')) {
      results = handleSplitData(response)
    }
    loading(false);
    return results
  }


  return (
    <CommonLayout>
      <ContentHeader>
        {newEntry ? "葬家　新規作成" : "葬家編集"}
      </ContentHeader>
      <form onSubmit={formik.handleSubmit} className='funeral-edit-container'>
        <Row>
          <Col md="12">
            <div className="card">
              <div className="card-body">
                <Row>
                  <Col sm="12" md="5" className="mb-2">
                    <h4>葬家・喪主情報</h4>
                    <span className="text-danger fs-6">*は必須項目になります。</span><br/>
                    <span className="text-primary fs-6">（*は一時保存の必須項目）</span>
                  </Col>
                  <Col sm="12" md="7">
                    {
                      !newEntry ?
                        <div className="text-end">
                          <span className="h5 me-5">訃報NO：{funeralCode}</span>
                          <Button className="mb-2" variant="secondary" onClick={() => { history.push(`${props.match.params.funeralId}/order`) }}>注文リスト</Button>
                          {' '}
                          <Button className="mb-2" variant="secondary" onClick={() => { history.push(`${props.match.params.funeralId}/kouden`) }}>香典（返礼品）リスト</Button>
                          {' '}
                          <Button className="mb-2" variant="secondary" onClick={() => { history.push(`${props.match.params.funeralId}/time-schedule`) }}>タイムスケジュール</Button>
                        </div>
                        : <></>
                    }
                  </Col>
                </Row>
                <div className="form-group">
                  <Row>
                    <Col md="6" sm="6" xs="6">
                      <label className="control-label">故人名（姓）※葬家名に反映 <span className="text-primary">＊</span></label>
                      <Input type="text" name="decreasedLastName" className="form-control" formik={formik} />
                    </Col>
                    <Col md="6" sm="6" xs="6">
                      <label className="control-label">故人名（名）<span className="text-primary">＊</span></label>
                      <Input type="text" name="decreasedFirstName" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col md="6" xs="6">
                      <label className="control-label">故人名（姓名ふりがな）</label>
                      <Input type="text" name="decreasedNameRuby" className="form-control" formik={formik} />
                    </Col>
                    <Col md="6" xs="6">
                      <label className="control-label">年齢（フリー入力）</label>
                      <Input type="text" name="age" maxLength="7" className="form-control" placeholder="例）享年80歳" formik={formik} />
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col md="12">
                      <label className="control-label">没日 <span className="text-danger">＊</span></label>
                      <Input type="date" name="deathDay" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col md="12">
                      <label className="control-label">喪主名（姓名） <span className="text-danger">＊</span></label>
                      <Input type="text" maxLength="30" name="chiefMournerName" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col md="6" xs="6">
                      <label className="control-label">喪主名（姓名ふりがな）</label>
                      <Input type="text" name="chiefMournerNameRuby" className="form-control" formik={formik} />
                    </Col>
                    <Col md="6" xs="6">
                      <label className="control-label">続柄</label>
                      <Input type="text" maxLength="7" name="relationship" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <span className="fs-4">お届け先情報　</span>
                <Accordion activeKey={activeKeyAddress} onSelect={(key) => setActiveKeyAddress(key)}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                    お届け先情報
                    </Accordion.Header>
                    <Accordion.Body>
                    <span>※告別式後の商品お届け先</span>
                    <div className="form-group">
                      <Row>
                        <Col md="6">
                          <label className="control-label">氏名</label>
                          <Input type="text" name="familyAddressName" className="form-control" formik={formik} />
                        </Col>
                        <Col md="6">
                          <label className="control-label">電話番号</label>
                          <Input type="text" name="familyPhoneNo" className="form-control" formik={formik} />
                        </Col>
                      </Row>
                    </div>
                    <div className="form-group">
                      <Row>
                        <Col md="3">
                          <label className="control-label">郵便番号</label>
                          <ZipCode name="familyZipCode" className="form-control" formik={formik} onChange={handleChangeFamilyZipCode} />
                        </Col>
                        <Col md="3">
                          <label className="control-label">都道府県</label>
                          <Prefecture type="text" name="familyPrefecture" className="custom-select" formik={formik} />
                        </Col>
                      </Row>
                    </div>
                    <div className="form-group">
                      <Row>
                        <Col md="6">
                          <label className="control-label">住所１</label>
                          <Input type="text" name="familyAddress1" className="form-control" formik={formik} />
                        </Col>
                        <Col md="6">
                          <label className="control-label">住所２（建物名）</label>
                          <Input type="text" maxLength="50" name="familyAddress2" className="form-control" formik={formik} />
                        </Col>
                      </Row>
                    </div>
                </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <br />
                <h6>※告別式後の商品郵送先となります。</h6>

                <br />
                <h4>代表者アカウント情報</h4>
                <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      代表者情報
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="form-group">
                        <Button variant="info" size="sm" onClick={copyChiefMournerInfo}>喪主の情報を代表者にコピーする</Button>
                      </div>
                      <div className="form-group">
                        <label className="control-label">代表者名</label>
                        <Input type="text" name="userName" className="form-control" formik={formik} />
                      </div>
                      <div className="form-group">
                        <label className="control-label">代表者・メールアドレス</label>
                        <Input type="email" name="email" className="form-control" formik={formik} />
                      </div>
                      <div className="form-group">
                        <label className="control-label">葬儀社挨拶</label>
                        <Input type="textarea" name="companyMessage" className="form-control" formik={formik} style={{ height: "150px" }} />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <br/>
                <div className="form-group">
                  <Row>
                    <Col md="12">
                      <label className="control-label">葬儀社名 <span className="text-danger">＊</span></label>
                      <Input type="text" maxLength="30" name="brandName" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <br></br>
                <h4>葬儀会場情報</h4>
                <div className="form-group">
                  <Row>
                    <Col md="4">
                      <label className="control-label">葬儀会場 <span className="text-danger">＊</span></label>
                      <select className={"custom-select" + (formik.errors.hall ? " is-invalid" : "")} name="hall" value={formik.values.hall} onChange={handleChangeHall}>
                        <option></option>
                        {
                          halls.map((hall, i) => <option key={i} value={hall.id}>{hall.name}</option>)
                        }
                        <option value="-1">その他会場</option>
                      </select>
                      <div className="invalid-feedback">{formik.errors.hall}</div>
                    </Col>

                    { String(formik.values.hall) === '-1' ? (
                        <>
                          <Col md={{ span: 12, order: 3 }} xs={{ order: 2 }}>
                            <br/>
                            <Row className={'mb-3'}>
                              <Col md={4}>
                                <label className="control-label">会場名<span className="text-danger">＊</span></label>
                                <Input type="text" maxLength="50" name="hallName" className="form-control" formik={formik} onChange={() => setFieldValue("hallName", formik.values.hallName)} />
                              </Col>
                            </Row>

                            <Row>
                              <Col md={4} className={'mb-3'}>
                                <label className="control-label">郵便番号<span className="text-danger">＊</span></label>
                                <ZipCodeHall name="hallZipCode" className="form-control" formik={formik} onChange={handleChangeZipCode} />
                                <div className="invalid-feedback">{formik.errors.hallZipCode}</div>
                              </Col>

                              <Col md={4} className={'mb-3'}>
                                <label className="control-label">都道府県<span className="text-danger">＊</span></label>
                                <Prefecture type="text" name="hallPrefecture" className="form-control" formik={formik} onChange={handleChangePrefecture}/>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={6} className={'mb-3'}>
                                <label className="control-label">市区郡<span className="text-danger">＊</span></label>
                                <City type="text" name="hallCity" className="form-control" formik={formik} listCity={listCity} onChange={handleChangeCity} />
                              </Col>

                              <Col md={6} className={'mb-3'}>
                                <label className="control-label">町村<span className="text-danger">＊</span></label>
                                <Town type="text" name="hallTown" className="form-control" formik={formik} listTown={listTown} onChange={handleChangeTown} />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} className={'mb-3'}>
                                  <label className="control-label">住所（番地・建物名）<span className="text-danger">＊</span></label>
                                  <Input type="text" maxLength="50" name="hallAddress2" className="form-control" formik={formik} />
                              </Col>
                              </Row>
                          </Col>

                          <Col md={{ span: 4, order: 2 }} xs={{ order: 3 }}>
                            <label className="control-label">担当者</label>
                            <Select className="custom-select" name="staff" formik={formik}>
                              <option></option>
                              {
                                staffList.map((staff, i) => <option key={i} value={staff.UserId}>{staff.UserName}</option>)
                              }
                            </Select>
                          </Col>
                        </>
                    ) : (
                        <>
                          <Col md="4">
                            <label className="control-label">部屋名</label>
                            <select className={"custom-select" + (formik.errors.room ? " is-invalid" : "")} name="room" value={formik.values.room} onChange={(e) => formik.setFieldValue("room", e.target.value)}>
                              <option></option>
                              {
                                rooms.map((room, i) => <option key={i} value={room}>{room}</option>)
                              }
                            </select>
                            <div className="invalid-feedback">{formik.errors.room}</div>
                          </Col>

                          <Col md="4">
                            <label className="control-label">担当者 <span className="text-danger">＊</span></label>
                            <Select className="custom-select" name="staff" formik={formik}>
                              <option></option>
                              {
                                staffList.map((staff, i) => <option key={i} value={staff.UserId}>{staff.UserName}</option>)
                              }
                            </Select>
                          </Col>
                        </>
                    ) }

                    {/* その他会場を選択した場合非表示 */}

                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="4">
                      <label className="control-label">通夜日時</label>
                    </Col>
                  </Row>
                  <div>
                    <Row>
                      <Col md="6">
                        <span className="container-vigil">
                          <input type="checkbox" checked={!vigilToFuneral} onChange={handleChangeVigilToFuneral} />
                          <label className="checkbox-vigil">通夜式を開催しない</label>
                        </span>
                        {vigilToFuneral &&
                          <DateTime name="vigilDateTime" className="form-control" formik={formik} onChange={handleChangeVigilDateTime} />
                        }
                      </Col>
                      <Col md="1"></Col>
                      <Col md="5">
                        <label className="control-label margin-label">通夜式コメント（23文字以内）</label>
                        <Input type="text" name="vigilMessage" className="form-control" maxLength="23" formik={formik} onChange={handleChangeVigilMessage} />
                      </Col>
                    </Row>
                  </div>

                  <br></br>

                  <Row>
                    <Col md="6">
                      <label className="control-label">葬儀日時 <span className="text-danger">＊</span></label>
                      <DateTime name="funeralDateTime" className="form-control" formik={formik} />
                    </Col>
                    <Col md="1"></Col>
                    <Col md="5">
                      <label className="control-label margin-label">葬儀式コメント（23文字以内）</label>
                      <Input type="text" name="funeralMessage" className="form-control" maxLength="23" formik={formik} onChange={handleChangeFuneralMessage} />
                    </Col>
                  </Row>

                  <br></br>
                  <Row>
                    <Col md="6">
                      <label className="control-label">注文期限（四十九日）</label>
                      <Input type="date" name="fortyNinthDate" className="form-control" formik={formik} />
                    </Col>
                    <Col md="1"></Col>
                    <Col md="5">
                      <label className="control-label margin-label">形式（23文字以内）</label>
                      <Input type="text" name="funeralFormat" className="form-control" maxLength="23" formik={formik} onChange={handleChangeFuneralFormat} />
                    </Col>
                  </Row>
                </div>
                <br></br>
                <h4>商品情報</h4>
                <Row>
                  {currentCompany?.hasIpadId && (
                    <Col md="4" lg="4">
                      <label className="control-label">会場香典</label>
                      <div className='funeralSettingGift'>
                        <select className={"custom-select" + (formik.errors.enableGiftMoney ? " is-invalid" : "")} name="enableGiftMoney" value={formik.values.enableGiftMoney} onChange={(e) => onChangeGiftMoney(e)}>
                          <option value="true">会場香典を受け取る</option>
                          <option value="false">会場香典を受け取らない </option>
                        </select>
                      </div>
                    </Col>
                  )}
                  {formik?.values?.enableGiftMoney && (
                    <Col md="4" lg="4">
                    <label className="control-label">返礼品</label>
                    <div className='funeralSettingGift'>
                      <select className={"custom-select" + (formik.errors.sendReturnGiftDate ? " is-invalid" : "")} name="sendReturnGiftDate" value={formik.values.sendReturnGiftDate} onChange={(e) => formik.setFieldValue("sendReturnGiftDate", e.target.value)}>
                        <option value="today">返礼品を当日返し</option>
                        <option value="tomorrow">返礼品を後日返し</option>
                      </select>
                    </div>
                    </Col>
                    )}
                  </Row>
                  <br></br>
                  <Row>
                    <Col md="4" lg="4">
                    <label className="control-label">オンライン香典を</label>
                    <div className='funeralSettingGift'>
                      <select className={"custom-select" + (formik.errors.enableMoneyFlow ? " is-invalid" : "")} name="enableMoneyFlow" value={formik.values.enableMoneyFlow} onChange={(e) => formik.setFieldValue("enableMoneyFlow", e.target.value === "true")}>
                        <option value="true">オンライン香典を有効にする</option>
                        <option value="false">オンライン香典を無効にする</option>
                      </select>
                    </div>
                    </Col>
                  </Row>
                  <br></br>
                <div className="form-group">
                  <div className="timeline">
                    <TimelineLabel>
                      {moment(formik.values.deathDay).format("YYYY年MM月DD日")} 没日
                    </TimelineLabel>
                    <TimelineItem title="商品情報（通夜）" name="vigilItems" formik={formik} useMoney="useVigilMoney" itemList={itemList} />
                    {vigilToFuneral && (
                      <>
                        <TimelineLabel>
                          <DateTime name="vigilToFuneral" formik={formik} /><div className="bg-gray">商品切替（通夜→葬儀）</div>
                        </TimelineLabel>
                      </>
                    )}
                    <TimelineItem title="商品情報（葬儀）" name="funeralItems" formik={formik} useMoney="useFuneralMoney" itemList={itemList} />
                    <TimelineLabel>
                      <DateTime name="funeralToFortyNinth" formik={formik} /><div className="bg-gray">商品切替（葬儀→四十九日）</div>
                    </TimelineLabel>
                    <TimelineItem title="商品情報（四十九日）" name="fortyNinthItems" formik={formik} useMoney="useFortyNinthMoney" itemList={itemList} />
                    <TimelineLabel>
                      {formik.values.fortyNinthDate && moment(formik.values.deathDay).add(48, 'days').format("YYYY年MM月DD日")} 四十九日
                    </TimelineLabel>
                    <TimelineLabel>
                      {formik.values.funeralDateTime && moment(formik.values.funeralDateTime).add(1, 'years').format("YYYY年MM月DD日")} 公開終了
                    </TimelineLabel>
                  </div>
                  <div className="form-group">
                    <div className="form-check">
                      <Input type="checkbox" name="topPublic" className="form-check-input" formik={formik} />
                      <label className="control-label">葬儀社TOPページで公開する</label>
                    </div>
                    {currentCompany?.enablePortal === true && (
                      <div className="form-check">
                        <Input type="checkbox" name="enablePortalForFuneral" className="form-check-input" formik={formik} />
                        <label className="control-label">お悔やみポータルに掲載する</label>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="card-footer">
                <div>
                  <Button variant="secondary" className="float-right" onClick={() => history.goBack()}>戻る</Button>
                  {
                    newEntry ?
                      <>
                        <Button type="submit" variant="success">公開/確定</Button>
                        {' '}
                        <Button className="btn-draft" onClick={handleSaveDraft}>一時保存</Button>
                      </>
                      : 
                      (
                        <>
                          { currentFuneral?.linkedWithLine === false ? (
                            currentFuneral?.isDraft === true ?
                              <>
                                <Button type="submit" variant="success">公開/確定</Button>
                                {' '}
                                <Button className="btn-draft" onClick={handleSaveDraft}>一時保存</Button>
                                {' '}
                                <Button variant="danger" onClick={handleDelete}>削除</Button>
                              </>
                              : 
                              <>
                                <Button type="submit" variant="success">QR更新</Button>
                                {' '}
                                <Button variant="danger" onClick={handleDelete}>削除</Button>
                              </>
                            )
                            :
                            <>
                              <Button type="submit" variant="success">更新</Button>
                              {' '}
                              <Button variant="danger" onClick={handleDelete}>削除</Button>
                              {' '}
                              { lineUserId ?
                                <Button variant="info" onClick={handleResendLineMessage}>LINE再送</Button> :
                                <Button variant="info" onClick={handleSendEmail}>メールの再送</Button> }
                            </>
                          }
                          {' '}
                          <Button variant="dark" onClick={handleDownloadObituary}>訃報紙PDF</Button>
                        </>
                      )
                  }

              {!newEntry ?
                <div>
                  <ContentHeader>
                    葬家  アクセスデータ集計
                  </ContentHeader>

                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-body">
                          <table className="table table-bordered border-primary border-analytics">
                            <tbody>
                              <tr>
                                <td className="table-secondary">　　</td>
                                <td className="table-secondary fs-5">アクセス数<br/>（PV数）</td>
                                <td className="table-secondary fs-5">ユーザー数<br/>（UU数）</td>
                                <td className="table-secondary fs-5">決済数<br/>（CV数）</td>
                              </tr>
                              <tr>
                                <td className="fs-5">通夜まで</td>
                                <td className="fs-5"> { userLogsSummary?.beforeVigilPV } </td>
                                <td className="fs-5"> { userLogsSummary?.beforeVigilUU } </td>
                                <td className="fs-5"> { userLogsSummary?.beforeVigilCV } </td>
                              </tr>
                              <tr>
                                <td className="fs-5">葬儀まで</td>
                                <td className="fs-5"> { userLogsSummary?.beforeFuneralPV } </td>
                                <td className="fs-5"> { userLogsSummary?.beforeFuneralUU } </td>
                                <td className="fs-5"> { userLogsSummary?.beforeFuneralCV } </td>
                              </tr>
                              <tr>
                                <td className="fs-5">葬儀後</td>
                                <td className="fs-5"> { userLogsSummary?.afterFuneralPV } </td>
                                <td className="fs-5"> { userLogsSummary?.afterFuneralUU } </td>
                                <td className="fs-5"> { userLogsSummary?.afterFuneralCV } </td>
                              </tr>
                              <tr>
                                <td className="table-danger fs-5">合計</td>
                                <td className="table-danger fs-5"> { userLogsSummary?.beforeVigilPV + userLogsSummary?.beforeFuneralPV + userLogsSummary?.afterFuneralPV } </td>
                                <td className="table-danger fs-5"> { userLogsSummary?.beforeVigilUU+ userLogsSummary?.beforeFuneralUU + userLogsSummary?.afterFuneralUU } </td>
                                <td className="table-danger fs-5"> { userLogsSummary?.beforeVigilCV + userLogsSummary?.beforeFuneralCV + userLogsSummary?.afterFuneralCV } </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {currentCompany?.hasIpadId && <TableAttendeeSummary funeral={currentFuneral}/>}
                </div>
                :
                <></>
                }
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </CommonLayout>
  );
};

const getCompany = async (companyId) => {
  return await API.get('product', '/companies/' + companyId);
}

const getFuneralTemplate = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }
  return await API.get('product', '/funerals/template', options);
}

const getFuneral = async (companyId, funeralId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }
  return await API.get('product', '/funerals/' + funeralId, options);
}

const getUserLogsSummary = async (funeralId) => {
  return await API.get('product', `/funerals/${funeralId}/userlogssummary`);
}

const createFuneral = async (companyId, forms, vigilToFuneral) => {
  forms.familyName = `${forms.decreasedLastName || ""}`;
  forms.decreasedName = `${forms.decreasedLastName || ""} ${forms.decreasedFirstName || ""}`.trim();

  forms.companyId = companyId;
  if (vigilToFuneral === false) {
    forms.vigilDateTime = "";
    forms.vigilToFuneral = "";
  }
  const options = {
    body: forms
  }
  return await API.post('product', '/funerals', options);
}

const updateFuneral = async (companyId, funeralId, forms, funeralCode, vigilToFuneral, formik) => {
  forms.familyName = `${forms.decreasedLastName}`;
  forms.decreasedName = `${forms.decreasedLastName} ${forms.decreasedFirstName}`.trim();

  forms.companyId = companyId;
  forms.funeralCode = funeralCode;
  if (vigilToFuneral === false) {
    forms.vigilDateTime = "";
    forms.vigilToFuneral = "";
  } else {
    forms.vigilDateTime = formik.values.vigilDateTime;
  }
  const options = {
    body: forms
  }
  return await API.put('product', '/funerals/' + funeralId, options);
}

const deleteFuneral = async (companyId, funeralId) => {
  const options = {
    body: {
      companyId: companyId,
      funeralId: funeralId,
    }
  }
  return await API.del('product', '/funerals', options);
}

const sendEmailUser = async (userId, funeralId) => {
  const options = {
    body: {
      email: userId,
      funeralId: funeralId
    }
  }
  return await API.post('product', '/mail/users/funeral', options);
}

const getTimeScheduleTemplates = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }

  return await API.get('product', '/schedule-template', options);
}

const resendLineMessage = async (lineUserId, funeralId, userName) => {
  const options = {
    body: {
      funeralId,
      lineUserId,
      userName,
    }
  }
  return await API.post('product', '/funerals/resend-message', options);
}

const downloadObituary = async (funeralId) => {
  return await API.get('product', '/funerals/' + funeralId + '/download/obituary');
}

export const getAddress = async (formData) => {
  formData.append("targetUrl", "https://www.keicho.net/api/AddressInformation/v1/addressInformation.php");
  const options = {
    body: formData
  }
  return await API.post('product', '/sagawa-api-proxy', options);
}

const setFuneralTimeSchedule = async (values) => {
  const options = {
    body: values,
  }
  return await API.post('product', '/funeral-time-schedule', options);
}

const getBrandName = (funeral, company) => {
  if (funeral.brandName && funeral.brandName !== "") return funeral.brandName;
  if (company.brandName && company.brandName !== "") return company.brandName;
  return company.companyName;
}

export default FuneralEdit;
