import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Pagination from 'components/Pagination';
import { useLoading } from 'components/Loading';
import useMessage from 'components/Message';
import { DeleteOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons';

export const FuneralTimeScheduleList = (props) => {
	const PER_PAGE = 10;
	const companyId = props.match.params.companyId;
	const [templateList, setItemList] = useState([]);
	const [pagenatedList, setPagenatedList] = useState([]);
	const loading = useLoading();
	const message = useMessage();
	const history = useHistory();
	const [currentPage, setCurrentPage] = useState(0);

	const paginatedList = (resultList) => {
		// If the current page exceeds the valid range, navigate to the last page
		if (currentPage >= Math.ceil(resultList.length / PER_PAGE)) {
		  const page = (Math.max(0, Math.ceil(resultList.length / PER_PAGE) - 1));
		  setPagenatedList(resultList?.slice(page * PER_PAGE, (page + 1) * PER_PAGE));
		} else {
		  setPagenatedList(resultList?.slice(currentPage * PER_PAGE, (currentPage + 1) * PER_PAGE));
		}
	  };
	
	const fetch = async () => {
		try {
			loading(true);
			const result = await getTemplateList(companyId);
			setItemList(result?.scheduleTemplateList);
			paginatedList(result?.scheduleTemplateList);
		} catch (e) {
			if (e?.response?.status === 400) {
				message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
			} else {
				message.alert.danger("システムエラーが発生しました");
			}
		} finally {
			loading(false);
		}
	}

	useEffect(() => {
		fetch();
		
		// eslint-disable-next-line
	}, [])

	const handleDelete = (id) => {
		message.confirm.danger({
			title: "葬家を削除",
			message: "削除してもよろしいですか？",
			onSubmit: async () => {
				try {
					await deleteScheduleTemplate(companyId, id);
					message.alert.success("葬家を削除しました");
					fetch();
				} catch (e) {
					if (e?.response?.status === 400) {
						message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
					} else {
						message.alert.danger("システムエラーが発生しました");
					}
				}
			}
		});
	}

	const create = () => {
		history.push(`/${companyId}/time-schedule-templates/new`)
	}
	return (
		<CommonLayout>
			<ContentHeader>
				葬儀タイムスジュールテンプレート管理
			</ContentHeader>
			<Row>
				<Col>
					<div className="card">
						<div className="card-body p-0">
							<table className="table funeral-time-item">
								<thead>
									<tr>
										<th>テンプレート名</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{
										pagenatedList.map((row, index) => {
											return (
												<tr key={index}>
													<td><Link to={"time-schedule-templates/" + row.Id}>{row.ScheduleTemplateName || "テンプレート名未設定"}</Link></td>
													<td className="space-btn">
														<div className='actions'>
															<CopyOutlined onClick={() => { history.push(`/${companyId}/time-schedule-templates/new`, {id: row.Id }) }}/>
															<EditOutlined onClick={() => { history.push(`/${companyId}/time-schedule-templates/${row.Id}`) }} />
															<DeleteOutlined onClick={() => { handleDelete(row.Id) }} />
														</div>
													</td>
												</tr>
											);
										})
									}
								</tbody>
							</table>
						</div>
						<div className="card-footer clearfix">
							<Button variant="success" className="float-left" onClick={create}>新規作成</Button>
							<Pagination per={PER_PAGE} list={templateList} onChange={(list) => setPagenatedList(list)} setCurrentPage={setCurrentPage}/>
						</div>
					</div>
				</Col>
			</Row>
		</CommonLayout>
	);
};

const getTemplateList = async (companyId) => {
	const options = {
		queryStringParameters: {
			companyId: companyId,
		}
	};

	return await API.get('product', '/schedule-template', options);
}

const deleteScheduleTemplate = async (companyId, scheduleTemplateId) => {
	const options = {
		body: {
			companyId: companyId,
			scheduleTemplateId: scheduleTemplateId
		}
	}
	return await API.del('product', '/schedule-template', options);
}

export default FuneralTimeScheduleList;