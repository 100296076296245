import dayjs from 'dayjs';

export const calculateDate = (timeSettingType, funeralValues, type = 'schedule', currentDate = null) => {
  switch (timeSettingType) {
    case 'wake-time':
      if (funeralValues?.vigilDateTime) {
        const timeFuneral = new Date(funeralValues?.vigilDateTime);
        return dayjs(timeFuneral);
      }
      return null;

    case 'funeral-time':
      if (funeralValues?.funeralDateTime) {
        const timeFuneral = new Date(funeralValues?.funeralDateTime);
        return dayjs(timeFuneral);
      }
      return null;

    case 'time-settings':
      if (type === 'funeral') {
        if (currentDate !== null) {
          if (funeralValues?.vigilDateTime) {
            const timeFuneral = new Date(funeralValues?.vigilDateTime);
            return dayjs(timeFuneral);
          } else if (funeralValues?.funeralDateTime) {
            const timeFuneral = new Date(funeralValues?.funeralDateTime);
            return dayjs(timeFuneral);
          }
        } else {
          return null;
        }
      }
      return null;

    default:
      return null;

  }
};

export const calculateTime = (timeSettingType, item, funeralValues, type = 'schedule', currentItem = null) => {

  const calculateTimeFuneral = (timeFuneral) => {
    let hourFuneral = '';
    let minuteFuneral = '';
    if (item?.Hour && item?.Minute) {
      const number = item?.Hour?.includes('-') ? -1 : 1;
      const time = dayjs(timeFuneral)?.add(item?.Hour * 1, 'hours')?.add(item?.Minute * number, 'minutes');

      hourFuneral = time?.get('hour') !== null ? ('0' + time?.get('hour')).slice(-2) : '';
      minuteFuneral = time?.get('minute') !== null ? ('0' + time?.get('minute')).slice(-2) : '';
    }

    return ({
      hour: hourFuneral,
      minute: minuteFuneral,
    });
  }

  switch (timeSettingType) {
    case 'wake-time':
      if (funeralValues?.vigilDateTime) {
        return calculateTimeFuneral(funeralValues?.vigilDateTime);
      }
      return ({
        hour: '',
        minute: '',
      });

    case 'funeral-time':
      if (funeralValues?.funeralDateTime) {
        return calculateTimeFuneral(funeralValues?.funeralDateTime);
      }
      return ({
        hour: '',
        minute: '',
      });;

    case 'time-settings':
      if (type === 'funeral') {
        return ({
          hour: currentItem?.Hour || '',
          minute: currentItem?.Minute || '',
        });
      }
      return ({
        hour: item?.Hour || '',
        minute: item?.Minute || '',
      });

    default:
      return ({
        hour: '',
        minute: '',
      });
  }
};

export const convertTimeScheduleData = (data) => {
  let scheduleSets = [];
  if (data?.scheduleSets?.length > 0) {
    scheduleSets = data?.scheduleSets?.map((item) => {
      return ({
        ...item,
        date: item?.date ? dayjs(item?.date).format('YYYY-MM-DD') : null,
      });
    });
  }
  return ({
    ...data,
    scheduleSets: scheduleSets,
  });
}