import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ConfirmReOrder = ({ show, setShow, onConfirm }) => {
  return (
    <React.Fragment>
      <Modal show={show} onHide={() => setShow(false)} centered={true}  className='confirmReOrderWrapper'>
        <Modal.Body>
          <h5 className="text-center mt-2">再度注文される方は、<br />下記からお願いいたします。</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => onConfirm(true)}>トップページに戻る</Button>
          <Button variant="secondary" onClick={() => setShow(false)}>キャンセル</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ConfirmReOrder;
