import { useState, useEffect } from 'react';
import { API } from 'aws-amplify'
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Accordion } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import useMessage from 'components/Message';
import Input from 'components/Input';
import { ZipCode } from 'components/CustomForms/ZipCode';
import { Prefecture, prefCodeToName, prefCodeToKana, prefNameToCode } from 'components/CustomForms/Prefecture';
import { useLoading } from 'components/Loading';

export const CompanyEdit = (props) => {
  const newEntry = props.method === "new";
  const [accountRegisted, setAccountRegisted] = useState(false);
  const [directorRegisted, setDirectorRegisted] = useState(false);
  const [devices, setDevices] = useState([]);

  const history = useHistory();
  const message = useMessage();
  const loading = useLoading();

  const formik = useFormik({
    initialValues: {
      taxId: "",
      companyName: "",
      companyNameKana: "",
      companyNameEnglish: "",
      phoneNo: "",
      zipCode: "",
      prefecture: "",
      city: "",
      cityKana: "",
      town: "",
      townKana: "",
      address1: "",
      address1Kana: "",
      address2: "",
      address2Kana: "",
      companyCode: "",
      directorFirstName: "",
      directorLastName: "",
      directorFirstNameKana: "",
      directorLastNameKana: "",
      directorPhoneNo: "",
      directorTitle: "",
      directorEmail: "",
      directorZipCode: "",
      directorPrefecture: "",
      directorCity: "",
      directorCityKana: "",
      directorTown: "",
      directorTownKana: "",
      directorAddress1: "",
      directorAddress1Kana: "",
      directorAddress2: "",
      directorAddress2Kana: "",
      representativeName: "",
      representativeEmail: "",
      invoicePaymentCheck: false,
      representativePhoneNo: "",
      dob: "",
      enableSagawaItem: false,
      enablePortal: false,
      ipadIds: [],
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required("葬儀社名は必須です"),
      representativeName: Yup.string().required("申請者名は必須です"),
      representativeEmail: Yup.string().email("メールアドレスの形式ではありません").required("申請者メールアドレスは必須です"),
      representativePhoneNo: Yup.string().required("申請者電話番号は必須です"),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          loading(true);
          const devices = [];
          const formValues = {};
          Object.keys(values)?.forEach((key) => {
            if (key?.includes("device_")) {
              if(values[key]){
                devices.push(values[key]);
              }
            } else {
              Object.assign(formValues, { [key]: values[key] });
            }
          });
          Object.assign(formValues, { ipadIds: devices });

          if (newEntry) {
            const result = await createCompany(formValues);
            if (result.error) {
              message.alert.danger("システムエラーが発生しました");
            } else {
              message.alert.success("葬儀社を作成しました");
              history.goBack();
            }
          } else {
            const result = await updateCompany(props.match.params.companyId, formValues);
            if (result.error) {
              message.alert.danger("システムエラーが発生しました");
            } else {
              message.alert.success("葬儀社を更新しました");
              history.goBack();
            }
          }
        } catch (e) {
          if (e.response.status === 400) {
            message.alert.danger(e.response.data.error);
          } else {
            message.alert.danger("システムエラーが発生しました");
          }
        } finally {
          loading(false);
        }
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    const fetch = async (companyId) => {
      loading(true);
      const { hasAccountId, hasDirectorId, ...company } = await getCompany(companyId);
      setFieldValue("invoicePaymentCheck", company.invoicePaymentCheck);
      setFieldValue("enableSagawaItem", company?.enableSagawaItem !== true ? false : true);
      setFieldValue("enablePortal", company?.enablePortal !== true ? false : true);
      const listDevices = (company?.ipadIds || []).map((item, index) => ({
        key: index,
        value: item,
      }));
      setDevices(listDevices);
      if(listDevices?.length > 0) {
        listDevices?.forEach((item) => {
          setFieldValue(`device_${item?.key}`, item?.value);
        });
      }

      if (hasAccountId) {
        setAccountRegisted(true);
      }
      if (hasDirectorId) {
        setDirectorRegisted(true)
      }

      Object.keys(company).map((key) => {
        if (key === "directorPrefecture") {
          return setFieldValue(key, prefNameToCode(company[key]));
        } else {
          return setFieldValue(key, company[key]);
        }
      });
      loading(false);
    }
    if (!newEntry) {
      fetch(props.match.params.companyId);
    }
    // eslint-disable-next-line
  }, []);

  const handleChangeCompanyZipCode = (result) => {
    formik.setFieldValue("prefecture", result.prefCode)
    formik.setFieldValue("city", result.city.kanji)
    formik.setFieldValue("cityKana", result.city.kana)
    formik.setFieldValue("town", result.town.kanji)
    formik.setFieldValue("townKana", result.town.kana)
  }

  const handleChangeDirectorZipCode = (result) => {
    formik.setFieldValue("directorPrefecture", result.prefCode)
    formik.setFieldValue("directorCity", result.city.kanji)
    formik.setFieldValue("directorCityKana", result.city.kana)
    formik.setFieldValue("directorTown", result.town.kanji)
    formik.setFieldValue("directorTownKana", result.town.kana)
  }

  const handleDelete = async () => {
    message.confirm.danger({
      title: "葬儀社を削除",
      message: "削除してもよろしいですか？",
      onSubmit: async () => {
        await deleteCompany(props.match.params.companyId);
        message.alert.success("葬儀社を削除しました");
        history.goBack();
      }
    })
  }

  const handleSendEmail = async () => {
    const { error } = await sendEmailUser(formik.values.representativeEmail, props.match.params.companyId);
    if (error) {
      message.alert.danger("メールを送信できませんでした。");
      return
    }
    message.alert.success("メールを送信しました。");
  }

  const handleInvoicePayment = () => {
    formik.setFieldValue('invoicePaymentCheck', !formik.values.invoicePaymentCheck);
  }

  const handleAddDevice = () => {
    setDevices(prevDevices => {
      const newIndex = prevDevices?.length > 0 ? prevDevices[prevDevices?.length - 1]?.key + 1 : 0;
      const updatedDevices = [...prevDevices, {key: newIndex, value: ''}];
      return updatedDevices;
    });
  }

  const handleDeleteDevice = (key) => {
    formik.setFieldValue(`device_${key}`, null);
    setDevices(prevItems => {
      const updatedDevices = prevItems.filter((item) => item?.key !== key);
      return updatedDevices;
    });
  }
  return (
    <CommonLayout>
      <ContentHeader>
        {newEntry ? "葬儀社新規作成" : "葬儀社編集"}
      </ContentHeader>

      <Row>
        <Col md="8">
          <form onSubmit={formik.handleSubmit}>
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <Row>
                    <Col md="9">
                      <label className="control-label">会社名（漢字）</label>
                      <Input type="text" name="companyName" className="form-control" formik={formik} />
                    </Col>
                    <Col md="3">
                      <label className="control-label">葬儀社コード</label>
                      <Input type="text" name="companyCode" className="form-control" formik={formik} disabled={true} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="6">
                      <label className="control-label">代表者名</label>
                      <Input type="text" name="representativeName" className="form-control" formik={formik} />
                    </Col>
                    <Col md="6">
                      <label className="control-label">代表者電話番号</label>
                      <Input type="text" name="representativePhoneNo" className="form-control" formik={formik} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <label className="control-label">代表者メールアドレス</label>
                  <Input type="text" name="representativeEmail" className="form-control" formik={formik} />
                </div>
                <label>
                  <Input type="checkbox" name="invoicePaymentCheck" checked={formik?.values?.invoicePaymentCheck} onChange={handleInvoicePayment} formik={formik} />
                  請求書払い機能
                </label>
                <div className="form-group padding-checkbox sagawa-checkbox">
                  <label className="form-check-label">
                    <Input type="checkbox" name="enableSagawaItem" className="form-check-input" formik={formik} />
                    佐川商品を表示
                  </label>
                </div>
                <div className="form-group padding-checkbox okinawa-checkbox-admin">
                  <label className="form-check-label">
                    <Input type="checkbox" name="enablePortal" className="form-check-input" formik={formik} />
                    お悔やみポータル掲載
                  </label>
                </div>
                <div className="form-group ipad-series">
                  <label className="form-check-label mb-2">
                    <b>会葬受付iPad端末シリアル番号</b>
                    <Button variant="secondary" className='btn-add-device ml-2' onClick={() => handleAddDevice()}>+追加</Button>
                  </label>
                  {devices?.length > 0 && devices.map((item, index) => {
                    return (
                      <Row key={item?.key || index}>
                        <Col md="10">
                          <Input type="text" name={`device_${item?.key || index}`} className="form-control mb-2" formik={formik}/>
                        </Col>
                        <Col md="2">
                          <Button variant="danger" className='btn-delete-device' onClick={() => handleDeleteDevice(item?.key)} >削除</Button>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <Accordion flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Stripe登録情報(クリックして展開)</Accordion.Header>
                    <Accordion.Body>
                      <h5>会社情報 {accountRegisted ? <span className="badge badge-success">Stripeと同期しています</span> : null}</h5>
                      <div className="form-group">
                        <label className="control-label">会社名（カナ）</label>
                        <Input type="text" name="companyNameKana" className="form-control" formik={formik} />
                      </div>
                      <div className="form-group">
                        <label className="control-label">会社名（英語）</label>
                        <Input type="text" name="companyNameEnglish" className="form-control" formik={formik} />
                      </div>
                      <div className="form-group">
                        <Row>
                          <Col md="6">
                            <label className="control-label">法人番号</label>
                            <Input type="text" name="taxId" className="form-control" formik={formik} />
                          </Col>
                          <Col md="6">
                            <label className="control-label">本社住所・電話番号</label>
                            <Input type="text" name="phoneNo" className="form-control" formik={formik} />
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group">
                        <Row>
                          <Col md="3">
                            <label className="control-label">本社住所・郵便番号</label>
                            <ZipCode name="zipCode" className="form-control" formik={formik} onChange={handleChangeCompanyZipCode} />
                          </Col>
                          <Col md="3">
                            <label className="control-label">本社住所・都道府県</label>
                            <Prefecture name="prefecture" className="form-control" formik={formik} />
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group">
                        <Row>
                          <Col md="6">
                            <label className="control-label">本社住所・市区町村（漢字）</label>
                            <Input type="text" name="city" className="form-control" formik={formik} />
                          </Col>
                          <Col md="6">
                            <label className="control-label">本社住所・市区町村（カナ）</label>
                            <Input type="text" name="cityKana" className="form-control" formik={formik} />
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group">
                        <Row>
                          <Col md="6">
                            <label className="control-label">本社住所・町域名（漢字）</label>
                            <Input type="text" name="town" className="form-control" formik={formik} />
                          </Col>
                          <Col md="6">
                            <label className="control-label">本社住所・町域名（カナ）</label>
                            <Input type="text" name="townKana" className="form-control" formik={formik} />
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group">
                        <Row>
                          <Col md="6">
                            <label className="control-label">本社住所・番地等（漢字）</label>
                            <Input type="text" name="address1" className="form-control" formik={formik} />
                          </Col>
                          <Col md="6">
                            <label className="control-label">本社住所・番地等（カナ）</label>
                            <Input type="text" name="address1Kana" className="form-control" formik={formik} />
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group">
                        <Row>
                          <Col md="6">
                            <label className="control-label">本社住所・建物名・部屋番号（漢字）</label>
                            <Input type="text" name="address2" className="form-control" formik={formik} />
                          </Col>
                          <Col md="6">
                            <label className="control-label">本社住所・建物名・部屋番号（カナ）</label>
                            <Input type="text" name="address2Kana" className="form-control" formik={formik} />
                          </Col>
                        </Row>
                      </div>
                      <h5>会社代表者情報 {directorRegisted ? <span className="badge badge-success">Stripeと同期しています</span> : null}</h5>
                      <div className="form-group">
                        <Row>
                          <Col md="6">
                            <label className="control-label">会社代表者名（漢字）</label>
                            <Row>
                              <Col md="6">
                                <Input type="text" name="directorLastName" className="form-control" formik={formik} placeholder="氏" />
                              </Col>
                              <Col md="6">
                                <Input type="text" name="directorFirstName" className="form-control" formik={formik} placeholder="名" />
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6">
                            <label className="control-label">会社代表者名（カナ）</label>
                            <Row>
                              <Col md="6">
                                <Input type="text" name="directorLastNameKana" className="form-control" formik={formik} placeholder="氏" />
                              </Col>
                              <Col md="6">
                                <Input type="text" name="directorFirstNameKana" className="form-control" formik={formik} placeholder="名" />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group">
                        <Row>
                          <Col md="6">
                            <label className="control-label">会社代表者・役職名</label>
                            <Input type="text" name="directorTitle" className="form-control" formik={formik} />
                          </Col>
                          <Col md="6">
                            <label className="control-label">会社代表者・電話番号</label>
                            <Input type="text" name="directorPhoneNo" className="form-control" formik={formik} />
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group">
                        <Row>
                          <Col md="3">
                            <label className="control-label">会社代表者住所・郵便番号</label>
                            <ZipCode name="directorZipCode" className="form-control" formik={formik} onChange={handleChangeDirectorZipCode} />
                          </Col>
                          <Col md="3">
                            <label className="control-label">会社代表者住所・都道府県</label>
                            <Prefecture name="directorPrefecture" className="form-control" formik={formik} />
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group">
                        <Row>
                          <Col md="6">
                            <label className="control-label">会社代表者住所・市区町村（漢字）</label>
                            <Input type="text" name="directorCity" className="form-control" formik={formik} />
                          </Col>
                          <Col md="6">
                            <label className="control-label">会社代表者住所・市区町村（カナ）</label>
                            <Input type="text" name="directorCityKana" className="form-control" formik={formik} />
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group">
                        <Row>
                          <Col md="6">
                            <label className="control-label">会社代表者住所・町域名（漢字）</label>
                            <Input type="text" name="directorTown" className="form-control" formik={formik} />
                          </Col>
                          <Col md="6">
                            <label className="control-label">会社代表者住所・町域名（カナ）</label>
                            <Input type="text" name="directorTownKana" className="form-control" formik={formik} />
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group">
                        <Row>
                          <Col md="6">
                            <label className="control-label">会社代表者住所・番地等（漢字）</label>
                            <Input type="text" name="directorAddress1" className="form-control" formik={formik} />
                          </Col>
                          <Col md="6">
                            <label className="control-label">会社代表者住所・番地等（カナ）</label>
                            <Input type="text" name="directorAddress1Kana" className="form-control" formik={formik} />
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group">
                        <Row>
                          <Col md="6">
                            <label className="control-label">会社代表者住所・建物名・部屋番号（漢字）</label>
                            <Input type="text" name="directorAddress2" className="form-control" formik={formik} />
                          </Col>
                          <Col md="6">
                            <label className="control-label">会社代表者住所・建物名・部屋番号（カナ）</label>
                            <Input type="text" name="directorAddress2Kana" className="form-control" formik={formik} />
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group">
                        <label className="control-label">会社代表者・メールアドレス</label>
                        <Input type="text" name="directorEmail" className="form-control" formik={formik} />
                      </div>
                      <div className="form-group">
                        <Row>
                          <Col md="3">
                            <label className="control-label">会社代表者・生年月日</label>
                            <Input type="date" name="dob" className="form-control" formik={formik} />
                          </Col>
                        </Row>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="card-footer">
                <div>
                  <Button variant="secondary" className="float-right" onClick={() => history.goBack()}>戻る</Button>
                  {
                    newEntry ?
                      <Button type="submit" variant="success">作成</Button>
                      :
                      <>
                        <Button type="submit" variant="warning">更新</Button>
                        {' '}
                        <Button variant="danger" onClick={handleDelete}>削除</Button>
                        {' '}
                        <Button variant="info" onClick={handleSendEmail}>メールの再送</Button>
                      </>
                  }
                </div>
              </div>
            </div>
          </form>
        </Col>
      </Row>

    </CommonLayout>
  );
};

const getCompany = async (companyId) => {
  return await API.get('product', '/companies/' + companyId);
}

const createCompany = async (form) => {
  form.prefectureKanji = prefCodeToName(form.prefecture);
  form.prefectureKana = prefCodeToKana(form.prefecture);
  form.directorPrefectureKanji = prefCodeToName(form.directorPrefecture);
  form.directorPrefectureKana = prefCodeToKana(form.directorPrefecture);
  const options = {
    body: form
  }
  return await API.post('product', '/companies', options);
}

const updateCompany = async (companyId, form) => {
  form.prefectureKanji = prefCodeToName(form.prefecture);
  form.prefectureKana = prefCodeToKana(form.prefecture);
  form.directorPrefectureKanji = prefCodeToName(form.directorPrefecture);
  form.directorPrefectureKana = prefCodeToKana(form.directorPrefecture);
  form.companyId = companyId;
  const options = {
    body: form
  }
  return await API.put('product', '/companies', options);
}

const deleteCompany = async (companyId) => {
  const options = {
    body: {
      companyId: companyId,
    }
  }
  return await API.del('product', '/companies', options);
}

const sendEmailUser = async (userId, companyId) => {
  const options = {
    body: {
      email: userId,
      companyId: companyId
    }
  }
  return await API.post('product', '/mail/users/undertaker', options);
}

export default CompanyEdit;
