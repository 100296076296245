import React, { useContext, useEffect, useState } from 'react'
import { API } from 'aws-amplify';
import { AuthContext } from './Contexts';
import useLoading from "./Loading";
import { useHistory, useRouteMatch } from 'react-router-dom';

const AttendanceTermsContents = () => {
  const [checked, setChecked] = useState(false);
  const { userInfo } = useContext(AuthContext);
  const loading = useLoading();
  const match = useRouteMatch("/:funeralId");
  const history = useHistory();

  const handleChanged = (event) => {
    setChecked(event.target.checked);
  }

  const handleContinue = async () => {
    loading(true);
    await updateAttendeeTermsCheck(userInfo.email, checked);
    loading(false);

    const url = sessionStorage.getItem('returnUrl');
    sessionStorage.removeItem('returnUrl');
    if (url) {
      window.location.href = url;
    } else {
      window.location.href = `/${match.params.funeralId}`;
    }
  }

  useEffect(() => {
    // 未認証 or 利用規約がチェック済みの場合はトップページへ遷移する
    if (!userInfo || (userInfo && userInfo.attendeeTermsConsents && userInfo.attendeeTermsConsents.TermsChecked === true)) {
      history.push(`/${match.params.funeralId}`);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="termsScroll" id="termsScroll">
      <br />
      <h4 className="text-center">本サービスを利用するには<br />以下の規則に同意する必要があります。</h4>
      <div className="terms-text terms-text--short fs-5">
        <br />
        <p className="text-center fs-2">利用規約（葬家・参列者・参列候補者用）</p><br />
        <br />
        <p className="indent-1">この利用規約（以下、「本規約」といいます。）は、株式会社itowa（以下、「当社」といいます。）が提供する「itowa」（以下、「本サービス」といいます。）の利用に関する条件を定めるものであり、本サービスを利用するすべての葬家、参列者及び参列候補者に適用されます。本サービスを利用する前に、本規約をよくお読みください。</p><br />

        <br />
        第１条（本規約への同意）<br />
        <br />
        <p className="indent-3">１　本規約は、会員と当社との間の本サービス利用に関わる一切の関係に適用されます。</p>
        <p className="indent-3">２　当社が、当社ウェブサイト又は本サービス上に本サービスに関して個別規定や追加規定を掲載する場合、それらは本規約の一部を構成するものとし、個別規定又は追加規定が本規約と抵触する場合には、当該個別規定又は追加規定が優先されるものとします。</p>
        <p className="indent-3">３　会員は、本規約に従って本サービスを利用するものとし、本規約に同意しない限り本サービスを利用できません。</p>
        <p className="indent-3">４　会員が本規約に同意した上で会員登録を完了した時点で、当該会員と当社との間で、本規約の諸規定に従った本サービスの利用に関するサービス利用契約（以下、「本利用契約」といいます。）が成立します。</p>

        <br />
        第２条（定義）<br />
        <br />
        <p className="indent-3">本規約において、次の各号に掲げる用語の意味は、当該各号に定めるとおりとします。</p>
        <p className="indent-3">(1)　「葬儀会社」　当社が別途定める利用規約（葬儀会社及び供物等販売会社用）に同意の上、当社と利用契約を締結した法人、団体、組合又は個人であって、葬家と葬儀を行う契約を締結し、その葬儀のために当社が提供するサービスを利用する者</p>
        <p className="indent-3">(2)　「供物等販売会社」　当社が別途定める利用規約（葬儀会社及び供物等販売会社用）に同意の上、当社と利用契約を締結した法人、団体、組合又は個人であって、葬儀会社以外で第９条第１項に定義する供物等の受注を受ける者をいう。</p>
        <p className="indent-3">(3)　「葬家」　本規約に同意の上、本利用契約を締結した法人、団体、組合又は個人であって、葬儀会社と葬儀を行う契約を締結し、その葬儀のために本サービスを利用する者</p>
        <p className="indent-3">(4)　「参列」　本サービスを利用して、供物・供花・弔電の提供、香典の支払い等を行うこと</p>
        <p className="indent-3">(5)　「参列者」　葬家が行う葬儀に本サービスを利用して参列した個人、法人その他の団体であって、葬家及びその親族以外の者　</p>
        <p className="indent-3">(6)　「会員」　本サービスを利用する葬家、参列者及び参列候補者の総称</p>
        <p className="indent-3">(7)　「アカウント」　会員が本サービスを利用するための権利又は当該権利を行使するための口座　　</p>
        <p className="indent-3">(8)　「参列候補者」　葬家が行う葬儀及び本サービスを利用した参列に関する情報提供を受けた個人、法人その他の団体、若しくは、かかる個人、法人その他の団体からさらに当該情報の提供を受けた個人、法人その他の団体、又は、葬儀会社のウェブサイト等から葬家が行う葬儀のウェブページに直接アクセスして当該情報を得た個人、法人その他の団体</p>
        <p className="indent-3">(9)　「会員情報」　会員の住所、氏名、電話番号、メールアドレス等のプロフィールに関する情報</p>
        <p className="indent-3">(10)　「本サービス活用データ」　会員が本サービスを利用して送信又は保存するすべての電子的なデータ及び情報</p>
        <p className="indent-3">(11)　「統計情報」　会員情報又は本サービス活用データ（いずれも個人情報、仮名加工情報又は匿名加工情報に該当するものは除く）の属性集計・分析を行い、個人が識別・特定できないようにした統計的な情報</p>
        <p className="indent-3">(12)　「外部サービス」　当社が運営主体ではなく、外部の個人、法人その他の団体が運営主体であるサービス</p>

        <br />
        第３条（本規約の変更）<br />
        <br />
        <p className="indent-3">１　当社は、民法第５４８条の４に従い、以下のいずれかに該当する場合に、法令上認められる範囲内で本規約を変更できるものとします。</p>
        <p className="indent-4">(1)　本規約の変更が、会員の一般の利益に適合するとき</p>
        <p className="indent-4">(2)　本規約の変更が、本規約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。</p>
        <p className="indent-3">２　当社は、本規約を変更する場合、その効力発生時期を定めたうえで、本規約を変更する旨及び変更後の本規約の内容並び効力発生時期を当社が運営するウェブサイトその他相当の方法で公表又は会員に対して通知するものとし、当該変更はその効力発生日に効力を生じるものとします。</p>

        <br />
        第４条（通知）<br />
        <br />
        <p className="indent-3">１　当社は、本サービスに関連して会員に通知をする場合には、本サービス上の会員専用ページ又は会員専用アプリで通知する方法又は登録情報として登録された電子メールアドレス・住所に宛てて電子メール・文書を送信・送付する方法など、当社が適当と判断する方法で実施します。</p>
        <p className="indent-3">２　前項に定める方法により行われた通知は、前者の場合には通知内容が当社ウェブサイト若しくは本サービス上に掲示された時点で、後者の場合は当社が電子メール・文書が通常到達すべきであった時点で、それぞれその効力を生じるものとします。</p>
        <p className="indent-3">３　本サービスに関する問い合わせその他会員から当社に対する連絡又は通知は、当社の定める方法で行うものとします。</p>

        <br />
        第５条（本サービスについて）<br />
        <br />
        <p className="indent-3">１　会員は、本サービスにおいて、以下のサービスを利用することができます。詳細は個別の条項において定めるものとします。</p>
        <p className="indent-3">＜葬家・参列者・参列候補者に共通のサービス＞</p>
        <p className="indent-4">・供物、供花、弔電の注文</p>
        <p className="indent-4">・香典の支払い・受領</p>
        <p className="indent-4">・写真・動画の投稿・共有・閲覧</p>
        <p className="indent-3">＜葬家のみ対象のサービス＞</p>
        <p className="indent-4">・お礼文書登録</p>
        <p className="indent-4">・参列者リストの整理・ダウンロード</p>
        <p className="indent-4">・返礼品の注文・受注</p>
        <p className="indent-4">・葬儀の告知・公表</p>
        <p className="indent-3">２　本サービスは、前項のサービスに係る業務・行為の効率化を支援するためのツールであり、会員の業務・行為の正確性、適法性、完全性を保証するものではありません。</p>
        <p className="indent-3">３　会員の業務・行為に関する最終的な判断は会員の判断によりますので、会員の業務・行為によって生じた不利益及び損害については会員自身が責任を負うものとし、当社は、一切の責任を負いません。ただし、当社に故意又は過失がある場合はこの限りではありません。</p>
        <p className="indent-3">４　本サービスは、故人の葬儀に関する情報を葬家から参列候補者に対して伝えるだけでなく、参列候補者からさらに別の参列候補者に対して伝えること、葬儀の情報をインターネット上で第三者が閲覧できるようにすることを想定したサービスです。これにより、会員が何らかの不利益・損害を被ったとしても、当社は一切の責任を負わないものとし、会員はこの可能性を十分に理解した上で本サービスを利用するものとします。ただし、当社に故意又は過失がある場合はこの限りではありません。</p>

        <br />
        第６条（会員登録）<br />
        <br />
        <p className="indent-3">１　本サービスの利用を希望する者（以下、「申請者」といいます。）は、本規約を遵守することに同意し、当社の定める情報を当社の定める方法により、当社に提供することによって、会員登録をするものとします。</p>
        <p className="indent-3">２　申請者が以下の各号のいずれかに該当する場合には、当社は、会員登録を拒否し、又は当該申請者による利用を制限することがあります。</p>
        <p className="indent-3">(1) 未成年者、成年被後見人、被保佐人又は被補助人のいずれかであって、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合</p>
        <p className="indent-3">(2) 会員登録にあたって当社に提供された情報の全部又は一部につき、虚偽、誤り又は記載漏れがあった場合</p>
        <p className="indent-3">(3) 申請者が、本サービスにつき利用停止措置を受けたことがあり、又は現在受けている場合</p>
        <p className="indent-3">(4) 過去に本利用契約その他当社との間で締結した契約に関し、当該契約に定められた義務の履行を怠ったことがある場合、その他本利用契約上の義務の履行を怠るおそれがあると当社が判断した場合</p>
        <p className="indent-3">(5) 第２５条第１項第１号に定義する反社会的勢力であるか、又は資金提供その他を通じて反社会的勢力の維持、運営若しくは経営に協力若しくは関与する等、反社会的勢力との何らかの交流若しくは関与を行っていると当社が判断した場合</p>
        <p className="indent-3">(6) 申請者が非居住者である場合</p>
        <p className="indent-3">(7) 申請者が日本国内で発行されたクレジットカードを保有していない場合</p>
        <p className="indent-3">(8) その他、当社が会員登録を適当でないと判断した場合</p>

        <br />
        第７条（未成年者による使用）<br />
        <br />
        <p className="indent-3">１　未成年者は、会員登録の申請及び本サービスの利用その他一切の行為につき、親権者等の法定代理人の同意を得た上でこれを行うものとします。</p>
        <p className="indent-3">２　本規約の同意時に未成年であった会員が成年に達した後に本サービスを利用した場合、当該会員は、本サービスに関する一切の法律行為を追認したものとみなされます。　</p>

        <br />
        第８条（ID等の管理）<br />
        <br />
        <p className="indent-3">１　会員は、自己の責任において本サービスのユーザＩＤ及びパスワード（以下、「ID等」といいます。）を管理・保管するものとし、これを会員以外の第三者に利用させ、又は貸与、譲渡、名義変更、売買等をしてはならないものとします。当社は、ID等の一致を確認した場合、当該アカウントを保有するものとして登録された会員が本サービスを利用したものとみなします。</p>
        <p className="indent-3">２　ID等の管理不十分又は第三者の使用等による損害の責任は、会員自身が負うものとし、当社は、当社に故意又は過失がない限り、一切の責任を負いません。</p>
        <p className="indent-3">３　会員は、ID等が盗用され又は第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。</p>

        <br />
        第９条（供物等）<br />
        <br />
        <p className="indent-3">１　葬家、参列者及び参列候補者は、本サービスを通じて、葬儀会社又は供物等販売会社に対して、供物、供花、弔電及び返礼品（以下、総称して「供物等」といいます。）の注文をすることができます。ただし、サーバーメンテナンス等により注文をすることができない場合があり得ます。</p>
        <p className="indent-3">２　前項の注文を行った葬家、参列者又は参列候補者（以下、総称して「注文者」といいます。）は、当社に対し、選択した供物等の代金を、原則として、当社の指定する決済代行業者（以下、「決済代行業者」といいます。）を通じてクレジットカードで支払うものとします。ただし、葬儀会社又は供物等販売会社が、銀行口座への振込み等、クレジットカード以外での支払方法を指定した場合には、この限りではありません。注文者は、葬儀会社又は供物等販売会社が指定した方法以外での支払いはできないものとします。</p>
        <p className="indent-3">３　注文者が供物等の代金をクレジットカードで支払う場合、当社が葬儀会社又は供物等販売会社から付与を受けた代理受領権に基づき、決済代行業者が支払いを受けた時点で、決済代行業者と葬儀会社又は供物等販売会社との間の契約に基づき、注文者の葬儀会社又は供物等販売会社に対する供物等の代金支払債務は履行されたものとして消滅します。</p>
        <p className="indent-3">４　注文者が供物等の代金をクレジットカードで支払う場合、引落し日等のクレジットカードによるお支払いに関する条件等は、注文者とクレジットカード会社との間の契約によるものとし、注文者と当該クレジットカード会社との間で紛争が発生した場合は、当該当事者間において解決するものとします。</p>
        <p className="indent-3">５　注文者は、供物等の代金を、葬儀会社又は供物等販売会社が指定するクレジットカード以外の方法により支払う場合、本サービス上で葬儀会社又は供物等販売会社が発行する請求書に記載された期限までに、当該請求書に記載された方法によって供物等の代金を支払うものとします。振込手数料その他の支払いに係る費用は、注文者の負担とします。注文者は、当該注文に際して入力した注文者に係る情報（個人情報を含みます。）は、葬儀会社又は供物等販売会社による請求書作成等当該注文に関するサービス提供のため、当該葬儀会社又は供物等販売会社に提供されることにあらかじめ同意するものとします。</p>
        <p className="indent-3">６　当社は、本サービス上で葬儀会社又は供物等販売会社が発行する請求書の正確性、完全性を保証するものではなく、供物等の代金の支払いに関して注文者と葬儀会社又は供物等販売会社その他の第三者との間で紛争が発生した場合は、当該当事者間において解決するものとします。</p>
        <p className="indent-3">７　本サービス上で注文完了画面が表示された時点において、注文者と葬儀会社又は供物等販売会社との間で、当該供物等の売買契約・弔電の送付契約（葬儀会社又は供物等販売会社が弔電を印刷して葬家に交付します。）が成立したものとします。葬儀会社及び供物等販売会社は、当該供物等の売買契約・弔電の送付契約の履行に関し、第三者に業務を委託することができるものとします。     </p>
        <p className="indent-3">８　供物等の引き渡しに関して葬儀会社又は供物等販売会社と注文者との間で紛争が発生した場合は、当該当事者間において解決するものとし、当社は責任を負わないものとします。本利用契約（終了の理由は問いません。）が終了した場合においても、葬儀会社及び供物等販売会社は、成立した前項の契約を、責任をもって履行するものとします。</p>
        <p className="indent-3">９　葬家は、会員登録をした時点で、注文者が行った前項の供物等の提供を受ける意思表示をしたものとみなし、供物等の受領を拒否することができないものとします。ただし、葬家、葬儀会社又は供物等販売会社及び注文者が合意した場合にはこの限りではありません。</p>
        <p className="indent-3">10　注文者は、注文者の住所、氏名、性別、年代、電話番号、メールアドレス等の情報が、注文者が葬家の場合は参列者及び参列候補者に、注文者が参列者又は参列候補者の場合は葬家に提供されることに同意するものとします。この同意ができない場合には、第１項の注文をしてはならないものとします。</p>

        <br />
        第１０条（香典）<br />
        <br />
        <p className="indent-3">１　葬家は、本規約に同意し、本サービスを通じて香典を受領することに同意することにより、当社に対して、参列者及び参列候補者から支払われる香典の受領に関する契約の締結権限及び同契約に基づいて支払われる香典の代理受領権を付与するものとします。葬家は、本サービスの利用開始時に、本サービスを通じた香典の受領につき同意しないことができます。ただし、当社が参列者又は参列候補者から香典を受け付けた日以降、本サービスの利用を終了するまで、葬家は本サービスを通じた香典の受領についての同意を撤回できないものとします。</p>
        <p className="indent-3">２　参列者及び参列候補者は、葬家が当社に対して本サービスを通じて香典を受領することに同意した場合、本サービスを通じて、葬家に対して香典支払いの申込みをすることができます。当社は、前項により授与された契約締結権限に基づき、これを承諾するものとし、本サービス上で支払画面が表示された時点において、当社による承諾があったものとみなします。これにより葬家と香典を支払う参列者又は参列候補者（以下、当該参列者又は参列候補者を「香典支出者」といいます。）と葬家との間で、香典の授受に関する契約が成立するものとします。ただし、サーバーメンテナンス等により香典支払いの申込みをすることができない場合があり得ます。</p>
        <p className="indent-3">３　前項により成立した香典の授受に関する契約に基づき、香典支出者は、当社に対し、香典及び当社が別途定めるシステム利用等に関する手数料（以下「本手数料」といいます。）を、決済代行業者を通じてクレジットカードで支払うものとします。本サービスにおいて、クレジットカード以外の方法で香典及び本手数料を支払うことはできないものとします。</p>
        <p className="indent-3">４　葬家は、当社から決済代行業者を通じて、香典から決済代行業者の定める決済手数料を控除した残額を受け取るものとし、支払いに関する条件等は、決済代行業者と葬家との間の契約及び当社の定める内容（別紙）に従うものとします。</p>
        <p className="indent-3">５　当社が葬家から付与を受けた代理受領権に基づき、決済代行業者が香典支出者からの支払いを受けた時点で、香典支出者の葬家に対する香典の支払債務は履行されたものとして、消滅します。この場合、葬家は、香典支出者に対し、別途香典を請求することはできません。</p>
        <p className="indent-3">６　引落し日等のお支払いに関する条件等は、香典支出者とクレジットカード会社との間の契約によるものとし、香典支出者と当該クレジットカード会社との間で紛争が発生した場合は、当該当事者双方で解決するものとし、当社は責任を負わないものとします。</p>
        <p className="indent-3">７　香典支出者は、香典支出者の住所、氏名、性別、年代、電話番号、メールアドレス、支払った香典の金額等の情報が葬家に提供されること、香典の使途は葬家に委ねられ、香典支出者は葬家による使途を限定することができないことに同意するものとします。この同意ができない場合には、第２項の申込みをしてはならないものとします。</p>

        <br />
        第１１条 （税金関係）<br />
        <br />
        <p className="indent-1">本サービスの利用に関し葬家、参列者又は参列候補者に発生する課税及び葬家、参列者又は参列候補者における本サービスに関連する税務上の取扱いについて当社、葬儀会社及び供物等販売会社は一切関知しないものとし、葬家、参列者及び参列候補者が自らの責任において対応し、処理するものとします。</p>

        <br />
        第１２条（写真）<br />
        <br />
        <p className="indent-3">１　葬家は、本サービスを通じて、故人に関する写真をアップロードすることができます。また、参列者は、本サービスを通じて、故人に関する写真をアップロードし、葬家に提供することができます。ただし、サーバーメンテナンス等により写真をアップロードすることができない場合があり得ます。</p>
        <p className="indent-3">２　前項に基づき写真のアップロードをする葬家及び参列者（以下、「写真提供者」といいます。）は、写真をアップロードする際に、葬家のみが閲覧できるようにするか（葬家アルバム）、葬家だけでなく他の参列者も閲覧できるようにするか（贈り物アルバム）を選択するものとします。写真提供者によるこの選択は自己の責任によるものとし、当社は責任を負わないものとします。なお、供物等販売会社が葬家アルバムを閲覧することはありません。葬儀会社も、葬家が葬儀会社に対して閲覧権限を付与しない限り、葬家アルバムを閲覧することはありません。</p>
        <p className="indent-3">３　写真提供者は、アップロードしようとする写真により、故人や葬家、写真に映り込む第三者の肖像権、名誉、名誉感情、その他の権利・利益又は心情等が害されることがないように最大限配慮しなければなりません。また、写真提供者は、第三者が著作権を有する写真を、著作者の許諾なくアップロードしてはならないものとします。写真提供者と葬家その他の第三者との間で生じた紛争については、写真提供者が自己の負担と責任でこれを解決するものとし、当社は責任を負わないものとします。</p>
        <p className="indent-3">４　葬家は、写真提供者から提供された写真について、葬家のみで閲覧するか（葬家アルバム）、葬家だけでなく参列者も閲覧できるようにするか（贈り物アルバム）を選択するものとします。ただし、写真提供者が葬家のみが閲覧できるようにすることを選択した写真については、葬家であっても、参列者も閲覧できるようにすることはできません。</p>
        <p className="indent-3">５　写真提供者は、葬家に対して、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与し、葬家に対して著作者人格権を行使しないことに同意するものとします。</p>
        <p className="indent-3">６　提供された写真は、葬儀日から１年間経過した時点で当社において削除するものとします。葬家は、写真が削除されるまでの間、写真をダウンロードすることができます。期限内にダウンロードしなかったことによる損失・損害について、当社は責任を負いません。</p>

        <br />
        第１３条（葬儀の告知・公表）<br />
        <br />
        <p className="indent-1">葬家は、第５条第１項に掲げる当社の提供する葬儀の告知・公表に関するサービスを利用する際には、葬儀の告知・公表に必要な範囲で葬家の会員情報（個人情報を含みます。）を公表することに同意するものとします。</p>

        <br />
        第１４条（権利の帰属・利用）<br />
        <br />
        <p className="indent-3">１　本サービスにおいて、当社から提供される情報等に関する⼀切の知的財産権は当社又は当社にライセンスを許諾している者に帰属し、本規約によって会員が当社又は当社にライセンスを許諾している者の知的財産権を取得し、又は使用許諾されることはありません。</p>
        <p className="indent-3">２　会員は、当社の許諾を得ずに、当社が提供する情報等の翻訳、編集及び改変等を行い、又は第三者に使用し若しくは公開することはできず、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）をしてはなりません。</p>
        <p className="indent-3">３　本サービス上には商標、ロゴ及びサービスマーク等（以下、総称して「商標等」といいます。）が表示される場合がありますが、当社は、会員その他の第三者に対し何ら当該商標を譲渡し、又は使用を許諾するものではありません。</p>
        <p className="indent-3">４　会員は、会員情報を含む本サービス活用データについて、自らが送信又は保存等することについての適法な権利を有していること、及び送信等データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。</p>
        <p className="indent-3">５　会員は、本サービス活用データ(統計情報又は匿名加工情報に加工されたものに限ります。)について、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。</p>
        <p className="indent-3">６　会員は、当社及び当社から権利を承継し又は許諾された者に対して著作者人格権を行使しないことに同意するものとします。</p>

        <br />
        第１５条（外部サービス）<br />
        <br />
        <p className="indent-3">１　当社は、当社が指定する外部サービスと連携して本サービスを提供することができるものとします。</p>
        <p className="indent-3">２　会員は、当社及び当社から権利を承継し又は許諾された者と当社が指定する外部サービス（返礼品発送会社を含みますが、これに限定されません。）を提供する事業者との間で、会員の住所、氏名、性別、年代、電話番号、メールアドレス等の情報（個人情報を含みます。）を提供し又は提供を受けることに同意するものとします。</p>
        <p className="indent-3">３　外部サービスについては、当該サービスを提供する事業者が責任を負うものとし、当社は、外部サービスにつき、会員の特定の目的への適合性、商品的価値、正確性、有用性、完全性、適法性、会員に適用される団体の内部規則等への適合性、及びセキュリティ上の欠陥、エラー、バグ又は不具合が存しないこと、並びに第三者の権利を侵害しないことについて、いかなる保証も行うものではありません。</p>

        <br />
        第１６条 （禁止行為）<br />
        <br />
        <p className="indent-1">会員は、本サービスの利用にあたり、自ら又は第三者をして、以下の各号のいずれかに該当する行為をしてはならず、また、以下の各号の行為を直接又は間接に惹起し、又は容易にしてはなりません。</p>
        <p className="indent-4">(1) 法令又は公序良俗に違反する行為、又はそのおそれのある行為</p>
        <p className="indent-4">(2) 本サービスの誤作動を誘引する行為、又はそのおそれのある行為</p>
        <p className="indent-4">(3) 法令、裁判所の判決、決定若しくは命令、又は法令上拘束力のある行政措置に違反する行為及びこれらを助長する行為又はそのおそれのある行為</p>
        <p className="indent-4">(4) 本サービスを逆アセンブル、逆コンパイル、リバースエンジニアリング、その他本サービスのソースコードを解析する行為</p>
        <p className="indent-4">(5) スクレイピング、クローリング（クローラ、ロボット又はスパイダー等のプログラム）及びその他の類似の手段によって本サービスにアクセスし、又は本サービスに関する情報を取得する行為</p>
        <p className="indent-4">(6) 本サービスに接続されたシステムに権限なく不正にアクセスし又は当社設備に蓄積された情報を不正に書き換え若しくは消去する行為</p>
        <p className="indent-4">(7) 本サービスを複製、譲渡、貸与又は改変する行為</p>
        <p className="indent-4">(8) 当社、他の会員その他の第三者に成りすます行為</p>
        <p className="indent-4">(9) 同一のアカウントを複数の個人が利用する行為</p>
        <p className="indent-4">(10) 同一の個人が複数のアカウントを利用する行為</p>
        <p className="indent-4">(11) 容易に推測されるパスワードの使用、パスワードの使い回し</p>
        <p className="indent-4">(12) 本規約及び本サービスの趣旨・目的に反する行為</p>
        <p className="indent-4">(13) 第２６条第１項第１号に定義する反社会的勢力への利益供与行為</p>
        <p className="indent-4">(14) 当社に虚偽の情報（故人及び葬儀に関する情報を含むが、これに限られない。）を提供する行為</p>
        <p className="indent-4">(15) 第５条第１項に掲げるサービスを利用する目的以外の目的で本サービスを利用</p>
        <p className="indent-4">(16) 供物等の代金、香典又は本手数料について、日本国外で発行されたクレジットカードで支払いをする行為</p>
        <p className="indent-4">(17) その他、前各号に準じる場合であって、当該行為の態様及び頻度等を考慮して、本サービスの利用に著しく支障があるものであると当社が合理的に判断する行為</p>

        <br />
        第１７条 （規約違反の場合の措置等）<br />
        <br />
        <p className="indent-3">１　当社は、会員が次の各号の一に該当し又は該当するおそれがあると当社が合理的に判断した場合には、相当期間を定めて催告し、会員が当該期間内に是正しない場合には（ただし、第４号から第１６号に該当する場合については、何らの催告なく）、当該会員に対し、本サービスの利用の一時停止若しくは制限、アカウントの削除又は本利用契約の解除等の措置（以下、「利用停止等」といいます。）を講じることができるものとします。</p>
        <p className="indent-4">(1) 本規約のいずれかの条項に違反した場合</p>
        <p className="indent-4">(2) 会員が本規約に基づいて当社又は葬家、葬儀会社若しくは供物等販売会社に対して負担する債務の履行を遅滞し又は怠った場合（クレジットカード決済ができなかった場合を含みます。）</p>
        <p className="indent-4">(3) 供物等の提供、香典又は本手数料の支払いを遅滞し又は怠った場合</p>
        <p className="indent-4">(4) 当社に提供された情報（故人及び葬儀に関する情報を含むが、これに限られない。）の全部又は一部につき虚偽の事実があることが判明した場合</p>
        <p className="indent-4">(5) 死亡した場合、又は後見開始、保佐開始若しくは補助開始の審判を受けた場合であって当社が会員の状況等を確認し本サービスの利用を継続することが困難であると当社が合理的に判断した場合</p>
        <p className="indent-4">(6) 会員が未成年者、成年被後見人、被保佐人又は被補助人のいずれかであって、法定代理人、後見人、保佐人又は補助人の同意等を得ていないことが判明した場合</p>
        <p className="indent-4">(7) 重要な資産につき差押え、仮差押え又は競売の申立てがあったとき</p>
        <p className="indent-4">(8) 租税滞納処分を受けたとき</p>
        <p className="indent-4">(9) 支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始若しくはこれらに類する手続の開始の申立てがあった場合</p>
        <p className="indent-4">(10) 当社からの問い合わせその他の回答を求める連絡に対して３０日又は別途当社が指定した期間を超えて応答がない場合</p>
        <p className="indent-4">(11) 本サービスの利用に際して、過去に利用停止等の措置を受けたことがあり又は現在受けている場合</p>
        <p className="indent-4">(12) 第２６条第２項各号に定める場合</p>
        <p className="indent-4">(13) 第５条第１項に掲げるサービスを利用する目的以外の目的で本サービスを利用した場合</p>
        <p className="indent-4">(14) 供物等の代金、香典又は本手数料について、日本国外で発行されたクレジットカードで支払いをした場合</p>
        <p className="indent-4">(15) 本サービスの運営、保守管理上必要であると当社が合理的に判断した場合</p>
        <p className="indent-4">(16) その他前各号に類する事由であって、当該事由の態様及び頻度等を考慮して、本サービスの利用に著しく支障があるものと当社が合理的に判断した場合</p>
        <p className="indent-3">２　会員が前項各号のいずれかに該当し、前項に定める相当期間内に是正しない場合（ただし、第４号から第１６号に定める場合については、当該各号に該当した場合）、当該会員は、期限の利益を失い、当社及び第三者に対して本規約に基づいて負担する一切の義務及び債務（損害賠償債務を含みますが、これに限られません。）を直ちに履行しなければなりません。</p>
        <p className="indent-3">３　当社は、本条に基づき当社が行った行為により会員に生じた損害について責任を負わず、利用停止等後も、当該会員に関し当社が取得した情報を保有・利用することができるものとします。</p>

        <br />
        第１８条（本サービスの利用終了）<br />
        <br />
        <p className="indent-1">会員は、アカウントの削除その他当社の定める方法により、本サービスの利用を終了することができます。本サービスの利用を終了した会員は、利用終了の時点から本サービスを利用することができなくなります。</p>

        <br />
        第１９条（本サービスの変更・中断・終了等）<br />
        <br />
        <p className="indent-3">１　当社は、会員に事前に通知することなく、本サービスの内容の全部又は一部を変更又は追加することができるものとします。</p>
        <p className="indent-3">２　当社は、事前に、本サービス上への掲示その他当社が適当と判断する方法で会員に通知することにより、当社の裁量で、本サービスを終了することができるものとします。ただし、緊急の場合は会員への通知を行わずに、当社の裁量で本サービスを終了することができます。</p>
        <p className="indent-3">３　当社は、以下の各号に掲げる事由の一が生じた場合には、会員に事前に通知することなく、本サービスの一部又は全部を一時的に中断することができるものとします。</p>
        <p className="indent-4">(1) 本サービス用の通信機器設備等に関わるメンテナンスや修理を定期的又は緊急に行う場合</p>
        <p className="indent-4">(2) アクセス過多、その他予期せぬ要因でシステムに負荷が集中した場合</p>
        <p className="indent-4">(3) 会員のセキュリティを確保する必要が生じた場合</p>
        <p className="indent-4">(4) 本サービスの提供に必要な電気通信事業者の役務が提供されない場合</p>
        <p className="indent-4">(5) 天災等の不可抗力により本サービスの提供が困難な場合</p>
        <p className="indent-4">(6) 火災、停電、その他の不慮の事故又は戦争、紛争、動乱、暴動、感染症（未知のものを含みます。）、労働争議等により本サービスの提供が困難な場合</p>
        <p className="indent-4">(7) 法令又はこれらに基づく措置により本サービスの運営が不能となった場合</p>
        <p className="indent-4">(8) その他前各号に準じる事由が生じ、当社が合理的に必要と判断した場合</p>
        <p className="indent-3">４　当社は、本条に基づき当社が行った措置により会員に生じた損害について責任を負いません。</p>

        <br />
        第２０条（損害賠償）<br />
        <br />
        <p className="indent-3">１　会員による本規約違反行為その他本サービスの利用に起因して、当社に直接又は間接の損害が生じた場合（当該行為が原因で、当社が第三者から損害賠償請求その他の請求を受けた場合を含みます。）、会員は、当社に対し、その全ての損害（弁護士等専門家費用及び当社において対応に要した人件費相当額を含みます。）を賠償しなければなりません。</p>
        <p className="indent-3">２　当社は、本サービスの利用に関連して会員が被った損害について、責任を負いません。ただし、当社に故意又は過失がある場合はこの限りではありません。</p>

        <br />
        第２１条 （保証の否認及び免責）<br />
        <br />
        <p className="indent-3">１　当社は、商品、本サービス、その他本サービス上に表示された一切の情報につき、会員の特定の目的への適合性、商品的価値、正確性、有用性、完全性、適法性、会員に適用のある団体の内部規則等への適合性を有すること、及びセキュリティ上の欠陥、エラー、バグ又は不具合が存しないこと、並びに第三者の権利を侵害しないことについて、本規約で定めるほかは如何なる保証も行うものではありません。</p>
        <p className="indent-3">２　当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、会員はあらかじめ承諾するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。</p>
        <p className="indent-3">３　当社は、原則として会員間の通信や活動に関与しません。万一会員間又は会員と葬儀会社若しくは供物等販売会社との間で紛争や問題が生じた場合には、当該会員間又は会員と葬儀会社若しくは供物等販売会社の責任と費用でこれを解決するものとし、当社はこれに一切関与しません。</p>
        <p className="indent-3">４　本サービスの利用に関連して会員と第三者との間で紛争が生じた場合には、会員は自己の責任と費用でこれを解決するものとし、当社はこれに一切関与しません。</p>
        <p className="indent-3">５　本サービスは、第５条第１項に掲げる業務等の効率化の支援を目的とするものであって、業務等の、完全性を保証するものではありません。</p>

        <br />
        第２２条 （権利義務の譲渡禁止）<br />
        <br />
        <p className="indent-3">１　会員は、当社の書面による事前の承諾がある場合を除き、本利用契約に基づく会員の権利若しくは義務、又は本利用契約上の地位について、第三者への譲渡、承継、担保設定、その他一切の処分をすることはできません。</p>
        <p className="indent-3">２　当社が、本サービスにかかる事業の譲渡又は当社が消滅会社若しくは分割会社となる合併若しくは会社分割等（以下、「事業譲渡等」といいます。）により本サービスに係る事業を個別承継又は包括承継させたときは、当社は、当該事業譲渡等に伴い、本サービスに関する本利用契約上の地位、権利及び義務並びに会員情報その他の会員に関する情報を当該事業譲渡等の譲受人又は承継人に譲渡することができるものとし、会員は、予めこれに同意するものとします。</p>

        <br />
        第２３条 （委託）<br />
        <br />
        <p className="indent-1">当社は、本サービスに関する業務の一部又は全部を第三者に委託することができるものとします。</p>

        <br />
        第２４条（秘密保持）<br />
        <br />
        <p className="indent-3">１　会員は、本サービスに関して知り得た当社の情報（以下、「本情報」といいます。）について、秘密として扱うものとし、     本利用契約の目的以外に使用せず、当社の事前の書面による同意を得ない限り、第三者に開示又は遺漏してはならないものとします。</p>
        <p className="indent-3">２　前項の規定にかかわらず、本情報には、次の各号に該当する情報は含まれないものとします。</p>
        <p className="indent-4">(1) 会員が本サービスに関して本情報を知り得た時点で既に公知となっていた情報</p>
        <p className="indent-4">(2) 会員が本サービスに関して本情報を知り得た後に受領者の責めに帰すべき事由によらずに公知となった情報</p>
        <p className="indent-4">(3) 会員が本サービスに関して本情報を知り得た時点で受領者が既に保有していた情報</p>
        <p className="indent-4">(4) 会員が本サービスに関して本情報を知り得た後に受領者が正当な権限を有する第三者からの秘密保持義務を自ら負うことなく開示された情報</p>
        <p className="indent-3">３　本情報が不要となったときは、会員は遅滞なくこれらを当社に返還又は当社の指示に従った処置を行うものとする。</p>
        <p className="indent-3">４　会員が第１項に違反した場合には、当社は、直ちに本利用契約を解除することができるものとし、当該会員は、当社に対し、当該違反に起因して直接又は間接に当社に生じた損害を賠償するものとする。</p>

        <br />
        第２５条（情報管理）<br />
        <br />
        <p className="indent-3">１　本サービスの利用に関連して会員から送信等される会員に関する情報及び本サービス活用データの取扱いについては、別途、当社の定める当社プライバシーポリシー、個人情報保護法及びその他関連する法令等を遵守し、情報の適切な取扱い及び保護に努めます。</p>
        <p className="indent-3">２　当社は、個人データに該当する会員情報（以下「会員データ」といいます。）について、会員の事前の同意を得ずに第三者に提供しません。ただし、次の各号に掲げる場合はこの限りではありません。     </p>
        <p className="indent-4">(1) 本規約に定める場合</p>
        <p className="indent-4">(2) 法令に基づく場合</p>
        <p className="indent-4">(3) 人の生命、身体又は財産の保護のために必要があり、かつ会員の同意を得ることが困難な場合</p>
        <p className="indent-4">(4) 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</p>
        <p className="indent-4">(5) 当社が、利用目的の達成に必要な範囲内において会員データの取扱いの全部又は一部を委託することに伴って当該会員データを提供する場合</p>
        <p className="indent-4">(6)　合併、会社分割、事業譲渡その他の事由による事業の承継に伴って会員データを提供する場合</p>
        <p className="indent-4">(7)　当社が特定の者との間で共同して利用する会員データが当該特定の者に提供される場合であって、その旨並びに共同して利用される会員データの項目、共同して利用する者の範囲、利用する者の利用目的並びに当該会員データの管理について責任を有する者の氏名又は名称及び住所並びに法人にあっては、その代表者の氏名について、あらかじめ、本人に通知し、又は本人が容易に知り得る状態に置いているとき</p>
        <p className="indent-3">３　前項にかかわらず、当社は、統計情報を作成し、本サービス及び当社のその他のサービスのために利用することがあります。また、統計情報を第三者に開示することがあります。</p>
        <p className="indent-3">４　当社は、会員情報の紛失、破壊、改鼠、漏洩等の危険に対して、合理的な最大限の安全対策を講じます。</p>
        <p className="indent-3">５　当社は、電話応対品質向上等のため、会員との間の電話応対を録音し、録音内容を業務において使用することができるものとします。</p>

        <br />
        第２６条（反社会的勢力との取引排除）<br />
        <br />
        <p className="indent-3">１　会員は、当社に対し、次の各号の事項を確約するものとします。</p>
        <p className="indent-4">(1) 自らが、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋、社会的運動等標榜ゴロ、特殊知能暴力集団その他これらに準ずる者（総称して「反社会的勢力」といいます。）でないこと</p>
        <p className="indent-4">(2) 反社会的勢力と次の関係を有していないこと</p>
        <p className="indent-5">①　自ら若しくは第三者の不正の利益を図る目的、又は第三者に損害を与える目的をもって反社会的勢力を利用していると認められる関係</p>
        <p className="indent-5">②　反社会的勢力に対して資金等を提供し、又は便宜を供与するなど反社会的勢力の維持、運営に協力し、又は関与している関係</p>
        <p className="indent-4">(3) 反社会的勢力に自己の名義を使用させ、本利用契約を締結するものでないこと</p>
        <p className="indent-4">(4) 自ら又は第三者を利用して本利用契約に関して次の行為をしないこと</p>
        <p className="indent-5">①　暴力的な要求行為</p>
        <p className="indent-5">②　法的な責任を超えた不当な要求行為</p>
        <p className="indent-5">③　取引に関して、脅迫的な言動をし、又は暴力を用いる行為</p>
        <p className="indent-5">④　風説を流布し、偽計又は威力を用いて相手方の業務を妨害し、又は信用を毀損する行為</p>
        <p className="indent-5">⑤　その他前各号に準ずる行為</p>
        <p className="indent-3">２　会員について、次のいずれかに該当した場合には、当社は、何らの催告を要せずして、本利用契約を解除することができます。</p>
        <p className="indent-4">(1) 前項(1)及び(2)の確約に反する表明をしたことが判明した場合</p>
        <p className="indent-4">(2) 前項(3)の確約に反し契約を締結したことが判明した場合</p>
        <p className="indent-4">(3) 前項(4)の確約に反した行為をした場合</p>
        <p className="indent-3">３　前項の規定により本利用契約が解除された場合には、解除された者は、当社に対し、当社の被った損害を賠償するものとします。</p>
        <p className="indent-3">４　第２項の規定により本利用契約が解除された場合には、解除された者は、解除により生じる損害について、当社及び他の会員に対し一切の請求を行わないものとします。</p>

        <br />
        第２７条（存続条項）<br />
        <br />
        <p className="indent-1">第１１条（税金関係）、第１３条（葬儀の告知・公表）、第１４条（権利の帰属・利用）、第１５条（外部サービス）第３項、第１７条 （規約違反の場合の措置等）第２項及び第３項、第１９条（本サービスの変更・中断・終了等）第４項、第２０条（損害賠償）、第２１条（保証の否認及び免責）、第２４条（秘密保持）、第２５条（情報管理）、第２６条（反社会的勢力との取引排除）第３項及び第４項、本条（存続条項）、第２９条（本規約の有効性及び個別性）、第３０条（準拠法・合意管轄）、並びに、第３１条（協議）は、本利用契約終了後も効力を有するものとします。</p>

        <br />
        第２８条（完全合意）<br />
        <br />
        <p className="indent-1">本規約は、締結日現在における当社と会員との合意を規定したものであり、本利用契約締結以前に両当事者間でなされた協議内容、合意事項又は一方当事者から相手方に提供された資料、申入れその他の通信と本利用契約の内容とが相違する場合は、本利用契約が優先するものとします。</p>

        <br />
        第２９条（本規約の有効性及び個別性）<br />
        <br />
        <p className="indent-1">本規約のいずれかの規定が無効又は違法であっても、本規約のほかの規定はそれに何ら影響を受けることなく有効とします。</p>

        <br />
        第３０条（準拠法・合意管轄）<br />
        <br />
        <p className="indent-1">本利用契約は、準拠法を日本法とし、本利用契約に関して訴訟の必要が生じた場合には、名古屋地方裁判所を第一審の専属的合意管轄裁判所とします。</p>

        <br />
        第３１条（協議）<br />
        <br />
        <p className="indent-1">本規約に定めのない事項又は疑義が生じた事項については、信義誠実の原則に従い当事者間で協議し、円満に解決を図るものとします。</p>

        <br />
        2023年3月17日制定<br />
        2023年10月24日改訂<br />
        2024年5月1日改訂<br />
        <br />

        <br />
        別紙<br />
        <br />

        <p className="indent-3">第１０条第４項の「当社の定める内容」は以下のとおりとする。</p>
        <p className="indent-4">(1)香典について、葬儀会社の作成する葬儀イベントごとの区別はしない（ただし、当社から葬家に対して明細書を発行する）。</p>
        <p className="indent-4">(2)当社は、葬家の喪主名義の銀行口座に対して、当社が別途定める時点に、振り込む方法で支払う。振込手数料は、原則として当社の負担とする。</p>
        <p className="indent-4">(3)葬家の喪主は、当社に対して、前号の振込みを待たず任意の時期に喪主名義の銀行口座への振込みを依頼することができる。当社は、実務上可能な限り、これに応じるものとする。この場合、当社は喪主に対して当社が別途定める手数料を請求するものとし、当社は香典の金額から当該手数料相当額を差し引いた金額の振込みを行うものとする。</p>

        <br />
        以上<br />
        <br />
      </div>
      <br></br>
      <div className="text-center consent-button">
        <label><input className="check" id="check" type="checkbox" onChange={ handleChanged }/>　利用規約に同意する</label>
      </div>
      <div style={{ marginTop: '40px'}} className="d-flex justify-content-center">
        <button type="submit" className='terms-white-button mb-5' disabled={ !checked } onClick={ handleContinue }>続ける</button>
      </div>
    </div>
  )
}

const updateAttendeeTermsCheck = (email, termsChecked) => {
  const options = {
    body: {
      email: email,
      termsChecked: termsChecked
    }
  }
  return API.put('product', '/users/attendee/terms', options);
}

export default AttendanceTermsContents