import { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AuthContext } from 'components/Contexts';

export const AccountConfirm = (props) => {
  const { userInfo } = useContext(AuthContext);
  const history = useHistory();
  const match = useRouteMatch("/:funeralId");
  const prefix = "/" + match.params.funeralId;

  const handleLogin = () => {
    sessionStorage.setItem("returnUrl", history.location.pathname);

    history.push(prefix + "/login");
  }

  return (
    <div id="account-confirm">
      <h2>{userInfo ? "アカウントの確認" : "アカウントをお持ちの方"}</h2>
      {
        userInfo && userInfo.attendees !== null &&
          <div>
            現在 {userInfo.email} でログインしています
          </div>
      }
      <div className="account-buttons">
        <button type="button" className="btn btn-primary login-button" onClick={handleLogin}>{userInfo ? "ログインしなおす": "ログイン"}</button>
      </div>
    </div>
  )
}

export default AccountConfirm;