import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

export const Pagination = ({list, per, onChange, currentPage, setCurrentPage}) => {
  const LAST_DISPLAY_SIZE = 10;
  const AROUND_DISPLAY_PAGES = 3;

  const [page, setPage] = useState(currentPage || 0);

  const calculatePageCount = () => {
    return Math.ceil(list.length / per);
  };

  const handlePaginate = (selectedItem) => {
    // selectedItem.selectedには、ページ番号 - 1が入る
    const offset = selectedItem.selected * per;
    const sliceList = list.slice(offset, offset + per);
    onChange(sliceList);
    setPage(selectedItem.selected);
    if(setCurrentPage) {
      setCurrentPage(selectedItem.selected);
    }
  };

  useEffect(() => {
    // listが変化したときに、現在のページが最終ページより大きくなったら、最終ページに合わせる
    const pageNo = Math.min(page, Math.max(0, calculatePageCount() - 1));
    if(pageNo !== page) {
      setPage(pageNo);
      handlePaginate({ selected: pageNo });
    }
    // eslint-disable-next-line
  }, [list, page]);

  const arrowIcon = (iconName) => {
    return (
      <i className={"fas fa-chevron-" + iconName}></i>
    );
  };

  return (
    <ReactPaginate
      pageCount={calculatePageCount()}
      marginPagesDisplayed={LAST_DISPLAY_SIZE}
      pageRangeDisplayed={AROUND_DISPLAY_PAGES}
      onPageChange={handlePaginate}
      forcePage={Math.min(page, calculatePageCount() - 1)}
      containerClassName="pagination pagination-sm m-0 float-right"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      activeClassName="active"
      activeLinkClassName="active"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      previousLabel={arrowIcon('left')}
      nextLabel={arrowIcon('right')}
      disabledClassName="disabled-button"
    />
  )
}

export default Pagination;

