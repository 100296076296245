import React, { useState, useContext, useEffect } from 'react';
import { Route, Redirect, useRouteMatch, useHistory } from 'react-router-dom'
import { API, Auth } from 'aws-amplify';
import { AuthContext } from './Contexts';
import uuid from 'uuid';
import { getUserInfo } from "utils/userInfo";

export const PrivateRoute = ({ children }) => {
  const { setUserInfo } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const match = useRouteMatch("/:uuid");

  useEffect( () => {
    async function load() {
      const userinfo = await getUserInfo(match.params.uuid);
      setUserInfo(userinfo);
      setLoading(false);
    }
    load();
  }, [setUserInfo, match.params.uuid]);

  return (
    <React.Fragment>
      {loading ? "" : children}
    </React.Fragment>
  )
}

/**
 * システム管理者用のRoute
 * @param {*} param0 
 * @returns 
 */
export const SystemAdminRoute = (props) => {
  const { userInfo } = useContext(AuthContext);

  // システム管理者用ドメインでアクセスした場合
  if (userInfo && userInfo.admin) {
    // admin権限のあるユーザはアクセス可能
    return <Route {...props} />
  } else {
    // admin権限がなければ自動でsignOutして再度signInへ
    Auth.signOut();
    return <Redirect to="/signin" />
  }
}

/**
 * 葬儀社用のRoute
 * @param {} param0 
 * @returns 
 */
export const UndertakerRoute = (props) => {
  const { managerOnly, ...routeProps } = props;
  const { userInfo } = useContext(AuthContext);
  const match = useRouteMatch("/:companyId");
  const companyId = match.params.companyId;

  // 葬儀社用ドメインでアクセスした場合
  if (userInfo && userInfo.undertaker) {
    // undertaker権限かつ、管理者
    if (!managerOnly || userInfo.undertaker.manager) {
      // 利用規約ページ以外で規約に同意していなければ利用規約ページへ遷移する
      if (!isTermsPage(routeProps.path) && userInfo.undertaker.TermsChecked !== true) {
        return <Redirect to={`/${companyId}/terms`}/>;
      }
      return <Route {...routeProps} />
    } else {
      // 権限がない場合は、signoutしてsignin画面を表示
      Auth.signOut();
      return <Redirect to={`/${companyId}/signin`} />;
    }
  } else {
    // signinしていない場合は、葬儀社signin画面を表示
    Auth.signOut();
    return <Redirect to={`/${companyId}/signin`} />;
  }
}

/**
 * 葬家用のRoute
 * @param props
 * @returns
 */
export const FuneralRoute = (props) => {
  const { forAttendee, ...routeProps } = props;
  // アクセスログを葬家ごとにリアルタイムで記録する (awaitしない)
  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;
  createUserLogs(funeralId);

  // 参列者用の画面の場合 (認証状態や参列済み状態, 利用規約チェック状態などが影響する画面)
  if (forAttendee) return <AttendeesRoute {...routeProps} />
  return <Route {...routeProps} />
}

export const AttendeesRoute = (props) => {
  const { permit, ...routeProps } = props;
  const { userInfo } = useContext(AuthContext);
  const match = useRouteMatch("/:funeralId");
  const history = useHistory();

  if (match) {
    const funeralId = match.params.funeralId;

    // ログインしていない用のバイパス、現状は参列者トップ( path="/:funeralId" )専用
    if (userInfo === undefined) {
      return <Route {...routeProps} />
    }

    // 利用規約ページではない and 利用規約に未チェック なら利用規約ページへリダイレクトする
    if (!isTermsPage(routeProps.path) && !isTermsChecked(userInfo)) {
      sessionStorage.setItem('returnUrl', history.location.pathname);
      return <Redirect to={`/${funeralId}/attendanceterms`} />
    }

    // 葬儀社用ドメインでアクセスして参列済み or 葬家の場合は表示する
    if (userInfo && userInfo.attendees !== null) {
      return <Route {...routeProps} />
    } else {
      // TOPページならリダイレクトしない
      if (routeProps.path === "/:funeralId") {
        return <Route {...routeProps} />
      }
      // TOP以外ならTOPへリダイレクトする
      return <Redirect to={"/" + funeralId} />
    }
  }
  return <Redirect to={"/"} />
}

const isTermsPage = (path) => {
  return path === '/:companyId/terms'
    || path === '/:companyId/termsdocumentpage'
    || path === '/:funeralId/attendanceterms'
    || path === '/:funeralId/attendancetermsdocument';
}

// 認証済み and 利用規約にチェック済み ならtrue
const isTermsChecked = (userInfo) => {
  return userInfo && userInfo.attendeeTermsConsents && userInfo.attendeeTermsConsents.TermsChecked === true;
}

// ユーザーログを作成する
const createUserLogs = async (funeralId) => {
  // uniqueIdを取得, 存在しなければ生成
  const key = funeralId + "_uniqueId";
  const uniqueId = localStorage.getItem(key) ?? uuid.v4();
  localStorage.setItem(key, uniqueId);

  const options = {
    body: {
      uniqueId: uniqueId,
    }
  }
  await API.post('guest', `/userlogs/${funeralId}`, options);
}

export default PrivateRoute;