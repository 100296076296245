import * as format from 'utils/format';
import moment from 'moment-timezone';
import * as Constants from '../constants';

export const copyToClipboard = async (funeralId, funeralInfo, userInfo) => {
  let text = null;

  if (userInfo && userInfo.attendees !== null && userInfo.attendees.Relatives === true) {
    // 葬家-------------------------------------------------------------------------
    text = `
${funeralInfo.decreasedName}　儀、${format.date(funeralInfo.deathDay)}に永眠いたしました。
ここに生前のご厚誼を感謝し謹んでご通知申し上げます。
なお、通夜葬儀式は下記のとおり相営みます。

故　${funeralInfo.decreasedName}　儀　葬儀告別式

${format.date(funeralInfo.vigilDateTime) === "Invalid date" ? 
    "通夜式　　　　：執り行っておりません"
    :
    `通夜式　　　　：${format.dateTime(funeralInfo.vigilDateTime)}より`
}
葬儀告別式　　：${format.dateTime(funeralInfo.funeralDateTime)}より
会場名　　　　：${funeralInfo.hallName}
会場住所　　　：${format.address(funeralInfo.hallAddress)}
会場電話番号　：${funeralInfo.hallPhoneNo}

喪主　${funeralInfo.chiefMournerName}



葬式詳細
https://app.itowa71.com/${funeralId}
ページ公開期間
${format.date(funeralInfo.expiredDate)} 24時まで
`
    // -------------------------------------------------------------------------------
  } else {
    // 参列者 or ゲスト----------------------------------------------------------------
    text = `
${funeralInfo.decreasedName} 儀　逝去のお知らせ
${funeralInfo.decreasedName} 儀、${format.date(funeralInfo.deathDay)}に逝去されました。
通夜ならびに葬儀は下記の通り執り行われます。

故　${funeralInfo.decreasedName}　儀　葬儀告別式
${format.date(funeralInfo.vigilDateTime) === "Invalid date" ? 
    "通夜式　　　　：執り行っておりません"
    :
    `通夜式　　　　：${format.dateTime(funeralInfo.vigilDateTime)}より`
}
葬儀告別式　　：${format.dateTime(funeralInfo.funeralDateTime)}より
会場名　　　　：${funeralInfo.hallName}
会場住所　　　：${format.address(funeralInfo.hallAddress)}
会場電話番号　：${funeralInfo.hallPhoneNo}

喪主　${funeralInfo.chiefMournerName}



葬式詳細
https://app.itowa71.com/${funeralId}
ページ公開期間
${format.date(funeralInfo.expiredDate)} 24時まで
`
    // -------------------------------------------------------------------------------
 }

  await writeText(text);
}

export const copyToClipboardInviteFamily =  async (text) => {
  await writeText(text);
}

const writeText = async (text) => {
  if(navigator.clipboard) {
    await navigator.clipboard.writeText(text);
  } else {
    // 非SSL環境では使えないので、ログのみ
    alert('非SSL環境ではクリップボードにコピーできません。console.logで確認してください')
    console.log(text);
  }
}

const writeLineMessage = async (text) => {
  if(navigator.clipboard) {
    await navigator.clipboard.writeText(text);
  } else {
    // 非SSL環境では使えないので、ログのみ
    console.log(text);
    throw Error("非SSL環境ではクリップボードにコピーできません。console.logで確認してください");
  }
}

export const copyToClipboardLineMessage = async (item) =>{
   const text = `故　${item?.DecreasedName}　
  【通夜式】${item?.VigilDateTime !== "" ? moment(item?.VigilDateTime).format("YYYY年M月D日") : '執り行っておりません'}
  【葬儀式】${moment(item?.FuneralDateTime).format("YYYY年M月D日")}
  喪主：${item?.ChiefMournerName}
  
  ●会場・葬儀の詳細はコチラ
  ${Constants.FUNERALS_WEB_URL}/${item?.FuneralId}`;

  await writeLineMessage(text);
}

export const copyToClipboardQRCode = async (link) =>{
  const text = `「ご親族専用ページへログイン」
  ①最初に読み込みをされた方が【代表者様】となります
  ②２番目以降の読み込みは【ご家族様】となります
  ③itowa公式LINEより【葬儀情報】が通知されます
  ${link}`;
 
 await writeText(text);
}
