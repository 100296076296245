import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify'
import { Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Input from 'components/Input';
import useMessage from 'components/Message';
import UploadImage, { FILE_SIZE_LIMIT } from 'components/UploadImage';
import { useLoading } from 'components/Loading';
import { SearchBankCode } from '../../components/SearchBankCode';
import { ZipCode } from 'components/CustomForms/ZipCode';
import { prefCodeToName } from 'components/CustomForms/Prefecture';

export const CompanySetting = (props) => {
  const companyId = props.match.params.companyId;

  const [invoicePayment, setInvoicePayment] = useState(false);

  const message = useMessage();
  const loading = useLoading();
  const phonePattern = '^0(?!.*--)(?!.*-$)([0-9]{9,10}|[0-9]+(-[0-9]+)+)$'

  const formik = useFormik({
    initialValues: {
      brandName: "",
      invoiceCompanyName: "",
      directorZipCode: "",
      invoiceAddress: "",
      invoiceAddress2: "",
      representativePhoneNo: "",
      faxNumber1: "",
      invoiceRegistrationNumber: "",
      bankName: "",
      branchName: "",
      accountNo1: "",
      accountName1: "",
      paymentDeadline: "",
      invoiceRemarks: "",
      orderMailAddress: "",
      faxNumber: "",
      contactNumber: "",
      imprintPath: "",
      imagePath1: "",
      imagePath2: "",
      homePageLink: "",
      bankCode1: "",
      bankCode2: "",
      accountNo: "",
      accountName: "",
      accepted: false,
      personalInfoLink: "",
    },
    validationSchema: () =>
      Yup.object().shape({
        orderMailAddress: Yup.string()
          .required("受注メールアドレスは必須です")
          .email("メールアドレスの書式ではありません"),
          contactNumber: Yup.string().matches(new RegExp(phonePattern , 'u'),'10～11桁の半角数字でご入力ください'),
      }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        let valueSize = 0;
        Object.keys(values).forEach(row => {
          valueSize += values[row] && values[row].length ? values[row].length : 0;
        })

        if (valueSize < FILE_SIZE_LIMIT) {
          loading(true);
          try {
            await updateCompany(companyId, values);
            message.alert.success("設定を更新しました");
          } catch (e) {
            message.alert.danger("更新に失敗しました");
          }
          loading(false);
        } else {
          message.alert.danger("送信サイズが大きいため更新ができません。");
        }
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });
  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    const fetch = async () => {
      loading(true);
      const company = await getCompany(companyId);

      const brandName = company.brandName && company.brandName !== "" ? company.brandName : company.companyName;

      setFieldValue("brandName", brandName);
      setFieldValue("invoiceCompanyName", company.invoiceCompanyName);
      setFieldValue("directorZipCode", company.directorZipCode);
      setFieldValue("invoiceAddress", company.invoiceAddress);
      setFieldValue("invoiceAddress2", company.invoiceAddress2);
      setFieldValue("representativePhoneNo", company.representativePhoneNo);
      setFieldValue("faxNumber1", company.faxNumber1);
      setFieldValue("invoiceRegistrationNumber", company.invoiceRegistrationNumber);
      setFieldValue("bankName", company.bankName);
      setFieldValue("branchName", company.branchName);
      setFieldValue("accountNo1", company.accountNo1);
      setFieldValue("accountName1", company.accountName1);
      setFieldValue("paymentDeadline", company.paymentDeadline);
      setFieldValue("invoiceRemarks", company.invoiceRemarks);
      setFieldValue("orderMailAddress", company.orderMailAddress);
      setFieldValue("faxNumber", company.faxNumber);
      setFieldValue("contactNumber", company.contactNumber);
      setFieldValue("imagePath1", company.imagePath1);
      setFieldValue("imagePath2", company.imagePath2);
      setFieldValue("homePageLink", company.homePageLink);
      setFieldValue("personalInfoLink", company.personalInfoLink);
      setFieldValue("accepted", company.accepted);
      const { invoicePaymentCheck } = await getCompanyInvoicePayment(companyId);

      if (invoicePaymentCheck) {
        setInvoicePayment(true);
      }

      loading(false);
    }
    fetch();
  }, [setFieldValue, loading, companyId]);

  const handleChangeFamilyZipCode = (result) => {
    formik.setFieldValue("invoiceAddress", prefCodeToName(result.prefCode) + result.city.kanji + result.town.kanji);
  }

  return (
    <CommonLayout>
      <ContentHeader>
        {"葬儀社設定"}
      </ContentHeader>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            <div className="card">
              <div className="card-body">
                {invoicePayment ? (
                  <>
                    <h4>訃報ページ表示情報 <span className="text-danger fs-6">*は必須項目になります。</span></h4>
                    <div className="form-group">
                      <Row>
                        <Col md="6">
                          <label className="control-label">葬儀社名（屋号） <span className="text-danger fs-6">*</span></label>
                          <Input type="text" name="brandName" className="form-control" formik={formik} />
                        </Col>
                      </Row>
                    </div>
                    <br/>
                    <h4>領収書・請求書掲載情報 <span className="text-danger fs-6">*は必須項目になります。</span></h4>
                    <div className="form-group">
                      <Row>
                        <Col md="6">
                          <label className="control-label">会社名 <span className="text-danger fs-6">*</span></label>
                          <Input type="text" name="invoiceCompanyName" className="form-control" formik={formik} placeholder="株式会社◯◯" />
                        </Col>
                        <Col md="6">
                          <label className="control-label">郵便番号 <span className="text-danger fs-6">*</span></label>
                          <ZipCode name="directorZipCode" className="form-control" formik={formik} onChange={handleChangeFamilyZipCode} placeholder="1234567" /> {/*onChange={handleChangeDirectorZipCode}*/}
                        </Col>
                      </Row>
                    </div>
                    <div className="form-group">
                      <Row>
                        <Col md="6">
                          <label className="control-label">住所 <span className="text-danger fs-6">*</span></label>
                          <Input type="text" name="invoiceAddress" className="form-control" formik={formik} placeholder="愛知県名古屋市・・・" />
                        </Col>
                        <Col md="6">
                          <label className="control-label">建物名</label>
                          <Input type="text" name="invoiceAddress2" className="form-control" formik={formik} placeholder="グローバルゲート11F" />
                        </Col>
                      </Row>
                    </div>
                    <div className="form-group">
                      <Row>
                        <Col md="6">
                          <label className="control-label">電話番号 <span className="text-danger fs-6">*</span></label>
                          <Input type="text" name="representativePhoneNo" className="form-control" formik={formik} placeholder="052-123-4567" />
                        </Col>
                        <Col md="6">
                          <label className="control-label">FAX</label>
                          <Input type="text" name="faxNumber1" className="form-control" formik={formik} placeholder="052-123-4567" />
                        </Col>
                      </Row>
                    </div>
                    <div className="form-group">
                      <label className="control-label">登録番号(インボイス) </label>
                      <Input type="text" name="invoiceRegistrationNumber" className="form-control" formik={formik} placeholder="T1234567890123" />
                    </div>
                    <h4>振込先（請求書に記載）</h4>
                    <div className="form-group">
                      <Row>
                        <Col md="6">
                          <label className="control-label">銀行名 <span className="text-danger fs-6">*</span></label>
                          <Input type="text" name="bankName" className="form-control" formik={formik} placeholder="◯◯銀行" />
                        </Col>
                        <Col md="6">
                          <label className="control-label">支店名 <span className="text-danger fs-6">*</span></label>
                          <Input type="text" name="branchName" className="form-control" formik={formik} placeholder="◯◯支店" />
                        </Col>
                      </Row>
                    </div>
                    <div className="form-group">
                      <Row>
                        <Col md="6">
                          <label className="control-label">口座番号 <span className="text-danger fs-6">*</span></label>
                          <Input type="text" name="accountNo1" className="form-control" formik={formik} placeholder="0000000" />
                        </Col>
                        <Col md="6">
                          <label className="control-label">口座名義 (カナ) <span className="text-danger fs-6">*</span></label>
                          <Input type="text" name="accountName1" className="form-control" formik={formik} placeholder="カ）〇〇〇〇〇" />
                        </Col>
                      </Row>
                    </div>
                    <div className="form-group">
                      <label className="control-label">支払期限について (30文字まで) <span className="text-danger fs-6">*</span></label>
                      <Input type="text" name="paymentDeadline" className="form-control" maxlength="30" formik={formik} placeholder="翌月末日支払い" />
                    </div>
                    <div className="form-group">
                      <label className="control-label">請求書備考 (80文字まで) </label>
                      <Input type="text" name="invoiceRemarks" className="form-control" maxlength="80" formik={formik} />
                    </div>
                  </>
                ) : null}
                <h4>受注関連</h4>
                <div className="form-group">
                  <label className="control-label">受注メールアドレス</label>
                  <Input type="text" name="orderMailAddress" className="form-control" formik={formik} />
                </div>

                <div className="form-group">
                  <label className="control-label">FAX番号</label>
                  <Input type="text" name="faxNumber" className="form-control" formik={formik} />
                  <div className="text-end">※発注書自動作成されます。FAX送信料別途</div>
                </div>

                <div className="form-group">
                  <label className="control-label">問い合わせ番号（その他会場用）</label>
                  <Input type="text" name="contactNumber" className="form-control" formik={formik} />
                </div>

                <br />
                <div className="form-group">
                  <h4>ランディングページ関連</h4>
                  <Row>
                    <Col md="12">
                      <label className="control-label">バナー画像1</label>
                      <UploadImage type="file" name="imagePath1" className="form-control" formik={formik} width={600} />
                    </Col>
                    <Col md="12" className='mt-4'>
                      <label className="control-label">バナー画像２</label>
                      <UploadImage type="file" name="imagePath2" className="form-control" formik={formik} width={600} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <label className="control-label">ホームページURL</label>
                  <Input type="text" name="homePageLink" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">プライバシーポリシーURL</label>
                  <Input type="text" name="personalInfoLink" className="form-control" formik={formik} />
                </div>
                <br />
                <h4>決済関連</h4>
                <div className="form-group">
                  <label className="control-label">会社代表者・身分証明書のコピー</label>
                  <Row>
                    <Col md="6">
                      <label className="control-label">表面</label>
                      <UploadImage type="file" name="idFileFront" className="form-control" formik={formik} width={360} />
                    </Col>
                    <Col md="6">
                      <label className="control-label">裏面</label>
                      <UploadImage type="file" name="idFileBack" className="form-control" formik={formik} width={360} />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md="3">
                      <label className="control-label">銀行コード</label>
                      <SearchBankCode />
                      <Input type="text" name="bankCode1" className="form-control" formik={formik} placeholder="0000" />
                    </Col>
                    <Col md="3">
                      <label className="control-label">支店コード</label>
                      <Input type="text" name="bankCode2" className="form-control" formik={formik} placeholder="000" />
                    </Col>
                    <Col md="4">
                      <label className="control-label">口座番号</label>
                      <Input type="text" name="accountNo" className="form-control" formik={formik} placeholder="0000000" />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <label className="control-label">口座名義 (カナ)</label>
                  <Input type="text" name="accountName" className="form-control" formik={formik} placeholder="カ）〇〇〇〇〇" />
                </div>
                <div className="form-group" style={{ paddingLeft: "1.25rem" }}>
                  <label className="form-check-label">
                    <Input type="checkbox" name="accepted" className="form-check-input" formik={formik} />
                    Stripeの利用規約に同意します
                  </label>
                </div>
              </div>
              <div className="card-footer">
                <div>
                  <Button type="submit" variant="success">更新</Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </form>

    </CommonLayout>
  );
};

const getCompanyInvoicePayment = async (companyId) => {
  return await API.get('product', '/companies/' + companyId);
}

const getCompany = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }
  return await API.get('product', '/companies/setting', options);
}

const updateCompany = async (companyId, values) => {
  values.companyId = companyId;
  const options = {
    body: values
  }
  return await API.put('product', '/companies/setting', options);
}

export default CompanySetting;