import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const LogoutRedirect = () => {
  const history = useHistory();

  useEffect(() => {
    const funeralId = sessionStorage.getItem('funeralId');
    sessionStorage.removeItem('funeralId');
    history.push(`/${funeralId}`);
  });

  return (
    <div></div>
  )
}

export default LogoutRedirect;