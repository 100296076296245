import { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import * as Constant from '../constants';
import heic2any from 'heic2any';
import imageCompression from 'browser-image-compression';

const Empty = styled.div`
  background-color: #DDDDDD;
  display: flex;
  justify-content: center;
  align-items: center;
`

const EmptyText = styled.div`
  font-size: 16px;
`

export const FILE_SIZE_LIMIT = 5000000;

const ErrorText = styled.div`
  color: #FF0000;
`

export const UploadImage = (props) => {
  const { name, formik } = props;
  const width = props.width || 200;
  const height = props.height || 200;
  const inputRef = useRef(null);
  const [fileUrl, setFileUrl] = useState(null);
  const previewPath = formik.values[name];
  const [preview, setPreview] = useState(false);
  const [error, setError] = useState(false);
  const fileUrlwithtime = (fileUrl && fileUrl.startsWith("blob:")) ? fileUrl : fileUrl + "?time=" + Date.now();

  useEffect(() => {
    if(previewPath && previewPath.slice(0, 10) !== "data:image") {
      setFileUrl(Constant.IMAGE_BUCKET_URL + previewPath);
    }
    setError(false);
  }, [previewPath]);

  const fileUpload = () => {
    inputRef.current.click()
  }

  const handleOnChange = async (e) => {
    const reader = new FileReader();
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size > FILE_SIZE_LIMIT) {
        setFileUrl(null);
        setError(true)
        return false;
      }

      const file = await heicFileConverter(e.target.files[0])
      const compressedFile = await imageFileCompression(file);

      reader.readAsDataURL(compressedFile);
      reader.onload = () => {
        formik.setFieldValue(name, reader.result);
      }
      setFileUrl(URL.createObjectURL(file));

      setError(false)
    }
  }

  const handleOnDelete = () => {
    formik.setFieldValue(name, "");
    setFileUrl("");
  }

  const heicFileConverter = async (file) => {
    // 拡張子を小文字に変換して取得
    const ext = file.name.split('.').pop().toLowerCase();

    // heicか判定
    if (ext !== 'heic') return file

    const blobUrl = URL.createObjectURL(file);
    const blobRes = await fetch(blobUrl)
    const imgBlob = await blobRes.blob()
    // heicをjpegに変換
    return await heic2any({
      blob: imgBlob,
      toType: 'image/jpeg',
    });
  }

  // 画像を圧縮する
  const imageFileCompression = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920
    }
    return await imageCompression(file, options);
  }

  return (
    <div>
      <div>
        {fileUrl ?
          <img src={fileUrlwithtime} style={{width: width + "px", height: height + "px", objectFit: "cover"}} alt="" onClick={() => setPreview(true)}/>
          :
          <Empty style={{ width: width + "px", height: height + "px" }}><EmptyText>Empty</EmptyText></Empty>
        }
      </div>
      <Modal show={preview} size="lg" onHide={() => setPreview(false)} centered>
        <img src={fileUrlwithtime} alt=""/>
      </Modal>
      <div>
        {
            error ?
              <ErrorText>5MBを超えるファイルはアップロードできません</ErrorText>
            :
              <></>
          }
        <Button variant="secondary" className="btn btn-sm" onClick={fileUpload} style={{ margin: "2px" }}>{fileUrl ? "ファイル変更" : "ファイル追加"}</Button>
        <Button variant="secondary" className="btn btn-sm" onClick={handleOnDelete} style={{ margin:"2px"}}>クリア</Button>
        {props?.recommendText && <span className='ml-3 mt-2'>{props?.recommendText}</span>}
        <input type="file" ref={inputRef} accept="image/*, .heic" hidden onChange={handleOnChange}/>
      </div>
    </div>
  )
}

export default UploadImage;