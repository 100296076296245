import { useState, useEffect, useRef, useContext } from 'react';
import { API } from 'aws-amplify'
import { useRouteMatch } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { saveAs } from 'file-saver';

import AttendeeLayout from 'components/AttendeeLayout';
import Pagination from 'components/Pagination';
import { useFormik } from 'formik';
import Input from 'components/Input';
import useMessage from 'components/Message';
import { FUNERALS_WEB_URL, IMAGE_BUCKET_URL } from "../../constants";
import QRCode from 'react-qr-code';
import {copyToClipboardInviteFamily} from "utils/copyToClipboard";
import {AttendanceContext} from "components/Contexts";

export const FuneralUserList = (props) => {
  const PER_PAGE = 10;
  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;

  const message = useMessage();

  const { funeralInfo } = useContext(AttendanceContext);
  const [funeralUserList, setFuneralUserList] = useState([]);
  const [pagenatedList, setPagenatedList] = useState([]);
  const [externalInvite, setExternalInvite] = useState(false);
  const [qrInvite, setQrInvite] = useState(false);
  const [invite, setInvite] = useState(false);
  const [isLine, setIsLine] = useState(false);
  const [edit, setEdit] = useState(false);
  const searchKey = useRef("");
  const [reload, setReload] = useState(true);
  const [oldEmail, setOldEmail] = useState("");
  const [copied, setCopied] = useState(false);

  const shareText = `${funeralInfo.familyName} 家様のitowa訃報案内ページへ招待いたします
下記URLをタップしてLINEで登録してください

${FUNERALS_WEB_URL}/${funeralId}/signin/line
※招待リンクには有効期限がありますのでお早めにご参加ください`;

  useEffect(() => {
    if (reload) {
      const fetch = async () => {
        const result = await getFuneralUserList(funeralId, searchKey.current.value);
        const resultList = result.funeralUserList.sort((a, b) => a.Id < b.Id ? -1 : 1);
        setFuneralUserList(result.funeralUserList);
        setPagenatedList(resultList.slice(0, PER_PAGE));
      }
      fetch();
      setReload(false);
    }
  }, [funeralId, reload]);

  const redirectToLine = () => {
    window.location.href=`https://line.me/R/share?text=${encodeURI(shareText)}`;
  };

  const openInvite = () => {
    formik.setFieldValue("userName", "");
    formik.setFieldValue("email", "");
    setInvite(true);
  }

  const openEdit = (user) => {
    formik.setFieldValue("userName", user.UserName);
    formik.setFieldValue("email", user.Email);
    setOldEmail(user.Email)
    if (user.LineUserId) {
      setIsLine(true)
    } else {
      setIsLine(false)
    }
    setEdit(true);
  }

  const handleDelete = async () => {
    await deleteFuneralUser(funeralId, formik.values.email);
    setEdit(false);
    setReload(true);
  }

  const handleResend = async () => {
    const { error } = await sendEmailUser(funeralId, formik.values.email);
    if (error) {
      message.alert.danger("メールを送信できませんでした。");
    } else {
      message.alert.success("メールを送信しました。");
      setEdit(false);
    }
  }

  const handleDownloadObituary = async () => {
    const response = await downloadObituary(funeralId);
    if(response.filename) {
      saveAs(`${IMAGE_BUCKET_URL}${response.filename}?openExternalBrowser=1`, '訃報案内.pdf');
    }
  }

  const handleCopyClick = async () => {
    await copyToClipboardInviteFamily(shareText);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  }

  const formik = useFormik({
    initialValues: {
      userName: "",
      email: ""
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("名前は必須です"),
      email: Yup.string().required("メールアドレスは必須です").email("メールアドレスの書式ではありません"),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        if (invite) {
          const { error } = await createFuneralUser(funeralId, values);
          setInvite(false);
          setReload(true);
          if (error) {
            message.alert.danger("家族の招待ができませんでした。");
          } else {
            message.alert.success("家族の招待が完了しました。");
            setEdit(false);
          }
        }

        if (edit) {
          const { error } = await updateFuneralUser(funeralId, oldEmail, values);
          setEdit(false);
          setReload(true);
          if (error) {
            message.alert.danger("葬家ユーザーの更新ができませんでした。");
          } else {
            message.alert.success("葬家ユーザーの更新が完了しました。");
            setEdit(false);
          }
        }
      }
    },
    validateOnBlur: false,
    validateOnChange: false
  });

  return (
    <AttendeeLayout adminPage={true}>
      <div id="funeral-users">
        <div className="title-invite">
          <h2 className="title">葬家ユーザー管理</h2>
          <div>
            <button type="button" className="black-button btn mb-2" onClick={handleDownloadObituary}>訃報案内PDF</button>
            {' '}
            <button type="button" className="black-button btn mb-2" onClick={() => setExternalInvite(true)}>LINEで招待する</button>
            {' '}
            <button type="button" className="btn btn-primary black-button mb-2" onClick={openInvite}>メールで招待する</button>
          </div>
        </div>
        <div className="card gothic">
          <div className="card-body p-0" style={{ overflow: "scroll", height: "50vh" }}>
            <table className="table" style={{ width: "800px" }}>
              <thead>
                <tr>
                  <th style={{ width: "20%" }}></th>
                  <th style={{ width: "20%" }}>名前</th>
                  <th>メールアドレス</th>
                </tr>
              </thead>
              <tbody>
                {
                  pagenatedList.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td><button className="edit btn btn-primary gray-button" type="button" onClick={() => openEdit(row)}>編集</button></td>
                        <td>{row.UserName}</td>
                        <td>{row.Email}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
          <div className="card-footer clearfix">
            <Pagination per={PER_PAGE} list={funeralUserList} onChange={(list) => setPagenatedList(list)} />
          </div>
        </div>
        <Modal id="invite-modal" show={externalInvite} onHide={() => setExternalInvite(false)} backdrop="static" centered={true} size="lg">
          <Modal.Header className="header" closeButton />
          <Modal.Body className="funeral-users">
            <div className={'d-flex justify-content-center'}>

              <div className={'d-flex flex-column mx-4'}>
                <button type="button" className="btn rounded-circle p-0" onClick={redirectToLine}>
                  <img src={'/images/icon_line.png'} width={50} height={50} alt={'line'}/>
                </button>
                <p className="text-center">LINE</p>
              </div>

              <div className={'d-flex flex-column mx-4'}>
                <button type="button" className="btn rounded-circle p-0" onClick={() => setQrInvite(true)}>
                  <img src={'/images/icon_qr.png'} width={50} height={50} alt={'qr'}/>
                </button>
                <p className="text-center">QRコード</p>
              </div>

              <div className={'d-flex flex-column mx-4'}>
                <button type="button" className="btn rounded-circle p-0" onClick={handleCopyClick}>
                  <img src={'/images/icon_copy.png'} width={50} height={50} alt={'copy'}/>
                </button>
                <p className="text-center">コピー</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal id="copied-modal" show={copied} onHide={() => setCopied(false)} backdrop="static" centered={true} size="lg">
          <Modal.Body>
            <p className="text-center m-0">訃報をクリップボードにコピーしました</p>
          </Modal.Body>
        </Modal>

        <Modal show={qrInvite} centered={true}>
          <Modal.Header>
            <div className={'mx-auto'}>
              <Modal.Title>QRコードで招待</Modal.Title>
            </div>
            <button type="button" className="btn-close ml-0" aria-label="Close" onClick={() => setQrInvite(false)}></button>
          </Modal.Header>
          <Modal.Body>
            <div style={{marginLeft: "5%", marginRight: "5%"}}>
              <div style={{background: 'white', padding: '16px', textAlign: "center"}}>
                <QRCode
                    size={256}
                    style={{height: "auto", maxWidth: "75%", width: "75%"}}
                    value={`${FUNERALS_WEB_URL}/${funeralId}/registration`}
                    viewBox={`0 0 256 256`}
                />
              </div>
              <p>上記のQRコードを家族の方に読み込むようにお伝えてください。QRコードを読み込むと登録画面が開きます。</p>
              <div style={{width: "100%", textAlign: "center"}}>
                <Button onClick={() => setQrInvite(false)}
                        style={{
                          width: "60%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          border: "0.1rem solid rgba(170, 170, 170, 0.8)"
                        }}
                        variant={"secondary"}>
                  読み取り完了
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal id="funeral-modal" show={invite} onHide={() => setInvite(false)} backdrop="static" centered={true} size="lg">
          <Modal.Header className="header" closeButton >
          </Modal.Header>
          <Modal.Body className="funeral-users">
            <h2>家族を招待する</h2>
            <hr />
            招待メールを送る
            <form className="form-horizontal" onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">名前</label>
                <div className="col-sm-9">
                  <Input type="text" name="userName" className="form-control" formik={formik} />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">メールアドレス</label>
                <div className="col-sm-9">
                  <Input type="text" name="email" className="form-control" formik={formik} />
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <button type="submit" className="black-button btn btn-primary invite" name="submitType" value="create">招待する</button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal id="funeral-modal" show={edit} onHide={() => setEdit(false)} backdrop="static" centered={true} size="lg">
          <Modal.Header className="header" closeButton >
          </Modal.Header>
          <Modal.Body className="funeral-users">
            <h2>葬家ユーザーの編集</h2>
            <hr />
            <form className="form-horizontal" onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">名前</label>
                <div className="col-sm-9">
                  <Input type="text" name="userName" className="form-control" formik={formik} />
                </div>
              </div>
              {!isLine &&
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">メールアドレス</label>
                    <div className="col-sm-9">
                      <Input type="text" name="email" className="form-control" formik={formik} />
                    </div>
                  </div>
              }
              <div className="buttons">
                <button type="button" className="black-button btn btn-danger" onClick={handleDelete}>削除</button>
                {!isLine &&
                    <>
                      <button type="submit" className="black-button btn btn-success">更新</button>
                      <button type="button" className="black-button btn btn-success" onClick={handleResend}>メール再送</button>
                    </>
                }
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </AttendeeLayout>
  );
};

const getFuneralUserList = async (funeralId, keyword) => {
  const options = {
    queryStringParameters: {
      funeralId: funeralId,
      keyword: keyword
    }
  };

  return await API.get('product', '/users/funeral', options);
}

const createFuneralUser = async (funeralId, values) => {
  const options = {
    body: {
      funeralId: funeralId,
      values: values
    }
  }
  return await API.post('product', '/users/funeral', options);
}

const updateFuneralUser = async (funeralId, oldEmail, values) => {
  const options = {
    body: {
      funeralId: funeralId,
      oldEmail: oldEmail,
      values: values
    }
  }
  return await API.put('product', '/users/funeral', options);
}

const deleteFuneralUser = async (funeralId, email) => {
  const options = {
    body: {
      funeralId: funeralId,
      userId: email,
    }
  }
  return await API.del('product', '/users/funeral', options);
}

const sendEmailUser = async (funeralId, email) => {
  const options = {
    body: {
      funeralId: funeralId,
      email: email,
    }
  }
  return await API.post('product', '/mail/users/funeral', options);
}

const downloadObituary = async (funeralId) => {
  return await API.get('product', '/funerals/'+ funeralId + '/download/obituary');
}

export default FuneralUserList;