export const ItemTypeToName = (type) => {
  switch (type) {
    case "money":
      return "香典";
    case "telegram":
      return "弔文";
    case "things":
      return "供物"
    case "flower":
      return "供花";
    default:
      return "undefined"
  }
}

export default ItemTypeToName;