import {
  BrowserRouter as Router,
  Route, Switch
} from 'react-router-dom';
import { PrivateRoute, SystemAdminRoute, UndertakerRoute, FuneralRoute } from './components/PrivateRoute';

import * as Constants from './constants';

import SignIn from './pages/SignIn';
import LogIn from './pages/LogIn';
import AdminLogIn from './pages/auth/admin/LogIn';
import Registration from './pages/Registration';
import Top from './pages/Top';
import OTP from './pages/OTP';
import PaymentTest from './pages/PaymentTest';
import * as Admin from 'pages/admin';
import * as Attendee from 'pages/attendee';
import * as Undertaker from 'pages/undertaker';
import Blank from 'pages/Blank';
import Password from 'pages/Password';
import AccessLogList from 'pages/AccessLogList';
import PasswordReset from 'pages/PasswordReset';
import AdminPasswordReset from 'pages/auth/admin/PasswordReset';
import LoginRedirect  from 'pages/LoginRedirect';
import LogoutRedirect  from 'pages/LogoutRedirect';
import Terms from 'pages/Terms';
import TermsDocumentPage from 'pages/TermsContentsPage';
import AttendanceTerms from 'pages/AttendanceTerms';
import PreRegistration from './pages/PreRegistration';
import PreRegistrationSubmit from './pages/PreRegistrationSubmit';
import PreRegistrationThanks from './pages/PreRegistrationThanks';
import LogInLine from './pages/LogInLine';
import ReactGA from 'react-ga4';
import LineFamilySignIn from "pages/LineFamilySignIn";
import LineFamilySignInSubmit from "pages/LineFamilySignInSubmit";
import LineFamilySignInThanks from "pages/LineFamilySignInThanks";
import FuneralRegistration from './pages/mourner/FuneralRegistration';
import FuneralRegistrationSubmit from './pages/mourner/FuneralRegistrationSubmit';
import FuneralRegistrationThanks from './pages/mourner/FuneralRegistrationThanks';
import FuneralRegistrationError from './pages/mourner/FuneralRegistrationError';
import OrderForm from './pages/attendee/OrderForm';

export const App = () => {
  // 参考: https://medium.com/@vergil333/google-analytics-4-in-react-a250005c2455
  ReactGA.initialize("G-NZDFB4BS13");

  const url = new URL(document.URL);
  switch(document.domain) {
    case Constants.LEGACY_ADMIN_DOMAIN:
      url.host = Constants.ADMIN_DOMAIN;
      document.location = url.toString();
      break;
    case Constants.LEGACY_UNDERTAKER_DOMAIN:
      url.host = Constants.UNDERTAKER_DOMAIN;
      document.location = url.toString();
      break;
    case Constants.LEGACY_FUNERALS_DOMAIN:
      url.host = Constants.FUNERALS_DOMAIN;
      document.location = url.toString();
      break;
    case Constants.ADMIN_DOMAIN:
      return (
        <Router>
          <Switch>
            <Route exact path="/" component={Top} />
            <Route exact path="/signin" render={() => <AdminLogIn type="admin" />} />
            <Route exact path="/password-reset" render={() => <AdminPasswordReset type="admin" />} />
            <PrivateRoute>
              <Switch>
                <SystemAdminRoute exact path="/main" component={Admin.Main} />
                <SystemAdminRoute exact path="/password" component={Password} />
                <SystemAdminRoute exact path="/otp" component={OTP} />
                <SystemAdminRoute exact path="/users" component={Admin.UserList} />
                <SystemAdminRoute exact path="/users/new" render={(props) => <Admin.UserEdit method="new" {...props} />} />
                <SystemAdminRoute exact path="/users/:userId" component={Admin.UserEdit} />
                <SystemAdminRoute exact path="/companies" component={Admin.CompanyList} />
                <SystemAdminRoute exact path="/companies/new" render={(props) => <Admin.CompanyEdit method="new" {...props} />} />
                <SystemAdminRoute exact path="/companies/:companyId" component={Admin.CompanyEdit} />
                <SystemAdminRoute exact path="/items" component={Admin.ItemList} />
                <SystemAdminRoute exact path="/items/new" render={(props) => <Admin.ItemEdit method="new" {...props} />} />
                <SystemAdminRoute exact path="/items/:itemId" component={Admin.ItemEdit} />
                <SystemAdminRoute exact path="/gifts/list" component={Admin.GiftManagement} />
                <SystemAdminRoute exact path="/gifts/new" render={(props) => <Admin.GiftEdit method="new" {...props} />} />
                <SystemAdminRoute exact path="/gifts/:giftId" component={Admin.GiftEdit} />
                <SystemAdminRoute exact path="/accesslogs" component={AccessLogList} />
                <SystemAdminRoute exact path="/statistics" component={Admin.Statistics} />
                <SystemAdminRoute exact path="/salesscore" component={Admin.SalesScore} />
                <SystemAdminRoute exact path="/obituary/search" component={Admin.ObituarySearch} />
                <SystemAdminRoute exact path="/payment/csv/download" component={Admin.PaymentCsvDownload} />
              </Switch>
            </PrivateRoute>
          </Switch>
        </Router >
      )
    case Constants.UNDERTAKER_DOMAIN:
      return (
        <Router>
          <Switch>
            <Route exact path="/" component={Blank} />
            <Route exact path="/:companyId/" component={Undertaker.Top} />
            <Route exact path="/:companyId/hallinfo/:hallId" component={Undertaker.HallInfo} />
            <Route exact path="/:companyId/law/info" component={Undertaker.CompanyLawInfo} />
            <Route exact path="/:companyId/signin" render={() => <AdminLogIn type="undertaker" />} />
            <Route exact path="/:companyId/password-reset" render={() => <AdminPasswordReset type="undertaker" />} />
            <Route exact path="/:companyId/order-form" component={OrderForm} />
            <PrivateRoute>
              <Switch>
                <UndertakerRoute exact path="/:companyId/password" component={Password} />
                <UndertakerRoute exact path="/:companyId/terms" component={Terms} />
                <UndertakerRoute exact path="/:companyId/termsdocumentpage" component={TermsDocumentPage} />
                <UndertakerRoute exact path="/:companyId/otp" component={OTP} />
                <UndertakerRoute exact path="/:companyId/main" component={Undertaker.Main} />
                <UndertakerRoute exact path="/:companyId/funerals" component={Undertaker.FuneralList} />
                <UndertakerRoute exact path="/:companyId/funerals/new" render={(props) => <Undertaker.FuneralEdit method="new" {...props} />} />
                <UndertakerRoute exact path="/:companyId/funerals/:funeralId" component={Undertaker.FuneralEdit} />
                <UndertakerRoute exact path="/:companyId/funerals/:funeralId/kouden" component={Undertaker.FuneralKoudenList} />
                <UndertakerRoute exact path="/:companyId/funerals/:funeralId/time-schedule" component={Undertaker.FuneralTimeSchedule} />
                <UndertakerRoute exact path="/:companyId/funerals/:funeralId/order" component={Undertaker.FuneralOrderList} />
                <UndertakerRoute exact path="/:companyId/users" component={Undertaker.UserList} />
                <UndertakerRoute exact path="/:companyId/users/new" render={(props) => <Undertaker.UserEdit method="new" {...props} />} />
                <UndertakerRoute exact path="/:companyId/users/:userId" component={Undertaker.UserEdit} />
                <UndertakerRoute exact path="/:companyId/companies" component={Undertaker.CompanySetting} />
                <UndertakerRoute exact path="/:companyId/time-schedule-templates/new" render={(props) => <Undertaker.FuneralTimeScheduleTemplates method="new" {...props} />} />
                <UndertakerRoute exact path="/:companyId/time-schedule-templates/:templateId" component={Undertaker.FuneralTimeScheduleTemplates} />
                <UndertakerRoute exact path="/:companyId/halls" component={Undertaker.HallList} />
                <UndertakerRoute exact path="/:companyId/halls/new" render={(props) => <Undertaker.HallEdit method="new" {...props} />} />
                <UndertakerRoute exact path="/:companyId/halls/:hallId" component={Undertaker.HallEdit} />
                <UndertakerRoute exact path="/:companyId/items" component={Undertaker.ItemList} />
                <UndertakerRoute exact path="/:companyId/items/new" render={(props) => <Undertaker.ItemEdit method="new" {...props} />} />
                <UndertakerRoute exact path="/:companyId/items/template" component={Undertaker.ItemTemplate2} />
                <UndertakerRoute exact path="/:companyId/items/:itemId" component={Undertaker.ItemEdit} />
                <UndertakerRoute exact path="/:companyId/gifts/list" component={Undertaker.GiftManagement} />
                <UndertakerRoute exact path="/:companyId/gifts/new" render={(props) => <Undertaker.GiftEdit method="new" {...props} />} />
                <UndertakerRoute exact path="/:companyId/gifts/:giftId" component={Undertaker.GiftEdit} />
                <UndertakerRoute exact path="/:companyId/suppliers" component={Undertaker.SupplierList} />
                <UndertakerRoute exact path="/:companyId/suppliers/new" render={(props) => <Undertaker.SupplierEdit method="new" {...props} />} />
                <UndertakerRoute exact path="/:companyId/suppliers/:supplierId" component={Undertaker.SupplierEdit} />
                <UndertakerRoute exact path="/:companyId/accesslogs" component={AccessLogList} />
                <UndertakerRoute exact path="/:companyId/statistics" component={Undertaker.Statistics} />
                <UndertakerRoute exact path="/:companyId/salesscore" component={Undertaker.SalesScore} />
                <UndertakerRoute exact path="/:companyId/histories" component={Undertaker.HistoryList} />
                <UndertakerRoute exact path="/:companyId/histories/:userId" component={Undertaker.FuneralHistoryList} />
                <UndertakerRoute exact path="/:companyId/histories/:userId/:funeralId" component={Undertaker.FuneralHistoryAttendeeList} />
                <UndertakerRoute exact path="/:companyId/histories/:userId/:funeralId/:attendeeId" component={Undertaker.FuneralHistoryOrderList} />
                <UndertakerRoute exact path="/:companyId/stripeConnect/new" render={(props) => <Undertaker.CompanyStripeConnectEdit method="new" {...props} />} />
                <UndertakerRoute exact path="/:companyId/stripeConnect/:stripeConnectId" component={Undertaker.CompanyStripeConnectEdit} />
                <UndertakerRoute exact path="/:companyId/time-schedule-items" component={Undertaker.FuneralTimeScheduleItem} />
                <UndertakerRoute exact path="/:companyId/time-schedule-template-list" component={Undertaker.FuneralTimeScheduleList}/>
              </Switch>
            </PrivateRoute>
          </Switch>
        </Router>
      )
    case Constants.FUNERALS_DOMAIN:
      return (
        <Router>
          <Switch>
            {/* ユーザ認証をしない */}
            <Route exact path="/" component={Blank} />
            <FuneralRoute exact path="/:funeralId/signin" component={SignIn} />
            <FuneralRoute exact path="/:funeralId/login" component={LogIn} />
            <FuneralRoute exact path="/:funeralId/regist" component={Registration} />
            <FuneralRoute exact path="/:funeralId/password-reset" component={PasswordReset} />
            <Route exact path="/login/redirect" component={LoginRedirect} />
            <Route exact path="/logout/redirect" component={LogoutRedirect} />
            <Route exact path="/:companyId/pre-registration" component={PreRegistration} />
            <Route exact path="/pre-registration/submit" component={PreRegistrationSubmit} />
            <Route exact path="/pre-registration/thanks" component={PreRegistrationThanks} />
            <FuneralRoute exact path="/:funeralId/login/line" component={LogInLine} />
            <Route exact path="/:funeralId/signin/line" component={LineFamilySignIn} />
            <Route exact path="/signin/line/submit" component={LineFamilySignInSubmit} />
            <Route exact path="/signin/line/thanks" component={LineFamilySignInThanks} />
            <Route exact path="/:funeralId/registration" component={FuneralRegistration} />
            <Route exact path="/registration/submit" component={FuneralRegistrationSubmit} />
            <Route exact path="/registration/thanks" component={FuneralRegistrationThanks} />
            <Route exact path="/registration/error" component={FuneralRegistrationError} />
            <PrivateRoute>
              <Switch>
                {/* ユーザ認証処理は実行するが、ゲストでも許可 */}
                <FuneralRoute exact forAttendee path="/:funeralId" component={Attendee.Top} />
                <FuneralRoute exact path="/:funeralId/attendance/content/select/kouden" component={Attendee.AttendanceContentSelectKouden} />
                <FuneralRoute exact path="/:funeralId/attendance/content/select/chouden" component={Attendee.AttendanceContentSelectChouden} />
                <FuneralRoute exact path="/:funeralId/attendance/content/select/kumotsu" component={Attendee.AttendanceContentSelectKumotsu} />
                <FuneralRoute exact path="/:funeralId/attendance/content/select/sagawa" component={Attendee.AttendanceContentSelectSagawa} />
                <FuneralRoute exact path="/:funeralId/attendance/content/confirm" component={Attendee.AttendanceContentConfirm} />
                <FuneralRoute exact path="/:funeralId/attendance/content/input" component={Attendee.AttendanceContentInput} />
                <FuneralRoute exact path="/:funeralId/attendance/content/payment" component={Attendee.AttendanceContentPayment} />
                <FuneralRoute exact path="/:funeralId/attendance/content/payment/select" component={Attendee.AttendanceContentPaymentSelect} />
                <FuneralRoute exact path="/:funeralId/attendance/complete" component={Attendee.AttendanceComplete} />
                <FuneralRoute exact path="/:funeralId/attendance/content/payment/invoice" component={Attendee.AttendanceContentInvoiceAuthCode} />
                <FuneralRoute exact path="/:funeralId/attendance/invoice/complete" component={Attendee.AttendanceInvoiceComplete} />
                <FuneralRoute exact path="/:funeralId/attendanceterms" component={AttendanceTerms} />
                <FuneralRoute exact path="/:funeralId/attendance/receipt" component={Attendee.ReceiptDownload} />

                {/* ユーザ認証処理を実行し、認証したユーザのみに制限 */}
                <FuneralRoute exact forAttendee path="/:funeralId/users/funeral" component={Attendee.FuneralUserList} />
                <FuneralRoute exact forAttendee path="/:funeralId/users/attendee" component={Attendee.AttendeeUserList} />
                <FuneralRoute exact forAttendee path="/:funeralId/payment" component={PaymentTest} />
                <FuneralRoute exact forAttendee path="/:funeralId/album" component={Attendee.Album} />
                <FuneralRoute exact forAttendee path="/:funeralId/album/post" component={Attendee.PhotoPost} />
                <FuneralRoute exact forAttendee path="/:funeralId/album/select" component={Attendee.PhotoSelect} />
                <FuneralRoute exact forAttendee path="/:funeralId/funeral/message" component={Attendee.MournerMessage} />
                <FuneralRoute exact forAttendee path="/:funeralId/receipt" component={Attendee.Receipt} />
                <FuneralRoute exact forAttendee path="/:funeralId/funeral/connect" component={Attendee.FuneralStripeConnectEdit} />
                <FuneralRoute exact forAttendee path="/:funeralId/funeral/connect/detail" component={Attendee.FuneralStripeConnectDetail} />
                <FuneralRoute exact forAttendee path="/:funeralId/funeral/connect/payout" component={Attendee.FuneralPayout} />
                <FuneralRoute exact forAttendee path="/:funeralId/funeral/gift" component={Attendee.FuneralGiftList} />
                <FuneralRoute exact forAttendee path="/:funeralId/funeral/gift/select" component={Attendee.FuneralGiftSelect} />
                <FuneralRoute exact forAttendee path="/:funeralId/funeral/gift/select/:orderId" render={(props) => <Attendee.FuneralGiftSelect method="individual" {...props} />} />
                <FuneralRoute exact forAttendee path="/:funeralId/funeral/gift/return" component={Attendee.CondolenceGiftReturnList} />
                <FuneralRoute exact forAttendee path="/:funeralId/funeral/gift/confirm" component={Attendee.FuneralGiftConfirm} />
                <FuneralRoute exact forAttendee path="/:funeralId/funeral/gift/payment" component={Attendee.FuneralGiftPayment} />
                <FuneralRoute exact forAttendee path="/:funeralId/funeral/gift/payment/select" component={Attendee.FuneralGiftPaymentSelect} />
                <FuneralRoute exact forAttendee path="/:funeralId/funeral/gift/payment/invoice" component={Attendee.FuneralGiftInvoiceAuthCode} />
                <FuneralRoute exact forAttendee path="/:funeralId/funeral/gift/invoice/complete" component={Attendee.FuneralGiftInvoiceComplete} />
                <FuneralRoute exact forAttendee path="/:funeralId/funeral/gift/complete" component={Attendee.FuneralGiftComplete} />
              </Switch>
            </PrivateRoute>
          </Switch>
        </Router>
      )
    default:
      return (
        <div>該当するドメインはありません</div>
      )
  }
}

export default App;
