import React, { useEffect, useContext } from 'react';
import moment from 'moment';

import { Form } from 'react-bootstrap';

import ReadMore from './ReadMore';
import { AuthContext } from 'components/Contexts';
import { ORDER_STATUS } from '../constants';

export const AttendeeOrderList = ({ attendeeUserList, handleSelectFilter, currentFilter, listOrdersSelected, setListOrdersSelected }) => {

  const { userInfo } = useContext(AuthContext);

  const attendeeUserListWaitingConfirm = attendeeUserList?.filter((item) => item?.Status === 'waiting_confirm');

  useEffect(() => {
    if (currentFilter === 'money') {
      const allOrderIds = attendeeUserListWaitingConfirm?.map((item) => item?.OrderId);
      setListOrdersSelected(allOrderIds);
    }

     // eslint-disable-next-line
  }, [attendeeUserList, currentFilter]);

  const handleSelectedAll = (type) => {
    if (type) {
      const allOrderIds = attendeeUserListWaitingConfirm?.map((item) => item?.OrderId);
      setListOrdersSelected(allOrderIds);
    } else {
      setListOrdersSelected([]);
    }
  }

  const handleCheckItem = (e, orderId) => {
    if (e?.target?.checked) {
      setListOrdersSelected((prev) => {
        return [
          ...prev,
          orderId,
        ]
      });
    } else {
      setListOrdersSelected((prev) => {
        return prev?.filter(item => item !== orderId);
      });
    }
  }

  return (
    <>
      <div className='filter-group'>
        <div className='button-filter'>
          <button className={currentFilter === 'all' ? 'active' : ''} onClick={() => handleSelectFilter('all')}>全体</button>
          <button className={currentFilter === 'money' ? 'active' : ''} onClick={() => handleSelectFilter('money')}>香典</button>
          <button className={currentFilter === 'flower_thing' ? 'active' : ''} onClick={() => handleSelectFilter('flower_thing')}>供花・供物</button>
        </div>
        {userInfo?.isChiefMourner && currentFilter === 'money' && attendeeUserListWaitingConfirm?.length > 0 && (
          <div className='button-check-all-wrapper'>
            <button className={`btn-check-all ${attendeeUserListWaitingConfirm?.length > 0 && listOrdersSelected?.length === attendeeUserListWaitingConfirm?.length ? 'active' : ''}`} onClick={() => handleSelectedAll(true)}>全て受取</button>
            <button className={`btn-check-all ${(listOrdersSelected?.length > 0 && listOrdersSelected?.length < attendeeUserListWaitingConfirm?.length) ? 'active' : ''}`} onClick={() => handleSelectedAll(false)} disabled>個別に選択</button>
            <button className={`btn-check-all ${attendeeUserListWaitingConfirm?.length > 0 && listOrdersSelected?.length === 0 ? 'active' : ''}`} onClick={() => handleSelectedAll(false)}>全て辞退</button>
          </div>
        )}
      </div>
      <div className="card-body p-0" style={{ overflow: "scroll", height: "50vh" }}>
        <table className="table table-width">
          <thead>
            <tr>
            <th className={`th-12 freeze-column ${currentFilter !== "flower_thing" ? "text-center" : ""}`}>名札名
              {currentFilter !=="flower_thing" && (
              <p className="status">（香典ステータス）</p>
              )}
              </th>
              <th className="th-6">返礼品注文日</th>
              <th className="th-10">法人名</th>
              <th className="th-10">注文者名</th>
              <th className="th-12">住所</th>
              <th className="th-8">注文日時</th>
              <th className="th-12">注文内容</th>
              <th className="th-6">金額</th>
              <th className="th-7">アドレス</th>
              <th className="th-9">電話番号</th>
              <th className="th-12">香典メッセージ</th>
            </tr>
          </thead>
          <tbody>
            {
              attendeeUserList.map((row, index) => {
                return (
                  <tr key={index}>
                    
                    <td class="freeze-column">
                      <div className="user-name">
                        {
                          userInfo?.isChiefMourner &&
                          currentFilter === 'money' &&
                          row?.Status === 'waiting_confirm' &&
                          (
                            <Form.Check
                              name="attendee"
                              type='checkbox'
                              checked={listOrdersSelected?.includes(row?.OrderId)}
                              id={row?.UserName}
                              onChange={(e) => handleCheckItem(e, row?.OrderId)}
                            />
                          )
                          }
                          <div className={`order-info ${currentFilter} ${row?.Status}`}>
                            <div>{row?.OrderLabel || row?.UserName || ""}</div>
                            {currentFilter !== 'flower_thing' && row?.OrderItemType === '香典' && (
                              <div className={`status ${row.Status}`}>
                                {row?.Status && ORDER_STATUS?.[row.Status]}
                              </div>
                            )}
                          </div>
                      </div>
                    </td>
                    <td>{row?.OrderReturnGiftDate}</td>
                    <td>{row?.CompanyName || ""}</td>
                    <td>{row?.UserName || ""}</td>
                    <td>{row?.FormatAddress || ""}</td>
                    <td>{
                      row?.OrderDate ? moment(row?.OrderDate).format("YYYY/MM/DD HH:mm:ss") : ""  
                    }</td>
                    <td>{row?.OrderItemType || ""}</td>
                    <td>{row?.OrderMoney || ""}</td>
                    <td>{row?.Email || ""}</td>
                    <td>{row?.PhoneNo || ""}</td>
                    <td>{row?.OrderMessage ? <ReadMore>{row?.OrderMessage}</ReadMore> : ""}
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </>
  );
}

export default AttendeeOrderList;