import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import LPLayout from 'components/LPLayout';
import { Helmet } from 'react-helmet-async';
import CommonFooter from 'components/CommonFooter';
import { prefCodeToName } from 'components/CustomForms/Prefecture';

export const CompanyLawInfo = (props) => {
  const companyId = props.match.params.companyId;
  const [companyName, setCompanyName] = useState("");
  const [homePageLink, setHomePageLink] = useState("");
  const [personalInfoLink, setPersonalInfoLink] = useState("");
  const [representativeName, setRepresentativeName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  useEffect(() => {
    const fetch = async () => {
      const company = await getCompanyLawInfo(companyId);
      setCompanyName(company.companyName);
      setCompanyAddress(prefCodeToName(company.prefecture) + company.city + company.town + company.address1 + company.address2);
      setRepresentativeName(company.representativeName);
      setPhoneNo(company.phoneNo);
      setHomePageLink(company.homePageLink);
      setPersonalInfoLink(company.personalInfoLink);
    }
    fetch();
  }, [companyId]);

  return (
    <>
      {
        companyName &&
        <LPLayout name={companyName}>
          <Helmet>
            <title>{companyName} | itowa</title>
          </Helmet>
          <div id="company-law-info">
            <div className="title">特定商取引法</div>
            <div className="law-info-heading">葬儀会社</div>
            <div className="law-info-text">
              事業者名：{companyName}<br />
              責任者：{representativeName}<br />
              所在地：{companyAddress}<br />
              電話番号：{phoneNo}
            </div>
            <br />

            <div className="law-info-heading">サービス提供者</div>
            <div className="law-info-text">
              事業者名：株式会社itowa<br />
              責任者：坂元充<br />
              所在地：愛知県名古屋市中村区平池町4-60-12 グローバルゲート 11F<br />
              電話番号：050-8884-4987<br />
              ［メールアドレス：itowa@itowa-inc.jp］
            </div>
            <br />

            <div className="law-info-heading">システム利用料</div>
            <div className="law-info-text">
              ・香典の支払いに関して、下表の金額を参列者又は参列候補者からシステム利用料としていただきます。ただし、利用者との間で別途の合意がある場合、当該合意に基づく手数料をいただきます。<br />
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">香典の金額</th>
                    <th scope="col">システム利用料（税込み）</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>3,000円</td>
                    <td>330円</td>
                  </tr>
                  <tr>
                    <td>5,000円</td>
                    <td>495円</td>
                  </tr>
                  <tr>
                    <td>10,000円</td>
                    <td>891円</td>
                  </tr>
                  <tr>
                    <td>20,000円</td>
                    <td>1,650円</td>
                  </tr>
                  <tr>
                    <td>30,000円</td>
                    <td>2,200円</td>
                  </tr>
                  <tr>
                    <td>50,000円</td>
                    <td>3,300円</td>
                  </tr>
                  <tr>
                    <td>70,000円</td>
                    <td>4,400円</td>
                  </tr>
                  <tr>
                    <td>100,000円</td>
                    <td>5,500円</td>
                  </tr>
                </tbody>
              </table>
              ・供物、弔電及び返礼品の代金に関しては、参列者、参列候補者又は葬家からいただくシステム利用料はございません。<br />
              ・その他の商品・サービスについての手数料等については、当該商品・サービスのウェブページ上に掲載します。<br />
              システム利用料の支払時期及び方法：<br />
              ・支払時期：香典の支払いと併せてお支払いいただきます。<br />
              ・支払方法：香典の支払いと併せてクレジットカードでお支払いいただきます。<br />
              システム利用料以外に利用者が負担すべき金銭：<br />
              葬家は、香典の代理受領に関して以下の金額をお支払いいただく必要がございます。<br />
              ・決済代行業者であるストライプジャパン株式会社に対する決済手数料：香典の金額の3.85％に相当する金額<br />
              ・（当社が定める時期以外に香典の振込みをご依頼される場合）銀行が別途定める銀行振込手数料<br />
              サービスの提供時期：<br />
              当社が定める利用規約にご同意いただいた上で会員登録が完了した時点でサービスの利用が可能になります。<br />
              キャンセルに関する事項：<br />
              ・アカウントの削除その他当社の定める方法により、サービスの利用を終了することができます。<br />
              ・ただし、葬家は、当社が参列者又は参列候補者から香典の支払いを受け付けた日以降、サービスの利用を終了するまで、香典の代理受領サービスを利用することについての同意を撤回できないものとします。<br />
              サービスの不備・不具合等（契約内容不適合責任）に関する特約<br />
              サービスの不備・不具合等に関して利用者に生じた損害については、当社に故意又は過失がある場合を除き、返金には応じません。また、当社は、当社が提供するサービスについて、当社が定める利用規約に定めるほか、如何なる保証も行いません。<br />
            </div>
          </div >
          <div className="bottom-banner">
            <div className="homepage" onClick={() => window.open(homePageLink, 'homepage')}>{companyName}ホームページ</div>
          </div>
          <CommonFooter privacyPolicyUrl={personalInfoLink} companyId={companyId} />
        </LPLayout >
      }
    </>
  );
};

const getCompanyLawInfo = async (companyId) => {
  return await API.get('guest', `/companies/${companyId}/law/info`);
}

export default CompanyLawInfo;