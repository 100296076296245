import { useState, useEffect } from "react";
import styled from 'styled-components';
import { Button, Modal, Accordion } from 'react-bootstrap';
import Pagination from 'components/Pagination';
import ItemDetail from "components/ItemDetail";

const Title = styled.div`
  width: 20rem;
  display: inline-block;
`

const PER_PAGE = 10

const TimelineItem = ({ title, name, formik, useMoney, itemList }) => {
  const [rows, setRows] = useState(formik.values[name] || []);
  const [showModal, setShowModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [selectList, setSelectList] = useState([]);
  const [pagenatedList, setPagenatedList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    setRows(formik.values[name] || []);
  }, [formik, name, useMoney]);

  useEffect(() => {
    const rowIds = rows.map(r => r.itemId);
    const list = itemList.filter(item => !rowIds.includes(item.itemId));
    setSelectList(list);
    setPagenatedList(list.slice(0, PER_PAGE));
  }, [rows, itemList, setPagenatedList, setSelectList])

  const handleDelete = (itemId) => {
    const newRows = rows.filter((row) => row.itemId !== itemId);
    setRows(newRows);
    formik.setFieldValue(name, newRows);
  }

  const handleSelect = (item) => {
    const newRows = [...rows, item];
    setRows(newRows);
    formik.setFieldValue(name, newRows);
    setShowModal(false);
  }

  const handleShowDetail = (item) => {
    setSelectedItem(item);
    setShowDetail(true);
  }

  return (
    <div>
      <i className="fas fa-shopping-bag bg-black"></i>
      <div className="timeline-item">
        <div className="timeline-header bg-light" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Title className="timeline-title">{title}</Title>
          <div>
            <Button className="btn-sm" variant="primary" onClick={() => setShowModal(true)}>商品追加</Button>
          </div>
        </div>
        <div className="timeline-body">

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              商品一覧
            </Accordion.Header>
              <Accordion.Body>
              {
                rows.length > 0 ?
                  <table className="table table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "20%" }}>商品コード</th>
                        <th style={{ width: "45%" }}>商品名</th>
                        <th className="text-right">価格</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        rows.map((row, i) =>
                          <tr key={i}>
                            <td>{row.itemId}</td>
                            <td>{row.itemName}</td>
                            <td className="text-right">{row.price?.toLocaleString() + "円"}</td>
                            <td className="text-right">
                              <button type="button" className="btn btn-info btn-sm" onClick={() => handleShowDetail(row)}>詳細</button>
                              {' '}
                              <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDelete(row.itemId)}>削除</button>
                            </td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                  :
                  <div>商品が登録されていません</div>
                }
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>
            商品選択
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-hover table-sm">
            <thead className="thead-light">
              <tr>
                <th>商品コード</th>
                <th>商品名</th>
                <th className="text-right">価格</th>
                <th className="text-right"></th>
              </tr>
            </thead>
            <tbody>
              {
                pagenatedList.map((row, i) => 
                  <tr key={i}>
                    <td>{row.itemId}</td>
                    <td>{row.itemName}</td>
                    <td className="text-right">{row.price.toLocaleString()}</td>
                    <td className="text-right"><Button type="button" className="btn-sm" onClick={() => handleSelect(row)}>選択</Button></td>
                  </tr>
                )
              }
            </tbody>
            <tfoot>

            </tfoot>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Pagination per={PER_PAGE} list={selectList} onChange={(list) => setPagenatedList(list)} />
        </Modal.Footer>
      </Modal>
      <ItemDetail show={showDetail} onHide={() => setShowDetail(false)} item={selectedItem} />
    </div>
  )
}

export default TimelineItem;