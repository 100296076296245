import { useRouteMatch } from 'react-router-dom';
import '../css/Common.scss';
import base64url from 'base64url';
import sha256 from 'js-sha256';
import { FUNERALS_WEB_URL, COGNITO_DOMAIN } from '../constants';
import { awsConfig } from 'aws-config';
import LineLoginButton from 'components/LineLoginButton';

export const LogInLine = () => {
  const match = useRouteMatch("/:funeralId");

  const handleLineLogin = async () => {
    sessionStorage.setItem('funeralId', match.params.funeralId);

    const get_code_challenge = (str) => {
      const hash = sha256.arrayBuffer(str);
      return base64url(hash);
    }
    const get_code_verifier = () => {
      let buf = Buffer.alloc(128);
      for (let i = 0; i < buf.length; i++) {
        const random_num = Math.floor(Math.random() * 256);
        buf.writeUInt8(random_num, i);
      }
      return base64url(buf);
    }

    const codeVerifier = get_code_verifier();
    sessionStorage.setItem('code_verifier', codeVerifier);
    const codeChallenge = get_code_challenge(codeVerifier);
    window.location.href = `https://${COGNITO_DOMAIN}.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?identity_provider=LINE&response_type=code&client_id=${awsConfig.UserPoolClientId}&scope=openid%20email%profile&redirect_uri=${FUNERALS_WEB_URL}/login/redirect&code_challenge=${codeChallenge}&code_challenge_method=S256`;
  }

  return (
    <div id="auth-main">
      <div id="auth-common">
        <img className="logo" src="/images/itowa-logo-square.jpg" alt="logo"></img>
        <h1 className="">LINEログイン</h1>
        <div>
          <div className="buttons">
            <LineLoginButton　handleLineLogin={handleLineLogin}　/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogInLine;
