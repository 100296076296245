import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MenuItem from './MenuItem';
import { AuthContext } from '../Contexts';
import * as Constants from '../../constants';

export const SideMenuBar = ({menu, height}) => {
  const { userInfo } = useContext(AuthContext);

  const bannerLink = window.location.hostname === Constants.ADMIN_DOMAIN ? "/main" : `/${userInfo.undertaker.CompanyId}/main`;

  const [dataWidget, setDataWidget] = useState("");
  const widthBorder = 768
  const [width, setWidth] = useState(window.innerWidth)
  const updateWidth = (event) => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener(`resize`, updateWidth, {
      capture: false,
      passive: true,
    })
    return () => window.removeEventListener(`resize`, updateWidth)
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (width < widthBorder){
      return setDataWidget("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{ minHeight: height + 'px', display:"flex", flexDirection:"column" }}>
      <Link to={bannerLink} className="brand-link">
        <img
          src="/images/itowa_whitelogosub.png"
          alt="itowa Logo"
          className="brand-image" style={{margin: "1px auto"}}
        />
      </Link>
      <div className="sidebar">
        <nav className="mt-2" style={{ overflowY: 'hidden' }}>
          <ul
            data-widget={dataWidget}
            data-auto-collapse-size="768"
            className="nav nav-pills nav-sidebar flex-column"
            role="menu"
          >
            {menu.map((menuItem) => (
              <MenuItem key={menuItem.name} menuItem={menuItem} />
            ))}
          </ul>
        </nav>
      </div>
      
    </aside>
  )
}

export default SideMenuBar;