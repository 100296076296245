import React, { useRef } from 'react';
import { API } from 'aws-amplify'
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Pagination from 'components/Pagination';

export const SupplierList = (props) => {
  const PER_PAGE = 10;
  const match = useRouteMatch("/:companyId");
  const companyId = match.params.companyId;

  const [supplierList, setSupplierList] = React.useState([]);
  const [pagenatedList, setPagenatedList] = React.useState([]);
  const history = useHistory();
  const searchKey = useRef("");
  
  const search = async () => {
    const result = await getSupplierList(companyId, searchKey.current.value);
    const resultList = result.supplierList.sort((a, b) => a.Id < b.Id ? -1 : 1);
    setSupplierList(result.supplierList);
    setPagenatedList(resultList.slice(0, PER_PAGE));
  }
  
  const create = () => {
    history.push("suppliers/new");
  }
  
  return (
    <CommonLayout>
      <ContentHeader>
        業者一覧
      </ContentHeader>
      
      <div className="form-group">
        <Row>
          <Col>
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">検索条件</h3>
              </div>
              <div className="card-body">
                <label className="control-label">業者名</label>
                <input type="text" className="form-control" ref={searchKey} placeholder="業者名"/>
              </div>
              <div className="card-footer">
                <Button className="float-right" onClick={() => search()}>検索</Button>
                {' '}
                <Button variant="success" className="float-left" onClick={() => create()}>新規作成</Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
  
      <Row>
        <Col>
          <div className="card">
            <div className="card-body p-0">
              <table className="table">
                <thead>
                  <tr>
                    <th>業者名</th>
                    <th>担当者名</th>
                    <th>電話番号</th>
                    <th>メールアドレス</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    pagenatedList.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td><Link to={"suppliers/" + row.Id}>{row.CompanyName}</Link></td>
                          <td>{row.StaffName}</td>
                          <td>{row.PhoneNo}</td>
                          <td>{row.Email}</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <Pagination per={PER_PAGE} list={supplierList} onChange={(list) => setPagenatedList(list)} />
            </div>
          </div>
        </Col>
      </Row>
    </CommonLayout>
  );
};
  
const getSupplierList = async (companyId, keyword) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
      keyword: keyword
    }
  };

  return await API.get('product', '/suppliers', options);
}
  
export default SupplierList;