import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { API, Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { awsConfig } from '../../aws-config';
import { FUNERALS_WEB_URL, COGNITO_DOMAIN } from '../../constants';

import MounerLayout from 'components/MounerLayout';
import Input from 'components/Input';
import { useLoading } from 'components/Loading';
import useMessage from 'components/Message';

export const FuneralRegistrationSubmit = () => {
  const history = useHistory();
  const loading = useLoading();
  const message = useMessage();

  const [loadedData, setLoadedData] = useState(false);
  const [lineFuneralId, setLineFuneralId] =  useState(null);

  const formik = useFormik({
    initialValues: {
      phoneNo: "",
      userName: "",
    },
    validationSchema: Yup.object({
      phoneNo: Yup.string().required("電話番号は必須です"),
      userName: Yup.string().required("ユーザ名は必須です"),
    }),
    onSubmit: async (values) => {
      if (!formik.isValid) return;
      try {
        loading(true);
        await registrationFuneral(lineFuneralId, values);
        history.push('/registration/thanks');
      } catch (e) {
        if (e?.response?.status === 400) {
          message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
        } else {
          message.alert.danger("システムエラーが発生しました");
        }
      } finally {
        loading(false);
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });
  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    const getToken = async () => {
      const url = new URL(window.location.href);
      const code = url.searchParams.get('code');
      const stateLine = url.searchParams.get('state') || null;
      let stateFuneralId = localStorage.getItem("funeralId");
      if (stateLine) {
        stateFuneralId = stateLine?.split('---')?.[0] || '';
      }
      
      setLineFuneralId(stateFuneralId);
      if (!code) {
        history.push('/registration/error');
      } else {
        const params = new URLSearchParams();
        params.append('client_id', awsConfig.UserPoolClientId);
        params.append('grant_type', 'authorization_code');
        params.append('code', code);
        params.append('redirect_uri', `${FUNERALS_WEB_URL}/registration/submit`);

        const headers = new Headers({'Content-Type': 'application/x-www-form-urlencoded'});

        try {

          await fetch(`https://${COGNITO_DOMAIN}.auth.ap-northeast-1.amazoncognito.com/oauth2/token`, {
            body: params,
            method: 'POST',
            headers: headers
          })
            .then(response => response.json())
            .then(async (data) => {
              if (!data?.error) {
                let idToken = data?.id_token?.split('.') || [];
                idToken = idToken?.[1] ? JSON.parse(atob(idToken?.[1])) : '';

                // LINEユーザIDとメールアドレスを取得して設定
                setFieldValue("lineUserId", idToken?.["identities"]?.[0]?.["userId"] || "");

                // Amplify関連の設定
                const userName = idToken["cognito:username"];
                localStorage.setItem(`CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}.${userName}.idToken`, data.id_token);
                localStorage.setItem(`CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}.${userName}.accessToken`, data.access_token);
                localStorage.setItem(`CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}.${userName}.refreshToken`, data.refresh_token);
                localStorage.setItem(`CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}.LastAuthUser`, userName);
                Auth.currentSession().then(res => {
                  localStorage.setItem(`CognitoIdentityServiceProvider.${awsConfig.UserPoolClientId}.${userName}.clockDrift`, res.clockDrift);
                });
              } else {
                history.push('/registration/error');
              }
            });

            const checkAddedFriendResult = await checkAddedFriend();
            if (checkAddedFriendResult?.hasAddedFriend !== true) {
              history.push('/registration/error');
            }

            const funeralResult = await getFuneralInfo(stateFuneralId);
            if (!funeralResult.error) {
              setFieldValue("userName", funeralResult?.userName || '');
              setFieldValue("phoneNo", funeralResult?.phoneNo || '');
              loading(false);
            } else {
              message.alert.danger("システムエラーが発生しました");
            }
            loading(false);
        } catch (e) {
          if (e?.response?.status === 400) {
            message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
          } else {
            message.alert.danger("システムエラーが発生しました");
          }
        } finally {
          setLoadedData(true);
          loading(false);
        }
      }
    }

    loading(true);
    getToken();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MounerLayout>
      {loadedData && (
        <div id="auth-common">
          <img className="logo" src="/images/itowa-logo-square.jpg" alt="logo"></img>
          <div className="title">ログイン</div>
          <form onSubmit={formik.handleSubmit}>
            <div id="identity">
              <div className="form-group">
                <label className="control-label">氏名</label>
                <div className="input-group">
                  <Input type="text" name="userName" className="form-control" formik={formik} />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label">電話番号</label>
                <div className="input-group">
                  <Input type="text" name="phoneNo" className="form-control" formik={formik} />
                </div>
              </div>
            </div>
            <div className="buttons">
              <Button type="submit" variant="success">申請</Button>
            </div>
          </form>
        </div>
      )}
    </MounerLayout>
  )
}

const registrationFuneral = async (funeralId, forms) => {
  forms.funeralId = funeralId;
  const options = {
    body: forms
  }
  return await API.put('product', '/funerals/' + funeralId + '/registration', options);
}

const getFuneralInfo = async (funeralId) => {
  return await API.get('guest', '/funerals/' + funeralId + '/info-registration');
}

const checkAddedFriend = async () => {
  return await API.get('product', '/line/added-friend-status');
}

export default FuneralRegistrationSubmit;
