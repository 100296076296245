import React, { useState } from "react";
import { HelmetProvider } from 'react-helmet-async';

export const AuthContext = React.createContext({});
export const AuthProvider = AuthContext.Provider;
export const MessageContext = React.createContext({});
export const MessageProvider = MessageContext.Provider;
export const LoadingContext = React.createContext({});
export const LoadingProvider = LoadingContext.Provider;
export const AttendanceContext = React.createContext({});
export const AttendanceProvider = AttendanceContext.Provider;

export const Provider = ({ children }) => {
  const [userInfo, setUserInfo] = useState();
  const [modal, setModal] = useState({});
  const [modalConfirm, setModalConfirm] = useState({});
  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);
  const [attendance, setAttendance] = useState(false);
  const [funeralInfo, setFuneralInfo] = useState(false);
  const [showGiftSetting, setShowGiftSetting] = useState(false);
  const [showFuneralMoney, setShowFuneralMoney] = useState(false);
  const [showConfirmFuneralMoney, setShowConfirmFuneralMoney] = useState(false);
  const [showPortalFuneralSetting, setShowPortalFuneralSetting] = useState(false);

  return (
    <AuthProvider value={{userInfo, setUserInfo}}>
      <MessageProvider value={{ alert, setAlert, modal, setModal, modalConfirm, setModalConfirm }}>
        <LoadingProvider value={{loading, setLoading}}>
          <HelmetProvider>
            <AttendanceProvider value={{attendance, setAttendance, funeralInfo, setFuneralInfo, showGiftSetting, setShowGiftSetting, showPortalFuneralSetting, setShowPortalFuneralSetting, showFuneralMoney, setShowFuneralMoney, showConfirmFuneralMoney, setShowConfirmFuneralMoney}}>
              {children}
            </AttendanceProvider>
          </HelmetProvider>
        </LoadingProvider>
      </MessageProvider>
    </AuthProvider>
  )
}

export default Provider;