import { useState, useEffect, useRef } from 'react';
import QRCode from 'qrcode.react';
import { Row, Col, Button } from 'react-bootstrap';
import { Auth } from "aws-amplify"
import styled from 'styled-components';

import CommonLayout from "components/CommonLayout";
import ContentHeader from 'components/ContentHeader';
import { useMessage } from 'components/Message';

const QRCodeArea = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

export const OTP = () => {
  const [code, setCode] = useState(null);
  const [release, setRelease] = useState(false);
  const challengeAnswer = useRef("");
  const message = useMessage();

  useEffect(() => {
    async function load() {
      const user = await Auth.currentAuthenticatedUser();

      const state = await Auth.getPreferredMFA(user, { bypassCache: false });

      if(state === "NOMFA") {
        Auth.setupTOTP(user).then((code) => {
          setCode("otpauth://totp/bloom-post:" + user.username + "?secret=" + code + "&issuer=" + user.issuer);
        })
      } else if(state === "SOFTWARE_TOKEN_MFA") {
        setRelease(true);
      }
    }
    load();
  }, []);

  const enableTOTP = async () => {
    const user = await Auth.currentAuthenticatedUser();

    try {
      await Auth.verifyTotpToken(user, challengeAnswer.current.value);
      await Auth.setPreferredMFA(user, 'TOTP');
      message.alert.success("ワンタイムパスワードが有効になりました");
      setCode(null);
      setRelease(true);
    } catch (e) {
      message.alert.danger("ワンタイムパスワードを有効にできませんでした");
    }
  }

  const disableTOTP = async () => {
    const user = await Auth.currentAuthenticatedUser();

    try {
      await Auth.setPreferredMFA(user, 'NOMFA');
      message.alert.success("ワンタイムパスワードが無効になりました");
      Auth.setupTOTP(user).then((code) => {
        setCode("otpauth://totp/bloom-post:" + user.username + "?secret=" + code + "&issuer=" + user.issuer);
      })
      setRelease(false);
    } catch (e) {
      message.alert.danger("ワンタイムパスワードを無効にできませんでした");
    }
  }

  return (
    <CommonLayout>
      <ContentHeader>
        多要素認証設定
      </ContentHeader>
      <Row>
        <Col md="6">
          { 
            code && 
            <div className="card">
              <div className="card-header">
                ワンタイムパスワードの登録
              </div>
              <div className="card-body">
                <ol>
                  <li>スマートフォンの認証アプリから、下のQRコードを読み取ってください</li>
                  <QRCodeArea>
                    {
                      code ? <QRCode value={code} /> : null
                    }
                  </QRCodeArea>
                  <li>スマートフォンに表示されているコードを入力してください</li>
                  <QRCodeArea>
                    <Row>
                      <Col md="6">
                        <input type="text" className="form-control" ref={challengeAnswer}></input>
                      </Col>
                      <Col md="3">                    
                        <button type="button" className="btn btn-primary" onClick={() => enableTOTP()}>送信</button>
                      </Col>
                    </Row>
                  </QRCodeArea>
                </ol>
              </div>
            </div>
          }
          {
            release &&
            <div className="card">
              <div className="card-header">
                ワンタイムパスワードの解除
              </div>
              <div className="card-body">
                現在、多要素認証（ワンタイムパスワード）は有効です。無効にしますか？
                <Button className="float-right btn btn-primary" onClick={() => disableTOTP()}>無効にする</Button>
              </div>
            </div>
          }
        </Col>
      </Row>
      <div id="otp">

      </div>
    </CommonLayout>
  );
}

export default OTP;