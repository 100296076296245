export const City = ({name, className, formik, listCity, onChange, ...props }) => {

  return (
    <>
      <select name={name} className={"custom-select" + (formik.errors[name] ? " is-invalid" : "")} onChange={(e) => onChange({ city: e.target.value })} value={formik.values[name]} {...props}>
        <option value=""></option>
        {
          listCity.map((p, i) => {
            return (
              <option key={i} value={p.value}>{p.value}</option>
            );
          })
        }
      </select>
      <div className="invalid-feedback">{formik.errors[name]}</div>
    </>
  )
}

export default City;