import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Auth } from 'aws-amplify';
import { Modal } from 'react-bootstrap';

import 'css/Common.scss';

export const LogIn = (props) => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [code, setCode] = useState("");
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [tooglePasswordType, setTooglePasswordType] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [confirmed, setConfirmed] = useState(false); 

  let header = confirmed === false ? "ログイン画面" : "パスワード変更画面";
  if (props.type === "admin") {
    header += " (管理者用)"
  } else if (props.type === "undertaker") {
    header += " (葬儀社用)"
  }

  const handleResendVerificationEmail = async () => {
    try {
      setLoading(true)
      await Auth.resendSignUp(email)
      setLoading(false)
    } catch (e) {
      setMessage('メールの送信に失敗しました。')
      setLoading(false)
    }
  }

  const handleConfirm = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      await Auth.confirmSignUp(email, code);
      setLoading(false)
      setShowVerificationModal(false)
      setMessage('')
    } catch (e) {
      setMessage("コードが正しくありません");
      setLoading(false)
    }
  }

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
      const user = await Auth.signIn(email, password);
      if (user) {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setMessage('');
          setConfirmed(true);
          setUserInfo(user);
        } else {
          history.push('main');
        }
      }
      setLoading(false)

    } catch (e) {
      setLoading(false)
      if (e.name === "UserNotConfirmedException") {
        setMessage('')
        setShowVerificationModal(true)
      } else {
        setMessage('認証できませんでした');
      }
    }
    return false;
  }

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      await Auth.completeNewPassword(userInfo, newPassword, userInfo.userAttributes);
      history.push('main');

      setLoading(false)

    } catch (e) {
      setLoading(false);
      if (e.name === "UserNotConfirmedException") {
        setMessage('')
        setShowVerificationModal(true)
      } else {
        setMessage('パスワードのリセットに失敗しました');
      }
    }
    return false;
  }

  return (
    <div id="auth-main">
      <div id="auth-common">
        <img className="logo" src="/images/itowa-logo-square.jpg" alt="logo"></img>
        <div className="title">{header}</div>
        {confirmed === false ? (
            <form onSubmit={handleLogin}>
              <div id="identity">
                <div className="form-group">
                  <label className="control-label">Eメール</label>
                  <div className="input-group">
                    <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}></input>

                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label">パスワード</label>
                  <div className="input-wrapper">
                    <input type={tooglePasswordType ? 'password' : 'text'} className="form-control" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                    <button type="button" onClick={() => setTooglePasswordType((prevent) => !prevent )}>
                        {tooglePasswordType ? (
                          <img className="logo" src="/images/hide.png" alt="password" />
                        ) : (
                          <img className="logo" src="/images/view.png" alt="password" />
                        )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="alert">
                {!showVerificationModal && <div className="msg">{message}</div>}
              </div>
              <div className="forget">
                <Link to="password-reset">パスワードを忘れた方</Link>
              </div>
              <div className="buttons">
                <button type="submit" className="btn btn-primary login-button" disabled={loading}>ログイン</button>
              </div>
            </form>
        ) : (
          <form onSubmit={handleUpdatePassword}>
            <div id="identity">
              <div className="form-group">
                <label className="control-label">新しいパスワード</label>
                <div className="input-wrapper">
                  <input type={tooglePasswordType ? 'password' : 'text'} className="form-control" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}></input>
                  <button type="button" onClick={() => setTooglePasswordType((prevent) => !prevent )}>
                      {tooglePasswordType ? (
                        <img className="logo" src="/images/hide.png" alt="password" />
                      ) : (
                        <img className="logo" src="/images/view.png" alt="password" />
                      )}
                  </button>
                </div>
              </div>
            </div>
            <div className="alert">
                {!showVerificationModal && <div className="msg">{message}</div>}
            </div>
            <div className="buttons">
              <button type="submit" className="btn btn-primary login-button" disabled={loading}>変更</button>
            </div>
          </form>
        )}
      </div>
      <Modal show={showVerificationModal} centered={true}>
        <Modal.Body style={{ textAlign: "center" }}>
          <h2 style={{ fontSize: "18px", textAlign: "center", fontWeight: "bold", margin: "20px" }} className="gothic">認証コードを入力してください。</h2>
          <label className="control-label gothic">メールに記載された6桁のコードを入力してください。</label>
          <input type="text" className="form-control" value={code} onChange={(e) => setCode(e.target.value)} maxLength={6} />
          {message && <div className="msg">{message}</div>}
          <div className="buttons">
            <button type="button" className="btn btn-primary black-button" onClick={handleConfirm} disabled={loading}>確認</button>
            <button type="button" className="btn btn-primary white-button" onClick={handleResendVerificationEmail} disabled={loading}>メールの再送</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default LogIn;
