export const GenderTypeToName = (type) => {
  switch (type) {
    case "M":
      return "男性";
    case "W":
      return "女性";
    case "F":
      return "女性";
    case "O":
      return "その他"
    default:
      return "undefined"
  }
}

export default GenderTypeToName;