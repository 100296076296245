import React, { useContext } from 'react';
import { useLoading } from 'components/Loading';
import { Helmet } from 'react-helmet-async';

import AttendeeLayout from 'components/AttendeeLayout';
import {AttendanceContext} from 'components/Contexts';
import {getBlob} from "../../utils/request";
import * as Constants from "../../constants";
import { saveAs } from 'file-saver';

export const FuneralGiftInvoiceComplete = (props) => {
    const { location } = props;
    const queryString = new URLSearchParams(location.search);
    const { funeralInfo } = useContext(AttendanceContext);

    const orderId = queryString.get("orderId");
    const loading = useLoading();

    // 請求書のダウンロード
    const handleDownload = async (orderId) => {
        loading(true);
        const filename = `${funeralInfo.companyId}/invoice/${orderId}.pdf`;
        const pdfData = await getBlob(Constants.IMAGE_BUCKET_URL + filename, { method: 'GET' });
        saveAs(pdfData, '請求書.pdf');
        loading(false)
    }

    return (
        <AttendeeLayout>
            <Helmet>
                <title>ご注文完了 | itowa</title>
            </Helmet>
            <div id="complete">
                <h2 className="item-title-horizontal">ご注文完了</h2>
                 <p className="thanks-message">
                     ご注文いただきありがとうございます。 <br/>
                     返礼品を選択・注文できました。<br/>
                     葬儀社にご連絡が伝わっておりますので<br/>
                     返礼品の郵送は葬儀社にお任せください。
                </p>
                <button className="btn btn-primary set-password-button" onClick={() => handleDownload(orderId)}>請求書ダウンロード</button>
            </div>
        </AttendeeLayout>
    );
};

export default FuneralGiftInvoiceComplete;