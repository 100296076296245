import React, {useState, useEffect, useContext} from 'react';
import { API } from 'aws-amplify';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

import AttendeeLayout from 'components/AttendeeLayout';
import { setCartItem, removeCartItem, getCartContents, checkLimit } from '../../components/Cart';
import { Img } from 'components/Img';
import { shuffle } from 'utils/shuffle';
import ItemCell from 'components/CustomForms/ItemCell';
import {AttendanceContext, AuthContext} from "../../components/Contexts";

export const AttendanceContentSelectKumotsu = (props) => {
  const [koudenList, setKoudenList] = useState([]);
  const [choudenList, setChoudenList] = useState([]);
  const [kumotsuList, setKumotsuList] = useState([]);
  const [kyoukaList, setKyoukaList] = useState([]);
  const { funeralInfo } = useContext(AttendanceContext);
  const { userInfo } = useContext(AuthContext);

  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;
  const history = useHistory();

  const { location } = props;
  const queryString = new URLSearchParams(location.search);
  const isOrderOnly = queryString.get("isOrderOnly") === "true";

  useEffect(() => {
    const fetch = async () => {
      const result = await getDisplayItem(funeralId);
      await checkLimit(funeralId, result.itemLimit, result.koudenLimit);
      let cart = await getCartContents(funeralId);
      cart.kumotsu = cart.filter((row) => row.itemType === "things");
      cart.kyouka = cart.filter((row) => row.itemType === "flower");

      let kumotsuList = result.kumotsuList;
      for (let i = 0; i < kumotsuList.length; i++) {
        if (cart.kumotsu.length > 0) {
          kumotsuList[i].quantity = cart.kumotsu.filter((row) => row.itemId === kumotsuList[i].itemId).length || 1;
          kumotsuList[i].flag = cart.kumotsu.filter((row) => row.itemId === kumotsuList[i].itemId).length > 0 ? true : false;
        } else {
          kumotsuList[i].quantity = 1;
          kumotsuList[i].flag = false;
        }
      }

      let kyoukaList = result.kyoukaList;
      for (let i = 0; i < kyoukaList.length; i++) {
        if (cart.kyouka.length > 0) {
          kyoukaList[i].quantity = cart.kyouka.filter((row) => row.itemId === kyoukaList[i].itemId).length || 1;
          kyoukaList[i].flag = cart.kyouka.filter((row) => row.itemId === kyoukaList[i].itemId).length > 0 ? true : false;
        } else {
          kyoukaList[i].quantity = 1;
          kyoukaList[i].flag = false;
        }
      }

      setKumotsuList(kumotsuList);
      setKyoukaList(kyoukaList);
      setChoudenList(result.choudenList);
      setKoudenList(result.koudenList);
    }
    fetch();
  }, [props, funeralId]);

  return (
    <AttendeeLayout>
      <Helmet>
        <title>参列選択（お供物・お供花） | itowa</title>
      </Helmet>
      <form className="kumotsu-form">
        {
          isOrderOnly ?
            <>
              <h1>{funeralInfo.familyName}家</h1>
              <h2>下記より商品を選択してください</h2>
            </> : <></>
        }
      <h2 className="item-title font-weight-bold">お供花</h2>
        {
          kyoukaList.length > 0 ?
            <>
              <div className="btn-to-confirm-wrapper mb-4">
                <Button className="btn btn-primary btn-to-confirm" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/confirm?isOrderOnly=" + isOrderOnly)}>お支払いに進む</Button>
                <span className="chevron-right"></span>
              </div>
              <Row className="item-grids">
                {
                  kyoukaList.map((row, index) => {
                    return (
                      <Col key={index} xl={3} lg={6} md={6} sm={6} xs={6}>
                        <ItemCell key={index} item={row} countable={true} flag={row.flag} value={row.quantity} onChange={(flag, quantity) => {
                          if (flag) {
                            setCartItem(funeralId, row, quantity);
                          } else {
                            removeCartItem(funeralId, row);
                          }
                        }} />
                      </Col>
                    )
                  })
                }
              </Row>
              <div className="btn-to-confirm-wrapper">
                <Button className="btn btn-primary btn-to-confirm" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/confirm?isOrderOnly=" + isOrderOnly)}>お支払いに進む</Button>
                <span className="chevron-right"></span>
              </div>
            </>
            :
            <>ご選択項目は現在注文可能な商品がありません</>
        }
        <h2 className="item-title font-weight-bold">お供物</h2>
        {
          kumotsuList.length > 0 ?
            <>
              <Row className="item-grids">
                {
                  kumotsuList.map((row, index) => {
                    return (
                      <Col key={index} xl={3} lg={6} md={6} sm={6} xs={6}>
                        <ItemCell key={index} item={row} countable={true} flag={row.flag} value={row.quantity} onChange={(flag, quantity) => {
                          if (flag) {
                            setCartItem(funeralId, row, quantity);
                          } else {
                            removeCartItem(funeralId, row);
                          }
                        }} />
                      </Col>
                    )
                  })
                }
              </Row>
              <div className="btn-to-confirm-wrapper">
                <Button className="btn btn-primary btn-to-confirm" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/confirm?isOrderOnly=" + isOrderOnly)}>お支払いに進む</Button>
                <span className="chevron-right"></span>
              </div>
            </>
            :
            <>ご選択項目は現在注文可能な商品がありません</>
        }

        {
          (choudenList.length > 0 && (kumotsuList.length > 0 || kyoukaList.length > 0)) &&
          <>
            <div className="item-sample">
              {
                koudenList.length > 0 &&
                funeralInfo?.enableMoneyFlow && 
                !userInfo?.isChiefMourner && 
                !userInfo?.attendees?.Relatives &&
                (
                  <>
                    <div className="item-side-scroll">
                      {
                        shuffle(koudenList).map((item, index) =>
                          <div key={index} className="item-photos">
                            <Img src={item.ImagePath} />
                          </div>
                        )
                      }
                    </div>
                    <div className="btn-to-flower-and-things-wrapper">
                      <Button className="btn-to-flower-and-things" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/select/kouden?isOrderOnly=" + isOrderOnly)}>香典を送る</Button>
                      <span className="chevron-right-thin"></span>
                    </div>
                  </>
                )
              }

              <div className="item-side-scroll">
                  {
                    shuffle(choudenList).map((item, index) =>
                      <div key={index} className="item-photos">
                        <Img src={item.imagePath} />
                      </div>
                    )
                  }
              </div>
              <div className="btn-to-flower-and-things-wrapper">
                <Button className="btn-to-flower-and-things" variant="success" onClick={() => history.push("/" + funeralId + "/attendance/content/select/chouden?isOrderOnly=" + isOrderOnly)}>弔文を贈る</Button>
                <span className="chevron-right-thin"></span>
              </div>
            </div>
          </>
        }
      </form>
    </AttendeeLayout>
  );
};

const getDisplayItem = async (funeralId) => {
  const options = {
    queryStringParameters: { funeralId: funeralId }
  }

  return await API.get('guest', '/funerals/item/template', options);
}

export default AttendanceContentSelectKumotsu;