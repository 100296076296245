import React from 'react'
import '../css/Common.scss';
import TermsContents from 'components/TermsContents';

const Terms = (props) => {
    
  return (
    <div>
      <TermsContents />
    </div>
  )
}

export default Terms