import React from 'react';
import ReactDOM from 'react-dom';
import Amplify, * as AmplifyModules from 'aws-amplify';
import { Translations } from '@aws-amplify/ui-components'
import { awsConfig } from './aws-config';

import './index.css';
import App from './App';
import Provider from './components/Contexts.js'; 

require('admin-lte/dist/js/adminlte.min')

const dictionary = {
	[Translations.CREATE_ACCOUNT_TEXT]: 'アカウントの作成',
	[Translations.USERNAME_LABEL]: 'メールアドレス *',
	[Translations.USERNAME_PLACEHOLDER]: ' ',
	[Translations.FORGOT_PASSWORD_TEXT]: 'パスワードをお忘れの場合',
	[Translations.PASSWORD_LABEL]: 'パスワード *',
	[Translations.PASSWORD_PLACEHOLDER]: ' ',
	[Translations.RESET_PASSWORD_TEXT]: 'パスワードを変更する',
	[Translations.SIGN_IN_ACTION]: 'ログイン',
	[Translations.SIGN_IN_HEADER_TEXT]: 'ログイン',
	[Translations.CHANGE_PASSWORD]: 'パスワードを設定してください',
	[Translations.NEW_PASSWORD_LABEL]: '新しいパスワード',
	[Translations.NEW_PASSWORD_PLACEHOLDER]: ' ',
	[Translations.CHANGE_PASSWORD_ACTION]: '設定',
	[Translations.BACK_TO_SIGN_IN]: 'ログインに戻る',
	[Translations.CONFIRM_TOTP_CODE]: 'ワンタイムパスワードを入力してください',
	[Translations.CODE_LABEL]: ' ',
	[Translations.CODE_PLACEHOLDER]: ' ',
	[Translations.VERIFY_CONTACT_HEADER_TEXT]: "メールアドレスの検証をしてください。",
	[Translations.VERIFY_CONTACT_VERIFY_LABEL]: "検証する",
	[Translations.SKIP]: "後で行う",
	[Translations.RESET_YOUR_PASSWORD ]: "パスワードをリセットします",
	[Translations.SEND_CODE]: "送信"
};
AmplifyModules.I18n.putVocabulariesForLanguage('ja', dictionary)

Amplify.configure({
	Auth: {
    region: 'ap-northeast-1',
		userPoolId: awsConfig.UserPoolId,
		userPoolWebClientId: awsConfig.UserPoolClientId
  },
	API: {
		endpoints: [
			{
				name: "product",
				endpoint: awsConfig.ServiceEndpoint,
				region: "ap-northeast-1",
				custom_header: async () => {
					return {
						Authorization: (await AmplifyModules.Auth.currentSession()).idToken.jwtToken,
						'Content-Type': 'application/json'
					}
				}
			},
			{
				name: "guest",
				endpoint: awsConfig.ServiceEndpoint,
				region: "ap-northeast-1",
				custom_header: async () => {
					return {
						'Content-Type': 'application/json'
					}
				}
			}
		]
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
