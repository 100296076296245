import { useRouteMatch } from 'react-router-dom';
import { FUNERALS_WEB_URL, COGNITO_DOMAIN } from '../constants';
import { awsConfig } from 'aws-config';
import LineFamilySignInButton from "components/LineFamilySignInButton";


export const LineFamilySignIn = () => {
  const match = useRouteMatch("/:funeralId");

  const handleLineLogin = () => {
    localStorage.setItem('funeralId', match.params.funeralId);
    window.location.href = `https://${COGNITO_DOMAIN}.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?identity_provider=LINE&response_type=code&client_id=${awsConfig.UserPoolClientId}&scope=openid%20email%profile&redirect_uri=${FUNERALS_WEB_URL}/signin/line/submit`;
  }

  return (
    <div id="auth-main">
      <div id="auth-common">
        <img className="logo" src="/images/itowa-logo-square.jpg" alt="logo"></img>
        <h1 className="">家族登録</h1>
        <div>
          <div className="buttons">
            <LineFamilySignInButton handleLineLogin={handleLineLogin}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LineFamilySignIn;
