import { useState, useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Modal, Row, Col, Button } from 'react-bootstrap';

import { AuthContext, AttendanceContext } from 'components/Contexts';
import { copyToClipboard } from 'utils/copyToClipboard';
import { CHIEF_MOUENER_MONEY_STATUS } from '../../constants';
import { getCartContents } from 'components/Cart';

import 'moment/locale/ja';
import moment from 'moment';

import ModalRegistrationAttendance from 'components/ModalRegistrationAttendance';

export const Footer = ({haveKoudenList}) => {
  const match = useRouteMatch("/:funeralId");
  const { userInfo } = useContext(AuthContext);
  const { funeralInfo, showFuneralMoney, setShowFuneralMoney } = useContext(AttendanceContext);
  const [copied, setCopied] = useState(false);
  const [showFuho, setShowFuho] = useState(false);
  const [showAttendance, setShowAttendance] = useState(false);
  const [showBannerFuneralMoney, setShowBannerFuneralMoney] = useState(false);
  const [isEndedFuneral, setIsEndedFuneral] = useState(false);

    const funeralId = match.params.funeralId;

  const handleFuhoClick = () => {
    setCopied(true);
    copyToClipboard(funeralId, funeralInfo, userInfo)
    setTimeout(() => setCopied(false), 2000);
  }

  useEffect(() => {
   let haveItemMoney;
   const fetch = async () => {
     const cart = await getCartContents(funeralId);
     haveItemMoney = cart?.find((item) => item?.itemType === 'money');
   }
   fetch();

   // 本日の日付が葬儀日より後なら葬儀済みとする (日付が切り替わった瞬間に変わるようになっている)
   moment.locale('ja');
   setIsEndedFuneral(moment() > moment(funeralInfo.funeralDateTime).endOf('day'));

   const showPopupMoney = localStorage.getItem(`${funeralId}_showPopupMoney`);
    if (
       !userInfo?.isChiefMourner &&
       !userInfo?.attendees?.Relatives &&
       haveKoudenList &&
       !haveItemMoney &&
       funeralInfo?.chiefMournerMoneyStatus !== CHIEF_MOUENER_MONEY_STATUS.DENIED &&
       funeralInfo?.enableMoneyFlow &&
       showPopupMoney === '1'
     ) {
      setShowBannerFuneralMoney(true);
     }
    // eslint-disable-next-line
  }, [haveKoudenList,funeralInfo, userInfo, showFuneralMoney]);

  return (
    <>
      <footer id="footer">
        <div className="desktop">
          <div className="show-attendance">
            {showBannerFuneralMoney && (
              <button className="btn-oragen" onClick={() => setShowFuneralMoney(true)}>オンライン香典申込</button>
            )}
            {!isEndedFuneral && (
              <button onClick={() => setShowFuho(true)}>訃報を伝達する</button>
            )}
            {funeralInfo?.hasIpadId && (
              <button className="btn-blue" onClick={() => setShowAttendance(true)}>出席返答をする</button>
            )}
          </div>
        </div>

        <div className="mobile">
          {showBannerFuneralMoney && (
            <div className="show-funeral-money">
              <button className="btn-oragen" onClick={() => setShowFuneralMoney(true)}>
                <span>香典<br />申込</span>
              </button>
            </div>
          )}
          <div className="show-attendance">
            {!isEndedFuneral && (
              <button onClick={() => setShowFuho(true)}>訃報を伝達する</button>
            )}
            {funeralInfo?.hasIpadId && (
              <button className="btn-blue" onClick={() => setShowAttendance(true)}>出席返答</button>
            )}
          </div>
        </div>

      </footer>
      {
        copied &&
        <div className="msg">
          訃報をクリップボードにコピーしました
        </div>
      }
      <Modal show={showFuho} onHide={() => setShowFuho(false)} centered={true} dialogClassName="fuho-modal-size" size="lg">
        <Modal.Header closeButton={true}><Modal.Title className="ms-auto">＜訃報を知り合いに伝える＞</Modal.Title></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6} sm={5} xs={12}>
              <div className="fuho-banner pt-1 pb-1 rounded-pill">STEP1</div>
              <br />
              <div className="ml-2">「訃報情報をコピーする」ボタンを押す</div>
              <br />
              <div className="fuho-banner pt-1 pb-1 rounded-pill">STEP2</div>
              <br />
              <div className="ml-2">メールやLINE等で貼り付け(ペースト)して送信する</div>
              <br />
            </Col>
            <Col md={6} sm={7} xs={12}><img src="/images/fuhou.png" alt="" width={300} /></Col>
          </Row>
          <div className="text-center mt-5"><Button variant="secondary" onClick={handleFuhoClick}>{copied ? <span className="copied">訃報をクリップボードにコピーしました</span> : "訃報情報をコピーする"}</Button></div>
        </Modal.Body>
      </Modal>

      {showAttendance && (
        <ModalRegistrationAttendance showAttendance={showAttendance} onClose={() => setShowAttendance(false)} />
      )}
    </>
  )
}

export default Footer;