import { useEffect, useState } from 'react';
import { API } from 'aws-amplify'
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup'; 
import moment from 'moment';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Pagination from 'components/Pagination';
import Input from 'components/Input';
import { useLoading } from 'components/Loading';
import FuneralQRCode from 'components/FuneralQRCode';
import useMessage from 'components/Message';
import { copyToClipboardLineMessage } from 'utils/copyToClipboard';
import * as Constants from '../../constants';

export const FuneralList = (props) => {
  const PER_PAGE = 10;
  const message = useMessage();
  const match = useRouteMatch("/:companyId");
  const companyId = match.params.companyId;

  const [funeralList, setFuneralList] = useState([]);
  const [funeralNewList, setFuneralNewList] = useState([]);
  const [showQr, setShowQr] = useState(false);
  const [currentFuneral, setCurrentFuneral] = useState([]);
  const [preRegistrationList, setPreRegistrationList] = useState([]);
  const [pagenatedList, setPagenatedList] = useState([]);
  const [funeralNewPagenatedList, setFuneralNewPagenatedList] = useState([]);
  const [preRegistrationPagenatedList, setPreRegistrationPagenatedList] = useState([]);
  const [halls, setHalls] = useState([]);
  const history = useHistory();
  const loading = useLoading(true);
  const [showToast, setShowToast] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [messageCopy, setMessageCopy] = useState("");

  const formik = useFormik({
    initialValues: {
      dateFrom: moment().format("YYYY-MM-DD"),
      dateTo: "",
      hallId: ""
    },
    validationSchema: Yup.object({
      dateFrom: Yup.string().required("日付指定は必須です"),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        loading(true);
        try {
          const [getFuneralListResult, getPreFuneralListResult] = await Promise.all([
            getFuneralList(companyId, values),
            getFuneralListMissingInformation(companyId),
          ]);

          setFuneralNewList(getPreFuneralListResult?.funeralList || []);

          setFuneralList(getFuneralListResult?.funeralList || []);
          setPagenatedList(getFuneralListResult?.funeralList?.slice(0, PER_PAGE) || []);
        } catch (e) {
          if (e?.response?.status === 400) {
            message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
          } else {
            message.alert.danger("システムエラーが発生しました");
          }
        } finally {
          loading(false);
        }
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  const handleReload = () => {
    window.location.reload();
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        const [getHallListResult, getPreRegistrationListResult, getListNewFunerals, getFuneralListResult] = await Promise.all([
          getHallList(companyId),
          getFuneralListForPreRegistration(companyId),
          getFuneralListMissingInformation(companyId),
          getFuneralList(companyId, formik?.values),
        ]);

        setHalls(getHallListResult?.hallList || []);
        setPreRegistrationList(getPreRegistrationListResult?.funeralList || []);
        setPreRegistrationPagenatedList(getPreRegistrationListResult?.funeralList?.slice(0, PER_PAGE) || []);
        setFuneralNewList(getListNewFunerals?.funeralList || []);
        setFuneralNewPagenatedList(getListNewFunerals?.funeralList?.slice(0, PER_PAGE) || []);

        setFuneralList(getFuneralListResult?.funeralList || []);
        setPagenatedList(getFuneralListResult?.funeralList?.slice(0, PER_PAGE) || []);
      } catch (e) {
        if (e?.response?.status === 400) {
          message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
        } else {
          message.alert.danger("システムエラーが発生しました");
        }
      } finally {
        loading(false);
      }
    }
    loading(true);
    fetch();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, companyId])

  const create = () => {
    history.push("funerals/new");
  }

  const handleShowFuneralQr = (item) => {
    setCurrentFuneral(item);
    setShowQr(true);
  }

  useEffect(() => {
    if (messageCopy && isCopy) {
      showToastMessage(); // Show toast message after state update
    }
  }, [messageCopy, isCopy]);

  const handleShowCoppyMessage = async (item) => {
    try {
      await copyToClipboardLineMessage(item);
      setMessageCopy("コピーしました");
      setIsCopy(true);
    } catch (e) {
      const errorMessage = e?.message || "システムエラーが発生しました";
      setMessageCopy(errorMessage);
      setIsCopy(true);
    }
  };
  
  const showToastMessage = () => {
    setShowToast(true);
    setIsCopy(false);
    setTimeout(() => setShowToast(false), 1500);
  };

  return (
    <CommonLayout>
      <ContentHeader showQr={true}>
        葬家一覧
      </ContentHeader>

      {
        preRegistrationList.length > 0 &&
        <Row>
          <h2 className="h4">LINE申請一覧</h2>
          <Col>
            <div className="card">
              <div className="card-body p-0">
                <table className="table">
                  <thead>
                  <tr>
                    <th>氏名</th>
                    <th>メールアドレス</th>
                    <th>電話番号</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    preRegistrationPagenatedList.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td><Link to={"funerals/" + row.FuneralId}>{row.ChiefMournerName} 様</Link></td>
                          <td>{row.Email}</td>
                          <td>{row.PhoneNo}</td>
                        </tr>
                      );
                    })
                  }
                  </tbody>
                </table>
              </div>
              <div className="card-footer clearfix">
                <Pagination per={PER_PAGE} list={preRegistrationList} onChange={(list) => setPreRegistrationPagenatedList(list)} />
              </div>
            </div>
          </Col>
        </Row>
      }

      {funeralNewList?.length > 0 && (
        <Row>
          <h2 className="h4">事前登録一覧</h2>
          <Col>
            <div className="card">
              <div className="card-body p-0">
                <table className="table table-pre-funeral">
                  <thead>
                    <tr>
                      <th>故人名</th>
                      <th className="col-manager">担当者</th>
                      <th className="col-actions"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      funeralNewPagenatedList.map((row, index) => {
                        return (
                          <tr key={index}>
                            <td><Link to={"funerals/" + row.FuneralId}>{row?.DecreasedName && row?.DecreasedName !== '' ? `${row.DecreasedName} 様` : '未入力'}</Link></td>
                            <td className="col-manager">{row?.UndertakerName && row?.UndertakerName !== '' ? row?.UndertakerName : '未入力'}</td>
                            <td className="text-right col-actions">
                                <Button disabled={true} variant="success" className="btn-sm btn-disabled">一時保存</Button>
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
              <div className="card-footer clearfix">
                <Pagination per={PER_PAGE} list={funeralNewList} onChange={(list) => setFuneralNewPagenatedList(list)} />
              </div>
            </div>
          </Col>
        </Row>
      )}

      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <Row>
            <Col>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">検索条件</h3>
                </div>
                <div className="card-body">
                  <Row className="form-group">
                    <Col md="4">
                      <label className="control-label">葬儀式日(From)</label>
                      <Input type="date" name="dateFrom" className="form-control" formik={formik} />
                    </Col>
                    <Col md="4">
                      <label className="control-label">葬儀式日(To)</label>
                      <Input type="date" name="dateTo" className="form-control" formik={formik} />
                    </Col>
                    <Col md="4">
                      <label className="control-label">会場</label>
                      <select className="custom-select" {...formik.getFieldProps("hallId")}>
                        <option></option>
                        {
                          halls.map((hall, i) => <option key={i} value={hall.HallId}>{hall.HallName}</option>)
                        }
                      </select>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer">
                  <Button variant="success" className="float-left" onClick={() => create()}>新規作成</Button>
                  <Button type="submit" className="float-right">検索</Button>
                  {' '}
                  <Button type="button" className="float-right mr-3" onClick={handleReload}>再読み込み</Button>
                  {' '}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </form>

      <Row>
        <Col>
          <div className="card">
            <div className="card-body p-0">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-funeral-fixed">故人名</th>
                    <th className="col-funeral-fixed">担当者</th>
                    <th className="col-funeral-fixed">会場</th>
                    <th className="col-num-fixed th-date">葬儀日時</th>
                    <th className="funeral-laptop col-num-fixed th-num">FU数</th>
                    <th className="funeral-laptop th-action"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    pagenatedList.map((row, index) => {
                      return (
                        <>
                        <tr className="funeral-laptop"  key={index} >
                          <td><Link to={"funerals/" + row.FuneralId}>{row?.DecreasedName && row?.DecreasedName !== '' ? `${row.DecreasedName} 様` : '未入力'}</Link></td>
                          <td>
                            <div className="custom-td-funeral">
                              <div className="funeral-text">{row?.UndertakerName && row?.UndertakerName !== '' ? row?.UndertakerName : '未入力'}</div>
                            </div>
                          </td>
                          <td>
                          <div className="custom-td-funeral">
                            <div className="funeral-text">{row?.HallName && row?.HallName !== '' ? row?.HallName : '未入力'}</div>
                            </div>
                          </td>
                          <td>
                            <div className="custom-td-funeral">
                              <div className="funeral-text">{row?.FuneralDateTime && row?.FuneralDateTime !== '' ? moment(row.FuneralDateTime).format("MM/DD HH:mm") : '未入力'}</div>
                          </div>
                          </td>
                          <td>{row?.LinkedWithLine && row?.NumOfRelatives}</td>
                          <td className="text-right btn-funeral-align">
                            {row?.LinkedWithLine ? (
                              <Button variant="secondary" className="btn btn-xs btn-margin" onClick={() => handleShowFuneralQr(row)}>家族QR</Button>
                            ) : (
                              <Button variant="success" className="btn btn-xs btn-margin" onClick={() => handleShowFuneralQr(row)}>受付QR</Button>
                            )}
                            <Button className="btn btn-xs btn-margin" onClick={() => window.open(`${Constants.FUNERALS_WEB_URL}/${row.FuneralId}`)}>葬家ページ <i className="fas fa-external-link-alt"/></Button>
                            <Button variant="dark" className="btn btn-xs btn-copy btn-margin" onClick={() => handleShowCoppyMessage(row)}>URLコピー <i class="fas fa-copy"></i></Button>
                            </td>
                        </tr>
                        <tr className="funeral-mobile tr-funeral" key={`secondtr-${index}`}>
                          <td><Link className="text-ellipsis" to={"funerals/" + row.FuneralId}>{row?.DecreasedName && row?.DecreasedName !== '' ? `${row.DecreasedName} 様` : '未入力'}</Link></td>
                          <td>
                              <div className="text-ellipsis">{row?.UndertakerName && row?.UndertakerName !== '' ? row?.UndertakerName : '未入力'}</div>
                          </td>
                          <td>
                            <div className="text-ellipsis">{row?.HallName && row?.HallName !== '' ? row?.HallName : '未入力'}</div>
                          </td>
                          <td>
                              <div className="text-ellipsis">{row?.FuneralDateTime && row?.FuneralDateTime !== '' ? moment(row.FuneralDateTime).format("MM/DD HH:mm") : '未入力'}</div>
                          </td>
                        </tr>
                        <tr key={`secondRow-${index}`} className='funeral-mobile tr-funeral text-vertical'>
                        <td>{row?.LinkedWithLine && `FU数 ${row?.NumOfRelatives}`}</td>
                          <td>
                            {row?.LinkedWithLine ? (
                                <Button variant="secondary" className="btn btn-xs" onClick={() => handleShowFuneralQr(row)}>家族QR</Button>
                              ) : (
                                <Button variant="success" className="btn btn-xs" onClick={() => handleShowFuneralQr(row)}>受取QR</Button>
                              )}
                            </td>
                            <td> <Button className="btn btn-xs btn-margin" onClick={() => window.open(`${Constants.FUNERALS_WEB_URL}/${row.FuneralId}`)}>葬儀ページ</Button></td>
                            <td> <Button variant="dark" className="btn btn-xs btn-copy btn-margin" onClick={() => handleShowCoppyMessage(row)}>URLコピー</Button></td>
                          </tr>
                        </>
                      );
                    })
                  }
                </tbody>
              </table>
                <Modal show={showToast} onHide={() => setShowToast(false)} backdrop="static" centered={true} >
                <Modal.Body>
                  <p className="text-center m-0">{messageCopy}</p>
                </Modal.Body>
              </Modal>
            </div>
            <div className="card-footer clearfix">
              <Pagination per={PER_PAGE} list={funeralList} onChange={(list) => setPagenatedList(list)} />
            </div>
          </div>
          <FuneralQRCode showQr={showQr} setShowQr={setShowQr} funeralItem={currentFuneral} />
        </Col>
      </Row>
    </CommonLayout>
  );
};

const getFuneralList = async (companyId, values) => {
  const { dateFrom, dateTo, hallId } = values;
  const options = {
    queryStringParameters: {
      companyId: companyId,
      dateFrom: dateFrom,
      dateTo: dateTo,
      hallId: hallId,
      sortBy: 'FuneralDateTime',
      sortDir: 'ASC',
    }
  };

  return await API.get('product', '/funerals', options);
}

const getFuneralListMissingInformation = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
      sortBy: 'UpdatedAt',
      sortDir: 'DESC',
    }
  };

  return await API.get('product', '/funerals/missing-line', options);
}

const getFuneralListForPreRegistration = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
    }
  };

  return await API.get('product', '/funerals/pre-registration', options);
}

const getHallList = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
      keyword: "" // すべて取得
    }
  };

  return await API.get('product', '/halls', options);
}

export default FuneralList;