import { useContext, useEffect, useState } from 'react';
import { API } from 'aws-amplify'
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import AttendeeLayout from 'components/AttendeeLayout';
import UploadImage from 'components/UploadImage';
import { ZipCode } from 'components/CustomForms/ZipCode';
import { Prefecture, prefCodeToName, prefCodeToKana, prefNameToCode } from 'components/CustomForms/Prefecture';
import { useLoading } from 'components/Loading';
import { ContentHeader } from 'components/ContentHeader';
import { Stepper } from 'react-form-stepper';
import { AuthContext } from '../../components/Contexts';
import { SearchBankCode } from '../../components/SearchBankCode';

// onBlurを使えるようにしたInput TODO:もし元のコンポーネントでonBlurを使えるようにしたら統合してください
const Input = ({type, name, className, value, formik, ...props}) => {
  switch(type) {
    case "checkbox":
      return (
        <input type="checkbox" className={className} {...props} onChange={(e) => formik.setFieldValue(name, e.target.checked)} checked={formik.values[name]}/>
      )
    case "radio":
      return (
        <input type="radio" name={name} className={className} {...props} value={value} onChange={(e) => formik.setFieldValue(name, e.target.value)} onBlur={(e) => formik.setFieldValue(name, e.target.value)} checked={formik.values[name] === value} />
      )
    case "file":
      return (
        <>
          <input type="file" className={className} {...props} onChange={(e) => {
            const reader = new FileReader();
            if(e.target.files && e.target.files[0]) {
              reader.readAsDataURL(e.target.files[0]);
              reader.onload = (ev) => {
                formik.setFieldValue(name, reader.result);
              }
            }
          }}/>
        </>
      )
    case "textarea":
      return (
        <>
          <textarea className={className} {...props} {...formik.getFieldProps(name)}/>
          <div className="invalid-feedback">{formik.errors[name]}</div>
        </>
      )
    default:
      const hasError = formik && formik.errors[name];
      return (
        <>
          <input type={type} className={className + (hasError ? " is-invalid" : "")} {...formik.getFieldProps(name)} {...props} />
          <div className="invalid-feedback">{formik.errors[name]}</div>
        </>
      );
  }
}

export const FuneralStripeConnectEdit = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const { userInfo } = useContext(AuthContext);
  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;
  const prefix = "/" + funeralId;

  const history = useHistory();
  const loading = useLoading();

  // 引数のDateオブジェクトの誕生日が13歳以上のものであるか検査する
  const checkOver13YearsOld = (value) => {
    const birthDate = moment(value);
    const today = moment();
    const age = today.diff(birthDate, 'years');
    return age >= 13;
  }

  // カタカナ（全角半角）を判定する正規表現
  const kanaPattern = '^([\u30A0-\u30FFｦ-ﾟ]| |　)*$'
  // 日本の電話番号（全角半角）を判定する正規表現
  const phonePattern = '^0[0-9０-９]{9,10}$'
  // ハイフンなし連続の数字（全角半角）を判定する正規表現
  const numberPattern = (number) => `^[0-9０-９]{${number}}$`

  // ステップごとに記録するアイテム
  const itemsOfSteps = [
    ["FirstName", "LastName", "FirstNameKana", "LastNameKana", "dob", "email"],
    ["phoneNo", "zipCode", "prefecture", "city", "cityKana", "town", "townKana", "address1", "address1Kana", "address2", "address2Kana"],
    ["bankCode1", "bankCode2", "accountNo", "accountName"],
    [],
    []
  ]

  // ステップごとのvalidateSchema
  const validateSchemaOfSteps = [
    Yup.object({
      FirstName: Yup.string().required("名は必須です").matches(/^[^ -~｡-ﾟ]+$/, '全角で入力してください'),
      LastName: Yup.string().required("姓は必須です").matches(/^[^ -~｡-ﾟ]+$/, '全角で入力してください'),
      FirstNameKana: Yup.string().required("名（カナ）は必須です").matches(new RegExp(kanaPattern, 'u'), 'カナで入力してください'),
      LastNameKana: Yup.string().required("姓（カナ）は必須です").matches(new RegExp(kanaPattern, 'u'), 'カナで入力してください'),
      dob: Yup.date().required("生年月日は必須です").test('valid-date', '13歳未満は登録できません', checkOver13YearsOld),
      email: Yup.string().required("メールアドレスは必須です").email("メールアドレス形式で入力してください"),
    }),
    Yup.object({
      phoneNo: Yup.string().required("電話番号は必須です").matches(new RegExp(phonePattern, 'u'), '正しい電話番号を入力してください'),
      zipCode: Yup.string().required("郵便番号は必須です").matches(new RegExp(numberPattern(7), 'u'), '郵便番号はハイフンなしの7桁で入力してください'),
      prefecture: Yup.string().required("都道府県は必須です"),
      city: Yup.string().required("市区町村は必須です").matches(/^[^\x20-\x7e]*$/, '全角で入力してください'),
      cityKana: Yup.string().required("市区町村（カナ）は必須です").matches(new RegExp(kanaPattern, 'u'), 'カナで入力してください'),
      town: Yup.string().required("町域は必須です").matches(/^[^\x20-\x7e]*$/, '全角で入力してください'),
      townKana: Yup.string().required("町域（カナ）は必須です").matches(new RegExp(kanaPattern, 'u'), 'カナで入力してください'),
      address1: Yup.string().required("番地は必須です"),
      address1Kana: Yup.string().required("番地（カナ）は必須です"),
    }),
    Yup.object({
      bankCode1: Yup.string().required("銀行コードは必須です").matches(new RegExp(numberPattern(4), 'u'), '銀行コードは4桁で入力してください'),
      bankCode2: Yup.string().required("支店コードは必須です").matches(new RegExp(numberPattern(3), 'u'), '支店コードは3桁で入力してください'),
      accountNo: Yup.string().required("口座番号は必須です").matches(new RegExp(numberPattern(7), 'u'), '口座番号は7桁で入力してください'),
      accountName: Yup.string().required("口座名義は必須です").matches(new RegExp(kanaPattern, 'u'), 'カナで入力してください'),
    }),
    null,
    Yup.object({
      accepted: Yup.boolean().required("StripeのConnectアカウント契約同意は必須です").oneOf([true], 'StripeのConnectアカウント契約同意は必須です'),
    })
  ]

  const formik = useFormik({
    initialValues: {
      FirstName: localStorage.getItem('FuneralStripeConnectEdit.FirstName') || '',
      LastName: localStorage.getItem('FuneralStripeConnectEdit.LastName') || '',
      FirstNameKana: localStorage.getItem('FuneralStripeConnectEdit.FirstNameKana') || '',
      LastNameKana: localStorage.getItem('FuneralStripeConnectEdit.LastNameKana') || '',
      phoneNo: localStorage.getItem('FuneralStripeConnectEdit.phoneNo') || '',
      zipCode: localStorage.getItem('FuneralStripeConnectEdit.zipCode') || '',
      prefecture: localStorage.getItem('FuneralStripeConnectEdit.prefecture') || '',
      city: localStorage.getItem('FuneralStripeConnectEdit.city') || '',
      cityKana: localStorage.getItem('FuneralStripeConnectEdit.cityKana') || '',
      town: localStorage.getItem('FuneralStripeConnectEdit.town') || '',
      townKana: localStorage.getItem('FuneralStripeConnectEdit.townKana') || '',
      address1: localStorage.getItem('FuneralStripeConnectEdit.address1') || '',
      address1Kana: localStorage.getItem('FuneralStripeConnectEdit.address1Kana') || '',
      address2: localStorage.getItem('FuneralStripeConnectEdit.address2') || '',
      address2Kana: localStorage.getItem('FuneralStripeConnectEdit.address2Kana') || '',
      dob: localStorage.getItem('FuneralStripeConnectEdit.dob') || '',
      email: localStorage.getItem('FuneralStripeConnectEdit.email') || '',
      bankCode1: localStorage.getItem('FuneralStripeConnectEdit.bankCode1') || '',
      bankCode2: localStorage.getItem('FuneralStripeConnectEdit.bankCode2') || '',
      accountNo: localStorage.getItem('FuneralStripeConnectEdit.accountNo') || '',
      accountName: localStorage.getItem('FuneralStripeConnectEdit.accountName') || '',
      accepted: localStorage.getItem('FuneralStripeConnectEdit.accepted') ? localStorage.getItem('FuneralStripeConnectEdit.accepted') === "true" : false,
    },
    validationSchema: validateSchemaOfSteps[activeStep],
    onSubmit: async (values) => {
      try {
        setIsUploading(true);
        loading(true);
        const result = await createFuneralStripeConnect(funeralId, values);
        if (result.error) {
          alert("システムエラーが発生しました");
          setErrorMessage(`システムエラーが発生しました: ${result.error}`);
        } else {
          clearAllValueForLocalStorage();
          alert("葬家の出金情報を更新しました");
          history.push(prefix + "/funeral/connect/detail");
        }
      } catch (e) {
        if (e.response.status === 400) {
          alert(e.response.data.error);
          setErrorMessage(`システムエラーが発生しました: ${e.response.data.error}`);
          return;
        }
        alert("システムエラーが発生しました");
        setErrorMessage("システムエラーが発生しました");
      } finally {
        loading(false);
        setIsUploading(false);
      }

    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  // このページでローカルストレージに保存したすべてのアイテムを消す
  const clearAllValueForLocalStorage = () => {
    localStorage.removeItem('FuneralStripeConnectEdit.FirstName')
    localStorage.removeItem('FuneralStripeConnectEdit.LastName')
    localStorage.removeItem('FuneralStripeConnectEdit.FirstNameKana')
    localStorage.removeItem('FuneralStripeConnectEdit.LastNameKana')
    localStorage.removeItem('FuneralStripeConnectEdit.phoneNo')
    localStorage.removeItem('FuneralStripeConnectEdit.zipCode')
    localStorage.removeItem('FuneralStripeConnectEdit.prefecture')
    localStorage.removeItem('FuneralStripeConnectEdit.city')
    localStorage.removeItem('FuneralStripeConnectEdit.cityKana')
    localStorage.removeItem('FuneralStripeConnectEdit.town')
    localStorage.removeItem('FuneralStripeConnectEdit.townKana')
    localStorage.removeItem('FuneralStripeConnectEdit.address1')
    localStorage.removeItem('FuneralStripeConnectEdit.address1Kana')
    localStorage.removeItem('FuneralStripeConnectEdit.address2')
    localStorage.removeItem('FuneralStripeConnectEdit.address2Kana')
    localStorage.removeItem('FuneralStripeConnectEdit.dob')
    localStorage.removeItem('FuneralStripeConnectEdit.email')
    localStorage.removeItem('FuneralStripeConnectEdit.bankCode1')
    localStorage.removeItem('FuneralStripeConnectEdit.bankCode2')
    localStorage.removeItem('FuneralStripeConnectEdit.accountNo')
    localStorage.removeItem('FuneralStripeConnectEdit.accountName')
    localStorage.removeItem('FuneralStripeConnectEdit.accepted')
  }

  // バリデーションを実行してからsubmitする
  const onSubmit = async (e) => {
    const validated = await formik.validateForm();
    if (Object.keys(validated).length === 0) {
      formik.handleSubmit(e);
    }
    else setErrorMessage("StripeのConnectアカウント契約同意は必須です");
  }

  const setFieldValue = formik.setFieldValue;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    loading(true);

    // 親族代表アカウントじゃなければリダイレクトする
    if (!userInfo.attendees.RelativesRepresentative) {
      loading(false);
      history.push(prefix + "/");
      return;
    }

    let funeral = null;
    try {
      funeral = await getFuneralStripeConnect(funeralId);
    } catch (e) {
      if (e.response.status !== 404) alert("システムエラーが発生しました");
      loading(false);
      return;
    }

    Object.keys(funeral).map((key) => {
      if (key === "prefecture") {
        return setFieldValue(key, prefNameToCode(funeral[key]));
      } else {
        return setFieldValue(key, funeral[key]);
      }
    });
    loading(false);

    // eslint-disable-next-line
  }, []);

  const handleChangeZipCode = (result) => {
    formik.setFieldValue("prefecture", result.prefCode)
    formik.setFieldValue("city", result.city.kanji)
    formik.setFieldValue("cityKana", result.city.kana)
    formik.setFieldValue("town", result.town.kanji)
    formik.setFieldValue("townKana", result.town.kana)
    localStorage.setItem("FuneralStripeConnectEdit.zipCode", formik.values["zipCode"])
    localStorage.setItem("FuneralStripeConnectEdit.prefecture", result.prefCode)
    localStorage.setItem("FuneralStripeConnectEdit.city", result.city.kanji)
    localStorage.setItem("FuneralStripeConnectEdit.cityKana", result.city.kana)
    localStorage.setItem("FuneralStripeConnectEdit.town", result.town.kanji)
    localStorage.setItem("FuneralStripeConnectEdit.townKana", result.town.kana)
  }

  // ステップが変わる時に自動保存
  const saveInputValueOnStepChange = (index) => {
    itemsOfSteps[index].forEach(item => {
      localStorage.setItem(`FuneralStripeConnectEdit.${item}`, formik.values[item]);
    })
  }

  // 現在のページをバリデーション検査しつつ、成功したら引数のステップに進む
  const changeStepWithValidation = async (step) => {
    const validated = await formik.validateForm();
    saveInputValueOnStepChange(step - 1);
    if (Object.keys(validated).length === 0) setActiveStep(step);
  };

  // 引数のステップに進む
  const changeStep = (step) => {
    setActiveStep(step)
  }

  // blurが発火するごとにローカルストレージに保存する
  const handleBlur = (e) => {
    formik.handleBlur(e);
    localStorage.setItem(`FuneralStripeConnectEdit.${e.target.name}`, formik.values[e.target.name])
  }

  return (
    <AttendeeLayout adminPage={true}>
      {
        isUploading && <p className="funeralConnectError">画面を閉じずにお待ちください</p>
      }

      <div className="title-search">
        <div className="title">
          <ContentHeader>
            出金情報入力
          </ContentHeader>
        </div>
      </div>
      <div>
        <Stepper
          steps={[
            { label: "本人情報登録" },
            { label: "住所登録" },
            { label: "口座登録" },
            { label: "本人確認書類の提出" },
            { label: "利用規約の同意" }
          ]}
          activeStep={activeStep}
        />
      </div>
      <div className="text-center">
        <div>
          <span style={{ display: activeStep === 0 ? "" : "none" }}>
            <div id="attendee-users">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <label className="control-label">本人情報</label>
                    <Row>
                      <Col md="6">
                        <label className="control-label">代表者名（漢字）</label>
                        <Row>
                          <Col md="6">
                            <Input type="text" name="LastName" className="form-control" formik={formik} placeholder="姓" onBlur={handleBlur} />
                          </Col>
                          <Col md="6">
                            <Input type="text" name="FirstName" className="form-control" formik={formik} placeholder="名" onBlur={handleBlur} />
                          </Col>
                        </Row>
                      </Col>
                      <Col md="6">
                        <label className="control-label">代表者名（カナ）</label>
                        <Row>
                          <Col md="6">
                            <Input type="text" name="LastNameKana" className="form-control" formik={formik} placeholder="セイ" onBlur={handleBlur} />
                          </Col>
                          <Col md="6">
                            <Input type="text" name="FirstNameKana" className="form-control" formik={formik} placeholder="メイ" onBlur={handleBlur} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <Row>
                      <Col md="9">
                        <label className="control-label">メールアドレス</label>
                        <Input type="text" name="email" className="form-control" formik={formik} onBlur={handleBlur} />
                      </Col>

                      <Col md="3">
                        <label className="control-label">生年月日</label>
                        <Input type="date" name="dob" className="form-control" formik={formik} onBlur={handleBlur} />
                      </Col>
                    </Row>
                  </div>
                  <div className="card-footer">
                    <div>
                      <Button variant="secondary" className="float-left" onClick={() => history.push(prefix + "/")}>トップへ戻る</Button>
                      <Button className="float-right" onClick={() => changeStepWithValidation(activeStep + 1)}>住所登録へ進む</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
          <span style={{ display: activeStep === 1 ? "" : "none" }}>
            <div id="attendee-users">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <label className="control-label">住所情報</label>
                    <Row>
                      <Col md="3">
                        <label className="control-label">郵便番号（ハイフン無し）</label>
                        <ZipCode name="zipCode" className="form-control" formik={formik} onChange={handleChangeZipCode} />
                      </Col>
                      <Col md="3">
                        <label className="control-label">都道府県</label>
                        <Prefecture name="prefecture" className="form-control" formik={formik} onBlur={handleBlur} />
                      </Col>
                      <Col md="6">
                        <label className="control-label">電話番号（ハイフン無し）</label>
                        <Input type="text" name="phoneNo" className="form-control" formik={formik} onBlur={handleBlur} />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <Row>
                      <Col md="6">
                        <label className="control-label">市区町村（漢字）</label>
                        <Input type="text" name="city" className="form-control" formik={formik} onBlur={handleBlur} />
                      </Col>
                      <Col md="6">
                        <label className="control-label">市区町村（カナ）</label>
                        <Input type="text" name="cityKana" className="form-control" formik={formik} onBlur={handleBlur} />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <Row>
                      <Col md="6">
                        <label className="control-label">町域名（漢字）</label>
                        <Input type="text" name="town" className="form-control" formik={formik} onBlur={handleBlur} />
                      </Col>
                      <Col md="6">
                        <label className="control-label">町域名（カナ）</label>
                        <Input type="text" name="townKana" className="form-control" formik={formik} onBlur={handleBlur} />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <Row>
                      <Col md="6">
                        <label className="control-label">番地等（漢字）</label>
                        <Input type="text" name="address1" className="form-control" formik={formik} onBlur={handleBlur} />
                      </Col>
                      <Col md="6">
                        <label className="control-label">番地等（カナ）</label>
                        <Input type="text" name="address1Kana" className="form-control" formik={formik} onBlur={handleBlur} />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <Row>
                      <Col md="6">
                        <label className="control-label">建物名・部屋番号（漢字）</label>
                        <Input type="text" name="address2" className="form-control" formik={formik} onBlur={handleBlur} />
                      </Col>
                      <Col md="6">
                        <label className="control-label">建物名・部屋番号（カナ）</label>
                        <Input type="text" name="address2Kana" className="form-control" formik={formik} onBlur={handleBlur} />
                      </Col>
                    </Row>
                  </div>
                  <div className="card-footer">
                    <div>
                      <Button variant="secondary" className="float-left" onClick={() => changeStep(activeStep - 1)}>本人情報登録へ戻る</Button>
                      <Button className="float-right" onClick={() => changeStepWithValidation(activeStep + 1)}>口座登録へ進む</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
          <span style={{ display: activeStep === 2 ? "" : "none" }}>
            <div id="attendee-users">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <label className="control-label">銀行口座情報</label>
                    <Row>
                      <Col md="3">
                        <label className="control-label">銀行コード</label>
                        <SearchBankCode />
                        <Input type="text" name="bankCode1" className="form-control" formik={formik} placeholder="0000" onBlur={handleBlur} />
                      </Col>
                      <Col md="3">
                        <label className="control-label">支店コード</label>
                        <Input type="text" name="bankCode2" className="form-control" formik={formik} placeholder="000" onBlur={handleBlur} />
                      </Col>
                      <Col md="4">
                        <label className="control-label">口座番号</label>
                        <Input type="text" name="accountNo" className="form-control" formik={formik} placeholder="0000000" onBlur={handleBlur} />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <label className="control-label">口座名義 (カナ)</label>
                    <Input type="text" name="accountName" className="form-control" formik={formik} placeholder="〇〇〇〇〇" onBlur={handleBlur} />
                  </div>
                  <div className="card-footer">
                    <div>
                      <Button variant="secondary" className="float-left" onClick={() => changeStep(activeStep - 1)}>住所登録へ戻る</Button>
                      <Button className="float-right" onClick={() => changeStepWithValidation(activeStep + 1)}>本人確認書類登録へ進む</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
          <span style={{ display: activeStep === 3 ? "" : "none" }}>
            <div id="attendee-users">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <label className="control-label">本人確認書類（身分証明書）のコピー</label>
                    <p>本人情報と同じ情報が記載された本人確認書類を提出してください</p>
                    <Row>
                      <Col md="6">
                        <label className="control-label">表面</label>
                        <UploadImage type="file" name="idFileFront" className="form-control" formik={formik} width={360} />
                      </Col>
                      <Col md="6">
                        <label className="control-label">裏面</label>
                        <UploadImage type="file" name="idFileBack" className="form-control" formik={formik} width={360} />
                      </Col>
                    </Row>
                  </div>
                  <div className="card-footer">
                    <div>
                      <Button variant="secondary" className="float-left" onClick={() => changeStep(activeStep - 1)}>口座登録へ戻る</Button>
                      <Button className="float-right" onClick={() => changeStepWithValidation(activeStep + 1)}>進む</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
          <span style={{ display: activeStep === 4 ? "" : "none" }}>
            <div id="attendee-users">
              <div className="card">
                <div className="card-body">
                  <div className="form-group float-left" style={{ paddingLeft: "1.25rem" }}>
                    <label className="form-check-label">
                      <input type="checkbox" className="form-check-input" onChange={(e) => {
                        formik.setFieldValue("accepted", e.target.checked)
                        localStorage.setItem("FuneralStripeConnectEdit.accepted", e.target.checked)
                      }} checked={formik.values["accepted"]}/>
                      <span style={{ paddingLeft: "1.25rem" }}><a href="https://stripe.com/jp/legal/connect-account" target="_blank" rel="noopener noreferrer">StripeのConnectアカウント契約</a>に同意します</span>
                    </label>
                  </div>
                </div>
                {
                    errorMessage &&
                    <div className="error-message">
                      {errorMessage}
                    </div>
                }
                <div className="card-footer">
                  <div>
                    <Button variant="secondary" className="float-left" onClick={() => changeStep(activeStep - 1)}>本人確認書類登録へ戻る</Button>
                    <Button type="button" className="float-right" variant="success" onClick={onSubmit}>登録する</Button>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </AttendeeLayout>
  );
};

const getFuneralStripeConnect = async (funeralId) => {
  return await API.get('product', `/funerals/${funeralId}/connect`);
}

const createFuneralStripeConnect = async (funeralId, form) => {
  form.prefectureKanji = prefCodeToName(form.prefecture);
  form.prefectureKana = prefCodeToKana(form.prefecture);

  // 銀行口座番号の先頭がマスクされている場合は登録済みなので更新しないようにする
  if (form.accountNo.slice(0, 3) === '***') {
    form.accountNo = '';
  }

  const options = {
    body: form
  }
  return await API.post('product', '/funerals/' + funeralId + '/connect', options);
}

export default FuneralStripeConnectEdit;

