import React, {useState, useEffect, useCallback} from 'react';
import { API } from 'aws-amplify';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Row, Col, Button, Dropdown  } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { Form, DatePicker, Popconfirm, Radio } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment-timezone';

import { DeleteOutlined, VerticalAlignTopOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';

import { getBlob } from 'utils/request';
import { calculateDate, calculateTime, convertTimeScheduleData } from 'utils/funeral';
import { isIOS } from 'utils/device';
import * as Constants from '../../constants';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import { useLoading } from 'components/Loading';
import useMessage from 'components/Message';

export const FuneralTimeSchedule = (props) => {
  const match = useRouteMatch("/:companyId");
  const companyId = match.params?.companyId || null;
  const funeralId = props.match.params?.funeralId || null;

  const hours = [...Array(24)].map((_, i) => ('0' + i).slice(-2));
  const minutes = [...Array(12)].map((_, i) => ('0' + (i * 5)).slice(-2));

  const history = useHistory();
  const message = useMessage();
  const loading = useLoading();

  const [form] = Form.useForm();

  const [currentTemplate, setCurrentTemplate] = useState({Id: null});
  const [funeralInfo, setFuneralInfo] = useState({});
  const [listScheduleTemplates, setListScheduleTemplates] = useState([]);
  const [optionsItem, setOptionsItem] = useState([]);

  const fetchData = async () => {
    loading(true);
    try {
      const [scheduleTemplates, scheduleItems, funeral] = await Promise.all([
        getTimeScheduleTemplates(companyId),
        getScheduleItemList(companyId),
        getFuneral(companyId, funeralId),
      ]);
      setOptionsItem(scheduleItems?.scheduleItemList || []);
      setListScheduleTemplates(scheduleTemplates?.scheduleTemplateList || []);
      setFuneralInfo(funeral);
      await getFuneralTimeSchedule(companyId, funeralId);
    } catch (err) {
      message.alert.danger("システムエラーが発生しました");
    } finally {
      loading(false);
    }
  };
  
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (funeralInfo?.funeralTimeSchdule && Object.keys(funeralInfo?.funeralTimeSchdule)?.length > 0) {
      let scheduleSets = [
        {
          items: [{
            itemName: '',
            hour: '',
            minute: '',
            comment: ''
          }],
          date: dayjs(),
          timeSettingType : 'time-settings',
          title: '',
        }
      ];
      if (funeralInfo?.funeralTimeSchdule?.ScheduleSets?.length > 0) {
        scheduleSets = funeralInfo?.funeralTimeSchdule?.ScheduleSets?.map((values) => ({
          timeSettingType : values?.TimeSettingType  || 'time-settings',
          title: values?.Title || '',
          date: values?.Date ? dayjs(values?.Date) : null,
          items: values?.Items?.map((item) => ({
            itemName: item?.ItemName || '',
            hour: item?.Hour || '',
            minute: item?.Minute || '',
            comment: item?.Comment || '',
          })) || [],
        }));
      }

      const formValues = {
        scheduleSets: scheduleSets,
        scheduleTemplateId: funeralInfo?.funeralTimeSchdule?.ScheduleTemplateId,
        isDraft: funeralInfo?.funeralTimeSchdule?.IsDraft,
      };
      let funeralTemplate = funeralInfo?.funeralTimeSchdule?.ScheduleTemplateId && listScheduleTemplates?.find((item)=> item?.Id === funeralInfo?.funeralTimeSchdule?.ScheduleTemplateId);
      if (funeralTemplate) {
        funeralTemplate = {
          ...funeralTemplate,
          isEdit: true,
        }
      } else {
        funeralTemplate = {Id: null, isEdit: true};
      }
      setCurrentTemplate(funeralTemplate);
      form.setFieldsValue(formValues);
    }

    // eslint-disable-next-line
  }, [funeralInfo]);

  useEffect(() => {
    if (currentTemplate?.isEdit !== true) {
      if (currentTemplate?.Id) {
        let scheduleSets = [
          {
            items: [{
              itemName: '',
              hour: '',
              minute: '',
              comment: ''
            }],
            date: null,
            timeSettingType : 'time-settings',
            title: '',
          }
        ];
        if (currentTemplate?.ScheduleSets?.length > 0) {
          scheduleSets = [];
          currentTemplate?.ScheduleSets?.forEach((values) => {
            if (values?.TimeSettingType !== 'wake-time' || funeralInfo?.vigilDateTime !== '') {
                scheduleSets.push({
                timeSettingType : values?.TimeSettingType  || 'time-settings',
                title: values?.Title || '',
                date: calculateDate(values?.TimeSettingType  || 'time-settings', funeralInfo),
                items: values?.Items?.map((item) => ({
                  itemName: item?.ItemName || '',
                  hour: calculateTime(values?.TimeSettingType  || 'time-settings', item, funeralInfo)?.hour || '',
                  minute: calculateTime(values?.TimeSettingType  || 'time-settings', item, funeralInfo)?.minute || '',
                  comment: item?.Comment || '',
                })) || [],
              });
            }
          });
        }

        const formValues = {
          scheduleSets: scheduleSets,
          scheduleTemplateId: currentTemplate?.Id,
        };
        form.setFieldsValue(formValues);
      } else {
        const formValues = {
          scheduleSets: [
            {
              items: [{
                itemName: '',
                hour: '',
                minute: '',
                comment: ''
              }],
              date: null,
              timeSettingType : 'time-settings',
              title: '',
            }
          ],
          scheduleTemplateId: null,
        };
        form.setFieldsValue(formValues);
      }
    }

    // eslint-disable-next-line
  }, [currentTemplate]);

  const handleDownloadPDF = async () => {
    loading(true);
    try {
      const res = await downloadTimeSchedulePdf(funeralId)
      if (res.filename) {
        if (isIOS()) {
          window.location.href = Constants.IMAGE_BUCKET_URL + res.filename;
        } else {
          const pdfFile = await getBlob(Constants.IMAGE_BUCKET_URL + res.filename, { method: 'GET' });
          saveAs(pdfFile, 'タイムスケジュール' + moment(new Date()).format("YYYY-MM-DD") + ".pdf");
        }
      }
    } catch (e) {
      if (e?.response?.status === 400) {
        message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
      } else {
        message.alert.danger("システムエラーが発生しました");
      }
    } finally {
      loading(false);
    }
  }

  const handleSave = (values) => {
    if (values?.isDraft === false && funeralInfo?.lineUserId !== '' && Object.keys(funeralInfo?.funeralTimeSchdule)?.length === 0) {
      message.confirm.info({
        title: "ラインメッセージを再送信する",
        message: "LINEメッセージを送信する？",
        onSubmit: async (type) => {
          saveFuneralTimeSchedule(values, type)
        }
      });
    } else {
      saveFuneralTimeSchedule(values, false)
    }
	}

  const handleSendLine = () => {
    message.confirm.info({
      title: "ラインメッセージを再送信する",
      message: "LINEメッセージを送信してもよろしいですか？",
      onSubmit: async () => {
        try {
          await sendLineSchedule(funeralId);
          message.alert.success("LINE送信されました。");
        } catch (e) {
          if (e?.response?.status === 400) {
            message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
          } else {
            message.alert.danger("システムエラーが発生しました");
          }
        } finally {
          loading(false);
        }
      }
    });
  }

  const saveFuneralTimeSchedule = async (values, sendLine) => {
    loading(true);
    try {
      const dataTimeSchedule = convertTimeScheduleData(values);
      const formValues = {
        ...dataTimeSchedule,
        companyId: companyId,
        funeralId: funeralId,
        scheduleTemplateId: currentTemplate?.Id,
        sendLine: sendLine,
        isDraft: values?.isDraft === false ? false : true,
      };
      await setFuneralTimeSchedule(formValues);
      const funeral = await getFuneral(companyId, funeralId);
      setFuneralInfo(funeral);
      message.alert.success("タイムスケジュールを保存しました。");
    } catch (e) {
      if (e?.response?.status === 400) {
        message.alert.danger(e?.response?.data?.error || "システムエラーが発生しました");
      } else {
        message.alert.danger("システムエラーが発生しました");
      }
    } finally {
      loading(false);
    }
  }

  const labelTemplateName = useCallback(() => {
    if (funeralInfo?.funeralTimeSchdule?.ScheduleTemplateId && !currentTemplate?.Id) {
      return listScheduleTemplates?.find((item)=> item?.Id === funeralInfo?.funeralTimeSchdule?.ScheduleTemplateId)?.ScheduleTemplateName || '';
    } else {
      return listScheduleTemplates?.find((item)=> item?.Id === currentTemplate?.Id)?.ScheduleTemplateName || '';
    }
    
    // eslint-disable-next-line
  }, [currentTemplate?.Id, funeralInfo?.funeralTimeSchdule?.ScheduleTemplateId]);

  const renderLabelTimeSettingType = useCallback((key) => {
      if (funeralInfo?.funeralTimeSchdule?.ScheduleSets?.[key]?.TimeSettingType) {
        return (
          <Col sm="12" md="3">
            <span className="label-datetime-setting">{Constants?.SETTING_DATETIME?.find((it) => it?.value === funeralInfo?.funeralTimeSchdule?.ScheduleSets?.[key]?.TimeSettingType)?.label  || ''}</span>
          </Col>
        );
      } else if (currentTemplate?.ScheduleSets?.[key]?.TimeSettingType) {
        return (
          <Col sm="12" md="3">
            <span className="label-datetime-setting">{Constants?.SETTING_DATETIME?.find((it) => it?.value === currentTemplate?.ScheduleSets?.[key]?.TimeSettingType)?.label  || ''}</span>
          </Col>
        );
      } else {
        return;
      }

      // eslint-disable-next-line
  }, [currentTemplate?.Id, funeralInfo?.funeralTimeSchdule?.ScheduleTemplateId]);

  return (
    <CommonLayout>
      <ContentHeader>{`${funeralInfo?.familyName || ''}家 タイムスケジュール`}</ContentHeader>
        <Form
        layout="vertical"
        form={form}
        name="time-schedule"
        autoComplete="off"
        onFinish={handleSave}
        initialValues={{ 
          scheduleSets: [{
            items: [{
              itemName: '',
              hour: '',
              minute: '',
              comment: ''
            }],
            date: null,
          }],
          isDraft: true,
        }}
      >
          <Row>
            <Col lg="12">
              <div className="card funeralTimeSchedule">
                <div className="card-body">
                <Dropdown onSelect={(key) => setCurrentTemplate(listScheduleTemplates?.find((item)=> item?.Id === key) || {Id: null})}>
                  <Dropdown.Toggle id="select-template" variant="light">{labelTemplateName()}</Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="" active={!currentTemplate?.Id}>&nbsp;</Dropdown.Item>
                    {listScheduleTemplates?.map((item) => (
                      <Dropdown.Item key={item?.Id} eventKey={item?.Id} active={currentTemplate?.Id === item?.Id}>{item?.ScheduleTemplateName || ''}</Dropdown.Item>
                    ))}
                    <Dropdown.Item href={`/${companyId}/time-schedule-templates/new`}><b>+追加する</b></Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Form.List name="scheduleSets">
                  {(fields, { add, remove }) => (
                    <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                      {fields.map((field, index) => (
                        <div key={field?.key || index}>
                          <Row className="titleWrapper">
                            <Col sm="12" md="3">
                              <Form.Item label="タイトル" name={[field.name, 'title']}>
                                <input className="form-control" placeholder="例:葬儀(最大23文字)" />
                              </Form.Item>
                            </Col>
                            <Col sm="12" md="2">
                              <Form.Item label="日付" name={[field.name, 'date']}>
                                <DatePicker format="YYYY/MM/DD" />
                              </Form.Item>
                            </Col>
                            <Col sm="12" lg="3" xl="2">
                              <Button variant="light" onClick={() => add({items: [{}]})}>+タイトルを追加</Button>
                            </Col>
                            <Col sm="12" lg="3" xl="2">
                              
                              <Popconfirm
                                description="このタイトルを削除しますか?"
                                onConfirm={() => {
                                  remove(field.name);
                                }}
                                okText="削除"
                                cancelText="キャンセル"
                              >
                                <Button variant="dark">タイトルを削除</Button>
                              </Popconfirm>
                            </Col>
                            {renderLabelTimeSettingType(field?.name)}
                          </Row>

                          <Form.Item>
                            <Form.List name={[field.name, 'items']}>
                              {(subFields, subOpt) => (
                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                  <div className="listItem listLabel">
                                    <Row>
                                      <Col sm="12" md="3">
                                        <label>項目</label>
                                      </Col>
                                      <Col sm="12" md="2">
                                        <label>時間</label>
                                      </Col>
                                      <Col sm="12" md="2">
                                        <label>分</label>
                                      </Col>
                                      <Col sm="12" md="3">
                                        <label>コメント</label>
                                      </Col>
                                    </Row>
                                  </div>
                                  {subFields.map((subField, index) => (
                                    <div className="listItem" key={subField.key}>
                                      <Row>
                                        <Col xs="6" md="3">
                                          <Form.Item label="項目" name={[subField.name, 'itemName']}>
                                            <select className="custom-select">
                                              <option value=""></option>
                                              { optionsItem?.map((item, index) => <option key={item?.Id || index} value={item?.Id}>{item?.Name || ''}</option>) }
                                            </select>
                                          </Form.Item>
                                        </Col>
                                        <Col xs="3" md="2">
                                          <Form.Item label="時間" name={[subField.name, 'hour']}>
                                            <select className="custom-select">
                                              <option value=""></option>
                                              { hours.map((h, i) => <option key={i} value={h}>{h}</option>) }
                                            </select>
                                          </Form.Item>
                                        </Col>
                                        <Col xs="3" md="2">
                                          <Form.Item label="分" name={[subField.name, 'minute']}>
                                            <select className="custom-select">
                                              <option value=""></option>
                                              {minutes.map((m, i) => <option key={i} value={m}>{m}</option>)}
                                            </select>
                                          </Form.Item>
                                        </Col>
                                        <Col sm="12" md="3">
                                          <Form.Item label="コメント" name={[subField.name, 'comment']}>
                                            <input className="form-control" maxLength={30} />
                                          </Form.Item>
                                        </Col>
                                        <Col sm="12" md="2">
                                          <div className='actions'>
                                            <VerticalAlignTopOutlined 
                                              onClick={() => {
                                                subOpt.move(index, index - 1);
                                              }}
                                            />
                                            <VerticalAlignBottomOutlined 
                                              onClick={() => {
                                                subOpt.move(index, index + 1);
                                              }}
                                            />
                                            
                                            <Popconfirm
                                              description="この項目を削除しますか？"
                                              onConfirm={() => {
                                                subOpt.remove(subField.name);
                                              }}
                                              okText="削除"
                                              cancelText="キャンセル"
                                            >
                                              <DeleteOutlined />
                                            </Popconfirm>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                  <Row>
                                    <Col sm="12" md="10">
                                      <Button className="button-add-item" variant="light" onClick={() => subOpt.add()} disabled={subFields?.length >= 18}>+項目を追加</Button>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </Form.List>
                          </Form.Item>
                        </div>
                      ))}
                    </div>
                  )}
                </Form.List>
                <div className="action-submit">
                  <Form.Item name='isDraft'>
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value={false}>公開</Radio.Button>
                      <Radio.Button value={true}>非公開</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </div>

              <div className="card-footer">
                <div>
                  <Button variant="dark" type="submit">保存</Button>
                  {funeralInfo?.funeralTimeSchdule?.IsDraft === false && funeralInfo?.lineUserId && (
                    <Button variant="success" onClick={() => handleSendLine()}>LINE送信</Button>
                  )}
                  <Button variant="info" onClick={() => handleDownloadPDF()}>PDF</Button>
                  <Button variant="secondary" onClick={() => history.goBack()}>戻る</Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        </Form>
    </CommonLayout>
  );
};

const getTimeScheduleTemplates = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }

  return await API.get('product', '/schedule-template', options);
}

const getScheduleItemList = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId,
    }
  };

  return await API.get('product', '/schedule-item', options);
}

const getFuneral = async (companyId, funeralId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }
  return await API.get('product', '/funerals/' + funeralId, options);
}

const getFuneralTimeSchedule = async (companyId, funeralId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }
  return await API.get('product', '/funerals/' + funeralId, options);
}

const sendLineSchedule = async (funeralId) => {
  return await API.post('product', `/funerals-schedule/${funeralId}/resend-line`);
}

const downloadTimeSchedulePdf = async (funeralId) => {
  return await API.get('product', '/funerals-schedule/' + funeralId + '/download');
}

const setFuneralTimeSchedule = async (values) => {
  const options = {
    body: values,
  }
  return await API.post('product', '/funeral-time-schedule', options);
}

export default FuneralTimeSchedule;
