/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from 'react';
import { Auth } from 'aws-amplify';
import { NavDropdown } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AuthContext } from '../Contexts';
import * as Constants from '../../constants';

export const Header = ({ user, email }) => {
  const history = useHistory();
  const { setUserInfo } = useContext(AuthContext);
  const match = useRouteMatch("/:companyId");
  const companyId = document.domain === Constants.ADMIN_DOMAIN? null: match.params.companyId;

  async function handleSignOut() {
    await Auth.signOut();
    setUserInfo(null);
    companyId? history.push("/"+companyId+"/signin"): history.push("signin");
  }

  return (
    <nav className="row main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="col-8 navbar-nav">
        <span className="navbar-header">{user.CompanyName ? user.CompanyName + "様 管理画面" : "システム管理画面"}</span>
      </ul>
      <ul className="col-4 navbar-nav ml-auto navbar-header justify-content-end">
        <li className="dropdown">
          <i className="fas fa-user-circle fa-lg"></i>
          <NavDropdown
            id="nav-dropdown-user"
            title={user.UserName}
            style={{ display: 'inline-block' }}
          >
            <NavDropdown.Item onClick={() => companyId? history.push("/"+companyId+"/otp"): history.push("/otp")}>
              <i className="fas fa-user-lock" />&nbsp;
              多要素認証設定
            </NavDropdown.Item>
            {companyId && <NavDropdown.Divider /> }
            <NavDropdown.Item onClick={() => companyId? history.push("/"+companyId+"/password"): history.push("/password")}>
              <i className="fas fa-key" />&nbsp;
              パスワード変更
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => handleSignOut()}>
              <i className="fas fa-sign-out-alt" />&nbsp;
              サインアウト
            </NavDropdown.Item>
          </NavDropdown>
        </li>
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars fa-2x"></i></a>
        </li>
      </ul>
    </nav>
  )
}

export default Header;