import EventListener from 'react-event-listener';

import '../css/LandingPage.scss';

export const LPLayout = (props) => {
  return (
    <div id="landing-page">
      <EventListener target="window" />
      <header className="header">
        {props.name}
      </header>
      <main id="lp-content-main">
        <section id="contents">
          {props.children}
        </section>
      </main>
    </div>
  );
}

export default LPLayout;