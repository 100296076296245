import { useRef } from 'react';

export const LineFamilySignInButton = ({handleLineLogin}) => {
  const lineBtnRef = useRef();

  const onHover = () => {
    lineBtnRef.current.src = '/images/SignInFamilyBtnHover.png';
  }

  const unHover = () => {
    lineBtnRef.current.src = '/images/SignInFamilyBtnDefault.png';
  }

  const downClick = () => {
    lineBtnRef.current.src = '/images/SignInFamilyBtnPress.png';
  }

  const upClick = () => {
    lineBtnRef.current.src = '/images/SignInFamilyBtnDefault.png';
  }
  return (
    <img
      className={'pointer'}
      ref={lineBtnRef}
      src="/images/SignInFamilyBtnDefault.png"
      alt={'LINEアカウントで登録'}
      onMouseEnter={onHover}
      onMouseLeave={unHover}
      onMouseUp={upClick}
      onMouseDown={downClick}
      onClick={handleLineLogin}
    />
  )
}

export default LineFamilySignInButton;