import { useState } from "react";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
  };
  return (
      <p className="text">
          {isReadMore && text.length > 7 ? text.slice(0, 7) : text}
          {text?.length > 7 && (
            <span
                onClick={toggleReadMore}
                className="read-or-hide"
                style={{ color: "blue" }}
            >
                {isReadMore ? "...続きを読む" : ""}
            </span>
          )}
      </p>
  );
};

export default ReadMore;