import React from 'react';
import { API } from 'aws-amplify'
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import { useLoading } from 'components/Loading';
import { ItemTypeToName } from 'components/ItemType';
import Pagination from 'components/Pagination';

export const FuneralHistoryOrderList = (props) => {
  const PER_PAGE = 10;

  const [orderList, setOrderList] = React.useState([]);
  const [pagenatedList, setPagenatedList] = React.useState([]);
  const [familyName, setFamilyName] = React.useState("");
  const attendeeId = props.match.params.attendeeId;
  const funeralId = props.match.params.funeralId;
  const history = useHistory();
  const loading = useLoading();

  React.useEffect(() => {
    const fetch = async () => {
      loading(true);
      try {
        const result = await getOrderList(attendeeId, funeralId);
        let list = [];
        result.orderList.forEach((row) => {
          row.Item.forEach((item) => {
            list.push({
              ItemType: ItemTypeToName(item.ItemType),
              ItemId: item.ItemType === "money" ? "-" : item.ItemId,
              ItemName: item.ItemName,
              Price: parseInt(item.DisplayPrice || item.Price, 10).toLocaleString()
            })
          })
        });
        setOrderList(list);
        setPagenatedList(list.slice(0, PER_PAGE));

        if (attendeeId === "order") {
          const funeral = await getFuneral(props.match.params.companyId, funeralId);
          setFamilyName(funeral.familyName);
        }
      } finally {
        loading(false);
      }
    }
    fetch()
    // eslint-disable-next-line
  }, []);

  return (
    <CommonLayout>
      <ContentHeader>
        {attendeeId === "order" ?
          `${familyName}家 注文リスト` :
          "注文リスト"
        }
      </ContentHeader>
      <Row>
        <Col md="9">
          <div className="card">
            <div className="card-body p-0">
              <table className="table">
                <thead>
                  <tr>
                    <th>商品種別</th>
                    <th>商品ID</th>
                    <th>商品名</th>
                    <th className="text-right">価格</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    pagenatedList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.ItemType}</td>
                          <td>{item.ItemId}</td>
                          <td>{item.ItemName}</td>
                          <td className="text-right">{item.Price}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <Button variant="success" className="float-left" onClick={() => history.goBack()}>戻る</Button>
              <Pagination per={PER_PAGE} list={orderList} onChange={(list) => setPagenatedList(list)} />
            </div>
          </div>
        </Col>
      </Row>
    </CommonLayout>
  );
};

const getOrderList = async (attendeeId, funeralId) => {
  const options = {
    queryStringParameters: {
      attendeeId: attendeeId,
      funeralId: funeralId
    }
  }

  return await API.get('product', '/orders', options);
}

const getFuneral = async (companyId, funeralId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }
  return await API.get('product', '/funerals/' + funeralId, options);
}

export default FuneralHistoryOrderList;