import { getAddress } from 'pages/undertaker/HallEdit';
import * as Constants from '../../constants';

export const ZipCodeHall = (props) => {
  const { name, className, onChange, formik, ...params } = props;

  const handleBlur = (e) => {
    formik.handleBlur(e);
    // Reset data afer onChange zipcode
    const fieldsToReset= ['prefecture', 'city', 'town'];
    fieldsToReset.forEach(field => {
      formik.setFieldValue(field, "")
    })

    const fetch = async (zipCode) => {
      const formData = new FormData();
      formData.append("type", "address");
      formData.append("zipcode", zipCode);
      const response = await getAddress(formData);
      if (response && response.includes('true')) {
        const splitData = response.replace("true", "").trim().split(',');
        const prefecture = Constants.PREFECTURES.find((e) => e.value === splitData[0]);
        onChange({
          prefCode: prefecture.key,
          prefecture: prefecture.value,
          city: splitData[1],
          town: splitData[2]
        })
      } else {
        onChange(null)
      }
    }
    if(e.target.value && e.target.value.length >= 7) {
      fetch(e.target.value);
    }
  }

  return (
    <div className="container-zipcode">
      <input type="text" name={name} className={className + (formik.errors[name] ? " is-invalid" : "")} value={formik.values[name]} onChange={formik.handleChange} onBlur={handleBlur} {...params}/>
      <div className="invalid-feedback">{formik.errors[name]}</div>
    </div>
  )
}
