import { useState, useEffect, useRef } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';

const ButtonsArea = styled.div`
  display: flex;
  margin: 2px;
` 

export const RoomList = ({ name, formik }) => {
  const [rooms, setRooms] = useState(formik.values[name] || []);
  const [roomName, setRoomName] = useState("");
  const selected = useRef("");

  useEffect(() => {
    setRooms(formik.values[name]);
  },[formik, name]);

  const handleAdd = () => {
    if (!rooms.includes(roomName)) {
      rooms.push(roomName);
      setRoomName("");
      formik.setFieldValue(name, rooms);
    }
  }

  const handleDelete = () => {
    const newList = rooms.filter(room => room !== selected.current.value);
    setRooms(newList);
    setRoomName("");
    formik.setFieldValue(name, newList);
  }

  const handleModify = () => {
    const newList = rooms.map(room => room === selected.current.value ? roomName : room)
    setRooms(newList);
    setRoomName("");
    formik.setFieldValue(name, newList);
  }

  const handleUp = () => {
    const currentRoom = selected.current.value;
    const index = rooms.indexOf(currentRoom);
    const newList = rooms.filter(room => room !== currentRoom);
    newList.splice(Math.max(index - 1, 0), 0, currentRoom)
    setRooms(newList);
    formik.setFieldValue(name, newList);
  }

  const handleDown = () => {
    const currentRoom = selected.current.value;
    const index = rooms.indexOf(currentRoom);
    const newList = rooms.filter(room => room !== currentRoom);
    newList.splice(Math.min(index + 1, newList.length), 0, currentRoom)
    setRooms(newList);
    formik.setFieldValue(name, newList);
  }

  const handleChange = (e) => {
    setRoomName(e.target.value);
  }

  return (
    <Row>
      <Col md="6">
        <select multiple ref={selected} className="custom-select" onChange={handleChange}>
          {
            rooms.map((room, i) => <option key={i} value={room}>{room}</option>)
          }
        </select>
        <ButtonsArea>
          <Button className="btn btn-secondary btn-sm" style={{ flexGrow: 1, margin: "2px" }} onClick={handleUp}>上へ</Button>
          <Button className="btn btn-secondary btn-sm" style={{ flexGrow: 1, margin: "2px" }} onClick={handleDown}>下へ</Button>
        </ButtonsArea>
      </Col>
      <Col md="6">
        <input type="text" className="form-control" value={roomName} onChange={(e) => setRoomName(e.target.value)}></input>
        <Button className="btn btn-primary btn-sm" style={{ flexGrow: 1, margin: "2px" }} onClick={handleAdd}>リストに追加</Button>
        <Button variant="info" className="btn btn-primary btn-sm" style={{ flexGrow: 1, margin: "2px" }} onClick={handleModify}>名前を変更</Button>
        <Button variant="danger" className="btn btn-primary btn-sm" style={{ flexGrow: 1, margin: "2px" }} onClick={handleDelete}>リストから削除</Button>
      </Col>
    </Row>
  )
}
