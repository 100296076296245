import { useEffect } from 'react';
import { API } from 'aws-amplify'
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Input from 'components/Input';
import useMessage from 'components/Message';

export const SupplierEdit = (props) => {
  const newEntry = props.method === "new";
  const match = useRouteMatch("/:companyId");
  const companyId = match.params.companyId;

  const history = useHistory();
  const message = useMessage();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      supplierId: "",
      companyName: "",
      staffName: "",
      phoneNo: "",
      email: "",
      useEmail: false
    },
    validationSchema: Yup.lazy(values => {
      if(values.useEmail) {
        return Yup.object({
          supplierId: Yup.string().required("業者IDは必須です").matches(/^[a-zA-Z0-9_\-@]+$/, { message: '業者IDが有効ではありません' }),
          companyName: Yup.string().max(20, "20文字以下で入力してください").required("業者名は必須です"),
          staffName: Yup.string().max(20, "20文字以下で入力してください").required("担当者名は必須です"),
          phoneNo: Yup.string().required("電話番号は必須です"),
          email: Yup.string().required("メールアドレスは必須です")
        })
      } else {
        return Yup.object({
          supplierId: Yup.string().required("業者IDは必須です").matches(/^[a-zA-Z0-9_\-@]+$/, { message: '文字列が有効ではありません' }),
          companyName: Yup.string().max(20, "20文字以下で入力してください").required("業者名は必須です"),
          staffName: Yup.string().max(20, "20文字以下で入力してください").required("担当者名は必須です"),
          phoneNo: Yup.string().required("電話番号は必須です")
        })
      }
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        if(newEntry) {
          const result = await createSupplier(companyId, values.supplierId, values.companyName, values.staffName, values.phoneNo, values.email, values.useEmail);
          if(result.error) {
            if (result.error === "SupplierId exists") {
              formik.setFieldError("supplierId", "業者IDがすでに存在しています");
            } else {
              message.alert.danger("システムエラーが発生しました");
            }
          } else {
            message.alert.success("業者を作成しました");
            history.goBack();
          }
        } else {
          await updateSupplier(companyId, values.supplierId, values.companyName, values.staffName, values.phoneNo, values.email, values.useEmail);
          message.alert.success("業者を更新しました");
          history.goBack();
        }
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });
  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    const fetch = async (supplierId) => {
      const supplier = await getSupplier(props.match.params.companyId, supplierId);
      setFieldValue("supplierId", supplier.Id)
      setFieldValue("companyName", supplier.CompanyName);
      setFieldValue("staffName", supplier.StaffName)
      setFieldValue("phoneNo", supplier.PhoneNo);
      setFieldValue("email", supplier.Email);
      setFieldValue("useEmail", supplier.UseEmail)
    }
    if (!newEntry) {
      fetch(props.match.params.supplierId);
    }
    // eslint-disable-next-line
  },[]);

  const handleDelete = () => {
    message.confirm.danger({
      title: "業者を削除",
      message: "削除してもよろしいですか？",
      onSubmit: () => {
        deleteSupplier(formik.values.supplierId);
        message.alert.success("業者を削除しました");
        history.goBack();
      }
    });
  }

  return (
    <CommonLayout>
      <ContentHeader>
        {newEntry ? "業者新規作成" : "業者編集"}
      </ContentHeader>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md="6">
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <label className="control-label">業者ID</label>
                  <Input type="text" name="supplierId" className="form-control" formik={formik} disabled={!newEntry}/>
                </div>
                <div className="form-group">
                  <label className="control-label">業者名</label>
                  <Input type="text" name="companyName" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">担当者名</label>
                  <Input type="text" name="staffName" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">電話番号</label>
                  <Input type="text" name="phoneNo" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <label className="control-label">メールアドレス</label>
                  <Input type="text" name="email" className="form-control" formik={formik} />
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <Input type="checkbox" name="useEmail" className="form-check-input" formik={formik} />
                    <label className="form-check-label">発注メールを送信</label>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div>
                  <Button variant="secondary" className="float-right" onClick={() => history.goBack()}>戻る</Button>
                  {
                    newEntry ?
                      <Button type="submit" variant="success">作成</Button>
                      :
                      <>
                        <Button type="submit" variant="success">更新</Button>
                        {' '}
                        <Button variant="danger" onClick={handleDelete}>削除</Button>
                      </>
                  }
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </form>

    </CommonLayout>
  );
};

const getSupplier = async (companyId, supplierId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }
  return await API.get('product', '/suppliers/' + supplierId, options);
}

const createSupplier = async (companyId, supplierId, companyName, staffName, phoneNo, email, useEmail) => {
  const options = {
    body: {
      companyId: companyId,
      supplierId: supplierId,
      companyName: companyName,
      staffName: staffName,
      phoneNo: phoneNo,
      email: email,
      useEmail: useEmail
    }
  }
  return await API.post('product', '/suppliers', options);
}

const updateSupplier = async (companyId, supplierId, companyName, staffName, phoneNo, email, useEmail) => {
  const options = {
    body: {
      companyId: companyId,
      supplierId: supplierId,
      companyName: companyName,
      staffName: staffName,
      phoneNo: phoneNo,
      email: email,
      useEmail: useEmail
    }
  }
  return await API.put('product', '/suppliers', options);
}

const deleteSupplier = async (companyId, supplierId) => {
  const options = {
    body: {
      companyId: companyId,
      supplierId: supplierId,
    }
  }
  return await API.del('product', '/suppliers', options);
}

export default SupplierEdit;