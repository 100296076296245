import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import LPLayout from 'components/LPLayout';
import { Img } from 'components/Img';
import { Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import CommonFooter from 'components/CommonFooter';
import * as format from 'utils/format';

export const Top = (props) => {
  const companyId = props.match.params.companyId;
  const [companyName, setCompanyName] = useState("");
  const [topImage, setTopImage] = useState("");
  const [bottomImage, setBottomImage] = useState("");
  const [halls, setHalls] = useState([]);
  const history = useHistory();
  const [homePageLink, setHomePageLink] = useState("");
  const [personalInfoLink, setPersonalInfoLink] = useState("");

  useEffect(() => {
    const fetch = async () => {
      const company = await getCompany(companyId);
      setCompanyName(company.companyName);
      if(company.topImage) {
        setTopImage(company.topImage);
      }
      if(company.bottomImage) {
        setBottomImage(company.bottomImage);
      }
      setHalls(company.hallList);
      setHomePageLink(company.homePageLink);
      setPersonalInfoLink(company.personalInfoLink);
    }
    fetch();
  },[companyId]);

  return (
    <>
    {
      companyName &&
      <LPLayout name={companyName}>
        <Helmet>
          <title>{companyName} | itowa</title>
        </Helmet>
        <div id="company-top">
          <div className="top-banner">
          {
            topImage && <Img src={topImage} />
          }
          </div>
          <div className="title">ご葬儀会場一覧</div>
          <p>
              各会場で行なわれるご葬儀を確認いただけます。<br/>
              会場名をクリックしていただくと、会場別の訃報が表示されます。
          </p>
          <Row className="halls-row">
            {
              halls.map((hall, i) => 
                <Col key={i} className="halls-col" xl={3} lg={6} md={6} sm={6} xs={6}>
                  <div className="hall" onClick={() => history.push(`/${companyId}/hallInfo/${hall.HallId}`)}>
                    <div className="hall-name">
                      {hall.HallName}
                    </div>
                    <div className="hall-address">
                      ({format.customAddress1(hall?.Address)})
                    </div>
                  </div>
                </Col>
              )
            }
          </Row>
        </div>
        <div className="bottom-banner">
          <div className="homepage" onClick={() => window.open(homePageLink,'homepage')}>{companyName}ホームページ</div>
          {
            bottomImage && <Img src={bottomImage} />
          }
        </div>
        <CommonFooter privacyPolicyUrl={personalInfoLink} companyId={companyId}/>
      </LPLayout>
    }
    </>
  );
};

const getCompany = async (companyId) => {
  return await API.get('guest', `/companies/${companyId}/top`);
}

export default Top;