import { useContext, useEffect } from 'react';
import { API } from 'aws-amplify'
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import AttendeeLayout from 'components/AttendeeLayout';
import { useLoading } from 'components/Loading';
import { ContentHeader } from 'components/ContentHeader';
import { AuthContext } from '../../components/Contexts';

export const FuneralPayout = (props) => {
  const { userInfo } = useContext(AuthContext);
  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;
  const prefix = "/" + funeralId;

  const history = useHistory();
  const loading = useLoading();

  // 手数料
  const transactionFee = 250

  const formik = useFormik({
    initialValues: {
      balance: 0,
      payout: 0
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      try {
        const validationError = payoutValidation(formik.values.balance, formik.values.payout, transactionFee)
        if(validationError) {
          alert(validationError);
          return false;
        }
        if(!window.confirm(formik.values.payout + '円から手数料' + transactionFee + '円を引いた、' + (formik.values.payout - transactionFee) + '円振込んでもよろしいですか？\n※残高からは' + formik.values.payout + '円引かれます。')) {
          return false;
        }
        loading(true);
        const result = await payoutFuneralStripeConnect(funeralId, values);
        if (result.error) {
          alert("システムエラーが発生しました");
        } else {
          alert(result.amount + '円振込ました。\n※残高からは' + formik.values.payout + '円引かれています。');
          window.location.reload();
        }
      } catch (e) {
        if (e.response.status === 400) {
          alert(e.response.data.error);
          return;
        }
        alert("システムエラーが発生しました");
      } finally {
        loading(false);
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    loading(true);

    // 親族代表アカウントじゃなければリダイレクトする
    if (!userInfo.attendees.RelativesRepresentative) {
      loading(false);
      history.push(prefix + "/");
      return;
    }

    let result = {
      balance: 0
    };
    try {
      result = await getBalanceFuneralStripeConnect(funeralId);
    } catch (e) {
      if (e.response.status !== 404) alert("システムエラーが発生しました");
      loading(false);
      return;
    }

    formik.setFieldValue("balance", result.balance)
    loading(false);

    // eslint-disable-next-line
  }, []);

  return (
    <AttendeeLayout adminPage={true}>
      <div className="title-search">
        <div className="title">
          <ContentHeader>
            振込申請
          </ContentHeader>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="card">
          <div className="card-body">
            <p>【自動出金】<br/>口座登録完了後、翌月の11日若しくは25日から4営業日以内に自動で指定口座にお振込されます。<br/>（自動振込の振込手数料は無料）</p>
            <p>【手動出金】<br/>手動出金につきましては<br/>現在準備中となります。</p>
            <p>手動出金をご希望の場合は下記までお問合せください。<br/>＜itowaサポート窓口（平日10:00〜18:00）＞<br/><a href="tel:05088848247">050-8884-8247</a></p>
            <br></br>
          </div>
          <div className="card-footer">
            <div>
              <Button variant="secondary" className="float-right" onClick={() => history.goBack()}>戻る</Button>
            </div>
          </div>
        </div>
      </form>
    </AttendeeLayout>
  );
};

const getBalanceFuneralStripeConnect = async (funeralId) => {
  return await API.get('product', `/funerals/${funeralId}/connect/balance`);
}

const payoutFuneralStripeConnect = async (funeralId, form) => {
  const options = {
    body: form
  }
  return await API.post('product', `/funerals/${funeralId}/connect/payout`, options);
}

const payoutValidation = (balance, payout, transactionFee) => {
  if(balance < payout) return "振込金額が振込可能金額を超えています。";
  if(payout <= transactionFee) return transactionFee + "円を超えた金額でないと振込できません。";
  return "";
}


export default FuneralPayout;
