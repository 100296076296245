import { useState, useContext, useEffect, useRef } from 'react';
import EventListener from 'react-event-listener';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Alert, Modal } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import { API } from 'aws-amplify';

import '../../css/Attendee.scss';
import Header from './Header';
import CommonFooter from 'components/CommonFooter';
import { LoadingContext, AttendanceContext, MessageContext, AuthContext } from '../Contexts';
import * as Constants from '../../constants';

import PortalFuneralSetting from 'components/PortalFuneralSetting';

export const AttendeeLayout = (props) => {
  const match = useRouteMatch("/:funeralId");
  const funeralId = match.params.funeralId;
  const [height, setHeight] = useState(window.innerHeight);
  const { loading } = useContext(LoadingContext);
  const { attendance, setAttendance, funeralInfo, setFuneralInfo, showPortalFuneralSetting, setShowPortalFuneralSetting } = useContext(AttendanceContext);
  const { alert, setAlert } = useContext(MessageContext);
  const { userInfo } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const isAdminPage = props.adminPage || false;

  const [isExistchoubun, setIsExistchoubun] = useState(false);
  const [isExistKyouka, setIsExistKyouka] = useState(false);
  const [isExistKumotsu, setIsExistKumotsu] = useState(false);
  const [isShowMenuSchedulePDF, setShowMenuSchedulePDF] = useState(false);

  const contentLayoutRef = useRef();

  const { giftBoxRef } = props;

  const queryString = new URLSearchParams(location.search);

  const handleResize = (e) => {
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    // reactの管理外のbodyタグへの変更のため、直接DOM操作
    document.body.classList.add('layout-top-nav');

    const fetch = async () => {
      const response = await getFuneralInfo(funeralId);
      if (!response.error) {
        setFuneralInfo(response);
        setAttendance(false);
        setShowMenuSchedulePDF(response?.isPublicSchedule || false)
        if(
          userInfo?.isChiefMourner &&
          response?.enablePortalForFuneral === true &&
          response?.portalStatus === Constants?.PORTAL_STATUS?.NOT_SET && 
          showPortalFuneralSetting !== true
        ) {
          setShowPortalFuneralSetting(true);
        }
      } else {
        history.push("/");
      }

      const result = await getDisplayItem(funeralId);
      setIsExistchoubun(result.choudenList.length > 0);
      setIsExistKyouka(result.kyoukaList.length > 0);
      setIsExistKumotsu(result.kumotsuList.length > 0);
    }
    fetch();

    return () => document.body.classList.remove('layout-top-nav');
    // eslint-disable-next-line
  }, [])

  const toChouden = () => {
    setAttendance(false);
    history.push(`/${funeralId}/attendance/content/select/chouden`);
  }

  const toKumotsu = () => {
    setAttendance(false);
    history.push(`/${funeralId}/attendance/content/select/kumotsu`);
  }

  useEffect(() => {
    if (alert.message && alert.variant !== "danger") {
      setTimeout(() => setAlert({}), 3000);
    }
  }, [alert, setAlert]);

  useEffect(() => {
    return () => {
      setShowPortalFuneralSetting(false);
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const reOrder = queryString.get('re-order');
    if (reOrder === '1' && giftBoxRef?.current && funeralInfo) {
      setTimeout(() => {
        contentLayoutRef?.current?.scrollTo({
          top: giftBoxRef?.current?.offsetTop - height/2,
          behavior: "smooth",
        });
      }, 1000);
    }

    // eslint-disable-next-line
  }, [giftBoxRef?.current, funeralInfo]);

  return (
    <div id="attendees" style={{ height: height + 'px' }}>
      {
        funeralInfo &&
        <>
          <EventListener target="window" onResize={(e) => handleResize(e)} />
          <Header adminPage={isAdminPage} isShowMenuSchedulePDF={isShowMenuSchedulePDF}/>
          <LoadingOverlay active={loading} spinner text='Loading...'>
            <div ref={contentLayoutRef} id="attendee-content-main" className={"content-wrapper content"} style={{ height: height + 'px' }}>
              <section className="content">
                {props.children}
                {alert.message ? <Alert className="attendee-alert" variant={alert.variant} onClose={() => setAlert({})} dismissible>{alert.message}</Alert> : ""}
              </section>
              <CommonFooter privacyPolicyUrl={funeralInfo.privacyPolicyUrl} companyId={funeralInfo.companyId} />
              <div className="footer-space"></div>
            </div>
          </LoadingOverlay>
          <Modal id="attendee-modal" show={attendance} onHide={() => setAttendance(false)} backdrop="static" centered={true} size="xl">
            <Modal.Header closeButton style={{ borderBottomWidth: "0px", backgroundColor: "#FBFBFB" }}>
            </Modal.Header>
            <Modal.Body style={{ textAlign: "center" }}>
              <div className="decreased-name">
                故 {funeralInfo.decreasedName}へ
              </div>
              <p className="message">
                故人を大切にされてきた方々とアルバムを<br />
                共有・閲覧することができます。<br />
                まずは、故人へのお気持ちをお送りしましょう。
              </p>
              <div className="buttons">
                { isExistchoubun &&
                <div className="gift-button" onClick={toChouden}>
                  <div className="desc">
                    <div></div>
                    弔文を送る
                  </div>
                  <i className="fas fa-angle-right"></i>
                </div>
                }
                { isExistKyouka &&
                  <div className="gift-button" onClick={toKumotsu}>
                    <div className="desc">
                      <div></div>
                      お供花を送る
                    </div>
                    <i className="fas fa-angle-right"></i>
                  </div>
                }
                { isExistKumotsu &&
                  <div className="gift-button" onClick={toKumotsu}>
                    <div className="desc">
                      <div></div>
                      お供物を送る
                    </div>
                    <i className="fas fa-angle-right"></i>
                  </div>
                }
                { !isExistchoubun && !isExistKyouka && !isExistKumotsu &&
                    <div>
                      <p className="text-dark">選択肢がありません</p>
                    </div>
                }
                <div className="login">
                  <a href={Constants.SERVICE_URL} target="_blank" rel="noreferrer">サービスについてはこちら</a>
                </div>
                <div className="login">
                  <Link to={`/${funeralId}/login`}>すでに参列済みの方はログインへ</Link>
                </div>
              </div>
              <button type="button" className="black-button btn btn-primary close-button" onClick={() => setAttendance(false)}>閉じる</button>
            </Modal.Body>
          </Modal>
          <PortalFuneralSetting />
        </>
      }
    </div>
  );
}

const getFuneralInfo = async (funeralId) => {
  return await API.get('guest', '/funerals/' + funeralId + '/top');
}

const getDisplayItem = async (funeralId) => {
  const options = {
    queryStringParameters: { funeralId: funeralId }
  }

  return await API.get('guest', '/funerals/item/template', options);
}

export default AttendeeLayout;
