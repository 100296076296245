import React, { useState } from 'react';
import { API } from 'aws-amplify'
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { saveAs } from 'file-saver';

import { useLoading } from 'components/Loading';
import useMessage from 'components/Message';
import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import Pagination from 'components/Pagination';
import { ItemTypeToName } from 'components/ItemType';
import { prefCodeToName } from 'components/CustomForms/Prefecture';
import * as format from 'utils/format';
import moment from 'moment';
import ExcelJS from 'exceljs';
import encoding from 'encoding-japanese';

import * as Constants from "../../constants";
import {getBlob} from "../../utils/request";
import { isIOS } from 'utils/device';

export const FuneralOrderList = (props) => {
  const PER_PAGE = 10;
  const history = useHistory();

  const [orderList, setOrderList] = React.useState([]);
  const [pagenatedList, setPagenatedList] = React.useState([]);
  const [familyName, setFamilyName] = React.useState("");
  const funeralId = props.match.params.funeralId;
  const companyId = props.match.params.companyId;
  const [modal, setModal] = useState(false);
  const [order, setOrder] = useState({})
  
  const loading = useLoading(true);
  const message = useMessage();

  React.useEffect(() => {
    const fetch = async () => {
      let list = []
      const result = await getFuneralOrderList(funeralId, companyId);
      result.orderList.forEach((order) => {
        order.Item.forEach((item) => {
          if (item.ItemType !== 'gift') {
            list.push({
              orderId: order?.OrderId,
              userName: order.UserName,
              itemType: item.ItemType,
              purchaseDate: order.PurchaseDate,
              itemId: item.ItemId,
              itemName: item.ItemName,
              label: item.Label,
              message: item.Message,
              price: item.Price,
              zipCode: order.Address ? order.Address.ZipCode : "未登録",
              address: order.Address ? prefCodeToName(order.Address.Prefecture) + order.Address.Address1 + order.Address.Address2 : "未登録",
              gender: format.gender(order.Gender),
              age: order.Age.replace("R", "") + "代",
              attendDateTime: order.AttendDateTime,
              isInvoice: order.IsInvoice,
              paymentMethod: order.IsInvoice ? "請求書払い" : "クレジットカード払い",
            })
          }
        })
      });
      const resultList = list.sort((a, b) => a.Id < b.Id ? -1 : 1);
      setOrderList(list);
      setPagenatedList(resultList.slice(0, PER_PAGE));
      setFamilyName(result.familyName);
    }
    fetch()
    // eslint-disable-next-line
  }, []);

  const showDetail = (row) => {
    setOrder(row);
    setModal(true);
  }

  const download = async () => {
    const orderListForCSV = orderList.map((item) => {
      return {
        ...item,
        purchaseDate: moment(item.purchaseDate).format("YYYY/M/D"),
      }
    })

    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet("sheet1");
    const worksheet = workbook.getWorksheet("sheet1");

    worksheet.columns = [
      { header: "注文日時", key: "purchaseDate" },
      { header: "注文者名", key: "userName" },
      { header: "郵便番号", key: "zipCode" },
      { header: "住所", key: "address" },
      { header: "商品名", key: "itemName" },
      { header: "金額", key: "price" },
      { header: "お名前(名札)", key: "label" },
      { header: "支払い形式", key: "paymentMethod" },
    ];
    worksheet.addRows(orderListForCSV);

    const uint8Array = new Uint8Array(
      encoding.convert(await workbook.csv.writeBuffer(), {
        from: "UTF8",
        to: "SJIS"
      })
    );
    const blob = new Blob([uint8Array], { type: "application/octet-binary" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "注文リスト.csv";
    a.click();
    a.remove();
  }

  const handleDownloadReceipt = async (orderId) => {
    loading(true);
    try {
      await downloadInvoice(funeralId, orderId);
    } catch {
      message.alert.danger("システムエラーが発生しました");
    }
    loading(false)
  }

  const handleDownloadInvoice = async (orderItem) => {
    loading(true);
    const filename = `${companyId}/invoice/${orderItem?.orderId}.pdf`;
    if (isIOS()) {
      window.location.href = Constants.IMAGE_BUCKET_URL + filename;
    } else {
      try {
        const pdfFile = await getBlob(Constants.IMAGE_BUCKET_URL + filename, { method: 'GET' });
        if (pdfFile) saveAs(pdfFile, '請求書' + moment(orderItem?.purchaseDate).format("YYYY-MM-DD") + '.pdf');
      } catch {
        message.alert.danger("システムエラーが発生しました");
      }
    }
    loading(false)
  }

  return (
    <CommonLayout>
      <ContentHeader>
        {`${familyName}家 注文リスト`}
      </ContentHeader>
      <Row>
        <Col md="12" lg="9">
          <div className="card">
            <div className="card-body p-0">
              <table className="table table-order-list">
                <thead>
                  <tr>
                    <th>項目</th>
                    <th>注文日</th>
                    <th>商品ID</th>
                    <th>商品名</th>
                    <th>お名前(名札)</th>
                    <th>価格</th>
                    <th className="action-col"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    pagenatedList.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td>{ItemTypeToName(row.itemType)}</td>
                          <td>{moment(row.purchaseDate).format("YYYY/M/D")}</td>
                          <td>{row.itemId}</td>
                          <td>{row.itemName}</td>
                          <td>
                            {
                              row.label ?
                                row.label.split('\n').map((label, i) => { return (<div key={i}>{label}</div>) })
                                :
                                ""
                            }
                          </td>
                          <td className='text-end'>{row.price.toLocaleString()}</td>
                          <td className="action-col">
                            <button className="btn btn-warning mr-1 mb-1 mt-1" onClick={() => showDetail(row)}>詳細</button>
                            {row?.isInvoice === true && (
                              <button className="btn btn-primary mb-1 mt-1" onClick={() => handleDownloadInvoice(row)}>請求書</button>
                            )}
                            {row?.isInvoice === false &&  (
                              <button className="btn btn-dark mb-1 mt-1" onClick={() => handleDownloadReceipt(row?.orderId)}>領収書</button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <Button variant="secondary" onClick={() => history.goBack()}>戻る</Button>
              {' '}
              <Button variant="info" onClick={() => download()}>CSVダウンロード</Button>
              <Pagination per={PER_PAGE} list={orderList} onChange={(list) => setPagenatedList(list)} />
            </div>
            <Modal show={modal} onHide={() => setModal(false)} backdrop="static" centered={true} size="lg">
              <Modal.Header className="header" closeButton >
              </Modal.Header>
              <Modal.Body>
                <table className="table table-borderless text-left">
                  <tbody>
                    <tr>
                      <th>参列日時</th>
                      <td>{moment(order.attendDateTime).format("YYYY/M/D H:mm")}</td>
                    </tr>
                    <tr>
                      <th>郵便番号</th>
                      <td>{order.zipCode}</td>
                    </tr>
                    <tr>
                      <th>住所</th>
                      <td>{order.address}</td>
                    </tr>
                    <tr>
                      <th>商品名</th>
                      <td>{order.itemName}</td>
                    </tr>
                    <tr>
                      <th>年代</th>
                      <td>{order.age}</td>
                    </tr>
                    <tr>
                      <th>性別</th>
                      <td>{order.gender}</td>
                    </tr>
                    {order.message ?
                      <tr>
                        <th>弔文文章</th>
                        <td>
                          {
                            order.message.split('\n').map((message, i) => { return (<div key={i}>{message}<br /></div>) })
                          }
                        </td>
                      </tr>
                      :
                      <></>
                    }
                  </tbody>
                </table>

                <div style={{ textAlign: "center" }}>
                  <button type="button" className="black-button btn btn-primary" onClick={() => setModal(false)}>閉じる</button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </Col>
      </Row>
    </CommonLayout>
  );
};

const downloadInvoice = async (funeralId, orderId) => {
  const options = {
    queryStringParameters: {
      orderId: orderId,
      downloadFile: 'itowa',
    }
  }
  await API.get(
      'guest',
      `/receipt/${funeralId}/create`, options
  ).then(async res => {
    if(Array.isArray(res)) {
      res?.forEach(async (file) => {
        if (file.filename) {
          if (isIOS()) {
            window.location.href = Constants.IMAGE_BUCKET_URL + file.filename;
          } else {
            const pdfFile = await getBlob(Constants.IMAGE_BUCKET_URL + file.filename, { method: 'GET' });
            saveAs(pdfFile, '領収書' + moment(res.orderDate).format("YYYY-MM-DD") + ".pdf");
          }
        }
      });
    } else {
      if (res.filename) {
        if (isIOS()) {
          window.location.href = Constants.IMAGE_BUCKET_URL + res.filename;
        } else {
          const pdfFile = await getBlob(Constants.IMAGE_BUCKET_URL + res.filename, { method: 'GET' });
          saveAs(pdfFile, '領収書' + moment(res.orderDate).format("YYYY-MM-DD") + ".pdf");
        }
      }
    }
  });
}

const getFuneralOrderList = async (funeralId, companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }

  return await API.get('product', `/funerals/${funeralId}/order`, options);
}

export default FuneralOrderList;