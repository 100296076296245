import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { Row, Col, Button, input } from 'react-bootstrap';

import CommonLayout from 'components/CommonLayout';
import ContentHeader from 'components/ContentHeader';
import useMessage from 'components/Message';

import TimelineLabel from 'components/CustomForms/TimelineLabel';
import TimelineItem from 'components/CustomForms/TimelineItem';
import { LimitDateTime } from 'components/CustomForms/LimitDateTime';
import * as Yup from "yup";

// const Flex = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 0px 5px;
// `

export const ItemTemplate2 = (props) => {
  const history = useHistory();
  const message = useMessage();

  const [itemList, setItemList] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      vigilItems: [],
      funeralItems: [],
      fortyNinthItems: [],
      useVigilMoney: true,
      useFuneralMoney: true,
      useFortyNinthMoney: true,

      vigilToFuneralType: "FEW_HOURS_AGO",
      vigilToFuneralFixDateTimeDay: "YESTERDAY",
      vigilToFuneralDateTime: "",

      funeralToFortyNinthType: "FEW_HOURS_AGO",
      funeralToFortyNinthFixDateTimeDay: "YESTERDAY",
      funeralToFortyNinthDateTime: "",
    },
    validationSchema: Yup.object({
      vigilToFuneralDateTime: Yup.string().required("時間情報は必須です"),
      funeralToFortyNinthDateTime: Yup.string().required("時間情報は必須です"),
    }),
    onSubmit: async (values) => {
      await updateItemTemplate(props.match.params.companyId, values);
      message.alert.success("商品テンプレートを更新しました");
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    const fetch = async () => {
      const result = await getItemTemplate(props.match.params.companyId);
      setItemList(result.itemList);
      setFieldValue("vigilItems", result.vigilItems);
      setFieldValue("funeralItems", result.funeralItems);
      setFieldValue("fortyNinthItems", result.fortyNinthItems);
      setFieldValue("useVigilMoney", result.useVigilMoney);
      setFieldValue("useFuneralMoney", result.useFuneralMoney);
      setFieldValue("useFortyNinthMoney", result.useFortyNinthMoney);

      setFieldValue("vigilToFuneralType", result.itemDateTime.vigilToFuneral.type);
      setFieldValue("vigilToFuneralFixDateTimeDay", result.itemDateTime.vigilToFuneral.fixDateTimeDay);
      setFieldValue("vigilToFuneralDateTime", result.itemDateTime.vigilToFuneral.dateTime);

      setFieldValue("funeralToFortyNinthType", result.itemDateTime.funeralToFortyNinth.type);
      setFieldValue("funeralToFortyNinthFixDateTimeDay", result.itemDateTime.funeralToFortyNinth.fixDateTimeDay);
      setFieldValue("funeralToFortyNinthDateTime", result.itemDateTime.funeralToFortyNinth.dateTime);
    }
    fetch();
  }, [props, setFieldValue]);

  // 通夜の数時間前パターン
  const handleTsuyaTimeLimitChange = () => {
    setFieldValue("vigilToFuneralType", "FEW_HOURS_AGO");
  }
  // 通夜の日時固定パターン
  const handleTsuyaDateLimitChange = () => {
    setFieldValue("vigilToFuneralType", "FIXED_DATE_TIME");
  }

  // 葬儀の数時間前パターン
  const handleFuneralTimeLimitChange = () => {
    setFieldValue("funeralToFortyNinthType", "FEW_HOURS_AGO");
  }
  // 葬儀の日時固定パターン
  const handleFuneralDateLimitChange = () => {
    setFieldValue("funeralToFortyNinthType", "FIXED_DATE_TIME");
  }

  const onChangeVigilToFuneralFixDateTimeDay = (e) => {
    setFieldValue("vigilToFuneralFixDateTimeDay", e.target.value);
  }

  const onChangeFuneralToFortyNinthFixDateTimeDay = (e) => {
    setFieldValue("funeralToFortyNinthFixDateTimeDay", e.target.value);
  }

  return (
    <CommonLayout>
      <ContentHeader>
        商品テンプレート
      </ContentHeader>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md="12">
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <div className="timeline">
                    <TimelineLabel>
                      没日
                    </TimelineLabel>
                    <TimelineItem title="商品情報（通夜）" name="vigilItems" formik={formik} useMoney="useVigilMoney" itemList={itemList} />
                    <div className="form-group">
                      <Row>
                        <Col md="8">
                          <div className="bg-gray templateDateTime">
                            {formik.values.vigilToFuneralType === "FEW_HOURS_AGO" &&
                              <TimelineLabel>
                                <p className="bg-gray limitDate m-3">通夜日時</p>
                                <LimitDateTime name="vigilToFuneralDateTime" formik={formik} /><div className="bg-gray m-3">前商品切替（通夜→葬儀）</div>
                              </TimelineLabel>
                            }
                            {formik.values.vigilToFuneralType === "FIXED_DATE_TIME" &&
                              <TimelineLabel>
                                <select name="vigilToFuneralFixDateTimeDay" className="custom-select dropdownDateTime" onChange={onChangeVigilToFuneralFixDateTimeDay}>
                                  <option value="YESTERDAY" selected={formik.values.vigilToFuneralFixDateTimeDay === "YESTERDAY"}>通夜日前日</option>
                                  <option value="TODAY" selected={formik.values.vigilToFuneralFixDateTimeDay === "TODAY"}>通夜日当日</option>
                                </select>
                                <LimitDateTime name="vigilToFuneralDateTime" formik={formik} />
                                <div className="bg-gray m-3">商品切替（通夜→葬儀）</div>
                              </TimelineLabel>
                            }
                          </div>
                        </Col>
                        <Col md="4">
                          <Row>
                            <Col md="1"><></></Col>
                            <Col md="11">
                              <div class="form-check my-3 text-right">
                                <input className="form-check-input" type="radio" name="tsuyaLimit" id="tsuyaLimit1" onClick={() => handleTsuyaTimeLimitChange()} checked={formik.values.vigilToFuneralType === "FEW_HOURS_AGO"}/>
                                <label className="form-check-label" for="tsuyaLimit1">
                                  時間設定　　
                                </label>
                                <input className="form-check-input" type="radio" name="tsuyaLimit" id="tsuyaLimit2" onClick={() => handleTsuyaDateLimitChange()} checked={formik.values.vigilToFuneralType === "FIXED_DATE_TIME"}/>
                                <label className="form-check-label" for="tsuyaLimit2">
                                  日時設定
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <TimelineItem title="商品情報（葬儀）" name="funeralItems" formik={formik} useMoney="useFuneralMoney" itemList={itemList} />
                    <div className="form-group">
                      <Row>
                        <Col md="8">
                          <div className="bg-gray templateDateTime">
                            {formik.values.funeralToFortyNinthType === "FEW_HOURS_AGO" &&
                              <TimelineLabel>
                                <p className="bg-gray limitDate m-3">葬儀日時</p>
                                <LimitDateTime name="funeralToFortyNinthDateTime" formik={formik} /><div className="bg-gray m-3">前商品切替（葬儀→四十九日）</div>
                              </TimelineLabel>
                            }
                            {formik.values.funeralToFortyNinthType === "FIXED_DATE_TIME" &&
                              <TimelineLabel>
                                <select name="funeralToFortyNinthFixDateTimeDay" className="custom-select dropdownDateTime" onChange={onChangeFuneralToFortyNinthFixDateTimeDay}>
                                  <option value="YESTERDAY" selected={formik.values.funeralToFortyNinthFixDateTimeDay === "YESTERDAY"}>葬儀日前日</option>
                                  <option value="TODAY" selected={formik.values.funeralToFortyNinthFixDateTimeDay === "TODAY"}>葬儀日当日</option>
                                </select>
                                <LimitDateTime name="funeralToFortyNinthDateTime" formik={formik} />
                                <div className="bg-gray m-3">商品切替（葬儀→四十九日）</div>
                              </TimelineLabel>
                            }
                          </div>
                        </Col>
                        <Col md="4">
                          <Row>
                            <Col md="1"><></></Col>
                            <Col md="11">
                              <div class="form-check my-3 text-right">
                                <span>
                                  <input className="form-check-input" type="radio" name="funeralLimit" id="funeralLimit1" onClick={() => handleFuneralTimeLimitChange()} checked={formik.values.funeralToFortyNinthType === "FEW_HOURS_AGO"}/>
                                  <label className="form-check-label" for="funeralLimit1">
                                    時間設定　　
                                  </label>
                                  <input className="form-check-input" type="radio" name="funeralLimit" id="funeralLimit2" onClick={() => handleFuneralDateLimitChange()} checked={formik.values.funeralToFortyNinthType === "FIXED_DATE_TIME"}/>
                                  <label className="form-check-label" for="funeralLimit2">
                                    日時設定
                                  </label>
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <TimelineItem title="商品情報（四十九日）" name="fortyNinthItems" formik={formik} useMoney="useFortyNinthMoney" itemList={itemList} />
                    <TimelineLabel>
                      四十九日
                    </TimelineLabel>
                    <TimelineLabel>
                      公開終了
                    </TimelineLabel>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div>
                  <Button variant="secondary" className="float-right" onClick={() => history.goBack()}>戻る</Button>
                  <Button type="submit" variant="success">更新</Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </CommonLayout>
  )
}

const getItemTemplate = async (companyId) => {
  const options = {
    queryStringParameters: {
      companyId: companyId
    }
  }
  return await API.get('product', '/items/template', options);
}

const updateItemTemplate = async (companyId, values) => {
  let vigilsItemIdList = [];
  if (values.vigilItems) values.vigilItems.forEach((row) => { vigilsItemIdList.push(row.itemId); });

  let funeralsItemIdList = [];
  if(values.funeralItems) values.funeralItems.forEach((row) => { funeralsItemIdList.push(row.itemId); });

  let fortyNinthItemIdList = [];
  if(values.fortyNinthItems) values.fortyNinthItems.forEach((row) => { fortyNinthItemIdList.push(row.itemId); });


  const options = {
    body: {
      companyId: companyId,
      itemTemplates: {
        Vigils: {
          UseMoney: values.useVigilMoney,
          Items: vigilsItemIdList
        },
        Funerals: {
          UseMoney: values.useFuneralMoney,
          Items: funeralsItemIdList
        },
        FortyNinth: {
          UseMoney: values.useFortyNinthMoney,
          Items: fortyNinthItemIdList
        },
        ItemDateTime: {
          VigilToFuneral: {
            Type: values.vigilToFuneralType,
            FixDateTimeDay: values.vigilToFuneralFixDateTimeDay,
            DateTime: values.vigilToFuneralDateTime,
          },
          FuneralToFortyNinth: {
            Type: values.funeralToFortyNinthType,
            FixDateTimeDay: values.funeralToFortyNinthFixDateTimeDay,
            DateTime: values.funeralToFortyNinthDateTime,
          }
        }
      }
    }
  }
  console.log(values);
  return await API.put('product', '/items/template', options);
}

export default ItemTemplate2;