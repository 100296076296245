import { Modal } from 'react-bootstrap';
import { Img } from 'components/Img';

export const ItemDetail = (props) => {
  const { item } = props;

  return (
    <div>
      <Modal id="attendee-modal" size="xl" show={props.show} onHide={props.onHide} centered={false}>
        <Modal.Header closeButton style={{ borderBottomWidth: "0px", backgroundColor: "#FBFBFB" }}>
        </Modal.Header>
        <Modal.Body id="item-detail-modal" className="item-cell" style={{ backgroundColor: "#FBFBFB" }}>
          <div className="title">{item.itemName}</div>
          <div className="panel">
            <div className="frame">
              <Img className="photo" src={item.imagePath}></Img>
            </div>
            <div className="detail">
              <div className="price">
                <span className="price-label">{item.itemType === "money" ? "香典料" : "価格"}</span>
                <span className="price-value">
                  {parseInt(item.displayPrice || item.price, 10).toLocaleString()}
                  <span className="yen"> {item.itemType === "money" ? "円" : "円（税込）"}</span>
                </span>
              </div>
              <div className="description">
                {
                  item.itemType === "money" ? "香典料を葬家様へお渡しします" : item.description
                }
              </div>
            </div>
          </div>
          {
            item.youtubeTag &&
            <div id="embeded" dangerouslySetInnerHTML={{ __html: item.youtubeTag }} />
          }
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ItemDetail;