export const AgeSelect = (props) => {
  const { name, label, formik } = props; 
  const hasError = formik && formik.errors[name];

  return (
    <>
      <label className="control-label">{label}</label>
      <select className={"custom-select" + (hasError ? " is-invalid" : "")} name={name} {...formik.getFieldProps(name)}>
        <option value=""></option>
        <option value="10">～19</option>
        <option value="20">20～29</option>
        <option value="30">30～39</option>
        <option value="40">40～49</option>
        <option value="50">50～59</option>
        <option value="60">60～</option>
      </select>
      <div className="invalid-feedback">{formik.errors[name]}</div>
    </>
  )
}

export default AgeSelect;